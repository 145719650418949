import Text from "components/atoms/text";
import useUrl from "hooks/utils/useUrl";
import React from "react";
import useMobileStore from "store/useMobileStore";
import commonText from "text/common";

const { blank } = commonText;

const Blank = ({ isBorderTop }) => {
  const {
    query: { searchKeyword },
  } = useUrl();

  const { isMobile } = useMobileStore();

  const isSearch = searchKeyword;

  return (
    <Text
      width={"100%"}
      textAlign={"center"}
      padding={isMobile ? "20px 0" : "50px 0"}
      margin={isMobile ? "0 0 40px" : "0"}
      borderTop={isBorderTop ? "1px solid" : ""}
      borderBottom={"1px solid"}
      borderColor={Text.COLOR.LINEGRAY}
      size={isMobile ? Text.SIZE.MOBILECONTENT : Text.SIZE.CLIENTNORMAL}
      color={Text.COLOR.TEXTGRAY}
    >
      {isSearch ? blank.search : blank.nosearch}
      {blank.common}
    </Text>
  );
};

export default Blank;
