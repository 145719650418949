import Box from "components/atoms/box";
import Button from "components/atoms/button";
import React from "react";
import useMobileStore from "store/useMobileStore";
import "./style.css";

const MobileLink = () => {
  const { setIsMobile, isMobile } = useMobileStore();

  return (
    !isMobile && (
      <Box
        className="mobile-link"
        display={Box.DISPLAY.NONE}
        position="fixed"
        bottom="30px"
        left="50%"
        transform="translateX(-50%)"
        zIndex={Box.LEVEL.ROOT}
      >
        <Button
          themeStyle={Button.THEME.NONE}
          width={"175px"}
          padding={"20px 30px"}
          bgColor={Button.COLOR.SECONDARY}
          color={Button.COLOR.WHITE}
          onClick={() => setIsMobile(true)}
          borderRadius={"20px"}
        >
          모바일 버전으로 보기
        </Button>
      </Box>
    )
  );
};

export default MobileLink;
