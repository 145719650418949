import Box from "components/atoms/box";
import Button from "components/atoms/button";
import Image from "components/atoms/image";
import Link from "components/atoms/link";
import Slider, { nextSlide, prevSlide } from "components/atoms/slider";
import Text from "components/atoms/text";
import dayjs from "dayjs";
import React, { useRef, useState } from "react";
import { BsChevronDown } from "@react-icons/all-files/bs/BsChevronDown";
import { BsChevronUp } from "@react-icons/all-files/bs/BsChevronUp";
import { VscCircleFilled } from "@react-icons/all-files/vsc/VscCircleFilled";
import homeText from "text/home";
import useMobileStore from "store/useMobileStore";
import convertImage from "utils/convertImage";

const { schedule } = homeText;

const images = [
  { image: "/assets/common/banner.png", date: "2021-04-01", title: "test1" },
  { image: "/assets/common/banner.png", date: "2021-04-01", title: "test2" },
  { image: "/assets/common/banner.png", date: "2021-04-01", title: "test3" },
  { image: "/assets/common/banner.png", date: "2021-04-01", title: "test4" },
  { image: "/assets/common/banner.png", date: "2021-04-01", title: "test5" },
];

const MainSchedule = (props) => {
  const { coverageNews } = props;
  const sliderRef = useRef();
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <Box
      display={Box.DISPLAY.FLEX}
      flexDirection="column"
      al="center"
      jc="center"
      width="20%"
      padding={"0 40px"}
      bgColor={Box.COLOR.BANNERGRAY}
      bgImage={"/assets/home/schedule.png"}
      bgSize={"contain"}
      bgRepeat={"no-repeat"}
      ariaLabel="원장님 동정 보도 영역"
    >
      <Box
        display={Box.DISPLAY.FLEX}
        margin={"0 0 15px "}
        ariaLabel="원장님 동정 보도 텍스트 영역"
      >
        <Image
          width={"43px"}
          src={"/assets/home/calendar.svg"}
          ariaLabel={"캘린더 아이콘"}
          alt={"캘린더 아이콘"}
        />
        <Box margin={"0 0 0 5px"}>
          <Text
            as={Text.VARIANT.SUBTITLE}
            weight={Text.WEIGHT.NORMAL}
            size={Text.SIZE.CLIENTNORMAL}
            ariaLabel={"원장님 동정 보도"}
          >
            {schedule.category}
          </Text>
          <Text
            as={Text.VARIANT.TITLE}
            size={Text.SIZE.CLIENTCATEGORY}
            lineHeight={"29px"}
            ariaLabel={"주요 일정"}
          >
            {schedule.title}
          </Text>
        </Box>
      </Box>
      <Link
        width={"100%"}
        link={
          coverageNews[activeIndex]
            ? `/news/${coverageNews[activeIndex].key}?category=COM045_AspbHPe`
            : ""
        }
      >
        <Image
          width={"100%"}
          src={convertImage(coverageNews[activeIndex]?.thumbnail, "thumbnail")}
          aspectRatio={"298/200"}
          radius={Box.RADIUS.NORMAL}
          ariaLabel={`${coverageNews[activeIndex]?.title} 썸네일 이미지`}
          alt={`${coverageNews[activeIndex]?.title} 썸네일 이미지`}
        />
      </Link>

      <Box
        display={Box.DISPLAY.FLEX}
        width={"100%"}
        height={"82px"}
        padding={"18px 0 18px 18px"}
        margin={"20px 0 0"}
        borderTop="1px solid"
        borderBottom="1px solid"
        borderColor={Box.COLOR.LINEGRAY}
        ariaLabel={"원장님 동정 보도 슬라이더"}
      >
        <Box width={"calc(100% - 18px)"}>
          {coverageNews.length === 0 && (
            <Box display={Box.DISPLAY.FLEX} al="center" height={"100%"}>
              <Text
                width={"100%"}
                as={Text.VARIANT.TEXT}
                lineClamp={1}
                wordBreak="break-all"
                margin={"0 0 0 16px"}
                size={Text.SIZE.CLIENTETC}
              >
                <Text
                  as={Text.VARIANT.SPAN}
                  margin={"0 6px 0 0"}
                  lineHeight={"1px"}
                >
                  <VscCircleFilled size={10} />
                </Text>
                일정이 없습니다.
              </Text>
            </Box>
          )}

          {coverageNews.length !== 0 && (
            <Slider
              direction="vertical"
              height={"100%"}
              slidesPerView={1}
              simulateTouch={false}
              loop={true}
              navigation={true}
              sliderRef={sliderRef}
              setActiveIndex={setActiveIndex}
            >
              {coverageNews.map(({ title, eventBeginDate, key }, idx) => (
                <Slider.Slide key={key}>
                  <Link link={`/news/${key}?category=COM045_AspbHPe`}>
                    <Box
                      display={Box.DISPLAY.FLEX}
                      al="center"
                      ariaLabel={`${idx + 1}번째 동정 보도 일정 : ${title}`}
                    >
                      <Box
                        display={Box.DISPLAY.FLEX}
                        al="center"
                        jc="center"
                        width={"44px"}
                        height={"44px"}
                        bgColor={Text.COLOR.SECONDARY}
                        borderRadius={Text.RADIUS.ROUND}
                      >
                        <Text
                          as={Text.VARIANT.TEXT}
                          size={Text.SIZE.CLIENTETC}
                          color={Text.COLOR.WHITE}
                        >
                          {dayjs(eventBeginDate).format("MM/DD")}
                        </Text>
                      </Box>
                      <Text
                        width={"calc(100% - 60px)"}
                        as={Text.VARIANT.TEXT}
                        lineClamp={1}
                        wordBreak="break-all"
                        margin={"0 0 0 16px"}
                        size={Text.SIZE.CLIENTETC}
                        ariaLabel={`${idx + 1}번째 동정 보도 일정 : ${title}`}
                      >
                        <Text
                          as={Text.VARIANT.SPAN}
                          margin={"0 6px 0 0"}
                          lineHeight={"1px"}
                        >
                          <VscCircleFilled size={10} />
                        </Text>
                        {title}
                      </Text>
                    </Box>
                  </Link>
                </Slider.Slide>
              ))}
            </Slider>
          )}
        </Box>
        <Box
          width={"18px"}
          display={Box.DISPLAY.FLEX}
          flexDirection="column"
          jc="center"
          ariaLabel="원장님 동정 보도 슬라이더 네비게이션"
        >
          <Button
            color={Button.COLOR.DARKGRAY}
            themeStyle={Button.THEME.NONE}
            onClick={prevSlide(sliderRef)}
            ariaLabel="이전 일정으로 이동"
          >
            <BsChevronUp />
          </Button>
          <Button
            color={Button.COLOR.DARKGRAY}
            themeStyle={Button.THEME.NONE}
            onClick={nextSlide(sliderRef)}
            ariaLabel="다음 일정으로 이동"
          >
            <BsChevronDown />
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default MainSchedule;
