const convertDateMonthDays = (date) => {
  if (!date) return;

  const parts = date.split(".");

  const year = parts[0];
  const month = parts[1].padStart(2, "0");
  const day = parts[2].padStart(2, "0");

  return `${year}-${month}-${day}`;
};

export default convertDateMonthDays;
