import React, { useRef } from "react";
import { useEffect } from "react";

const PasswordMailDetail = () => {
  const letterRef = useRef(null);

  // useEffect(() => {
  //   console.log(letterRef.current.innerHTML);
  // }, []);

  return (
    <div
      ref={letterRef}
      style={{
        width: "100%",
      }}
    >
      <table
        style={{
          width: "818px",
          margin: "0 auto",
          tableLayout: "fixed",
          overflowWrap: "break-word",
          wordBreak: "normal",
        }}
      >
        <tbody>
          <tr>
            <td style={{ width: "818px", textAlign: "center" }}>
              <img
                src={
                  "https://online.kofst.or.kr/images/user/common/top/logo.gif"
                }
                alt={"logo"}
              />

              <p
                style={{
                  marginTop: "15px",
                }}
              >
                비밀번호를 변경하시려면{" "}
                <a
                  style={{
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                >
                  [여기]
                </a>
                를 클릭해주세요.
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default PasswordMailDetail;
