import { Navigate, Outlet, createBrowserRouter } from "react-router-dom";
import React from "react";
import Home from "pages/home";
import Daily from "pages/daily";
import Calendar from "pages/calendar";
import News from "pages/news";
import Feature from "pages/feature";
import FeatureDetail from "pages/feature/detail";
import People from "pages/people";
import Science from "pages/science";
import Header from "components/organisms/header";
import AdminLogin from "pages/admin/login";
import SideBar from "components/organisms/sideBar";
import { AdminContainer } from "components/templates/admin/admin.style";
import Dashboard from "pages/admin/dashboard";
import Box from "components/atoms/box";
import ManagementPopup from "pages/admin/management_site/popup";
import ManagementBanner from "pages/admin/management_site/banner";
import Footer from "components/organisms/footer";
import Detail from "pages/detail";
import Service from "pages/service";
import Help from "pages/help";
import ManagementManager from "pages/admin/management_admin/manager";
import ManagementAuthority from "pages/admin/management_admin/authority";
import ScienceCoverage from "pages/admin/calendar/coverage";
import CalendarScience from "pages/admin/calendar/science";
import ManagementNewsLetter from "pages/admin/newsletter/management";
import ManagementMember from "pages/admin/management_member/management";
import ManagementNewsletterApplier from "pages/admin/newsletter/applier";
import ManagementComment from "pages/admin/management_site/comment";
import ManagementWorkStatus from "pages/admin/management_admin/workStatus";
import ManagementLoginLog from "pages/admin/management_admin/login_log";
import ManagementNewsletterRejection from "pages/admin/newsletter/reject";
import CustomerServiceRequest from "pages/admin/customer_service/manager";
import ManagementMemberTermination from "pages/admin/management_member/termination";
import ManagementCategory from "pages/admin/category";
import CategoryDetail from "pages/admin/category/detail";
import AdminHeader from "components/organisms/adminHeader";
import ManagerDetail from "pages/admin/management_admin/manager/detail";
import BannerDetail from "pages/admin/management_site/banner/detail";
import Search from "pages/search";
import NotFound from "pages/notFound";
import MobileHome from "pages/mobile/home";
import MobileDaily from "pages/mobile/daily";
import MobileCalendar from "pages/mobile/calendar";
import MobileNews from "pages/mobile/news";
import MobileFeature from "pages/mobile/feature";
import MobilePeople from "pages/mobile/people";
import MobileScience from "pages/mobile/science";
import MobileFeatureDetail from "pages/mobile/feature/detail";
import MobileNewsLetter from "pages/mobile/newsletter";
import MobileDetail from "pages/mobile/detail";
import MobileService from "pages/mobile/service";
import MobileHelp from "pages/mobile/help";
import MobileSearch from "pages/mobile/search";
import MobileHeader from "components/organisms/mobileHeader";
import MobileFooter from "components/organisms/mobileFooter";
import Login from "pages/login";
import NewsletterDetail from "pages/newsletterDetail";
import Newsletter from "pages/newsletter";
import SignUp from "pages/signup";
import FindId from "pages/findId";
import FindPw from "pages/findPw";
import PopupDetail from "pages/admin/management_site/popup/detail";
import AuthorityDetail from "pages/admin/management_admin/authority/detail";
import ScienceDetail from "pages/admin/calendar/science/detail";
import MobileSignUp from "pages/mobile/signup";
import MobileFindId from "pages/mobile/findId";
import MobileFindPw from "pages/mobile/findPw";
import ManagementNewsBoard from "pages/admin/management_news_board";
import NewsBoardDetail from "pages/admin/management_news_board/detail";
import AdminNewsLetterDetail from "pages/admin/newsletter/management/detail";
import RequestDetail from "pages/admin/customer_service/manager/detail";
import MemberDetail from "pages/admin/management_member/management/detail";
import MyInfo from "pages/admin/myInfo";
import ChangePassword from "pages/changePassword";
import MobileChangePassword from "pages/mobile/changePassword";
import PasswordMailDetail from "pages/passwordMailDetail";
import PrevNewsletter from "pages/prevNewsletter";
import Unsubscribe from "pages/unsubscribe";
import CoverageDetail from "pages/admin/calendar/coverage/detail";
import NewsletterBanner from "pages/admin/newsletter/banner";
import NewsletterBannerDetail from "pages/admin/newsletter/banner/detail";
import ProtectedRoute from "components/molecules/protectedRoute";
import NewsletterPreview from "pages/admin/newsletter/preview";
import MembershipLoginLog from "pages/admin/management_member/login_log";
import UpdateAdminMail from "pages/admin/customer_service/update_mail";
import NewsletterAutoDelivery from "pages/admin/newsletter/comment";
import Redirect from "pages/redirect";
import { adminDefaultUrl } from "api/admin/url";

const getQueries = (list, url) => {
  const queries = {};
  for (const key of list) {
    const value = url.searchParams.get(key);
    if (value) {
      queries[key] = value;
    }
  }
  return queries;
};

const createRouter = (isMobile) => {
  return createBrowserRouter([
    {
      path: isMobile ? "/pc" : "/",
      element: (
        <>
          <Header />
          <Outlet />

          <Footer />
        </>
      ),
      children: [
        {
          path: "",
          element: <Home />,
          loader: ({ request }) => {
            const url = new URL(request.url);
            return getQueries(["hello"], url); // ?hello=world
          },
        },
        {
          path: "daily",
          element: <Daily />,
          loader: ({ request }) => {
            const url = new URL(request.url);
            return getQueries(["page", "category", "date", "calendar"], url);
          },
        },
        {
          path: "calendar",
          element: <Calendar />,
          loader: ({ request }) => {
            const url = new URL(request.url);
            return getQueries(
              [
                "page",
                "category",
                "date",
                "calendar",
                "searchKeyword",
                "type",
                "listviewBtn",
                "listviewBasic",
              ],
              url
            );
          },
        },
        {
          path: "news",
          element: <News />,
          loader: ({ request }) => {
            const url = new URL(request.url);
            return getQueries(
              ["type", "page", "category", "listviewBtn", "listviewBasic", "calendar", "date"],
              url
            );
          },
        },
        {
          path: "feature",
          element: <Feature />,
          loader: ({ request }) => {
            const url = new URL(request.url);
            return getQueries(["page"], url);
          },
        },
        {
          path: "feature/detail/:id",
          element: <FeatureDetail />,
          loader: ({ request }) => {
            const url = new URL(request.url);
            return getQueries(["type", "page", "listviewBtn", "listviewBasic"], url);
          },
        },
        {
          path: "people",
          element: <People />,
          loader: ({ request }) => {
            const url = new URL(request.url);
            return getQueries(["type", "page", "category", "listviewBtn", "listviewBasic"], url);
          },
        },
        {
          path: "science",
          element: <Science />,
          loader: ({ request }) => {
            const url = new URL(request.url);
            return getQueries(["type", "page", "category", "listviewBtn", "listviewBasic"], url);
          },
        },
        {
          path: "detail/:id",
          element: <Detail />,
          loader: ({ request }) => {
            const url = new URL(request.url);
            return getQueries(["page"], url);
          },
        },
        {
          path: "feature/:id",
          element: <Detail />,
          loader: ({ request }) => {
            const url = new URL(request.url);
            return getQueries(["page"], url);
          },
        },
        {
          path: "science/:id",
          element: <Detail />,
          loader: ({ request }) => {
            const url = new URL(request.url);
            return getQueries(["page"], url);
          },
        },
        {
          path: "calendar/:id",
          element: <Detail />,
        },
        {
          path: "people/:id",
          element: <Detail />,
          loader: ({ request }) => {
            const url = new URL(request.url);
            return getQueries(["page"], url);
          },
        },
        {
          path: "news/:id",
          element: <Detail />,
          loader: ({ request }) => {
            const url = new URL(request.url);
            return getQueries(["page"], url);
          },
        },
        {
          path: "service",
          element: <Service />,
        },
        {
          path: "help",
          element: <Help />,
        },
        {
          path: "search",
          element: <Search />,
          loader: ({ request }) => {
            const url = new URL(request.url);
            return getQueries(["page", "searchKeyword"], url);
          },
        },
        {
          path: "login",
          element: <Login />,
          loader: ({ request }) => {
            const url = new URL(request.url);
            return getQueries(["naver"], url);
          },
        },
        {
          path: "signup",
          element: <SignUp />,
        },
        {
          path: "find/id",
          element: <FindId />,
        },
        {
          path: "find/pw",
          element: <FindPw />,
        },
        {
          path: "change/password",
          element: <ChangePassword />,
          loader: ({ request }) => {
            const url = new URL(request.url);
            return getQueries(["key"], url);
          },
        },
        {
          path: "newsletter",
          element: <Newsletter />,
          loader: ({ request }) => {
            const url = new URL(request.url);
            return getQueries(["category", "page", "year"], url);
          },
        },
        // 팝업 미리보기 페이지
      ],
    },
    {
      path: "/admin/popup/preview",
      element: <Home />,
    },
    {
      path: "/newsletter/:id",
      element: <NewsletterDetail />,
    },
    {
      path: isMobile ? "/" : "/mobile",
      element: (
        <>
          <MobileHeader />
          <Outlet />

          <MobileFooter />
        </>
      ),
      children: [
        {
          path: "",
          element: <MobileHome />,
          loader: ({ request }) => {
            const url = new URL(request.url);
            return getQueries(["hello"], url); // ?hello=world
          },
        },
        {
          path: "daily",
          element: <MobileDaily />,
          loader: ({ request }) => {
            const url = new URL(request.url);
            return getQueries(["page", "category", "date", "calendar"], url);
          },
        },
        {
          path: "calendar",
          element: <MobileCalendar />,
          loader: ({ request }) => {
            const url = new URL(request.url);
            return getQueries(
              [
                "page",
                "category",
                "date",
                "calendar",
                "searchKeyword",
                "type",
                "listviewBtn",
                "listviewBasic",
              ],
              url
            );
          },
        },
        {
          path: "news",
          element: <MobileNews />,
          loader: ({ request }) => {
            const url = new URL(request.url);
            return getQueries(
              ["type", "page", "category", "listviewBtn", "listviewBasic", "calendar", "date"],
              url
            );
          },
        },
        {
          path: "feature",
          element: <MobileFeature />,
          loader: ({ request }) => {
            const url = new URL(request.url);
            return getQueries(["page"], url);
          },
        },
        {
          path: "feature/detail/:id",
          element: <MobileFeatureDetail />,
          loader: ({ request }) => {
            const url = new URL(request.url);
            return getQueries(["type", "page", "listviewBtn", "listviewBasic"], url);
          },
        },
        {
          path: "people",
          element: <MobilePeople />,
          loader: ({ request }) => {
            const url = new URL(request.url);
            return getQueries(["type", "page", "category", "listviewBtn", "listviewBasic"], url);
          },
        },
        {
          path: "science",
          element: <MobileScience />,
          loader: ({ request }) => {
            const url = new URL(request.url);
            return getQueries(["type", "page", "category", "listviewBtn", "listviewBasic"], url);
          },
        },
        {
          path: "letter",
          element: <MobileNewsLetter />,
        },
        {
          path: "detail/:id",
          element: <MobileDetail />,
          loader: ({ request }) => {
            const url = new URL(request.url);
            return getQueries(["page"], url);
          },
        },
        {
          path: "science/:id",
          element: <MobileDetail />,
          loader: ({ request }) => {
            const url = new URL(request.url);
            return getQueries(["page"], url);
          },
        },
        {
          path: "calendar/:id",
          element: <MobileDetail />,
        },
        {
          path: "people/:id",
          element: <MobileDetail />,
          loader: ({ request }) => {
            const url = new URL(request.url);
            return getQueries(["page"], url);
          },
        },
        {
          path: "news/:id",
          element: <MobileDetail />,
          loader: ({ request }) => {
            const url = new URL(request.url);
            return getQueries(["page"], url);
          },
        },
        {
          path: "feature/:id",
          element: <MobileDetail />,
          loader: ({ request }) => {
            const url = new URL(request.url);
            return getQueries(["page"], url);
          },
        },
        {
          path: "service",
          element: <MobileService />,
        },
        {
          path: "help",
          element: <MobileHelp />,
        },
        {
          path: "search",
          element: <MobileSearch />,
          loader: ({ request }) => {
            const url = new URL(request.url);
            return getQueries(["page", "searchKeyword", "year"], url);
          },
        },
        {
          path: "login",
          element: <Login />,
          loader: ({ request }) => {
            const url = new URL(request.url);
            return getQueries(["naver"], url);
          },
        },
        {
          path: "signup",
          element: <MobileSignUp />,
        },
        {
          path: "find/id",
          element: <MobileFindId />,
        },
        {
          path: "find/pw",
          element: <MobileFindPw />,
        },
        {
          path: "change/password",
          element: <MobileChangePassword />,
          loader: ({ request }) => {
            const url = new URL(request.url);
            return getQueries(["key"], url);
          },
        },
        {
          path: "newsletter",
          element: <MobileNewsLetter />,
          loader: ({ request }) => {
            const url = new URL(request.url);
            return getQueries(["category", "page", "year"], url);
          },
        },
      ],
    },
    {
      path: `${adminDefaultUrl}/login`,
      element: <AdminLogin />,
    },
    // 뉴스레터 미리보기 페이지
    {
      path: "/admin/newsletter/management/preview",
      element: <NewsletterPreview />,
    },
    {
      path: adminDefaultUrl,
      element: (
        <ProtectedRoute>
          <AdminContainer>
            <SideBar />
            <Box
              flex="1 1 0"
              display={Box.DISPLAY.FLEX}
              flexDirection="column"
              height="100vh"
              overflow="scroll"
            >
              <AdminHeader />
              <Outlet />
            </Box>
          </AdminContainer>
        </ProtectedRoute>
      ),
      children: [
        // 로그인을 한 상태에서 /admin 접근 시 대시보드 페이지로 리다이렉트
        {
          path: "",
          element: <Navigate to={`${adminDefaultUrl}/dashboard`} replace />,
        },
        {
          path: "dashboard",
          element: <Dashboard />,
        },
        // 내 정보 관리
        {
          path: "my_info",
          element: <MyInfo />,
        },
        // 카테고리 관리
        {
          path: "management_category/:category",
          element: <ManagementCategory />,
        },
        // 카테고리 등록 및 수정
        {
          path: "management_category/:categoryName",
          element: <CategoryDetail />,
        },
        {
          path: "management_category/news/detail",
          element: <CategoryDetail />,
        },
        {
          path: "management_category/feature/detail",
          element: <CategoryDetail />,
        },
        {
          path: "management_category/people/detail",
          element: <CategoryDetail />,
        },
        {
          path: "management_category/science/detail",
          element: <CategoryDetail />,
        },
        {
          path: "management_category/calendar/detail",
          element: <CategoryDetail />,
        },
        {
          path: "management_category",
          element: <ManagementCategory />,
        },
        // 사이트 관리
        {
          path: "management_site/comment",
          element: <ManagementComment />,
        },
        // 팝업 관리
        {
          path: "management_site/popup",
          element: <ManagementPopup />,
        },
        // 배너 관리
        {
          path: "management_site/banner",
          element: <ManagementBanner />,
        },
        // 배너 관리 세부 페이지
        {
          path: "management_site/banner/:key",
          element: <BannerDetail />,
        },
        // 팝업 관리 세부 페이지
        {
          path: "management_site/popup/detail",
          element: <PopupDetail />,
        },
        // 운영자 관리
        {
          path: "management_manager/manager",
          element: <ManagementManager />,
        },
        // 운영자 관리 세부 페이지
        {
          path: "management/manager/detail",
          element: <ManagerDetail />,
        },
        // 권한 관리
        {
          path: "management_manager/authority",
          element: <ManagementAuthority />,
        },
        // 권한 관리 세부 페이지
        {
          path: "management_manager/authority/detail",
          element: <AuthorityDetail />,
        },
        // 운영자 작업 현황
        {
          path: "management_manager/workstatus",
          element: <ManagementWorkStatus />,
        },
        // 로그인 현황
        {
          path: "management_manager/loginlog",
          element: <ManagementLoginLog />,
        },
        // 과학기술 캘린더
        {
          path: "science/calendar",
          element: <CalendarScience />,
        },
        // 과학기술 캘린더 세부 페이지
        {
          path: "science/calendar/detail",
          element: <ScienceDetail />,
        },
        // 과총 동정
        {
          path: "schedule/coverage",
          element: <ScienceCoverage />,
        },
        // 과총동정 세부 페이지
        {
          path: "schedule/coverage/detail",
          element: <CoverageDetail />,
        },
        // 뉴스게시판 관리
        {
          path: "newsboard/:key",
          element: <ManagementNewsBoard />,
        },
        // 뉴스게시판 세부 페이지
        {
          path: "newsboard/:key/detail",
          element: <NewsBoardDetail />,
        },
        // 뉴스레터 관리
        {
          path: "newsletter/management",
          element: <ManagementNewsLetter />,
        },
        // 뉴스레터 세부 페이지
        {
          path: "newsletter/management/detail",
          element: <AdminNewsLetterDetail />,
        },

        // 뉴스레터 신청자
        {
          path: "newsletter/applier",
          element: <ManagementNewsletterApplier />,
        },
        // 뉴스레터 거부/변경
        {
          path: "newsletter/rejection",
          element: <ManagementNewsletterRejection />,
        },
        // 뉴스레터 배너
        {
          path: "newsletter/below_banner",
          element: <NewsletterBanner />,
        },
        // 뉴스레터 배너 상세
        {
          path: "newsletter/below_banner/detail",
          element: <NewsletterBannerDetail />,
        },
        // 뉴스레터 자동전달
        {
          path: "newsletter/auto_delivery",
          element: <NewsletterAutoDelivery />,
        },
        // 관리자에게
        {
          path: "customer_service/request",
          element: <CustomerServiceRequest />,
        },
        // 관리자에게 상세
        {
          path: "customer_service/request/detail",
          element: <RequestDetail />,
        },
        // 관리자에게 이메일
        {
          path: "customer_service/email",
          element: <UpdateAdminMail />,
        },
        // 회원관리
        {
          path: "member/membership",
          element: <ManagementMember />,
        },
        // 회원관리 상세
        {
          path: "member/membership/detail",
          element: <MemberDetail />,
        },
        // 회원 로그인 현황
        {
          path: "member/login_log",
          element: <MembershipLoginLog />,
        },
        // 탈퇴회원
        {
          path: "member/termination",
          element: <ManagementMemberTermination />,
        },
      ],
    },
    {
      path: "/prev/:id",
      element: <PrevNewsletter />,
    },
    {
      path: "/passwordMail",
      element: <PasswordMailDetail />,
    },
    {
      path: "unsubscribe",
      element: <Unsubscribe />,
      loader: ({ request }) => {
        const url = new URL(request.url);
        return getQueries(["key"], url);
      },
    },
    {
      path: "kofstNewsLetterTmpltPop.do",
      element: <Redirect />,
      loader: ({ request }) => {
        const url = new URL(request.url);
        return getQueries(["seq"], url);
      },
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ]);
};

export default createRouter;
