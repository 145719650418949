import styled from "styled-components";

export const AdminLoginContainer = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
`;

export const LoginContainer = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${(props) =>
    props.isMobile ? "137px 62px 200px" : "137px 0 200px"};
`;

export const MobileLoginContainer = styled(LoginContainer)`
  padding: 167px 0 210px;
`;

export const LoginBox = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: 327px;
  margin: 0 auto;
`;
