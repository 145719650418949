import Box from "components/atoms/box";
import Text from "components/atoms/text";
import React from "react";
import AdminTable from "../adminTable";
import PopupTable from "components/molecules/popupTable";
import useContextForm from "hooks/utils/useContextForm";
import useUrl from "hooks/utils/useUrl";
import { useSitePopupList } from "hooks/api/admin/management_site";
import { useState } from "react";
import { site } from "text/admin/site";

const AdminPopupList = (props) => {
  const { searchValue } = props;

  const { title } = site.popup;

  const {
    path,
    query: { page },
  } = useUrl();

  const categoryName = path[2];

  const [selectedMenu, setSelectedMenu] = useState("10");

  const defaultValues = {
    itemsPerPage: "10",
  };

  const { Provider } = useContextForm(defaultValues);

  return (
    <Box maxWidth="1024px" display={Box.DISPLAY.FLEX} flexDirection="column" margin="40px 0 0 0">
      <Text as={Text.VARIANT.SUBTITLE} ariaLabel={title} margin="0 0 10px 0">
        {title}
      </Text>
      <Provider>
        <AdminTable
          type={["countBar", "changeOrder"]}
          hasSelectMenu={true}
          //
          api={useSitePopupList}
          categoryName={categoryName}
          //
          page={page}
          itemsPerPage={selectedMenu}
          searchValue={searchValue}
          //
          selectedMenu={selectedMenu}
          setSelectedMenu={setSelectedMenu}
          width="120px"
        >
          <PopupTable />
        </AdminTable>
      </Provider>
    </Box>
  );
};

export default AdminPopupList;
