import Box from "components/atoms/box";
import Button from "components/atoms/button";
import Slider from "components/atoms/slider";
import { updateDate } from "utils/calendar";
import useUrl from "hooks/utils/useUrl";
import React, { useRef, useState } from "react";
import dayjs from "dayjs";

const MobileDailyHeader = (props) => {
  const { calendarDate } = props;
  const {
    query: { date, calendar = dayjs() },
    changeQuery,
  } = useUrl();

  const headList = calendarDate.headListForm(dayjs(calendar), 10);

  headList.unshift(null);
  headList.push(null);

  const selectDate = date ?? headList[1];

  const sliderRef = useRef();
  const [activeSlide, setActiveSlide] = useState(
    date ? headList.indexOf(date) : 0
  );

  return (
    <Slider
      slidesPerView={"auto"}
      spaceBetween={16}
      simulateTouch={false}
      setActiveIndex={setActiveSlide}
      sliderRef={sliderRef}
      initialSlide={activeSlide}
      isTest
    >
      {headList.map((link, idx) => {
        const info = updateDate(link, 0, calendarDate.picker);
        const isActive = link === date || (!date && idx === 1);
        const isLight =
          idx > activeSlide + calendarDate.light && link < selectDate;

        return (
          <Slider.Slide key={idx}>
            {link ? (
              <Button
                themeStyle={Button.THEME.NONE}
                padding={isActive ? "2px 10px" : ""}
                bgColor={
                  isActive ? Button.COLOR.SECONDARY : Button.COLOR.LIGHTGRAY
                }
                borderRadius={isActive ? "20px" : "0"}
                color={
                  isActive
                    ? Button.COLOR.WHITE
                    : isLight
                    ? Button.COLOR.LINEGRAY
                    : Button.COLOR.TEXTGRAY
                }
                fontSize={Button.FONT_SIZE.MOBILECATEGORY}
                onClick={() =>
                  changeQuery({
                    date: link,
                    calendar: info.calendar,
                    page: "1",
                  })
                }
              >
                {dayjs(link).format(calendarDate.headerFormat)}
              </Button>
            ) : (
              <Box width={"24px"} height={"1px"}></Box>
            )}
          </Slider.Slide>
        );
      })}
    </Slider>
  );
};

export default MobileDailyHeader;
