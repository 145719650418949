import { adminDefaultUrl } from "api/admin/url";
import Box from "components/atoms/box";
import Button from "components/atoms/button";
import Text from "components/atoms/text";
import { useLogoutMutation } from "hooks/api/auth";
import useUrl from "hooks/utils/useUrl";
import React, { useState } from "react";
import { useEffect } from "react";
import { common } from "text/admin/common";

const AdminProfile = (props) => {
  const { setIsOpen } = props;

  const {
    default: { logout: logoutRequest },
    success: { logout: logoutSuccess },
    failed: { logout: logoutFailed },
  } = common.alert;

  const [currUserName, setCurrUserName] = useState("");

  const { changeLink } = useUrl();

  const handleSuccessLogout = () => {
    window.alert(logoutSuccess);
    sessionStorage.removeItem("userName");
    sessionStorage.removeItem("authority");
    changeLink(`${adminDefaultUrl}/login`);
  };

  const handleFailLogout = () => {
    window.alert(logoutFailed);
  };

  const logoutMutate = useLogoutMutation(handleSuccessLogout, handleFailLogout);

  const handleUpdateMyInfo = () => {
    changeLink(`${adminDefaultUrl}/my_info`);
    setIsOpen(false);
  };

  const handleLogout = () => {
    const confirm = window.confirm(logoutRequest);
    if (confirm) logoutMutate();
  };

  const buttonList = [
    { title: "내정보 수정", onClick: handleUpdateMyInfo },
    { title: "로그아웃", onClick: handleLogout },
  ];

  useEffect(() => {
    const storedUser = sessionStorage.getItem("userName");

    if (storedUser) {
      const user = JSON.parse(storedUser);
      setCurrUserName(user);
    }
  }, []);

  return (
    <Box
      width="165px"
      padding="10px"
      position="absolute"
      top="50px"
      right="12px"
      zIndex="9999"
      bgColor={Box.COLOR.WHITE}
      border="1px solid"
      borderColor={Box.COLOR.ADMINLINEGRAY}
    >
      <Box padding="5px" display={Box.DISPLAY.FLEX} flexDirection="column" gap="5px">
        <Text as={Text.VARIANT.TEXT} size={Text.SIZE.ADMINETC} ariaLabel="중간관리자">
          중간관리자
        </Text>
        <Text
          as={Text.VARIANT.TEXT}
          size={Text.SIZE.CLIENTCONTENTDETAIL}
          weight={Text.WEIGHT.BOLD}
          ariaLabel={currUserName}
        >
          {currUserName}
        </Text>
      </Box>
      <Box width="100%" height="1px" bgColor={Box.COLOR.ADMINLINEGRAY} margin="15px 0 8px 0"></Box>
      <Box display={Box.DISPLAY.FLEX} flexDirection="column" gap="5px">
        {buttonList.map(({ title, onClick }, idx) => (
          <Button
            width="100%"
            padding="8px 14px"
            border="1px solid"
            borderColor={Button.COLOR.LINEGRAY}
            borderRadius={Button.RADIUS.SMALL}
            bgColor={Button.COLOR.TRANSPARENT}
            color={Button.COLOR.SECONDARY}
            onClick={onClick}
            key={idx}
          >
            {title}
          </Button>
        ))}
      </Box>
    </Box>
  );
};

export default AdminProfile;
