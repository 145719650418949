import Box from "components/atoms/box";
import Text from "components/atoms/text";
import useContextForm from "hooks/utils/useContextForm";
import React from "react";
import AdminTable from "../adminTable";
import { useManagerWorkStatusList } from "hooks/api/admin/manager";
import { useState } from "react";
import StatusTable from "components/molecules/statusTable";
import useUrl from "hooks/utils/useUrl";
import { management } from "text/admin/management";

const AdminWorkStatusList = (props) => {
  const {
    filterData: { cate1, cate2, currOption, keyword, startDate, endDate, searchCondition },
  } = props;

  const { title, tableHeader, defaultValues } = management.status.table;

  const {
    query: { page },
  } = useUrl();

  const [selectedMenu, setSelectedMenu] = useState("10");

  const { Provider } = useContextForm(defaultValues);

  return (
    <Box maxWidth="1024px" display={Box.DISPLAY.FLEX} flexDirection="column" margin="40px 0 0 0">
      <Text as={Text.VARIANT.SUBTITLE} ariaLabel={title} margin="0 0 10px 0">
        {title}
      </Text>
      <Provider>
        <AdminTable
          type={["countBar"]}
          hasSelectMenu={true}
          //
          api={useManagerWorkStatusList}
          tableHeader={tableHeader}
          searchValue={keyword}
          searchCondition={searchCondition}
          page={page}
          //
          cate1={cate1}
          cate2={cate2}
          currOption={currOption}
          startDate={startDate}
          endDate={endDate}
          //
          title={title}
          //
          selectedMenu={selectedMenu}
          setSelectedMenu={setSelectedMenu}
          width="120px"
        >
          <StatusTable />
        </AdminTable>
      </Provider>
    </Box>
  );
};

export default AdminWorkStatusList;
