import Loading from "components/atoms/loading";
import BannerBtnGroup from "components/molecules/bannerBtnGroup";
import BannerType from "components/molecules/bannerType";
import BannerList from "components/organisms/bannerList";
import { AdminContentsContainer } from "components/templates/admin/admin.style";
import { useSiteBannerList } from "hooks/api/admin/management_site";
import React, { useState } from "react";

const ManagementBanner = () => {
  const [currCategory, setCurrCategory] = useState("slide");
  const { list, isLoading, isSuccess, form } = useSiteBannerList(currCategory);
  const [newList, setNewList] = useState([]);

  return (
    <AdminContentsContainer>
      <BannerBtnGroup
        list={list}
        setNewList={setNewList}
        setCurrCategory={setCurrCategory}
        currCategory={currCategory}
      />
      {form && <BannerType defaultType={form} />}
      {isLoading && <Loading />}
      {isSuccess && (
        <BannerList
          list={list}
          isLoading={isLoading}
          newList={newList}
          setNewList={setNewList}
          currCategory={currCategory}
          snsForm={form}
        />
      )}
    </AdminContentsContainer>
  );
};

export default ManagementBanner;
