import styled, { css } from "styled-components";

const defaultStyle = {
  h1: css`
    font-weight: ${(props) => props.theme.weight.bold};
  `,
  h2: css`
    font-weight: ${(props) => props.theme.weight.bold};
  `,
  p: css``,
  span: css`
    display: inline-block;
  `,
};

const themeStyle = {
  underline: css`
    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      margin-top: 2px;
      background-color: ${(props) => props.theme.color.secondary};
    }
  `,
};

const sort = css`
  display: inline-flex;
  align-items: center;
`;

export const TextComponent = styled.div`
  ${(props) => defaultStyle[props.as]}
  ${(props) => themeStyle[props.themeStyle]}

  ${(props) => props.isSort && sort}

  position: ${(props) => props.position};
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  right: ${(props) => props.right};
  bottom: ${(props) => props.bottom};

  width: ${(props) => props.$width};
  max-width: ${(props) => props.maxWidth};
  height: ${(props) => props.$height};

  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};

  background-color: ${(props) => props.theme.color[props.bgColor]};

  border: ${(props) => props.border};
  border-top: ${(props) => props.borderTop};
  border-bottom: ${(props) => props.borderBottom};
  border-left: ${(props) => props.borderLeft};
  border-right: ${(props) => props.borderRight};

  border-radius: ${(props) =>
    props.theme.radius[props.borderRadius] ?? props.borderRadius};
  border-color: ${(props) => props.theme.color[props.borderColor]};

  color: ${(props) => props.theme.color[props.$color]};
  font-size: ${(props) => props.theme.font[props.size]};
  font-weight: ${(props) => props.theme.weight[props.weight]};
  line-height: ${(props) => props.lineHeight};

  text-align: ${(props) => props.textAlign};

  word-break: ${(props) => props.wordBreak};
  white-space: ${(props) => props.whiteSpace};

  text-decoration: ${(props) => props.decoration};
  cursor: ${(props) => props.$cursor};

  ${(props) =>
    props.lineClamp &&
    css`
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: ${props.lineClamp};
      display: -moz-box;
      -moz-box-orient: vertical;
      -moz-line-clamp: ${props.lineClamp};
      display: -ms-box;
      -ms-box-orient: vertical;
      -ms-line-clamp: ${props.lineClamp};
    `}
`;
