import Box from "components/atoms/box";
import Text from "components/atoms/text";
import useContextForm from "hooks/utils/useContextForm";
import React from "react";
import AdminTable from "../adminTable";
import { useState } from "react";
import useUrl from "hooks/utils/useUrl";
import { useNewsBoardList } from "hooks/api/admin/news_board";
import NewsBoardTable from "components/molecules/newsBoardTable";
import { newsboard } from "text/admin/newsboard";

const AdminNewsBoardList = (props) => {
  const {
    filterData: { cate1, cate2, searchCondition, keyword },
  } = props;

  const { tableHeader, defaultValues, title } = newsboard.table;

  const {
    path,
    query: { page },
  } = useUrl();

  const categoryName = path[2];

  const [selectedMenu, setSelectedMenu] = useState("10");

  const { Provider } = useContextForm(defaultValues);

  return (
    <Box maxWidth="1024px" display={Box.DISPLAY.FLEX} flexDirection="column" margin="40px 0 0 0">
      <Text as={Text.VARIANT.SUBTITLE} ariaLabel={title} margin="0 0 10px 0">
        {title}
      </Text>
      <Provider>
        <AdminTable
          type={["countBar"]}
          hasSelectMenu={true}
          //
          api={useNewsBoardList}
          tableHeader={tableHeader}
          searchValue={keyword}
          searchCondition={searchCondition}
          page={page}
          //
          categoryName={categoryName}
          cate1={cate1}
          cate2={cate2}
          //
          selectedMenu={selectedMenu}
          setSelectedMenu={setSelectedMenu}
          width="120px"
        >
          <NewsBoardTable />
        </AdminTable>
      </Provider>
    </Box>
  );
};

export default AdminNewsBoardList;
