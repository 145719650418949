import Box from "components/atoms/box";
import Button from "components/atoms/button";
import Text from "components/atoms/text";
import { AdminContentsContainer } from "components/templates/admin/admin.style";
import {
  useGetNewsletterAutoDelivery,
  useUpdateNewsletterAutoDelivery,
} from "hooks/api/admin/news_letter";
import React, { Fragment, useEffect, useState } from "react";
import { common } from "text/admin/common";
import { newsletter } from "text/admin/newsletter";

const NewsletterAutoDelivery = () => {
  const { title, buttonList } = newsletter.comment;
  const { automail } = common.alert;

  const { autoDeliveryState } = useGetNewsletterAutoDelivery();

  const [isAutoDelivery, setIsAutoDelivery] = useState(null);

  useEffect(() => {
    setIsAutoDelivery(autoDeliveryState);
  }, [autoDeliveryState]);

  const mutate = useUpdateNewsletterAutoDelivery();

  const handleCommentOnOff = (e) => {
    setIsAutoDelivery(e.target.value);
  };

  const handleCommentStatus = () => {
    const data = {
      onoff: isAutoDelivery,
    };
    const confirm = window.confirm(automail);
    if (confirm) mutate({ data });
  };

  return (
    <AdminContentsContainer>
      <Box display={Box.DISPLAY.FLEX} al="center" gap="5px" width="403px" margin="0 0 10px 0">
        <Text as={Text.VARIANT.SUBTITLE}>{title}</Text>
      </Box>
      <Box
        height="98px"
        maxWidth="1024px"
        display={Box.DISPLAY.FLEX}
        al="center"
        border="1px solid"
        borderColor={Box.COLOR.ADMINLINEGRAY}
      >
        <Box padding="0 0 0 24px" width="403px">
          <Text as={Text.VARIANT.TEXT} weight={Text.WEIGHT.BOLD} size={Text.SIZE.ADMINCONTENT}>
            {title}
          </Text>
        </Box>
        <Box
          flex="1 1 0"
          bgColor={Box.COLOR.WHITE}
          height="100%"
          display={Box.DISPLAY.FLEX}
          al="center"
          gap="10px"
          padding="0 0 0 37px"
        >
          {buttonList.map(({ title, ariaLabel, value }, idx) => (
            <Fragment key={idx}>
              <Button
                width="67px"
                height="29px"
                sort="center"
                themeStyle="adminCommon"
                value={value}
                onClick={handleCommentOnOff}
                fontSize={Button.FONT_SIZE.ADMINCONTENTDETAIL}
                bgColor={isAutoDelivery === value && "#3A47B833"}
                borderColor={isAutoDelivery === value && Button.COLOR.SECONDARY}
                fontWeight={isAutoDelivery === value ? Button.FONT_WEIGHT.BOLD : undefined}
                color={isAutoDelivery === value ? Button.COLOR.SECONDARY : undefined}
                ariaLabel={ariaLabel}
              >
                {title}
              </Button>
            </Fragment>
          ))}
        </Box>
      </Box>
      <Box display={Box.DISPLAY.FLEX} al="center" jc="flex-end" maxWidth="1024px">
        <Button
          themeStyle="adminSave"
          padding="12px 40px"
          margin="40px 0 0 0"
          onClick={handleCommentStatus}
        >
          저장
        </Button>
      </Box>
    </AdminContentsContainer>
  );
};

export default NewsletterAutoDelivery;
