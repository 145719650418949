import React, { useEffect, useState } from "react";
import propsTypes from "prop-types";
import { ImageComponent } from "./image.style";
import { COLOR, RADIUS } from "styles/theme";
import { forwardRef } from "react";

const Image = forwardRef((props, ref) => {
  const {
    src,
    alt,
    fallback = "/assets/common/none.png",
    width,
    height,
    margin,
    radius,
    color,
    //
    fit,
    aspectRatio,
    ariaLabel,
    //
    onLoad,
  } = props;

  const [imgSrc, setImgSrc] = useState(src ?? fallback);

  const handleError = (e) => {
    setImgSrc(fallback);
  };

  useEffect(() => {
    setImgSrc(src ?? fallback);
  }, [src, fallback]);

  return (
    <ImageComponent
      ref={ref}
      src={imgSrc}
      onError={handleError}
      alt={alt}
      //
      $width={width}
      $height={height}
      margin={margin}
      $radius={radius}
      color={color}
      //
      fit={fit}
      aspectRatio={aspectRatio}
      //
      onLoad={onLoad}
    />
  );
});

Image.RADIUS = RADIUS;
Image.COLOR = COLOR;

Image.propsTypes = {
  /**
   * Image source
   */
  src: propsTypes.string.isRequired,
  /**
   * Image alt
   */
  alt: propsTypes.string.isRequired,
  /**
   * Image width
   */
  width: propsTypes.string,
  /**
   * Image height
   */
  height: propsTypes.string,
  /**
   * Image radius
   * use Image.RADIUS
   */
  radius: propsTypes.oneOf(Object.values(RADIUS)),
  /**
   * Image aspectRatio
   * ex) 16/9
   * ex) 1/1
   * ex) 4/3
   */
  aspectRatio: propsTypes.string,
  /**
   * Image aria-label
   */
  ariaLabel: propsTypes.string,
};

export default Image;
