import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { TextComponent } from "./text.style";
import { COLOR, FONT_SIZE, FONT_WEIGHT, RADIUS } from "styles/theme";

const VARIANT = {
  TITLE: "h1",
  SUBTITLE: "h2",
  TEXT: "p",
  SPAN: "span",
};

const Text = forwardRef((props, ref) => {
  const {
    as,
    children,
    //
    isSort,
    //
    width,
    maxWidth,
    height,
    //
    position,
    top,
    left,
    right,
    bottom,
    //
    margin,
    padding,
    bgColor,
    //
    border,
    borderTop,
    borderBottom,
    borderLeft,
    borderRight,
    borderRadius,
    borderColor,
    //
    color,
    size,
    weight,
    lineHeight,
    lineClamp,
    wordBreak,
    whiteSpace,
    textAlign,
    //
    themeStyle,
    //
    decoration,
    //
    ariaLabel,
    //
    cursor,
    onClick,
  } = props;

  return (
    <TextComponent
      as={as}
      ref={ref}
      //
      isSort={isSort}
      //
      position={position}
      top={top}
      left={left}
      right={right}
      bottom={bottom}
      //
      $width={width}
      maxWidth={maxWidth}
      $height={height}
      //
      margin={margin}
      padding={padding}
      bgColor={bgColor}
      //
      border={border}
      borderTop={borderTop}
      borderBottom={borderBottom}
      borderLeft={borderLeft}
      borderRight={borderRight}
      borderRadius={borderRadius}
      borderColor={borderColor}
      //
      $color={color}
      size={size}
      weight={weight}
      lineHeight={lineHeight}
      lineClamp={lineClamp}
      wordBreak={wordBreak}
      whiteSpace={whiteSpace}
      textAlign={textAlign}
      //
      themeStyle={themeStyle}
      //
      decoration={decoration}
      //
      $cursor={cursor}
      onClick={onClick}
    >
      {children}
    </TextComponent>
  );
});

Text.VARIANT = VARIANT;
Text.COLOR = COLOR;
Text.RADIUS = RADIUS;
Text.SIZE = FONT_SIZE;
Text.WEIGHT = FONT_WEIGHT;

Text.propsTypes = {
  /**
   * The content of the component.
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,

  /**
   * The Text variant to use.
   * use Text.VARIANT
   */
  as: PropTypes.oneOf(Object.values(VARIANT)).isRequired,

  /**
   * The position of the component.
   */
  position: PropTypes.oneOf(["relative", "absolute", "fixed"]),
  /**
   * The top of the component.
   * ex) "10px"
   */
  top: PropTypes.string,
  /**
   * The left of the component.
   * ex) "10px"
   */
  left: PropTypes.string,
  /**
   * The right of the component.
   * ex) "10px"
   */
  right: PropTypes.string,
  /**
   * The bottom of the component.
   * ex) "10px"
   */
  bottom: PropTypes.string,

  /**
   * The width of the component.
   * ex) "100px"
   */
  width: PropTypes.string,
  /**
   * The height of the component.
   * ex) "100px"
   */
  height: PropTypes.string,

  /**
   * The margin of the component.
   * ex) "10px 10px 0 10px"
   * ex) "10px 10px"
   * ex) "10px"
   */
  margin: PropTypes.string,
  /**
   * The padding of the component.
   * ex) "10px 10px 0 10px"
   * ex) "10px 10px"
   * ex) "10px"
   */
  padding: PropTypes.string,
  /**
   * The bgColor of the component.
   * use Box.COLOR
   */
  bgColor: PropTypes.oneOf(Object.values(COLOR)),

  /**
   * The border of the component.
   * ex) "1px solid #000"
   */
  border: PropTypes.string,
  /**
   * The borderTop of the component.
   * ex) "1px solid #000"
   */
  borderTop: PropTypes.string,
  /**
   * The borderBottom of the component.
   * ex) "1px solid #000"
   */
  borderBottom: PropTypes.string,
  /**
   * The borderLeft of the component.
   * ex) "1px solid #000"
   */
  borderLeft: PropTypes.string,
  /**
   * The borderRight of the component.
   * ex) "1px solid #000"
   */
  borderRight: PropTypes.string,
  /**
   * The borderRadius of the component.
   * use Box.RADIUS
   * ex) "10px"
   */
  borderRadius: PropTypes.oneOfType([
    PropTypes.oneOf(Object.values(RADIUS)),
    PropTypes.string,
  ]),
  /**
   * The borderColor of the component.
   * use Box.COLOR
   */
  borderColor: PropTypes.oneOf(Object.values(COLOR)),

  /**
   * The borderColor of the component.
   * use Box.COLOR
   */
  color: PropTypes.oneOf(Object.values(COLOR)),

  /**
   * The fontSize of the component.
   */
  size: PropTypes.oneOf(Object.values(FONT_SIZE)),
  /**
   * The fontWeight of the component.
   * use Box.WEIGHT
   */
  weight: PropTypes.oneOf(Object.values(FONT_WEIGHT)),
  /**
   * The lineClamp of the component.
   * ex) 1
   */
  lineClamp: PropTypes.number,

  /**
   * The wordBreak of the component.
   */
  wordBreak: PropTypes.string,
};

export default Text;
