import React from "react";
import Box from "components/atoms/box";
import Text from "components/atoms/text";
import NewsList from "components/organisms/newsList";
import ScienceCalendar from "components/organisms/scienceCalendar";
import { HomeContainer } from "components/templates/home/home.style";
import Button from "components/atoms/button";
import List from "components/molecules/list";
import {
  useBreakingNews,
  useBroadCastNews,
  useFeatureNews,
  useHeadLineNews,
  useIctNews,
  usePeopleNews,
  usePreFetchBanner,
  useScienceNews,
  useTodayNews,
} from "hooks/api/news";
import { useGroupMainNotice, useScienceMainNotice } from "hooks/api/notice";
import MainBanner from "components/organisms/mainBanner";
import NewsLetterForm from "components/organisms/newsLetterForm";
import NewsLetter from "components/organisms/newsLetter";
import Banner from "components/molecules/banner";
import Link from "components/atoms/link";
import Image from "components/atoms/image";
import { useGetBanner, useTopBanner } from "hooks/api/banner";
import Popup from "components/organisms/popup";
import convertImage from "utils/convertImage";
import useCategory from "hooks/utils/useCategory";
import { usePopup } from "hooks/api/popup";
import text from "text/home";
import useUrl from "hooks/utils/useUrl";

const TOP_BANNER = [useScienceMainNotice, useGroupMainNotice, useBreakingNews, useTodayNews];

const BOTTOM_BANNER = [useScienceNews, usePeopleNews, useFeatureNews, useIctNews];

const { topCategory, bottomCategory, newsTitle } = text;

const Home = () => {
  const [topBannerCategory, setTopBannerCategory, topBannerListApi] = useCategory(TOP_BANNER);
  const [bottomBannerCategory, setBottomBannerCategory, bottomBannerListApi] =
    useCategory(BOTTOM_BANNER);

  const { list, isSuccess } = usePopup();

  const {
    query: { isAdmin, path, period, newWindow },
  } = useUrl();

  usePreFetchBanner();

  return (
    <HomeContainer>
      <List api={useTopBanner} params={{}}>
        {(list) => <MainBanner bannerList={list[0]} coverageNews={list[1]} />}
      </List>

      <Box
        as="section"
        //
        width={"1260px"}
        bgColor={Box.COLOR.LIGHTGRAY}
        borderRadius="10px"
        padding={"32px 40px"}
        margin={"40px 0"}
        ariaLabel={"주요 News 영역"}
      >
        <NewsList
          title={newsTitle.headline.title}
          bold={newsTitle.headline.bold}
          sideBar={() => <NewsList.Link link={"/news?category=all"} />}
        >
          <List api={useHeadLineNews} isBorderTop>
            {(list) => list && <NewsList.LAYOUT.HeadLineNews list={list} />}
          </List>
        </NewsList>
      </Box>

      <Box
        as="section"
        //
        display={Box.DISPLAY.FLEX}
        jc={"space-between"}
        width={"1260px"}
        //
        bgColor={Box.COLOR.LIGHTGRAY}
        borderRadius="10px"
        padding={"10px 40px 68px 0"}
        margin={"40px 0 0"}
        ariaLabel={"상단 카테고리 뉴스 영역"}
      >
        <Box width={"30%"} position={"relative"} ariaLabel={"카테고리 선택 영역"}>
          {topCategory.map(({ title, link }, idx) => {
            const isActive = idx === topBannerCategory;

            return (
              <Button
                themeStyle={Button.THEME.CATEGORY}
                isActive={isActive}
                size={Button.SIZE.FULL}
                padding={"0 60px"}
                position={"relative"}
                //
                onClick={() => setTopBannerCategory(idx)}
                key={title}
                ariaLabel={`${title} 카테고리 선택`}
              >
                <Text
                  width={"100%"}
                  as={Text.VARIANT.SPAN}
                  borderTop={idx === "0" ? "1px solid" : ""}
                  borderBottom={!isActive ? "1px solid" : ""}
                  borderColor={Box.COLOR.LINEGRAY}
                  textAlign={"left"}
                  padding={"20px 0 22px 40px"}
                  ariaLabel={`${title} 카테고리 선택`}
                >
                  {title}
                </Text>

                {isActive && (
                  <Text
                    as={Text.VARIANT.SPAN}
                    position="absolute"
                    top="-1px"
                    left="-20px"
                    width="calc(100% + 20px)"
                    height="calc(100% + 1px)"
                    padding="20px 0 22px 120px"
                    bgColor={Box.COLOR.PRIMARY}
                    borderRadius={"0 20px 0 20px"}
                    textAlign={"left"}
                    ariaLabel={`${title} 카테고리 선택`}
                  >
                    {title}
                  </Text>
                )}
              </Button>
            );
          })}
        </Box>

        <Box width={"63%"} ariaLabel={`${topCategory[topBannerCategory].title} 리스트 영역`}>
          <Box
            display={Box.DISPLAY.FLEX}
            jc={"flex-end"}
            al={"center"}
            padding={"23px 0"}
            ariaLabel={"더보기 영역"}
          >
            <NewsList.Link link={topCategory[topBannerCategory].link} />
          </Box>
          <List api={topBannerListApi} isBorderTop>
            {(list) =>
              list &&
              list.map((data, idx) => (
                <Link
                  link={`/${topCategory[topBannerCategory].root}/${data.key}${
                    data.cate2 && `?category=${data.cate2}`
                  }`}
                  key={data.key}
                  width={"100%"}
                >
                  <Box
                    display={Box.DISPLAY.FLEX}
                    jc="center"
                    flexDirection="column"
                    borderTop={idx === 0 ? "1px solid" : ""}
                    borderBottom={"1px solid"}
                    borderColor={Box.COLOR.LINEGRAY}
                    padding={"20px 0"}
                    height={"90px"}
                    ariaLabel={`${data.title} 뉴스 링크`}
                  >
                    <Text
                      as={Text.VARIANT.SUBTITLE}
                      ariaLabel={data.title}
                      lineClamp={1}
                      lineHeight={"22px"}
                      size={Text.SIZE.CLIENTNORMAL}
                    >
                      {data.title}
                    </Text>
                  </Box>
                </Link>
              ))
            }
          </List>
        </Box>
      </Box>

      <Box
        as="section"
        //
        width={"1260px"}
        margin={"100px 0"}
        borderRadius="10px"
        overflow="hidden"
        ariaLabel={"중간 배너 영역"}
      >
        <List api={useGetBanner} params={{ type: "middle" }} isBorderTop>
          {(list) =>
            list && (
              <Banner imageList={list} bottom={"12px"} simulateTouch>
                {(imageList) =>
                  imageList.map(({ filePath, key, url }, idx) => (
                    <Banner.Slide key={key}>
                      <Box
                        aspectRatio={"1260/130"}
                        overflow="hidden"
                        ariaLabel={`${idx + 1} 번째 중간 배너 이미지`}
                      >
                        <Link width={"100%"} link={url} target="_blank">
                          <Image
                            src={convertImage(filePath)}
                            alt="banner"
                            width="100%"
                            height="100%"
                            fit="cover"
                            ariaLabel={`${idx + 1} 번째 중간 배너 이미지`}
                          />
                        </Link>
                      </Box>
                    </Banner.Slide>
                  ))
                }
              </Banner>
            )
          }
        </List>
      </Box>

      <Box
        as="section"
        //
        display={Box.DISPLAY.FLEX}
        jc={"space-between"}
        width={"1260px"}
        ariaLabel={"지상 중계 / 과학 기술 일정 영역"}
      >
        <Box
          width={"calc((100% - 42px) / 2 )"}
          bgColor={Box.COLOR.LIGHTGRAY}
          borderRadius="10px"
          padding={"32px 40px"}
          ariaLabel={"지상 중계 뉴스 영역"}
        >
          <NewsList
            title={newsTitle.broadCast.title}
            sideBar={() => <NewsList.Link link={"/news?category=COM045_7DaOeSe"} />}
          >
            <List api={useBroadCastNews} isBorderTop>
              {(list) =>
                list && <NewsList.LAYOUT.BroadCastNews list={list.slice(0, 3)} category={"news"} />
              }
            </List>
          </NewsList>
        </Box>

        <Box
          as="section"
          //
          width={"calc((100% - 42px) / 2 )"}
          bgColor={Box.COLOR.LIGHTGRAY}
          borderRadius="10px"
          padding={"32px 40px"}
          ariaLabel={`과학기술 일정 영역`}
        >
          <Text as={Text.VARIANT.TITLE} size={Text.SIZE.CLIENTTITLE} ariaLabel={`과학기술 일정`}>
            {newsTitle.scienceCalendar.title}
          </Text>
          <ScienceCalendar />
        </Box>
      </Box>

      <Box
        as="section"
        //
        width={"1260px"}
        bgColor={Box.COLOR.LIGHTGRAY}
        borderRadius="10px"
        padding={"32px 40px"}
        margin={"40px 0 0"}
        ariaLabel={"하단 카테고리 뉴스 영역"}
      >
        <Box display={Box.DISPLAY.FLEX} flexDirection="column" ariaLabel={"카테고리 선택 영역"}>
          <Box
            width={"100%"}
            bgColor={Box.COLOR.GRAY}
            borderRadius={"0 20px"}
            margin={"0 0 23px"}
            ariaLabel={"카테고리 선택 영역"}
          >
            {bottomCategory.map(({ title }, idx) => {
              return (
                <Button
                  key={title}
                  themeStyle={Button.THEME.CATEGORY}
                  width={"25%"}
                  isActive={idx === bottomBannerCategory}
                  onClick={() => setBottomBannerCategory(idx)}
                  //
                  ariaLabel={`${title} 선택 버튼`}
                >
                  <Text as={Text.VARIANT.SPAN} position="relative" ariaLabel={`${title} 선택 버튼`}>
                    {title}
                  </Text>
                </Button>
              );
            })}
          </Box>

          <NewsList
            title={""}
            sideBar={() => <NewsList.Link link={bottomCategory[bottomBannerCategory].link} />}
          >
            <List api={bottomBannerListApi} isBorderTop>
              {(list) =>
                list && (
                  <NewsList.LAYOUT.ThumbnailNews
                    list={list}
                    category={bottomCategory[bottomBannerCategory].root}
                  />
                )
              }
            </List>
          </NewsList>
        </Box>
      </Box>

      <Box
        as="section"
        //
        width={"1260px"}
        height={"131px"}
        margin={"100px 0 130px"}
        ariaLabel={"하단 SNS 배너 영역"}
      >
        <List api={useGetBanner} params={{ type: "sns" }} isBorderTop>
          {(list) =>
            list && (
              <Banner imageList={list} slidesPerView={list.length} spaceBetween={10}>
                {(imageList) => {
                  const isMaxBanner = imageList.length === 3;

                  return imageList.map(({ key, filePath, url }, idx) => (
                    <Banner.Slide key={key}>
                      <Box
                        aspectRatio={isMaxBanner ? "413/131" : "307/131"}
                        overflow="hidden"
                        borderRadius={Box.RADIUS.NORMAL}
                      >
                        <Link width={"100%"} height={"131px"} link={url} target="_blank">
                          <Image
                            src={convertImage(filePath)}
                            alt="banner"
                            width={isMaxBanner ? "413px" : "307px"}
                            height={"131px"}
                            ariaLabel={`${idx + 1} 번째 하단 SNS 배너 이미지`}
                          />
                        </Link>
                      </Box>
                    </Banner.Slide>
                  ));
                }}
              </Banner>
            )
          }
        </List>
      </Box>

      <Box
        display={Box.DISPLAY.FLEX}
        jc={"space-between"}
        width={"1260px"}
        margin={"0 0 200px"}
        ariaLabel="뉴스레터 영역"
      >
        <NewsLetterForm />
        <NewsLetter />
      </Box>

      {isSuccess &&
        !isAdmin &&
        list.map(({ url, filePath, contents, newWindow, key }, idx) => (
          <Popup
            position={`${(idx + 1) * 50}px`}
            link={url}
            image={filePath}
            type={contents}
            newWindow={newWindow}
            key={key}
            id={key}
          />
        ))}
      {isAdmin && (
        <Popup
          position={"50px"}
          link={"#"}
          image={path}
          type={period}
          newWindow={newWindow}
          id={null}
          isPopupPreview={true}
        />
      )}
    </HomeContainer>
  );
};

export default Home;
