import Box from "components/atoms/box";
import Button from "components/atoms/button";
import Image from "components/atoms/image";
import Input from "components/atoms/input";
import Loading from "components/atoms/loading";
import Text from "components/atoms/text";
import { useFindPwMutation } from "hooks/api/auth";
import usePattern from "hooks/utils/usePattern";
import useUrl from "hooks/utils/useUrl";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import findPwText from "text/findPw";

const { success, fail, pending } = findPwText;

const MobileFindPw = () => {
  const { changeLink } = useUrl();
  const { register, watch, reset, handleSubmit } = useForm({
    defaultValues: {
      name: "",
      email: "",
      phone: "",
    },
  });
  const pattern = usePattern();
  const [request, setRequest] = useState("pending");
  const [isMailLoading, setIsMailLoading] = useState(false);

  const handleSuccessFindPw = (info) => {
    setRequest("success");
  };

  const handleFailFindPw = () => {
    setRequest("fail");
  };

  const findPwMutation = useFindPwMutation(
    handleSuccessFindPw,
    handleFailFindPw
  );

  const handleFail = () => {
    setRequest("pending");
    reset();
  };

  const isActive =
    watch("name").trim() !== "" &&
    watch("email").trim() !== "" &&
    watch("phone").trim() !== "" &&
    pattern.email.value.test(watch("email")) &&
    pattern.call.value.test(watch("phone"));

  const handleSubmitFindPw = (data) => {
    const { name, email, phone } = data;
    const [phone1, phone2, phone3] = phone.split("-");

    setIsMailLoading(true);

    findPwMutation({
      name,
      email,
      phone1,
      phone2,
      phone3,
    });
  };
  return (
    <Box
      margin={"167px 0 300px"}
      padding={"0 62px"}
      width={"100%"}
      display={Box.DISPLAY.FLEX}
      flexDirection={"column"}
      al="center"
    >
      {request === "success" && (
        <>
          <Image src={"/assets/auth/checked.svg"} />
          <Text
            as={Text.VARIANT.SUBTITLE}
            size={Text.SIZE.MOBILETITLE}
            weight={Text.WEIGHT.BOLD}
            color={Text.COLOR.SECONDARY}
            textAlign={"center"}
            margin={"14px 0 0"}
          >
            {success.title[0]}
          </Text>
          <Text
            as={Text.VARIANT.SUBTITLE}
            size={Text.SIZE.MOBILETITLE}
            weight={Text.WEIGHT.BOLD}
            color={Text.COLOR.SECONDARY}
            textAlign={"center"}
            margin={"0 0 40px"}
          >
            {success.title[1]}
          </Text>

          <Button
            width={"100%"}
            padding={"10px 0"}
            border={"1px solid"}
            borderColor={Button.COLOR.SECONDARY}
            borderRadius={"21px"}
            bgColor={Button.COLOR.SECONDARY}
            color={Button.COLOR.WHITE}
            fontSize={Button.FONT_SIZE.MOBILECONTENT}
            fontWeight={Button.FONT_WEIGHT.BOLD}
            onClick={() => changeLink("/login")}
          >
            {success.submit}
          </Button>
        </>
      )}
      {request === "fail" && (
        <>
          <Image src={"/assets/auth/Error.svg"} />
          <Text
            as={Text.VARIANT.SUBTITLE}
            size={Text.SIZE.MOBILETITLE}
            weight={Text.WEIGHT.BOLD}
            color={Text.COLOR.SECONDARY}
            textAlign={"center"}
            margin={"14px 0 0"}
          >
            {fail.title[0]}
          </Text>
          <Text
            as={Text.VARIANT.SUBTITLE}
            size={Text.SIZE.MOBILETITLE}
            weight={Text.WEIGHT.BOLD}
            color={Text.COLOR.SECONDARY}
            textAlign={"center"}
            margin={"0 0 40px"}
          >
            {fail.title[1]}
          </Text>

          <Button
            width={"252px"}
            padding={"10px 0"}
            border={"1px solid"}
            borderColor={Button.COLOR.SECONDARY}
            borderRadius={"21px"}
            bgColor={Button.COLOR.SECONDARY}
            color={Button.COLOR.WHITE}
            fontSize={Button.FONT_SIZE.MOBILECONTENT}
            fontWeight={Button.FONT_WEIGHT.BOLD}
            onClick={handleFail}
          >
            {fail.submit}
          </Button>
        </>
      )}
      {request === "pending" && (
        <>
          <Text
            as={Text.VARIANT.SUBTITLE}
            size={Text.SIZE.MOBILETITLE}
            weight={Text.WEIGHT.BOLD}
            textAlign={"center"}
            margin={"0 0 30px"}
          >
            {pending.title}
          </Text>
          <Input
            size={Input.SIZE.MOBILECONTENT}
            placeholder={pending.form.name}
            width={"100%"}
            padding={"10px 18px"}
            margin={"0 0 12px"}
            borderColor={Input.COLOR.LINEGRAY}
            borderRadius={"21px"}
            {...register("name")}
          />

          <Input
            type={"email"}
            size={Input.SIZE.MOBILECONTENT}
            placeholder={pending.form.email}
            width={"100%"}
            padding={"10px 18px"}
            margin={"0 0 12px"}
            borderColor={Input.COLOR.LINEGRAY}
            borderRadius={"21px"}
            {...register("email")}
          />
          <Input
            type={"tel"}
            size={Input.SIZE.MOBILECONTENT}
            placeholder={pending.form.mobileTel}
            width={"100%"}
            padding={"10px 18px"}
            margin={"0 0 12px"}
            borderColor={Input.COLOR.LINEGRAY}
            borderRadius={"21px"}
            {...register("phone")}
          />

          <Box width={"100%"} margin={"0 0 40px"} padding={"0 15px 0 0"}>
            <Text
              as={Text.VARIANT.TEXT}
              color={Text.COLOR.RED}
              size={Text.SIZE.MOBILECONTENTDETAIL}
              width={"100%"}
              textAlign={"right"}
            >
              {pending.info[0]}
            </Text>
            <Text
              as={Text.VARIANT.TEXT}
              color={Text.COLOR.RED}
              size={Text.SIZE.MOBILECONTENTDETAIL}
              width={"100%"}
              textAlign={"right"}
            >
              {pending.info[1]}
            </Text>
          </Box>

          {isMailLoading ? (
            <Loading />
          ) : (
            <Button
              width={"100%"}
              padding={"10px 0"}
              border={"1px solid"}
              borderColor={
                isActive ? Button.COLOR.SECONDARY : Button.COLOR.LINEGRAY
              }
              borderRadius={"21px"}
              bgColor={
                isActive ? Button.COLOR.SECONDARY : Button.COLOR.LINEGRAY
              }
              color={Button.COLOR.WHITE}
              fontSize={Button.FONT_SIZE.MOBILECONTENT}
              fontWeight={Button.FONT_WEIGHT.BOLD}
              onClick={handleSubmit(handleSubmitFindPw)}
              disabled={!isActive}
            >
              {pending.submit}
            </Button>
          )}
        </>
      )}
    </Box>
  );
};

export default MobileFindPw;
