import Box from "components/atoms/box";
import Button from "components/atoms/button";
import Image from "components/atoms/image";
import Text from "components/atoms/text";
import CommentForm from "components/molecules/commentForm";
import CommentItem from "components/molecules/commentItem";
import List from "components/molecules/list";
import { useComment, usePreFetchComment } from "hooks/api/comment";
import useUrl from "hooks/utils/useUrl";
import React, { useState } from "react";
import useMobileStore from "store/useMobileStore";
import detailText from "text/detail";

const { comment } = detailText;

const Comment = (props) => {
  const { commentCnt, commentOn } = props;
  const {
    path,
    query: { page = 1 },
  } = useUrl();
  const [sortType, setSortType] = useState("lates");
  const { isMobile } = useMobileStore();

  return (
    <Box margin={"30px 0"}>
      <Text
        as={Text.VARIANT.SUBTITLE}
        size={isMobile ? Text.SIZE.MOBILECONTENT : Text.SIZE.NORMAL}
        margin={"0 0 20px"}
      >
        {comment.length} {commentCnt}
      </Text>
      <CommentForm height={"74px"} commentOn={commentOn} />

      <List
        api={useComment}
        preFetch={usePreFetchComment}
        params={{ id: path[1], sort: sortType, page }}
        margin={"50px 0 20px"}
      >
        {(list) =>
          list && (
            <>
              {list.length === 0 && (
                <Box
                  display={Box.DISPLAY.FLEX}
                  al={"center"}
                  flexDirection={"column"}
                  padding={"80px 0 0"}
                >
                  <Image
                    src={"/assets/detail/commentBlank.svg"}
                    width={isMobile ? "56px" : "80px"}
                  />
                  <Text
                    as={Text.VARIANT.TEXT}
                    margin={"30px 0 0 0"}
                    size={
                      isMobile
                        ? Text.SIZE.MOBILECONTENT
                        : Text.SIZE.CLIENTCONTENT
                    }
                    color={Text.COLOR.TEXTGRAY}
                  >
                    {commentOn ? comment.comment : comment.blank}
                  </Text>
                </Box>
              )}
              {list.length !== 0 && (
                <>
                  <Box
                    padding={"30px 0 15px"}
                    borderBottom="1px solid"
                    borderColor={Box.COLOR.LINEGRAY}
                  >
                    {comment.sort.map(({ title, value }, idx) => (
                      <Button
                        margin={isMobile ? "0 20px 0 0" : "0 0 0 20px"}
                        themeStyle={Button.THEME.NONE}
                        color={
                          sortType === value
                            ? Button.COLOR.SECONDARY
                            : Button.COLOR.TEXTGRAY
                        }
                        fontWeight={
                          sortType === value
                            ? Button.FONT_WEIGHT.BOLD
                            : Button.FONT_WEIGHT.NORMAL
                        }
                        fontSize={
                          isMobile
                            ? Button.FONT_SIZE.MOBILECONTENT
                            : Button.FONT_SIZE.CLIENTCONTENT
                        }
                        onClick={() => setSortType(value)}
                        key={title}
                      >
                        {title}
                      </Button>
                    ))}
                  </Box>
                  {list.map((data) => (
                    <CommentItem
                      {...data}
                      sortType={sortType}
                      key={data.seq}
                      commentOn={commentOn}
                    />
                  ))}
                </>
              )}
            </>
          )
        }
      </List>
    </Box>
  );
};

export default Comment;
