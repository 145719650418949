import Box from "components/atoms/box";
import MobileBoard from "components/organisms/mobileBoard";
import React from "react";

const MobileCalendar = () => {
  return (
    <>
      <Box
        display={Box.DISPLAY.FLEX}
        flexDirection="column"
        al="center"
        padding={"135px 0 0"}
      />

      <MobileBoard padding={"0"} isCalendar>
        <MobileBoard.Calendar />
      </MobileBoard>
    </>
  );
};

export default MobileCalendar;
