import AdminCoverageList from "components/organisms/adminCoverageList";
import SearchCondition from "components/organisms/searchCondition";
import { AdminContentsContainer } from "components/templates/admin/admin.style";
import dayjs from "dayjs";
import useUrl from "hooks/utils/useUrl";
import React from "react";
import { useState } from "react";
import { coverage } from "text/admin/coverage";
import convertDateWithYear from "utils/convertDateWithYear";

const ScienceCoverage = () => {
  const today = dayjs();

  const { defaultValues, keywordOptions, dateOptions } = coverage;

  const { pathname, changeLink } = useUrl();

  const [selectedMenu, setSelectedMenu] = useState("title");
  const [dateSelectedMenu, setDateSelectedMenu] = useState("regDate");
  const [selectedStartDate, setSelectedStartDate] = useState(today.subtract(1, "month"));
  const [selectedEndDate, setSelectedEndDate] = useState(today);
  const [filterData, setFilterData] = useState([]);

  const onSubmit = (data) => {
    const startDate = convertDateWithYear(selectedStartDate);
    const endDate = convertDateWithYear(selectedEndDate);
    const { searchValue, dateCondition } = data;
    setFilterData({
      startDate,
      endDate,
      searchCondition: selectedMenu,
      searchValue,
      dateCondition,
    });
    changeLink(pathname);
  };

  return (
    <AdminContentsContainer>
      <SearchCondition onSubmit={onSubmit} defaultValues={defaultValues}>
        <SearchCondition.DatePicker
          title="일시"
          selectedStartDate={selectedStartDate}
          setSelectedStartDate={setSelectedStartDate}
          selectedEndDate={selectedEndDate}
          setSelectedEndDate={setSelectedEndDate}
          selectedMenu={dateSelectedMenu}
          setSelectedMenu={setDateSelectedMenu}
          options={dateOptions}
          width="120px"
          registerName="dateCondition"
          hasSelectMenu
        />
        <SearchCondition.Select
          title="검색 분류"
          registerName="searchValue"
          selectedMenu={selectedMenu}
          setSelectedMenu={setSelectedMenu}
          options={keywordOptions}
          width="104px"
        />
      </SearchCondition>
      <AdminCoverageList filterData={filterData} />
    </AdminContentsContainer>
  );
};

export default ScienceCoverage;
