import Box from "components/atoms/box";
import Board from "components/organisms/board";
import useUrl from "hooks/utils/useUrl";
import React from "react";

const People = () => {
  const {
    query: { category = "all" },
  } = useUrl();

  const boardType = "people";

  return (
    <Box
      width="100%"
      display={Box.DISPLAY.FLEX}
      flexDirection="column"
      al="center"
    >
      <Board isAllPage={false}>
        <Board.News category={category} boardType={boardType} />
      </Board>
    </Box>
  );
};

export default People;
