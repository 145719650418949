import Box from "components/atoms/box";
import Button from "components/atoms/button";
import Image from "components/atoms/image";
import Input from "components/atoms/input";
import Text from "components/atoms/text";
import { useChangePasswordMutation } from "hooks/api/auth";
import usePattern from "hooks/utils/usePattern";
import useUrl from "hooks/utils/useUrl";
import React, { useState } from "react";
import changePasswordText from "text/changePassword";

const { success, fail, pending } = changePasswordText;

const MobileChangePassword = () => {
  const [request, setRequest] = useState("pending"); // ["pending", "success", "fail"
  const [password, setPassword] = useState("");
  const [passwordCheck, setPasswordCheck] = useState("");

  const {
    query: { key },
    changeLink,
  } = useUrl();
  const pattern = usePattern();

  const handleSuccessChangePassword = () => {
    setRequest("success");
  };

  const handleFailChangePassword = () => {
    setRequest("fail");
  };

  const changePasswordMutation = useChangePasswordMutation(
    handleSuccessChangePassword,
    handleFailChangePassword
  );

  const isActive =
    password !== "" &&
    passwordCheck !== "" &&
    password === passwordCheck &&
    pattern.password.value.test(password);

  const handleFail = () => {
    changeLink("/");
    setPassword("");
    setPasswordCheck("");
  };

  const handleChangePassword = () => {
    changePasswordMutation({
      key,
      password,
    });
  };

  return (
    <Box
      display={Box.DISPLAY.FLEX}
      jc="center"
      minHeight={"calc(100vh - 339px)"}
    >
      <Box
        display={Box.DISPLAY.FLEX}
        flexDirection={"column"}
        al="center"
        width={"calc(100% - 24px)"}
        padding={"32px 24px 40px"}
        margin={"75px 0 10px"}
        borderRadius={"10px"}
        bgColor={request === "pending" ? Box.COLOR.LIGHTGRAY : Box.COLOR.WHITE}
      >
        {request === "success" && (
          <>
            <Image src={"/assets/auth/checked.svg"} />
            <Text
              as={Text.VARIANT.SUBTITLE}
              size={Text.SIZE.MOBILETITLE}
              weight={Text.WEIGHT.NORMAL}
              color={Text.COLOR.SECONDARY}
              textAlign={"center"}
              margin={"24px 0 35px"}
            >
              {success.title}
            </Text>

            <Text
              as={Text.VARIANT.TEXT}
              size={Text.SIZE.MOBILEETC}
              lineHeight={"14px"}
            >
              {success.content[0]}
            </Text>

            <Text
              as={Text.VARIANT.TEXT}
              size={Text.SIZE.MOBILEETC}
              lineHeight={"14px"}
              margin={"0 0 35px"}
            >
              {success.content[1]}
            </Text>

            <Button
              width={"252px"}
              padding={"10px 0"}
              border={"1px solid"}
              borderColor={Button.COLOR.SECONDARY}
              borderRadius={"21px"}
              bgColor={Button.COLOR.SECONDARY}
              color={Button.COLOR.WHITE}
              fontSize={Button.FONT_SIZE.MOBILECONTENT}
              fontWeight={Button.FONT_WEIGHT.BOLD}
              onClick={() => changeLink("/login")}
            >
              {success.submit}
            </Button>
          </>
        )}
        {request === "fail" && (
          <>
            <Image src={"/assets/auth/Error.svg"} />
            <Text
              as={Text.VARIANT.SUBTITLE}
              size={Text.SIZE.MOBILETITLE}
              weight={Text.WEIGHT.NORMAL}
              color={Text.COLOR.SECONDARY}
              textAlign={"center"}
              margin={"24px 0 35px"}
            >
              {fail.title}
            </Text>
            <Button
              width={"252px"}
              padding={"10px 0"}
              margin={"0 0 10px"}
              border={"1px solid"}
              borderColor={Button.COLOR.SECONDARY}
              borderRadius={"21px"}
              bgColor={Button.COLOR.SECONDARY}
              color={Button.COLOR.WHITE}
              fontSize={Button.FONT_SIZE.MOBILECONTENT}
              fontWeight={Button.FONT_WEIGHT.BOLD}
              onClick={handleFail}
            >
              {fail.submit}
            </Button>
          </>
        )}
        {request === "pending" && (
          <>
            <Text
              as={Text.VARIANT.SUBTITLE}
              size={Text.SIZE.MOBILETITLE}
              textAlign={"center"}
              padding={"0 0 40px"}
            >
              {pending.title}
            </Text>

            <Box
              width={"100%"}
              display={Box.DISPLAY.FLEX}
              padding={"10px 12px"}
              margin={"12px 0"}
              border={"1px solid"}
              borderColor={Box.COLOR.LINEGRAY}
              borderRadius={"5px"}
              bgColor={Text.COLOR.WHITE}
            >
              <Text
                as={Text.VARIANT.TEXT}
                size={Text.SIZE.MOBILECONTENT}
                width={"60px"}
                margin={"0 4px 0 0"}
                borderRight={"1px solid"}
                borderColor={Text.COLOR.LINEGRAY}
                weight={Text.WEIGHT.BOLD}
                color={Text.COLOR.TEXTGRAY}
                isSort
              >
                {pending.form.password.title}
              </Text>

              <Input
                type={"password"}
                width={"calc(100% - 64px)"}
                border={"none"}
                size={Input.SIZE.MOBILECONTENTDETAIL}
                color={Input.COLOR.TEXTGRAY}
                placeholder={pending.form.password.mobilePlaceholder}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Box>

            <Box
              width={"100%"}
              display={Box.DISPLAY.FLEX}
              padding={"10px 12px"}
              border={"1px solid"}
              borderColor={Box.COLOR.LINEGRAY}
              borderRadius={"5px"}
              bgColor={Text.COLOR.WHITE}
            >
              <Text
                as={Text.VARIANT.TEXT}
                size={Text.SIZE.MOBILECONTENT}
                width={"85px"}
                margin={"0 8px 0 0"}
                borderRight={"1px solid"}
                borderColor={Text.COLOR.LINEGRAY}
                weight={Text.WEIGHT.BOLD}
                color={Text.COLOR.TEXTGRAY}
                isSort
              >
                {pending.form.passwordCheck.title}
              </Text>

              <Input
                type={"password"}
                width={"calc(100% - 93px)"}
                border={"none"}
                size={Input.SIZE.MOBILECONTENTDETAIL}
                color={Input.COLOR.TEXTGRAY}
                value={passwordCheck}
                onChange={(e) => setPasswordCheck(e.target.value)}
              />
            </Box>
            <Button
              width={"100%"}
              height={"36px"}
              margin={"40px 0 12px"}
              color={Button.COLOR.WHITE}
              bgColor={
                isActive ? Button.COLOR.SECONDARY : Button.COLOR.LINEGRAY
              }
              border={"1px solid"}
              borderColor={
                isActive ? Button.COLOR.SECONDARY : Button.COLOR.LINEGRAY
              }
              borderRadius={"20px"}
              fontSize={Button.FONT_SIZE.MOBILECONTENT}
              fontWeight={Button.FONT_WEIGHT.BOLD}
              disabled={!isActive}
              onClick={handleChangePassword}
            >
              {pending.submit}
            </Button>
          </>
        )}
      </Box>
    </Box>
  );
};

export default MobileChangePassword;
