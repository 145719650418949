import { Select } from "antd";
import Box from "components/atoms/box";
import Button from "components/atoms/button";
import Input from "components/atoms/input";
import Text from "components/atoms/text";
import CategorySelect from "components/molecules/categorySelect";
import MobileCategorySelect from "components/molecules/mobileCategorySelect";
import useToggle from "hooks/utils/useToggle";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { IoMdClose } from "@react-icons/all-files/io/IoMdClose";
import Picker from "react-mobile-picker";
import SignupAgreement from "../signupAgreement";
import Image from "components/atoms/image";
import {
  useCreateRequestNewsletterMutation,
  useRejectRequestNewsletterMutation,
} from "hooks/api/newsletter";
import usePattern from "hooks/utils/usePattern";
import newsletterText from "text/newsletter";
import { BsChevronDown } from "@react-icons/all-files/bs/BsChevronDown";

const { newsletterRequest } = newsletterText;

const optionGroups = {
  select: [
    newsletterRequest.pending.form.emailSelect.title,
    "kofst.or.kr",
    "dreamwiz.com",
    "empal.com",
    "freechal.com",
    "gmail.com",
    "hanafos.com",
    "hanmail.net",
    "hanmir.com",
    "hotmail.com",
    "korea.com",
    "lycos.com",
    "nate.com",
    "naver.com",
    "netian.com",
    "paran.com",
    "yahoo.com.kr",
    "yahoo.com",
    "chol.com",
  ],
};

const NewsletterRequest = ({ isMobile, setIsHidden }) => {
  const [currentSelect, setCurrentSelect] = useState(0);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const form = useForm({
    defaultValues: {
      name: "",
      email: "",
      emailDomain: "",
      password: "",
      emailSelect: "",
      isPrivacy: false,
    },
  });
  const { control, register, setValue, watch, reset, handleSubmit } = form;
  const [isEmail, setIsEmail] = useToggle(false);
  const [selectEmail, setSelectEmail] = useState({
    select: newsletterRequest.pending.form.emailSelect.title,
  });
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [request, setRequest] = useState("pending");
  const pattern = usePattern();

  const handleSuccessNewsletter = () => {
    setRequest("success");
    setIsHidden(true);
    window.scrollTo(0, 0);
  };

  const handleFailNewsletter = (message) => {
    setRequest("fail");
    setIsHidden(true);
    window.scrollTo(0, 0);

    if (message === "DUPLICATE VALUE EXISTS") {
      setIsDuplicate(true);
    }
  };

  const createRequestNewsletterMutation = useCreateRequestNewsletterMutation(
    handleSuccessNewsletter,
    handleFailNewsletter
  );
  const requestNewsletterMutation = useRejectRequestNewsletterMutation(
    handleSuccessNewsletter,
    handleFailNewsletter
  );

  const isReject = currentSelect === 1;

  const submit = isReject
    ? requestNewsletterMutation
    : createRequestNewsletterMutation;

  const handleReset = () => {
    reset();
    setRequest("pending");
    setIsHidden(false);
    setIsDuplicate(false);
  };

  const emailSelect = watch("emailSelect");

  const handleChange = (name, value) => {
    setSelectEmail({
      [name]: value,
    });
  };

  const handleSubmitEmail = () => {
    setValue("emailSelect", selectEmail.select);
    setIsEmail();
  };

  const CurrentCategorySelect = isMobile
    ? MobileCategorySelect
    : CategorySelect;

  const isActive =
    watch("email").trim() !== "" &&
    watch("emailDomain").trim() !== "" &&
    pattern.email.value.test(`${watch("email")}@${watch("emailDomain")}`) &&
    // watch("password").trim() !== "" &&
    // pattern.password.validate(watch("password")) &&
    (isReject ? true : watch("name").trim() !== "" && watch("isPrivacy"));

  useEffect(() => {
    const select =
      emailSelect === newsletterRequest.pending.form.emailSelect.title
        ? ""
        : emailSelect;

    setValue("emailDomain", select);

    if (select === "") {
      setIsReadOnly(false);
    } else {
      setIsReadOnly(true);
    }
  }, [emailSelect, setIsReadOnly, setValue]);

  useEffect(() => {
    reset();
  }, [currentSelect, reset]);

  return (
    <Box position={"relative"} width={"100%"}>
      {request === "success" && (
        <Box
          display={Box.DISPLAY.FLEX}
          flexDirection={"column"}
          al={"center"}
          width={"100%"}
          margin={isMobile ? "77px 0 100px" : "100px 0 300px"}
        >
          <Image src={"/assets/auth/checked.svg"} />
          <Text
            as={Text.VARIANT.SUBTITLE}
            size={isMobile ? Text.SIZE.MOBILETITLE : Text.SIZE.CLIENTCATEGORY}
            weight={Text.WEIGHT.BOLD}
            color={Text.COLOR.SECONDARY}
            textAlign={"center"}
            margin={"14px 0 0"}
          >
            {isReject
              ? newsletterRequest.success.title.reject
              : newsletterRequest.success.title.accept}
          </Text>
          <Text
            as={Text.VARIANT.SUBTITLE}
            size={isMobile ? Text.SIZE.MOBILETITLE : Text.SIZE.CLIENTCATEGORY}
            weight={Text.WEIGHT.BOLD}
            color={Text.COLOR.SECONDARY}
            textAlign={"center"}
            margin={"0 0 40px"}
          >
            {newsletterRequest.success.title.complete}
          </Text>

          <Button
            width={"327px"}
            padding={"12px 0"}
            border={"1px solid"}
            borderColor={Button.COLOR.SECONDARY}
            borderRadius={isMobile ? "21px" : "15px"}
            bgColor={Button.COLOR.SECONDARY}
            color={Button.COLOR.WHITE}
            fontSize={
              isMobile
                ? Button.FONT_SIZE.MOBILECONTENT
                : Button.FONT_SIZE.CLIENTCONTENT
            }
            fontWeight={Button.FONT_WEIGHT.BOLD}
            onClick={handleReset}
          >
            {newsletterRequest.success.submit}
          </Button>
        </Box>
      )}
      {request === "fail" && (
        <Box
          display={Box.DISPLAY.FLEX}
          flexDirection={"column"}
          al={"center"}
          width={"100%"}
          margin={isMobile ? "77px 0 100px" : "100px 0 300px"}
        >
          <Image src={"/assets/auth/Error.svg"} />
          {isDuplicate ? (
            <Text
              as={Text.VARIANT.SUBTITLE}
              size={isMobile ? Text.SIZE.MOBILETITLE : Text.SIZE.CLIENTCATEGORY}
              weight={Text.WEIGHT.BOLD}
              color={Text.COLOR.SECONDARY}
              textAlign={"center"}
              margin={"14px 0 40px"}
            >
              이미 신청한 이메일입니다.
            </Text>
          ) : (
            <>
              <Text
                as={Text.VARIANT.SUBTITLE}
                size={
                  isMobile ? Text.SIZE.MOBILETITLE : Text.SIZE.CLIENTCATEGORY
                }
                weight={Text.WEIGHT.BOLD}
                color={Text.COLOR.SECONDARY}
                textAlign={"center"}
                margin={"14px 0 0"}
              >
                {isReject
                  ? newsletterRequest.fail.title.reject
                  : newsletterRequest.fail.title.accept}
              </Text>
              <Text
                as={Text.VARIANT.SUBTITLE}
                size={
                  isMobile ? Text.SIZE.MOBILETITLE : Text.SIZE.CLIENTCATEGORY
                }
                weight={Text.WEIGHT.BOLD}
                color={Text.COLOR.SECONDARY}
                textAlign={"center"}
                margin={"0 0 40px"}
              >
                {newsletterRequest.fail.title.complete}
              </Text>
            </>
          )}

          <Button
            width={"327px"}
            padding={"12px 0"}
            border={"1px solid"}
            borderColor={Button.COLOR.SECONDARY}
            borderRadius={isMobile ? "21px" : "15px"}
            bgColor={Button.COLOR.SECONDARY}
            color={Button.COLOR.WHITE}
            fontSize={
              isMobile
                ? Button.FONT_SIZE.MOBILECONTENT
                : Button.FONT_SIZE.CLIENTCONTENT
            }
            fontWeight={Button.FONT_WEIGHT.BOLD}
            onClick={handleReset}
          >
            {newsletterRequest.fail.submit}
          </Button>
        </Box>
      )}
      {request === "pending" && (
        <>
          <Box
            position={"absolute"}
            top={isMobile ? "-12px" : "-32px"}
            width={"100%"}
          >
            <CurrentCategorySelect
              list={newsletterRequest.newsletterList}
              current={currentSelect}
              onClick={setCurrentSelect}
            />
          </Box>

          <Text
            as={Text.VARIANT.TEXT}
            color={Text.COLOR.TEXTGRAY}
            size={isMobile ? Text.SIZE.MOBILECONTENT : Text.SIZE.CLIENTNORMAL}
            weight={Text.WEIGHT.BOLD}
            padding={isMobile ? "48px 0 8px" : "137px 0 12px"}
          >
            {newsletterRequest.pending.title.common}{" "}
            {isReject
              ? newsletterRequest.pending.title.reject
              : newsletterRequest.pending.title.accept}
          </Text>
          <Text
            as={Text.VARIANT.TEXT}
            color={Text.COLOR.TEXTGRAY}
            size={isMobile ? Text.SIZE.MOBILEETC : Text.SIZE.CLIENTCONTENT}
            padding={"0 0 12px 13px"}
          >
            {isReject ? (
              <>
                {newsletterRequest.pending.description.reject.common[0]}
                <Text as={Text.VARIANT.SPAN} weight={Text.WEIGHT.BOLD}>
                  {newsletterRequest.pending.description.reject.bold[0]}
                </Text>
                {newsletterRequest.pending.description.reject.common[1]}
                <Text as={Text.VARIANT.SPAN} weight={Text.WEIGHT.BOLD}>
                  {newsletterRequest.pending.description.reject.bold[1]}
                </Text>
                {newsletterRequest.pending.description.reject.common[2]}
              </>
            ) : (
              newsletterRequest.pending.description.accept
            )}
          </Text>

          {!isReject && (
            <Box
              display={Box.DISPLAY.FLEX}
              padding={isMobile ? "10px 13px" : "22px 40px"}
              margin={"12px 0"}
              border={"1px solid"}
              borderColor={Box.COLOR.LINEGRAY}
              borderRadius={isMobile ? "5px" : "20px"}
              bgColor={Text.COLOR.WHITE}
            >
              <Text
                as={Text.VARIANT.TEXT}
                width={isMobile ? "53px" : "82px"}
                margin={isMobile ? "0 8px 0 0" : "0 28px 0 0"}
                borderRight={"1px solid"}
                borderColor={Text.COLOR.LINEGRAY}
                weight={Text.WEIGHT.BOLD}
                color={Text.COLOR.TEXTGRAY}
                size={
                  isMobile ? Text.SIZE.MOBILECONTENT : Text.SIZE.CLIENTCONTENT
                }
                isSort
              >
                {newsletterRequest.pending.form.name.title}
              </Text>

              <Input
                width={isMobile ? "calc(100% - 66px)" : "calc(100% - 82px)"}
                border={"none"}
                size={
                  isMobile
                    ? Input.SIZE.MOBILECONTENTDETAIL
                    : Input.SIZE.CLIENTCONTENT
                }
                color={Input.COLOR.TEXTGRAY}
                {...register("name")}
              />
            </Box>
          )}

          <Box display={Box.DISPLAY.FLEX} margin={"12px 0"}>
            <Box
              display={Box.DISPLAY.FLEX}
              width={isMobile ? "calc(100% - 91px)" : "calc(100% - 176px)"}
              margin={"0 10px 0 0"}
              padding={isMobile ? "10px 13px" : "22px 40px"}
              border={"1px solid"}
              borderColor={Box.COLOR.LINEGRAY}
              borderRadius={isMobile ? "5px" : "20px"}
              bgColor={Text.COLOR.WHITE}
            >
              <Text
                as={Text.VARIANT.TEXT}
                width={isMobile ? "53px" : "82px"}
                margin={isMobile ? "0 8px 0 0" : "0 28px 0 0"}
                borderRight={"1px solid"}
                borderColor={Text.COLOR.LINEGRAY}
                weight={Text.WEIGHT.BOLD}
                color={Text.COLOR.TEXTGRAY}
                size={
                  isMobile ? Text.SIZE.MOBILECONTENT : Text.SIZE.CLIENTCONTENT
                }
                isSort
              >
                {newsletterRequest.pending.form.email.title}
              </Text>
              <Input
                type="email"
                width={isMobile ? "calc(50% - 30px)" : "calc(50% - 90px)"}
                border={"none"}
                size={
                  isMobile
                    ? Input.SIZE.MOBILECONTENTDETAIL
                    : Input.SIZE.CLIENTCONTENT
                }
                color={Input.COLOR.TEXTGRAY}
                {...register("email")}
              />
              <Text
                as={Text.VARIANT.TEXT}
                color={Text.COLOR.TEXTGRAY}
                size={
                  isMobile
                    ? Text.SIZE.MOBILECONTENTDETAIL
                    : Text.SIZE.CLIENTCONTENT
                }
                isSort
                weight={Text.WEIGHT.BOLD}
                margin={isMobile ? "0" : "0 28px"}
              >
                @
              </Text>
              <Input
                type="email"
                width={isMobile ? "calc(50% - 30px)" : "calc(50% - 90px)"}
                border={"none"}
                size={
                  isMobile
                    ? Input.SIZE.MOBILECONTENTDETAIL
                    : Input.SIZE.CLIENTCONTENT
                }
                color={Input.COLOR.TEXTGRAY}
                readOnly={isReadOnly}
                {...register("emailDomain")}
              />
            </Box>{" "}
            {isMobile ? (
              <Button
                border={"1px solid"}
                borderColor={Button.COLOR.LINEGRAY}
                borderRadius={"5px"}
                width={"91px"}
                bgColor={Button.COLOR.WHITE}
                color={Button.COLOR.TEXTGRAY}
                fontSize={Button.FONT_SIZE.MOBILECONTENT}
                fontWeight={Button.FONT_WEIGHT.BOLD}
                onClick={setIsEmail}
              >
                {emailSelect === ""
                  ? newsletterRequest.pending.form.emailSelect.title
                  : emailSelect}
              </Button>
            ) : (
              <Controller
                name="emailSelect"
                defaultValue=""
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    className="admin-select"
                    suffixIcon={
                      <Text
                        weight={Text.WEIGHT.BOLD}
                        color={Text.COLOR.TEXTGRAY}
                      >
                        <BsChevronDown size={16} />
                      </Text>
                    }
                    style={{
                      width: "176px",
                      height: "62px",
                      borderRadius: "20px",
                    }}
                    options={[
                      {
                        label: newsletterRequest.pending.form.emailSelect.title,
                        value: "",
                      },
                      { value: "kofst.or.kr" },
                      { value: "dreamwiz.com" },
                      { value: "empal.com" },
                      { value: "freechal.com" },
                      { value: "gmail.com" },
                      { value: "hanafos.com" },
                      { value: "hanmail.net" },
                      { value: "hanmir.com" },
                      { value: "hotmail.com" },
                      { value: "korea.com" },
                      { value: "lycos.com" },
                      { value: "nate.com" },
                      { value: "naver.com" },
                      { value: "netian.com" },
                      { value: "paran.com" },
                      { value: "yahoo.com.kr" },
                      { value: "yahoo.com" },
                      { value: "chol.com" },
                    ]}
                  />
                )}
              />
            )}
          </Box>

          {/* <Box
            display={Box.DISPLAY.FLEX}
            padding={isMobile ? "10px 13px" : "22px 40px"}
            margin={"12px 0"}
            border={"1px solid"}
            borderColor={Box.COLOR.LINEGRAY}
            borderRadius={isMobile ? "5px" : "20px"}
            bgColor={Text.COLOR.WHITE}
          >
            <Text
              as={Text.VARIANT.TEXT}
              width={isMobile ? "53px" : "82px"}
              margin={isMobile ? "0 8px 0 0" : "0 28px 0 0"}
              borderRight={"1px solid"}
              borderColor={Text.COLOR.LINEGRAY}
              weight={Text.WEIGHT.BOLD}
              color={Text.COLOR.TEXTGRAY}
              size={
                isMobile ? Text.SIZE.MOBILECONTENT : Text.SIZE.CLIENTCONTENT
              }
              isSort
            >
              {newsletterRequest.pending.form.password.title}
            </Text>

            <Input
              type={"password"}
              width={isMobile ? "calc(100% - 66px)" : "calc(100% - 82px)"}
              border={"none"}
              size={
                isMobile
                  ? Input.SIZE.MOBILECONTENTDETAIL
                  : Input.SIZE.CLIENTCONTENT
              }
              color={Input.COLOR.TEXTGRAY}
              {...register("password")}
              placeholder={newsletterRequest.pending.form.password.placeholder}
            />
          </Box> */}

          {!isReject && (
            <>
              {/* <Text
                as={Text.VARIANT.TEXT}
                color={Text.COLOR.RED}
                size={isMobile ? Text.SIZE.MOBILEETC : Text.SIZE.CLIENTCONTENT}
                padding={isMobile ? "0 0 0 65px" : "0 0 0 120px"}
              >
                {newsletterRequest.pending.form.password.info}
              </Text> */}

              <Text
                as={Text.VARIANT.SUBTITLE}
                color={Input.COLOR.TEXTGRAY}
                size={
                  isMobile ? Text.SIZE.MOBILECONTENT : Text.SIZE.CLIENTNORMAL
                }
                borderBottom={"1px solid"}
                borderColor={Text.COLOR.LINEGRAY}
                margin={isMobile ? "30px 0 0" : "80px 0 0"}
                padding={"0 0 10px"}
              >
                {newsletterRequest.pending.form.privacy.title}
                <Text as={Text.VARIANT.SPAN} color={Text.COLOR.SECONDARY}>
                  {newsletterRequest.pending.form.privacy.required}
                </Text>
              </Text>

              <FormProvider {...form}>
                <SignupAgreement isPrivacyAble isMobile={isMobile} />
              </FormProvider>
            </>
          )}

          <Box
            display={Box.DISPLAY.FLEX}
            jc={"center"}
            margin={isMobile ? "40px 0 0" : "120px 0 0"}
          >
            <Button
              themeStyle={Button.THEME.NONE}
              width={isMobile ? "100%" : "260px"}
              padding={"12px"}
              border={"1px solid"}
              borderColor={
                isActive ? Button.COLOR.SECONDARY : Button.COLOR.LINEGRAY
              }
              borderRadius={"40px"}
              color={isActive ? Button.COLOR.SECONDARY : Button.COLOR.LINEGRAY}
              fontSize={
                isMobile
                  ? Button.FONT_SIZE.MOBILECONTENT
                  : Button.FONT_SIZE.NORMAL
              }
              onClick={handleSubmit(submit)}
              disabled={!isActive}
            >
              {isReject
                ? newsletterRequest.pending.form.submit.reject
                : newsletterRequest.pending.form.submit.accept}
            </Button>
          </Box>

          {isEmail && (
            <Box
              position={"fixed"}
              top={"0"}
              left={"0"}
              width={"100%"}
              height={"100vh"}
              display={Box.DISPLAY.FLEX}
              jc={"center"}
              al={"center"}
              zIndex={Box.LEVEL.ROOT}
              bgColor={Box.COLOR.SHADOW}
              onClick={setIsEmail}
            >
              <Box
                display={Box.DISPLAY.FLEX}
                flexDirection={"column"}
                al={"center"}
                jc={"space-between"}
                position={"relative"}
                width="275px"
                height={"295px"}
                bgColor={Box.COLOR.WHITE}
                padding={"12px 10px"}
                border={"1px solid"}
                borderColor={Box.COLOR.SECONDARY}
                borderRadius={"5px"}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <Text
                  as={Text.VARIANT.SUBTITLE}
                  size={Text.SIZE.MOBILETITLE}
                  weight={Text.WEIGHT.BOLD}
                  textAlign={"center"}
                >
                  {newsletterRequest.pending.form.modal.title}
                </Text>

                <Box position={"absolute"} top={"12px"} right={"22px"}>
                  <Button themeStyle={Button.THEME.NONE} onClick={setIsEmail}>
                    <IoMdClose size={18} />
                  </Button>
                </Box>
                <Picker
                  optionGroups={optionGroups}
                  valueGroups={selectEmail}
                  onChange={handleChange}
                />

                <Button
                  themeStyle={Button.THEME.NONE}
                  width={"100%"}
                  padding={"9px 0"}
                  fontSize={Button.FONT_SIZE.MOBILETITLE}
                  fontWeight={Button.FONT_WEIGHT.BOLD}
                  color={Button.COLOR.WHITE}
                  bgColor={Button.COLOR.SECONDARY}
                  borderRadius={"17.5px"}
                  onClick={handleSubmitEmail}
                >
                  {newsletterRequest.pending.form.modal.submit}
                </Button>
              </Box>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default NewsletterRequest;
