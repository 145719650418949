import { adminDefaultUrl } from "api/admin/url";
import React from "react";
import { Navigate } from "react-router-dom";
import useUserStore from "store/useUserStore";

const ProtectedRoute = ({ children }) => {
  const { authority } = useUserStore();

  return authority ? children : <Navigate to={`${adminDefaultUrl}/login`} replace />;
};

export default ProtectedRoute;
