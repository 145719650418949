import Box from "components/atoms/box";
import Button from "components/atoms/button";
import React from "react";

const AdminEmailEditButton = (props) => {
  const { isPending } = props;

  return (
    <Box
      display={Box.DISPLAY.FLEX}
      al="center"
      jc="center"
      gap="12px"
      width="100%"
      padding="40px 0 0 0"
      bgColor={Box.COLOR.ADMINCONTENTSBG}
    >
      <Button
        themeStyle={isPending ? "disabled" : "adminSave"}
        padding="12px 40px"
        disabled={isPending}
      >
        저장
      </Button>
    </Box>
  );
};

export default AdminEmailEditButton;
