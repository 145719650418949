import styled, { css } from "styled-components";

const theme = {
  secondary: css`
    width: 100%;

    border: 1px solid ${(props) => props.theme.color.lineGray};
    border-radius: ${(props) => props.theme.radius.small};

    &:hover {
      border-color: ${(props) => props.theme.color.secondary};
    }
    &:focus {
      border-color: ${(props) => props.theme.color.secondary};
      outline: none;
    }
  `,
};

export const InputComponent = styled.input`
  ${(props) => theme[props.themeStyle]};

  width: ${(props) => props.$width};
  height: ${(props) => props.$height};

  border: ${(props) => props.border};
  border-left: ${(props) => props.borderLeft};
  border-radius: ${(props) =>
    props.theme.radius[props.borderRadius] ?? props.borderRadius};
  border-color: ${(props) => props.theme.color[props.borderColor]};

  background-color: ${(props) => props.theme.color[props.bgColor]};

  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};

  color: ${(props) => props.theme.color[props.$color]};
  font-size: ${(props) => props.theme.font[props.$fontSize] ?? props.$fontSize};

  resize: ${(props) => props.resize};

  display: ${(props) => props.display};

  &:hover {
    border-color: ${(props) => props.theme.color[props.hoverColor]};
  }
  &:focus {
    border-color: ${(props) => props.theme.color[props.focusColor]};
    outline: none;
  }

  ::placeholder {
    color: ${(props) => props.theme.color.lineGray};
  }
`;
