import Box from "components/atoms/box";
import Board from "components/organisms/board";
import useUrl from "hooks/utils/useUrl";
import React from "react";

const FeatDetail = () => {
  const {
    params: { id },
  } = useUrl();

  const boardType = "feature";

  return (
    <Box display={Box.DISPLAY.FLEX} flexDirection="column" al="center">
      <Board>
        <Board.News boardType={boardType} category={id} typeForm={[id]} />
      </Board>
    </Box>
  );
};

export default FeatDetail;
