import Box from "components/atoms/box";
import RadioButton from "components/molecules/radioButton";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useFormContext } from "react-hook-form";

const BelowComment = (props) => {
  const { isActiveComment, setPopupPeriod } = props;

  const buttonList = [
    {
      title: "오늘 하루 열지 않기",
      value: "TODAY",
      ariaLabel: "팝업 하단 문구 : 오늘 하루 열지 않기",
    },
    {
      title: "일주일간 열지 않기",
      value: "WEEK",
      ariaLabel: "팝업 하단 문구 : 일주일간 열지 않기",
    },
  ];

  const { setValue } = useFormContext();

  const [selectedRadioOption, setSelectedRadioOption] = useState("");

  useEffect(() => {
    setSelectedRadioOption(isActiveComment);
  }, [isActiveComment]);

  useEffect(() => {
    setValue("bottomPhrase", selectedRadioOption);
    setPopupPeriod && setPopupPeriod(selectedRadioOption);
  }, [selectedRadioOption]);

  return (
    <Box display={Box.DISPLAY.FLEX} al="center" gap="20px" height="100%">
      <RadioButton
        list={buttonList}
        registerName="bottomPhrase"
        selectedRadioOption={selectedRadioOption}
        setSelectedRadioOption={setSelectedRadioOption}
        errorMessage="필수값 입니다."
        name="commentGroup"
        required
      />
    </Box>
  );
};

export default BelowComment;
