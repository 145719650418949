import Box from "components/atoms/box";
import Button from "components/atoms/button";
import Image from "components/atoms/image";
import Input from "components/atoms/input";
import Text from "components/atoms/text";
import ChangeOrderButton from "components/molecules/changeOrderButton";
import TableDeleteBar from "components/molecules/tableDeleteBar";
import useAllCheckbox from "hooks/utils/useAllCheckbox";
import React from "react";
import convertDayWithTime from "utils/convertDayWithTime";
import { useNavigate } from "react-router-dom";
import { useChangeOrderStore } from "store/useChangeStore";
import ChangeOrderContainer from "components/molecules/changeOrder";
import { Draggable } from "react-beautiful-dnd";
import { useDeleteSlideBanner, useUpdateBannerOrder } from "hooks/api/admin/management_site";
import { useEffect } from "react";
import { useState } from "react";
import { common } from "text/admin/common";
import { site } from "text/admin/site";
import { SERVER_URL } from "api/config";
import { adminDefaultUrl } from "api/admin/url";

const BannerList = (props) => {
  const { list, newList, currCategory, setNewList, snsForm } = props;

  const {
    selectRemoveItems,
    orderChange,
    bannerCountLimit,
    default: { remove },
  } = common.alert;
  const { title } = site.banner.list;

  useEffect(() => {
    setNewList(list);
  }, [list]);

  const navigate = useNavigate();

  let keyArr = [];

  const [allKeyList, setAllKeyList] = useState([]);

  useEffect(() => {
    setAllKeyList(keyArr);
  }, [list]);

  const {
    checkAll,
    resetCheckbox,
    checkboxes,
    selectedKeys,
    handleCheckAllChange,
    handleCheckboxChange,
  } = useAllCheckbox(list.length, allKeyList);

  const { isChangeOrderList } = useChangeOrderStore();

  const updateOrderMutate = useUpdateBannerOrder();
  const deleteMutate = useDeleteSlideBanner(resetCheckbox);

  const isNoneSelected = selectedKeys.length === 0;

  const tableHeader = [
    { name: "No.", width: "5%" },
    { name: "배너", width: currCategory === "sns" ? "33%" : "28%" },
    { name: "제목", width: "29%" },
    { name: "등록일시", width: "15%" },
    { name: "관리", width: "19%" },
  ];

  const handleDeleteSelectedBanner = () => {
    const data = { keyList: selectedKeys };
    if (isNoneSelected) {
      alert(selectRemoveItems);
      return;
    }

    const confirm = window.confirm(remove);
    if (confirm) deleteMutate({ categoryName: currCategory, data });
  };

  const handleCreateBanner = () => {
    if (list.length === 5) {
      alert(bannerCountLimit);
      return;
    }
    navigate(`${adminDefaultUrl}/management_site/banner/detail?category=${currCategory}`);
  };

  const handleSaveOrder = () => {
    const confirm = window.confirm(orderChange);
    if (confirm) {
      const newOrderList = newList.map(({ key }, idx) => ({
        key,
        sortOrdr: idx + 1,
      }));
      const data = { list: newOrderList };
      updateOrderMutate({ categoryName: currCategory, data, snsForm });
    }
  };

  const handleUpdateBanner = (key) => {
    navigate(
      `${adminDefaultUrl}/management_site/banner/detail?category=${currCategory}&key=${key}`
    );
  };

  const handleDeleteBanner = (key) => {
    const data = { keyList: [key] };
    const confirm = window.confirm(remove);
    if (confirm) deleteMutate({ categoryName: currCategory, data });
  };

  return (
    <Box maxWidth="1024px">
      <Box display={Box.DISPLAY.FLEX} al="center" jc="space-between" margin="0 0 10px 0">
        <Text as={Text.VARIANT.SUBTITLE}>{title}</Text>
        {currCategory === "sns" ? (
          ""
        ) : (
          <Text as={Text.VARIANT.TEXT} size={Text.SIZE.ADMINCONTENT} color={Text.COLOR.TEXTGRAY}>
            최대 등록 갯수 : 5
          </Text>
        )}
      </Box>
      {currCategory !== "sns" && (
        <TableDeleteBar
          title="배너 등록"
          handleDelete={handleDeleteSelectedBanner}
          handleSave={handleCreateBanner}
        />
      )}
      <Box
        display={Box.DISPLAY.FLEX}
        al="center"
        height="32px"
        borderTop="1px solid"
        borderColor={Box.COLOR.ADMINLINEGRAY}
        bgColor={Box.COLOR.WHITE}
      >
        {currCategory !== "sns" && (
          <Box
            width="4%"
            height="100%"
            display={Box.DISPLAY.FLEX}
            al="center"
            jc="center"
            borderLeft="1px solid"
            borderBottom="1px solid"
            borderColor={Box.COLOR.ADMINLINEGRAY}
            bgColor={Box.COLOR.ADMINCONTENTSBG}
          >
            <Input
              type="checkbox"
              width="16px"
              height="16px"
              borderColor={Input.COLOR.ADMINLINEGRAY}
              onChange={handleCheckAllChange}
              checked={checkAll}
            />
          </Box>
        )}
        {tableHeader.map((header, idx) => (
          <Box
            width={header.width}
            height="100%"
            display={Box.DISPLAY.FLEX}
            al="center"
            jc="center"
            key={idx}
            borderLeft="1px solid"
            borderRight={idx === tableHeader.length - 1 ? "1px solid" : "none"}
            borderBottom="1px solid"
            borderColor={Box.COLOR.ADMINLINEGRAY}
            bgColor={Box.COLOR.ADMINCONTENTSBG}
          >
            <Text
              as={Text.VARIANT.TEXT}
              weight={Text.WEIGHT.BOLD}
              size={Text.SIZE.CLIENTCONTENTDETAIL}
            >
              {header.name}
            </Text>
          </Box>
        ))}
      </Box>
      {!isChangeOrderList &&
        list.map(({ filePath, key, regDate, title }, idx) => {
          const imagePath = filePath && `${SERVER_URL}/upload/attach/${filePath}`;
          keyArr.push(key);

          return (
            <Box
              display={Box.DISPLAY.FLEX}
              al="center"
              key={key}
              height="106px"
              borderBottom="1px solid"
              borderColor={Box.COLOR.ADMINLINEGRAY}
              bgColor={Box.COLOR.WHITE}
            >
              {currCategory !== "sns" && (
                <Box
                  width="4%"
                  height="100%"
                  display={Box.DISPLAY.FLEX}
                  jc="center"
                  borderLeft="1px solid"
                  borderColor={Box.COLOR.ADMINLINEGRAY}
                  al="center"
                >
                  <Input
                    type="checkbox"
                    name={key}
                    width="16px"
                    height="16px"
                    borderColor={Input.COLOR.ADMINLINEGRAY}
                    checked={checkboxes[key] ?? false}
                    onChange={(e) => handleCheckboxChange({ e, key })}
                  />
                </Box>
              )}
              <Box
                width="5%"
                height="100%"
                display={Box.DISPLAY.FLEX}
                jc="center"
                borderLeft="1px solid"
                borderColor={Box.COLOR.ADMINLINEGRAY}
                al="center"
              >
                <Text as={Text.VARIANT.TEXT} size={Text.SIZE.ADMINCONTENT}>
                  {idx + 1}
                </Text>
              </Box>
              <Box
                width={currCategory === "sns" ? "33%" : "28%"}
                height="100%"
                padding="10px"
                display={Box.DISPLAY.FLEX}
                jc="center"
                borderLeft="1px solid"
                borderColor={Box.COLOR.ADMINLINEGRAY}
                al="center"
              >
                <Image src={imagePath} width="100%" height="100%" />
              </Box>
              <Box
                width="29%"
                height="100%"
                display={Box.DISPLAY.FLEX}
                jc="center"
                borderLeft="1px solid"
                borderColor={Box.COLOR.ADMINLINEGRAY}
                al="center"
              >
                <Text as={Text.VARIANT.TEXT} size={Text.SIZE.ADMINCONTENT}>
                  {title}
                </Text>
              </Box>
              <Box
                width="15%"
                height="100%"
                display={Box.DISPLAY.FLEX}
                jc="center"
                borderLeft="1px solid"
                borderColor={Box.COLOR.ADMINLINEGRAY}
                al="center"
                wordBreak="break-all"
              >
                <Text as={Text.VARIANT.TEXT} size={Text.SIZE.ADMINCONTENT}>
                  {convertDayWithTime(regDate)}
                </Text>
              </Box>
              <Box
                width="19%"
                height="100%"
                display={Box.DISPLAY.FLEX}
                jc="center"
                al="center"
                gap="12px"
                borderLeft="1px solid"
                borderRight="1px solid"
                borderColor={Box.COLOR.ADMINLINEGRAY}
              >
                <Button
                  themeStyle="adminCommon"
                  padding="7px 24px"
                  onClick={() => handleUpdateBanner(key)}
                >
                  수정
                </Button>
                {currCategory !== "sns" && (
                  <Button
                    themeStyle="adminCommon"
                    padding="7px 24px"
                    onClick={() => handleDeleteBanner(key)}
                  >
                    삭제
                  </Button>
                )}
              </Box>
            </Box>
          );
        })}
      {isChangeOrderList && (
        <ChangeOrderContainer newList={newList} setNewList={setNewList}>
          {newList.map(({ filePath, key, regDate, title }, idx) => {
            const imagePath = filePath && `${SERVER_URL}/upload/attach/${filePath}`;

            return (
              <Draggable draggableId={key} index={idx} key={key}>
                {(provided) => (
                  <Box
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    display={Box.DISPLAY.FLEX}
                    al="center"
                    key={key}
                    height="106px"
                    borderBottom="1px solid"
                    borderColor={Box.COLOR.ADMINLINEGRAY}
                    bgColor={Box.COLOR.WHITE}
                  >
                    {currCategory !== "sns" && (
                      <Box
                        width="4%"
                        height="100%"
                        display={Box.DISPLAY.FLEX}
                        jc="center"
                        borderLeft="1px solid"
                        borderColor={Box.COLOR.ADMINLINEGRAY}
                        al="center"
                      >
                        <Input
                          type="checkbox"
                          name={`check_${idx}`}
                          width="16px"
                          height="16px"
                          borderColor={Input.COLOR.ADMINLINEGRAY}
                          checked={checkboxes[`check_${idx}`]}
                          onChange={(e) => handleCheckboxChange(e, key)}
                        />
                      </Box>
                    )}
                    <Box
                      width="5%"
                      height="100%"
                      display={Box.DISPLAY.FLEX}
                      jc="center"
                      borderLeft="1px solid"
                      borderColor={Box.COLOR.ADMINLINEGRAY}
                      al="center"
                    >
                      <Text as={Text.VARIANT.TEXT} size={Text.SIZE.ADMINCONTENT}>
                        {idx + 1}
                      </Text>
                    </Box>
                    <Box
                      width={currCategory === "sns" ? "33%" : "28%"}
                      height="100%"
                      display={Box.DISPLAY.FLEX}
                      jc="center"
                      padding="10px"
                      borderLeft="1px solid"
                      borderColor={Box.COLOR.ADMINLINEGRAY}
                      al="center"
                    >
                      <Image src={imagePath} width="100%" height="100%" />
                    </Box>
                    <Box
                      width="29%"
                      height="100%"
                      display={Box.DISPLAY.FLEX}
                      jc="center"
                      borderLeft="1px solid"
                      borderColor={Box.COLOR.ADMINLINEGRAY}
                      al="center"
                    >
                      <Text as={Text.VARIANT.TEXT} size={Text.SIZE.ADMINCONTENT}>
                        {title}
                      </Text>
                    </Box>
                    <Box
                      width="15%"
                      height="100%"
                      display={Box.DISPLAY.FLEX}
                      jc="center"
                      borderLeft="1px solid"
                      borderColor={Box.COLOR.ADMINLINEGRAY}
                      al="center"
                    >
                      <Text as={Text.VARIANT.TEXT} size={Text.SIZE.ADMINCONTENT}>
                        {convertDayWithTime(regDate)}
                      </Text>
                    </Box>
                    <Box
                      width="19%"
                      height="100%"
                      display={Box.DISPLAY.FLEX}
                      jc="center"
                      al="center"
                      gap="12px"
                      borderLeft="1px solid"
                      borderRight="1px solid"
                      borderColor={Box.COLOR.ADMINLINEGRAY}
                    >
                      <Button
                        themeStyle="adminCommon"
                        padding="7px 24px"
                        onClick={() => handleUpdateBanner(key)}
                      >
                        수정
                      </Button>
                      {currCategory !== "sns" && (
                        <Button
                          themeStyle="adminCommon"
                          padding="7px 24px"
                          onClick={() => handleDeleteBanner(key)}
                        >
                          삭제
                        </Button>
                      )}
                    </Box>
                  </Box>
                )}
              </Draggable>
            );
          })}
        </ChangeOrderContainer>
      )}
      <Box display={Box.DISPLAY.FLEX} jc="flex-end" margin="10px 0 0 0">
        <ChangeOrderButton handleSaveOrder={handleSaveOrder} list={list} setNewList={setNewList} />
      </Box>
    </Box>
  );
};

export default BannerList;
