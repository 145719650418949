import Box from "components/atoms/box";
import Text from "components/atoms/text";
import React, { useEffect, useState } from "react";
import convertDayWithTime from "utils/convertDayWithTime";
import HasNotResult from "../HasNotResult";
import useAllCheckbox from "hooks/utils/useAllCheckbox";
import Input from "components/atoms/input";
import Link from "components/atoms/link";
import useUrl from "hooks/utils/useUrl";
import Image from "components/atoms/image";
import TableDeleteBar from "../tableDeleteBar";
import { useDeleteArticle } from "hooks/api/admin/news_board";
import { useRef } from "react";
import MovementArticle from "components/organisms/movementArticle";
import { common } from "text/admin/common";
import { adminDefaultUrl } from "api/admin/url";

const NewsBoardTable = (props) => {
  const { list, tableHeader } = props;

  const {
    default: { remove },
    selectRemoveItems,
    selectMoveItems,
  } = common.alert;

  const ref = useRef();

  const {
    path,
    changeLink,
    query: { page = 1 },
  } = useUrl();

  const currPage = parseInt(page);

  const categoryName = path[2];

  let keyArr = [];
  const [allKeyList, setAllKeyList] = useState([]);

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setAllKeyList(keyArr);
  }, [list]);

  const {
    checkAll,
    checkboxes,
    resetCheckbox,
    selectedKeys,
    handleCheckAllChange,
    handleCheckboxChange,
    cate2Name,
  } = useAllCheckbox(list.length, allKeyList);

  const isNoneSelected = selectedKeys.length === 0;

  const mutate = useDeleteArticle(resetCheckbox);

  const handleDeleteArticle = () => {
    const data = { keyList: selectedKeys };
    if (isNoneSelected) {
      alert(selectRemoveItems);
      return;
    }

    const confirm = window.confirm(remove);
    if (confirm) mutate({ data });
  };

  const handleCreateArticle = () => {
    changeLink(`${adminDefaultUrl}/newsboard/${categoryName}/detail`);
  };

  const handleMove = () => {
    if (isNoneSelected) {
      alert(selectMoveItems);
      return;
    }
    setIsOpen(true);
  };

  return (
    <>
      <TableDeleteBar
        title="게시물 등록"
        handleDelete={handleDeleteArticle}
        handleSave={handleCreateArticle}
        handleMove={categoryName !== "all" && handleMove}
        hasRadius={false}
      />
      <Box
        display={Box.DISPLAY.FLEX}
        height="47px"
        borderTop="1px solid"
        borderColor={Box.COLOR.LINEGRAY}
      >
        <Box
          width="4%"
          height="100%"
          display={Box.DISPLAY.FLEX}
          al="center"
          jc="center"
          borderLeft="1px solid"
          borderBottom={list.length === 0 ? "1px solid" : ""}
          borderColor={Box.COLOR.ADMINLINEGRAY}
          bgColor={Box.COLOR.ADMINCONTENTSBG}
        >
          <Input
            type="checkbox"
            width="16px"
            height="16px"
            borderColor={Input.COLOR.ADMINLINEGRAY}
            onChange={handleCheckAllChange}
            checked={checkAll}
          />
        </Box>
        {tableHeader.map(({ title, width, ariaLabel }, idx) => (
          <Box
            display={title === "카테고리" ? Box.DISPLAY.BLOCK : Box.DISPLAY.FLEX}
            al="center"
            jc="center"
            width={width}
            padding={title === "카테고리" ? "0" : "8px 0"}
            key={idx}
            borderBottom={list.length === 0 ? "1px solid" : ""}
            borderRight={idx === tableHeader.length - 1 ? "1px solid" : ""}
            borderLeft="1px solid"
            borderColor={Box.COLOR.LINEGRAY}
            bgColor={Box.COLOR.ADMINCONTENTSBG}
          >
            {title === "카테고리" ? (
              <Box height="100%">
                <Box height="50%" display={Box.DISPLAY.FLEX} al="center">
                  <Text
                    as={Text.VARIANT.TEXT}
                    width="100%"
                    height="100%"
                    weight={Text.WEIGHT.BOLD}
                    size={Text.SIZE.CLIENTCONTENTDETAIL}
                    textAlign="center"
                    ariaLabel={ariaLabel}
                    borderBottom="1px solid"
                    borderColor={Box.COLOR.LINEGRAY}
                    lineHeight="2"
                  >
                    {title}
                  </Text>
                </Box>
                <Box display={Box.DISPLAY.FLEX} al="center" jc="center">
                  <Box
                    height="50%"
                    display={Box.DISPLAY.FLEX}
                    al="center"
                    jc="center"
                    flex="1 1 0"
                    borderRight="1px solid"
                    borderColor={Box.COLOR.LINEGRAY}
                  >
                    <Text
                      as={Text.VARIANT.TEXT}
                      weight={Text.WEIGHT.BOLD}
                      size={Text.SIZE.CLIENTCONTENTDETAIL}
                      textAlign="center"
                      lineHeight="2"
                      ariaLabel={ariaLabel}
                    >
                      1차
                    </Text>
                  </Box>
                  <Box height="50%" display={Box.DISPLAY.FLEX} al="center" jc="center" flex="1 1 0">
                    <Text
                      as={Text.VARIANT.TEXT}
                      weight={Text.WEIGHT.BOLD}
                      size={Text.SIZE.CLIENTCONTENTDETAIL}
                      textAlign="center"
                      lineHeight="2"
                      ariaLabel={ariaLabel}
                    >
                      2차
                    </Text>
                  </Box>
                </Box>
              </Box>
            ) : (
              <Text
                as={Text.VARIANT.TEXT}
                weight={Text.WEIGHT.BOLD}
                size={Text.SIZE.CLIENTCONTENTDETAIL}
                textAlign="center"
                ariaLabel={ariaLabel}
              >
                {title}
              </Text>
            )}
          </Box>
        ))}
      </Box>
      {list.length === 0 && <HasNotResult />}
      {list.length !== 0 &&
        list.map(
          ({ boardName, codeName, title, writer = "", regDate, hits, key, filePath }, idx) => {
            const pageIdx = currPage > 1 ? (currPage - 1) * 10 + (idx + 1) : idx + 1;
            keyArr.push(key);

            return (
              <Box
                key={idx}
                borderTop={idx === 0 ? "1px solid" : ""}
                borderBottom="1px solid"
                borderColor={Box.COLOR.LINEGRAY}
                display={Box.DISPLAY.FLEX}
                height="53px"
              >
                <Box
                  width="4%"
                  height="100%"
                  display={Box.DISPLAY.FLEX}
                  jc="center"
                  borderLeft="1px solid"
                  borderColor={Box.COLOR.ADMINLINEGRAY}
                  al="center"
                >
                  <Input
                    type="checkbox"
                    name={key}
                    width="16px"
                    height="16px"
                    borderColor={Input.COLOR.ADMINLINEGRAY}
                    checked={checkboxes[key] ?? false}
                    onChange={(e) => handleCheckboxChange({ e, key, codeName })}
                  />
                </Box>
                <Box
                  width="7%"
                  display={Box.DISPLAY.FLEX}
                  jc="center"
                  al="center"
                  borderLeft="1px solid"
                  borderColor={Box.COLOR.LINEGRAY}
                >
                  <Text
                    as={Text.VARIANT.TEXT}
                    size={Text.SIZE.CLIENTCONTENTDETAIL}
                    textAlign="center"
                    ariaLabel={`게시물 번호 : ${pageIdx} `}
                  >
                    {pageIdx}
                  </Text>
                </Box>
                <Box
                  width="8%"
                  display={Box.DISPLAY.FLEX}
                  jc="center"
                  al="center"
                  borderLeft="1px solid"
                  borderColor={Box.COLOR.LINEGRAY}
                  padding="0 10px"
                  wordBreak="break-all"
                >
                  <Text
                    as={Text.VARIANT.TEXT}
                    size={Text.SIZE.CLIENTCONTENTDETAIL}
                    textAlign="center"
                    ariaLabel={boardName}
                  >
                    {boardName}
                  </Text>
                </Box>
                <Box
                  width="8%"
                  display={Box.DISPLAY.FLEX}
                  jc="center"
                  al="center"
                  borderLeft="1px solid"
                  borderColor={Box.COLOR.LINEGRAY}
                  padding="0 10px"
                  wordBreak="break-all"
                >
                  <Text
                    as={Text.VARIANT.TEXT}
                    size={Text.SIZE.CLIENTCONTENTDETAIL}
                    textAlign="center"
                    ariaLabel={codeName}
                  >
                    {codeName}
                  </Text>
                </Box>
                <Box
                  width="38%"
                  display={Box.DISPLAY.FLEX}
                  al="center"
                  gap="5px"
                  borderLeft="1px solid"
                  borderColor={Box.COLOR.LINEGRAY}
                  padding="0 14px"
                  wordBreak="break-all"
                >
                  <Link
                    link={`${adminDefaultUrl}/newsboard/${categoryName}/detail?categoryName=${categoryName}&key=${key}`}
                  >
                    <Text
                      as={Text.VARIANT.TEXT}
                      size={Text.SIZE.CLIENTCONTENTDETAIL}
                      textAlign="left"
                      decoration="underline"
                      lineHeight="1.2"
                      ariaLabel={title}
                    >
                      {title}
                      {filePath && (
                        <Box
                          as="span"
                          display={Box.DISPLAY.INLINE_FLEX}
                          position="relative"
                          top="2px"
                        >
                          <Image
                            src="/assets/admin/clip.png"
                            width="13px"
                            height="13px"
                            margin="0 0 0 5px"
                          />
                        </Box>
                      )}
                    </Text>
                  </Link>
                </Box>
                <Box
                  width="14%"
                  display={Box.DISPLAY.FLEX}
                  jc="center"
                  al="center"
                  borderLeft="1px solid"
                  borderColor={Box.COLOR.LINEGRAY}
                  padding="0 10px"
                  wordBreak="break-all"
                >
                  <Text
                    as={Text.VARIANT.TEXT}
                    size={Text.SIZE.CLIENTCONTENTDETAIL}
                    textAlign="center"
                    ariaLabel={writer}
                  >
                    {writer?.includes("웹진관리자") ? "웹진관리자" : writer}
                  </Text>
                </Box>
                <Box
                  width="14%"
                  display={Box.DISPLAY.FLEX}
                  jc="center"
                  al="center"
                  borderLeft="1px solid"
                  borderColor={Box.COLOR.LINEGRAY}
                >
                  <Text
                    as={Text.VARIANT.TEXT}
                    size={Text.SIZE.CLIENTCONTENTDETAIL}
                    textAlign="center"
                    ariaLabel={regDate}
                  >
                    {convertDayWithTime(regDate)}
                  </Text>
                </Box>
                <Box
                  width="7%"
                  display={Box.DISPLAY.FLEX}
                  jc="center"
                  al="center"
                  borderLeft="1px solid"
                  borderRight="1px solid"
                  borderColor={Box.COLOR.LINEGRAY}
                >
                  <Text
                    as={Text.VARIANT.TEXT}
                    size={Text.SIZE.CLIENTCONTENTDETAIL}
                    textAlign="center"
                    ariaLabel={hits}
                  >
                    {hits || 0}
                  </Text>
                </Box>
              </Box>
            );
          }
        )}
      {isOpen && (
        <MovementArticle
          width="418px"
          height="290px"
          setIsOpen={setIsOpen}
          ref={ref}
          currCate1={categoryName}
          currCate2={cate2Name}
          keyList={selectedKeys}
        />
      )}
    </>
  );
};

export default NewsBoardTable;
