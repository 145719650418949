import serviceText from "./info.json";

serviceText.privacy.content = [
  `'한국과학기술단체총연합회' 은 (이하 '한국과총') 고객님의 개인정보를 중요시하며, "정보통신망 이용촉진 및 정보보호"에 관한 법률을 준수하고 있습니다. 한국과총은 개인정보취급방침을 통하여 고객님께서 제공하시는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며, 개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려드립니다.

한국과총은 개인정보취급방침을 개정하는 경우 웹사이트 공지사항(또는 개별공지)을 통하여 공지할 것입니다.

본 방침은 : 2012 년 03 월 31 일 부터 시행됩니다`,
  `- 한국과총은 회원가입, 상담, 서비스 신청 등등을 위해 아래와 같은 개인정보를 수집하고 있습니다.
- 수집항목 : 이름 , 생년월일 , 성별 , 로그인ID , 이메일 , 직업 , 회사명 , 부서 , 직책 , 회사전화번호 , 서비스 이용기록 , 접속 로그 , 접속 IP 정보
- 개인정보 수집방법 : 회원가입, 서면양식 , 전화/팩스를 통한 회원가입 , 과총 행사 참여 신청, 학회로부터의 제공`,
  `- 한국과총은 수집한 개인정보를 다음의 목적을 위해 활용합니다.
- 서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금정산 콘텐츠 제공
- 회원 관리 : 회원제 서비스 이용에 따른 본인확인 , 불량회원의 부정 이용 방지와 비인가 사용 방지 , 가입 의사 확인 , 불만처리 등 민원처리 , 고지사항 전달
- 마케팅 및 광고에 활용 : 접속 빈도 파악 또는 회원의 서비스 이용에 대한 통계`,
  `- 이용자가 한국과총에서 제공하는 서비스를 이용하는 동안 한국과총은 이용자의 개인정보를 계속적으로 보유하며 서비스 제공 등을 위해 이용합니다. 이용자가 직접 회원탈퇴를 신청하시거나 개인정보 삭제를 요청한 경우 추후 열람이나 이용이 불가능한 상태로 처리됩니다.`,
  `- 한국과총은 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다. 파기절차 및 방법은 다음과 같습니다.
- 파기절차 : 회원님이 회원가입 등을 위해 입력하신 정보는 목적이 달성된 후 별도의 DB로 옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라(보유 및 이용기간 참조) 일정 기간 저장된 후 파기되어집니다. 별도 DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 보유되어지는 이외의 다른 목적으로 이용되지 않습니다.
- 파기방법 : 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다. | 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.`,
  `- 한국과총은 이용자의 개인정보를 원칙적으로 외부에 제공하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.
- 이용자들이 사전에 동의한 경우
- 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우`,
  `- 한국과총은 고객님의 동의없이 고객님의 정보를 외부 업체에 위탁하지 않습니다. 향후 그러한 필요가 생길 경우, 위탁 대상자와 위탁 업무 내용에 대해 고객님에게 통지하고 필요한 경우 사전 동의를 받도록 하겠습니다.`,
  `- 이용자 및 법정 대리인은 언제든지 등록되어 있는 자신 혹은 당해 만 14세 미만 아동의 개인정보를 조회하거나 수정할 수 있으며 가입해지를 요청할 수도 있습니다.
- 이용자 혹은 만 14세 미만 아동의 개인정보 조회?수정을 위해서는 '개인정보변경'(또는 '회원정보수정' 등)을 가입해지(동의철회)를 위해서는 "회원탈퇴"를 클릭하여 본인 확인 절차를 거치신 후 직접 열람, 정정 또는 탈퇴가 가능합니다. 혹은 개인정보관리책임자에게 서면, 전화 또는 이메일로 연락하시면 지체없이 조치하겠습니다.
- 귀하가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체없이 통지하여 정정이 이루어지도록 하겠습니다.
- 한국과총은 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된 개인정보는 "회사가 수집하는 개인정보의 보유 및 이용기간"에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.`,
  `- 쿠키 등 인터넷 서비스 이용 시 자동 생성되는 개인정보를 수집하는 장치를 운영하지 않습니다.`,
  `- 한국과총은 고객의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 아래와 같이 관련 부서 및 개인정보관리책임자를 지정하고 있습니다.
- 고객서비스담당 부서 : 회원지원부
- 전화번호 : 02-3420-1317
- 이메일 : kofst@kofst.or.kr
- 귀하께서는 회사의 서비스를 이용하시며 발생하는 모든 개인정보보호 관련 민원을 개인정보관리책임자 혹은 담당부서로 신고하실 수 있습니다. 한국과총은 이용자들의 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다.`,
  `- 한국과학기술단체총연합회(이하 “과총”이라 함)는 영상정보처리기기 운영․관리 방침을 통해 과총에서 처리하는 영상정보가 어떠한 용도와 방식으로 이용․관리되고 있는지 알려드립니다. (공고일자 : 2012년 7월 1일 / 시행일자 : 2012년 7월 1일)
1. 영상정보처리기기의 설치 근거 및 설치 목적
- 과총은 개인정보보호법 제25조 제1항에 따라 다음과 같은 목적으로 영상정보처리기기를 설치․운영 합니다.
- 입주기관 및 방문객의 안전을 위한 범죄 예방
- 국제회의장 운영․관리
2. 설치 대수, 설치 위치 및 촬영범위
- 설치 대수 : 118대
- 설치 위치 및 촬영 범위 : 1관/2관, 로비, 동,서쪽 비상계단, 1층 후문, 현관입구, 승강기 내부, 2층 복도, 신관 지하주차장, 지하1층 국제회의장 로비 및 실내 등
3. 관리책임자 및 접근권한자
- 귀하의 영상정보를 보호하고 개인영상정보와 관련한 불만을 처리하기 위하여 아래와 같이 개인영상정보 보호책임자를 두고 있습니다.
- 설치 위치 및 촬영 범위 :
- 관리책임자 : 김선일 회원지원부장 02-3420-1291
- 접근권한자 : 안정호 회원지원부 02-3420-1317
4. 영상정보의 촬영시간, 보관기간, 보관장소 및 처리방법
- 촬영시간 : 24시간
- 보관기간 : 촬영일로부터 30일
- 보관장소 : 방재실, 방송실
- 처리방법 : 개인영상정보의 목적외 이용, 제3자 제공, 파기, 열람 등 요구에 관한 사항을 기록․관리하고, 보관기간 만료시 복원이 불가능한 방법으로 영구 삭제(출력물의 경우 파쇄 또는 소각)합니다.
5. 개인영상정보의 확인 방법 및 장소에 관한 사항- 확인 방법 : 영상정보 관리책임자에게 미리 연락하고 과총을 방문하시면 확인 가능합니다.
- 확인 장소 : 방재실 및 방송실
6. 정보주체의 영상정보 열람 등 요구에 대한 조치
- 귀하는 개인영상정보에 관하여 열람 또는 존재확인․삭제를 원하는 경우 언제든지 영상정보처리기기 운영자에게 요구하실 수 있습니다. 단, 귀하가 촬영된 개인영상정보 및 명백히 정보주체의 급박한 생명, 신체, 재산의 이익을 위하여 필요한 개인영상정보에 한정됩니다. 과총은 개인영상정보에 관하여 열람 또는 존재확인․삭제를 요구한 경우 지체 없이 필요한 조치를 하겠습니다.
7. 영상정보의 안전성 확보조치
- 과총에서 처리하는 영상정보는 암호화 조치 등을 통하여 안전하게 관리되고 있습니다. 또한 과총은 개인영상정보보호를 위한 관리적 대책으로서 개인정보에 대한 접근 권한을 차등 부여하고 있고, 개인영상정보의 위․변조 방지를 위하여 개인영상정보의 생성 일시, 열람시 열람 목적․열람자․열람 일시 등을 기록하여 관리하고 있습니다. 이 외에도 개인영상정보의 안전한 물리적 보관을 위하여 잠금장치를 설치하고 있습니다.
8. 개인정보 처리방침 변경에 관한 사항
- 이 영상정보처리기기 운영․관리방침은 2012년 7월 1일에 제정되었으며 법령ㆍ정책 또는 보안기술의 변경에 따라 내용의 추가ㆍ삭제 및 수정이 있을 시에는 시행하기 최소 7일전에 과총 홈페이지를 통해 변경사유 및 내용 등을 공지하도록 하겠습니다.`,
];

serviceText.privacy.common = [
  `기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.
개인정보침해신고센터 (www.1336.or.kr/국번없이 118)
정보보호마크인증위원회 (www.eprivacy.or.kr/02-580-0533~4)
대검찰청 인터넷범죄수사센터 (http://icic.sppo.go.kr/02-3480-3600)
경찰청 사이버테러대응센터 (www.ctrc.go.kr/02-392-0330)`,
];

serviceText.termsOfUse.content = [
  `- 이 약관은 한국과학기술단체총연합회가 제공하는 홈페이지의(이하 "서비스"라 함)의 이용에 관한 조건, 절차 및 기타 필요한 사항을 규정하는 것을 목적으로 합니다.`,
  `- 이 약관은 서비스 메뉴를 통해 공시함으로써 효력을 발생합니다.
- 한국과학기술단체총연합회는 합리적 사유가 발생한 경우에는 이 약관을 변경할 수 있으며, 약관은 변경하기 7일전에 공지하며, 회원에게 불리한 경우에는 30일 이전에 공지토록 합니다.
- 이용자는 변경된 약관사항에 동의하지 않으면, 언제나 서비스 이용을 중단하고 이용계약을 해지할 수 있습니다. 약관의 효력발생일 이후의 계속적인 서비스 이용은 약관의 변경사항에 이용자가 동의한 것으로 간주됩니다.`,
  `- 이 약관에 명시되지 않은 사항은 관계 법령에 규정 되어있을 경우 그 규정에 따르며, 그렇지 않은 경우에는 일반적인 관례에 따릅니다.`,
  `- 이용자 : 서비스에 가입한 자
- 이용자번호(ID) : 이용자 식별과 이용자의 서비스 이용을 위하여 이용계약 체결시 이용자가 설정한 문자와 숫자의 조합
- 비밀번호 : 이용자의 비밀을 보호하기 위하여 이용자 자신이 설정한 문자 또는 숫자의 조합
- 단말기 : 서비스 제공을 받기 위해 이용자가 설치한 개인용 컴퓨터 등의 기기-
 서비스 이용 : 이용자가 단말기를 이용하여 한국과학기술단체총연합회의 주전산기에 접속하여 정보를 이용하는 것
- 이용계약 : 서비스를 제공받기 위하여 이 약관으로 한국과학기술단체총연합회과 이용자간의 체결하는 계약을 말함`,
  `- 이 약관은 서비스 메뉴를 통해 공시함으로써 효력을 발생합니다.
- 한국과학기술단체총연합회는 합리적 사유가 발생한 경우에는 이 약관을 변경할 수 있으며, 약관은 변경하기 7일전에 공지하며, 회원에게 불리한 경우에는 30일 이전에 공지토록 합니다.
- 이용자는 변경된 약관사항에 동의하지 않으면, 언제나 서비스 이용을 중단하고 이용계약을 해지할 수 있습니다. 약관의 효력발생일 이후의 계속적인 서비스 이용은 약관의 변경사항에 이용자가 동의한 것으로 간주됩니다.`,
  `- 서비스를 이용하고자 하는 자는 한국과학기술단체총연합회이 지정한 양식에 따라 온라인신청을 이용하여 가입 신청을 해야 합니다.`,
  `* 한국과학기술단체총연합회은 다음 각 호에 해당하는 경우에는 이용계약의 승낙을 유보할 수 있습니다.
- 설비에 여유가 없는 경우
- 기술상에 지장이 있는 경우
- 기타 서비스의 효율적인 운영 등을 위하여 필요하다고 인정되는 경우
* 한국과학기술단체총연합회은 다음 각 호에 해당하는 이용계약 신청에 대하여는 이를 거절할 수 있습니다.
- 다른 사람의 명의를 사용하여 이용신청을 하였을 때
- 이용계약 신청서의 내용을 허위로 기재하였을 때
- 사회의 안녕, 질서 혹은 미풍양속을 저해할 목적으로 이용신청을 한 때`,
  `* 이용자는 다음 사항을 변경하고자 하는 경우 서비스에 접속하여 서비스 내의 기능을 이용하여 변경할 수 있습니다.
- 개인 이력사항- 비밀번호
- 기타 한국과학기술단체총연합회이 인정하는 사항`,
  `- 서비스의 이용 시간은 한국과학기술단체총연합회의 업무 및 기술상 특별한 지장이 없는 한 연중무휴, 1일 24시간(00:00-24:00)을 원칙으로 합니다. 다만 정기점검 등의 필요로 한국과학기술단체총연합회이 정한 날이나 시간은 그러하지 아니합니다.`,
  `- 이용자번호 및 비밀번호에 대한 모든 관리책임은 이용자에게 있습니다.
- 명백한 사유가 있는 경우를 제외하고는 이용자가 이용자번호를 공유, 양도 또는 변경할 수 없습니다.
- 이용자에게 부여된 이용자번호에 의하여 발생되는 서비스 이용 상의 과실 또는 제3자에의한 부정사용 등에 대한 모든 책임은 이용자에게 있습니다.`,
  `* 이용자가 서비스 이용계약을 해지하고자 하는 때에는 온라인으로 해지신청을 하여야 합니다.
* 한국과학기술단체총연합회은 이용자가 다음 각 호에 해당하는 경우 사전통지 없이 이용계약을 해지하거나 전부 또는 일부의 서비스 제공을 중지할 수 있습니다.
- 타인의 이용자번호를 사용한 경우
- 다량의 정보를 전송하여 서비스의 안정적 운영을 방해하는 경우
- 수신자의 의사에 반하는 광고성 정보, 전자우편을 전송하는 경우
- 정보통신설비의 오작동이나 정보 등의 파괴를 유발하는 컴퓨터 바이러스 프로그램등을 유포하는 경우
- 정보통신윤리위원회로부터의 이용제한 요구 대상인 경우
- 선거관리위원회의 유권해석 상의 불법선거운동을 하는 경우
- 서비스를 통해 얻은 정보를 한국과학기술단체총연합회의 동의없이 상업적으로 이용하는 경우- 기타 한국과학기술단체총연합회이 부적당하다고 판단하는 경우
* 전항의 규정에 의하여 이용자의 이용을 제한하는 경우와 제한의 종류 및 기간 등 구체적인 기준은 공지, 서비스 이용안내 등에서 별도로 정하는 바에 의합니다.
* 해지 처리된 이용자의 정보는 서비스 개선을 위한 자료로서 해지 후 1년간 별도로 저장될 수 있습니다.`,
  `* 한국과학기술단체총연합회은 서비스용 설비의 용량에 여유가 없다고 판단되는 경우 필요에 따라 이용자가 게재 또는 등록한 내용물을 삭제할 수 있습니다.
* 한국과학기술단체총연합회은 서비스용 설비의 용량에 여유가 없다고 판단되는 경우 이용자의 서비스 이용을 부분적으로 제한할 수 있습니다.
* 제 1 항 및 제 2 항의 경우에는 당해 사항을 사전에 온라인을 통해서 공지합니다.
* 한국과학기술단체총연합회은 이용자가 게재 또는 등록하는 서비스내의 내용물이 다음 각호에 해당한다고 판단되는 경우에 이용자에게 사전 통지없이 삭제할 수 있습니다.
- 다른 이용자 또는 제 3자를 비방하거나 중상모략으로 명예를 손상시키는 경우
- 공공질서 및 미풍양속에 위반되는 내용의 정보, 문장, 도형 등을 유포하는 경우
- 반국가적, 반사회적, 범죄적 행위와 결부된다고 판단되는 경우
- 다른 이용자 또는 제3자의 저작권 등 기타 권리를 침해하는 경우
- 게시 기간이 규정된 기간을 초과한 경우
- 이용자의 조작 미숙이나 광고목적으로 동일한 내용의 게시물을 5회이상 반복 등록하였을 경우
- 기타 관계 법령에 위배된다고 판단되는 경우`,
  `* 한국과학기술단체총연합회은 다음 각 호에 해당하는 경우 서비스 제공을 중지할 수 있습니다.
- 서비스용 설비의 보수 또는 공사로 인한 부득이한 경우
- 전기통신사업법에 규정된 기간통신사업자가 전기통신 서비스를 중지했을 때
* 한국과학기술단체총연합회은 국가비상사태, 서비스 설비의 장애 또는 서비스 이용의 폭주 등으로 서비스 이용에 지장이 있는 때에는 서비스 제공을 중지하거나 제한 할 수 있습니다.`,
  `* 한국과학기술단체총연합회은 정보통신이용촉진등에 관한 법률등 관계법령에 따라 제공받는 이용자의 개인정보 및 서비스 이용 중 생성되는 개인정보를 보호하여야 합니다.
* 한국과학기술단체총연합회의 관리책임자는 홈페이지 이용자 관리담당 부서장(한국과학기술단체총연합회장)이며,개인정보 관리책임자의 성명은 별도로 공지하거나 서비스 안내에 게시합니다.
* 이용자가 자신의 개인정보를 전송 등의 방법으로 한국과학기술단체총연합회에 제공하는 행위는 한국과학기술단체총연합회의 개인정보 수집 및 이용 등에 동의하는 것으로간주됩니다. 한국과학기술단체총연합회의 이용자 개인정보의 수집목적 및 이용목적은 다음 각 호와 같습니다.
- 서비스 제공 등 이용계약의 이행
- 마케팅 정보 생성 및 이용 고객별 안내
- 광고 전송 또는 우송
* 한국과학기술단체총연합회은 개인정보를 이용고객의 별도의 동의 없이 제3자에게 제공하지 않습니다. 다만, 다음 각 호의 경우는 이용고객의 별도 동의 없이 제3자에게 이용고객의 개인정보를 제공할 수 있습니다.
- 수사상의 목적에 따른 수사기관의 서면 요구가 있는 경우에 수사협조의 목적으로 국가수사 기관에 성명, 주소 등 신상정보를 제공하는 경우
- 신용정보의 이용 및 보호에 관한 법률, 전기통신관련법률 등 법률에 특별한 규정이 있는 경우
- 통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우로서 특정 개인을 식별할 수 없는 형태로 제공하는 경우
* 이용자는 언제나 자신의 개인정보를 열람할 수 있으며, 스스로 오류를 수정할 수 있습니다. 열람 및 수정은 원칙적으로 이용신청과 동일한 방법으로 하며, 자세한 방법은 공지, 이용 안내에 정한 바에 따릅니다.
* 이용자는 언제나 이용계약을 해지함으로써 개인정보의 수집 및 이용에 대한 동의, 목적 이외의 사용에 대한 별도 동의,제3자 제공에 대한 별도 동의를 철회할 수 있으며, 해지방법은 이 약관에서 별도로 규정한 바에 따릅니다.`,
  `- 서비스 이용료는 무료로 합니다. 단, 한국과학기술단체총연합회의 정책에 따라 이용 요금이 유료화될 경우에는 온라인으로 서비스 화면에 게시합니다.`,
  `제 19 조 (게재된 자료에 대한 권리)
* 게시물에 대한 권리와 책임은 게시자에게 있으며, 한국과학기술단체총연합회은 게시자의 동의없이는 이를 영리적 목적으로 사용할 수 없습니다. 단, 비영리적 목적인 경우, 한국과학기술단체총연합회은 게시자의 동의 없이도 이를 사용할 수 있으며 서비스내의 게재권을 갖습니다.
* 게시자의 사전 동의가 없이는 이용자는 서비스를 이용하여 얻은 정보를 가공, 판매하는행위 등 서비스에 게재된 자료를 상업적 목적으로 이용할 수 없습니다.`,
  `이용자는 한국과학기술단체총연합회에서 무료로 제공되는 서비스와 관련하여 회원에게 어떠한 손해가 발생하더라도 한국과학기술단체총연합회의 고의, 실수 또는 범죄행위로 인해 발생한 손해를 제외하고 이에 대하여 책임을 부담하지 아니합니다.`,
  `서비스 이용에 관한 분쟁에 대하여는 대한민국 법을 적용하며, 본 분쟁으로 인한 소는 민사소송법상의 관할법원에 제기합니다.`,
  `(시행일) 이 약관은 2004년 4월 1일부터 시행합니다.`,
];

export default serviceText;
