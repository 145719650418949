import Box from "components/atoms/box";
import Text from "components/atoms/text";
import useContextForm from "hooks/utils/useContextForm";
import React from "react";
import AdminTable from "../adminTable";
import { useAuthorityList } from "hooks/api/admin/manager";
import AuthorityTable from "components/molecules/authorityTable";
import { management } from "text/admin/management";
import useUrl from "hooks/utils/useUrl";

const AdminAuthorityList = (props) => {
  const { searchCondition, searchValue } = props;

  const {
    query: { page },
  } = useUrl();

  const { tableHeader, defaultValues, title } = management.authority.table;

  const { Provider } = useContextForm(defaultValues);

  return (
    <Box maxWidth="1024px" display={Box.DISPLAY.FLEX} flexDirection="column" margin="40px 0 0 0">
      <Text as={Text.VARIANT.SUBTITLE} ariaLabel={title} margin="0 0 10px 0">
        {title}
      </Text>
      <Provider>
        <AdminTable
          type={["countBar"]}
          hasSelectMenu={false}
          page={page}
          //
          api={useAuthorityList}
          tableHeader={tableHeader}
          searchValue={searchValue}
          searchCondition={searchCondition}
          //
          width="120px"
        >
          <AuthorityTable />
        </AdminTable>
      </Provider>
    </Box>
  );
};

export default AdminAuthorityList;
