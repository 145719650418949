import { SERVER_URL } from "api/config";
import axios from "axios";

const downloadFile = (filePath, fileName, ext, originFileName) => {
  const url = `${SERVER_URL}${filePath}${fileName}.${ext}`;
  axios.get(url, { responseType: "blob" }).then((res) => {
    const href = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement("a");
    link.href = href;
    link.setAttribute("download", originFileName);
    link.click();
    if (url.includes("admin")) {
      window.alert("다운로드가 완료되었습니다.");
    }
    link.remove();
    window.URL.revokeObjectURL(href);
  });
};

export default downloadFile;
