import {
  getBroadcastMainNews,
  getKofstMainNews,
  getTodayMainNews,
  getWeekMainNews,
  getHeadlineMainNews,
  getIctMainNews,
  getScienceMainNews,
  getPeopleMainNews,
  getFeatureMainNews,
  getBreakingMainNews,
  getRankWeekNews,
  getRankMonthNews,
  getCoverageNews,
} from "api/news";
import { getGroupMainNotice, getScienceMainNotice } from "api/notice";
import { useEffect } from "react";
import { useQuery, useQueryClient } from "react-query";

export const useMainNews = (key, api) => {
  const { data, isLoading, isSuccess, isError } = useQuery(key, api);

  const list = data?.data.list;

  return {
    list,
    isLoading,
    isSuccess,
    isError,
  };
};

export const useWeekNews = () => {
  return useMainNews("week", getWeekMainNews);
};

export const useHeadLineNews = () => {
  return useMainNews("headline", getHeadlineMainNews);
};

export const useKofstNews = () => {
  return useMainNews("kofst", getKofstMainNews);
};

export const useBroadCastNews = () => {
  return useMainNews("broadcast", getBroadcastMainNews);
};

export const useBreakingNews = () => {
  return useMainNews("breaking", getBreakingMainNews);
};

export const useTodayNews = () => {
  return useMainNews("today", getTodayMainNews);
};

export const useIctNews = () => {
  return useMainNews("ict", getIctMainNews);
};

export const useScienceNews = () => {
  return useMainNews("science", getScienceMainNews);
};

export const usePeopleNews = () => {
  return useMainNews("people", getPeopleMainNews);
};

export const useFeatureNews = () => {
  return useMainNews("feature", getFeatureMainNews);
};

export const useCoverageNews = () => useMainNews("coverage", getCoverageNews);

export const usePreFetchBanner = () => {
  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.prefetchQuery("scienceNotice", getScienceMainNotice);
    queryClient.prefetchQuery("group", getGroupMainNotice);
    queryClient.prefetchQuery("breaking", getBreakingMainNews);
    queryClient.prefetchQuery("today", getTodayMainNews);
    queryClient.prefetchQuery("ict", getIctMainNews);
    queryClient.prefetchQuery("science", getScienceMainNews);
    queryClient.prefetchQuery("people", getPeopleMainNews);
    queryClient.prefetchQuery("feature", getFeatureMainNews);
  }, [queryClient]);
};

export const useRankWeekNews = (type) => {
  return useMainNews(["week", type], () => getRankWeekNews(type));
};
export const useRankMonthNews = (type) => {
  return useMainNews(["month", type], () => getRankMonthNews(type));
};

export const usePreFetchRankNews = (type) => {
  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.prefetchQuery(["week", type], () => getRankWeekNews(type));
    queryClient.prefetchQuery(["month", type], () => getRankMonthNews(type));
  }, [queryClient]);
};
