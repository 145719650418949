import Box from "components/atoms/box";
import Text from "components/atoms/text";
import Blank from "components/molecules/blank";
import List from "components/molecules/list";
import SearchInput from "components/molecules/searchInput";
import NewsList from "components/organisms/newsList";
import dayjs from "dayjs";
import { useSearchNews } from "hooks/api/search";
import useUrl from "hooks/utils/useUrl";
import React, { useEffect, useState } from "react";
import searchText from "text/search";

const { search } = searchText;

const Search = () => {
  const {
    query: { searchKeyword, year = dayjs().format("YYYY"), page = 1 },
    changeQuery,
  } = useUrl();
  const [yearList, setYearList] = useState([]);
  const [total, setTotal] = useState(0);

  const handleSearch = (searchKeyword, year) => {
    changeQuery({ searchKeyword, year });
  };

  useEffect(() => {
    let currentYear = dayjs().year();
    let years = [];

    for (let year = currentYear; year >= 2003; year--) {
      years.push({ value: year });
    }

    setYearList(years);
  }, []);

  return (
    <Box
      width="100%"
      display={Box.DISPLAY.FLEX}
      flexDirection="column"
      al="center"
      padding={"80px 0 200px"}
      id="board"
    >
      <Box
        width={"1260px"}
        padding={"0 40px 200px"}
        bgColor={Box.COLOR.LIGHTGRAY}
        borderRadius="10px"
      >
        <Box
          display={Box.DISPLAY.FLEX}
          flexDirection="column"
          al="center"
          width={"100%"}
          borderBottom="1px solid"
          borderColor={Box.COLOR.LINEGRAY}
          padding={"0 0 100px 0"}
        >
          <Text
            as={Text.VARIANT.TEXT}
            size={Text.SIZE.CLIENTTITLE}
            weight={Text.WEIGHT.BOLD}
            margin={"80px 0 60px"}
          >
            <Text
              as={Text.VARIANT.SPAN}
              color={Text.COLOR.SECONDARY}
              weight={Text.WEIGHT.BOLD}
            >
              {searchKeyword}
            </Text>{" "}
            {search[0]}
            <Text
              as={Text.VARIANT.SPAN}
              color={Text.COLOR.SECONDARY}
              weight={Text.WEIGHT.BOLD}
            >
              {total}
            </Text>{" "}
            {search[1]}
          </Text>
          <SearchInput
            theme={SearchInput.THEME.SEARCH}
            submit={handleSearch}
            isSelect
            selectList={yearList}
            value={year}
          />
        </Box>

        <NewsList>
          <List
            api={useSearchNews}
            params={{ searchKeyword, getDate: year, page }}
            setTotal={setTotal}
          >
            {(list) => (
              <>
                {list.length === 0 && <Blank />}
                {list.length !== 0 && (
                  <NewsList.LAYOUT.TextNews
                    list={list}
                    highlight={searchKeyword}
                    isSearch
                  />
                )}
              </>
            )}
          </List>
        </NewsList>
      </Box>
    </Box>
  );
};

export default Search;
