import Box from "components/atoms/box";
import Button from "components/atoms/button";
import Input from "components/atoms/input";
import Text from "components/atoms/text";
import useAllCheckbox from "hooks/utils/useAllCheckbox";
import React from "react";
import { useNavigate } from "react-router-dom";
import convertDayWithTime from "utils/convertDayWithTime";
import HasNotResult from "../HasNotResult";
import { useState } from "react";
import { useEffect } from "react";
import TableDeleteBar from "../tableDeleteBar";
import { useDeleteAuthority } from "hooks/api/admin/manager";
import { common } from "text/admin/common";
import { adminDefaultUrl } from "api/admin/url";

const AuthorityTable = (props) => {
  const { list, tableHeader, itemsPerPage } = props;

  const {
    selectRemoveItems,
    default: { remove },
  } = common.alert;

  const navigate = useNavigate();

  const count = list.length > parseInt(itemsPerPage) ? parseInt(itemsPerPage) : list.length;

  let keyArr = [];
  const [allKeyList, setAllKeyList] = useState([]);

  useEffect(() => {
    setAllKeyList(keyArr);
  }, [list]);

  const {
    checkAll,
    checkboxes,
    resetCheckbox,
    selectedKeys,
    handleCheckAllChange,
    handleCheckboxChange,
  } = useAllCheckbox(count, allKeyList);

  const isNoneSelected = selectedKeys.length === 0;

  const mutate = useDeleteAuthority(resetCheckbox);

  const handleDeleteSelectedAuthority = () => {
    const data = { authorCodeList: selectedKeys };
    if (isNoneSelected) {
      alert(selectRemoveItems);
      return;
    }

    const confirm = window.confirm(remove);
    if (confirm) mutate({ data });
  };

  const handleAuthorityUpdate = (code) => {
    navigate(`${adminDefaultUrl}/management_manager/authority/detail?authorCode=${code}`);
  };

  const handleCreateAuthority = () => {
    navigate(`${adminDefaultUrl}/management_manager/authority/detail`);
  };

  return (
    <>
      <TableDeleteBar
        title="권한 등록"
        handleDelete={handleDeleteSelectedAuthority}
        handleSave={handleCreateAuthority}
        hasRadius={false}
      />
      <Box
        display={Box.DISPLAY.FLEX}
        height="32px"
        borderTop="1px solid"
        borderColor={Box.COLOR.LINEGRAY}
      >
        <Box
          width="4%"
          height="100%"
          display={Box.DISPLAY.FLEX}
          al="center"
          jc="center"
          borderLeft="1px solid"
          borderBottom={list.length === 0 ? "1px solid" : ""}
          borderColor={Box.COLOR.ADMINLINEGRAY}
          bgColor={Box.COLOR.ADMINCONTENTSBG}
        >
          <Input
            type="checkbox"
            width="16px"
            height="16px"
            borderColor={Input.COLOR.ADMINLINEGRAY}
            onChange={handleCheckAllChange}
            checked={checkAll}
          />
        </Box>
        {tableHeader.map(({ title, width, ariaLabel }, idx) => (
          <Box
            width={width}
            padding="8px 0"
            key={idx}
            borderLeft="1px solid"
            borderRight={idx === tableHeader.length - 1 ? "1px solid" : ""}
            borderBottom={list.length === 0 ? "1px solid" : ""}
            borderColor={Box.COLOR.LINEGRAY}
            bgColor={Box.COLOR.ADMINCONTENTSBG}
          >
            <Text
              as={Text.VARIANT.TEXT}
              size={Text.SIZE.CLIENTCONTENTDETAIL}
              weight={Text.WEIGHT.BOLD}
              textAlign="center"
              ariaLabel={ariaLabel}
            >
              {title}
            </Text>
          </Box>
        ))}
      </Box>
      {list.length === 0 && <HasNotResult />}
      {list.length > 0 &&
        list.map(({ authorCode, authorName, regDate, userInfo }, idx) => {
          keyArr.push(authorCode);

          return (
            <Box
              key={idx}
              borderTop={idx === 0 ? "1px solid" : ""}
              borderBottom="1px solid"
              borderColor={Box.COLOR.LINEGRAY}
              display={Box.DISPLAY.FLEX}
              height="77px"
            >
              <Box
                width="4%"
                height="100%"
                display={Box.DISPLAY.FLEX}
                jc="center"
                borderLeft="1px solid"
                borderColor={Box.COLOR.ADMINLINEGRAY}
                al="center"
              >
                <Input
                  type="checkbox"
                  name={authorCode}
                  width="16px"
                  height="16px"
                  borderColor={Input.COLOR.ADMINLINEGRAY}
                  checked={checkboxes[authorCode] ?? false}
                  onChange={(e) => handleCheckboxChange({ e })}
                />
              </Box>
              <Box
                width="24%"
                display={Box.DISPLAY.FLEX}
                jc="center"
                al="center"
                borderLeft="1px solid"
                borderColor={Box.COLOR.LINEGRAY}
              >
                <Text
                  as={Text.VARIANT.TEXT}
                  size={Text.SIZE.CLIENTCONTENTDETAIL}
                  textAlign="center"
                  ariaLabel={authorName}
                >
                  {authorName}
                </Text>
              </Box>
              <Box
                width="24%"
                display={Box.DISPLAY.FLEX}
                jc="center"
                al="center"
                borderLeft="1px solid"
                borderColor={Box.COLOR.LINEGRAY}
              >
                <Text
                  as={Text.VARIANT.TEXT}
                  size={Text.SIZE.CLIENTCONTENTDETAIL}
                  textAlign="center"
                  ariaLabel={userInfo}
                >
                  {userInfo}
                </Text>
              </Box>
              <Box
                width="24%"
                display={Box.DISPLAY.FLEX}
                jc="center"
                al="center"
                borderLeft="1px solid"
                borderColor={Box.COLOR.LINEGRAY}
              >
                <Text
                  as={Text.VARIANT.TEXT}
                  size={Text.SIZE.CLIENTCONTENTDETAIL}
                  textAlign="center"
                  ariaLabel={regDate}
                >
                  {convertDayWithTime(regDate)}
                </Text>
              </Box>
              <Box
                width="24%"
                display={Box.DISPLAY.FLEX}
                jc="center"
                al="center"
                borderLeft="1px solid"
                borderRight="1px solid"
                borderColor={Box.COLOR.LINEGRAY}
              >
                <Button
                  themeStyle="adminCommon"
                  padding="7px 24px"
                  onClick={() => handleAuthorityUpdate(authorCode)}
                >
                  <Text
                    as={Text.VARIANT.TEXT}
                    size={Text.SIZE.CLIENTCONTENTDETAIL}
                    textAlign="center"
                  >
                    수정
                  </Text>
                </Button>
              </Box>
            </Box>
          );
        })}
    </>
  );
};

export default AuthorityTable;
