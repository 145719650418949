import { getBanner } from "api/banner";
import { getCoverageNews } from "api/news";
import { useQueries, useQuery } from "react-query";

export const useGetBanner = ({ type }) => {
  const { data, isLoading, isError, isSuccess } = useQuery(
    ["banner", type],
    () => getBanner(type)
  );

  const list = data?.data.list;

  return {
    list,
    isLoading,
    isError,
    isSuccess,
  };
};

export const useTopBanner = () => {
  const [
    {
      data: bannerData,
      isLoading: isBannerLoading,
      isError: isBannerError,
      isSuccess: isBannerSuccess,
    },
    {
      data: coverageData,
      isLoading: isCoverageLoading,
      isError: isCoverageError,
      isSuccess: isCoverageSuccess,
    },
  ] = useQueries([
    {
      queryKey: ["banner_top"],
      queryFn: () => getBanner("slide"),
    },
    {
      queryKey: ["banner_coverage"],
      queryFn: getCoverageNews,
    },
  ]);

  const list = [];

  if (isBannerSuccess) {
    list.push(bannerData.data.list);
  }

  if (isCoverageSuccess) {
    list.push(coverageData.data.list);
  }

  return {
    list,
    isLoading: isBannerLoading || isCoverageLoading,
    isError: isBannerError || isCoverageError,
    isSuccess: isBannerSuccess && isCoverageSuccess,
  };
};
