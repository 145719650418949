import React, { useState } from "react";
import Calendar from "components/molecules/calendar";
import { usePreFetchSchedule, useScienceSchedule } from "hooks/api/schedule";
import Loading from "components/atoms/loading";
import Box from "components/atoms/box";
import { VscCircleFilled } from "@react-icons/all-files/vsc/VscCircleFilled";
import { AiOutlineCaretLeft } from "@react-icons/all-files/ai/AiOutlineCaretLeft";
import { AiOutlineCaretRight } from "@react-icons/all-files/ai/AiOutlineCaretRight";
import Text from "components/atoms/text";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import ScienceCalendarDate from "components/molecules/scienceCalendarDate";
import Button from "components/atoms/button";
import useMobileStore from "store/useMobileStore";

dayjs.extend(isBetween);

const cellRender = (schedule, isError) => {
  if (isError) return null;
  return (value) => {
    const date = value.format("YYYY-MM-DD");
    const checkDate = dayjs(date);
    const eventList = schedule.data.getEventBeginCalendarList;
    const preEventList = schedule.data.getPreBeginCalendarList;

    const eventDate = eventList.find(({ eventBeginDate }) => {
      const startDate = dayjs(eventBeginDate);

      return checkDate.isSame(startDate, "day");
    });

    const preEventDate = preEventList.find(({ preBeginDate }) => {
      const startDate = dayjs(preBeginDate);

      return checkDate.isSame(startDate, "day");
    });

    const isNull = !eventDate && !preEventDate;

    return (
      <Box
        display={Box.DISPLAY.FLEX}
        jc={"center"}
        al={"center"}
        ariaLabel={`${date} 과학기술 일정`}
      >
        {eventDate && (
          <Text
            as={Text.VARIANT.SPAN}
            color={Text.COLOR.SECONDARY}
            lineHeight={"1px"}
            ariaLabel={"시작하는 행사 있음"}
          >
            <VscCircleFilled size={10} />
          </Text>
        )}
        {preEventDate && (
          <Text
            as={Text.VARIANT.SPAN}
            color={Text.COLOR.THIRD}
            lineHeight={"1px"}
            ariaLabel={"시작하는 사전예약 있음"}
          >
            <VscCircleFilled size={10} />
          </Text>
        )}
        {isNull && (
          <Text
            as={Text.VARIANT.SPAN}
            color={Text.COLOR.TRANSPARENT}
            lineHeight={"1px"}
          >
            <VscCircleFilled size={10} />
          </Text>
        )}
      </Box>
    );
  };
};

const ScienceCalendar = () => {
  const [scienceDate, setScienceDate] = useState(() => dayjs());
  const { scienceSchedule, isScienceScheduleLoading, isScienceScheduleError } =
    useScienceSchedule(scienceDate.format("YYYYMM"));
  const { isMobile } = useMobileStore();

  const handleChangeDate = (date) => {
    setScienceDate(date);
  };

  const moveMonth = (offer) => {
    const date = scienceDate.add(offer, "month");

    setScienceDate(date);
  };

  usePreFetchSchedule(scienceDate);

  const scienceFormat = scienceDate.format("YYYY년 M월");

  return (
    <>
      {isScienceScheduleLoading && <Loading />}
      {!isScienceScheduleLoading && (
        <>
          <Box
            display={Box.DISPLAY.FLEX}
            jc={"center"}
            margin={isMobile ? "15px" : "40px 15px"}
            ariaLabel={"과학기술 일정 네비게이션 영역"}
          >
            <Button
              display="flex"
              al="center"
              themeStyle={Button.THEME.NONE}
              onClick={() => moveMonth(-1)}
              ariaLabel={"과학기술 일정 이전달로 이동"}
            >
              <AiOutlineCaretLeft size={15} />
            </Button>
            <Text
              as={Text.VARIANT.TEXT}
              size={
                isMobile ? Text.SIZE.MOBILECONTENT : Text.SIZE.CLIENTSUBTITLE
              }
              weight={Text.WEIGHT.BOLD}
              margin={"0 24px"}
              ariaLabel={`${scienceFormat} 과학기술 일정`}
              isSort
            >
              {scienceFormat}
            </Text>
            <Button
              display="flex"
              al="center"
              themeStyle={Button.THEME.NONE}
              onClick={() => moveMonth(1)}
              ariaLabel={"과학기술 일정 다음달로 이동"}
            >
              <AiOutlineCaretRight size={15} />
            </Button>
          </Box>
          <Calendar
            className={isMobile ? "mobileScience" : "science"}
            value={scienceDate}
            onChange={handleChangeDate}
            cellRender={cellRender(scienceSchedule, isScienceScheduleError)}
          />

          <ScienceCalendarDate
            {...scienceSchedule.data}
            scienceDate={scienceDate}
            isMobile={isMobile}
          />
        </>
      )}
    </>
  );
};

export default ScienceCalendar;
