import React, { useEffect } from "react";
import Loading from "components/atoms/loading";
import usePagination from "hooks/utils/usePagination";
import Page from "../page";
import Box from "components/atoms/box";
import Text from "components/atoms/text";
import useMobileStore from "store/useMobileStore";
import commonText from "text/common";

const { error } = commonText;

const List = (props) => {
  const {
    api,
    preFetch,
    children: renderChildren,
    params,
    pagePerBlock = 3,
    hash = "board",
    setTotal = () => {},
    isBorderTop,
    isAdmin,
  } = props;
  const {
    list,
    totalCnt,
    listviewList,
    listviewBasic,
    totalPageIndex,
    isLoading,
    isSuccess,
    isError,
  } = api(params);

  const { isMobile } = useMobileStore();

  const margin = isMobile ? "50px 0 18px" : isAdmin ? "40px 0" : "80px 0";

  if (preFetch) preFetch(params);

  const { page, pages } = usePagination({
    totalPage: totalPageIndex ?? 0,
    pagePerBlock,
  });

  useEffect(() => {
    setTotal(totalCnt);
  }, [totalCnt, isSuccess, setTotal]);

  return (
    <>
      {isLoading && <Loading />}
      {isSuccess && (
        <>
          {renderChildren(list, { listviewList, listviewBasic })}
          {!isNaN(totalPageIndex) && totalPageIndex !== 0 && (
            <Box display={Box.DISPLAY.FLEX} jc="center" width={"100%"} margin={margin}>
              <Page page={page} pages={pages} totalPage={totalPageIndex} hash={hash} />
            </Box>
          )}
        </>
      )}

      {isError && (
        <Text
          width={"100%"}
          textAlign={"center"}
          padding={isMobile ? "20px 0" : "50px 0"}
          margin={isMobile ? "0 0 40px" : "0"}
          borderTop={isBorderTop ? "1px solid" : ""}
          borderBottom={"1px solid"}
          borderColor={Text.COLOR.LINEGRAY}
          size={isMobile ? Text.SIZE.MOBILECONTENT : Text.SIZE.CLIENTNORMAL}
          color={Text.COLOR.TEXTGRAY}
        >
          {error}
        </Text>
      )}
    </>
  );
};

export default List;
