import { SERVER_URL } from "api/config";
import axios from "axios";
import dayjs from "dayjs";

const API_VERSION = process.env.REACT_APP_API_VERSION ?? "v1";

const createResponseUrl = (apiUrl, condition = {}, type) => {
  const { searchCondition = "", keyword = "" } = condition;

  let responseUrl = `${SERVER_URL}/api/${API_VERSION}${apiUrl}/downloads`;
  if (searchCondition && keyword) {
    if (type === "newsletter") {
      responseUrl += `?${searchCondition}=${keyword}`;
    } else {
      responseUrl += `?searchCondition=${searchCondition}&searchKeyword=${keyword}`;
    }
  }

  return responseUrl;
};

const downloadFile = (url, filename) => {
  axios.get(url, { responseType: "blob", withCredentials: true }).then((res) => {
    const blob = new Blob([res.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const href = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = href;
    link.setAttribute("download", filename);
    link.click();
    if (url.includes("admin")) {
      window.alert("다운로드가 완료되었습니다.");
    }
    link.remove();
    window.URL.revokeObjectURL(href);
  });
};

const generateExcelFile = async (apiUrl, fileName, condition = {}, type = "") => {
  const confirm = window.confirm("엑셀 파일을 다운로드 하시겠습니까?");
  if (!confirm) return;

  const responseUrl = createResponseUrl(apiUrl, condition, type);
  const today = dayjs().format("YYYY-MM-DD");

  downloadFile(responseUrl, `${fileName} (${today}).xlsx`);
};

export default generateExcelFile;
