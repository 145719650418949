import {
  createAuthority,
  createManager,
  deleteAuthority,
  deleteManager,
  getAuthorityInfo,
  getAuthorityList,
  getAuthorityMenuDetail,
  getAuthorityMenuList,
  getLoginLogList,
  getManagerDetail,
  getManagerList,
  getWorkStatusList,
  updateAuthorityInfo,
  updateManagerInfo,
  updateManagerPassword,
} from "api/admin/manager";
import useHandleAlert from "hooks/utils/useHandleAlert";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { common } from "text/admin/common";

const {
  success: { create, update, remove },
  failed: { isUsedAuthority: isUsedAuthorityComment },
  temporaryPassword: { success },
} = common.alert;

// 관리자 목록
export const useManagerList = ({
  page,
  itemsPerPage,
  searchCondition,
  searchValue,
  authorCode,
  inactive,
}) => {
  const { data, isLoading, isSuccess, isError } = useQuery(
    ["manager", page, itemsPerPage, searchValue],
    () => getManagerList(page, itemsPerPage, searchCondition, searchValue, authorCode, inactive)
  );

  const list = data?.data.list;
  const totalCnt = data?.data.totalCnt;
  const totalPageIndex = data?.data.totalPageIndex;

  return {
    list,
    totalCnt,
    totalPageIndex,
    isLoading,
    isSuccess,
    isError,
  };
};

// 관리자 상세
export const useManagerDetail = ({ key }) => {
  const { data, isLoading, isSuccess, isError } = useQuery({
    queryKey: ["managerDetail", key],
    queryFn: () => getManagerDetail(key),
    enabled: !!key,
  });

  const list = data?.data.adminManagerInfo;

  return {
    list,
    isLoading,
    isSuccess,
    isError,
  };
};

// 관리자 등록
export const useCreateManager = () => {
  const { handleGoBack } = useHandleAlert();
  const queryClient = useQueryClient();
  const createManagerMutation = useMutation(createManager, {
    onSuccess: () => {
      alert(create);
      queryClient.invalidateQueries("manager");
      handleGoBack();
    },
  });

  const mutate = ({ data }) => {
    createManagerMutation.mutate({ data });
  };

  return mutate;
};

// 관리자 수정
export const useUpdateManagerInfo = () => {
  const { handleGoBack } = useHandleAlert();
  const queryClient = useQueryClient();
  const updateManagerInfoMutation = useMutation(updateManagerInfo, {
    onSuccess: () => {
      alert(update);
      queryClient.invalidateQueries("manager");
      handleGoBack();
    },
  });

  const mutate = ({ key, data }) => {
    updateManagerInfoMutation.mutate({ key, data });
  };

  return mutate;
};

// 관리자 삭제
export const useDeleteManager = (reset) => {
  const queryClient = useQueryClient();
  const deleteManagerMutation = useMutation(deleteManager, {
    onSuccess: () => {
      alert(remove);
      queryClient.invalidateQueries("manager");
      reset();
    },
  });

  const mutate = ({ data }) => {
    deleteManagerMutation.mutate({ data });
  };

  return mutate;
};

// 관리자 임시 비밀번호 발급
export const useUpdateManagerPassword = (setIsCreatedPw) => {
  const queryClient = useQueryClient();
  const updateManagerPasswordMutation = useMutation(updateManagerPassword, {
    onSuccess: () => {
      alert(success);
      queryClient.invalidateQueries("manager");
      setIsCreatedPw(true);
    },
  });

  const mutate = ({ key }) => {
    updateManagerPasswordMutation.mutate({ key });
  };

  return mutate;
};

// 권한 목록
export const useAuthorityList = ({ page, searchValue }) => {
  const { data, isLoading, isSuccess, isError } = useQuery(["authority", page, searchValue], () =>
    getAuthorityList(page, searchValue)
  );

  const list = data?.data.list;
  const totalCnt = data?.data.totalCnt;
  const totalPageIndex = data?.data.totalPageIndex;

  return {
    list,
    totalCnt,
    totalPageIndex,
    isLoading,
    isSuccess,
    isError,
  };
};

// 권한 정보
export const useAuthorityInfo = ({ authorCode }) => {
  const { data, isLoading, isSuccess, isError } = useQuery({
    queryKey: ["authorityInfo", authorCode],
    queryFn: () => getAuthorityInfo(authorCode),
    enabled: !!authorCode,
  });

  const list = data?.data.adminAuthorDto;

  return {
    list,
    isLoading,
    isSuccess,
    isError,
  };
};

// 권한 메뉴
export const useAuthorityMenuList = () => {
  const { data, isLoading, isSuccess, isError } = useQuery(["authorityMenuList"], () =>
    getAuthorityMenuList()
  );

  const list = data?.data.list;

  return {
    list,
    isLoading,
    isSuccess,
    isError,
  };
};

// 권한별 메뉴 사용 유무
export const useAuthorityMenuDetail = ({ authorCode }) => {
  const { data, isLoading, isSuccess, isError } = useQuery({
    queryKey: ["authorityMenuDetail", authorCode],
    queryFn: () => getAuthorityMenuDetail(authorCode),
    enabled: !!authorCode,
  });

  const list = data?.data.createMenuChkList;

  return {
    list,
    isLoading,
    isSuccess,
    isError,
  };
};

// 권한 등록
export const useCreateAuthority = () => {
  const { handleGoBack } = useHandleAlert();
  const queryClient = useQueryClient();
  const createAuthorityMutation = useMutation(createAuthority, {
    onSuccess: () => {
      alert(create);
      queryClient.invalidateQueries("authorityMenuDetail");
      handleGoBack();
    },
  });

  const mutate = ({ data }) => {
    createAuthorityMutation.mutate({ data });
  };

  return mutate;
};

// 권한 수정
export const useUpdateAuthorityInfo = () => {
  const { handleGoBack } = useHandleAlert();
  const queryClient = useQueryClient();
  const updateAuthorityInfoMutation = useMutation(updateAuthorityInfo, {
    onSuccess: () => {
      alert(update);
      queryClient.invalidateQueries("authorityMenuDetail");
      handleGoBack();
    },
  });

  const mutate = ({ authorCode, data }) => {
    updateAuthorityInfoMutation.mutate({ authorCode, data });
  };

  return mutate;
};

// 권한 삭제
export const useDeleteAuthority = (reset) => {
  const queryClient = useQueryClient();
  const deleteAuthorityMutation = useMutation(deleteAuthority, {
    onSuccess: () => {
      alert(remove);
      queryClient.invalidateQueries("authority");
      reset();
    },
    onError: (error) => {
      const isUsedAuthority = error.response.data.message.includes("RELATED ITEMS EXIST");
      if (isUsedAuthority) alert(isUsedAuthorityComment);
    },
  });

  const mutate = ({ data }) => {
    deleteAuthorityMutation.mutate({ data });
  };

  return mutate;
};

// 운영자 작업 현황
export const useManagerWorkStatusList = ({
  page,
  itemsPerPage,
  startDate,
  endDate,
  currOption,
  searchValue,
  searchCondition,
  cate1,
  cate2,
}) => {
  const { data, isLoading, isSuccess, isError } = useQuery(
    [
      "workStatus",
      page,
      itemsPerPage,
      startDate,
      endDate,
      currOption,
      searchValue,
      searchCondition,
      cate1,
      cate2,
    ],
    () =>
      getWorkStatusList({
        page,
        itemsPerPage,
        startDate,
        endDate,
        currOption,
        searchValue,
        searchCondition,
        cate1,
        cate2,
      })
  );

  const list = data?.data.list;
  const totalCnt = data?.data.totalCnt;
  const totalPageIndex = data?.data.totalPageIndex;

  return {
    list,
    totalCnt,
    totalPageIndex,
    isLoading,
    isSuccess,
    isError,
  };
};

// 로그인 현황
export const useLoginLogList = ({
  page,
  itemsPerPage,
  searchValue,
  searchCondition,
  startDate,
  endDate,
}) => {
  const { data, isLoading, isSuccess, isError } = useQuery(
    ["workStatus", page, itemsPerPage, searchValue, searchCondition, startDate, endDate],
    () =>
      getLoginLogList({
        page,
        itemsPerPage,
        searchValue,
        searchCondition,
        startDate,
        endDate,
      })
  );

  const list = data?.data.list;
  const totalCnt = data?.data.totalCnt;
  const totalPageIndex = data?.data.totalPageIndex;

  return {
    list,
    totalCnt,
    totalPageIndex,
    isLoading,
    isSuccess,
    isError,
  };
};
