import Box from "components/atoms/box";
import Text from "components/atoms/text";
import React, { forwardRef } from "react";
import { NewsInfo } from "../newsList";
import Image from "components/atoms/image";

const Print = forwardRef(({ data }, ref) => {
  return (
    <Box
      position={"relative"}
      className="print"
      margin={"20px 0"}
      borderTop={"1px solid"}
      borderBottom={"1px solid"}
      borderColor={Box.COLOR.LINEGRAY}
      ref={ref}
    >
      <Box position={"absolute"} top={"0"} left={"0"} right={"0"}>
        <Box
          display={Box.DISPLAY.FLEX}
          jc={"center"}
          padding={"8px 0"}
          borderBottom={"1px solid"}
          borderColor={Box.COLOR.LINEGRAY}
        >
          <Image src={"/assets/common/logo.png"} width={"200px"} alt={"logo"} />
        </Box>
        <Box padding={"40px 50px 0"}>
          <Text
            as={Text.VARIANT.TITLE}
            size={Text.SIZE.CLIENTTITLE}
            margin={"10px 0 8px"}
          >
            {data.article.title}
          </Text>

          <NewsInfo
            margin={"8px 0 40px"}
            size={Text.SIZE.CLIENTCONTENTDETAIL}
            writer={data.article.writer}
            regDate={data.article.regDate}
            commentCnt={data.article.commentCnt}
          />
        </Box>
      </Box>

      <Box padding="184px 50px 100px" borderColor={Box.COLOR.LINEGRAY}>
        <div
          style={{
            fontSize: `calc(16px)`,
            pageBreakInside: "avoid",
          }}
          dangerouslySetInnerHTML={{ __html: data.article.contents }}
        />
      </Box>

      <Box
        display={Box.DISPLAY.FLEX}
        jc={"space-between"}
        al={"center"}
        borderTop={"1px solid"}
        borderColor={Box.COLOR.LINEGRAY}
        padding={"12px 50px"}
      >
        <Image src={"/assets/detail/printLogo.png"} alt={"printLogo"} />
        <Text
          as={Text.VARIANT.TEXT}
          size={Text.SIZE.CLIENTETC}
          color={Text.COLOR.TEXTGRAY}
        >
          COLPYRIGHT (C) KOFST . ALL RIGHTS RESERVED.
        </Text>
      </Box>
    </Box>
  );
});

export default Print;
