import { DatePicker } from "antd";
import locale from "antd/es/date-picker/locale/ko_KR";
import Box from "components/atoms/box";
import Image from "components/atoms/image";
import Text from "components/atoms/text";
import React from "react";

const DatePickerList = (props) => {
  const { selectedStartDate, selectedEndDate, setSelectedStartDate, setSelectedEndDate } = props;

  const dateFormat = "YYYY-MM-DD";

  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);

    if (date && date > selectedEndDate) {
      setSelectedEndDate(date);
    }
  };

  const handleEndDateChange = (date) => {
    setSelectedEndDate(date);
  };

  const disabledEndDate = (current) => {
    if (current && current < selectedStartDate) {
      return { disabled: true };
    }
  };

  return (
    <Box display={Box.DISPLAY.FLEX} al="center" gap="15px">
      <Box display={Box.DISPLAY.FLEX} al="center" gap="15px">
        <Box as="label" display={Box.DISPLAY.FLEX} al="center" gap="6px">
          <DatePicker
            locale={locale}
            format={dateFormat}
            onChange={handleStartDateChange}
            value={selectedStartDate}
            className="admin-date-picker"
          />
          <Image src="/assets/admin/calendarIcon.png" width="24px" height="24px" />
        </Box>
      </Box>
      <Text as={Text.VARIANT.SPAN}>~</Text>
      <Box display={Box.DISPLAY.FLEX} al="center" gap="15px">
        <Box as="label" display={Box.DISPLAY.FLEX} al="center" gap="6px">
          <DatePicker
            locale={locale}
            format={dateFormat}
            onChange={handleEndDateChange}
            value={selectedEndDate}
            disabledDate={disabledEndDate}
            className="admin-date-picker"
          />
          <Image src="/assets/admin/calendarIcon.png" width="24px" height="24px" />
        </Box>
      </Box>
    </Box>
  );
};

export default DatePickerList;
