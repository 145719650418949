import Box from "components/atoms/box";
import Image from "components/atoms/image";
import Link from "components/atoms/link";
import Slider from "components/atoms/slider";
import Text from "components/atoms/text";
import dayjs from "dayjs";
import React from "react";
import { useRef } from "react";
import { useState } from "react";
import homeText from "text/home";
import convertImage from "utils/convertImage";

const { schedule } = homeText;

const MobileSchedule = ({ coverageNews }) => {
  const sliderRef = useRef();
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <Box
      width={"100%"}
      bgColor={Box.COLOR.LIGHTGRAY}
      borderRadius={"10px"}
      padding={"20px 24px"}
    >
      <Box
        width={"100%"}
        display={Box.DISPLAY.FLEX}
        jc={"center"}
        margin={"0 0 15px"}
        ariaLabel="원장님 동정 보도 텍스트 영역"
      >
        <Image
          width={"30px"}
          src={"/assets/home/calendar.svg"}
          ariaLabel={"캘린더 아이콘"}
          alt={"캘린더 아이콘"}
        />
        <Box margin={"0 0 0 5px"}>
          <Text
            as={Text.VARIANT.SUBTITLE}
            weight={Text.WEIGHT.NORMAL}
            size={Text.SIZE.MOBILEADD}
            ariaLabel={"원장님 동정 보도"}
          >
            {schedule.category}
          </Text>
          <Text
            as={Text.VARIANT.TITLE}
            size={Text.SIZE.MOBILETITLE}
            lineHeight={"16px"}
            ariaLabel={"주요 일정"}
          >
            {schedule.title}
          </Text>
        </Box>
      </Box>
      <Link
        width={"100%"}
        link={
          coverageNews[activeIndex]
            ? `/news/${coverageNews[activeIndex].key}?category=COM045_AspbHPe`
            : ""
        }
      >
        <Image
          width={"100%"}
          src={convertImage(coverageNews[activeIndex]?.thumbnail, "thumbnail")}
          aspectRatio={"298/200"}
          radius={Box.RADIUS.NORMAL}
          ariaLabel={`${coverageNews[activeIndex]?.title} 썸네일 이미지`}
          alt={`${coverageNews[activeIndex]?.title} 썸네일 이미지`}
        />{" "}
      </Link>

      <Box
        position={"relative"}
        display={Box.DISPLAY.FLEX}
        width={"100%"}
        margin={"20px 0 0"}
        ariaLabel={"원장님 동정 보도 슬라이더"}
      >
        {coverageNews.length === 0 && (
          <Box
            display={Box.DISPLAY.FLEX}
            flexDirection="column"
            jc="center"
            width={"100%"}
            height={"60px"}
            padding={"12px 10px"}
            border="1px solid"
            borderColor={Box.COLOR.LINEGRAY}
            borderRadius={"5px"}
            bgColor={Box.COLOR.WHITE}
          >
            <Text
              as={Text.VARIANT.TEXT}
              size={Text.SIZE.MOBILECONTENT}
              lineHeight={"14px"}
              lineClamp={1}
              wordBreak="break-all"
            >
              일정이 없습니다.
            </Text>
          </Box>
        )}
        {coverageNews.length !== 0 && (
          <Slider
            direction="horizontal"
            height={"100%"}
            slidesPerView={1.3}
            spaceBetween={4}
            simulateTouch={false}
            loop={true}
            navigation={true}
            isAutoplay={false}
            sliderRef={sliderRef}
            setActiveIndex={setActiveIndex}
          >
            {coverageNews.map(({ eventBeginDate, title, key }, idx) => (
              <Slider.Slide key={idx}>
                <Link
                  link={`/news/${key}?category=COM045_AspbHPe`}
                  width={"100%"}
                >
                  <Box
                    display={Box.DISPLAY.FLEX}
                    flexDirection="column"
                    jc="center"
                    height={"60px"}
                    padding={"12px 10px"}
                    border="1px solid"
                    borderColor={Box.COLOR.LINEGRAY}
                    borderRadius={"5px"}
                    bgColor={Box.COLOR.WHITE}
                    ariaLabel={`${idx + 1}번째 동정 보도 일정 : ${title}`}
                  >
                    <Text
                      as={Text.VARIANT.TEXT}
                      weight={Text.WEIGHT.BOLD}
                      size={Text.SIZE.MOBILECONTENT}
                      color={Text.COLOR.SECONDARY}
                      margin={"0 0 6px"}
                      lineClamp={1}
                      wordBreak="break-all"
                      ariaLabel={`${idx + 1}번째 동정 보도 일정 : ${title}`}
                    >
                      {dayjs(eventBeginDate).format("MM/DD")}
                    </Text>
                    <Text
                      as={Text.VARIANT.TEXT}
                      size={Text.SIZE.MOBILECONTENT}
                      lineHeight={"14px"}
                      lineClamp={1}
                      wordBreak="break-all"
                      ariaLabel={`${idx + 1}번째 동정 보도 일정 : ${title}`}
                    >
                      {title}
                    </Text>
                  </Box>
                </Link>
              </Slider.Slide>
            ))}
          </Slider>
        )}
        <Box
          position={"absolute"}
          top={"0"}
          right={"-0px"}
          height={"60px"}
          zIndex={Box.LEVEL.TOP}
        >
          <Image height={"100%"} src={"/assets/common/blur.png"} />
        </Box>
      </Box>
    </Box>
  );
};

export default MobileSchedule;
