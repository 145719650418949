import { useEffect, useState } from "react";

const usePattern = (password = "") => {
  const [pattern, setPattern] = useState({
    // id: {
    //   value: /^[a-z0-9]{4,12}$/,
    //   message: "아이디 형식을 확인해주세요.\n (영문 소문자, 숫자 4~12자)",
    // },
    password: {
      value:
        /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-]).{8,16}|(?=.*[a-zA-Z])(?=.*[0-9]).{8,16}|(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,16}$/,
      validate: (password) => {
        if (password.length < 8 || password.length > 16) return false;

        let regexArray = [/[A-Za-z]/g, /[0-9]/g, /[!@#$%^&*(),.?":{}|<>]/g];

        let matchCount = regexArray.reduce(
          (count, regex) => (password.match(regex) ? count + 1 : count),
          0
        );

        return matchCount >= 2;
      },
      message:
        "비밀번호 형식을 확인해주세요.\n (영문, 숫자, 특수문자 포함 8자 이상)",
    },
    passwordConfirm: {
      value: new RegExp(`${password}`),
      message: "비밀번호가 일치하지 않습니다.",
    },
    email: {
      value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9-]+(\.[A-Za-z]{2,})+$/,
      message: "이메일 형식을 확인해주세요.",
    },
    call: {
      value: /^\d{2,3}-\d{3,4}-\d{4}$/,
      message: "전화번호 형식을 확인해주세요.",
    },
  });

  useEffect(() => {
    setPattern((prev) => {
      return {
        ...prev,
        passwordConfirm: {
          ...prev.passwordConfirm,
          value: new RegExp(`^${password}$`),
        },
      };
    });
  }, [password]);

  return pattern;
};

export default usePattern;
