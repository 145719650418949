import Box from "components/atoms/box";
import Button from "components/atoms/button";
import Image from "components/atoms/image";
import Link from "components/atoms/link";
import Text from "components/atoms/text";
import AdminProfile from "components/molecules/adminProfile";
import { useTokenMutation } from "hooks/api/auth";
import useUrl from "hooks/utils/useUrl";
import React, { useEffect, useRef, useState } from "react";
import useUserStore from "store/useUserStore";
import { myInfo } from "text/admin/myinfo";

const AdminHeader = () => {
  const { path, pathname, search, hash } = useUrl();

  const profileRef = useRef();

  const { headerTitle, goToWebzine } = myInfo;

  const [isOpen, setIsOpen] = useState(false);

  const { user, setUser } = useUserStore();

  const handleSuccessToken = (list) => {
    setUser(list);
  };

  const tokenMutation = useTokenMutation(handleSuccessToken);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (profileRef.current && !profileRef.current.contains(e.target)) {
        setIsOpen(false);
      }
    };

    document.body.addEventListener("click", handleClickOutside);

    return () => {
      document.body.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const currTitle = headerTitle[path[1]];

  const handleProfile = () => {
    setIsOpen((p) => !p);
  };

  useEffect(() => {
    if (!user) {
      tokenMutation();
    }
  }, [user, pathname, search, hash]);

  return (
    <Box
      display={Box.DISPLAY.FLEX}
      al="center"
      jc="space-between"
      borderBottom="1px solid"
      borderColor={Box.COLOR.ADMINLINEGRAY}
      padding="22px 24px"
      bgColor={Box.COLOR.WHITE}
    >
      <Text
        as={Text.VARIANT.TEXT}
        size={Text.SIZE.CLIENTSUBTITLE}
        weight={Text.WEIGHT.BOLD}
        ariaLabel={currTitle}
      >
        {currTitle}
      </Text>
      <Box display={Box.DISPLAY.FLEX} al="center" gap="15px">
        <Button
          padding="0"
          border="1px solid"
          borderColor={Button.COLOR.LINEGRAY}
          borderRadius={Button.RADIUS.SMALL}
          bgColor={Button.COLOR.TRANSPARENT}
          width="104px"
          height="32px"
          sort="center"
          ariaLabel={goToWebzine}
        >
          <Link link="/" target="_blank" width="100%" height="100%">
            <Text
              as={Text.VARIANT.TEXT}
              size={Text.SIZE.CLIENTCONTENTDETAIL}
              color={Text.COLOR.SECONDARY}
              lineHeight="32px"
              ariaLabel={goToWebzine}
            >
              {goToWebzine}
            </Text>
          </Link>
        </Button>
        <Box position="relative" ref={profileRef}>
          <Button
            sort="center"
            border="1px solid"
            borderColor={Button.COLOR.ADMINLINEGRAY}
            bgColor={Button.COLOR.TRANSPARENT}
            borderRadius={Button.RADIUS.ROUND}
            width="40px"
            height="40px"
            onClick={handleProfile}
          >
            <Image src="/assets/admin/profile.png" />
          </Button>
          {isOpen && <AdminProfile setIsOpen={setIsOpen} />}
        </Box>
      </Box>
    </Box>
  );
};

export default AdminHeader;
