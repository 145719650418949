import React from "react";
import Text from "components/atoms/text";
import Box from "components/atoms/box";
import Button from "components/atoms/button";
import { BsChevronDown } from "@react-icons/all-files/bs/BsChevronDown";
import { BsChevronUp } from "@react-icons/all-files/bs/BsChevronUp";
import { useState } from "react";
import { useEffect } from "react";
import useUrl from "hooks/utils/useUrl";
import { useRef } from "react";
import featureText from "text/feature";

const { button } = featureText;

const MobileFeatureContent = ({ contents }) => {
  const {
    query: { page = 1 },
  } = useUrl();
  const [isExpanded, setIsExpanded] = useState(false);
  const [isMore, setIsMore] = useState(false);
  const contentRef = useRef(null);

  const handleToggleMore = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    const height = 70;
    if (contentRef.current.clientHeight > height) {
      setIsExpanded(true);
      setIsMore(true);
    }
  }, [page]);

  return (
    <>
      <Text
        as={Text.VARIANT.TEXT}
        width={"100%"}
        size={Button.FONT_SIZE.MOBILECONTENT}
        lineClamp={isExpanded ? "4" : "none"}
        margin={"20px 0"}
        lineHeight={"17.5px"}
        ref={contentRef}
      >
        {contents}
      </Text>

      {isMore && (
        <Button
          themeStyle={Button.THEME.NONE}
          borderTop="1px solid"
          borderColor={Button.COLOR.LINEGRAY}
          color={Button.COLOR.TEXTGRAY}
          fontSize={Button.FONT_SIZE.MOBILECONTENT}
          width={"100%"}
          padding={"10px 0"}
          onClick={handleToggleMore}
        >
          {isExpanded ? (
            <>
              {button.open} <BsChevronDown />
            </>
          ) : (
            <>
              {button.close} <BsChevronUp />
            </>
          )}
        </Button>
      )}
    </>
  );
};

export default MobileFeatureContent;
