import React from "react";
import { Select } from "antd";
import Box from "components/atoms/box";
import Button from "components/atoms/button";
import Input from "components/atoms/input";
import Text from "components/atoms/text";
import CheckBox from "components/molecules/checkbox";
import { Controller, useFormContext } from "react-hook-form";
import { useState } from "react";
import { useEffect } from "react";
import {
  useCheckEmailCodeMutation,
  useSendEmailCodeMutation,
} from "hooks/api/auth";
import signUpText from "text/signup";
import Loading from "components/atoms/loading";
import { BsChevronDown } from "@react-icons/all-files/bs/BsChevronDown";

const { pending } = signUpText;

const SignupForm = () => {
  const { register, watch, setValue, control } = useFormContext();

  const [isReadOnly, setIsReadOnly] = useState(false);
  const [isSendMail, setIsSendMail] = useState(false);
  const [isMailLoading, setIsMailLoading] = useState(false);

  const handleSuccessSendEmail = () => {
    setIsSendMail(true);
    setIsMailLoading(false);
    alert(pending.form.alert.sendEmail.success);
  };

  const handleFailSendEmail = (error) => {
    const message =
      error.response.data.message ===
      "입력하신 정보로 가입 된 계정이 이미 존재합니다."
        ? error.response.data.message
        : pending.form.alert.sendEmail.fail;

    setIsSendMail(false);
    setIsMailLoading(false);
    alert(message);
  };

  const handleSuccessCheckEmail = () => {
    alert(pending.form.alert.checkEmail.success);
    setValue("isEmail", true);
  };

  const handleFailCheckEmail = () => {
    alert(pending.form.alert.checkEmail.fail);
    setValue("isEmail", false);
  };

  const sendEmailCodeMutation = useSendEmailCodeMutation(
    handleSuccessSendEmail,
    handleFailSendEmail
  );
  const checkEmailCodeMutation = useCheckEmailCodeMutation(
    handleSuccessCheckEmail,
    handleFailCheckEmail
  );
  const emailSelect = watch("emailSelect");

  const email1 = watch("email");
  const email2 = watch("emailDomain");
  const emailCheck = watch("emailCheck");
  const isEmail = watch("isEmail");

  const email = isEmail ? `${email1}@${email2}` : "";

  const handleSubmitEmail = () => {
    setIsMailLoading(true);

    sendEmailCodeMutation({
      email1,
      email2,
    });
  };

  const handleChangePassword = (maxLength) => (e) => {
    const value = e.target.value;
    const name = e.target.name;
    const reg = /[0-9]/g;
    if (!reg.test(value) || value.length > maxLength) {
      return;
    }

    setValue(name, value);
  };

  useEffect(() => {
    const select =
      emailSelect === pending.form.emailSelect.title ? "" : emailSelect;

    setValue("emailDomain", select);

    if (select === "") {
      setIsReadOnly(false);
    } else {
      setIsReadOnly(true);
    }
  }, [emailSelect, setValue, setIsReadOnly]);

  return (
    <>
      <Text
        as={Text.VARIANT.TEXT}
        size={Text.SIZE.CLIENTCONTENT}
        color={Text.COLOR.TEXTGRAY}
      >
        <Text as={Text.VARIANT.SPAN} color={Text.COLOR.SECONDARY}>
          *
        </Text>
        {pending.form.info.common[0]}
        <Text as={Text.VARIANT.SPAN} weight={Text.WEIGHT.BOLD}>
          {pending.form.info.bold[0]}
        </Text>
        {pending.form.info.common[1]}
      </Text>

      <Box
        display={Box.DISPLAY.FLEX}
        padding={"22px 40px"}
        margin={"12px 0"}
        border={"1px solid"}
        borderColor={Box.COLOR.LINEGRAY}
        borderRadius={"20px"}
        bgColor={Text.COLOR.WHITE}
      >
        <Text
          as={Text.VARIANT.TEXT}
          size={Text.SIZE.CLIENTCONTENT}
          width={"100px"}
          margin={"0 28px 0 0"}
          borderRight={"1px solid"}
          borderColor={Text.COLOR.LINEGRAY}
          weight={Text.WEIGHT.BOLD}
          color={Text.COLOR.TEXTGRAY}
          isSort
        >
          {pending.form.name.title}
          <Text as={Text.VARIANT.SPAN} color={Text.COLOR.SECONDARY}>
            *
          </Text>
        </Text>

        <Input
          width={"calc(100% - 128px)"}
          border={"none"}
          size={Input.SIZE.CLIENTCONTENT}
          color={Input.COLOR.TEXTGRAY}
          {...register("name")}
        />
      </Box>

      <Box
        display={Box.DISPLAY.FLEX}
        padding={"22px 40px"}
        margin={"12px 0"}
        border={"1px solid"}
        borderColor={Box.COLOR.LINEGRAY}
        borderRadius={"20px"}
        bgColor={Text.COLOR.WHITE}
      >
        <Text
          as={Text.VARIANT.TEXT}
          size={Text.SIZE.CLIENTCONTENT}
          width={"100px"}
          margin={"0 28px 0 0"}
          borderRight={"1px solid"}
          borderColor={Text.COLOR.LINEGRAY}
          weight={Text.WEIGHT.BOLD}
          color={Text.COLOR.TEXTGRAY}
          isSort
        >
          {pending.form.tel.title}
          <Text as={Text.VARIANT.SPAN} color={Text.COLOR.SECONDARY}>
            *
          </Text>
        </Text>

        <Input
          width={"calc((100% - 280px) / 3)"}
          border={"none"}
          size={Input.SIZE.CLIENTCONTENT}
          color={Input.COLOR.TEXTGRAY}
          type={"tel"}
          {...register("phone1")}
          value={watch("phone1")}
          onChange={handleChangePassword(3)}
        />
        <Text
          as={Text.VARIANT.TEXT}
          size={Text.SIZE.CLIENTCONTENT}
          color={Text.COLOR.TEXTGRAY}
          weight={Text.WEIGHT.BOLD}
          margin={"0 28px"}
        >
          -
        </Text>
        <Input
          width={"calc((100% - 280px) / 3)"}
          border={"none"}
          size={Input.SIZE.CLIENTCONTENT}
          color={Input.COLOR.TEXTGRAY}
          type={"tel"}
          {...register("phone2")}
          value={watch("phone2")}
          onChange={handleChangePassword(4)}
        />
        <Text
          as={Text.VARIANT.TEXT}
          size={Text.SIZE.CLIENTCONTENT}
          color={Text.COLOR.TEXTGRAY}
          weight={Text.WEIGHT.BOLD}
          margin={"0 28px"}
        >
          -
        </Text>
        <Input
          width={"calc((100% - 280px) / 3)"}
          border={"none"}
          size={Input.SIZE.CLIENTCONTENT}
          color={Input.COLOR.TEXTGRAY}
          type={"tel"}
          {...register("phone3")}
          value={watch("phone3")}
          onChange={handleChangePassword(4)}
        />
      </Box>

      <Box display={Box.DISPLAY.FLEX} margin={"12px 0"}>
        <Box
          display={Box.DISPLAY.FLEX}
          width={"calc(100% - 176px - 134px)"}
          margin={"0 10px 0 0"}
          padding={"22px 40px"}
          border={"1px solid"}
          borderColor={Box.COLOR.LINEGRAY}
          borderRadius={"20px"}
          bgColor={isEmail ? Text.COLOR.GRAY : Text.COLOR.WHITE}
        >
          <Text
            as={Text.VARIANT.TEXT}
            size={Text.SIZE.CLIENTCONTENT}
            width={"100px"}
            margin={"0 28px 0 0"}
            borderRight={"1px solid"}
            borderColor={Text.COLOR.LINEGRAY}
            weight={Text.WEIGHT.BOLD}
            color={Text.COLOR.TEXTGRAY}
            isSort
          >
            {pending.form.email.title}
            <Text as={Text.VARIANT.SPAN} color={Text.COLOR.SECONDARY}>
              *
            </Text>
          </Text>
          <Input
            type={"email"}
            width={"calc(50% - 100px)"}
            border={"none"}
            size={Input.SIZE.CLIENTCONTENT}
            bgColor={isEmail ? Text.COLOR.GRAY : Text.COLOR.WHITE}
            color={Input.COLOR.TEXTGRAY}
            {...register("email")}
            readOnly={isEmail}
          />
          <Text
            as={Text.VARIANT.TEXT}
            size={Text.SIZE.CLIENTCONTENT}
            color={Text.COLOR.TEXTGRAY}
            weight={Text.WEIGHT.BOLD}
            margin={"0 28px"}
          >
            @
          </Text>
          <Input
            type={"email"}
            width={"calc(50% - 100px)"}
            border={"none"}
            size={Input.SIZE.CLIENTCONTENT}
            bgColor={isEmail ? Text.COLOR.GRAY : Text.COLOR.WHITE}
            color={Input.COLOR.TEXTGRAY}
            readOnly={isReadOnly || isEmail}
            {...register("emailDomain")}
          />
        </Box>

        <Controller
          name="emailSelect"
          defaultValue=""
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              disabled={isEmail}
              suffixIcon={
                <Text weight={Text.WEIGHT.BOLD} color={Text.COLOR.TEXTGRAY}>
                  <BsChevronDown size={16} />
                </Text>
              }
              className="admin-select"
              style={{ width: "176px", height: "62px", borderRadius: "20px" }}
              options={[
                { label: pending.form.emailSelect.title, value: "" },
                { value: "kofst.or.kr" },
                { value: "dreamwiz.com" },
                { value: "empal.com" },
                { value: "freechal.com" },
                { value: "gmail.com" },
                { value: "hanafos.com" },
                { value: "hanmail.net" },
                { value: "hanmir.com" },
                { value: "hotmail.com" },
                { value: "korea.com" },
                { value: "lycos.com" },
                { value: "nate.com" },
                { value: "naver.com" },
                { value: "netian.com" },
                { value: "paran.com" },
                { value: "yahoo.com.kr" },
                { value: "yahoo.com" },
                { value: "chol.com" },
              ]}
            />
          )}
        />

        {isMailLoading ? (
          <Box
            display={Box.DISPLAY.FLEX}
            jc={"center"}
            al={"center"}
            width={"124px"}
          >
            <Loading />
          </Box>
        ) : (
          <Button
            border={"1px solid"}
            borderColor={Button.COLOR.LINEGRAY}
            borderRadius={"20px"}
            width={"124px"}
            margin={"0 0 0 10px"}
            bgColor={isEmail ? Button.COLOR.GRAY : Button.COLOR.WHITE}
            color={Button.COLOR.TEXTGRAY}
            fontSize={Button.FONT_SIZE.CLIENTCONTENT}
            fontWeight={Button.FONT_WEIGHT.BOLD}
            onClick={() => {
              !isEmail && handleSubmitEmail();
            }}
          >
            {pending.form.sendEmail.title}
          </Button>
        )}
      </Box>

      <Box display={Box.DISPLAY.FLEX} jc={"flex-end"} margin={"12px 0"}>
        <Box
          display={Box.DISPLAY.FLEX}
          width={"526px"}
          margin={"0 10px 0 0"}
          padding={"22px 40px"}
          border={"1px solid"}
          borderColor={Box.COLOR.LINEGRAY}
          borderRadius={"20px"}
          bgColor={isEmail ? Button.COLOR.GRAY : Button.COLOR.WHITE}
        >
          <Input
            width={"100%"}
            border={"none"}
            size={Input.SIZE.CLIENTCONTENT}
            color={Input.COLOR.TEXTGRAY}
            bgColor={isEmail ? Button.COLOR.GRAY : Button.COLOR.WHITE}
            placeholder={pending.form.emailCheck.placeholder}
            {...register("emailCheck")}
            readOnly={isEmail}
          />
        </Box>
        <Button
          border={"1px solid"}
          borderColor={Button.COLOR.LINEGRAY}
          borderRadius={"20px"}
          width={"124px"}
          bgColor={isEmail ? Button.COLOR.GRAY : Button.COLOR.WHITE}
          color={Button.COLOR.TEXTGRAY}
          fontSize={Button.FONT_SIZE.CLIENTCONTENT}
          fontWeight={Button.FONT_WEIGHT.BOLD}
          onClick={() => {
            isSendMail &&
              !isEmail &&
              checkEmailCodeMutation({ email1, email2, code: emailCheck });
          }}
        >
          {pending.form.checkEmail.title}
        </Button>
      </Box>

      <Box
        display={Box.DISPLAY.FLEX}
        padding={"22px 40px"}
        margin={"12px 0"}
        border={"1px solid"}
        borderColor={Box.COLOR.LINEGRAY}
        borderRadius={"20px"}
        bgColor={Text.COLOR.GRAY}
      >
        <Text
          as={Text.VARIANT.TEXT}
          size={Text.SIZE.CLIENTCONTENT}
          width={"100px"}
          margin={"0 28px 0 0"}
          borderRight={"1px solid"}
          borderColor={Text.COLOR.LINEGRAY}
          weight={Text.WEIGHT.BOLD}
          color={Text.COLOR.TEXTGRAY}
          isSort
        >
          {pending.form.id.title}
        </Text>

        <Input
          width={"calc(100% - 128px)"}
          border={"none"}
          size={Input.SIZE.CLIENTCONTENT}
          color={Input.COLOR.TEXTGRAY}
          bgColor={Text.COLOR.GRAY}
          value={email}
          readOnly
          placeholder={"이메일 주소를 아이디로 사용합니다"}
        />
      </Box>

      <Box
        display={Box.DISPLAY.FLEX}
        padding={"22px 40px"}
        margin={"12px 0"}
        border={"1px solid"}
        borderColor={Box.COLOR.LINEGRAY}
        borderRadius={"20px"}
        bgColor={Text.COLOR.WHITE}
      >
        <Text
          as={Text.VARIANT.TEXT}
          size={Text.SIZE.CLIENTCONTENT}
          width={"100px"}
          margin={"0 28px 0 0"}
          borderRight={"1px solid"}
          borderColor={Text.COLOR.LINEGRAY}
          weight={Text.WEIGHT.BOLD}
          color={Text.COLOR.TEXTGRAY}
          isSort
        >
          {pending.form.password.title}
          <Text as={Text.VARIANT.SPAN} color={Text.COLOR.SECONDARY}>
            *
          </Text>
        </Text>

        <Input
          type={"password"}
          width={"calc(100% - 128px)"}
          border={"none"}
          size={Input.SIZE.CLIENTCONTENT}
          color={Input.COLOR.TEXTGRAY}
          placeholder={pending.form.password.placeholder}
          {...register("password")}
        />
      </Box>

      <Box
        display={Box.DISPLAY.FLEX}
        padding={"22px 40px"}
        margin={"12px 0"}
        border={"1px solid"}
        borderColor={Box.COLOR.LINEGRAY}
        borderRadius={"20px"}
        bgColor={Text.COLOR.WHITE}
      >
        <Text
          as={Text.VARIANT.TEXT}
          size={Text.SIZE.CLIENTCONTENT}
          width={"100px"}
          margin={"0 28px 0 0"}
          borderRight={"1px solid"}
          borderColor={Text.COLOR.LINEGRAY}
          weight={Text.WEIGHT.BOLD}
          color={Text.COLOR.TEXTGRAY}
          isSort
        >
          {pending.form.passwordCheck.title}
          <Text as={Text.VARIANT.SPAN} color={Text.COLOR.SECONDARY}>
            *
          </Text>
        </Text>

        <Input
          type={"password"}
          width={"calc(100% - 128px)"}
          border={"none"}
          size={Input.SIZE.CLIENTCONTENT}
          color={Input.COLOR.TEXTGRAY}
          {...register("passwordCheck")}
        />
      </Box>

      <Box
        as={"label"}
        display={Box.DISPLAY.FLEX}
        al={"center"}
        padding={"18px 0"}
        margin={"40px 0 0"}
        borderTop={"1px solid"}
        borderBottom={"1px solid"}
        borderColor={Text.COLOR.LINEGRAY}
      >
        <CheckBox
          {...register("isAgreement")}
          borderColor={Input.COLOR.SECONDARY}
        />
        <Text
          as={Text.VARIANT.TEXT}
          color={Text.COLOR.TEXTGRAY}
          margin={"0 0 0 6px"}
          size={Text.SIZE.CLIENTCONTENT}
        >
          {pending.form.newsletterAgree}
        </Text>
      </Box>
    </>
  );
};

export default SignupForm;
