import MobileLink from "components/molecules/mobileLink";
import React, { useEffect } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { RouterProvider } from "react-router-dom";
import createRouter from "routes";
import useMobileStore from "store/useMobileStore";
import { ThemeProvider } from "styled-components";
import GlobalStyle from "styles/base";
import theme from "styles/theme";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,

      refetchOnWindowFocus: false,
      // refetchOnMount: false,
      // refetchOnReconnect: false,
    },
  },
});

function App() {
  const { setIsMobile, isMobile } = useMobileStore();
  const router = createRouter(isMobile);

  useEffect(() => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    setIsMobile(isMobile);
  }, [setIsMobile]);

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <RouterProvider router={router} />
        <MobileLink />
      </ThemeProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default App;
