import Box from "components/atoms/box";
import dayjs from "dayjs";
import React, { useEffect } from "react";
import relativeTime from "dayjs/plugin/relativeTime";
import useToggle from "hooks/utils/useToggle";
import CommentForm from "../commentForm";
import CommentContent from "../commentContent";
import useMobileStore from "store/useMobileStore";

dayjs.extend(relativeTime);

const CommentItem = (props) => {
  const {
    seq,
    sortType,
    replyList,
    writer,
    contents,
    regDate,
    likeCnt,
    replyCnt,
    myComment,
    myCommentLike,
    deletedComment,
    commentOn,
  } = props;
  const { isMobile } = useMobileStore();
  const [isOpen, toggleOpen, setOpen] = useToggle(false);
  const isReply = !replyList;

  useEffect(() => {
    setOpen(false);
  }, [setOpen, sortType]);

  return (
    <Box
      padding={isReply ? "0" : isMobile ? "12px 0 0" : "22px 0 0"}
      borderBottom={isReply ? "none" : "1px solid"}
      borderColor={Box.COLOR.LINEGRAY}
    >
      <Box
        padding={
          isMobile
            ? isOpen && commentOn
              ? "0 0 20px 0"
              : "0 0 0 0"
            : isOpen && commentOn
            ? "0 0 20px 10px"
            : "0 0 0 10px"
        }
      >
        <CommentContent
          seq={seq}
          writer={writer}
          regDate={regDate}
          likeCnt={likeCnt}
          replyCnt={replyCnt}
          contents={contents}
          deletedComment={deletedComment}
          myComment={myComment}
          myCommentLike={myCommentLike}
          toggleOpen={toggleOpen}
          isReply={isReply}
          key={sortType}
          commentOn={commentOn}
        />

        {isOpen && commentOn && (
          <CommentForm height={"60px"} seq={seq} commentOn={commentOn} />
        )}
      </Box>

      {isOpen &&
        replyList.map((reply) => (
          <Box
            padding={"22px 0 20px"}
            borderTop="1px solid"
            borderColor={Box.COLOR.LINEGRAY}
            bgColor={Box.COLOR.ADMINCONTENTSBG}
            key={reply.seq}
          >
            <Box padding={isMobile ? "0 20px" : "0 20px 0 66px"}>
              <CommentItem {...reply} commentOn={commentOn} />
            </Box>
          </Box>
        ))}
    </Box>
  );
};

export default CommentItem;
