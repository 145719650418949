import Box from "components/atoms/box";
import Board from "components/organisms/board";
import NewsCategory from "components/organisms/newsCategory";
import {
  useRankMonthScience,
  useRankWeekScience,
  usePreFetchRankScience,
} from "hooks/api/science";
import useUrl from "hooks/utils/useUrl";
import React from "react";

const dailyNewsList = [
  { title: "주간 TOP Click" },
  { title: "월간 TOP Click" },
];

const typeForm = ["all", "column", "focus"];

const Science = () => {
  const {
    query: { category = "all" },
  } = useUrl();

  const boardType = "science";
  const isAllPage = category === "all";

  return (
    <Box
      width="100%"
      display={Box.DISPLAY.FLEX}
      flexDirection="column"
      al="center"
    >
      {isAllPage && (
        <NewsCategory
          type={"science"}
          newsInfoList={dailyNewsList}
          category={"science"}
        />
      )}

      <Board isAllPage={isAllPage}>
        <Board.News
          category={category}
          typeForm={typeForm}
          boardType={boardType}
        />
      </Board>
    </Box>
  );
};

export default Science;
