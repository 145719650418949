import styled from "styled-components";

export const AdminContainer = styled.main`
  display: flex;
  align-items: flex-start;
  background-color: #f8f9fb;
`;

export const AdminContentsContainer = styled.section`
  padding: 22px 24px;
  flex: 1 1 0;
`;
