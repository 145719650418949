import { getPopup } from "api/popup";
import { useQuery } from "react-query";

export const usePopup = () => {
  const { data, isLoading, isSuccess, isError } = useQuery("popup", getPopup);

  const list = data?.data.list;

  return {
    list,
    isLoading,
    isSuccess,
    isError,
  };
};
