import { create } from "zustand";

const useDropdownStore = create((set) => ({
  activeDropdownIdx: sessionStorage.getItem("selectedId"),

  setActiveDropdownIdx: (idx) => {
    sessionStorage.setItem("selectedId", idx);
    set({ activeDropdownIdx: idx });
  },
}));

export default useDropdownStore;
