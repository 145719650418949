import Box from "components/atoms/box";
import Button from "components/atoms/button";
import Input from "components/atoms/input";
import Text from "components/atoms/text";
import { useCreateCommentMutation } from "hooks/api/comment";
import useUrl from "hooks/utils/useUrl";
import React from "react";
import { useForm } from "react-hook-form";
import useMobileStore from "store/useMobileStore";
import useUserStore from "store/useUserStore";
import detailText from "text/detail";

const { comment } = detailText;

const CommentForm = (props) => {
  const { height, seq, commentOn, contents = "" } = props;
  const { register, watch, handleSubmit, reset } = useForm({
    defaultValues: {
      contents,
    },
  });
  const { path, changeLink } = useUrl();
  const { user } = useUserStore();
  const { isMobile } = useMobileStore();

  const createCommentMutation = useCreateCommentMutation(reset);

  const handleSubmitComment = (data) => {
    const body = {
      stepNo: 1,
      refNo: "",
      contents: data.contents,
    };

    if (seq) {
      body.stepNo = 2;
      body.refNo = seq;
    }

    createCommentMutation({
      key: path[1],
      body,
    });
  };

  const handleAlert = () => {
    if (commentOn && window.confirm(comment.form.confirm.login)) {
      changeLink("/login");
      localStorage.setItem(
        "redirect",
        window.location.pathname + window.location.search
      );
    }
  };

  const isActive = watch("contents").trim() !== "";

  return (
    <Box isClearBlank>
      <Box
        width={"100%"}
        borderRadius={user && commentOn ? "20px 20px 0 0" : "20px"}
        borderColor={Box.COLOR.LINEGRAY}
        bgColor={Input.COLOR.TRANSPARENT}
        border={"1px solid"}
      >
        {user && commentOn && (
          <Text
            as={Text.VARIANT.TEXT}
            size={
              isMobile ? Text.SIZE.MOBILECONTENT : Text.SIZE.CLIENTCONTENTDETAIL
            }
            padding={"15px 0 0 15px"}
            weight={Text.WEIGHT.BOLD}
          >
            {user.userName}
          </Text>
        )}

        {user && commentOn ? (
          <Input
            as={Input.VARIANT.TEXTAREA}
            width={"100%"}
            height={height}
            bgColor={Input.COLOR.TRANSPARENT}
            placeholder={comment.form.contents}
            padding={"15px"}
            resize={"none"}
            border={"none"}
            size={
              isMobile ? Input.SIZE.MOBILECONTENT : Input.SIZE.CLIENTCONTENT
            }
            {...register("contents")}
          />
        ) : (
          <Box
            display={Box.DISPLAY.FLEX}
            jc="left"
            al="center"
            height={height}
            padding={"0 15px"}
            cursor={"pointer"}
            onClick={handleAlert}
          >
            <Text
              as={Text.VARIANT.TEXT}
              color={Text.COLOR.TEXTGRAY}
              size={
                isMobile ? Text.SIZE.MOBILECONTENT : Text.SIZE.CLIENTCONTENT
              }
            >
              {commentOn ? comment.form.login : comment.form.comment}
            </Text>
          </Box>
        )}
      </Box>
      {user && commentOn && (
        <Box
          display={Box.DISPLAY.FLEX}
          jc={"flex-end"}
          borderRadius={"0 0 20px 20px"}
          border="1px solid"
          borderTop="none"
          borderColor={Box.COLOR.LINEGRAY}
        >
          <Button
            padding={isMobile ? "7px 20px" : "10px 30px"}
            margin={isMobile ? "7px 20px 7px 0" : "0"}
            bgColor={isActive ? Button.COLOR.SECONDARY : Button.COLOR.LINEGRAY}
            color={Button.COLOR.WHITE}
            border={"none"}
            borderRadius={isMobile ? "60px" : "0 0 20px 0"}
            onClick={handleSubmit(handleSubmitComment)}
            disabled={!isActive}
          >
            {comment.form.submit}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default CommentForm;
