import Box from "components/atoms/box";
import Loading from "components/atoms/loading";
import ImageUploader from "components/molecules/imageUploader";
import InputForm from "components/molecules/inputForm";
import AdminDetailForm from "components/organisms/adminDetailForm";
import AdminDetailFormContainer from "components/organisms/adminDetailFormContainer";
import DatePickerList from "components/organisms/datePickerList";
import { AdminContentsContainer } from "components/templates/admin/admin.style";
import dayjs from "dayjs";
import useUrl from "hooks/utils/useUrl";
import React, { useRef } from "react";
import NamoWebEditor from "components/molecules/namoWebEditor";
import FileUploader from "components/molecules/fileUploader";
import {
  useCreateCoverage,
  useScienceCoverageDetail,
  useUpdateCoverageInfo,
} from "hooks/api/admin/coverage";
import { coverage } from "text/admin/coverage";
import { useEffect } from "react";
import usePendingMutate from "hooks/utils/usePendingMutate";
import useEventStore from "store/useEventStore";

const CoverageDetail = () => {
  const {
    query: { key },
  } = useUrl();

  const { defaultValues } = coverage.detail;

  const subTitle = key ? "일정 수정" : "일정 등록";

  const { list, isLoading, fileList } = useScienceCoverageDetail({ key });
  const { isPending, handleMutate } = usePendingMutate();

  const handleSuccess = () => {
    localStorage.removeItem("schedule");
  };

  const api = key ? useUpdateCoverageInfo : useCreateCoverage;
  const mutate = api(handleSuccess);

  const { selectedStartDate, selectedEndDate, setSelectedStartDate, setSelectedEndDate } =
    useEventStore();

  useEffect(() => {
    if (list) {
      const { eventBeginDate, eventEndDate } = list;
      setSelectedStartDate(dayjs(eventBeginDate));
      setSelectedEndDate(dayjs(eventEndDate));
    }

    return () => {
      const today = dayjs();
      setSelectedStartDate(today);
      setSelectedEndDate(today);
    };
  }, [list]);

  const _instanceRef = useRef(null);

  const formInfo = [
    {
      title: "제목",
      height: "48px",
      components: (
        <InputForm
          name="title"
          type="text"
          width="500px"
          height="32px"
          padding="0 12px"
          theme="secondary"
          defaultName={list?.title}
          ariaLabel="제목"
        />
      ),
      required: true,
    },
    {
      title: "일정",
      height: "48px",
      components: (
        <DatePickerList
          selectedStartDate={selectedStartDate}
          selectedEndDate={selectedEndDate}
          setSelectedStartDate={setSelectedStartDate}
          setSelectedEndDate={setSelectedEndDate}
        />
      ),
      required: false,
    },
    {
      title: "내용",
      minHeight: "436px",
      components: <NamoWebEditor name="editor" defaultValue={list?.contents} ref={_instanceRef} />,
      required: false,
      isUpperPosition: true,
    },
    {
      title: "첨부파일",
      minHeight: "48px",
      components: <FileUploader registerName="files" defaultFileList={fileList} no={key} />,
      required: false,
    },
    {
      title: "썸네일 등록",
      minHeight: "281px",
      components: (
        <ImageUploader
          registerName="thumbnail"
          comments={["- 권장 이미지 : 596px*400px / 10mb 이하 / jpg,png 파일"]}
          defaultPath={list?.thumbnail ?? null}
          serverImgPath="thumbnail"
        />
      ),
      required: false,
      isUpperPosition: true,
    },
  ];

  const formatToYYYYMMDD = (date) => date.format("YYYY-MM-DD");

  const eventDates = {
    eventBeginDate: formatToYYYYMMDD(selectedStartDate),
    eventEndDate: formatToYYYYMMDD(selectedEndDate),
  };

  const createFormData = (data, contents, files, thumbnail) => {
    const formData = new FormData();
    formData.append(
      "param",
      JSON.stringify({
        ...data,
        contents,
      })
    );
    if (files && files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        formData.append("files", files[i]);
      }
    }
    if (thumbnail) {
      formData.append("thumbnail", thumbnail);
    }
    return formData;
  };

  const handleSubmit = (data) => {
    const contents = _instanceRef.current.GetBodyValue();
    const { title, thumbnail, files } = data;
    const formData = createFormData(
      {
        title,
        ...eventDates,
      },
      contents,
      files,
      thumbnail
    );

    handleMutate(mutate, { data: formData, key });
  };

  if (isLoading) return <Loading />;

  return (
    <AdminContentsContainer>
      <Box maxWidth="1024px">
        <AdminDetailFormContainer
          title={subTitle}
          defaultValues={defaultValues}
          handleSubmit={handleSubmit}
          isPending={isPending}
          hasTemporaryFunc={true}
          eventDates={eventDates}
          ref={_instanceRef}
        >
          <AdminDetailForm formInfo={formInfo} />
        </AdminDetailFormContainer>
      </Box>
    </AdminContentsContainer>
  );
};

export default CoverageDetail;
