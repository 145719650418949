import Box from "components/atoms/box";
import Text from "components/atoms/text";
import React, { useEffect, useState } from "react";
import convertDayWithTime from "utils/convertDayWithTime";
import HasNotResult from "../HasNotResult";
import useAllCheckbox from "hooks/utils/useAllCheckbox";
import Input from "components/atoms/input";
import TableDeleteBar from "../tableDeleteBar";
import { useDeleteNewsletterApplier } from "hooks/api/admin/news_letter";
import { newsletter } from "api/admin/url";
import generateExcelFile from "utils/generateExcelFile";
import { common } from "text/admin/common";
import useUrl from "hooks/utils/useUrl";

const ApplierTable = (props) => {
  const { list, tableHeader, searchCondition, keyword } = props;

  const {
    default: { remove },
    selectRemoveItems,
  } = common.alert;

  const {
    query: { page = 1 },
  } = useUrl();

  const currPage = parseInt(page);

  let keyArr = [];
  const [allKeyList, setAllKeyList] = useState([]);

  useEffect(() => {
    setAllKeyList(keyArr);
  }, [list]);

  const { applierUrl } = newsletter;

  const {
    checkAll,
    checkboxes,
    resetCheckbox,
    selectedKeys,
    handleCheckAllChange,
    handleCheckboxChange,
  } = useAllCheckbox(list.length, allKeyList);

  const isNoneSelected = selectedKeys.length === 0;

  const mutate = useDeleteNewsletterApplier(resetCheckbox);

  const handleDeleteSelectedApplier = () => {
    const data = { keyList: selectedKeys };

    if (isNoneSelected) {
      alert(selectRemoveItems);
      return;
    }

    const confirm = window.confirm(remove);
    if (confirm) mutate({ data });
  };

  const handleExcelDownload = () => {
    const fileName = "뉴스레터 신청자 목록";
    const condition = { searchCondition, keyword };
    generateExcelFile(applierUrl, fileName, condition);
  };

  return (
    <>
      <TableDeleteBar
        handleDelete={handleDeleteSelectedApplier}
        handleExcelDownload={handleExcelDownload}
      />
      <Box
        display={Box.DISPLAY.FLEX}
        height="47px"
        borderTop="1px solid"
        borderColor={Box.COLOR.LINEGRAY}
      >
        <Box
          width="4%"
          height="100%"
          display={Box.DISPLAY.FLEX}
          al="center"
          jc="center"
          borderLeft="1px solid"
          borderColor={Box.COLOR.ADMINLINEGRAY}
          bgColor={Box.COLOR.ADMINCONTENTSBG}
        >
          <Input
            type="checkbox"
            width="16px"
            height="16px"
            borderColor={Input.COLOR.ADMINLINEGRAY}
            onChange={handleCheckAllChange}
            checked={checkAll}
          />
        </Box>
        {tableHeader.map(({ title, width, ariaLabel }, idx) => (
          <Box
            display={Box.DISPLAY.FLEX}
            al="center"
            jc="center"
            width={width}
            padding={"8px 0"}
            key={idx}
            borderBottom={list.length === 0 ? "1px solid" : ""}
            borderRight={idx === tableHeader.length - 1 ? "1px solid" : ""}
            borderLeft="1px solid"
            borderColor={Box.COLOR.LINEGRAY}
            bgColor={Box.COLOR.ADMINCONTENTSBG}
          >
            <Text
              as={Text.VARIANT.TEXT}
              weight={Text.WEIGHT.BOLD}
              size={Text.SIZE.CLIENTCONTENTDETAIL}
              textAlign="center"
              ariaLabel={ariaLabel}
            >
              {title}
            </Text>
          </Box>
        ))}
      </Box>
      {list.length === 0 && <HasNotResult />}
      {list.length !== 0 &&
        list.map(({ email1, email2, regDate, userName, key }, idx) => {
          const pageIdx = currPage > 1 ? (currPage - 1) * 10 + (idx + 1) : idx + 1;
          keyArr.push(key);

          return (
            <Box
              key={key}
              borderTop={idx === 0 ? "1px solid" : ""}
              borderBottom="1px solid"
              borderColor={Box.COLOR.LINEGRAY}
              display={Box.DISPLAY.FLEX}
              height="53px"
            >
              <Box
                width="4%"
                height="100%"
                display={Box.DISPLAY.FLEX}
                jc="center"
                borderLeft="1px solid"
                borderColor={Box.COLOR.ADMINLINEGRAY}
                al="center"
              >
                <Input
                  type="checkbox"
                  name={key}
                  width="16px"
                  height="16px"
                  borderColor={Input.COLOR.ADMINLINEGRAY}
                  checked={checkboxes[key] ?? false}
                  onChange={(e) => handleCheckboxChange({ e })}
                />
              </Box>
              <Box
                width="7%"
                display={Box.DISPLAY.FLEX}
                jc="center"
                al="center"
                borderLeft="1px solid"
                borderColor={Box.COLOR.LINEGRAY}
              >
                <Text
                  as={Text.VARIANT.TEXT}
                  size={Text.SIZE.CLIENTCONTENTDETAIL}
                  textAlign="center"
                  ariaLabel={`No: ${pageIdx}`}
                >
                  {pageIdx}
                </Text>
              </Box>
              <Box
                width="29%"
                display={Box.DISPLAY.FLEX}
                jc="center"
                al="center"
                borderLeft="1px solid"
                borderColor={Box.COLOR.LINEGRAY}
              >
                <Text
                  as={Text.VARIANT.TEXT}
                  size={Text.SIZE.CLIENTCONTENTDETAIL}
                  textAlign="center"
                  ariaLabel={`뉴스레터 신청자 ID : ${email1}@${email2}`}
                >
                  {`${email1}@${email2}`}
                </Text>
              </Box>
              <Box
                width="18%"
                display={Box.DISPLAY.FLEX}
                jc="center"
                al="center"
                borderLeft="1px solid"
                borderColor={Box.COLOR.LINEGRAY}
                padding="0 10px"
              >
                <Text
                  as={Text.VARIANT.TEXT}
                  size={Text.SIZE.CLIENTCONTENTDETAIL}
                  textAlign="center"
                  ariaLabel={`회원명: ${userName}`}
                >
                  {userName}
                </Text>
              </Box>
              <Box
                width="26%"
                display={Box.DISPLAY.FLEX}
                jc="center"
                al="center"
                borderLeft="1px solid"
                borderColor={Box.COLOR.LINEGRAY}
                padding="0 10px"
              >
                <Text
                  as={Text.VARIANT.TEXT}
                  size={Text.SIZE.CLIENTCONTENTDETAIL}
                  textAlign="center"
                  ariaLabel={`뉴스레터 신청자 이메일 : ${email1}@${email2}`}
                >
                  {`${email1}@${email2}`}
                </Text>
              </Box>
              <Box
                width="16%"
                display={Box.DISPLAY.FLEX}
                jc="center"
                al="center"
                borderLeft="1px solid"
                borderRight="1px solid"
                borderColor={Box.COLOR.LINEGRAY}
              >
                <Text
                  as={Text.VARIANT.TEXT}
                  size={Text.SIZE.CLIENTCONTENTDETAIL}
                  textAlign="center"
                  ariaLabel={regDate}
                >
                  {convertDayWithTime(regDate)}
                </Text>
              </Box>
            </Box>
          );
        })}
    </>
  );
};

export default ApplierTable;
