const convertEmailFormatter = (email) => {
  const email1 = email.split("@")[0];
  const email2 = email.split("@")[1];

  return {
    email1,
    email2,
  };
};

export default convertEmailFormatter;
