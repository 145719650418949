import Box from "components/atoms/box";
import Button from "components/atoms/button";
import Input from "components/atoms/input";
import Text from "components/atoms/text";
import dayjs from "dayjs";
import {
  useCreateLikeMutation,
  useDeleteCommentMutation,
  useDeleteLikeMutation,
  useUpdateCommentMutation,
} from "hooks/api/comment";
import useToggle from "hooks/utils/useToggle";
import useUrl from "hooks/utils/useUrl";
import React, { useState, useRef } from "react";
import { useEffect } from "react";
import { FaCircle } from "@react-icons/all-files/fa/FaCircle";
import useMobileStore from "store/useMobileStore";
import useUserStore from "store/useUserStore";
import { color } from "styles/theme";
import detailText from "text/detail";

const { comment } = detailText;

const CommentContent = (props) => {
  const {
    seq,
    writer,
    deletedComment,
    regDate,
    likeCnt,
    replyCnt,
    contents,
    myComment,
    myCommentLike,
    toggleOpen,
    commentOn,
    isReply,
  } = props;
  const { path, changeLink } = useUrl();

  const [reply, setReply] = useState(contents);
  const [isMore, setIsMore] = useState(false);
  const contentRef = useRef(null);

  const [isExpanded, toggleExpended, setIsExpanded] = useToggle(false);
  const [isUpdate, toggleUpdate] = useToggle(false);
  const { user } = useUserStore();
  const { isMobile } = useMobileStore();

  const isDelete = deletedComment === "1";
  const isComment = myComment === "1";
  const isLike = myCommentLike === "1";

  const handleSuccessUpdate = () => {
    toggleUpdate();
  };

  const handleFailUpdate = () => {
    alert(comment.item.alert.failUpdate);
  };

  const handleFailDelete = () => {
    alert(comment.item.alert.failDelete);
  };

  const handleSuccessCreateLike = () => {
    alert(comment.item.alert.successLike);
  };

  const handleFailCreateLike = () => {
    alert(comment.item.alert.failLike);
  };

  const handleSuccessDeleteLike = () => {
    alert(comment.item.alert.successDeleteLike);
  };

  const handleFailDeleteLike = () => {
    alert(comment.item.alert.failDeleteLike);
  };

  const updateCommentMutation = useUpdateCommentMutation(
    handleSuccessUpdate,
    handleFailUpdate
  );
  const deleteCommentMutation = useDeleteCommentMutation(handleFailDelete);
  const createLikeMutation = useCreateLikeMutation(
    handleSuccessCreateLike,
    handleFailCreateLike
  );
  const deleteLikeMutation = useDeleteLikeMutation(
    handleSuccessDeleteLike,
    handleFailDeleteLike
  );

  const likeMutation = isLike ? deleteLikeMutation : createLikeMutation;

  const handleSubmitLike = () => {
    if (user) likeMutation(path[1], seq);
    else {
      if (window.confirm(comment.item.confirm.login)) changeLink("/login");
    }
  };

  const handleSubmitUpdate = () => {
    if (window.confirm(comment.item.confirm.update)) {
      updateCommentMutation(path[1], seq, { contents: reply });
    }
  };

  const handleSubmitDelete = () => {
    if (window.confirm(comment.item.confirm.delete)) {
      deleteCommentMutation(path[1], seq);
    }
  };

  const getFormattedDate = (inputDate) => {
    const currentDate = dayjs();
    const inputDateDayjs = dayjs(inputDate);
    const diff = currentDate.diff(inputDateDayjs, "day");

    if (diff <= 3 && diff > 0) {
      return `${diff}${comment.item.date.prev}`;
    } else if (diff === 0) {
      return comment.item.date.today;
    } else {
      return inputDateDayjs.format("YYYY-MM-DD");
    }
  };

  useEffect(() => {
    const height = isMobile ? 24 : 44;
    if (contentRef.current.clientHeight > height) {
      setIsExpanded(true);
      setIsMore(true);
    }
  }, [isMobile, setIsExpanded, setIsMore]);

  return (
    <>
      <Box display={Box.DISPLAY.FLEX} jc={"space-between"}>
        <Text
          isSort
          as={Text.VARIANT.TEXT}
          size={
            isMobile ? Text.SIZE.MOBILECONTENT : Text.SIZE.CLIENTCONTENTDETAIL
          }
          weight={Text.WEIGHT.BOLD}
        >
          {writer}&nbsp;
          <Text
            isSort
            color={Text.COLOR.TEXTGRAY}
            as={Text.VARIANT.SPAN}
            weight={Text.WEIGHT.NORMAL}
          >
            <FaCircle size={4} color={color.lineGray} />
            &nbsp;{getFormattedDate(regDate)}
          </Text>
        </Text>

        {commentOn && isComment && !isDelete && (
          <Box>
            <Button
              themeStyle={Button.THEME.NONE}
              fontSize={
                isMobile
                  ? Button.FONT_SIZE.MOBILECONTENT
                  : Button.FONT_SIZE.CLIENTCONTENTDETAIL
              }
              color={Button.COLOR.TEXTGRAY}
              margin={"0 15px 0 0"}
              onClick={toggleUpdate}
            >
              {comment.item.update}
            </Button>
            <Button
              themeStyle={Button.THEME.NONE}
              fontSize={
                isMobile
                  ? Button.FONT_SIZE.MOBILECONTENT
                  : Button.FONT_SIZE.CLIENTCONTENTDETAIL
              }
              color={Button.COLOR.TEXTGRAY}
              onClick={handleSubmitDelete}
            >
              {comment.item.delete}
            </Button>
          </Box>
        )}
      </Box>
      <Box display={Box.DISPLAY.FLEX} jc={"flex-end"} wrap={"wrap"}>
        <Text
          ref={contentRef}
          width={"100%"}
          whiteSpace={"break-spaces"}
          margin={"10px 0 0"}
          size={isMobile ? Text.SIZE.MOBILECONTENT : Text.SIZE.CLIENTCONTENT}
          lineClamp={isExpanded ? "2" : "none"}
          wordBreak={"break-all"}
        >
          {isDelete ? comment.item.deleteComment : contents}
        </Text>
        {isMore && (
          <Button
            themeStyle={Button.THEME.NONE}
            onClick={toggleExpended}
            color={Button.COLOR.TEXTGRAY}
            margin={"12px 0 0"}
            fontSize={
              isMobile
                ? Button.FONT_SIZE.MOBILECONTENT
                : Button.FONT_SIZE.CLIENTCONTENT
            }
          >
            {isExpanded
              ? comment.item.expanded.accept
              : comment.item.expanded.cancel}
          </Button>
        )}
      </Box>

      {isUpdate && (
        <Box
          padding={isMobile ? "10px" : "15px"}
          border="1px solid"
          borderColor={Box.COLOR.LINEGRAY}
          borderRadius={"20px"}
          margin={"15px 0 10px"}
        >
          <Input
            as={Input.VARIANT.TEXTAREA}
            width={"100%"}
            height={"44px"}
            bgColor={Input.COLOR.TRANSPARENT}
            placeholder={comment.item.form.contents}
            border={"none"}
            resize={"none"}
            size={
              isMobile ? Input.SIZE.MOBILECONTENT : Input.SIZE.CLIENTCONTENT
            }
            value={reply}
            onChange={(e) => setReply(e.target.value)}
          />

          <Box display={Box.DISPLAY.FLEX} jc={"flex-end"}>
            <Button
              themeStyle={Button.THEME.NONE}
              borderRadius={"20px"}
              padding={isMobile ? "5px 15px" : "10px 30px"}
              bgColor={
                Button.COLOR.LINEGRAY
                // Button.COLOR.SECONDARY
              }
              color={Button.COLOR.WHITE}
              fontSize={
                isMobile
                  ? Button.FONT_SIZE.MOBILECONTENT
                  : Button.FONT_SIZE.CLIENTCONTENT
              }
              margin={"0 12px 0 0"}
              onClick={toggleUpdate}
            >
              {comment.item.form.submit.cancel}
            </Button>
            <Button
              themeStyle={Button.THEME.NONE}
              borderRadius={"20px"}
              padding={isMobile ? "5px 15px" : "10px 30px"}
              bgColor={reply ? Button.COLOR.SECONDARY : Button.COLOR.LINEGRAY}
              color={Button.COLOR.WHITE}
              fontSize={
                isMobile
                  ? Button.FONT_SIZE.MOBILECONTENT
                  : Button.FONT_SIZE.CLIENTCONTENT
              }
              onClick={handleSubmitUpdate}
            >
              {comment.item.form.submit.accept}
            </Button>
          </Box>
        </Box>
      )}
      <Box
        display={Box.DISPLAY.FLEX}
        margin={isReply ? "10px 0 0" : "10px 0 20px"}
      >
        {!isDelete && (
          <Button
            themeStyle={Button.THEME.NONE}
            fontSize={
              isMobile
                ? Button.FONT_SIZE.MOBILECONTENT
                : Button.FONT_SIZE.CLIENTCONTENTDETAIL
            }
            fontWeight={
              isLike ? Button.FONT_WEIGHT.BOLD : Button.FONT_WEIGHT.NORMAL
            }
            color={isLike ? Button.COLOR.SECONDARY : Button.COLOR.TEXTGRAY}
            margin={"0 15px 0 0"}
            onClick={handleSubmitLike}
          >
            {comment.item.like} {likeCnt}
          </Button>
        )}
        {!isReply && (
          <Button
            themeStyle={Button.THEME.NONE}
            fontSize={
              isMobile
                ? Button.FONT_SIZE.MOBILECONTENT
                : Button.FONT_SIZE.CLIENTCONTENTDETAIL
            }
            color={Button.COLOR.TEXTGRAY}
            margin={"0 15px 0 0"}
            onClick={toggleOpen}
          >
            {comment.item.reply} {replyCnt}
          </Button>
        )}
      </Box>
    </>
  );
};

export default CommentContent;
