import Box from "components/atoms/box";
import Input from "components/atoms/input";
import Text from "components/atoms/text";
import React from "react";
import { useFormContext } from "react-hook-form";

const RadioButton = (props) => {
  const {
    list,
    registerName,
    errorMessage,
    required,
    selectedRadioOption,
    setSelectedRadioOption,
    disabled,
    name = "radioGroup",
  } = props;

  const {
    register,
    formState: { errors },
  } = useFormContext();

  const hasError = errors[registerName];

  const handleRadioInput = (e) => {
    const { value } = e.target;
    setSelectedRadioOption(value);
  };

  return (
    <>
      {list.map(({ title, value, ariaLabel }, idx) => (
        <Box display={Box.DISPLAY.FLEX} al="cetner" gap="6px" as="label" key={idx}>
          <Input
            {...register(registerName, { required })}
            theme="RADIO"
            type="radio"
            className="peer"
            name={name}
            value={value}
            onChange={handleRadioInput}
            ariaLabel={ariaLabel}
            checked={selectedRadioOption === value}
            disabled={disabled}
            marign="0"
          />
          <Text as={Text.VARIANT.SPAN} size={Text.SIZE.ADMINCONTENT}>
            {title}
          </Text>
        </Box>
      ))}
      {hasError && (
        <Text
          as={Text.VARIANT.TEXT}
          color={Text.COLOR.RED}
          whiteSpace="pre-line"
          size={Text.SIZE.ADMINCONTENT}
          padding="0 0 0 8px"
          ariaLabel={hasError.message || errorMessage}
          lineHeight="1.2"
        >
          {hasError.message || errorMessage}
        </Text>
      )}
    </>
  );
};

export default RadioButton;
