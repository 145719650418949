import React from "react";
import {
  useHeadLineNews,
  useRankWeekNews,
  useRankMonthNews,
} from "hooks/api/news";
import Box from "components/atoms/box";
import NewsList from "components/organisms/newsList";
import List from "components/molecules/list";
import Banner from "components/molecules/banner";
import Image from "components/atoms/image";
import Link from "components/atoms/link";
import Text from "components/atoms/text";
import { useGetBanner } from "hooks/api/banner";
import convertImage from "utils/convertImage";

const SideNews = ({ padding = "0 40px" }) => {
  return (
    <Box
      padding={padding}
      width={"390px"}
      borderLeft={"1px solid"}
      borderColor={Box.COLOR.LINEGRAY}
    >
      <NewsList
        size={Text.SIZE.CLIENTNORMAL}
        bold={"주요 NEWS"}
        margin={"0 0 40px"}
        sideBar={() => <NewsList.Link link={"/news?category=all"} />}
      >
        <List api={useHeadLineNews}>
          {(list) =>
            list && (
              <NewsList.LAYOUT.SideImageNews
                list={list.slice(0, 3)}
                category={"news"}
              />
            )
          }
        </List>
      </NewsList>

      <Box margin={"30px 0 50px"}>
        <List api={useGetBanner} params={{ type: "sns" }}>
          {(list) =>
            list && (
              <Banner
                imageList={list}
                slidesPerView={1}
                bottom={"4px"}
                simulateTouch
                isAutoplay
              >
                {(imageList) => {
                  const isMaxBanner = imageList.length === 3;

                  return imageList.map(({ key, filePath, url }) => (
                    <Banner.Slide key={key}>
                      <Box
                        aspectRatio={isMaxBanner ? "277/88" : "277/118"}
                        borderRadius={Box.RADIUS.NORMAL}
                        overflow="hidden"
                      >
                        <Link
                          width={"100%"}
                          height={"100%"}
                          link={url}
                          target={"_blank"}
                        >
                          <Image
                            src={convertImage(filePath)}
                            alt="banner"
                            width="100%"
                            height="100%"
                            fit="cover"
                          />
                        </Link>
                      </Box>
                    </Banner.Slide>
                  ));
                }}
              </Banner>
            )
          }
        </List>
      </Box>

      <NewsList
        size={Text.SIZE.CLIENTNORMAL}
        bold="주간 Main News"
        margin={"0 0 25px"}
        sideBar={() => <NewsList.Link link={"/daily?category=week"} />}
      >
        <List api={useRankWeekNews} params={"news"}>
          {(list) =>
            list && (
              <NewsList.LAYOUT.SideLineNews
                list={list}
                category={"news"}
                ariaLabel={"주간 Main News"}
              />
            )
          }
        </List>
      </NewsList>

      <NewsList
        size={Text.SIZE.CLIENTNORMAL}
        bold="월간 Main News"
        margin={"50px 0 25px"}
        sideBar={() => <NewsList.Link link={"/daily?category=month"} />}
      >
        <List api={useRankMonthNews} params={"news"}>
          {(list) =>
            list && (
              <NewsList.LAYOUT.SideLineNews
                list={list}
                category={"news"}
                ariaLabel={"월간 Main News"}
              />
            )
          }
        </List>
      </NewsList>
    </Box>
  );
};

export default SideNews;
