import Box from "components/atoms/box";
import Button from "components/atoms/button";
import Text from "components/atoms/text";
import React, { Fragment } from "react";
import { useEffect } from "react";
import { useChangeOrderStore } from "store/useChangeStore";
import { site } from "text/admin/site";

const BannerBtnGroup = (props) => {
  const { setCurrCategory, currCategory, list, setNewList } = props;

  const { title, buttonList } = site.banner.buttonGroup;

  const { setIsChangeOrderList } = useChangeOrderStore();

  const handleBannerBtn = (category) => {
    setCurrCategory(category);
    setIsChangeOrderList(false);
  };

  useEffect(() => {
    setNewList(list);
  }, [list]);

  return (
    <Box display={Box.DISPLAY.FLEX} flexDirection="column" gap="15px" margin="0 0 40px 0">
      <Text as={Text.VARIANT.SUBTITLE}>{title}</Text>
      <Box display={Box.DISPLAY.FLEX} al="center" gap="20px">
        {buttonList.map(({ title, category }, idx) => {
          const isActive = currCategory === category;
          return (
            <Fragment key={idx}>
              <Button
                padding="12px 20px"
                themeStyle="adminCommon"
                bgColor={Button.COLOR.WHITE}
                isActive={isActive}
                onClick={() => handleBannerBtn(category)}
              >
                <Text
                  as={Text.VARIANT.TEXT}
                  weight={isActive ? Text.WEIGHT.BOLD : Text.WEIGHT.NORMAL}
                  size={Text.SIZE.ADMINCONTENTDETAIL}
                >
                  {title}
                </Text>
              </Button>
            </Fragment>
          );
        })}
      </Box>
    </Box>
  );
};

export default BannerBtnGroup;
