const shareSns = {
  shareToKakaoStory: ({ url, title }) => {
    window.Kakao.Story.share({
      url,
      text: title,
    });
  },

  shareToKakaoTalk: ({ url, title, desc }) => {
    window.Kakao.Link.sendDefault({
      objectType: "feed",
      content: {
        title,
        description: desc,
        imageUrl: "https://online.kofst.or.kr/assets/common/logo512.png", // 공유할 이미지의 URL
        link: {
          mobileWebUrl: url,
          webUrl: url,
        },
      },
    });
  },

  shareToTwitter: ({ url, title }) => {
    const tweetUrl = `https://twitter.com/intent/tweet?text=${title}&url=${url}`;

    window.open(tweetUrl, "Share to Twitter", "width=800,height=600");
  },

  shareToNaverBlog: ({ url, title }) => {
    const shareUrl = `https://share.naver.com/web/shareView.nhn?url=${encodeURIComponent(
      // "https://www.jls.or.kr/"
      // "https://www.kms.or.kr/"
      // "https://www.ksot.or.kr/"
      // "https://kofst.or.kr/intro.jsp"
      url
    )}&title=${encodeURIComponent(title)}&content=123123123`;

    window.open(shareUrl, "Share to Naver Blog", "width=800,height=600");
  },

  shareToFacebook: ({ url, title }) => {
    const sharedLink = encodeURIComponent(url);

    window.open(
      `http://www.facebook.com/sharer/sharer.php?u=${sharedLink}`,
      "_blank"
    );
  },

  copyLink: ({ url }) => {
    const textarea = document.createElement("textarea");
    textarea.value = url;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);

    alert("주소가 복사되었습니다.");
  },
};

export default shareSns;
