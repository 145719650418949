import { adminDefaultUrl } from "api/admin/url";
import AdminRequestList from "components/organisms/adminRequestList";
import SearchCondition from "components/organisms/searchCondition";
import { AdminContentsContainer } from "components/templates/admin/admin.style";
import useUrl from "hooks/utils/useUrl";
import React, { useState } from "react";
import { cs } from "text/admin/cs";

const RequestToManager = () => {
  const { statusOptions, keywordOptions, defaultValues } = cs;

  const { changeLink } = useUrl();

  const [selectedMenu, setSelectedMenu] = useState("title");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [filterData, setFilterData] = useState({});

  const onSubmit = (data) => {
    const { index: searchCondition, keyword, status } = data;
    setFilterData({
      status,
      searchCondition,
      keyword,
    });
    changeLink(`${adminDefaultUrl}/customer_service/request`);
  };

  return (
    <AdminContentsContainer>
      <SearchCondition onSubmit={onSubmit} defaultValues={defaultValues}>
        <SearchCondition.Select
          title="구분"
          selectedMenu={selectedStatus}
          setSelectedMenu={setSelectedStatus}
          options={statusOptions}
          width="104px"
          hasSearchInput={false}
          selectName="status"
        />
        <SearchCondition.Select
          title="검색 분류"
          registerName="keyword"
          selectedMenu={selectedMenu}
          setSelectedMenu={setSelectedMenu}
          options={keywordOptions}
          width="104px"
        />
      </SearchCondition>
      <AdminRequestList filterData={filterData} />
    </AdminContentsContainer>
  );
};

export default RequestToManager;
