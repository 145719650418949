import Box from "components/atoms/box";
import Button from "components/atoms/button";
import Text from "components/atoms/text";
import { useDeleteCategoryDetail, useUpdateCategoryOrder } from "hooks/api/admin/category";
import React, { useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { useNavigate } from "react-router-dom";
import ChangeOrderContainer from "../changeOrder";
import { useChangeOrderStore } from "store/useChangeStore";
import ChangeOrderButton from "../changeOrderButton";
import { useEffect } from "react";
import { common } from "text/admin/common";
import { category } from "text/admin/category";
import { adminDefaultUrl } from "api/admin/url";

const CategoryTable = (props) => {
  const { list, tableHeader, categoryName } = props;

  const {
    default: { remove },
    orderChange,
  } = common.alert;
  const { defaultCategoryList } = category.management;

  const [newList, setNewList] = useState(list);

  const { isChangeOrderList, setIsChangeOrderList } = useChangeOrderStore();

  useEffect(() => {
    if (list) setNewList(list);

    return () => setIsChangeOrderList(false);
  }, [list]);

  const navigate = useNavigate();

  const deleteMutate = useDeleteCategoryDetail();
  const updateMutate = useUpdateCategoryOrder();

  const handleUpdateCategory = (key, isDefault) => {
    navigate(
      `${adminDefaultUrl}/management_category/${categoryName}/detail?key=${key}&isDefault=${isDefault}`
    );
  };

  const handleDeleteCategory = (key) => {
    const confirm = window.confirm(remove);
    if (confirm) {
      deleteMutate({ key, categoryName });
    }
  };

  const handleSaveOrder = () => {
    const confirm = window.confirm(orderChange);
    if (confirm) {
      const newOrderList = newList.map(({ key }, idx) => ({
        key,
        sortSeq: idx + 1,
      }));
      const data = { list: newOrderList };
      updateMutate({ categoryName, data });
    }
  };

  const handleAppendSubCategory = () => {
    navigate(`${adminDefaultUrl}/management_category/${categoryName}/detail`);
  };

  return (
    <Box
      height={categoryName === "feature" ? "640px" : "auto"}
      overflow="auto"
      borderBottom={categoryName === "feature" ? "1px solid" : "none"}
      borderColor={Box.COLOR.LINEGRAY}
    >
      <Box
        display={Box.DISPLAY.FLEX}
        borderTop="1px solid"
        borderLeft="1px solid"
        borderRight="1px solid"
        borderColor={Box.COLOR.LINEGRAY}
      >
        {tableHeader.map(({ title, width }, idx) => (
          <Box
            width={width}
            padding="14px 0"
            key={idx}
            borderLeft={idx === 0 ? "" : "1px solid"}
            borderColor={Box.COLOR.LINEGRAY}
            bgColor={Box.COLOR.ADMINCONTENTSBG}
          >
            <Text
              as={Text.VARIANT.TEXT}
              size={Text.SIZE.ADMINCONTENT}
              weight={Text.WEIGHT.BOLD}
              textAlign="center"
            >
              {title}
            </Text>
          </Box>
        ))}
      </Box>
      {!isChangeOrderList &&
        list.map(({ key, categoryName, sortSeq, menuUse }, idx) => {
          const isDefaultCategory = defaultCategoryList.includes(categoryName);
          return (
            <Box
              key={key}
              borderTop={idx === 0 ? "1px solid" : ""}
              borderLeft="1px solid"
              borderRight="1px solid"
              borderBottom={
                categoryName === "feature" && list.length === idx + 1 ? "none" : "1px solid"
              }
              borderColor={Box.COLOR.LINEGRAY}
              display={Box.DISPLAY.FLEX}
              height="77px"
            >
              <Box width="6%" display={Box.DISPLAY.FLEX} jc="center" al="center">
                <Text as={Text.VARIANT.TEXT} textAlign="center">
                  {sortSeq}
                </Text>
              </Box>
              <Box
                width="51%"
                display={Box.DISPLAY.FLEX}
                jc="center"
                al="center"
                borderLeft="1px solid"
                borderColor={Box.COLOR.LINEGRAY}
              >
                <Text
                  as={Text.VARIANT.TEXT}
                  size={Text.SIZE.ADMINCONTENT}
                  textAlign="center"
                  ariaLabel={categoryName}
                >
                  {categoryName}
                </Text>
              </Box>
              <Box
                width="16%"
                display={Box.DISPLAY.FLEX}
                jc="center"
                al="center"
                borderLeft="1px solid"
                borderColor={Box.COLOR.LINEGRAY}
              >
                <Text
                  as={Text.VARIANT.TEXT}
                  size={Text.SIZE.ADMINCONTENT}
                  textAlign="center"
                  ariaLabel={menuUse === "Y" ? "카테고리 노출" : "카테고리 미노출"}
                >
                  {menuUse === "Y" ? "노출함" : "노출안함"}
                </Text>
              </Box>
              <Box
                width="27%"
                display={Box.DISPLAY.FLEX}
                jc="center"
                al="center"
                gap="12px"
                borderLeft="1px solid"
                borderColor={Box.COLOR.LINEGRAY}
              >
                <Button
                  padding="7px 24px"
                  themeStyle="adminCommon"
                  ariaLabel="수정"
                  onClick={() => handleUpdateCategory(key, isDefaultCategory)}
                >
                  수정
                </Button>
                {!isDefaultCategory && (
                  <Button
                    padding="7px 24px"
                    themeStyle="adminCommon"
                    ariaLabel="삭제"
                    onClick={() => handleDeleteCategory(key)}
                  >
                    삭제
                  </Button>
                )}
              </Box>
            </Box>
          );
        })}
      {isChangeOrderList && (
        <ChangeOrderContainer newList={newList} setNewList={setNewList}>
          {newList.map(({ key, categoryName, sortSeq, menuUse }, idx) => {
            const isDefaultCategory = defaultCategoryList.includes(categoryName);
            return (
              <Draggable draggableId={key} index={idx} key={key}>
                {(provided) => (
                  <Box
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    key={key}
                    borderTop={idx === 0 ? "1px solid" : ""}
                    borderLeft="1px solid"
                    borderRight="1px solid"
                    borderBottom={
                      categoryName === "feature" && list.length === idx + 1 ? "none" : "1px solid"
                    }
                    borderColor={Box.COLOR.LINEGRAY}
                    display={Box.DISPLAY.FLEX}
                    height="77px"
                  >
                    <Box width="6%" display={Box.DISPLAY.FLEX} jc="center" al="center">
                      <Text as={Text.VARIANT.TEXT} textAlign="center">
                        {sortSeq}
                      </Text>
                    </Box>
                    <Box
                      width="51%"
                      display={Box.DISPLAY.FLEX}
                      jc="center"
                      al="center"
                      borderLeft="1px solid"
                      borderColor={Box.COLOR.LINEGRAY}
                    >
                      <Text
                        as={Text.VARIANT.TEXT}
                        size={Text.SIZE.ADMINCONTENT}
                        textAlign="center"
                        ariaLabel={categoryName}
                      >
                        {categoryName}
                      </Text>
                    </Box>
                    <Box
                      width="16%"
                      display={Box.DISPLAY.FLEX}
                      jc="center"
                      al="center"
                      borderLeft="1px solid"
                      borderColor={Box.COLOR.LINEGRAY}
                    >
                      <Text
                        as={Text.VARIANT.TEXT}
                        size={Text.SIZE.ADMINCONTENT}
                        textAlign="center"
                        ariaLabel={menuUse === "Y" ? "카테고리 노출" : "카테고리 미노출"}
                      >
                        {menuUse === "Y" ? "노출함" : "노출안함"}
                      </Text>
                    </Box>
                    <Box
                      width="27%"
                      display={Box.DISPLAY.FLEX}
                      jc="center"
                      al="center"
                      gap="12px"
                      borderLeft="1px solid"
                      borderColor={Box.COLOR.LINEGRAY}
                    >
                      <Button
                        padding="7px 24px"
                        themeStyle="adminCommon"
                        ariaLabel="수정"
                        onClick={() => handleUpdateCategory(key, isDefaultCategory)}
                      >
                        수정
                      </Button>
                      {!isDefaultCategory && (
                        <Button
                          padding="7px 24px"
                          themeStyle="adminCommon"
                          ariaLabel="삭제"
                          onClick={() => handleDeleteCategory(key)}
                        >
                          삭제
                        </Button>
                      )}
                    </Box>
                  </Box>
                )}
              </Draggable>
            );
          })}
        </ChangeOrderContainer>
      )}
      {categoryName !== "calendar" && (
        <Box
          display={Box.DISPLAY.FLEX}
          al="center"
          jc={isChangeOrderList ? "flex-end" : "space-between"}
          bgColor={Box.COLOR.ADMINCONTENTSBG}
          padding="10px 0 0 0"
          position={categoryName === "feature" ? "absolute" : "relative"}
          top={categoryName === "feature" ? "820px" : ""}
          width="100%"
          maxWidth="1024px"
        >
          {!isChangeOrderList && (
            <Button
              padding="12px 40px"
              themeStyle="adminCommon"
              bgColor={Button.COLOR.WHITE}
              onClick={handleAppendSubCategory}
            >
              서브 카테고리 추가
            </Button>
          )}
          <ChangeOrderButton
            handleSaveOrder={handleSaveOrder}
            list={list}
            setNewList={setNewList}
          />
        </Box>
      )}
    </Box>
  );
};

export default CategoryTable;
