import DashboardContents from "components/organisms/dashboardContents";
import DashboardMemberCount from "components/organisms/dashboardMemberCount";
import { AdminContentsContainer } from "components/templates/admin/admin.style";
import React from "react";

const Dashboard = () => {
  return (
    <AdminContentsContainer>
      <DashboardMemberCount />
      <DashboardContents />
    </AdminContentsContainer>
  );
};

export default Dashboard;
