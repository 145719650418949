import Box from "components/atoms/box";
import RadioButton from "components/molecules/radioButton";
import React, { useEffect } from "react";
import { useState } from "react";
import AdminDatePicker from "../adminDatePicker";
import { useFormContext } from "react-hook-form";

const ExposureDate = (props) => {
  const { list, name } = props;

  const { setValue } = useFormContext();

  const buttonList = [
    { title: "노출 기간 설정", value: "Y", ariaLabel: "팝업 노출 기간 설정" },
    { title: "노출 기간 미설정", value: "N", ariaLabel: "팝업 노출 기간 미설정" },
  ];

  const [selectedRadioOption, setSelectedRadioOption] = useState("Y");

  useEffect(() => {
    setValue(name, selectedRadioOption);
  }, [selectedRadioOption]);

  return (
    <Box display={Box.DISPLAY.FLEX} jc="center" flexDirection="column" gap="15px" height="100%">
      <Box display={Box.DISPLAY.FLEX} al="center" gap="20px">
        <RadioButton
          list={buttonList}
          registerName={name}
          selectedRadioOption={selectedRadioOption}
          setSelectedRadioOption={setSelectedRadioOption}
          errorMessage="필수값 입니다."
          name={name}
          required
        />
      </Box>
      {selectedRadioOption === "Y" && <AdminDatePicker list={list} />}
    </Box>
  );
};

export default ExposureDate;
