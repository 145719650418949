import React from "react";
import Box from "components/atoms/box";
import Text from "components/atoms/text";

const AdminDetailForm = (props) => {
  const { formInfo } = props;

  return (
    <>
      {formInfo.map(({ title, height, components, required, isUpperPosition, minHeight }, idx) => (
        <Box
          display={Box.DISPLAY.FLEX}
          al="center"
          height={height}
          key={idx}
          borderRight="1px solid"
          borderLeft="1px solid"
          borderBottom="1px solid"
          borderColor={Box.COLOR.ADMINLINEGRAY}
          bgColor={Box.COLOR.ADMINCONTENTSBG}
        >
          <Box
            width="160px"
            height="100%"
            minHeight={minHeight}
            padding="12px 16px"
            display={Box.DISPLAY.FLEX}
            gap="5px"
            al={isUpperPosition ? "flex-start" : "center"}
          >
            <Text size={Text.SIZE.ADMINCONTENT} as={Text.VARIANT.SUBTITLE}>
              {title}
            </Text>
            {required && (
              <Text
                color={Text.COLOR.SECONDARY}
                size={Text.SIZE.ADMINCONTENT}
                as={Text.VARIANT.SPAN}
              >
                *
              </Text>
            )}
          </Box>
          <Box
            position="relative"
            width="100%"
            height="100%"
            minHeight={minHeight}
            padding="8px 12px"
            borderLeft="1px solid"
            borderColor={Box.COLOR.ADMINLINEGRAY}
            bgColor={Box.COLOR.WHITE}
          >
            {components}
          </Box>
        </Box>
      ))}
    </>
  );
};

export default AdminDetailForm;
