import { DELETE, GET, PUT } from "api/config";
import { cs } from "../url";

const { requestUrl, emailUrl } = cs;

// 관리자에게 목록
export const getRequestList = async ({
  page = "1",
  itemsPerPage = "10",
  status = "",
  searchCondition = "",
  searchValue = "",
}) =>
  await GET(
    `${requestUrl}?pageIndex=${page}&itemsPerPage=${itemsPerPage}&status=${status}&searchCondition=${searchCondition}&searchKeyword=${searchValue}`
  );

//관리자에게 상세 목록
export const getRequestDetailList = async ({ key }) => await GET(`${requestUrl}/${key}`);

// 관리자에게 수정
export const updateRequestInfo = async ({ key, data }) => {
  await PUT(`${requestUrl}/${key}`, data);
};

// 관리자에게 삭제
export const deleteRequest = async ({ data }) => {
  await DELETE(`${requestUrl}`, data);
};

// 관리자 이메일
export const getManagerEmail = async () => await GET(emailUrl);

// 관리자 이메일 수정
export const updateManagerEmail = async ({ data }) => {
  await PUT(emailUrl, data);
};
