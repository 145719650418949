import Box from "components/atoms/box";
import Button from "components/atoms/button";
import Image from "components/atoms/image";
import Text from "components/atoms/text";
import Tooltip from "components/molecules/tooltip";
import { AdminContentsContainer } from "components/templates/admin/admin.style";
import { useGetCommentOnOff, useUpdateCommentOnOff } from "hooks/api/admin/management_site";
import React, { Fragment, useEffect, useState } from "react";
import { common } from "text/admin/common";
import { site } from "text/admin/site";

const ManagementComment = () => {
  const { title, buttonList, tooltipText } = site.comment;
  const { comment } = common.alert;

  const { commentState } = useGetCommentOnOff();

  const [isCommentOn, setIsCommentOn] = useState(null);

  useEffect(() => {
    setIsCommentOn(commentState);
  }, [commentState]);

  const mutate = useUpdateCommentOnOff();

  const handleCommentOnOff = (e) => {
    setIsCommentOn(e.target.value);
  };

  const handleCommentStatus = () => {
    const data = {
      commentOnOff: isCommentOn,
    };
    const confirm = window.confirm(comment);
    if (confirm) mutate({ data });
  };

  return (
    <AdminContentsContainer>
      <Box display={Box.DISPLAY.FLEX} al="center" gap="5px" width="403px" margin="0 0 10px 0">
        <Text as={Text.VARIANT.SUBTITLE}>{title}</Text>
        <Tooltip text={tooltipText}>
          <Image src="/assets/admin/comment/question.png" width="18px" height="18px" />
        </Tooltip>
      </Box>
      <Box
        height="98px"
        maxWidth="1024px"
        display={Box.DISPLAY.FLEX}
        al="center"
        border="1px solid"
        borderColor={Box.COLOR.ADMINLINEGRAY}
      >
        <Box padding="0 0 0 24px" width="403px">
          <Text as={Text.VARIANT.TEXT} weight={Text.WEIGHT.BOLD} size={Text.SIZE.ADMINCONTENT}>
            {title}
          </Text>
        </Box>
        <Box
          flex="1 1 0"
          bgColor={Box.COLOR.WHITE}
          height="100%"
          display={Box.DISPLAY.FLEX}
          al="center"
          gap="10px"
          padding="0 0 0 37px"
        >
          {buttonList.map(({ title, ariaLabel, value }, idx) => (
            <Fragment key={idx}>
              <Button
                width="67px"
                height="29px"
                sort="center"
                themeStyle="adminCommon"
                value={value}
                onClick={handleCommentOnOff}
                fontSize={Button.FONT_SIZE.ADMINCONTENTDETAIL}
                bgColor={isCommentOn === value && "#3A47B833"}
                borderColor={isCommentOn === value && Button.COLOR.SECONDARY}
                fontWeight={isCommentOn === value ? Button.FONT_WEIGHT.BOLD : undefined}
                color={isCommentOn === value ? Button.COLOR.SECONDARY : undefined}
                ariaLabel={ariaLabel}
              >
                {title}
              </Button>
            </Fragment>
          ))}
        </Box>
      </Box>
      <Box display={Box.DISPLAY.FLEX} al="center" jc="flex-end" maxWidth="1024px">
        <Button
          themeStyle="adminSave"
          padding="12px 40px"
          margin="40px 0 0 0"
          onClick={handleCommentStatus}
        >
          저장
        </Button>
      </Box>
    </AdminContentsContainer>
  );
};

export default ManagementComment;
