import Box from "components/atoms/box";
import Button from "components/atoms/button";
import Image from "components/atoms/image";
import Link from "components/atoms/link";
import Text from "components/atoms/text";
import Checkbox from "components/molecules/checkboxForm";
import InputForm from "components/molecules/inputForm";
import { useCreateRequestNewsletterMutation } from "hooks/api/newsletter";
import useContextForm from "hooks/utils/useContextForm";
import usePattern from "hooks/utils/usePattern";
import React from "react";
import { useEffect } from "react";
import { useRef } from "react";
import useMobileStore from "store/useMobileStore";
import homeText from "text/home";

const defaultValues = {
  name: "",
  email: "",
  password: "",
  agreement: false,
};

const { newsletterForm } = homeText;

const NewsLetterForm = () => {
  const { methods, Provider } = useContextForm(defaultValues);
  const { isMobile } = useMobileStore();
  const pattern = usePattern();

  const imageRef = useRef(null);
  const formRef = useRef(null);

  const isActive =
    methods.watch("name").trim() !== "" &&
    methods.watch("email").trim() !== "" &&
    pattern.email.value.test(methods.watch("email")) &&
    methods.watch("agreement");

  const onSubmit = ({ name, email, password }) => {
    const emailArray = email.split("@");

    createRequestNewsletterMutation({
      email: emailArray[0],
      emailDomain: emailArray[1],
      name,
    });
  };

  const handleSuccess = () => {
    alert(newsletterForm.alert.success);
    methods.reset();
  };
  const handleFail = (message) => {
    if (message === "DUPLICATE VALUE EXISTS") {
      alert("이미 신청한 이메일입니다.");
    } else {
      alert(newsletterForm.alert.fail);
    }
  };

  const createRequestNewsletterMutation = useCreateRequestNewsletterMutation(
    handleSuccess,
    handleFail
  );

  return (
    <Box
      width={isMobile ? "100%" : "calc(50% - 5px)"}
      display={Box.DISPLAY.FLEX}
      jc={"center"}
      al={"center"}
    >
      <Box
        display={Box.DISPLAY.FLEX}
        flexDirection="column"
        jc={"space-between"}
        al={"center"}
        width={"100%"}
        maxWidth={isMobile ? "500px" : "100%"}
        height={isMobile ? "auto" : "507px"}
        margin={isMobile ? "10px 0 0" : ""}
        bgSize={"cover"}
        bgRepeat={"no-repeat"}
        borderRadius={Box.RADIUS.NORMAL}
        ariaLabel="뉴스레터 신청 영역"
        position="relative"
      >
        <Box
          ref={imageRef}
          width={"100%"}
          position="relative"
          zIndex={Box.LEVEL.BOTTOM}
          themeStyle={Box.THEME.NEWSLETTERFORM_BACKGROUND}
        >
          <Image
            width={"100%"}
            height={isMobile ? "330px" : "auto"}
            src={`assets${isMobile ? "/mobile" : ""}/home/newsletter.png`}
          />
        </Box>
        <Box
          ref={formRef}
          display={Box.DISPLAY.FLEX}
          al={"center"}
          jc={"center"}
          height={"100%"}
          position="absolute"
          top={"0"}
          bottom={"0"}
          left={"0"}
          right={"0"}
          themeStyle={Box.THEME.NEWSLETTERFORM_INPUT}
          zIndex={Box.LEVEL.TOP}
        >
          <Box
            display={Box.DISPLAY.FLEX}
            flexDirection="column"
            al={"center"}
            jc={"space-between"}
            width={"320px"}
            maxWidth={"100%"}
          >
            <Text
              color={Text.COLOR.WHITE}
              size={isMobile ? Text.SIZE.MOBILETITLE : Text.SIZE.CLIENTTITLE}
              as={Text.VARIANT.SUBTITLE}
              lineHeight={"30.26px"}
              ariaLabel="뉴스레터 신청하기"
            >
              {newsletterForm.title}
            </Text>
            <Text
              color={Text.COLOR.WHITE}
              size={
                isMobile
                  ? Text.SIZE.MOBILECONTENT
                  : Text.SIZE.CLIENTCONTENTDETAIL
              }
              as={Text.VARIANT.TEXT}
              margin={isMobile ? "0 0 24px" : "0 0 30px"}
              ariaLabel="한국 과총 웹진의 소식을 가장 빠르게 받아보세요."
            >
              {newsletterForm.description}
            </Text>

            <Provider>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <InputForm
                  type="text"
                  Label={() => (
                    <Text
                      as={Text.VARIANT.SPAN}
                      color={Text.COLOR.WHITE}
                      size={
                        isMobile ? Text.SIZE.MOBILECONTENT : Text.SIZE.CLIENTETC
                      }
                    >
                      {newsletterForm.form.name.title}
                    </Text>
                  )}
                  name="name"
                  isError={false}
                  size={
                    isMobile
                      ? Text.SIZE.MOBILECONTENT
                      : Text.SIZE.CLIENTCONTENTDETAIL
                  }
                  padding={isMobile ? "8px 10px" : "15px 12px"}
                  theme={"secondary"}
                  placeholder={newsletterForm.form.name.placeholder}
                  ariaLabel="이름 입력창"
                  required={true}
                />

                <InputForm
                  type="email"
                  Label={() => (
                    <Text
                      as={Text.VARIANT.SPAN}
                      color={Text.COLOR.WHITE}
                      size={
                        isMobile ? Text.SIZE.MOBILECONTENT : Text.SIZE.CLIENTETC
                      }
                      margin={"15px 0 0"}
                    >
                      {newsletterForm.form.email.title}
                    </Text>
                  )}
                  name="email"
                  isError={false}
                  size={
                    isMobile
                      ? Text.SIZE.MOBILECONTENT
                      : Text.SIZE.CLIENTCONTENTDETAIL
                  }
                  padding={isMobile ? "8px 10px" : "15px 12px"}
                  theme={"secondary"}
                  placeholder={newsletterForm.form.email.placeholder}
                  ariaLabel="이메일 주소 입력창"
                  required={true}
                />

                {/* <InputForm
                  Label={() => (
                    <Text
                      as={Text.VARIANT.SPAN}
                      color={Text.COLOR.WHITE}
                      size={
                        isMobile ? Text.SIZE.MOBILECONTENT : Text.SIZE.CLIENTETC
                      }
                      margin={"15px 0 0"}
                    >
                      {newsletterForm.form.password.title}
                    </Text>
                  )}
                  name="password"
                  isError={false}
                  size={
                    isMobile
                      ? Text.SIZE.MOBILECONTENT
                      : Text.SIZE.CLIENTCONTENTDETAIL
                  }
                  padding={isMobile ? "8px 10px" : "15px 12px"}
                  theme={"secondary"}
                  placeholder="영소문자, 숫자조합으로 6~14자리 입력하세요."
                  ariaLabel={newsletterForm.form.password.placeholder}
                  required={true}
                  type={"password"}
                /> */}

                <Box
                  as={"label"}
                  display={Box.DISPLAY.FLEX}
                  jc={"center"}
                  wrap={"wrap"}
                  margin={isMobile ? "20px 0" : "30px 0 121px"}
                >
                  <Checkbox
                    name="agreement"
                    margin={isMobile ? "20px 0" : "30px 0 "}
                    Label={() => (
                      <Text
                        as={Text.VARIANT.SPAN}
                        size={
                          isMobile
                            ? Text.SIZE.MOBILECONTENT
                            : Text.SIZE.CLIENTCONTENTDETAIL
                        }
                        color={Text.COLOR.WHITE}
                        margin={"0 0 0 7px"}
                      >
                        {newsletterForm.form.privacy.required}
                        <Link
                          link={"/service?category=privacy"}
                          target="_blank"
                        >
                          <Text
                            as={Text.VARIANT.SPAN}
                            weight={Text.WEIGHT.BOLD}
                            color={Text.COLOR.WHITE}
                            decoration={"underline"}
                            lineHeight={"18px"}
                          >
                            {newsletterForm.form.privacy.link}
                          </Text>
                        </Link>
                        {newsletterForm.form.privacy.description}
                      </Text>
                    )}
                    isError={false}
                    size={
                      isMobile
                        ? Text.SIZE.MOBILECONTENT
                        : Text.SIZE.CLIENTCONTENTDETAIL
                    }
                    required={true}
                    ariaLabel={"개인정보 수집 및 이용 동의"}
                  />
                </Box>

                <Button
                  width={"100%"}
                  themeStyle={Button.THEME.PRIMARY}
                  color={isActive ? Button.COLOR.SECONDARY : Button.COLOR.WHITE}
                  fontWeight={Button.FONT_WEIGHT.BOLD}
                  fontSize={
                    isMobile
                      ? Button.FONT_SIZE.MOBILETITLE
                      : Button.FONT_SIZE.CLIENTCONTENT
                  }
                  padding={isMobile ? "10px 0" : "15px 0"}
                  ariaLabel="뉴스레터 신청하기"
                  bgColor={
                    isActive ? Button.COLOR.YELLOW : Button.COLOR.LINEGRAY
                  }
                  border={"none"}
                  disabled={!isActive}
                  type="submit"
                >
                  {newsletterForm.form.submit}
                </Button>
              </form>
            </Provider>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default NewsLetterForm;
