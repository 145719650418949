import { GET } from "api/config";

export const getBroadcastMainNews = async () => await GET("/news/broadcast");
export const getKofstMainNews = async () => await GET("/news/kofst");
export const getWeekMainNews = async () => await GET("/news/week");
export const getHeadlineMainNews = async () => await GET("/news/headline");
export const getIctMainNews = async () => await GET("/news/ict");
export const getScienceMainNews = async () => await GET("/news/science");
export const getPeopleMainNews = async () => await GET("/news/people");
export const getFeatureMainNews = async () => await GET("/news/feature");
export const getBreakingMainNews = async () => await GET("/news/breaking");
export const getTodayMainNews = async () => await GET("/news/today");
export const getCoverageNews = async () => await GET("/news/coverage");

export const getRankWeekNews = async (type) => await GET(`/${type}/rank/week`);
export const getRankMonthNews = async (type) =>
  await GET(`/${type}/rank/month`);
