import Box from "components/atoms/box";
import Text from "components/atoms/text";
import useContextForm from "hooks/utils/useContextForm";
import React from "react";
import AdminTable from "../adminTable";
import { useLoginLogList } from "hooks/api/admin/manager";
import { useState } from "react";
import useUrl from "hooks/utils/useUrl";
import LoginLogTable from "components/molecules/loginLogTable";
import { management } from "text/admin/management";
import { useMemberLoginLog } from "hooks/api/admin/member";

const AdminLoginLogList = (props) => {
  const {
    filterData: { keyword, startDate, endDate, searchCondition, currOption },
  } = props;

  const { tableHeader, defaultValues } = management.loginLog.table;

  const {
    path,
    query: { page },
  } = useUrl();

  const [selectedMenu, setSelectedMenu] = useState("10");

  const { Provider } = useContextForm(defaultValues);

  const api = path[1] === "member" ? useMemberLoginLog : useLoginLogList;

  return (
    <Box maxWidth="1024px" display={Box.DISPLAY.FLEX} flexDirection="column" margin="40px 0 0 0">
      <Text as={Text.VARIANT.SUBTITLE} ariaLabel="로그인 현황 목록" margin="0 0 10px 0">
        로그인 현황 목록
      </Text>
      <Provider>
        <AdminTable
          type={["countBar"]}
          hasSelectMenu={true}
          //
          api={api}
          tableHeader={tableHeader}
          searchValue={keyword}
          searchCondition={searchCondition}
          page={page}
          //
          currOption={currOption}
          startDate={startDate}
          endDate={endDate}
          //
          title="로그인 현황 목록"
          //
          selectedMenu={selectedMenu}
          setSelectedMenu={setSelectedMenu}
          width="120px"
        >
          <LoginLogTable />
        </AdminTable>
      </Provider>
    </Box>
  );
};

export default AdminLoginLogList;
