import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
import weekOfYear from "dayjs/plugin/weekOfYear";

dayjs.extend(isoWeek);
dayjs.extend(weekOfYear);

// 날짜를 전달받고 해당 날짜의 주차를 반환하는 함수
export const changeWeek = (date) => {
  let startOfWeek = dayjs(date).startOf("isoWeek");

  let thursday = startOfWeek.add(3, "day");

  let firstDayOfMonth = thursday.startOf("month");

  let firstThursday =
    firstDayOfMonth.day() <= 4
      ? firstDayOfMonth.add(4 - firstDayOfMonth.day(), "day")
      : firstDayOfMonth.add(11 - firstDayOfMonth.day(), "day");

  let weekOfMonth = Math.ceil((thursday.diff(firstThursday, "day") + 1) / 7);

  const month = (thursday.month() + 1).toString().padStart(2, "0");
  const week = weekOfMonth.toString().padStart(2, "0");

  return `${thursday.year()}${month}${week}`;
};

// 주차를 전달받고 해당 주차의 월요일을 반환하는 함수
// ex ) 20230701 => 20230703
export const getMondayOfWeek = (yearMonthWeek) => {
  const year = parseInt(yearMonthWeek.slice(0, 4));
  const month = parseInt(yearMonthWeek.slice(4, 6)) - 1;
  const week = parseInt(yearMonthWeek.slice(6, 8));

  const date = dayjs().year(year).month(month).date(1);

  const firstThursday =
    date.day() <= 4
      ? date.add(4 - date.day(), "day")
      : date.add(11 - date.day(), "day");

  const targetThursday = firstThursday.add(week - 1, "week");
  const targetMonday = targetThursday.add(-3, "day");

  return targetMonday.format("YYYYMMDD");
};

// 날짜를 전달받고 해당 날짜를 기준으로 5개의 날짜를 반환하는 함수
// ex) today : 20230705 => input : 20230705 [20230705, 20230704, 20230703, 20230702, 20230701]
// ex) today : 20230705 => input : 20230702 [20230705, 20230704, 20230703, 20230702, 20230701]
// ex) today : 20230705 => input : 20230630 [20230630, 20230629, 20230628, 20230627, 20230626]
// ex) today : 20230705 => input : 20230706 [20230710, 20230709, 20230708, 20230707, 20230706]
export const getDates = (date, length = 5) => {
  const current = dayjs().startOf("day");
  const inputDate = dayjs(date, "YYYYMMDD");

  const diff = current.diff(inputDate, "day");

  let root;
  if (diff > 0) {
    root = current.subtract(Math.floor(diff / length) * length, "day");
  } else if (diff < 0) {
    root = current.add(Math.ceil(Math.abs(diff) / length) * length, "day");
  } else {
    root = current;
  }

  const arr = [];
  for (let i = 0; i < length; i++) {
    arr.push(root.subtract(i, "day").format("YYYYMMDD"));
  }

  return arr;
};

// 날짜를 전달받고 해당 날짜를 기준으로 5개의 월을 반환하는 함수
// ex) today : 20230705 => input : 20230705 [202307, 202306, 202305, 202304, 202303]
// ex) today : 20230705 => input : 20230630 [202307, 202306, 202305, 202304, 202303]
// ex) today : 20230705 => input : 20230230 [202302, 202301, 202212, 202211, 202210]
// ex) today : 20230705 => input : 20230806 [202312, 202311, 202310, 202309, 202308]
export const getMonths = (date, length = 5) => {
  const current = dayjs().startOf("month");
  const inputDate = dayjs(date, "YYYYMMDD").startOf("month");

  const diff = current.diff(inputDate, "month");

  let root;
  if (diff > 0) {
    root = current.subtract(Math.floor(diff / length) * length, "month");
  } else if (diff < 0) {
    root = current.add(Math.ceil(Math.abs(diff) / length) * length, "month");
  } else {
    root = current;
  }

  const arr = [];
  for (let i = 0; i < length; i++) {
    arr.push(root.subtract(i, "month").format("YYYYMM"));
  }

  return arr;
};

// 날짜를 전달받고 해당 날짜를 기준으로 5개의 주차를 반환하는 함수
// ex) today : 20230705 => input : 20230705 [20230701, 20230605, 20230604, 20230603, 20230602]
export const getWeeks = (date, length = 5) => {
  const current = dayjs(dayjs().format("YYYY-MM-DD")).startOf("isoWeek");
  const update = dayjs(date.format("YYYY-MM-DD")).startOf("isoWeek");

  const diff = current.diff(update, "week");
  const index = diff > 0 ? -1 : 1;
  const key = Math.abs(Math.floor(diff / length));

  const arr = [];

  const root = current.add(key * length * index, "week");

  for (let i = 0; i < length; i++) {
    arr.push(changeWeek(root.subtract(i, "week")));
  }

  return arr;
};

// 날짜를 전달받고 해당 날짜를 기준으로 5개의 주차를 반환하는 함수
// ex) today : 20230705 => input : 20230705 [20230701, 20230605, 20230604, 20230603, 20230602]
export const getYearWeeks = (date, length = 5) => {
  const current = dayjs(date.format("YYYY-MM-DD")).startOf("isoWeek");

  const arr = [];

  for (let i = 0; i < length; i++) {
    arr.push(changeWeek(current.subtract(i, "week")));
  }

  return arr;
};

export const updateDate = (dateStr, value, type) => {
  if (dateStr) {
    if (type === "week") {
      dateStr = getMondayOfWeek(dateStr);
    }

    if (type === "date") {
      type = "day";
    }

    const current = dayjs(dateStr).add(value, type);

    const date =
      type === "month"
        ? current.format("YYYYMM")
        : type === "week"
        ? changeWeek(current)
        : current.format("YYYYMMDD");

    return {
      calendar: current.format("YYYYMMDD"),
      date,
    };
  }
};

export const createPeriod = (beginHour, beginMin, endHour, endMin) => {
  const sum =
    Number(beginHour) + Number(beginMin) + Number(endHour) + Number(endMin);
  return !sum || sum === 0
    ? ""
    : `(${beginHour}:${beginMin} ~ ${endHour}:${endMin})`;
};
