import Box from "components/atoms/box";
import Text from "components/atoms/text";
import SelectMenu from "components/molecules/selectMenu";
import React from "react";
import { common } from "text/admin/common";

const TableCountBar = (props) => {
  const { api, params, hasSelectMenu, selectedMenu, setSelectedMenu, options, width } = props;

  const { countPerView } = common.table;

  const { totalCnt } = api(params);

  return (
    <Box
      display={Box.DISPLAY.FLEX}
      al="center"
      jc="space-between"
      padding="13px 10px"
      borderTop="1px solid"
      borderLeft="1px solid"
      borderRight="1px solid"
      borderColor={Box.COLOR.LINEGRAY}
      bgColor={Box.COLOR.WHITE}
    >
      <Text as={Text.VARIANT.TEXT} ariaLabel={`총 ${totalCnt} 건`}>
        총{" "}
        <Text as={Text.VARIANT.SPAN} weight={Text.WEIGHT.BOLD} color={Text.COLOR.ACCENTCOUNT}>
          {totalCnt}
        </Text>{" "}
        건
      </Text>
      {hasSelectMenu && (
        <SelectMenu
          selectedMenu={selectedMenu}
          setSelectedMenu={setSelectedMenu}
          options={countPerView}
          width={width}
        />
      )}
    </Box>
  );
};

export default TableCountBar;
