import { adminDefaultUrl } from "api/admin/url";
import AdminNewsBoardList from "components/organisms/adminNewsBoardList";
import SearchCondition from "components/organisms/searchCondition";
import { AdminContentsContainer } from "components/templates/admin/admin.style";
import { useCategoryList } from "hooks/api/admin/category";
import useUrl from "hooks/utils/useUrl";
import React, { useEffect, useState } from "react";
import { newsboard } from "text/admin/newsboard";

const ManagementNewsBoard = () => {
  const { depth1Options, keywordOptions, defaultValues } = newsboard;

  const { path, changeLink } = useUrl();
  const categoryName = path[2];

  const [selectedMenu, setSelectedMenu] = useState("title");
  const [depth1Menu, setDepth1Menu] = useState("news");
  const [depth2Menu, setDepth2Menu] = useState("");
  const [depth2Options, setDepth2Options] = useState([]);
  const [filterData, setFilterData] = useState([]);

  const { list } = useCategoryList({ categoryName: depth1Menu });

  useEffect(() => {
    if (list) {
      setDepth2Options(
        list?.map(({ categoryName, key }) => ({
          title: categoryName,
          value: key,
        }))
      );
    }
    return () => {
      setFilterData([]);
    };
  }, [list]);

  useEffect(() => {
    if (categoryName === "all") {
      setDepth1Menu("news");
    } else {
      setDepth1Menu(categoryName);
    }
  }, [categoryName]);

  useEffect(() => {
    setDepth2Menu("");
  }, [depth1Menu]);

  const onSubmit = (data) => {
    const { index: searchCondition, keyword, cate1 } = data;
    const code = {
      news: "COM045",
      feature: "COM046",
      people: "COM047",
      science: "COM048",
    };
    setFilterData({
      cate1: code[cate1] || "",
      cate2: depth2Menu || "",
      searchCondition: searchCondition ?? "title",
      keyword: keyword ?? "",
    });
    changeLink(`${adminDefaultUrl}/newsboard/${categoryName}`);
  };

  return (
    <AdminContentsContainer>
      <SearchCondition onSubmit={onSubmit} defaultValues={defaultValues}>
        <SearchCondition.TwoDepthSearchMenu
          title="카테고리 선택"
          depth1Menu={depth1Menu}
          setDepth1Menu={setDepth1Menu}
          depth2Menu={depth2Menu}
          setDepth2Menu={setDepth2Menu}
          depth1Options={depth1Options}
          depth2Options={depth2Options}
          width="150px"
        />
        <SearchCondition.Select
          title="키워드 검색"
          registerName="keyword"
          selectedMenu={selectedMenu}
          setSelectedMenu={setSelectedMenu}
          options={keywordOptions}
          width="104px"
        />
      </SearchCondition>
      <AdminNewsBoardList filterData={filterData} />
    </AdminContentsContainer>
  );
};

export default ManagementNewsBoard;
