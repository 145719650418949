import Text from "components/atoms/text";
import React from "react";
import { useFormContext } from "react-hook-form";
import CheckBox from "../checkbox";

const CheckboxForm = (props) => {
  const {
    name,
    checked,
    size,
    value,
    Label,
    onChange,
    required,
    errorMessage,
    ariaLabel,
    isError = true,
  } = props;

  const {
    register,
    formState: { errors },
  } = useFormContext();

  const hasError = isError && errors[name];

  return (
    <>
      <CheckBox
        name={name}
        size={size}
        checked={checked}
        value={value}
        onChange={onChange}
        ariaLabel={ariaLabel}
        {...register(name, { required })}
      />

      {Label && <Label />}
      {hasError && (
        <Text
          as={Text.VARIANT.TEXT}
          color={Text.COLOR.RED}
          whiteSpace="pre-line"
          size={Text.SIZE.CLIENTCONTENTDETAIL}
          padding="0 0 0 8px"
          ariaLabel={hasError.message || errorMessage}
          lineHeight="1.2"
        >
          {hasError.message || errorMessage}
        </Text>
      )}
    </>
  );
};

export default CheckboxForm;
