import { GET, POST, PUT } from "api/config";

export const getNewsletterList = async (page, limit) =>
  await GET(`/board/newsletter?pageIndex=${page}&itemsPerPage=${limit}`);

export const getNewsletterDetail = async (id) =>
  await GET(`/board/newsletter/${id}`);

export const getPrevNewsletter = async (page, limit, year) =>
  await GET(
    `/board/previous-newsletter?pageIndex=${page}&itemsPerPage=${limit}&year=${year}`
  );

export const getPrevNewsletterDetail = async (id) =>
  await GET(`/board/previous-newsletter/${id}`);

export const getNewsletterSideNews = async (key) =>
  await GET(`/board/newsletter/${key}/articles`);

export const getNewsletterBanner = async () =>
  await GET(`/board/newsletter/banner`);

export const createRequestNewsletter = async ({
  userName,
  email1,
  email2,
  password,
}) =>
  await POST("/board/newsletter/applier", {
    userName,
    email1,
    email2,
    password,
  });

export const rejectRequestNewsletter = async ({ email1, email2, password }) =>
  await PUT("/board/newsletter/rejection", {
    email1,
    email2,
    password,
  });

export const rejectRequestNewsletterEmail = async ({ key }) =>
  await PUT(`/board/newsletter/rejection/${key}`);
