import React from "react";
import { LinkComponent } from "./link.style";

const ACTIVE = {
  MENU: "menu",
};

const Link = (props) => {
  const {
    width,
    height,
    margin,
    padding,
    active,
    link,
    target,
    children,
    ariaLabel,
  } = props;

  return (
    <LinkComponent
      $width={width}
      $height={height}
      $margin={margin}
      $padding={padding}
      $active={active}
      to={link}
      target={target}
      aria-label={ariaLabel}
    >
      {children}
    </LinkComponent>
  );
};

Link.ACTIVE = ACTIVE;

export default Link;
