import {
  createComment,
  createLike,
  createReply,
  deleteComment,
  deleteLike,
  getComment,
  updateComment,
} from "api/comment";
import { useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";

export const useComment = ({ id, sort, page }) => {
  const { data, isLoading, isSuccess, isError } = useQuery(
    ["comment", id, sort, page],
    () => getComment(id, sort, page)
  );

  const list = data?.data.list;
  const totalPageIndex = data?.data.totalPageIndex;

  return {
    list,
    totalPageIndex,
    isLoading,
    isSuccess,
    isError,
  };
};

export const usePreFetchComment = ({ id, sort, page }) => {
  const queryClient = useQueryClient();

  useEffect(() => {
    const pageTerm = parseInt((page - 1) / 3);
    const start = pageTerm * 3 < 1 ? 1 : pageTerm * 3;
    const end = (pageTerm + 1) * 3 + 1;

    for (let i = start; i <= end; i++) {
      const page = String(i);

      queryClient.prefetchQuery(["comment", id, sort, page], () =>
        getComment(id, sort, page)
      );
    }
  }, [queryClient, id, sort, page]);
};

export const useCreateCommentMutation = (successCallback) => {
  const queryClient = useQueryClient();
  const createCommentMutation = useMutation(createComment, {
    onSuccess: () => {
      queryClient.invalidateQueries("comment");
      queryClient.invalidateQueries("board");
      queryClient.invalidateQueries("detail");
      successCallback();
    },
    onError: () => {},
  });

  const submit = ({ key, body }) => {
    createCommentMutation.mutate({ key, body });
  };

  return submit;
};

export const useCreateReplyMutation = () => {
  const queryClient = useQueryClient();

  const createReplyMutation = useMutation(createReply, {
    onSuccess: () => {
      queryClient.invalidateQueries("comment");
    },
    onError: () => {},
  });

  const submit = (key, seq, body) => {
    createReplyMutation.mutate({ key, seq, body });
  };

  return submit;
};

export const useUpdateCommentMutation = (successCallback, failCallback) => {
  const queryClient = useQueryClient();

  const updateCommentMutation = useMutation(updateComment, {
    onSuccess: () => {
      queryClient.invalidateQueries("comment");
      successCallback();
    },
    onError: () => {
      failCallback();
    },
  });

  const submit = (key, seq, body) => {
    updateCommentMutation.mutate({ key, seq, body });
  };

  return submit;
};

export const useDeleteCommentMutation = (failCallback) => {
  const queryClient = useQueryClient();

  const deleteCommentMutation = useMutation(deleteComment, {
    onSuccess: () => {
      queryClient.invalidateQueries("comment");
    },
    onError: () => {
      failCallback();
    },
  });

  const submit = (key, seq) => {
    deleteCommentMutation.mutate({ key, seq });
  };

  return submit;
};

export const useCreateLikeMutation = (successCallback, failCallback) => {
  const queryClient = useQueryClient();

  const createLikeMutation = useMutation(createLike, {
    onSuccess: () => {
      queryClient.invalidateQueries("comment");
      successCallback();
    },
    onError: () => {
      failCallback();
    },
  });

  const submit = (key, seq) => {
    createLikeMutation.mutate({ key, seq });
  };

  return submit;
};

export const useDeleteLikeMutation = (successCallback, failCallback) => {
  const queryClient = useQueryClient();

  const deleteLikeMutation = useMutation(deleteLike, {
    onSuccess: () => {
      queryClient.invalidateQueries("comment");
      successCallback();
    },
    onError: () => {
      failCallback();
    },
  });

  const submit = (key, seq) => {
    deleteLikeMutation.mutate({ key, seq });
  };

  return submit;
};
