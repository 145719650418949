import Box from "components/atoms/box";
import React from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const ChangeOrderContainer = (props) => {
  const { children, newList, setNewList, setIsChange } = props;

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const newItems = reorder(newList, result.source.index, result.destination.index);

    setNewList(newItems);
    if (setIsChange) setIsChange((p) => !p);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided) => (
          <Box {...provided.droppableProps} ref={provided.innerRef} width="100%">
            {children}
            {provided.placeholder}
          </Box>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default ChangeOrderContainer;
