import {
  changePassword,
  checkEmailCode,
  createMember,
  findAdminPw,
  findId,
  findPw,
  login,
  loginSns,
  logout,
  sendEmailCode,
  token,
} from "api/auth";
import { useMutation } from "react-query";

export const useTokenMutation = (successCallback) => {
  const tokenMutation = useMutation(token, {
    onSuccess: ({ list }) => {
      successCallback(list);
    },
    onError: () => {},
  });

  const submit = () => {
    tokenMutation.mutate();
  };

  return submit;
};

export const useLoginMutation = (successCallback, failCallback) => {
  const loginMutation = useMutation(login, {
    onSuccess: ({ list }) => {
      successCallback(list);
    },
    onError: () => {
      failCallback();
    },
  });

  const submit = ({ id, password }) => {
    loginMutation.mutate({ userId: id, password });
  };

  return submit;
};

export const useLoginSnsMutation = (successCallback, failCallback) => {
  const loginSnsMutation = useMutation(loginSns, {
    onSuccess: ({ list }) => {
      successCallback(list);
    },
    onError: () => {
      failCallback();
    },
  });

  const submit = ({ userType, userId, userName }) => {
    loginSnsMutation.mutate({
      userType,
      userId,
      userName,
    });
  };

  return submit;
};

export const useLogoutMutation = (successCallback, failCallback) => {
  const logoutMutation = useMutation(logout, {
    onSuccess: () => {
      successCallback();
    },
    onError: () => {
      failCallback();
    },
  });

  const submit = () => {
    logoutMutation.mutate();
  };

  return submit;
};

export const useFindIdMutation = (successCallback, failCallback) => {
  const findIdMutation = useMutation(findId, {
    onSuccess: ({ data }) => {
      successCallback(data.list);
    },
    onError: () => {
      failCallback();
    },
  });

  const submit = ({ name, phone1, phone2, phone3 }) => {
    findIdMutation.mutate({
      userName: name,
      hp1: phone1,
      hp2: phone2,
      hp3: phone3,
    });
  };

  return submit;
};

export const useFindPwMutation = (successCallback, failCallback) => {
  const findPwMutation = useMutation(findPw, {
    onSuccess: () => {
      successCallback();
    },
    onError: () => {
      failCallback();
    },
  });

  const submit = ({ name, email, phone1, phone2, phone3 }) => {
    const [email1, email2] = email.split("@");

    findPwMutation.mutate({
      userName: name,
      email1,
      email2,
      hp1: phone1,
      hp2: phone2,
      hp3: phone3,
    });
  };

  return submit;
};

export const useChangePasswordMutation = (successCallback, failCallback) => {
  const changePasswordMutation = useMutation(changePassword, {
    onSuccess: () => {
      successCallback();
    },
    onError: () => {
      failCallback();
    },
  });

  const submit = ({ key, password }) => {
    changePasswordMutation.mutate({ key, password });
  };

  return submit;
};

export const useSendEmailCodeMutation = (successCallback, failCallback) => {
  const sendEmailCodeMutation = useMutation(sendEmailCode, {
    onSuccess: () => {
      successCallback();
    },
    onError: (error) => {
      failCallback(error);
    },
  });

  const submit = ({ email1, email2 }) => {
    sendEmailCodeMutation.mutate({ email1, email2 });
  };

  return submit;
};

export const useCheckEmailCodeMutation = (successCallback, failCallback) => {
  const checkEmailCodeMutation = useMutation(checkEmailCode, {
    onSuccess: () => {
      successCallback();
    },
    onError: () => {
      failCallback();
    },
  });

  const submit = ({ email1, email2, code }) => {
    checkEmailCodeMutation.mutate({ email1, email2, code });
  };

  return submit;
};

export const useCreateMemberMutation = (successCallback, failCallback) => {
  const createMemberMutation = useMutation(createMember, {
    onSuccess: () => {
      successCallback();
    },
    onError: () => {
      failCallback();
    },
  });

  const submit = ({
    email,
    emailDomain,
    password,
    name,
    phone1,
    phone2,
    phone3,
    isAgreement,
  }) => {
    createMemberMutation.mutate({
      email1: email,
      email2: emailDomain,
      password,
      userName: name,
      hp1: phone1,
      hp2: phone2,
      hp3: phone3,
      userId: `${email}@${emailDomain}`,
      newsYN: isAgreement ? "Y" : "N",
    });
  };

  return submit;
};

export const useFindAdminPwMutation = (successCallback, failCallback) => {
  const findPwMutation = useMutation(findAdminPw, {
    onSuccess: (data) => {
      successCallback(data.message);
    },
    onError: () => {
      failCallback();
    },
  });

  const submit = ({ userId, email1, email2 }) => {
    findPwMutation.mutate({
      userId,
      email1,
      email2,
    });
  };

  return submit;
};
