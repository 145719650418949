import { DELETE, GET, POST, PUT } from "api/config";
import { category } from "../url";

const { defaultUrl } = category;

export const getCategoryList = async (categoryName) => await GET(`${defaultUrl}/${categoryName}`);

export const getCategoryDetail = async ({ categoryName, key }) =>
  await GET(`${defaultUrl}/${categoryName}/${key}`);

export const updateCategoryDetail = async ({ key, categoryName, data }) => {
  await PUT(`${defaultUrl}/${categoryName}/${key}`, data);
};

export const updateCategoryOrder = async ({ categoryName, data }) => {
  await PUT(`${defaultUrl}/${categoryName}`, data);
};

export const createCategoryDetail = async ({ categoryName, data }) => {
  await POST(`${defaultUrl}/${categoryName}`, data);
};

export const deleteCategoryDetail = async ({ key, categoryName }) => {
  await DELETE(`${defaultUrl}/${categoryName}/${key}`);
};
