import AdminLoginLogList from "components/organisms/adminLoginLogList";
import SearchCondition from "components/organisms/searchCondition";
import { AdminContentsContainer } from "components/templates/admin/admin.style";
import dayjs from "dayjs";
import useUrl from "hooks/utils/useUrl";
import React from "react";
import { useState } from "react";
import { member } from "text/admin/member";
import convertDateWithYear from "utils/convertDateWithYear";

const MembershipLoginLog = () => {
  const today = dayjs();

  const { keywordOptions, defaultValues } = member.loginLog;

  const { pathname, changeLink } = useUrl();

  const [selectedMenu, setSelectedMenu] = useState("userId");
  const [selectedStartDate, setSelectedStartDate] = useState(today.subtract(1, "month"));
  const [selectedEndDate, setSelectedEndDate] = useState(today);
  const [filterData, setFilterData] = useState([]);

  const onSubmit = (data) => {
    const startDate = convertDateWithYear(selectedStartDate);
    const endDate = convertDateWithYear(selectedEndDate);
    const { keyword } = data;
    setFilterData({ keyword, startDate, endDate, searchCondition: selectedMenu });
    changeLink(pathname);
  };

  return (
    <AdminContentsContainer>
      <SearchCondition onSubmit={onSubmit} defaultValues={defaultValues}>
        <SearchCondition.DatePicker
          title="접속 일시"
          selectedStartDate={selectedStartDate}
          setSelectedStartDate={setSelectedStartDate}
          selectedEndDate={selectedEndDate}
          setSelectedEndDate={setSelectedEndDate}
        />
        <SearchCondition.Select
          title="키워드 검색"
          registerName="keyword"
          selectedMenu={selectedMenu}
          setSelectedMenu={setSelectedMenu}
          options={keywordOptions}
          width="104px"
        />
      </SearchCondition>
      <AdminLoginLogList filterData={filterData} />
    </AdminContentsContainer>
  );
};

export default MembershipLoginLog;
