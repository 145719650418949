import Box from "components/atoms/box";
import Button from "components/atoms/button";
import Image from "components/atoms/image";
import Input from "components/atoms/input";
import Text from "components/atoms/text";
import { useDeleteAttachedFiles } from "hooks/api/admin/news_board";
import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useFormContext } from "react-hook-form";
import convertByteToMegabytes from "utils/convertByteToMegabytes";
import generateDownloadLink from "utils/generateDownloadLink";

const FileUploader = (props) => {
  const { registerName, defaultFileList, no } = props;

  const list = generateDownloadLink(defaultFileList);

  const { setValue } = useFormContext();

  const [files, setFiles] = useState([]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      setFiles((p) => {
        if (p.length === 5) {
          alert("첨부파일은 최대 5개 첨부 가능합니다.");
          return p;
        }
        const newFiles = [...p, ...acceptedFiles];
        return newFiles;
      });
    },
    onDropRejected: () => {
      alert("첨부파일은 한번에 최대 5개 첨부 가능합니다.");
    },
    maxFiles: 5,
  });

  useEffect(() => {
    if (defaultFileList) {
      setFiles(list);
    }
  }, [defaultFileList]);

  // 기존 파일은 서버 api 호출 후 삭제
  // 새로 첨부한 파일은 서버 통신 없이 리스트에서만 제거
  const mutate = useDeleteAttachedFiles();

  const handleDelete = (e, idx, fileSN) => {
    e.preventDefault();
    const confirm = window.confirm("첨부파일을 삭제하시겠습니까?");
    if (!confirm) return;
    if (fileSN) {
      mutate({ key: no, fileSN });
    } else {
      setFiles((p) => p.filter((_, index) => index !== idx));
    }
  };

  const handleDownload = (e, url, name) => {
    e.preventDefault();
    if (!url) return;
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", name);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const uploadFiles = files.map(({ name, size, url, fileSN }, idx) => (
    <Box
      display={Box.DISPLAY.FLEX}
      al="center"
      jc="space-between"
      gap="10px"
      width="514px"
      margin="7px 0 0 0"
      key={idx}
    >
      <Box
        key={name}
        display={Box.DISPLAY.FLEX}
        al="center"
        width="480px"
        height="41px"
        borderRight="1px solid"
        borderLeft="1px solid"
        borderTop="1px solid"
        borderBottom="1px solid"
        borderColor={Box.COLOR.ADMINLINEGRAY}
      >
        <Box
          width="120px"
          height="100%"
          padding="12px 16px"
          display={Box.DISPLAY.FLEX}
          al="center"
          jc="center"
          borderRight="1px solid"
          borderColor={Box.COLOR.ADMINLINEGRAY}
        >
          <Text as={Text.VARIANT.TEXT} size={Text.SIZE.ADMINCONTENT}>
            첨부파일 {idx + 1}
          </Text>
        </Box>
        <Box
          width="360px"
          height="100%"
          padding="8px"
          display={Box.DISPLAY.FLEX}
          al="center"
          jc="space-between"
          bgColor={Box.COLOR.ATTACHEDFILEBG}
        >
          <Button
            themeStyle="none"
            onClick={(e) => handleDownload(e, url, name)}
            width="fit-content"
          >
            <Text
              decoration="underline"
              as={Text.VARIANT.TEXT}
              size={Text.SIZE.ADMINCONTENTDETAIL}
              color={Text.COLOR.ADMINDARKGRAY}
              textAlign="left"
              lineClamp={1}
            >
              {name}
            </Text>
          </Button>
          <Box>
            <Text
              as={Text.VARIANT.TEXT}
              size={Text.SIZE.ADMINCONTENTDETAIL}
              color={Text.COLOR.ATTACHEDFILELIGHTGRAY}
              textAlign="center"
            >
              ({convertByteToMegabytes(size)} MB)
            </Text>
          </Box>
        </Box>
      </Box>
      <Button
        onClick={(e) => handleDelete(e, idx, fileSN)}
        bgColor="transparent"
        border="none"
        padding="0"
      >
        <Image src="/assets/admin/round_close.png" width="24px" height="24px" />
      </Button>
    </Box>
  ));

  useEffect(() => {
    if (files.length === 0) return;
    setValue(registerName, files, { shouldValidate: true });
  }, [files]);

  return (
    <>
      <Box minHeight="32px" width="fit-content" {...getRootProps({ className: "dropzone" })}>
        <Input {...getInputProps()} display="none" />
        <Button
          themeStyle="adminCommon"
          onClick={(e) => e.preventDefault()}
          padding="5px 34px"
          color={Button.COLOR.SECONDARY}
          borderColor={Button.COLOR.SECONDARY}
          height="100%"
        >
          첨부파일 추가
        </Button>
      </Box>
      <Box>{uploadFiles}</Box>
    </>
  );
};

export default FileUploader;
