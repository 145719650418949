import { Select } from "antd";
import React from "react";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { FiChevronDown } from "@react-icons/all-files/fi/FiChevronDown";

const { Option } = Select;

const SelectMenu = (props) => {
  const { selectedMenu, setSelectedMenu, options, width, registerName = "index" } = props;

  const handleSelectMenu = (value) => {
    setSelectedMenu(value);
  };

  const { setValue } = useFormContext();

  useEffect(() => {
    setValue(registerName, selectedMenu, { shouldValidate: true });
  }, [selectedMenu]);

  return (
    <Select
      suffixIcon={<FiChevronDown size={18} />}
      className="admin-search-select"
      style={{ width }}
      onChange={handleSelectMenu}
      value={selectedMenu}
    >
      {options.map(({ title, value }, idx) => (
        <Option value={value} key={idx}>
          {title}
        </Option>
      ))}
    </Select>
  );
};

export default SelectMenu;
