import React from "react";
import { ComposedChart, Line, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from "recharts";

const DashboardChart = (props) => {
  const { data } = props;

  return (
    <ComposedChart width={470} height={300} data={data}>
      <CartesianGrid stroke="#DFDFDF" />
      <XAxis
        dataKey="name"
        label={{ position: "insideBottomRight", offset: 0 }}
        scale="auto"
        fontSize={13}
        fontWeight={700}
      />
      <YAxis label={{ angle: -90, position: "insideLeft" }} />
      <Tooltip />
      <Bar dataKey="All" barSize={40} fill="#C4C8EA" />
      <Line
        type="monotone"
        dataKey="PC"
        stroke="#23295C"
        strokeWidth={2}
        dot={{ stroke: "#23295C", strokeWidth: 2 }}
        isAnimationActive={false}
      />
      <Line
        type="monotone"
        dataKey="Mobile"
        stroke="#DA4337"
        strokeWidth={2}
        dot={{ stroke: "#DA4337", strokeWidth: 2 }}
        isAnimationActive={false}
      />
    </ComposedChart>
  );
};

export default DashboardChart;
