const generateCheckListCount = (count, list) => {
  const checkList = {};

  for (let i = 0; i < count; i++) {
    checkList[list[i]] = false;
  }

  return checkList;
};

export default generateCheckListCount;
