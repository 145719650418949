import React from "react";
import Box from "components/atoms/box";
import Image from "components/atoms/image";
import Link from "components/atoms/link";

const SnsList = ({ margin }) => {
  return (
    <Box display={Box.DISPLAY.FLEX} al="center">
      <Link
        link={"https://blog.naver.com/kofst_news"}
        target={"_blank"}
        margin={margin}
        ariaLabel="네이버 블로그 링크"
      >
        <Image src={"/assets/header/blog.svg"} alt="naver blog" />
      </Link>

      <Link
        link={"https://www.facebook.com/profile.php?id=100048766631028"}
        target={"_blank"}
        margin={margin}
        ariaLabel="페이스북 링크"
      >
        <Image src={"/assets/header/facebook.svg"} alt="facebook" />
      </Link>

      <Link
        link={
          "https://www.youtube.com/channel/UC-NCnfxdAwke4hYdLEEuJfg/featured"
        }
        target={"_blank"}
        margin={margin}
        ariaLabel="유튜브 링크"
      >
        <Image src={"/assets/header/youtube.svg"} alt="youtube" />
      </Link>

      <Link
        link={"https://www.instagram.com/kofst_news/"}
        target={"_blank"}
        margin={margin}
        ariaLabel="인스타그램 링크"
      >
        <Image src={"/assets/header/insta.svg"} alt="instagram" />
      </Link>
    </Box>
  );
};

export default SnsList;
