import React from "react";
import Link from "components/atoms/link";
import Box from "components/atoms/box";
import Image from "components/atoms/image";
import MainSchedule from "../mainSchedule";
import Banner from "components/molecules/banner";
import convertImage from "utils/convertImage";

const MainBanner = ({ bannerList, coverageNews }) => {
  return (
    <Box
      display="flex"
      position="relative"
      width={"100%"}
      minWidth={"1260px"}
      ariaLabel="최상단 메인 배너"
    >
      <Box width={"80%"} ariaLabel="최상단 메인 배너">
        <Banner imageList={bannerList} bottom={"35px"} simulateTouch isNavigate>
          {(imageList) =>
            imageList.map(({ filePath, key, url }, index) => (
              <Banner.Slide key={key}>
                <Box aspectRatio={"1540/500"} overflow="hidden">
                  <Link
                    width={"100%"}
                    height={"100%"}
                    link={url ?? "/"}
                    target={url ? "_blank" : "_self"}
                  >
                    <Image
                      src={convertImage(filePath)}
                      alt="banner"
                      width="100%"
                      height="100%"
                      fit="cover"
                      ariaLabel={`${index + 1}번째 최상단 메인 배너
                      `}
                    />
                  </Link>
                </Box>
              </Banner.Slide>
            ))
          }
        </Banner>
      </Box>

      <MainSchedule coverageNews={coverageNews} />
    </Box>
  );
};

export default MainBanner;
