import Box from "components/atoms/box";
import Text from "components/atoms/text";
import React from "react";
import convertDayWithTime from "utils/convertDayWithTime";
import HasNotResult from "../HasNotResult";
import useAllCheckbox from "hooks/utils/useAllCheckbox";
import Input from "components/atoms/input";
import Link from "components/atoms/link";
import useUrl from "hooks/utils/useUrl";
import Button from "components/atoms/button";
import { useState } from "react";
import { useEffect } from "react";
import TableDeleteBar from "../tableDeleteBar";
import { useCopyNewsletterHtml, useDeleteNewsletter } from "hooks/api/admin/news_letter";
import { adminDefaultUrl, newsletter } from "api/admin/url";
import generateExcelFile from "utils/generateExcelFile";
import dayjs from "dayjs";

const NewsLetterTable = (props) => {
  const { list, tableHeader, searchCondition, keyword } = props;

  const {
    changeLink,
    query: { page = 1 },
  } = useUrl();

  const currPage = parseInt(page);

  let keyArr = [];
  const [allKeyList, setAllKeyList] = useState([]);
  const [currKey, setCurrKey] = useState(null);

  useEffect(() => {
    setAllKeyList(keyArr);
  }, [list]);

  const { newsletterUrl } = newsletter;

  const {
    checkAll,
    checkboxes,
    resetCheckbox,
    selectedKeys,
    handleCheckAllChange,
    handleCheckboxChange,
  } = useAllCheckbox(list.length, allKeyList);

  const isNoneSelected = selectedKeys.length === 0;

  const { html } = useCopyNewsletterHtml({ key: currKey });

  useEffect(() => {
    if (html) {
      navigator.clipboard.writeText(html);
      alert("HTML 코드가 복사되었습니다.");
      setCurrKey(null);
    }
  }, [html]);
  const mutate = useDeleteNewsletter(resetCheckbox);

  const handleDeleteSelectedNewsletter = () => {
    const data = { keyList: selectedKeys };
    if (isNoneSelected) {
      alert("삭제할 목록을 하나 이상 선택해주세요.");
      return;
    }

    const confirm = window.confirm("삭제 하시겠습니까?");
    if (confirm) mutate({ data });
  };

  const handleCreateNewsletter = () => {
    changeLink(`${adminDefaultUrl}/newsletter/management/detail`);
  };

  const handleExcelDownload = () => {
    const fileName = "뉴스레터 목록";
    const condition = { searchCondition, keyword };
    const type = "newsletter";
    generateExcelFile(newsletterUrl, fileName, condition, type);
  };

  const handlePreview = (e, key) => {
    e.preventDefault();
    window.open(`/admin/newsletter/management/preview?key=${key}`, "_blank");
  };

  const handleCopyHtml = (e, key) => {
    e.preventDefault();
    setCurrKey(key);
  };

  return (
    <>
      <TableDeleteBar
        title="뉴스레터 등록"
        handleDelete={handleDeleteSelectedNewsletter}
        handleSave={handleCreateNewsletter}
        handleExcelDownload={handleExcelDownload}
      />
      <Box
        display={Box.DISPLAY.FLEX}
        height="47px"
        borderTop="1px solid"
        borderColor={Box.COLOR.LINEGRAY}
        bgColor={Box.COLOR.ADMINCONTENTSBG}
      >
        <Box
          width="4%"
          height="100%"
          display={Box.DISPLAY.FLEX}
          al="center"
          jc="center"
          borderLeft="1px solid"
          borderBottom={list.length === 0 ? "1px solid" : ""}
          borderColor={Box.COLOR.ADMINLINEGRAY}
        >
          <Input
            type="checkbox"
            width="16px"
            height="16px"
            borderColor={Input.COLOR.ADMINLINEGRAY}
            onChange={handleCheckAllChange}
            checked={checkAll}
          />
        </Box>
        {tableHeader.map(({ title, width, ariaLabel }, idx) => (
          <Box
            display={Box.DISPLAY.FLEX}
            al="center"
            jc="center"
            width={width}
            padding={"8px 0"}
            key={idx}
            borderBottom={list.length === 0 ? "1px solid" : ""}
            borderRight={idx === tableHeader.length - 1 ? "1px solid" : ""}
            borderLeft="1px solid"
            borderColor={Box.COLOR.LINEGRAY}
          >
            <Text
              as={Text.VARIANT.TEXT}
              weight={Text.WEIGHT.BOLD}
              size={Text.SIZE.CLIENTCONTENTDETAIL}
              textAlign="center"
              ariaLabel={ariaLabel}
            >
              {title}
            </Text>
          </Box>
        ))}
      </Box>
      {list.length === 0 && <HasNotResult />}
      {list.length !== 0 &&
        list.map(({ cnt, key, regDate, title, writer, period }, idx) => {
          const pageIdx = currPage > 1 ? (currPage - 1) * 10 + (idx + 1) : idx + 1;
          const formattedPeriod = dayjs(period).format("YYYY-MM-DD");
          keyArr.push(key);

          return (
            <Box
              key={idx}
              borderTop={idx === 0 ? "1px solid" : ""}
              borderBottom="1px solid"
              borderColor={Box.COLOR.LINEGRAY}
              display={Box.DISPLAY.FLEX}
              height="53px"
            >
              <Box
                width="4%"
                height="100%"
                display={Box.DISPLAY.FLEX}
                jc="center"
                borderLeft="1px solid"
                borderColor={Box.COLOR.ADMINLINEGRAY}
                al="center"
              >
                <Input
                  type="checkbox"
                  name={key}
                  width="16px"
                  height="16px"
                  borderColor={Input.COLOR.ADMINLINEGRAY}
                  checked={checkboxes[key] ?? false}
                  onChange={(e) => handleCheckboxChange({ e, key })}
                />
              </Box>
              <Box
                width="7%"
                display={Box.DISPLAY.FLEX}
                jc="center"
                al="center"
                borderLeft="1px solid"
                borderColor={Box.COLOR.LINEGRAY}
              >
                <Text
                  as={Text.VARIANT.TEXT}
                  size={Text.SIZE.CLIENTCONTENTDETAIL}
                  textAlign="center"
                  ariaLabel={`게시물 번호 : ${pageIdx} `}
                >
                  {pageIdx}
                </Text>
              </Box>
              <Box
                width="11%"
                display={Box.DISPLAY.FLEX}
                jc="center"
                al="center"
                borderLeft="1px solid"
                borderColor={Box.COLOR.LINEGRAY}
                padding="0 10px"
              >
                <Text
                  as={Text.VARIANT.TEXT}
                  size={Text.SIZE.CLIENTCONTENTDETAIL}
                  textAlign="center"
                  ariaLabel={`게시 일자: ${formattedPeriod}`}
                >
                  {formattedPeriod}
                </Text>
              </Box>
              <Box
                width="31%"
                display={Box.DISPLAY.FLEX}
                al="center"
                borderLeft="1px solid"
                borderColor={Box.COLOR.LINEGRAY}
                padding="0 14px"
              >
                <Link link={`${adminDefaultUrl}/newsletter/management/detail?key=${key}`}>
                  <Text
                    as={Text.VARIANT.TEXT}
                    size={Text.SIZE.CLIENTCONTENTDETAIL}
                    textAlign="left"
                    decoration="underline"
                    ariaLabel={title}
                  >
                    {title}
                  </Text>
                </Link>
              </Box>
              <Box
                width="9%"
                display={Box.DISPLAY.FLEX}
                jc="center"
                al="center"
                gap="5px"
                borderLeft="1px solid"
                borderColor={Box.COLOR.LINEGRAY}
                padding="0 10px"
              >
                <Button
                  themeStyle="adminCommon"
                  padding="7px 13px"
                  onClick={(e) => handlePreview(e, key)}
                >
                  <Text
                    as={Text.VARIANT.TEXT}
                    size={Text.SIZE.ADMINCONTENTDETAIL}
                    textAlign="center"
                    ariaLabel={title}
                  >
                    미리보기
                  </Text>
                </Button>
              </Box>
              <Box
                width="9%"
                display={Box.DISPLAY.FLEX}
                jc="center"
                al="center"
                borderLeft="1px solid"
                borderColor={Box.COLOR.LINEGRAY}
                padding="0 10px"
              >
                <Text
                  as={Text.VARIANT.TEXT}
                  size={Text.SIZE.CLIENTCONTENTDETAIL}
                  textAlign="center"
                  ariaLabel={writer}
                >
                  {writer}
                </Text>
              </Box>
              <Box
                width="8%"
                display={Box.DISPLAY.FLEX}
                jc="center"
                al="center"
                borderLeft="1px solid"
                borderColor={Box.COLOR.LINEGRAY}
              >
                <Text
                  as={Text.VARIANT.TEXT}
                  size={Text.SIZE.CLIENTCONTENTDETAIL}
                  textAlign="center"
                  ariaLabel={cnt}
                >
                  {cnt}
                </Text>
              </Box>
              <Box
                width="14%"
                display={Box.DISPLAY.FLEX}
                jc="center"
                al="center"
                borderLeft="1px solid"
                borderColor={Box.COLOR.LINEGRAY}
              >
                <Text
                  as={Text.VARIANT.TEXT}
                  size={Text.SIZE.CLIENTCONTENTDETAIL}
                  textAlign="center"
                  ariaLabel={regDate}
                >
                  {convertDayWithTime(regDate)}
                </Text>
              </Box>
              <Box
                width="7%"
                display={Box.DISPLAY.FLEX}
                jc="center"
                al="center"
                gap="5px"
                borderLeft="1px solid"
                borderRight="1px solid"
                borderColor={Box.COLOR.LINEGRAY}
                padding="0 10px"
              >
                <Button
                  themeStyle="adminCommon"
                  padding="7px 13px"
                  onClick={(e) => handleCopyHtml(e, key)}
                >
                  <Text
                    as={Text.VARIANT.TEXT}
                    size={Text.SIZE.ADMINCONTENTDETAIL}
                    textAlign="center"
                    ariaLabel={title}
                  >
                    복사
                  </Text>
                </Button>
              </Box>
            </Box>
          );
        })}
    </>
  );
};

export default NewsLetterTable;
