const { POST } = require("api/config");

export const createRequest = async ({
  title,
  contents,
  email1,
  email2,
  writer,
}) =>
  await POST("/cs/request", {
    title,
    contents,
    email1,
    email2,
    writer,
  });
