import {
  createRequestNewsletter,
  getNewsletterBanner,
  getNewsletterDetail,
  getNewsletterList,
  getNewsletterSideNews,
  getPrevNewsletter,
  getPrevNewsletterDetail,
  rejectRequestNewsletter,
  rejectRequestNewsletterEmail,
} from "api/newsletter";
import { useMutation, useQuery } from "react-query";

export const useNewsletterList = ({ page, limit }) => {
  const { data, isLoading, isSuccess, isError } = useQuery(
    ["newsletter", page, limit],
    () => getNewsletterList(page, limit)
  );

  const list = data?.data.list;
  const totalCnt = data?.data.totalCnt;
  const totalPageIndex = data?.data.totalPageIndex;

  return {
    list,
    totalCnt,
    totalPageIndex,
    isLoading,
    isSuccess,
    isError,
  };
};

export const useNewsletterDetail = (id) => {
  const { data, isLoading, isSuccess, isError } = useQuery({
    queryKey: ["newsletter", id],
    queryFn: () => getNewsletterDetail(id),
    enabled: !!id,
  });

  const detail = data?.data.resultDto;

  return {
    detail,
    isLoading,
    isSuccess,
    isError,
  };
};

export const usePrevNewsletterList = ({ page, limit, year }) => {
  const { data, isLoading, isSuccess, isError } = useQuery(
    ["prevNewsletter", page, limit, year],
    () => getPrevNewsletter(page, limit, year)
  );

  const list = data?.data.list;
  const totalCnt = data?.data.totalCnt;
  const totalPageIndex = data?.data.totalPageIndex;

  return {
    list,
    totalCnt,
    totalPageIndex,
    isLoading,
    isSuccess,
    isError,
  };
};

export const usePrevNewsletterDetail = (id) => {
  const { data, isLoading, isSuccess, isError } = useQuery(
    ["prevNewsletter", id],
    () => getPrevNewsletterDetail(id)
  );

  const detail = data?.data.resultDto;

  return {
    detail,
    isLoading,
    isSuccess,
    isError,
  };
};

export const useNewsletterSideNews = (key) => {
  const { data, isLoading, isSuccess, isError } = useQuery(
    ["newsletterSide", key],
    () => getNewsletterSideNews(key)
  );

  const list = data?.data;

  return {
    list,
    isLoading,
    isSuccess,
    isError,
  };
};

export const useNewsletterBanner = () => {
  const { data, isLoading, isSuccess, isError } = useQuery(
    ["newsletterBanner"],
    () => getNewsletterBanner()
  );

  const banner = data?.data.list;

  return {
    banner,
    isLoading,
    isSuccess,
    isError,
  };
};

export const useCreateRequestNewsletterMutation = (
  successCallback,
  failCallback
) => {
  const createRequestNewsletterMutation = useMutation(createRequestNewsletter, {
    onSuccess: () => {
      successCallback();
    },
    onError: ({
      response: {
        data: { message },
      },
    }) => {
      failCallback(message);
    },
  });

  const submit = ({ name, email, emailDomain }) => {
    createRequestNewsletterMutation.mutate({
      userName: name,
      email1: email,
      email2: emailDomain,
    });
  };

  return submit;
};

export const useRejectRequestNewsletterMutation = (
  successCallback,
  failCallback
) => {
  const rejectRequestNewsletterMutation = useMutation(rejectRequestNewsletter, {
    onSuccess: () => {
      successCallback();
    },
    onError: () => {
      failCallback();
    },
  });

  const submit = ({ email, emailDomain, password }) => {
    rejectRequestNewsletterMutation.mutate({
      email1: email,
      email2: emailDomain,
      password,
    });
  };

  return submit;
};

export const useRejectRequestNewsletterEmailMutation = (
  successCallback,
  failCallback
) => {
  const rejectRequestNewsletterEmailMutation = useMutation(
    rejectRequestNewsletterEmail,
    {
      onSuccess: () => {
        successCallback();
      },
      onError: () => {
        failCallback();
      },
    }
  );

  const submit = ({ key }) => {
    rejectRequestNewsletterEmailMutation.mutate({
      key,
    });
  };

  return submit;
};
