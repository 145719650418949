import Box from "components/atoms/box";
import useUrl from "hooks/utils/useUrl";
import React, { useEffect, useState } from "react";
import NewsList from "../newsList";
import List from "components/molecules/list";
import SideNews from "../sideNews";
import {
  useBoardCalendar,
  useBoardCalendarMonth,
  useBoardNews,
  usePreFetchBoardNews,
} from "hooks/api/board";
import Blank from "components/molecules/blank";
import dayjs from "dayjs";
import CalendarHeader from "../calendarHeader";
import Text from "components/atoms/text";
import CalendarList from "../calendarList";
import commonText from "text/common";

const { allPage, eventList, dateFormat } = commonText;

const handleCheckActive = (list, date) => {
  let isActive = false;

  list.forEach(({ eventBeginDate, preBeginDate }) => {
    const eventDay = dayjs(eventBeginDate).format("MMDD");
    const preDay = dayjs(preBeginDate).format("MMDD");

    if (date === eventDay || (preBeginDate && date === preDay)) {
      isActive = true;

      return;
    }
  });

  return isActive;
};

const NewsCalendar = ({ isCoverage }) => {
  const {
    query: {
      category = "COM065_meJ9Dpf",
      date = dayjs().format("YYYYMMDD"),
      page = "1",
      searchKeyword = "",
    },
  } = useUrl();

  const [length, setLength] = useState(0);
  const [typeForm, setTypeForm] = useState([]);
  const [basicForm, setBasicForm] = useState(null);

  return (
    <>
      <CalendarHeader
        isSearch
        margin={"30px 0 0 "}
        format={dateFormat}
        picker={"date"}
        defaultValue={dayjs().format("YYYYMMDD")}
        length={length}
        type={isCoverage ? "news" : "calendar"}
        category={category}
        handleCheckActive={handleCheckActive}
        handleChangeDate={(changeQuery) => (date) => {
          changeQuery({
            date: date.format("YYYYMMDD"),
            calendar: date.format("YYYYMMDD"),
          });
        }}
        preFetch={useBoardCalendarMonth}
        head={() => (
          <Box
            display={Box.DISPLAY.FLEX}
            jc={"space-between"}
            al={"center"}
            padding={"20px 0"}
            margin={"40px 0 0"}
            borderTop={"1px solid"}
            borderBottom={"1px solid"}
            borderColor={Box.COLOR.LINEGRAY}
          >
            <Text
              as={Text.VARIANT.TEXT}
              size={Text.SIZE.CLIENTNORMAL}
              weight={Text.WEIGHT.BOLD}
              color={Text.COLOR.PRIMARY}
            >
              {eventList}
            </Text>

            <NewsList.Type typeForm={typeForm} basicForm={basicForm} />
          </Box>
        )}
      />

      <List
        api={useBoardCalendar}
        params={{
          type: isCoverage ? "news" : "calendar",
          category,
          date,
          page,
          searchKeyword,
        }}
      >
        {(list, { listviewList, listviewBasic }) =>
          list && (
            <CalendarList
              list={list}
              setTypeForm={setTypeForm}
              setLength={setLength}
              setBasicForm={setBasicForm}
              listviewList={listviewList}
              listviewBasic={listviewBasic}
              isCoverage={isCoverage}
            />
          )
        }
      </List>
    </>
  );
};

const newsListType = {
  title: NewsList.LAYOUT.TextNews,
  summary: NewsList.LAYOUT.LineSmallImageNews,
  photo: NewsList.LAYOUT.ThumbnailNews,
};

const NewsBoard = (props) => {
  const { boardType, isAllPage, category } = props;
  const [typeForm, setTypeForm] = useState(null);
  const [basicForm, setBasicForm] = useState("title");
  const isTypeForm = (typeForm && typeForm.length > 1) || isAllPage;

  const {
    query: { type, page = "1" },
  } = useUrl();

  const newsType = type ? type : basicForm;
  const NewsForm = newsListType[newsType] ?? newsListType.title;

  const NewsBoardList = ({ list, listviewList, listviewBasic, isAllPage }) => {
    useEffect(() => {
      setTypeForm(listviewList);
      if (listviewBasic) setBasicForm(listviewBasic);
    }, [listviewList, isAllPage, listviewBasic]);

    return (
      <NewsForm
        list={list}
        category={boardType}
        boxPadding={"30px 0"}
        width={isAllPage ? 4 : 2}
        isBorder
        isBlank
        isSort
      />
    );
  };

  return (
    <NewsList
      title={isAllPage ? allPage : ""}
      sideBar={
        isTypeForm &&
        (() => (
          <NewsList.Type
            isAllPage={isAllPage}
            typeForm={typeForm}
            basicForm={basicForm}
          />
        ))
      }
      margin="0"
      isBorder={isTypeForm}
    >
      <List
        api={useBoardNews}
        preFetch={usePreFetchBoardNews}
        params={{ type: boardType, category, page }}
        isBorderTop={!isAllPage}
      >
        {(list, { listviewList, listviewBasic }) =>
          list && (
            <>
              {list.length === 0 && <Blank />}
              {list.length !== 0 && (
                <NewsBoardList
                  list={list}
                  isAllPage={isAllPage}
                  listviewList={listviewList}
                  listviewBasic={listviewBasic}
                />
              )}
            </>
          )
        }
      </List>
    </NewsList>
  );
};

const Board = (props) => {
  const { isAllPage, isTransparent, isCalendar, children } = props;

  const childrenWithProps = React.Children.map(children, (child) =>
    React.cloneElement(child, { isAllPage })
  );

  return (
    <Box
      display={Box.DISPLAY.FLEX}
      width={"1260px"}
      padding={`32px ${isAllPage ? "40px" : "0"} 32px ${
        isCalendar ? "0" : "40px"
      }`}
      margin={"80px 0 40px"}
      bgColor={isTransparent ? Box.COLOR.WHITE : Box.COLOR.LIGHTGRAY}
      borderRadius="10px"
      id={"board"}
    >
      <Box
        width={isAllPage ? "100%" : "790px"}
        margin={isAllPage || isCalendar ? "0" : "0 40px 0 0"}
      >
        {childrenWithProps}
      </Box>
      {!isAllPage && <SideNews />}
    </Box>
  );
};

Board.News = NewsBoard;
Board.Calendar = NewsCalendar;

export default Board;
