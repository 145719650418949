import Box from "components/atoms/box";
import Button from "components/atoms/button";
import React from "react";
import NewsList from "../newsList";
import Text from "components/atoms/text";
import List from "components/molecules/list";
import {
  usePreFetchRankNews,
  useRankMonthNews,
  useRankWeekNews,
} from "hooks/api/news";
import useCategory from "hooks/utils/useCategory";

const MobileNewsCategory = ({ type = "news" }) => {
  const [rankNews, setRankNews, rankNewsListApi] = useCategory([
    useRankWeekNews,
    useRankMonthNews,
  ]);

  const category = rankNews === 0 ? "week" : "month";

  usePreFetchRankNews(type);

  return (
    <Box
      width={"100%"}
      bgColor={Box.COLOR.LIGHTGRAY}
      borderRadius={"10px"}
      padding={"20px 24px"}
    >
      <Box
        width={"100%"}
        borderBottom={"1px solid"}
        padding={"0 0 1px"}
        margin={"0 0 30px"}
        borderColor={Box.COLOR.LINEGRAY}
      >
        <Button
          width={"50%"}
          padding={"0 0 13px"}
          fontSize={Button.FONT_SIZE.MOBILECATEGORY}
          fontWeight={
            rankNews === 0 ? Button.FONT_WEIGHT.BOLD : Button.FONT_WEIGHT.NORMAL
          }
          borderBottom={rankNews === 0 ? "2px solid" : ""}
          borderColor={Button.COLOR.SECONDARY}
          color={rankNews === 0 ? Button.COLOR.SECONDARY : Button.COLOR.BLACK}
          themeStyle={Button.THEME.NONE}
          onClick={() => setRankNews(0)}
        >
          주간 Main News
        </Button>
        <Button
          width={"50%"}
          padding={"0 0 13px"}
          fontSize={Button.FONT_SIZE.MOBILECATEGORY}
          fontWeight={
            rankNews === 1 ? Button.FONT_WEIGHT.BOLD : Button.FONT_WEIGHT.NORMAL
          }
          borderBottom={rankNews === 1 ? "2px solid" : ""}
          borderColor={Button.COLOR.SECONDARY}
          color={rankNews === 1 ? Button.COLOR.SECONDARY : Button.COLOR.BLACK}
          themeStyle={Button.THEME.NONE}
          onClick={() => setRankNews(1)}
        >
          월간 Main News
        </Button>
      </Box>
      <NewsList size={Text.SIZE.MOBILETITLE} margin={"0 0 20px"} isMobile>
        <List api={rankNewsListApi} params={type}>
          {(list) =>
            list && (
              <NewsList.LAYOUT.MobileThumbnailNews
                list={list.slice(0, 4)}
                isContent
                category={"news"}
              />
            )
          }
        </List>
      </NewsList>
    </Box>
  );
};

export default MobileNewsCategory;
