import Box from "components/atoms/box";
import Button from "components/atoms/button";
import Text from "components/atoms/text";
import List from "components/molecules/list";
import FeatureContent from "components/organisms/featureContent";
import { useBoardFeature } from "hooks/api/board";
import useUrl from "hooks/utils/useUrl";
import React from "react";
import { FaRegNewspaper } from "@react-icons/all-files/fa/FaRegNewspaper";
import featureText from "text/feature";
import Image from "components/atoms/image";

const { more, unit } = featureText;

const Feature = () => {
  const {
    query: { page = 1 },
    changeLink,
  } = useUrl();

  return (
    <Box
      display={Box.DISPLAY.FLEX}
      flexDirection="column"
      al="center"
      padding={"80px 0 0"}
    >
      <List api={useBoardFeature} params={{ page }}>
        {(list) =>
          list &&
          list.map(({ title, cate1Name, cate2, articleCnt, contents }, idx) => {
            const isLast = idx === list.length - 1;

            return (
              <Box
                display={Box.DISPLAY.FLEX}
                flexDirection="column"
                al="flex-end"
                padding={"0 40px 40px"}
                margin={isLast ? "" : "0 0 100px"}
                width={"1260px"}
                bgColor={Box.COLOR.LIGHTGRAY}
                borderRadius="10px"
                key={cate2}
              >
                <Box
                  width={"100%"}
                  borderBottom={"1px solid"}
                  borderColor={Box.COLOR.GRAY}
                  borderRadius={"0 20px"}
                >
                  <Text
                    width={"545px"}
                    bgColor={Text.COLOR.PRIMARY}
                    borderRadius={"0 20px"}
                    padding={"12px 0 12px 72px"}
                    color={Text.COLOR.WHITE}
                    size={Text.SIZE.CLIENTCATEGORY}
                    as={Text.VARIANT.SUBTITLE}
                  >
                    {title}
                  </Text>
                </Box>

                <FeatureContent contents={contents} />

                <Button
                  width={"180px"}
                  height={"40px"}
                  themeStyle={Button.THEME.NONE}
                  borderRadius={"20px"}
                  bgColor={Button.COLOR.SECONDARY}
                  fontSize={Button.FONT_SIZE.CLIENTNORMAL}
                  color={Button.COLOR.WHITE}
                  onClick={() => changeLink(`/feature/detail/${cate2}`)}
                >
                  <Text as={Text.VARIANT.SPAN} isSort>
                    <Image src={"/assets/feature/news.svg"} />
                    <Text as={Text.VARIANT.SPAN} margin={"0 4px 0 14px"}>
                      {more}
                    </Text>
                    <Text as={Text.VARIANT.SPAN} weight={Text.WEIGHT.BOLD}>
                      {articleCnt}
                      {unit}
                    </Text>
                  </Text>
                </Button>
              </Box>
            );
          })
        }
      </List>
    </Box>
  );
};

export default Feature;
