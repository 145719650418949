import Box from "components/atoms/box";
import Image from "components/atoms/image";
import Link from "components/atoms/link";
import Text from "components/atoms/text";
import useUrl from "hooks/utils/useUrl";
import React from "react";

const MobileFooter = () => {
  const {
    query: { category },
  } = useUrl();

  return (
    <Box
      display={Box.DISPLAY.FLEX}
      flexDirection={"column"}
      al={"center"}
      bgColor={Box.COLOR.GRAY}
      padding={"40px 0"}
    >
      <Image
        src={"/assets/footer/mobileFooterLogo.svg"}
        ariaLabel={"한국과학기술단체총연합회 로고"}
        alt={"한국과학기술단체총연합회 로고"}
      />

      <Box display={Box.DISPLAY.FLEX} margin={"40px 0 20px"}>
        <Link link={"/service?category=privacy"}>
          <Text
            as={Text.VARIANT.TEXT}
            size={Text.SIZE.MOBILECONTENT}
            weight={Text.WEIGHT.BOLD}
            color={
              category === "privacy" ? Text.COLOR.SECONDARY : Text.COLOR.BLACK
            }
            padding={"0 10px"}
            ariaLabel={"개인정보 처리 방침 링크"}
          >
            개인정보 처리 방침
          </Text>
        </Link>

        <Link link={"/service?category=termsOfUse"}>
          <Text
            as={Text.VARIANT.TEXT}
            size={Text.SIZE.MOBILECONTENT}
            weight={Text.WEIGHT.BOLD}
            color={
              category === "termsOfUse"
                ? Text.COLOR.SECONDARY
                : Text.COLOR.BLACK
            }
            ariaLabel={"이용약관 링크"}
            padding={"0 10px"}
            borderLeft={"1px solid"}
            borderRight={"1px solid"}
          >
            이용약관
          </Text>
        </Link>

        <Link link={"/service?category=copyright"}>
          <Text
            as={Text.VARIANT.TEXT}
            size={Text.SIZE.MOBILECONTENT}
            weight={Text.WEIGHT.BOLD}
            color={
              category === "copyright" ? Text.COLOR.SECONDARY : Text.COLOR.BLACK
            }
            ariaLabel={"저작권보호정책 링크"}
            padding={"0 10px"}
          >
            저작권보호정책
          </Text>
        </Link>
      </Box>
      <Text
        as={Text.VARIANT.TEXT}
        size={Text.SIZE.MOBILECONTENT}
        color={Text.COLOR.TEXTGRAY}
        lineHeight={"146%"}
        ariaLabel={"[06130] 서울시 강남구 테헤란로 7길 22(역삼동 635-4)"}
      >
        [06130] 서울시 강남구 테헤란로 7길 22(역삼동 635-4)
      </Text>
      <Text
        as={Text.VARIANT.TEXT}
        size={Text.SIZE.MOBILECONTENT}
        color={Text.COLOR.TEXTGRAY}
        lineHeight={"146%"}
        ariaLabel={"한국과학기술회관 1관 2층"}
      >
        한국과학기술회관 1관 2층
      </Text>
      <Text
        as={Text.VARIANT.TEXT}
        size={Text.SIZE.MOBILECONTENT}
        color={Text.COLOR.TEXTGRAY}
        lineHeight={"146%"}
        ariaLabel={"연락처. 02-3420-1242 | FAX. 02-563-4931"}
      >
        TEL. 02-3420-1242 | FAX. 02-563-4931
      </Text>
      <Text
        as={Text.VARIANT.TEXT}
        size={Text.SIZE.MOBILECONTENT}
        color={Text.COLOR.TEXTGRAY}
        lineHeight={"146%"}
        ariaLabel={
          "정기간행물 등록번호 : 서울 아 00067 | 등록일자 : 2005.09.26"
        }
      >
        정기간행물 등록번호 : 서울 아 00067
      </Text>
      <Text
        as={Text.VARIANT.TEXT}
        size={Text.SIZE.MOBILECONTENT}
        color={Text.COLOR.TEXTGRAY}
        lineHeight={"146%"}
        ariaLabel={
          "정기간행물 등록번호 : 서울 아 00067 | 등록일자 : 2005.09.26"
        }
      >
        등록일자 : 2005.09.26 | 발행일자 : 2005.09.27
      </Text>
      <Text
        as={Text.VARIANT.TEXT}
        size={Text.SIZE.MOBILECONTENT}
        color={Text.COLOR.TEXTGRAY}
        lineHeight={"146%"}
        ariaLabel={
          "발행인 : 이태식 | 편집인 : 강건기 | 청소년보호책임자 : 오승원"
        }
      >
        발행인 : 이태식 | 편집인 : 강건기 | 청소년보호책임자 : 오승원
      </Text>

      <Text
        as={Text.VARIANT.TEXT}
        size={Text.SIZE.MOBILECONTENT}
        color={Text.COLOR.TEXTGRAY}
        margin={"30px 0 0"}
        ariaLabel={"COPYRIGHT (C) KOFST. ALL RIGHTS RESERVED"}
      >
        COPYRIGHT (C) KOFST. ALL RIGHTS RESERVED
      </Text>
    </Box>
  );
};

export default MobileFooter;
