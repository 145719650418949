import Box from "components/atoms/box";
import Input from "components/atoms/input";
import Link from "components/atoms/link";
import Text from "components/atoms/text";
import useAllCheckbox from "hooks/utils/useAllCheckbox";
import React, { useEffect, useState } from "react";
import HasNotResult from "../HasNotResult";
import convertDayWithTime from "utils/convertDayWithTime";
import TableDeleteBar from "../tableDeleteBar";
import useUrl from "hooks/utils/useUrl";
import { common } from "text/admin/common";
import { useDeleteCoverage } from "hooks/api/admin/coverage";
import Image from "components/atoms/image";
import { adminDefaultUrl } from "api/admin/url";

const CoverageTable = (props) => {
  const { list, tableHeader } = props;

  const {
    default: { remove },
    selectRemoveItems,
  } = common.alert;

  const {
    changeLink,
    query: { page = 1 },
  } = useUrl();

  const currPage = parseInt(page);

  let keyArr = [];
  const [allKeyList, setAllKeyList] = useState([]);

  useEffect(() => {
    setAllKeyList(keyArr);
  }, [list]);

  const {
    checkAll,
    checkboxes,
    resetCheckbox,
    selectedKeys,
    handleCheckAllChange,
    handleCheckboxChange,
  } = useAllCheckbox(list.length, allKeyList);

  const isNoneSelected = selectedKeys.length === 0;

  const mutate = useDeleteCoverage(resetCheckbox);

  const handleDeleteSchedule = () => {
    const data = { keyList: selectedKeys };
    if (isNoneSelected) {
      alert(selectRemoveItems);
      return;
    }

    const confirm = window.confirm(remove);
    if (confirm) mutate({ data });
  };

  const handleCreateSchedule = () => {
    changeLink(`${adminDefaultUrl}/schedule/coverage/detail`);
  };

  return (
    <>
      <TableDeleteBar
        title="일정 등록"
        handleDelete={handleDeleteSchedule}
        handleSave={handleCreateSchedule}
        hasRadius={false}
      />
      <Box
        display={Box.DISPLAY.FLEX}
        height="32px"
        borderTop="1px solid"
        borderColor={Box.COLOR.LINEGRAY}
        bgColor={Box.COLOR.ADMINCONTENTSBG}
      >
        <Box
          width="5%"
          height="100%"
          display={Box.DISPLAY.FLEX}
          al="center"
          jc="center"
          borderBottom={list.length === 0 ? "1px solid" : ""}
          borderLeft="1px solid"
          borderColor={Box.COLOR.ADMINLINEGRAY}
        >
          <Input
            type="checkbox"
            width="16px"
            height="16px"
            borderColor={Input.COLOR.ADMINLINEGRAY}
            onChange={handleCheckAllChange}
            checked={checkAll}
          />
        </Box>
        {tableHeader.map(({ title, width, ariaLabel }, idx) => (
          <Box
            width={width}
            padding="8px 0"
            key={idx}
            borderLeft="1px solid"
            borderRight={idx === tableHeader.length - 1 ? "1px solid" : ""}
            borderBottom={list.length === 0 ? "1px solid" : ""}
            borderColor={Box.COLOR.LINEGRAY}
          >
            <Text
              as={Text.VARIANT.TEXT}
              size={Text.SIZE.CLIENTCONTENTDETAIL}
              weight={Text.WEIGHT.BOLD}
              textAlign="center"
              ariaLabel={ariaLabel}
            >
              {title}
            </Text>
          </Box>
        ))}
      </Box>
      {list.length === 0 && <HasNotResult />}
      {list.length !== 0 &&
        list.map(({ eventBeginDate, eventEndDate, title, key, regDate, fileCnt }, idx) => {
          const pageIdx = currPage > 1 ? (currPage - 1) * 10 + (idx + 1) : idx + 1;
          const hasFile = Number(fileCnt) > 0;
          keyArr.push(key);

          return (
            <Box
              key={idx}
              borderTop={idx === 0 ? "1px solid" : ""}
              borderBottom="1px solid"
              borderColor={Box.COLOR.LINEGRAY}
              display={Box.DISPLAY.FLEX}
              height="77px"
            >
              <Box
                width="5%"
                height="100%"
                display={Box.DISPLAY.FLEX}
                jc="center"
                borderLeft="1px solid"
                borderColor={Box.COLOR.ADMINLINEGRAY}
                al="center"
              >
                <Input
                  type="checkbox"
                  name={key}
                  width="16px"
                  height="16px"
                  borderColor={Input.COLOR.ADMINLINEGRAY}
                  checked={checkboxes[key] ?? false}
                  onChange={(e) => handleCheckboxChange({ e })}
                />
              </Box>
              <Box
                width="7%"
                display={Box.DISPLAY.FLEX}
                jc="center"
                al="center"
                borderLeft="1px solid"
                borderColor={Box.COLOR.LINEGRAY}
              >
                <Text
                  as={Text.VARIANT.TEXT}
                  size={Text.SIZE.CLIENTCONTENTDETAIL}
                  textAlign="center"
                  ariaLabel={pageIdx}
                >
                  {pageIdx}
                </Text>
              </Box>
              <Box
                width="58%"
                display={Box.DISPLAY.FLEX}
                al="center"
                wordBreak="break-all"
                borderLeft="1px solid"
                borderColor={Box.COLOR.LINEGRAY}
                padding="0 10px"
              >
                <Link link={`${adminDefaultUrl}/schedule/coverage/detail?key=${key}`}>
                  <Text
                    as={Text.VARIANT.TEXT}
                    size={Text.SIZE.CLIENTCONTENTDETAIL}
                    textAlign="left"
                    decoration="underline"
                    lineHeight="1.2"
                    ariaLabel={key}
                  >
                    {title}
                    {hasFile && (
                      <Box
                        as="span"
                        display={Box.DISPLAY.INLINE_FLEX}
                        position="relative"
                        top="2px"
                      >
                        <Image
                          src="/assets/admin/clip.png"
                          width="13px"
                          height="13px"
                          margin="0 0 0 5px"
                        />
                      </Box>
                    )}
                  </Text>
                </Link>
              </Box>
              <Box
                width="15%"
                display={Box.DISPLAY.FLEX}
                flexDirection="column"
                gap="6px"
                jc="center"
                al="center"
                wordBreak="break-all"
                borderLeft="1px solid"
                borderColor={Box.COLOR.LINEGRAY}
              >
                <Text
                  as={Text.VARIANT.TEXT}
                  size={Text.SIZE.CLIENTCONTENTDETAIL}
                  textAlign="center"
                  ariaLabel={`행사 기간: ${eventBeginDate} ~ ${eventEndDate}`}
                >
                  {eventBeginDate}
                </Text>
                <Text
                  as={Text.VARIANT.TEXT}
                  size={Text.SIZE.CLIENTCONTENTDETAIL}
                  textAlign="center"
                  ariaLabel={`행사 기간: ${eventBeginDate} ~ ${eventEndDate}`}
                >
                  ~
                </Text>
                <Text
                  as={Text.VARIANT.TEXT}
                  size={Text.SIZE.CLIENTCONTENTDETAIL}
                  textAlign="center"
                  ariaLabel={`행사 기간: ${eventBeginDate} ~ ${eventEndDate}`}
                >
                  {eventEndDate}
                </Text>
              </Box>
              <Box
                width="15%"
                display={Box.DISPLAY.FLEX}
                jc="center"
                al="center"
                wordBreak="break-all"
                borderLeft="1px solid"
                borderRight="1px solid"
                borderColor={Box.COLOR.LINEGRAY}
              >
                <Text
                  as={Text.VARIANT.TEXT}
                  size={Text.SIZE.CLIENTCONTENTDETAIL}
                  textAlign="center"
                  ariaLabel={`등록일시: ${convertDayWithTime(regDate)}`}
                >
                  {convertDayWithTime(regDate)}
                </Text>
              </Box>
            </Box>
          );
        })}
    </>
  );
};

export default CoverageTable;
