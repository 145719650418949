import dayjs from "dayjs";
import { useBoardNews } from "hooks/api/board";
import {
  useNewsletterSideNews,
  usePrevNewsletterDetail,
} from "hooks/api/newsletter";
import useUrl from "hooks/utils/useUrl";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import useMobileStore from "store/useMobileStore";
import convertImage from "utils/convertImage";

const PrevNewsletter = (props) => {
  const { path } = useUrl();
  const { isMobile } = useMobileStore();

  const { list: scienceList, isSuccess: isScienceSuccess } = useBoardNews({
    type: "news",
    category: "COM045_YpfsuKY",
    page: 1,
    limit: 11,
  });

  const { list: groupList, isSuccess: isGroupSuccess } = useBoardNews({
    type: "news",
    category: "COM045_AcXdVFG",
    page: 1,
    limit: 11,
  });
  const fallback = "/assets/common/none.png";
  const { detail, isSuccess } = usePrevNewsletterDetail(path[1]);

  const { list: sideNewsList, isSuccess: isSideNewsSuccess } =
    useNewsletterSideNews(path[1]);

  const [img1Src, setImg1Src] = useState(fallback);
  const [img2Src, setImg2Src] = useState(fallback);

  const handleError = (e, setImgSrc) => {
    setImgSrc(fallback);
  };

  const getUrl = (urlString) => {
    const url = new URL(urlString);

    const params = new URLSearchParams(url.search);

    const key = params.get("key");
    const cate2 = params.get("cate2");

    return `${window.location.origin}/news/${key}?category=${cate2}`;
  };

  const handleChangeText = (text, length) => {
    if (text.length > length) {
      return `${text.substring(0, length)}...`;
    }
    return text;
  };

  useEffect(() => {
    if (isSuccess) {
      setImg1Src(convertImage(detail.sec1Img, "newsletter"));
      setImg2Src(convertImage(detail.sec2Img, "newsletter"));
    }
  }, [isSuccess, detail]);

  return (
    <div
      style={{
        position: "absolute",
        left: "50%",
        transform: isMobile
          ? "scale(0.458) translate3d(-50%, 0px, 0px)"
          : "translateX(-50%)",
        transformOrigin: isMobile ? "0px 0px" : "none",
      }}
    >
      <table
        width="800"
        border="0"
        cellSpacing="0"
        cellPadding="0"
        align="center"
        style={{
          margin: "0 auto",
        }}
      >
        {isSuccess && (
          <tbody>
            <tr>
              <td width="25">&nbsp;</td>
              <td width="677">
                <a href={`${window.location.origin}/`} target="_blank">
                  <img
                    src="https://onlineServer.kofst.or.kr/upload/images/user/newsletter/top.gif"
                    width="677"
                    height="77"
                    alt="한국과총 Webzine"
                    border="0"
                  />
                </a>
              </td>
              <td width="25">&nbsp;</td>
              <td
                width="73"
                style={{
                  background:
                    "url('https://onlineServer.kofst.or.kr/upload/images/user/newsletter/bg_ho.gif')no-repeat 0 0",
                  verticalAlign: "top",
                }}
                rowSpan="5"
              >
                <table
                  width="73"
                  border="0"
                  cellSpacing="0"
                  cellPadding="0"
                  style={{
                    background:
                      "url('https://onlineServer.kofst.or.kr/upload/images/user/newsletter/bg_ho.gif') no-repeat 0 0",
                  }}
                >
                  <tbody>
                    <tr>
                      <td
                        height="77px"
                        style={{
                          fontSize: "28px",
                          textAlign: "center",
                          color: "#ffffff",
                          fontFamily:
                            "'맑은 고딕', '돋움', '돋움체', '나눔고딕'",
                          fontWeight: "bold",
                          verticalAlign: "middle",
                        }}
                      >
                        vol. {detail.cnt}
                      </td>
                    </tr>
                    <tr>
                      <td
                        height="25px"
                        style={{
                          fontFamily:
                            "'맑은 고딕', '돋움', '돋움체', '나눔고딕'",
                          fontSize: "13px",
                          textAlign: "center",
                          color: "#ffffff",
                          verticalAlign: "middle",
                        }}
                      >
                        {detail.period}
                      </td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td colspan="4">&nbsp;</td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td
                style={{
                  verticalAlign: "top",
                }}
              >
                <table width="677" border="0" cellSpacing="0" cellPadding="0">
                  <tbody>
                    <tr>
                      <td
                        width="450"
                        style={{
                          verticalAlign: "top",
                        }}
                      >
                        <table
                          width="450"
                          border="0"
                          cellSpacing="0"
                          cellPadding="0"
                        >
                          <tbody>
                            <tr>
                              <td colspan="2">
                                <table
                                  width="450"
                                  border="0"
                                  cellSpacing="0"
                                  cellPadding="0"
                                >
                                  <tbody>
                                    <tr>
                                      <td>
                                        <div
                                          style={{
                                            width: "450px",
                                            height: "278px",
                                            overflow: "hidden",
                                            padding: "0",
                                            margin: "0",
                                            border: "0",
                                            textAlign: "center",
                                          }}
                                        >
                                          <a
                                            href={getUrl(detail.sec1Url)}
                                            target="_blank"
                                            style={{
                                              width: "100%",
                                            }}
                                          >
                                            <img
                                              style={{
                                                width: "100%",
                                              }}
                                              onError={(e) =>
                                                handleError(e, setImg1Src)
                                              }
                                              src={img1Src}
                                              alt=""
                                              border="0"
                                            />
                                          </a>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        height="40"
                                        style={{
                                          fontFamily: "돋움",
                                          fontSize: "14px",
                                          color: "#e03c48",
                                          fontWeight: "bold",
                                          verticalAlign: "middle",
                                        }}
                                      >
                                        <a
                                          href={getUrl(detail.sec1Url)}
                                          target="_blank"
                                          style={{
                                            textDecoration: "none",
                                            color: "#000",
                                            fontFamily: "돋움",
                                          }}
                                        >
                                          <strong>{detail.sec1Title}</strong>
                                        </a>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        height="60"
                                        style={{
                                          fontFamily: "돋움",
                                          fontSize: "12px",
                                          color: "#636363",
                                          lineHeight: "18px",
                                          verticalAlign: "top",
                                        }}
                                      >
                                        <a
                                          href={getUrl(detail.sec1Url)}
                                          target="_blank"
                                          style={{
                                            textDecoration: "none",
                                            color: "#707070",
                                            fontFamily: "돋움",
                                          }}
                                        >
                                          {handleChangeText(
                                            detail.sec1Contents,
                                            130
                                          )}
                                        </a>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                            <tr>
                              <td colspan="2" style={{ height: "25px" }}>
                                &nbsp;
                              </td>
                            </tr>
                            <tr>
                              <td colspan="2">
                                <table
                                  width="450"
                                  border="0"
                                  cellSpacing="0"
                                  cellPadding="0"
                                >
                                  <tbody>
                                    <tr>
                                      <td>
                                        <div
                                          style={{
                                            width: "450px",
                                            height: "278px",
                                            overflow: "hidden",
                                            padding: "0",
                                            margin: "0",
                                            border: "0",
                                            textAlign: "center",
                                          }}
                                        >
                                          <a
                                            href={getUrl(detail.sec2Url)}
                                            target="_blank"
                                            style={{
                                              width: "100%",
                                            }}
                                          >
                                            <img
                                              style={{
                                                width: "100%",
                                              }}
                                              onError={(e) =>
                                                handleError(e, setImg2Src)
                                              }
                                              src={img2Src}
                                              alt=""
                                              border="0"
                                            />
                                          </a>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        height="40"
                                        style={{
                                          fontFamily: "돋움",
                                          fontSize: "14px",
                                          color: "#e03c48",
                                          fontWeight: "bold",
                                          verticalAlign: "middle",
                                        }}
                                      >
                                        <a
                                          href={getUrl(detail.sec2Url)}
                                          target="_blank"
                                          style={{
                                            textDecoration: "none",
                                            color: "#000",
                                            fontFamily: "돋움",
                                          }}
                                        >
                                          <strong>{detail.sec2Title}</strong>
                                        </a>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        height="60"
                                        style={{
                                          fontFamily: "돋움",
                                          fontSize: "12px",
                                          color: "#636363",
                                          lineHeight: "18px",
                                          verticalAlign: "top",
                                        }}
                                      >
                                        <a
                                          href={getUrl(detail.sec2Url)}
                                          target="_blank"
                                          style={{
                                            textDecoration: "none",
                                            color: "#707070",
                                            fontFamily: "돋움",
                                          }}
                                        >
                                          {handleChangeText(
                                            detail.sec2Contents,
                                            130
                                          )}
                                        </a>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <td
                        style={{
                          borderRight: "1px #e4e4e4 solid",
                          paddingRight: "20px",
                        }}
                      >
                        &nbsp;
                      </td>
                      <td
                        style={{ paddingRight: "10px", verticalAlign: "top" }}
                      >
                        &nbsp;
                      </td>
                      <td
                        width="190"
                        style={{
                          verticalAlign: "top",
                        }}
                      >
                        <table
                          width="190"
                          border="0"
                          cellSpacing="0"
                          cellPadding="0"
                        >
                          <tbody>
                            <tr>
                              <td
                                align="left"
                                style={{
                                  lineHeight: "0",
                                  verticalAlign: "top",
                                }}
                              >
                                <table
                                  width="190"
                                  border="0"
                                  cellSpacing="0"
                                  cellPadding="0"
                                >
                                  <tbody>
                                    <tr>
                                      <td
                                        align="left"
                                        style={{ lineHeight: "0" }}
                                      >
                                        <img
                                          src="https://onlineServer.kofst.or.kr/upload/images/user/newsletter/category_top.gif"
                                          width="118"
                                          height="26"
                                          alt=""
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        align="left"
                                        style={{ lineHeight: "0" }}
                                      >
                                        <a
                                          href={`${window.location.origin}/daily`}
                                          target="_blank"
                                        >
                                          <img
                                            src="https://onlineServer.kofst.or.kr/upload/images/user/newsletter/category_daily.gif"
                                            width="118"
                                            height="30"
                                            alt=""
                                            border="0"
                                          />
                                        </a>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        align="left"
                                        style={{ lineHeight: "0" }}
                                      >
                                        <a
                                          href={`${window.location.origin}/calendar`}
                                          target="_blank"
                                        >
                                          <img
                                            src="https://onlineServer.kofst.or.kr/upload/images/user/newsletter/category_cal.gif"
                                            width="118"
                                            height="28"
                                            alt=""
                                            border="0"
                                          />
                                        </a>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        align="left"
                                        style={{ lineHeight: "0" }}
                                      >
                                        <a
                                          href={`${window.location.origin}/news`}
                                          target="_blank"
                                        >
                                          <img
                                            src="https://onlineServer.kofst.or.kr/upload/images/user/newsletter/category_news.gif"
                                            width="118"
                                            height="27"
                                            alt=""
                                            border="0"
                                          />
                                        </a>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        align="left"
                                        style={{ lineHeight: "0" }}
                                      >
                                        <a
                                          href={`${window.location.origin}/feature`}
                                          target="_blank"
                                        >
                                          <img
                                            src="https://onlineServer.kofst.or.kr/upload/images/user/newsletter/category_special.gif"
                                            width="118"
                                            height="28"
                                            alt=""
                                            border="0"
                                          />
                                        </a>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        align="left"
                                        style={{ lineHeight: "0" }}
                                      >
                                        <a
                                          href={`${window.location.origin}/people`}
                                          target="_blank"
                                        >
                                          <img
                                            src="https://onlineServer.kofst.or.kr/upload/images/user/newsletter/category_person.gif"
                                            width="118"
                                            height="28"
                                            alt=""
                                            border="0"
                                          />
                                        </a>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        height="46"
                                        align="left"
                                        style={{
                                          lineHeight: "0",
                                          verticalAlign: "top",
                                        }}
                                      >
                                        <a
                                          href={`${window.location.origin}/science`}
                                          target="_blank"
                                        >
                                          <img
                                            src="https://onlineServer.kofst.or.kr/upload/images/user/newsletter/category_world.gif"
                                            width="118"
                                            height="29"
                                            alt=""
                                            border="0"
                                          />
                                        </a>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                            <tr>
                              <td width="190" align="left">
                                <table
                                  width="190"
                                  border="0"
                                  cellSpacing="0"
                                  cellPadding="0"
                                  style={{
                                    borderTop: "1px #e4e4e4 solid",
                                    marginBottom: "20px",
                                  }}
                                >
                                  <tbody>
                                    <tr>
                                      <td
                                        width="150"
                                        style={{
                                          fontFamily:
                                            "'맑은 고딕', '돋움', '돋움체', '나눔고딕'",
                                          fontSize: "15px",
                                          fontWeight: "bold",
                                          lineHeight: "18px",
                                        }}
                                      >
                                        과학기술계 소식
                                      </td>
                                      <td
                                        width="56"
                                        height="45"
                                        align="right"
                                        style={{ paddingTop: "5px" }}
                                      >
                                        <a
                                          href={`${window.location.origin}/news?category=COM045_YpfsuKY`}
                                          target="_blank"
                                        >
                                          <img
                                            src="https://onlineServer.kofst.or.kr/upload/images/user/newsletter/btn_more.gif"
                                            width="56"
                                            height="22"
                                            alt=""
                                            border="0"
                                          />
                                        </a>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        colspan="2"
                                        align="left"
                                        style={{
                                          fontFamily: "돋움",
                                          fontSize: "11px",
                                          color: "#707070",
                                          lineHeight: "12px",
                                        }}
                                      >
                                        <table
                                          width="190"
                                          border="0"
                                          cellSpacing="0"
                                          cellPadding="0"
                                        >
                                          <tbody>
                                            {isScienceSuccess &&
                                              scienceList.map(
                                                ({ key, title, cate2 }) => (
                                                  <tr key={key}>
                                                    <td
                                                      width="7"
                                                      style={{
                                                        verticalAlign: "middle",
                                                        //   paddingTop: "6px",
                                                        lineHeight: "12px",
                                                        paddingRight: "5px",
                                                      }}
                                                    >
                                                      <strong>
                                                        <span
                                                          style={{
                                                            fontSize: "20px",
                                                            color: "#222",
                                                          }}
                                                        >
                                                          ·
                                                        </span>
                                                      </strong>
                                                    </td>
                                                    <td
                                                      style={{
                                                        fontFamily: "돋움",
                                                        fontSize: "11px",
                                                        color: "#707070",
                                                        lineHeight: "12px",
                                                        textAlign: "justify",
                                                        verticalAlign: "middle",
                                                      }}
                                                      height="20"
                                                      width="190"
                                                    >
                                                      <a
                                                        target="_blank"
                                                        style={{
                                                          textDecoration:
                                                            "none",
                                                          color: "#707070",
                                                          textAlign: "justify",
                                                          width: "190px",
                                                        }}
                                                        href={`${window.location.origin}/news/${key}?category=${cate2}`}
                                                      >
                                                        {handleChangeText(
                                                          title,
                                                          16
                                                        )}
                                                      </a>
                                                    </td>
                                                  </tr>
                                                )
                                              )}
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                            <tr>
                              <td width="190">
                                <table
                                  width="190"
                                  border="0"
                                  cellSpacing="0"
                                  align="left"
                                  cellPadding="0"
                                  style={{ borderTop: "1px #e4e4e4 solid" }}
                                >
                                  <tbody>
                                    <tr>
                                      <td
                                        width="130"
                                        style={{
                                          fontFamily:
                                            "'맑은 고딕', '돋움', '돋움체', '나눔고딕'",
                                          fontSize: "15px",
                                          fontWeight: "bolder",
                                          lineHeight: "18px",
                                        }}
                                      >
                                        회원단체 소식
                                      </td>
                                      <td
                                        height="45"
                                        align="right"
                                        style={{ paddingTop: "5px" }}
                                      >
                                        <a
                                          href={`${window.location.origin}/news?category=COM045_AcXdVFG`}
                                          target="_blank"
                                        >
                                          <img
                                            src="https://onlineServer.kofst.or.kr/upload/images/user/newsletter/btn_more.gif"
                                            width="56"
                                            height="22"
                                            alt=""
                                            border="0"
                                          />
                                        </a>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        colspan="2"
                                        align="left"
                                        style={{
                                          fontFamily: "돋움",
                                          fontSize: "11px",
                                          color: "#707070",
                                          lineHeight: "12px",
                                        }}
                                      >
                                        <table
                                          width="190"
                                          border="0"
                                          cellSpacing="0"
                                          cellPadding="0"
                                        >
                                          <tbody>
                                            {isGroupSuccess &&
                                              groupList.map(
                                                ({ key, title, cate2 }) => (
                                                  <tr key={key}>
                                                    <td
                                                      width="7"
                                                      style={{
                                                        verticalAlign: "middle",
                                                        //   paddingTop: "6px",
                                                        lineHeight: "12px",
                                                        paddingRight: "5px",
                                                      }}
                                                    >
                                                      <strong>
                                                        <span
                                                          style={{
                                                            fontSize: "20px",
                                                            color: "#222",
                                                          }}
                                                        >
                                                          ·
                                                        </span>
                                                      </strong>
                                                    </td>
                                                    <td
                                                      style={{
                                                        verticalAlign: "middle",
                                                        fontFamily: "돋움",
                                                        fontSize: "11px",
                                                        color: "#707070",
                                                        lineHeight: "12px",
                                                        textAlign: "justify",
                                                      }}
                                                      height="20"
                                                      width="190"
                                                    >
                                                      <a
                                                        target="_blank"
                                                        style={{
                                                          textDecoration:
                                                            "none",
                                                          color: "#707070",
                                                          textAlign: "justify",
                                                          width: "190px",
                                                        }}
                                                        href={`${window.location.origin}/news/${key}?category=${cate2}`}
                                                      >
                                                        {handleChangeText(
                                                          title,
                                                          16
                                                        )}
                                                      </a>
                                                    </td>
                                                  </tr>
                                                )
                                              )}
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td
                        height="40"
                        colspan="4"
                        style={{
                          verticalAlign: "top",
                        }}
                      >
                        &nbsp;
                      </td>
                    </tr>
                    <tr>
                      <td width="447" height="260">
                        <table
                          width="447"
                          height="260"
                          border="0"
                          cellSpacing="0"
                          cellPadding="0"
                        >
                          <tbody>
                            <tr>
                              <td
                                width="387"
                                height="30"
                                style={{
                                  fontFamily:
                                    "'맑은 고딕', '돋움', '돋움체', '나눔고딕'",
                                  fontSize: "16px",
                                  fontWeight: "bold",
                                  verticalAlign: "top",
                                }}
                              >
                                과학기술 캘린더
                              </td>
                              <td
                                width="60"
                                style={{
                                  verticalAlign: "top",
                                }}
                              >
                                <a
                                  href={`${window.location.origin}/calendar`}
                                  target="_blank"
                                >
                                  <img
                                    src="https://onlineServer.kofst.or.kr/upload/images/user/newsletter/btn_more.gif"
                                    width="56"
                                    height="22"
                                    alt=""
                                    border="0"
                                  />
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <td
                                colspan="2"
                                height="222"
                                style={{
                                  borderTop: "2px #e4e4e4 solid",
                                  paddingTop: "10px",
                                  verticalAlign: "top",
                                }}
                              >
                                <table
                                  width="447"
                                  border="0"
                                  cellSpacing="0"
                                  cellPadding="0"
                                  style={{ maxHeight: "222px" }}
                                >
                                  <tbody>
                                    {isSideNewsSuccess &&
                                      sideNewsList.calendarList.map(
                                        ({ key, title, eventBeginDate }) => (
                                          <tr key={key}>
                                            <td
                                              width="10"
                                              style={{
                                                paddingTop: "-3px",
                                                lineHeight: "12px",
                                              }}
                                            >
                                              <strong>
                                                <span
                                                  style={{
                                                    fontSize: "20px",
                                                    color: "#222",
                                                  }}
                                                >
                                                  ·
                                                </span>
                                              </strong>
                                            </td>
                                            <td
                                              width="130"
                                              style={{
                                                paddingTop: "3px",
                                                fontSize: "11px",
                                                fontFamily: "돋움",
                                                color: "#000000",
                                                lineHeight: "12px",
                                              }}
                                              height="22"
                                            >
                                              {dayjs(eventBeginDate).format(
                                                "YYYY. MM. DD (dd요일)"
                                              )}
                                            </td>
                                            <td
                                              width="307"
                                              style={{
                                                paddingTop: "3px",
                                                fontSize: "12px",
                                                fontFamily: "돋움",
                                                color: "#707070",
                                                lineHeight: "12px",
                                              }}
                                              height="22"
                                            >
                                              <a
                                                style={{
                                                  textDecoration: "none",
                                                  color: "#707070",
                                                  textAlign: "justify",
                                                }}
                                                href={`${window.location.origin}/calendar/${key}`}
                                                target="_blank"
                                              >
                                                {handleChangeText(title, 25)}
                                              </a>
                                            </td>
                                          </tr>
                                        )
                                      )}
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <td width="20">&nbsp;</td>
                      <td
                        width="210"
                        colspan="2"
                        style={{
                          verticalAlign: "top",
                        }}
                      >
                        <table
                          width="210"
                          border="0"
                          cellSpacing="0"
                          cellPadding="0"
                        >
                          <tbody>
                            {detail.banner1Img && (
                              <tr>
                                <td
                                  height="80"
                                  style={{
                                    verticalAlign: "top",
                                  }}
                                >
                                  <div
                                    style={{
                                      width: "210px",
                                      height: "75px",
                                      overflow: "hidden",
                                      padding: "0",
                                      margin: "0",
                                      border: "0",
                                      textAlign: "center",
                                    }}
                                  >
                                    <a href={detail.banner1Url} target="_blank">
                                      <img
                                        src={convertImage(
                                          detail.banner1Img,
                                          "newsletter"
                                        )}
                                        alt=""
                                        border="0"
                                      />
                                    </a>
                                  </div>
                                </td>
                              </tr>
                            )}

                            {detail.banner2Img && (
                              <tr>
                                <td
                                  height="80"
                                  style={{
                                    verticalAlign: "top",
                                  }}
                                >
                                  <div
                                    style={{
                                      width: "210px",
                                      height: "75px",
                                      overflow: "hidden",
                                      padding: "0",
                                      margin: "0",
                                      border: "0",
                                      textAlign: "center",
                                    }}
                                  >
                                    <a href={detail.banner2Url} target="_blank">
                                      <img
                                        src={convertImage(
                                          detail.banner2Img,
                                          "newsletter"
                                        )}
                                        alt=""
                                        border="0"
                                      />
                                    </a>
                                  </div>
                                </td>
                              </tr>
                            )}
                            {detail.banner3Img && (
                              <tr>
                                <td
                                  height="80"
                                  style={{
                                    verticalAlign: "top",
                                  }}
                                >
                                  <div
                                    style={{
                                      width: "210px",
                                      height: "75px",
                                      overflow: "hidden",
                                      padding: "0",
                                      margin: "0",
                                      border: "0",
                                      textAlign: "center",
                                    }}
                                  >
                                    <a href={detail.banner3Url} target="_blank">
                                      <img
                                        src={convertImage(
                                          detail.banner3Img,
                                          "newsletter"
                                        )}
                                        alt=""
                                        border="0"
                                      />
                                    </a>
                                  </div>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td width="25">&nbsp;</td>
            </tr>
            <tr>
              <td colspan="4">&nbsp;</td>
            </tr>
            <tr>
              <td width="25">&nbsp;</td>
              <td
                height="140"
                valign="middle"
                style={{ borderTop: "2px #4c4c4c solid", paddingTop: "20px" }}
              >
                <table width="677" border="0" cellSpacing="0" cellPadding="0">
                  <tbody>
                    <tr>
                      <td width="207">
                        <a href="https://www.kofst.or.kr" target="_blank">
                          <img
                            src="https://onlineServer.kofst.or.kr/upload/images/user/newsletter/blogo.gif"
                            width="104"
                            height="33"
                            alt="한국과학기술단체총연합회"
                            border=""
                          />
                        </a>
                      </td>
                      <td width="430">
                        <img
                          src="https://onlineServer.kofst.or.kr/upload/images/user/newsletter/address.gif"
                          width="348"
                          height="36"
                          alt="[135-703] 서울시 강남구 테헤란로 7길 22(역삼동 635-4) 한국과학기술회관 신관2층 TEL. 02-3420-1233  | FAX. 02-555-6226 COPYRIGHT (C) KOFST. ALL RIGHTS RESERVED"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td height="90" colspan="4" valign="middle">
                        <img
                          src="https://onlineServer.kofst.or.kr/upload/images/user/newsletter/btminfo.gif"
                          width="666"
                          height="28"
                          alt=""
                          border="0"
                          usemap="#Map"
                        />
                        <map name="Map">
                          <area
                            shape="rect"
                            coords="491,17,565,27"
                            href="mailto:news@kofst.or.kr"
                          />
                          <area shape="rect" coords="127,14,152,28" href="#" />
                        </map>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td width="25">&nbsp;</td>
            </tr>
          </tbody>
        )}
      </table>
    </div>
  );
};

export default PrevNewsletter;
