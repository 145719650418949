import Box from "components/atoms/box";
import Text from "components/atoms/text";
import useContextForm from "hooks/utils/useContextForm";
import React from "react";
import AdminTable from "../adminTable";
import { useState } from "react";
import { useTerminationMemberList } from "hooks/api/admin/member";
import TerminationMemberTable from "components/molecules/terminationMemberTable";
import { member } from "text/admin/member";
import useUrl from "hooks/utils/useUrl";

const AdminTerminationMemberList = (props) => {
  const {
    filterData: { searchCondition, searchValue, receptionStatus },
  } = props;

  const {
    query: { page },
  } = useUrl();

  const { title, tableHeader, defaultValues } = member.termination.table;

  const [selectedMenu, setSelectedMenu] = useState("10");

  const { Provider } = useContextForm(defaultValues);

  return (
    <Box maxWidth="1024px" display={Box.DISPLAY.FLEX} flexDirection="column" margin="40px 0 0 0">
      <Text as={Text.VARIANT.SUBTITLE} ariaLabel={title} margin="0 0 10px 0">
        {title}
      </Text>
      <Provider>
        <AdminTable
          type={["countBar"]}
          hasSelectMenu={true}
          page={page}
          //
          api={useTerminationMemberList}
          tableHeader={tableHeader}
          searchValue={searchValue}
          searchCondition={searchCondition}
          receptionStatus={receptionStatus}
          //
          selectedMenu={selectedMenu}
          setSelectedMenu={setSelectedMenu}
          width="120px"
        >
          <TerminationMemberTable />
        </AdminTable>
      </Provider>
    </Box>
  );
};

export default AdminTerminationMemberList;
