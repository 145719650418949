import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const useHandleAlert = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleCancel = (e, message) => {
    e.preventDefault();
    const confirm = window.confirm(message);
    if (confirm) {
      navigate(-1);
    }
  };

  useEffect(() => {
    window.addEventListener("popstate", handleGoBack);

    return () => window.removeEventListener("popstate", handleGoBack);
  }, [navigate]);

  return {
    handleCancel,
    handleGoBack,
  };
};

export default useHandleAlert;
