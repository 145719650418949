import Box from "components/atoms/box";
import Button from "components/atoms/button";
import Image from "components/atoms/image";
import Input from "components/atoms/input";
import Text from "components/atoms/text";
import TableDeleteBar from "components/molecules/tableDeleteBar";
import useAllCheckbox from "hooks/utils/useAllCheckbox";
import React from "react";
import { useNavigate } from "react-router-dom";
import convertDayWithTime from "utils/convertDayWithTime";
import HasNotResult from "../HasNotResult";
import { useDeletePopup } from "hooks/api/admin/management_site";
import { useEffect } from "react";
import { useState } from "react";
import { site } from "text/admin/site";
import { common } from "text/admin/common";
import { SERVER_URL } from "api/config";
import { adminDefaultUrl } from "api/admin/url";

const PopupTable = (props) => {
  const { list } = props;

  const { tableHeader } = site.popup;
  const {
    selectRemoveItems,
    default: { remove },
  } = common.alert;

  const navigate = useNavigate();

  let keyArr = [];

  const [allKeyList, setAllKeyList] = useState([]);

  useEffect(() => {
    setAllKeyList(keyArr);
  }, [list]);

  const {
    checkAll,
    resetCheckbox,
    checkboxes,
    selectedKeys,
    handleCheckAllChange,
    handleCheckboxChange,
  } = useAllCheckbox(list.length, allKeyList);

  const mutate = useDeletePopup(resetCheckbox);

  const isNoneSelected = selectedKeys.length === 0;

  const handleDeleteSelectedBanner = () => {
    const data = { keyList: selectedKeys };
    if (isNoneSelected) {
      alert(selectRemoveItems);
      return;
    }

    const confirm = window.confirm(remove);
    if (confirm) mutate({ data });
  };

  const handleCreateBanner = () => {
    navigate(`${adminDefaultUrl}/management_site/popup/detail`);
  };

  const handleUpdateBanner = (key) => {
    navigate(`${adminDefaultUrl}/management_site/popup/detail?key=${key}`);
  };

  const handleDeleteBanner = (key) => {
    const data = { keyList: [key] };
    const confirm = window.confirm(remove);
    if (confirm) mutate({ data });
  };

  return (
    <Box maxWidth="1024px">
      <TableDeleteBar
        title="등록"
        handleDelete={handleDeleteSelectedBanner}
        handleSave={handleCreateBanner}
        hasRadius={false}
      />
      <Box
        display={Box.DISPLAY.FLEX}
        al="center"
        height="32px"
        borderTop="1px solid"
        borderColor={Box.COLOR.ADMINLINEGRAY}
        bgColor={Box.COLOR.WHITE}
      >
        <Box
          width="4%"
          height="100%"
          display={Box.DISPLAY.FLEX}
          al="center"
          jc="center"
          borderLeft="1px solid"
          borderBottom="1px solid"
          borderColor={Box.COLOR.ADMINLINEGRAY}
          bgColor={Box.COLOR.ADMINCONTENTSBG}
        >
          <Input
            type="checkbox"
            width="16px"
            height="16px"
            borderColor={Input.COLOR.ADMINLINEGRAY}
            onChange={handleCheckAllChange}
            checked={checkAll}
          />
        </Box>
        {tableHeader.map(({ title, width }, idx) => (
          <Box
            width={width}
            height="100%"
            display={Box.DISPLAY.FLEX}
            al="center"
            jc="center"
            key={idx}
            borderLeft="1px solid"
            borderRight={idx === tableHeader.length - 1 ? "1px solid" : "none"}
            borderBottom="1px solid"
            borderColor={Box.COLOR.ADMINLINEGRAY}
            bgColor={Box.COLOR.ADMINCONTENTSBG}
          >
            <Text
              as={Text.VARIANT.TEXT}
              weight={Text.WEIGHT.BOLD}
              size={Text.SIZE.CLIENTCONTENTDETAIL}
            >
              {title}
            </Text>
          </Box>
        ))}
      </Box>
      {list.length === 0 && <HasNotResult />}
      {list.length > 0 &&
        list.map(({ key, startDate, endDate, status, title, filePath }, idx) => {
          const imagePath = filePath && `${SERVER_URL}/upload/attach/${filePath}`;
          keyArr.push(key);

          return (
            <Box
              display={Box.DISPLAY.FLEX}
              al="center"
              key={key}
              height="106px"
              borderBottom="1px solid"
              borderColor={Box.COLOR.ADMINLINEGRAY}
              bgColor={Box.COLOR.WHITE}
            >
              <Box
                width="4%"
                height="100%"
                display={Box.DISPLAY.FLEX}
                jc="center"
                borderLeft="1px solid"
                borderColor={Box.COLOR.ADMINLINEGRAY}
                al="center"
              >
                <Input
                  type="checkbox"
                  name={key}
                  width="16px"
                  height="16px"
                  borderColor={Input.COLOR.ADMINLINEGRAY}
                  checked={checkboxes[key] ?? false}
                  onChange={(e) => handleCheckboxChange({ e, key })}
                />
              </Box>
              <Box
                width="6%"
                height="100%"
                display={Box.DISPLAY.FLEX}
                jc="center"
                borderLeft="1px solid"
                borderColor={Box.COLOR.ADMINLINEGRAY}
                al="center"
              >
                <Text as={Text.VARIANT.TEXT} size={Text.SIZE.ADMINCONTENT}>
                  {idx + 1}
                </Text>
              </Box>
              <Box
                width="9%"
                height="100%"
                display={Box.DISPLAY.FLEX}
                jc="center"
                borderLeft="1px solid"
                borderColor={Box.COLOR.ADMINLINEGRAY}
                al="center"
              >
                <Text as={Text.VARIANT.TEXT} size={Text.SIZE.ADMINCONTENT}>
                  {status === "Y" ? "사용함" : "사용안함"}
                </Text>
              </Box>
              <Box
                width="18%"
                height="100%"
                display={Box.DISPLAY.FLEX}
                jc="center"
                padding="10px"
                borderLeft="1px solid"
                borderColor={Box.COLOR.ADMINLINEGRAY}
                al="center"
              >
                <Image src={imagePath} width="100%" height="100%" />
              </Box>
              <Box
                width="16%"
                height="100%"
                padding="0 10px"
                display={Box.DISPLAY.FLEX}
                jc="center"
                borderLeft="1px solid"
                borderColor={Box.COLOR.ADMINLINEGRAY}
                al="center"
              >
                <Text as={Text.VARIANT.TEXT} size={Text.SIZE.ADMINCONTENT}>
                  {title}
                </Text>
              </Box>
              <Box
                width="13%"
                height="100%"
                display={Box.DISPLAY.FLEX}
                jc="center"
                borderLeft="1px solid"
                borderColor={Box.COLOR.ADMINLINEGRAY}
                al="center"
              >
                <Text as={Text.VARIANT.TEXT} size={Text.SIZE.ADMINCONTENT} wordBreak="break-all">
                  {convertDayWithTime(startDate)}
                </Text>
              </Box>
              <Box
                width="13%"
                height="100%"
                display={Box.DISPLAY.FLEX}
                jc="center"
                borderLeft="1px solid"
                borderColor={Box.COLOR.ADMINLINEGRAY}
                al="center"
              >
                <Text as={Text.VARIANT.TEXT} size={Text.SIZE.ADMINCONTENT} wordBreak="break-all">
                  {convertDayWithTime(endDate)}
                </Text>
              </Box>
              <Box
                width="21%"
                height="100%"
                display={Box.DISPLAY.FLEX}
                jc="center"
                al="center"
                gap="12px"
                borderLeft="1px solid"
                borderRight="1px solid"
                borderColor={Box.COLOR.ADMINLINEGRAY}
              >
                <Button
                  themeStyle="adminCommon"
                  padding="7px 24px"
                  onClick={() => handleUpdateBanner(key)}
                >
                  수정
                </Button>
                <Button
                  themeStyle="adminCommon"
                  padding="7px 24px"
                  onClick={() => handleDeleteBanner(key)}
                >
                  삭제
                </Button>
              </Box>
            </Box>
          );
        })}
    </Box>
  );
};

export default PopupTable;
