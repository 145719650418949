import { DELETE, GET, POST, PUT } from "api/config";
import { newsletter } from "../url";

const { newsletterUrl, applierUrl, rejectionUrl, bannerUrl, automailUrl } = newsletter;

// 뉴스레터 목록
export const getNewsletterList = async ({
  page = "1",
  itemsPerPage = "10",
  searchCondition = "",
  searchValue = "",
}) =>
  await GET(
    `${newsletterUrl}?pageIndex=${page}&itemsPerPage=${itemsPerPage}&${searchCondition}=${searchValue}`
  );

// 뉴스레터 상세 목록
export const getNewsletterDetailList = async ({ key }) => await GET(`${newsletterUrl}/${key}`);

// 뉴스레터 등록
export const createNewsletter = async ({ data }) => {
  await POST(`${newsletterUrl}`, data);
};

// 뉴스레터 수정
export const updateNewsletter = async ({ data, key }) => {
  await PUT(`${newsletterUrl}/${key}`, data);
};

// 뉴스레터 삭제
export const deleteNewsletter = async ({ data }) => {
  await DELETE(`${newsletterUrl}`, data);
};

// 뉴스레터 신청자 목록
export const getApplierList = async ({
  page = "1",
  itemsPerPage = "10",
  searchCondition = "",
  searchValue = "",
}) =>
  await GET(
    `${applierUrl}?pageIndex=${page}&itemsPerPage=${itemsPerPage}&searchCondition=${searchCondition}&searchKeyword=${searchValue}`
  );

// 뉴스레터 신청자 목록 삭제
export const deleteNewsletterApplier = async ({ data }) => {
  await PUT(`${applierUrl}`, data);
};

// 뉴스레터 신청 거부/변경 목록
export const getNewsletterRejectList = async ({
  page = "1",
  itemsPerPage = "10",
  searchCondition = "",
  searchValue = "",
}) =>
  await GET(
    `${rejectionUrl}?pageIndex=${page}&itemsPerPage=${itemsPerPage}&searchCondition=${searchCondition}&searchKeyword=${searchValue}`
  );

// 뉴스레터 신청 거부/변경 목록 삭제
export const deleteNewsletterRejectList = async ({ data }) => {
  await PUT(`${rejectionUrl}`, data);
};

// 뉴스레터 신청 거부/변경 거부 철회
export const updateNewsletterRejection = async ({ data }) => {
  await PUT(`${rejectionUrl}`, data);
};

// 하단 배너 관리
export const getNewsletterBannerList = async () => await GET(bannerUrl);

// 하단 배너 상세
export const getNewsletterBannerDetail = async ({ key }) => await GET(`${bannerUrl}/${key}`);

// 하단 배너 상세 수정
export const updateNewsletterBanner = async ({ key, formData }) => {
  await PUT(`${bannerUrl}/${key}`, formData);
};

// 하단 배너 순서 변경
export const updateNewsletterBannerOrder = async ({ data }) => {
  await PUT(bannerUrl, data);
};

// 뉴스레터 자동전달
export const getNewsletterAutoDelivery = async () => await GET(automailUrl);

// 뉴스레터 자동전달 수정
export const updateNewsletterAutoDelivery = async ({ data }) => {
  await PUT(automailUrl, data);
};

// 뉴스레터 HTML 코드 복사
export const copyNewsletterHtml = async ({ key }) => await GET(`${newsletterUrl}/${key}/html`);
