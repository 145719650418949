import { NavLink } from "react-router-dom";
import styled, { css } from "styled-components";

const active = {
  menu: css`
    position: relative;

    ::before {
      content: "";

      position: absolute;
      bottom: 0;
      left: 0;

      width: 100%;
      height: 4px;

      background-color: ${(props) => props.theme.color.secondary};
      border-radius: 2px;
    }
  `,
};

export const LinkComponent = styled(NavLink)`
  width: ${(props) => props.$width};
  height: ${(props) => props.$height};
  margin: ${(props) => props.$margin};
  padding: ${(props) => props.$padding};

  &.active {
    ${(props) => active[props.$active]}
  }
`;
