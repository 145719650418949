import Box from "components/atoms/box";
import Button from "components/atoms/button";
import useHandleAlert from "hooks/utils/useHandleAlert";
import useUrl from "hooks/utils/useUrl";
import React from "react";
import { common } from "text/admin/common";

const AdminDetailEditButton = (props) => {
  const { isPending, handleTemporarySave, hasTemporaryFunc } = props;

  const {
    query: { key },
  } = useUrl();

  const { cancel } = common.alert.edit;
  const { handleCancel } = useHandleAlert();

  return (
    <Box
      display={Box.DISPLAY.FLEX}
      al="center"
      jc="center"
      gap="12px"
      width="100%"
      padding="40px 0 0 0"
      bgColor={Box.COLOR.ADMINCONTENTSBG}
      position="relative"
    >
      <Button onClick={(e) => handleCancel(e, cancel)} themeStyle="adminCancel" padding="12px 40px">
        취소
      </Button>
      <Button
        themeStyle={isPending ? "disabled" : "adminSave"}
        padding="12px 40px"
        disabled={isPending}
      >
        저장
      </Button>
      {hasTemporaryFunc && !key && (
        <Button
          onClick={handleTemporarySave}
          themeStyle={isPending ? "disabled" : "adminSave"}
          padding="12px 40px"
          disabled={isPending}
        >
          임시저장
        </Button>
      )}
    </Box>
  );
};

export default AdminDetailEditButton;
