import helpText from "./info.json";

helpText.rss.intro = `・RSS는 Really Syndication (또는 Rich Site Summary)의 줄임말로 자주 업데이트 되는 컨텐츠를 사용자에게 
    쉽게 제공하기 위해 만들어진 콘텐츠의 배급 포맷입니다.

・사이트를 직접 방문하지 않고도 업데이트되는 콘텐츠에 대한 최소한의 정보를 사용자가 받아 볼 수 있도록 해주
    는 기술을 의미합니다.

・한국과학기술단체총연합회의 과학기술관련 정책, 기술동향, 인물, 과학상식, 과학관련소식, 자유로운 토론 등을 
    즐기실 수 있습니다.`;

helpText.rss.usingIntro = `・기사페이지 상단 및 하단에 있는 RSS 버튼을 클릭하시면 해당하는 카테고리의 주소가 복사됩니다.
・그 주소를 Ctrl+V 단축버튼을 사용하여 사용하고 계신 RSS 리더에 복사해서 붙이세요.
・일부러 찾아다니지 않아도 실시간으로 업데이트 되는 뉴스를 구독 하실 수 있습니다.`;

helpText.rss.readerIntro = `・연모 프로그램을 사용하여 한국과총의 기사를 실시간으로 받아오세요!`;

export default helpText;
