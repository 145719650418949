import { getCategory } from "api/category";
import { useQuery } from "react-query";

export const useCategory = () => {
  const { data, isLoading, isSuccess, isError } = useQuery(
    "categoryMenu",
    getCategory
  );

  return {
    data,
    isLoading,
    isSuccess,
    isError,
  };
};
