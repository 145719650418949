import { css } from "styled-components";

const picker = css`
  .picker-container .picker-column .picker-item {
    font-size: ${(props) => props.theme.font.mobileTitle};
    padding: 0;
  }

  .picker-container {
    height: 180px !important;
  }

  .picker-container .picker-inner {
    -webkit-mask-box-image: none;
  }

  .picker-highlight {
    display: none !important;
  }
`;

export default picker;
