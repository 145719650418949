import Box from "components/atoms/box";
import Image from "components/atoms/image";
import Link from "components/atoms/link";
import Text from "components/atoms/text";
import React from "react";
import useMobileStore from "store/useMobileStore";
import homeText from "text/home";

const { newsletter } = homeText;

const NewsLetter = () => {
  const { isMobile } = useMobileStore();

  return (
    <Box
      width={isMobile ? "100%" : "calc(50% - 5px)"}
      display={Box.DISPLAY.FLEX}
      jc={"center"}
      al={"center"}
    >
      <Box
        display={Box.DISPLAY.FLEX}
        flexDirection="column"
        jc={"space-between"}
        al={"center"}
        width={"100%"}
        maxWidth={isMobile ? "500px" : "100%"}
        height={isMobile ? "auto" : "507px"}
        margin={isMobile ? "10px 0" : "0"}
        borderRadius={Box.RADIUS.NORMAL}
        ariaLabel="뉴스레터 과월호 보러가기 영역"
        position="relative"
      >
        <Box width={"100%"} position="relative" zIndex={Box.LEVEL.BOTTOM}>
          <Image
            width={"100%"}
            src={`assets${isMobile ? "/mobile" : ""}/home/webzine.png`}
          />
        </Box>
        <Box
          width={"100%"}
          display={Box.DISPLAY.FLEX}
          flexDirection="column"
          jc={"space-between"}
          position="absolute"
          top={"0"}
          bottom={"0"}
          left={"0"}
          padding={isMobile ? "25px 47px" : "36px 54px"}
        >
          <Box
            width={"100%"}
            zIndex={Box.LEVEL.TOP}
            ariaLabel="다시보는 한국과총 웹진"
          >
            <Text
              as={Text.VARIANT.TEXT}
              size={isMobile ? Text.SIZE.MOBILETITLE : Text.SIZE.CLIENTHEAD}
              weight={Text.WEIGHT.BOLD}
              color={Text.COLOR.SECONDARY}
              ariaLabel="다시보는 한국과총 웹진"
            >
              {newsletter.title[0]}
            </Text>
            <Text
              as={Text.VARIANT.TEXT}
              size={isMobile ? Text.SIZE.MOBILETITLE : Text.SIZE.CLIENTHEAD}
              weight={Text.WEIGHT.BOLD}
              color={Text.COLOR.SECONDARY}
              ariaLabel="다시보는 한국과총 웹진"
            >
              {newsletter.title[1]}
            </Text>
          </Box>

          <Box
            display={Box.DISPLAY.FLEX}
            jc={"center"}
            position="relative"
            zIndex={Box.LEVEL.TOP}
            width={"100%"}
          >
            <Link link={"/newsletter"} width={isMobile ? "100%" : "320px"}>
              <Box
                display={Box.DISPLAY.FLEX}
                jc={"center"}
                al={"center"}
                borderRadius={"5px"}
                width={isMobile ? "100%" : "320px"}
                padding={isMobile ? "10px 0" : "15px 0"}
                bgColor={Box.COLOR.SECONDARY}
                borderColor={Box.COLOR.SECONDARY}
                ariaLabel="과월호 보러가기"
              >
                <Text
                  weight={Text.WEIGHT.BOLD}
                  size={Text.SIZE.CLIENTCONTENT}
                  color={Box.COLOR.WHITE}
                >
                  {newsletter.button}
                </Text>
              </Box>
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default NewsLetter;
