import { DatePicker } from "antd";
import Box from "components/atoms/box";
import Button from "components/atoms/button";
import Image from "components/atoms/image";
import Input from "components/atoms/input";
import Text from "components/atoms/text";
import RadioButton from "components/molecules/radioButton";
import SelectMenu from "components/molecules/selectMenu";
import useContextForm from "hooks/utils/useContextForm";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import locale from "antd/es/date-picker/locale/ko_KR";
import useUrl from "hooks/utils/useUrl";

const SelectSearchMenu = (props) => {
  const {
    selectedMenu,
    setSelectedMenu,
    title,
    registerName,
    selectName,
    options,
    width = "104px",
    hasSearchInput = true,
  } = props;

  const { pathname } = useUrl();

  const { register, reset } = useFormContext();

  useEffect(() => {
    return () => reset({ keyword: "" });
  }, [pathname]);

  return (
    <Box as="tr" bgColor={Box.COLOR.WHITE}>
      <Box
        as="td"
        padding="8px"
        border="1px solid"
        borderColor={Box.COLOR.ADMINLINEGRAY}
        width="120px"
      >
        <Text as={Text.VARIANT.TEXT} size={Text.SIZE.ADMINCONTENT}>
          {title}
        </Text>
      </Box>
      <Box as="td" padding="8px" border="1px solid" borderColor={Box.COLOR.ADMINLINEGRAY}>
        <SelectMenu
          width={width}
          selectedMenu={selectedMenu}
          setSelectedMenu={setSelectedMenu}
          options={options}
          registerName={selectName}
        />
        {hasSearchInput && (
          <Input
            {...register(registerName)}
            type="text"
            name={registerName}
            padding="0 8px"
            margin="0 0 0 5px"
            width="500px"
            height="32px"
            themeStyle="secondary"
          />
        )}
      </Box>
    </Box>
  );
};

const SelectTwoDepthSearchMenu = (props) => {
  const {
    depth1Menu,
    setDepth1Menu,
    depth2Menu,
    setDepth2Menu,
    title,
    depth1Options,
    depth2Options,
    width = "104px",
  } = props;

  return (
    <Box as="tr" bgColor={Box.COLOR.WHITE}>
      <Box
        as="td"
        padding="8px"
        border="1px solid"
        borderColor={Box.COLOR.ADMINLINEGRAY}
        width="120px"
      >
        <Text as={Text.VARIANT.TEXT} size={Text.SIZE.ADMINCONTENT}>
          {title}
        </Text>
      </Box>
      <Box as="td" padding="8px" border="1px solid" borderColor={Box.COLOR.ADMINLINEGRAY}>
        <Box display={Box.DISPLAY.FLEX} al="center" gap="5px">
          <SelectMenu
            width={width}
            selectedMenu={depth1Menu}
            setSelectedMenu={setDepth1Menu}
            options={depth1Options}
            registerName="cate1"
          />
          {depth1Menu && (
            <SelectMenu
              width="50%"
              selectedMenu={depth2Menu}
              setSelectedMenu={setDepth2Menu}
              options={depth2Options}
              registerName="cate2"
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

const SelectRadioButtonList = (props) => {
  const { list, registerName, currOption, name, title } = props;

  const { setValue } = useFormContext();

  const [selectedRadioOption, setSelectedRadioOption] = useState("");

  useEffect(() => {
    setSelectedRadioOption(currOption);
  }, [currOption]);

  useEffect(() => {
    setValue(registerName, selectedRadioOption);
  }, [selectedRadioOption]);

  return (
    <Box as="tr" bgColor={Box.COLOR.WHITE}>
      <Box as="td" padding="8px" border="1px solid" borderColor={Box.COLOR.ADMINLINEGRAY}>
        <Text as={Text.VARIANT.TEXT} size={Text.SIZE.ADMINCONTENT}>
          {title}
        </Text>
      </Box>
      <Box as="td" padding="8px" border="1px solid" borderColor={Box.COLOR.ADMINLINEGRAY}>
        <Box display={Box.DISPLAY.FLEX} al="center" gap="20px" height="32px">
          <RadioButton
            list={list}
            registerName={registerName}
            selectedRadioOption={selectedRadioOption}
            setSelectedRadioOption={setSelectedRadioOption}
            errorMessage="필수값 입니다."
            name={name}
            required
          />
        </Box>
      </Box>
    </Box>
  );
};

const SearchDatePicker = (props) => {
  const {
    title,
    selectedStartDate,
    selectedEndDate,
    setSelectedStartDate,
    setSelectedEndDate,
    hasSelectMenu = false,
    selectedMenu,
    setSelectedMenu,
    registerName,
    width,
    options,
  } = props;

  const dateFormat = "YYYY-MM-DD";

  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);

    if (date && date > selectedEndDate) {
      setSelectedEndDate(date);
    }
  };

  const handleEndDateChange = (date) => {
    setSelectedEndDate(date);
  };

  const disabledEndDate = (current) => {
    if (current && current < selectedStartDate) {
      return { disabled: true };
    }
  };

  return (
    <Box as="tr" bgColor={Box.COLOR.WHITE}>
      <Box
        as="td"
        padding="8px"
        border="1px solid"
        borderColor={Box.COLOR.ADMINLINEGRAY}
        width="120px"
      >
        <Text as={Text.VARIANT.TEXT} size={Text.SIZE.ADMINCONTENT}>
          {title}
        </Text>
      </Box>
      <Box as="td" padding="8px" border="1px solid" borderColor={Box.COLOR.ADMINLINEGRAY}>
        <Box display={Box.DISPLAY.FLEX} al="center" gap="5px">
          {hasSelectMenu && (
            <SelectMenu
              width={width}
              selectedMenu={selectedMenu}
              setSelectedMenu={setSelectedMenu}
              options={options}
              registerName={registerName}
            />
          )}
          <Box display={Box.DISPLAY.FLEX} al="center" gap="15px">
            <Box as="label" display={Box.DISPLAY.FLEX} al="center" gap="6px">
              <DatePicker
                locale={locale}
                format={dateFormat}
                onChange={handleStartDateChange}
                value={selectedStartDate}
                className="admin-date-picker"
              />
              <Image src="/assets/admin/calendarIcon.png" width="24px" height="24px" />
            </Box>
          </Box>
          <Text as={Text.VARIANT.SPAN}>~</Text>
          <Box display={Box.DISPLAY.FLEX} al="center" gap="15px">
            <Box as="label" display={Box.DISPLAY.FLEX} al="center" gap="6px">
              <DatePicker
                locale={locale}
                format={dateFormat}
                onChange={handleEndDateChange}
                value={selectedEndDate}
                disabledDate={disabledEndDate}
                className="admin-date-picker"
              />
              <Image src="/assets/admin/calendarIcon.png" width="24px" height="24px" />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const SearchCondition = (props) => {
  const { children, onSubmit, defaultValues } = props;

  const { methods, Provider } = useContextForm(defaultValues);

  return (
    <Provider>
      <Box as="form" onSubmit={methods.handleSubmit(onSubmit)} maxWidth="1024px">
        <Box as="table" maxWidth="1024px" width="100%" borderCollapse="collapse">
          <Box as="tbody">{children}</Box>
        </Box>
        <Box width="100%" display={Box.DISPLAY.FLEX} jc="center" margin="20px 0 0 0">
          <Button themeStyle="adminSave" padding="12px 40px">
            검색
          </Button>
        </Box>
      </Box>
    </Provider>
  );
};

SearchCondition.Select = SelectSearchMenu;
SearchCondition.RadioButtonList = SelectRadioButtonList;
SearchCondition.TwoDepthSearchMenu = SelectTwoDepthSearchMenu;
SearchCondition.DatePicker = SearchDatePicker;

export default SearchCondition;
