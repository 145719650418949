import { getGroupMainNotice, getScienceMainNotice } from "api/notice";
import { useQuery } from "react-query";

export const useMainNews = (key, api) => {
  const { data, isLoading, isSuccess, isError } = useQuery(key, api);

  const list = data?.data.list;

  return {
    list,
    isLoading,
    isSuccess,
    isError,
  };
};

export const useScienceMainNotice = () => {
  return useMainNews("scienceNotice", getScienceMainNotice);
};

export const useGroupMainNotice = () => {
  return useMainNews("groupNotice", getGroupMainNotice);
};
