import Box from "components/atoms/box";
import Button from "components/atoms/button";
import Image from "components/atoms/image";
import Text from "components/atoms/text";
import React from "react";
import { IoMdClose } from "@react-icons/all-files/io/IoMdClose";
import shareSns from "utils/shareSns";
import detailText from "text/detail";

const { share } = detailText;

const sns = [
  {
    titleList: share.kakaotalk,
    image: "/assets/detail/kakao.svg",
    event: shareSns.shareToKakaoTalk,
  },
  {
    titleList: share.kakaoStory,
    image: "/assets/detail/kakaoStory.svg",
    event: shareSns.shareToKakaoStory,
  },
  {
    titleList: share.blog,
    image: "/assets/detail/blog.svg",
    event: shareSns.shareToNaverBlog,
  },
  {
    titleList: share.facebook,
    image: "/assets/detail/facebook.svg",
    event: shareSns.shareToFacebook,
  },
  {
    titleList: share.twitter,
    image: "/assets/detail/twitter.svg",
    event: shareSns.shareToTwitter,
  },
];

const ShareBox = ({ data, toggle, isMobile }) => {
  return (
    <>
      {isMobile && (
        <Box
          position={"fixed"}
          top={"0"}
          left={"0"}
          width={"100vw"}
          height={"100vh"}
          bgColor={Box.COLOR.SHADOW}
          zIndex={Box.LEVEL.ROOT}
          onClick={toggle}
        />
      )}
      <Box
        display={Box.DISPLAY.FLEX}
        jc="space-between"
        al="flex-start"
        wrap="wrap"
        //
        position={isMobile ? "fixed" : "absolute"}
        right={isMobile ? "calc((100% - 300px) / 2)" : "0"}
        top={isMobile ? "calc((100vh - 175px) / 2)" : "35px"}
        //
        width="300px"
        height="175px"
        padding={"20px 25px"}
        //
        border={"1px solid"}
        borderColor={Box.COLOR.SECONDARY}
        borderRadius="20px"
        bgColor={Box.COLOR.WHITE}
        zIndex={Box.LEVEL.ROOT}
      >
        {isMobile && (
          <>
            <Text
              as={Text.VARIANT.SUBTITLE}
              size={Text.SIZE.MOBILETITLE}
              weight={Text.WEIGHT.BOLD}
              textAlign={"center"}
              width={"100%"}
              margin={"0 0 16px"}
            >
              공유하기
            </Text>
            <Box position={"absolute"} top={"16px"} right={"16px"}>
              <Button themeStyle={Button.THEME.NONE} onClick={toggle}>
                <IoMdClose size={18} />
              </Button>
            </Box>
          </>
        )}
        {sns.map(({ titleList, image, event }) => (
          <Button
            width={"48px"}
            height={"auto"}
            fontSize={
              isMobile
                ? Button.FONT_SIZE.MOBILECONTENTDETAIL
                : Button.FONT_SIZE.CLIENTETC
            }
            padding={"0"}
            themeStyle={Button.THEME.NONE}
            onClick={() =>
              event({
                url: window.location.href,
                title: data.article.title,
                desc: data.article.summary,
              })
            }
            key={image}
          >
            <Image src={image} margin={"0 0 8px 0"} />
            {titleList.map((title) => (
              <Text
                key={title}
                as={Text.VARIANT.TEXT}
                color={Text.COLOR.TEXTGRAY}
              >
                {title}
              </Text>
            ))}
          </Button>
        ))}
        <Button
          themeStyle={Button.THEME.NONE}
          width={"100%"}
          height={"26px"}
          border={"1px solid"}
          borderColor={Box.COLOR.SECONDARY}
          borderRadius="20px"
          margin={"20px 0 0 0"}
          fontSize={
            isMobile
              ? Button.FONT_SIZE.MOBILECONTENTDETAIL
              : Button.FONT_SIZE.CLIENTETC
          }
          onClick={() => shareSns.copyLink({ url: window.location.href })}
        >
          <Box display={Box.DISPLAY.FLEX} al={"center"} height={"auto"}>
            <Text
              as={Text.VARIANT.SPAN}
              margin={"0 12px 0 10px"}
              width={"170px"}
              color={Text.COLOR.TEXTGRAY}
              lineClamp="1"
            >
              {window.location.href}
            </Text>
            <Text
              as={Text.VARIANT.SPAN}
              size={Text.SIZE}
              width={"calc(100% - 170px)"}
              borderLeft={"1px solid"}
              borderColor={Text.COLOR.LINEGRAY}
              padding={"0 10px 0 10px"}
              color={Text.COLOR.SECONDARY}
            >
              {share.link}
            </Text>
          </Box>
        </Button>
      </Box>
    </>
  );
};

export default ShareBox;
