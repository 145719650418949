import Box from "components/atoms/box";
import Text from "components/atoms/text";
import React from "react";
import convertDayWithTime from "utils/convertDayWithTime";
import HasNotResult from "../HasNotResult";
import useUrl from "hooks/utils/useUrl";

const StatusTable = (props) => {
  const { list, tableHeader } = props;

  const {
    query: { page },
  } = useUrl();

  const currPage = parseInt(page);

  return (
    <>
      <Box
        display={Box.DISPLAY.FLEX}
        height="47px"
        borderTop="1px solid"
        borderColor={Box.COLOR.LINEGRAY}
      >
        {tableHeader.map(({ title, width, ariaLabel }, idx) => (
          <Box
            display={title === "카테고리" ? Box.DISPLAY.BLOCK : Box.DISPLAY.FLEX}
            al="center"
            jc="center"
            width={width}
            padding={title === "카테고리" ? "0" : "8px 0"}
            key={idx}
            borderBottom={list.length === 0 ? "1px solid" : ""}
            borderRight={idx === tableHeader.length - 1 ? "1px solid" : ""}
            borderLeft="1px solid"
            borderColor={Box.COLOR.LINEGRAY}
            bgColor={Box.COLOR.ADMINCONTENTSBG}
          >
            {title === "카테고리" ? (
              <Box height="100%">
                <Box height="50%" display={Box.DISPLAY.FLEX} al="center">
                  <Text
                    as={Text.VARIANT.TEXT}
                    width="100%"
                    height="100%"
                    weight={Text.WEIGHT.BOLD}
                    size={Text.SIZE.CLIENTCONTENTDETAIL}
                    textAlign="center"
                    ariaLabel={ariaLabel}
                    borderBottom="1px solid"
                    borderColor={Box.COLOR.LINEGRAY}
                    lineHeight="2"
                  >
                    {title}
                  </Text>
                </Box>
                <Box display={Box.DISPLAY.FLEX} al="center" jc="center">
                  <Box
                    height="50%"
                    display={Box.DISPLAY.FLEX}
                    al="center"
                    jc="center"
                    flex="1 1 0"
                    borderRight="1px solid"
                    borderColor={Box.COLOR.LINEGRAY}
                  >
                    <Text
                      as={Text.VARIANT.TEXT}
                      weight={Text.WEIGHT.BOLD}
                      size={Text.SIZE.CLIENTCONTENTDETAIL}
                      textAlign="center"
                      lineHeight="2"
                      ariaLabel={ariaLabel}
                    >
                      1차
                    </Text>
                  </Box>
                  <Box height="50%" display={Box.DISPLAY.FLEX} al="center" jc="center" flex="1 1 0">
                    <Text
                      as={Text.VARIANT.TEXT}
                      weight={Text.WEIGHT.BOLD}
                      size={Text.SIZE.CLIENTCONTENTDETAIL}
                      textAlign="center"
                      lineHeight="2"
                      ariaLabel={ariaLabel}
                    >
                      2차
                    </Text>
                  </Box>
                </Box>
              </Box>
            ) : (
              <Text
                as={Text.VARIANT.TEXT}
                weight={Text.WEIGHT.BOLD}
                size={Text.SIZE.CLIENTCONTENTDETAIL}
                textAlign="center"
                ariaLabel={ariaLabel}
              >
                {title}
              </Text>
            )}
          </Box>
        ))}
      </Box>
      {list.length === 0 && <HasNotResult />}
      {list.length > 0 &&
        list.map(({ boardName, codeName, statusName, title, writer, regDate }, idx) => (
          <Box
            key={idx}
            borderTop={idx === 0 ? "1px solid" : ""}
            borderBottom="1px solid"
            borderColor={Box.COLOR.LINEGRAY}
            display={Box.DISPLAY.FLEX}
            height="53px"
          >
            <Box
              width="6%"
              display={Box.DISPLAY.FLEX}
              jc="center"
              al="center"
              borderLeft="1px solid"
              borderColor={Box.COLOR.LINEGRAY}
            >
              <Text
                as={Text.VARIANT.TEXT}
                size={Text.SIZE.CLIENTCONTENTDETAIL}
                textAlign="center"
                ariaLabel={`게시물 번호 : ${
                  currPage > 1 ? (currPage - 1) * 10 + (idx + 1) : idx + 1
                }`}
              >
                {currPage > 1 ? (currPage - 1) * 10 + (idx + 1) : idx + 1}
              </Text>
            </Box>
            <Box
              width="9%"
              display={Box.DISPLAY.FLEX}
              jc="center"
              al="center"
              padding="0 10px"
              wordBreak="break-all"
              borderLeft="1px solid"
              borderColor={Box.COLOR.LINEGRAY}
            >
              <Text
                as={Text.VARIANT.TEXT}
                size={Text.SIZE.CLIENTCONTENTDETAIL}
                textAlign="center"
                ariaLabel={boardName}
              >
                {boardName}
              </Text>
            </Box>
            <Box
              width="9%"
              display={Box.DISPLAY.FLEX}
              jc="center"
              al="center"
              padding="0 10px"
              wordBreak="break-all"
              borderLeft="1px solid"
              borderColor={Box.COLOR.LINEGRAY}
            >
              <Text
                as={Text.VARIANT.TEXT}
                size={Text.SIZE.CLIENTCONTENTDETAIL}
                textAlign="center"
                ariaLabel={codeName}
              >
                {codeName}
              </Text>
            </Box>
            <Box
              width="6%"
              display={Box.DISPLAY.FLEX}
              jc="center"
              al="center"
              borderLeft="1px solid"
              borderColor={Box.COLOR.LINEGRAY}
            >
              <Text
                as={Text.VARIANT.TEXT}
                size={Text.SIZE.CLIENTCONTENTDETAIL}
                textAlign="center"
                ariaLabel={statusName}
              >
                {statusName}
              </Text>
            </Box>
            <Box
              width="46%"
              display={Box.DISPLAY.FLEX}
              al="center"
              borderLeft="1px solid"
              borderColor={Box.COLOR.LINEGRAY}
              padding="0 10px"
            >
              <Text
                as={Text.VARIANT.TEXT}
                size={Text.SIZE.CLIENTCONTENTDETAIL}
                textAlign="left"
                lineHeight="1.2"
                ariaLabel={title}
              >
                {title}
              </Text>
            </Box>
            <Box
              width="10%"
              display={Box.DISPLAY.FLEX}
              jc="center"
              al="center"
              borderLeft="1px solid"
              borderColor={Box.COLOR.LINEGRAY}
            >
              <Text
                as={Text.VARIANT.TEXT}
                size={Text.SIZE.CLIENTCONTENTDETAIL}
                textAlign="center"
                ariaLabel={writer}
              >
                {writer}
              </Text>
            </Box>
            <Box
              width="14%"
              display={Box.DISPLAY.FLEX}
              jc="center"
              al="center"
              borderLeft="1px solid"
              borderRight="1px solid"
              borderColor={Box.COLOR.LINEGRAY}
            >
              <Text
                as={Text.VARIANT.TEXT}
                size={Text.SIZE.CLIENTCONTENTDETAIL}
                textAlign="center"
                ariaLabel={regDate}
              >
                {convertDayWithTime(regDate)}
              </Text>
            </Box>
          </Box>
        ))}
    </>
  );
};

export default StatusTable;
