import React from "react";
import { Calendar as AntdCalendar } from "antd";

const Calendar = (props) => {
  const {
    value,
    className,
    headerRender = () => {},
    cellRender = null,
    onChange,
    onSelect,
  } = props;
  const validDates = [
    value.clone().startOf("month"),
    value.clone().endOf("month"),
  ];

  return (
    <AntdCalendar
      className={className}
      value={value}
      validRange={validDates}
      fullscreen={false}
      headerRender={(...props) => headerRender(props[0])}
      cellRender={cellRender}
      onChange={onChange}
      onSelect={onSelect}
    />
  );
};

export default Calendar;
