import Image from "components/atoms/image";
import React from "react";

const SVG = (props) => {
  const { path, width, height = "auto" } = props;

  return <Image src={path} width={width} height={height} alt="svg" />;
};

export default SVG;
