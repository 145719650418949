import Box from "components/atoms/box";
import Loading from "components/atoms/loading";
import ImageUploader from "components/molecules/imageUploader";
import InputForm from "components/molecules/inputForm";
import AdminDetailForm from "components/organisms/adminDetailForm";
import AdminDetailFormContainer from "components/organisms/adminDetailFormContainer";
import LinkList from "components/organisms/linkList";
import { AdminContentsContainer } from "components/templates/admin/admin.style";
import {
  useNewsletterBannerDetail,
  useUpdateNewsletterBannerDetail,
} from "hooks/api/admin/news_letter";
import useUrl from "hooks/utils/useUrl";
import React, { useState } from "react";
import { common } from "text/admin/common";
import { newsletter } from "text/admin/newsletter";

const NewsletterBannerDetail = () => {
  const {
    query: { key },
  } = useUrl();

  const { defaultValues } = newsletter.banner.detail;
  const {
    default: { save },
  } = common.alert;

  const [isDisabled, setIsDisabled] = useState(false);

  const mutate = useUpdateNewsletterBannerDetail();

  const { list, isLoading } = useNewsletterBannerDetail({ key });

  const formInfo = [
    {
      title: "제목",
      minHeight: "48px",
      components: (
        <InputForm
          name="title"
          type="text"
          width="430px"
          height="32px"
          padding="0 12px"
          theme="secondary"
          defaultName={list?.title}
          required="필수값 입니다."
          ariaLabel="제목"
        />
      ),
      required: true,
    },
    {
      title: "이미지 등록",
      minHeight: "300px",
      components: (
        <ImageUploader
          registerName="image"
          comments={[
            "- 권장 이미지 :  826px*262px / 10mb 이하 / jpg,png 파일",
            "- 1장의 이미지만 등록 가능합니다.",
          ]}
          required={key ? false : "필수값 입니다."}
          defaultPath={list?.filePath ?? null}
          serverImgPath="newsletter"
        />
      ),
      required: key ? false : true,
      isUpperPosition: true,
    },
    {
      title: "링크",
      minHeight: isDisabled ? "auto" : "102px",
      components: (
        <LinkList
          radioName="isUsedLink"
          inputName="url"
          checkName="newWindow"
          defaultCheckValue={list?.newWindow ?? "N"}
          defaultLink={list?.url ?? ""}
          required={list?.url ? false : "필수값 입니다."}
          setIsDisabled={setIsDisabled}
        />
      ),
      required: true,
      isUpperPosition: true,
    },
  ];

  const handleSubmit = (data) => {
    const { image: file, newWindow, title, isUsedLink } = data;
    let { url } = data;

    const formData = new FormData();

    if (isUsedLink === "N") url = "";

    const param = { url, newWindow: newWindow === true ? "Y" : "N", title };

    formData.append("files", file);
    formData.append("param", JSON.stringify(param));

    const confirm = window.confirm(save);
    if (confirm) mutate({ key, formData });
  };

  if (isLoading) return <Loading />;

  return (
    <AdminContentsContainer>
      <Box maxWidth="1024px">
        <AdminDetailFormContainer
          title="배너 등록"
          defaultValues={defaultValues}
          handleSubmit={handleSubmit}
        >
          <AdminDetailForm formInfo={formInfo} />
        </AdminDetailFormContainer>
      </Box>
    </AdminContentsContainer>
  );
};

export default NewsletterBannerDetail;
