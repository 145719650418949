import Box from "components/atoms/box";
import Loading from "components/atoms/loading";
import ImageUploader from "components/molecules/imageUploader";
import InputForm from "components/molecules/inputForm";
import AdminDetailForm from "components/organisms/adminDetailForm";
import AdminDetailFormContainer from "components/organisms/adminDetailFormContainer";
import LinkList from "components/organisms/linkList";
import { AdminContentsContainer } from "components/templates/admin/admin.style";
import {
  useCreateSlideBanner,
  useSNSBannerDetail,
  useSiteBannerDetail,
  useUpdateSNSBanner,
  useUpdateSlideBanner,
} from "hooks/api/admin/management_site";
import usePendingMutate from "hooks/utils/usePendingMutate";
import useUrl from "hooks/utils/useUrl";
import React, { useState } from "react";
import useBannerStore from "store/useBannerStore";
import { site } from "text/admin/site";

const BannerDetail = () => {
  const {
    path,
    query: { category, key },
  } = useUrl();

  const categoryName = path[2];

  const { defaultValues } = site.banner.detail;

  const { isPending, handleMutate } = usePendingMutate();

  const [isDisabled, setIsDisabled] = useState(false);

  const { bannerType } = useBannerStore();

  const isSNSBanner = bannerType === "SNS03" || bannerType === "SNS04";

  const getApi = categoryName === "banner" ? useSNSBannerDetail : useSiteBannerDetail;
  const mutateApi = !key
    ? useCreateSlideBanner
    : isSNSBanner
    ? useUpdateSNSBanner
    : useUpdateSlideBanner;

  const mutate = mutateApi();

  const subTitle = {
    slide: key ? "메인 슬라이드(PC) 배너 수정" : "메인 슬라이드(PC) 배너 등록",
    middle: key ? "메인 중간(PC) 배너 수정" : "메인 중간(PC) 배너 등록",
    "slide-mobile": key ? "메인 슬라이드(Mobile) 배너 수정" : "메인 슬라이드(Mobile) 배너 등록",
    "middle-mobile": key ? "메인 중간(Mobile) 배너 수정" : "메인 중간(Mobile) 배너 등록",
    sns: key ? "SNS 배너 수정" : "SNS 배너 등록",
  };

  const recommendComment = {
    slide: [
      "- 권장 이미지 :  1540px*500px / 10mb 이하 / jpg,png 파일",
      "- 1장의 이미지만 등록 가능합니다.",
    ],
    middle: [
      "- 권장 이미지 :  1260px*130px / 10mb 이하 / jpg,png 파일",
      "- 1장의 이미지만 등록 가능합니다.",
    ],
    "slide-mobile": [
      "- 권장 이미지 :  375px*240px / 10mb 이하 / jpg,png 파일",
      "- 1장의 이미지만 등록 가능합니다.",
    ],
    "middle-mobile": [
      "- 권장 이미지 :  375px*106px / 10mb 이하 / jpg,png 파일",
      "- 1장의 이미지만 등록 가능합니다.",
    ],
    sns:
      bannerType === "SNS03"
        ? [
            "- 권장 이미지 :  413px*131px / 10mb 이하 / jpg,png 파일",
            "- 1장의 이미지만 등록 가능합니다.",
          ]
        : [
            "- 권장 이미지 :  307px*131px / 10mb 이하 / jpg,png 파일",
            "- 1장의 이미지만 등록 가능합니다.",
          ],
  };

  const { list, isLoading } = getApi({ category, key, snsForm: bannerType });

  const formInfo = [
    {
      title: "제목",
      minHeight: "48px",
      components: (
        <InputForm
          name="title"
          type="text"
          width="430px"
          height="32px"
          padding="0 12px"
          theme="secondary"
          defaultName={list?.title}
          required="필수값 입니다."
          ariaLabel="제목"
        />
      ),
      required: true,
    },
    {
      title: "이미지 등록",
      minHeight: "300px",
      components: (
        <ImageUploader
          registerName="image"
          comments={recommendComment[category]}
          required={key ? false : "필수값 입니다."}
          serverImgPath="attach"
          defaultPath={list?.filePath ?? null}
        />
      ),
      required: key ? false : true,
      isUpperPosition: true,
    },
    {
      title: "링크",
      minHeight: isDisabled ? "auto" : "102px",
      components: (
        <LinkList
          radioName="isUsedLink"
          inputName="url"
          checkName="newWindow"
          defaultCheckValue={list?.newWindow ?? "N"}
          defaultLink={list?.url ?? ""}
          required={list?.url ? false : "필수값 입니다."}
          setIsDisabled={setIsDisabled}
        />
      ),
      required: true,
      isUpperPosition: true,
    },
  ];

  const handleSubmit = (data) => {
    const { image: file, newWindow, title, isUsedLink } = data;
    let { url } = data;

    const formData = new FormData();

    if (isUsedLink === "N") url = "";

    const param = { url, newWindow: newWindow === true ? "Y" : "N", title };

    formData.append("files", file);
    formData.append("param", JSON.stringify(param));

    handleMutate(mutate, { categoryName: category, snsForm: bannerType, key, formData });
  };

  if (isLoading) return <Loading />;

  return (
    <AdminContentsContainer>
      <Box maxWidth="1024px">
        <AdminDetailFormContainer
          title={subTitle[category]}
          defaultValues={defaultValues}
          handleSubmit={handleSubmit}
          isPending={isPending}
        >
          <AdminDetailForm formInfo={formInfo} />
        </AdminDetailFormContainer>
      </Box>
    </AdminContentsContainer>
  );
};

export default BannerDetail;
