import Box from "components/atoms/box";
import Button from "components/atoms/button";
import Loading from "components/atoms/loading";
import Text from "components/atoms/text";
import { useDashboardArticleList } from "hooks/api/admin/dashboard";
import React from "react";
import { useState } from "react";
import { dashboard } from "text/admin/dashboard";

const DashboardRankList = () => {
  const [selectedMenu, setSelectedMenu] = useState("all");

  const { title, buttonList } = dashboard.hits;

  const { list, isLoading, isSuccess } = useDashboardArticleList({ boardName: selectedMenu });

  return (
    <Box flex="1 1 0">
      <Text as={Text.VARIANT.SUBTITLE} weight={Text.WEIGHT.BOLD} ariaLabel="방문자 수">
        {title}
      </Text>
      <Box
        height="418px"
        border="1px solid"
        borderColor={Box.COLOR.LINEGRAY}
        margin="8px 0 0 0"
        display={Box.DISPLAY.FLEX}
        flexDirection="column"
      >
        <Box
          display={Box.DISPLAY.FLEX}
          al="center"
          padding="15px 20px 0"
          borderBottom="1px solid"
          borderColor={Box.COLOR.LINEGRAY}
        >
          {buttonList.map(({ title, value, ariaLabel }, idx) => (
            <Button
              onClick={() => setSelectedMenu(value)}
              themeStyle="none"
              padding="10px 17px 20px"
              borderBottom={selectedMenu === value ? "2px solid" : "none"}
              borderColor={selectedMenu === value ? Button.COLOR.SECONDARY : ""}
              ariaLabel={ariaLabel}
              key={idx}
            >
              <Text
                as={Text.VARIANT.SUBTITLE}
                size={Text.SIZE.ADMINCONTENT}
                weight={selectedMenu === value ? Text.WEIGHT.BOLD : Text.WEIGHT.NORMAL}
                color={selectedMenu === value ? Text.COLOR.SECONDARY : Text.COLOR.BLACK}
              >
                {title}
              </Text>
            </Button>
          ))}
        </Box>
        <Box flex="1 1 0" display={Box.DISPLAY.FLEX} flexDirection="column" al="center" jc="center">
          {isLoading && <Loading />}

          {isSuccess &&
            list.map(({ boardName, title }, idx) => (
              <Box
                key={idx}
                width="457px"
                display={Box.DISPLAY.FLEX}
                al="center"
                padding="15px 0"
                margin="0 auto"
                borderBottom={list.length === idx + 1 ? "none" : "1px solid"}
                borderColor={Box.COLOR.LINEGRAY}
              >
                <Box margin="0 14px 0 0" width="20px">
                  <Text
                    as={Text.VARIANT.SUBTITLE}
                    size={Text.SIZE.ADMINCONTENT}
                    color={Text.COLOR.SECONDARY}
                  >
                    {`${idx + 1}위`}
                  </Text>
                </Box>
                <Box margin="0 5px 0 0">
                  <Text
                    as={Text.VARIANT.TEXT}
                    size={Text.SIZE.ADMINCONTENT}
                    color={Text.COLOR.TEXTGRAY}
                  >
                    {`[${boardName}]`}
                  </Text>
                </Box>
                <Box width="320px">
                  <Text
                    as={Text.VARIANT.TEXT}
                    size={Text.SIZE.ADMINCONTENT}
                    color={Text.COLOR.ADMINDARKGRAY}
                    lineClamp="1"
                  >
                    {title}
                  </Text>
                </Box>
              </Box>
            ))}
        </Box>
      </Box>
    </Box>
  );
};

export default DashboardRankList;
