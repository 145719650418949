import Box from "components/atoms/box";
import Button from "components/atoms/button";
import React from "react";
import { useChangeOrderStore } from "store/useChangeStore";

const ChangeOrderButton = (props) => {
  const { handleSaveOrder, list, setNewList } = props;

  const { isChangeOrderList, setIsChangeOrderList } = useChangeOrderStore();

  const handleChangeOrder = () => {
    setIsChangeOrderList(true);
  };

  const handleCancel = () => {
    setIsChangeOrderList(false);
    setNewList(list);
  };

  return (
    <>
      {isChangeOrderList ? (
        <Box display={Box.DISPLAY.FLEX} gap="12px">
          <Button themeStyle="adminCancel" padding="12px 40px" onClick={handleCancel}>
            취소
          </Button>
          <Button themeStyle="adminSave" padding="12px 40px" onClick={handleSaveOrder}>
            저장
          </Button>
        </Box>
      ) : (
        <Button
          padding="12px 40px"
          themeStyle="adminCommon"
          bgColor={Button.COLOR.WHITE}
          onClick={handleChangeOrder}
        >
          순서변경
        </Button>
      )}
    </>
  );
};

export default ChangeOrderButton;
