import styled, { css, keyframes } from "styled-components";

const sliderHeaderDown = keyframes`
  0% {
    height: 0;
    overflow: hidden;
  }
  100% {
    height: 90px;
    overflow: hidden;
  }
`;

const sliderHeaderUp = keyframes`
  0% {
    height: 90px;
    overflow: hidden;
  }
  100% {
    height: 0;
    overflow: hidden;
    opacity: 0;
  }
`;

const singleHeaderDown = keyframes`
  0% {
    height: 0;
    overflow: hidden;
  }
  100% {
    height: 60px;
    overflow: hidden;
  }
`;

const singleHeaderUp = keyframes`
  0% {
    height: 60px;

    overflow: hidden;
  }
  100% {
    height: 0;
    overflow: hidden;
    opacity: 0;
  }
`;

const theme = {
  textarea: css`
    border: 1px solid ${(props) => props.theme.color.lineGray};
    border-radius: ${(props) => props.theme.radius.normal};

    resize: none;

    &:hover {
      border-color: ${(props) => props.theme.color.secondary};
    }
    &:focus {
      border-color: ${(props) => props.theme.color.secondary};
      outline: none;
    }
  `,
  sliderHeaderDown: css`
    animation: ${sliderHeaderDown} 0.5s ease-in-out forwards;
  `,
  sliderHeaderUp: css`
    animation: ${sliderHeaderUp} 0.5s ease-in-out forwards;
  `,
  singleHeaderDown: css`
    animation: ${singleHeaderDown} 0.5s ease-in-out forwards;
  `,
  singleHeaderUp: css`
    animation: ${singleHeaderUp} 0.5s ease-in-out forwards;
  `,
  dropdown: css`
    @keyframes dropdown {
      0% {
        transform: translateY(-10%);
      }
      100% {
        transform: translateY(0);
      }
    }
    animation: dropdown 0.4s ease;
    display: ${(props) => props.$display};
  `,

  newsletterFormInput: css`
    @media (max-width: 300px) {
      padding: 5px;
    }
  `,

  newsletterFormBackground: css`
    @media (max-width: 300px) {
      height: 400px;

      img {
        height: 100%;
      }
    }
  `,

  sideMenu: css`
    height: 100vh;

    @supports (-webkit-touch-callout: none) {
      height: -webkit-fill-available;
    }
  `,
};

export const BoxComponent = styled.div`
  ${(props) => theme[props.themeStyle]};
  display: ${(props) => props.$display};
  flex-direction: ${(props) => props.flexDirection};
  justify-content: ${(props) => props.jc};
  align-items: ${(props) => props.al};
  flex-wrap: ${(props) => props.$wrap};
  gap: ${(props) => props.gap};
  flex: ${(props) => props.flex};

  overflow: ${(props) => props.$overflow};
  overflow-x: ${(props) => props.overflowX};
  overflow-y: ${(props) => props.overflowY};

  position: ${(props) => props.position};
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  right: ${(props) => props.right};
  bottom: ${(props) => props.bottom};

  z-index: ${(props) => props.zIndex};

  float: ${(props) => props.float};

  width: ${(props) => props.$width};
  max-width: ${(props) => props.maxWidth};
  min-width: ${(props) => props.minWidth};
  height: ${(props) => props.$height};
  min-height: ${(props) => props.minHeight};
  max-height: ${(props) => props.maxHeight};

  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};

  background-color: ${(props) => props.theme.color[props.bgColor]};
  background-image: ${(props) => props.bgImage && `url(${props.bgImage})`};
  background-repeat: ${(props) => props.bgRepeat};
  background-size: ${(props) => props.bgSize};

  border: ${(props) => props.border};
  border-top: ${(props) => props.borderTop};
  border-bottom: ${(props) => props.borderBottom};
  border-left: ${(props) => props.borderLeft};
  border-right: ${(props) => props.borderRight};

  border-radius: ${(props) =>
    props.theme.radius[props.borderRadius] ?? props.borderRadius};
  border-color: ${(props) => props.theme.color[props.borderColor]};
  border-collapse: ${(props) => props.borderCollapse};

  cursor: ${(props) => props.$cursor};

  aspect-ratio: ${(props) => props.aspectRatio};

  box-shadow: ${(props) => props.isShadow && props.theme.option.shadow};

  color: ${(props) => props.theme.color[props.$color]};

  grid-template-columns: ${(props) => props.gridColumns};
  font-size: ${(props) => props.isClearBlank && "0"};

  transform: ${(props) => props.$transform};

  opacity: ${(props) => props.$opacity};

  word-break: ${(props) => props.wordBreak};

  ${(props) =>
    props.as === "label" &&
    css`
      cursor: pointer;
    `}

  ${(props) =>
    props.isActiveMenu &&
    css`
      background-color: ${props.theme.color.secondary};
      button {
        color: ${props.theme.color.white};
        p {
          font-weight: 700;
        }
      }
    `}

  ${(props) =>
    props.isNotActiveMenu &&
    css`
      &:hover {
        background-color: #b9c4e7;
      }
    `}

  ${(props) =>
    props.isActiveDropdownMenu &&
    css`
      button {
        color: ${props.theme.color.secondary};
        p {
          font-weight: 700;
        }
      }
    `}
`;
