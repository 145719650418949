import {
  createCoverage,
  deleteCoverage,
  getScienceCoverageDetail,
  getScienceCoverageList,
  updateCoverageInfo,
} from "api/admin/coverage";
import useHandleAlert from "hooks/utils/useHandleAlert";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { common } from "text/admin/common";

const {
  success: { create, remove, update },
} = common.alert;

// 과총동정 목록
export const useScienceCoverageList = ({
  page,
  itemsPerPage,
  searchCondition,
  searchValue,
  dateCondition,
  startDate,
  endDate,
}) => {
  const { data, isLoading, isSuccess, isError } = useQuery(
    [
      "coverage",
      page,
      itemsPerPage,
      searchCondition,
      searchValue,
      dateCondition,
      startDate,
      endDate,
    ],
    () =>
      getScienceCoverageList({
        page,
        itemsPerPage,
        searchCondition,
        searchValue,
        dateCondition,
        startDate,
        endDate,
      })
  );

  const list = data?.data.list;
  const totalCnt = data?.data.totalCnt;
  const totalPageIndex = data?.data.totalPageIndex;

  return {
    list,
    totalCnt,
    totalPageIndex,
    isLoading,
    isSuccess,
    isError,
  };
};

// 과총동정 상세
export const useScienceCoverageDetail = ({ key }) => {
  const { data, isLoading, isSuccess, isError } = useQuery({
    queryKey: ["coverageDetail", key],
    queryFn: () => getScienceCoverageDetail({ key }),
    enabled: !!key,
  });

  const list = data?.data.adminCoverageDetail;
  const fileList = data?.data.adminCoverageFileList;

  return {
    list,
    fileList,
    isLoading,
    isSuccess,
    isError,
  };
};

// 과총동정 등록
export const useCreateCoverage = (successCallback) => {
  const { handleGoBack } = useHandleAlert();
  const queryClient = useQueryClient();
  const createCoverageMutation = useMutation(createCoverage, {
    onSuccess: () => {
      alert(create);
      queryClient.invalidateQueries("coverage");
      successCallback();
      handleGoBack();
    },
  });

  const mutate = ({ data }) => {
    createCoverageMutation.mutate({ data });
  };

  return mutate;
};

// 과총동정 수정
export const useUpdateCoverageInfo = (successCallback) => {
  const { handleGoBack } = useHandleAlert();
  const queryClient = useQueryClient();
  const updateCoverageInfoMutation = useMutation(updateCoverageInfo, {
    onSuccess: () => {
      alert(update);
      queryClient.invalidateQueries("coverage");
      successCallback();
      handleGoBack();
    },
  });

  const mutate = ({ key, data }) => {
    updateCoverageInfoMutation.mutate({ key, data });
  };

  return mutate;
};

// 과총동정 삭제
export const useDeleteCoverage = (reset) => {
  const queryClient = useQueryClient();
  const deleteCoverageMutation = useMutation(deleteCoverage, {
    onSuccess: () => {
      alert(remove);
      queryClient.invalidateQueries("coverage");
      reset();
    },
  });

  const mutate = ({ data }) => {
    deleteCoverageMutation.mutate({ data });
  };

  return mutate;
};
