import {
  getDashboardArticleList,
  getDashboardMembership,
  getMyInfo,
  getTodayVisitors,
  updateMyInfo,
} from "api/admin/dashboard";
import useHandleAlert from "hooks/utils/useHandleAlert";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { common } from "text/admin/common";

const {
  success: { update },
} = common.alert;

// 회원 현황
export const useDashboardMembership = () => {
  const { data, isLoading, isSuccess, isError } = useQuery(["membership"], () =>
    getDashboardMembership()
  );

  const list = data?.data;

  return {
    list,
    isLoading,
    isSuccess,
    isError,
  };
};

// 게시글 조회수 순위
export const useDashboardArticleList = ({ boardName }) => {
  const { data, isLoading, isSuccess, isError } = useQuery(["articleList", boardName], () =>
    getDashboardArticleList(boardName)
  );

  const list = data?.data.list;

  return {
    list,
    isLoading,
    isSuccess,
    isError,
  };
};

// 내정보 조회
export const useMyInfo = () => {
  const { data, isLoading, isSuccess, isError } = useQuery(["myInfo"], () => getMyInfo());

  const list = data?.data?.userInfo;

  return {
    list,
    isLoading,
    isSuccess,
    isError,
  };
};

// 내정보 수정
export const useUpdateMyInfo = () => {
  const { handleGoBack } = useHandleAlert();
  const queryClient = useQueryClient();
  const updateMyInfoMutation = useMutation(updateMyInfo, {
    onSuccess: () => {
      alert(update);
      queryClient.invalidateQueries("myInfo");
      handleGoBack();
    },
  });

  const mutate = ({ data }) => {
    updateMyInfoMutation.mutate({ data });
  };

  return mutate;
};

// 일별/월별 방문자 수
export const useTodayVisiorsCount = ({ type = "today" }) => {
  const { data, isLoading, isSuccess, isError } = useQuery(["todayVisitors", type], () =>
    getTodayVisitors({ type })
  );

  return {
    list: data,
    isLoading,
    isSuccess,
    isError,
  };
};
