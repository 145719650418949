import Box from "components/atoms/box";
import Button from "components/atoms/button";
import Link from "components/atoms/link";
import Text from "components/atoms/text";
import useUrl from "hooks/utils/useUrl";
import React from "react";
import { AiOutlineDoubleLeft } from "@react-icons/all-files/ai/AiOutlineDoubleLeft";
import { AiOutlineDoubleRight } from "@react-icons/all-files/ai/AiOutlineDoubleRight";
import { FiChevronLeft } from "@react-icons/all-files/fi/FiChevronLeft";
import { FiChevronRight } from "@react-icons/all-files/fi/FiChevronRight";
import { useNavigate } from "react-router-dom";

const Page = (props) => {
  const { pages, page, totalPage, hash } = props;
  const { query: queries } = useUrl();
  const router = useNavigate();

  const pageBlock = pages.find((p) => p.includes(page));

  const nextPage = () => {
    if (page === totalPage) return;

    const query = {
      ...queries,
      page: page + 1,
    };

    router({ search: new URLSearchParams(query).toString(), hash });
  };

  const beforePage = () => {
    if (page === 1) return;

    const query = {
      ...queries,
      page: page - 1,
    };

    router({ search: new URLSearchParams(query).toString(), hash });
  };

  const firstPage = () => {
    const query = {
      ...queries,
      page: 1,
    };

    router({ search: new URLSearchParams(query).toString(), hash });
  };

  const lastPage = () => {
    const query = {
      ...queries,
      page: totalPage,
    };

    router({ search: new URLSearchParams(query).toString(), hash });
  };

  if (!pageBlock) return null;

  return (
    <Box display={Box.DISPLAY.FLEX} jc="center" al="center" width="100%">
      <Button
        width={"14px"}
        height={"14px"}
        themeStyle={Button.THEME.NONE}
        margin={"0 10px"}
        ariaLabel="first-page-button"
        onClick={firstPage}
      >
        <AiOutlineDoubleLeft size={14} />
      </Button>

      <Button
        width={"15px"}
        height={"15px"}
        themeStyle={Button.THEME.NONE}
        margin={"0 10px"}
        ariaLabel="before-page-button"
        onClick={beforePage}
      >
        <FiChevronLeft size={15} />
      </Button>

      {pageBlock.map((p) => {
        const query = {
          ...queries,
          page: p,
        };

        const isActive = page === p;

        return (
          <Link
            key={p}
            margin="0 17.5px"
            link={`?${new URLSearchParams(query).toString()}#${hash}`}
          >
            <Text
              size={Text.SIZE.CLIENTCONTENTDETAIL}
              weight={isActive ? Text.WEIGHT.BOLD : Text.WEIGHT.NORMAL}
              color={isActive ? Text.COLOR.SECONDARY : Text.COLOR.TEXTGRAY}
            >
              {p}
            </Text>
          </Link>
        );
      })}

      <Button
        width={"15px"}
        height={"15px"}
        themeStyle={Button.THEME.NONE}
        margin={"0 10px"}
        ariaLabel="next-page-button"
        onClick={nextPage}
      >
        <FiChevronRight size={15} />
      </Button>

      <Button
        width={"14px"}
        height={"14px"}
        themeStyle={Button.THEME.NONE}
        margin={"0 10px"}
        ariaLabel="last-page-button"
        onClick={lastPage}
      >
        <AiOutlineDoubleRight size={14} />
      </Button>
    </Box>
  );
};

export default Page;
