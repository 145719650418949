import dayjs from "dayjs";
import { create } from "zustand";

const usePopupStore = create((set) => ({
  selectedStartDate: dayjs(),
  selectedEndDate: dayjs(),
  selectedStartHours: "00",
  selectedStartMinutes: "00",
  selectedEndHours: "00",
  selectedEndMinutes: "00",

  setSelectedStartDate: (selectedStartDate) => set({ selectedStartDate }),
  setSelectedEndDate: (selectedEndDate) => set({ selectedEndDate }),
  setSelectedStartHours: (selectedStartHours) => set({ selectedStartHours }),
  setSelectedEndHours: (selectedEndHours) => set({ selectedEndHours }),
  setSelectedStartMinutes: (selectedStartMinutes) => set({ selectedStartMinutes }),
  setSelectedEndMinutes: (selectedEndMinutes) => set({ selectedEndMinutes }),

  reset: () => {
    set({
      selectedStartDate: dayjs(),
      selectedEndDate: dayjs(),
      selectedStartHours: "00",
      selectedStartMinutes: "00",
      selectedEndHours: "00",
      selectedEndMinutes: "00",
    });
  },
}));

export default usePopupStore;
