import { getScienceSchedule } from "api/schedule";
import { useEffect } from "react";
import { useQuery, useQueryClient } from "react-query";

export const useScienceSchedule = (date) => {
  const {
    data: scienceSchedule = {},
    isLoading: isScienceScheduleLoading,
    isSuccess: isScienceScheduleSuccess,
    isError: isScienceScheduleError,
  } = useQuery(["scienceSchedule", date], () => getScienceSchedule(date), {
    retry: false,
  });

  return {
    scienceSchedule,
    isScienceScheduleLoading,
    isScienceScheduleSuccess,
    isScienceScheduleError,
  };
};

export const usePreFetchSchedule = (date) => {
  const queryClient = useQueryClient();

  useEffect(() => {
    const preMonth = date.subtract(1, "month").format("YYYYMM");
    const nextMonth = date.add(1, "month").format("YYYYMM");

    queryClient.prefetchQuery(["scienceSchedule", preMonth], () =>
      getScienceSchedule(preMonth)
    );
    queryClient.prefetchQuery(["scienceSchedule", nextMonth], () =>
      getScienceSchedule(nextMonth)
    );
  }, [date, queryClient]);
};
