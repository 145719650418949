const generateDownloadLink = (files) => {
  if (!files) return;
  const donwloadLinkList = [];

  files.forEach((file) => {
    const { fileName, filePath, fileExtsn, originFileName, fileSize, fileId, fileSN } = file;
    const downloadUrl = `${filePath}${fileName}.${fileExtsn}`;
    donwloadLinkList.push({
      name: originFileName,
      url: downloadUrl,
      size: fileSize,
      id: fileId,
      fileSN,
    });
  });

  return donwloadLinkList;
};

export default generateDownloadLink;
