import {
  copyNewsletterHtml,
  createNewsletter,
  deleteNewsletter,
  deleteNewsletterApplier,
  deleteNewsletterRejectList,
  getApplierList,
  getNewsletterAutoDelivery,
  getNewsletterBannerDetail,
  getNewsletterBannerList,
  getNewsletterDetailList,
  getNewsletterList,
  getNewsletterRejectList,
  updateNewsletter,
  updateNewsletterAutoDelivery,
  updateNewsletterBanner,
  updateNewsletterBannerOrder,
  updateNewsletterRejection,
} from "api/admin/news_letter";
import useHandleAlert from "hooks/utils/useHandleAlert";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useChangeOrderStore } from "store/useChangeStore";
import { common } from "text/admin/common";

const {
  success: { create, order, remove, reject, update },
  updateBanner: { success },
} = common.alert;

// 뉴스레터 목록
export const useNewsLetterList = ({ page, itemsPerPage, searchCondition, searchValue }) => {
  const { data, isLoading, isSuccess, isError } = useQuery(
    ["newsLetterList", page, itemsPerPage, searchCondition, searchValue],
    () =>
      getNewsletterList({
        page,
        itemsPerPage,
        searchCondition,
        searchValue,
      })
  );

  const list = data?.data.list;
  const totalCnt = data?.data.totalCnt;
  const totalPageIndex = data?.data.totalPageIndex;

  return {
    list,
    totalCnt,
    totalPageIndex,
    isLoading,
    isSuccess,
    isError,
  };
};

// 뉴스레터 상세 목록
export const useNewsLetterDetailList = ({ key }) => {
  const { data, isLoading, isSuccess, isError } = useQuery({
    queryKey: ["newsLetterDetailList", key],
    queryFn: () => getNewsletterDetailList({ key }),
    enabled: !!key,
  });

  const list = data?.data.resultDto;
  const totalCnt = data?.data.totalCnt;
  const totalPageIndex = data?.data.totalPageIndex;
  const fileList = data?.data.adminArticleFileList;

  return {
    list,
    totalCnt,
    totalPageIndex,
    fileList,
    isLoading,
    isSuccess,
    isError,
  };
};

// 뉴스레터 등록
export const useCreateNewsletter = (setIsChangeOrderList) => {
  const { handleGoBack } = useHandleAlert();
  const queryClient = useQueryClient();
  const createNewsletterMutation = useMutation(createNewsletter, {
    onSuccess: () => {
      alert(create);
      queryClient.invalidateQueries(["newsLetterList", "newsLetterDetailList"]);
      setIsChangeOrderList(false);
      handleGoBack();
    },
  });

  const mutate = ({ data }) => {
    createNewsletterMutation.mutate({ data });
  };

  return mutate;
};

// 뉴스레터 수정
export const useUpdateNewsletter = (setIsChangeOrderList) => {
  const { handleGoBack } = useHandleAlert();
  const queryClient = useQueryClient();
  const updateNewsletterMutation = useMutation(updateNewsletter, {
    onSuccess: () => {
      alert(update);
      queryClient.invalidateQueries(["newsLetterList", "newsLetterDetailList"]);
      setIsChangeOrderList(false);
      handleGoBack();
    },
  });

  const mutate = ({ data, key }) => {
    updateNewsletterMutation.mutate({ data, key });
  };

  return mutate;
};

// 뉴스레터 삭제
export const useDeleteNewsletter = (reset) => {
  const queryClient = useQueryClient();
  const deleteNewsletterMutation = useMutation(deleteNewsletter, {
    onSuccess: () => {
      alert(remove);
      queryClient.invalidateQueries("newsLetterList");
      reset();
    },
  });

  const mutate = ({ data }) => {
    deleteNewsletterMutation.mutate({ data });
  };

  return mutate;
};

// 뉴스레터 신청자 목록
export const useApplierList = ({ page, itemsPerPage, searchCondition, searchValue }) => {
  const { data, isLoading, isSuccess, isError } = useQuery(
    ["applierList", page, itemsPerPage, searchCondition, searchValue],
    () =>
      getApplierList({
        page,
        itemsPerPage,
        searchCondition,
        searchValue,
      })
  );

  const list = data?.data.list;
  const totalCnt = data?.data.totalCnt;
  const totalPageIndex = data?.data.totalPageIndex;

  return {
    list,
    totalCnt,
    totalPageIndex,
    isLoading,
    isSuccess,
    isError,
  };
};

// 뉴스레터 신청자 목록 삭제
export const useDeleteNewsletterApplier = (reset) => {
  const queryClient = useQueryClient();
  const deleteNewsletterMutation = useMutation(deleteNewsletterApplier, {
    onSuccess: () => {
      alert(remove);
      queryClient.invalidateQueries("applierList");
      reset();
    },
  });

  const mutate = ({ data }) => {
    deleteNewsletterMutation.mutate({ data });
  };

  return mutate;
};

// 뉴스레터 거부/변경 목록
export const useNewsletterRejectList = ({ page, itemsPerPage, searchCondition, searchValue }) => {
  const { data, isLoading, isSuccess, isError } = useQuery(
    ["newsletterRejectList", page, itemsPerPage, searchCondition, searchValue],
    () =>
      getNewsletterRejectList({
        page,
        itemsPerPage,
        searchCondition,
        searchValue,
      })
  );

  const list = data?.data.list;
  const totalCnt = data?.data.totalCnt;
  const totalPageIndex = data?.data.totalPageIndex;

  return {
    list,
    totalCnt,
    totalPageIndex,
    isLoading,
    isSuccess,
    isError,
  };
};

// 뉴스레터 신청자 목록 삭제
export const useDeleteNewsletterRejectList = (reset) => {
  const queryClient = useQueryClient();
  const deleteNewsletterMutation = useMutation(deleteNewsletterRejectList, {
    onSuccess: () => {
      alert(remove);
      queryClient.invalidateQueries("newsletterRejectList");
      reset();
    },
  });

  const mutate = ({ data }) => {
    deleteNewsletterMutation.mutate({ data });
  };

  return mutate;
};

// 뉴스레터 신청 거부/변경 거부 철회
export const useUpdateNewsletterRejection = (reset) => {
  const queryClient = useQueryClient();
  const updateNewsletterMutation = useMutation(updateNewsletterRejection, {
    onSuccess: () => {
      alert(reject);
      queryClient.invalidateQueries("newsletterRejectList");
      reset();
    },
  });

  const mutate = ({ data }) => {
    updateNewsletterMutation.mutate({ data });
  };

  return mutate;
};

// 하단 배너 관리
export const useNewsletterBannerList = () => {
  const { data, isLoading, isSuccess, isError } = useQuery(["newsletterBanner"], () =>
    getNewsletterBannerList()
  );

  const list = data?.data.list;
  const totalCnt = data?.data.totalCnt;
  const totalPageIndex = data?.data.totalPageIndex;

  return {
    list,
    totalCnt,
    totalPageIndex,
    isLoading,
    isSuccess,
    isError,
  };
};

// 하단 배너 상세
export const useNewsletterBannerDetail = ({ key }) => {
  const { data, isLoading, isSuccess, isError } = useQuery({
    queryKey: ["newsletterBannerDetail", key],
    queryFn: () => getNewsletterBannerDetail({ key }),
    enabled: !!key,
  });

  const list = data?.data.bannerDetail;

  return {
    list,
    isLoading,
    isSuccess,
    isError,
  };
};

// 하단 배너 수정
export const useUpdateNewsletterBannerDetail = () => {
  const { handleGoBack } = useHandleAlert();
  const queryClient = useQueryClient();
  const updateBannerDetailMutation = useMutation(updateNewsletterBanner, {
    onSuccess: () => {
      alert(success);
      queryClient.invalidateQueries("newsletterBanner");
      handleGoBack();
    },
  });

  const mutate = ({ key, formData }) => {
    updateBannerDetailMutation.mutate({ key, formData });
  };

  return mutate;
};

// 하단 배너 순서 변경
export const useUpdateNewsletterBannerOrder = () => {
  const { setIsChangeOrderList } = useChangeOrderStore();
  const queryClient = useQueryClient();
  const updateBannerOrderMutation = useMutation(updateNewsletterBannerOrder, {
    onSuccess: () => {
      alert(order);
      queryClient.invalidateQueries("newsletterBanner");
      setIsChangeOrderList(false);
    },
  });

  const mutate = ({ data }) => {
    updateBannerOrderMutation.mutate({ data });
  };

  return mutate;
};

// 뉴스레터 자동전달 설정
export const useGetNewsletterAutoDelivery = () => {
  const { data, isLoading, isSuccess, isError } = useQuery("autoDelivery", () =>
    getNewsletterAutoDelivery()
  );

  const autoDeliveryState = data?.data.onoff;

  return {
    autoDeliveryState,
    isLoading,
    isSuccess,
    isError,
  };
};

// 뉴스레터 자동 전달
export const useUpdateNewsletterAutoDelivery = () => {
  const queryClient = useQueryClient();
  const updateNewsletterAutoDeliveryMutation = useMutation(updateNewsletterAutoDelivery, {
    onSuccess: () => {
      alert(update);
      queryClient.invalidateQueries("autoDelivery");
    },
  });

  const mutate = ({ data }) => {
    updateNewsletterAutoDeliveryMutation.mutate({ data });
  };

  return mutate;
};

// 뉴스레터 HTML 코드 복사
export const useCopyNewsletterHtml = ({ key }) => {
  const { data, isLoading, isSuccess, isError } = useQuery({
    queryKey: ["newsletterHtml", key],
    queryFn: () => copyNewsletterHtml({ key }),
    enabled: !!key,
  });

  return {
    html: data,
    isLoading,
    isSuccess,
    isError,
  };
};
