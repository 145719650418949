import Box from "components/atoms/box";
import Button from "components/atoms/button";
import Text from "components/atoms/text";
import { useRejectRequestNewsletterEmailMutation } from "hooks/api/newsletter";
import useUrl from "hooks/utils/useUrl";
import React from "react";
import { useState } from "react";
import { BiChevronRight } from "@react-icons/all-files/bi/BiChevronRight";
import useMobileStore from "store/useMobileStore";
import unsubscribeText from "text/unsubscribe";

const { success, fail, pending } = unsubscribeText;

const Unsubscribe = () => {
  const { isMobile } = useMobileStore();
  const {
    query: { key = "" },
  } = useUrl();

  const [request, setRequest] = useState("pending");

  const handleSuccessReject = () => {
    setRequest("success");
  };

  const handleFailReject = () => {
    setRequest("fail");
  };

  const rejectRequestNewsletterMutation =
    useRejectRequestNewsletterEmailMutation(
      handleSuccessReject,
      handleFailReject
    );

  return (
    <Box
      display={Box.DISPLAY.FLEX}
      flexDirection={"column"}
      al={"center"}
      jc={"center"}
      width={"100vw"}
      height={"100vh"}
    >
      {request === "success" && (
        <>
          <Text
            as={Text.VARIANT.TITLE}
            size={isMobile ? Text.SIZE.MOBILETITLE : Text.SIZE.CLIENTTITLE}
            margin={"0 0 30px"}
          >
            {success.title}
          </Text>

          <Text
            as={Text.VARIANT.TEXT}
            size={
              isMobile ? Text.SIZE.MOBILECATEGORY : Text.SIZE.CLIENTCATEGORY
            }
            color={Text.COLOR.TEXTGRAY}
            lineHeight={isMobile ? "20px" : "35px"}
          >
            {success.content.common[0]}
            <Text as={Text.VARIANT.SPAN} weight={Text.WEIGHT.BOLD} isSort>
              {success.content.bold[0]} <BiChevronRight />{" "}
              {success.content.bold[1]} 신청
            </Text>
            {success.content.common[1]}
          </Text>

          <Text
            as={Text.VARIANT.TEXT}
            size={
              isMobile ? Text.SIZE.MOBILECATEGORY : Text.SIZE.CLIENTCATEGORY
            }
            color={Text.COLOR.TEXTGRAY}
            lineHeight={isMobile ? "20px" : "35px"}
          >
            {success.content.common[2]}
          </Text>
        </>
      )}
      {request === "fail" && (
        <>
          <Text
            as={Text.VARIANT.TITLE}
            size={isMobile ? Text.SIZE.MOBILETITLE : Text.SIZE.CLIENTTITLE}
            margin={"0 0 30px"}
          >
            {fail.title}
          </Text>

          <Text
            as={Text.VARIANT.TEXT}
            size={
              isMobile ? Text.SIZE.MOBILECATEGORY : Text.SIZE.CLIENTCATEGORY
            }
            color={Text.COLOR.TEXTGRAY}
            lineHeight={isMobile ? "20px" : "35px"}
          >
            {fail.content.common[0]}
            <Text as={Text.VARIANT.SPAN} weight={Text.WEIGHT.BOLD} isSort>
              {fail.content.bold[0]} <BiChevronRight /> {fail.content.bold[1]}
            </Text>
            {fail.content.common[1]}
          </Text>

          <Text
            as={Text.VARIANT.TEXT}
            size={
              isMobile ? Text.SIZE.MOBILECATEGORY : Text.SIZE.CLIENTCATEGORY
            }
            color={Text.COLOR.TEXTGRAY}
            lineHeight={isMobile ? "20px" : "35px"}
          >
            {fail.content.common[2]}
          </Text>
        </>
      )}
      {request === "pending" && (
        <>
          <Text
            as={Text.VARIANT.TITLE}
            size={isMobile ? Text.SIZE.MOBILETITLE : Text.SIZE.CLIENTTITLE}
            margin={"0 0 30px"}
          >
            {pending.title}
          </Text>

          <Text
            as={Text.VARIANT.TEXT}
            size={
              isMobile ? Text.SIZE.MOBILECATEGORY : Text.SIZE.CLIENTCATEGORY
            }
            color={Text.COLOR.TEXTGRAY}
            lineHeight={isMobile ? "20px" : "35px"}
          >
            {pending.content.common[0]}
            <Text as={Text.VARIANT.SPAN} weight={Text.WEIGHT.BOLD} isSort>
              {pending.content.bold[0]} <BiChevronRight />{" "}
              {pending.content.bold[1]}
            </Text>
            {pending.content.common[1]}
          </Text>

          <Text
            as={Text.VARIANT.TEXT}
            size={
              isMobile ? Text.SIZE.MOBILECATEGORY : Text.SIZE.CLIENTCATEGORY
            }
            color={Text.COLOR.TEXTGRAY}
            lineHeight={isMobile ? "20px" : "35px"}
          >
            {pending.content.common[2]}
          </Text>

          <Button
            themeStyle={Button.THEME.NONE}
            padding={isMobile ? "10px 30px" : "12px 53px"}
            color={Button.COLOR.WHITE}
            bgColor={Button.COLOR.SECONDARY}
            borderRadius={"40px"}
            fontSize={
              isMobile
                ? Button.FONT_SIZE.MOBILECONTENT
                : Button.FONT_SIZE.CLIENTNORMAL
            }
            margin={"60px 0 0"}
            onClick={() => rejectRequestNewsletterMutation({ key })}
          >
            {pending.submit}
          </Button>
        </>
      )}
    </Box>
  );
};

export default Unsubscribe;
