import Box from "components/atoms/box";
import SelectMenu from "../selectMenu";
import React from "react";

const SelectTwoDepthMenu = (props) => {
  const {
    depth1Menu,
    setDepth1Menu,
    depth2Menu,
    setDepth2Menu,
    depth1Options,
    depth2Options,
    width = "104px",
  } = props;

  return (
    <Box>
      <Box display={Box.DISPLAY.FLEX} al="center" gap="5px">
        <SelectMenu
          width={width}
          selectedMenu={depth1Menu}
          setSelectedMenu={setDepth1Menu}
          options={depth1Options}
          registerName="cate1"
        />
        {depth1Menu && (
          <SelectMenu
            width="50%"
            selectedMenu={depth2Menu}
            setSelectedMenu={setDepth2Menu}
            options={depth2Options}
            registerName="cate2"
          />
        )}
      </Box>
    </Box>
  );
};

export default SelectTwoDepthMenu;
