import { getSearchNews } from "api/search";
import { useQuery } from "react-query";

export const useSearchNews = ({ searchKeyword, getDate, page, limit }) => {
  const { data, isLoading, isError, isSuccess } = useQuery(
    ["search", searchKeyword, getDate, page, limit],
    () => getSearchNews(searchKeyword, getDate, page, limit)
  );

  const list = data?.data.list;
  const totalCnt = data?.data.totalCnt;
  const totalPageIndex = data?.data.totalPageIndex;
  const listviewBtn = data?.data.listviewBtn;

  return {
    list,
    totalCnt,
    totalPageIndex,
    listviewBtn,
    isLoading,
    isError,
    isSuccess,
  };
};
