import Box from "components/atoms/box";
import Button from "components/atoms/button";
import Image from "components/atoms/image";
import Input from "components/atoms/input";
import Text from "components/atoms/text";
import { useFindIdMutation } from "hooks/api/auth";
import usePattern from "hooks/utils/usePattern";
import useUrl from "hooks/utils/useUrl";
import React from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { AiOutlineCheck } from "@react-icons/all-files/ai/AiOutlineCheck";
import findIdText from "text/findId";

const { success, fail, pending } = findIdText;

const MobileFindId = () => {
  const { changeLink } = useUrl();
  const { register, watch, setValue, reset, handleSubmit } = useForm({
    defaultValues: {
      name: "",
      phone: "",
    },
  });
  const pattern = usePattern();
  const [request, setRequest] = useState("pending");
  const [userInfo, setUserInfo] = useState([]);

  const handleSuccessFindId = (info) => {
    setRequest("success");
    setUserInfo(info);
  };

  const handleFailFindId = () => {
    setRequest("fail");
  };

  const findIdMutation = useFindIdMutation(
    handleSuccessFindId,
    handleFailFindId
  );

  const handleFail = () => {
    setRequest("pending");
    reset();
  };

  const handleSubmitFindId = (data) => {
    const { name, phone } = data;
    const [phone1, phone2, phone3] = phone.split("-");

    findIdMutation({
      name,
      phone1,
      phone2,
      phone3,
    });
  };

  const isActive =
    watch("name").trim() !== "" &&
    watch("phone").trim() !== "" &&
    pattern.call.value.test(watch("phone"));

  const handleChangeId = (userId) => {
    const [id, email] = userId.split("@");

    const idLength = id.length;
    const emailLength = email.length;

    return `${id.slice(0, 3).padEnd(idLength, "*")}@${email
      .slice(0, 3)
      .padEnd(emailLength, "*")}`;
  };

  return (
    <Box
      margin={"167px 0 300px"}
      padding={"0 62px"}
      width={"100%"}
      display={Box.DISPLAY.FLEX}
      flexDirection={"column"}
      al="center"
    >
      {request === "success" && (
        <>
          <Image src={"/assets/auth/checked.svg"} width={"20px"} />
          <Text
            as={Text.VARIANT.SUBTITLE}
            size={Text.SIZE.MOBILETITLE}
            weight={Text.WEIGHT.BOLD}
            color={Text.COLOR.SECONDARY}
            textAlign={"center"}
            margin={"14px 0 30px"}
          >
            {success.title}
          </Text>
          {userInfo.map(({ userId, regDate }, idx) => (
            <Text
              as={Text.VARIANT.TEXT}
              size={Text.SIZE.MOBILEETC}
              margin={"0 0 35px"}
              isSort
              key={userId}
            >
              <Text
                as={Text.VARIANT.SPAN}
                color={Text.COLOR.SECONDARY}
                margin={"0 4px 0 0"}
              >
                <AiOutlineCheck size={16} />
              </Text>
              {handleChangeId(userId)} ({regDate} {success.info.unit})
            </Text>
          ))}

          <Button
            width={"252px"}
            padding={"10px 0"}
            margin={"0 0 10px"}
            border={"1px solid"}
            borderColor={Button.COLOR.SECONDARY}
            borderRadius={"21px"}
            bgColor={Button.COLOR.SECONDARY}
            color={Button.COLOR.WHITE}
            fontSize={Button.FONT_SIZE.MOBILECONTENT}
            fontWeight={Button.FONT_WEIGHT.BOLD}
            onClick={() => changeLink("/login")}
          >
            {success.submit.login}
          </Button>
          <Button
            width={"252px"}
            padding={"10px 0"}
            border={"1px solid"}
            borderColor={Button.COLOR.LINEGRAY}
            borderRadius={"21px"}
            bgColor={Button.COLOR.LINEGRAY}
            color={Button.COLOR.WHITE}
            fontSize={Button.FONT_SIZE.MOBILECONTENT}
            fontWeight={Button.FONT_WEIGHT.BOLD}
            onClick={() => changeLink("/find/pw")}
          >
            {success.submit.findPw}
          </Button>
        </>
      )}
      {request === "fail" && (
        <>
          <Image src={"/assets/auth/Error.svg"} width={"20px"} />
          <Text
            as={Text.VARIANT.SUBTITLE}
            size={Text.SIZE.MOBILETITLE}
            weight={Text.WEIGHT.BOLD}
            color={Text.COLOR.SECONDARY}
            textAlign={"center"}
            margin={"14px 0 0"}
          >
            {fail.title[0]}
          </Text>
          <Text
            as={Text.VARIANT.SUBTITLE}
            size={Text.SIZE.MOBILETITLE}
            weight={Text.WEIGHT.BOLD}
            color={Text.COLOR.SECONDARY}
            textAlign={"center"}
            margin={"0 0 40px"}
          >
            {fail.title[1]}
          </Text>

          <Button
            width={"252px"}
            padding={"10px 0"}
            margin={"0 0 10px"}
            border={"1px solid"}
            borderColor={Button.COLOR.SECONDARY}
            borderRadius={"21px"}
            bgColor={Button.COLOR.SECONDARY}
            color={Button.COLOR.WHITE}
            fontSize={Button.FONT_SIZE.MOBILECONTENT}
            fontWeight={Button.FONT_WEIGHT.BOLD}
            onClick={handleFail}
          >
            {fail.submit}
          </Button>
        </>
      )}
      {request === "pending" && (
        <>
          <Text
            as={Text.VARIANT.SUBTITLE}
            size={Text.SIZE.MOBILETITLE}
            weight={Text.WEIGHT.BOLD}
            textAlign={"center"}
            margin={"0 0 40px"}
          >
            {pending.title}
          </Text>

          <Input
            size={Input.SIZE.MOBILECONTENT}
            placeholder={pending.form.id}
            width={"100%"}
            padding={"10px 18px"}
            borderColor={Input.COLOR.LINEGRAY}
            borderRadius={"21px"}
            {...register("name")}
          />

          <Box
            display={Box.DISPLAY.FLEX}
            jc={"space-between"}
            width={"100%"}
            margin={"12px 0 40px"}
          >
            <Input
              size={Input.SIZE.MOBILECONTENT}
              type="tel"
              placeholder={pending.form.mobileTel}
              width={"100% "}
              padding={"10px 18px"}
              borderColor={Input.COLOR.LINEGRAY}
              borderRadius={"21px"}
              {...register("phone")}
            />
          </Box>

          <Button
            width={"100%"}
            padding={"10px 0"}
            border={"1px solid"}
            borderColor={
              isActive ? Button.COLOR.SECONDARY : Button.COLOR.LINEGRAY
            }
            borderRadius={"21px"}
            bgColor={isActive ? Button.COLOR.SECONDARY : Button.COLOR.LINEGRAY}
            color={Button.COLOR.WHITE}
            fontSize={Button.FONT_SIZE.MOBILECONTENT}
            fontWeight={Button.FONT_WEIGHT.BOLD}
            onClick={handleSubmit(handleSubmitFindId)}
            disabled={!isActive}
          >
            {pending.form.submit}
          </Button>
        </>
      )}
    </Box>
  );
};

export default MobileFindId;
