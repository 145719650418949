import { COLOR, FONT_SIZE, RADIUS } from "styles/theme";
import { InputComponent } from "./input.style";
import React, { forwardRef } from "react";

const VARIANT = {
  INPUT: "input",
  TEXTAREA: "textarea",
};

const Input = forwardRef((props, ref) => {
  const {
    as,
    //
    name,
    defaultValue,
    value,
    type,
    placeholder,
    checked,
    onChange,
    //
    width,
    height,
    //
    border,
    borderLeft,
    borderRadius,
    borderColor,
    //
    bgColor,
    //
    margin,
    padding,
    //
    color,
    hoverColor,
    focusColor,
    themeStyle,
    size,
    //
    resize,
    //
    readOnly,
    display,
    //
    disabled,
    //
    maxLength,
    //
    ariaLabel,
  } = props;

  return (
    <InputComponent
      as={as}
      //
      name={name}
      value={value}
      defaultValue={defaultValue}
      type={type}
      placeholder={placeholder}
      checked={checked}
      onChange={onChange}
      //
      $width={width}
      $height={height}
      //
      border={border}
      borderLeft={borderLeft}
      borderRadius={borderRadius}
      borderColor={borderColor}
      //
      bgColor={bgColor}
      //
      margin={margin}
      padding={padding}
      //
      $color={color}
      hoverColor={hoverColor}
      focusColor={focusColor}
      themeStyle={themeStyle}
      $fontSize={size}
      //
      readOnly={readOnly}
      //
      resize={resize}
      //
      display={display}
      //
      disabled={disabled}
      //
      maxLength={maxLength}
      //
      aria-label={ariaLabel}
      //
      ref={ref}
    />
  );
});

Input.VARIANT = VARIANT;
Input.RADIUS = RADIUS;
Input.COLOR = COLOR;
Input.SIZE = FONT_SIZE;

export default Input;
