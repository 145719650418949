import Box from "components/atoms/box";
import Text from "components/atoms/text";
import useContextForm from "hooks/utils/useContextForm";
import React from "react";
import AdminTable from "../adminTable";
import { useState } from "react";
import useUrl from "hooks/utils/useUrl";
import { useNewsLetterList } from "hooks/api/admin/news_letter";
import NewsLetterTable from "components/molecules/newsLetterTable";
import { newsletter } from "text/admin/newsletter";

const AdminNewsLetterList = (props) => {
  const {
    filterData: { searchCondition, keyword },
  } = props;

  const { tableHeader } = newsletter.table;

  const {
    query: { page },
  } = useUrl();

  const [selectedMenu, setSelectedMenu] = useState("10");

  const defaultValues = {
    itemsPerPage: "10",
  };

  const { Provider } = useContextForm(defaultValues);

  return (
    <Box maxWidth="1024px" display={Box.DISPLAY.FLEX} flexDirection="column" margin="40px 0 0 0">
      <Text as={Text.VARIANT.SUBTITLE} ariaLabel="뉴스레터 목록" margin="0 0 10px 0">
        뉴스레터 목록
      </Text>
      <Provider>
        <AdminTable
          type={["countBar"]}
          hasSelectMenu={true}
          //
          api={useNewsLetterList}
          tableHeader={tableHeader}
          searchValue={keyword}
          searchCondition={searchCondition}
          page={page}
          //
          selectedMenu={selectedMenu}
          setSelectedMenu={setSelectedMenu}
          width="120px"
        >
          <NewsLetterTable searchCondition={searchCondition} keyword={keyword} />
        </AdminTable>
      </Provider>
    </Box>
  );
};

export default AdminNewsLetterList;
