import Box from "components/atoms/box";
import InputForm from "components/molecules/inputForm";
import TextArea from "components/molecules/textarea";
import AdminDetailFormContainer from "components/organisms/adminDetailFormContainer";
import AdminDetailForm from "components/organisms/adminDetailForm";
import ListView from "components/organisms/listView";
import { AdminContentsContainer } from "components/templates/admin/admin.style";
import {
  useCategoryDetail,
  useCreateCategoryDetail,
  useUpdateCategoryDetail,
} from "hooks/api/admin/category";
import useUrl from "hooks/utils/useUrl";
import React, { useState } from "react";
import ListViewButtonBundle from "components/organisms/listViewButtonBundle";
import Text from "components/atoms/text";
import { category } from "text/admin/category";
import { common } from "text/admin/common";
import usePendingMutate from "hooks/utils/usePendingMutate";

const CategoryDetail = () => {
  const {
    path,
    query: { key, isDefault },
  } = useUrl();

  const { isPending, handleMutate } = usePendingMutate();

  const categoryName = path[2];

  const { defaultValues, buttonList } = category.detail;

  const { checkListView } = common.alert;

  const subTitle = {
    news: key ? "뉴스 카테고리 수정" : "뉴스 카테고리 등록",
    feature: key ? "특집 카테고리 수정" : "특집 카테고리 등록",
    people: key ? "인물 카테고리 수정" : "인물 카테고리 등록",
    science: key ? "과학 카테고리 수정" : "과학 카테고리 등록",
  };

  const { list } = useCategoryDetail({ categoryName, key });

  const api = key ? useUpdateCategoryDetail : useCreateCategoryDetail;
  const mutate = api();

  const formInfo = [
    {
      title: "카테고리 노출",
      height: "48px",
      components:
        isDefault === "true" ? (
          <ListView
            buttonList={buttonList}
            isRenderedListView={list?.menuUse ?? "Y"}
            registerName="menuUse"
            disabled={true}
            required={false}
          />
        ) : (
          <ListView
            buttonList={buttonList}
            isRenderedListView={list?.menuUse ?? "Y"}
            registerName="menuUse"
          />
        ),
      required: isDefault === "true" ? false : true,
    },
    {
      title: "코드명",
      height: "48px",
      components:
        isDefault === "true" ? (
          <Box display={Box.DISPLAY.FLEX} al="center" height="100%">
            <Text as={Text.VARIANT.TEXT} size={Text.SIZE.ADMINCONTENT}>
              {list?.codeName ?? ""}
            </Text>
          </Box>
        ) : (
          <InputForm
            name="codeName"
            type="text"
            width="240px"
            height="32px"
            padding="0 12px"
            theme="secondary"
            defaultName={list?.codeName ?? ""}
            required="코드명 입력은 필수입니다."
            ariaLabel="코드명"
            isAdminDetail
          />
        ),
      required: true,
    },
    {
      title: "요약내용",
      height: "284px",
      components: (
        <TextArea
          name="codeDc"
          width="633px"
          height="268px"
          padding="7px 13px"
          defaultValue={list?.codeDc ?? ""}
          required={categoryName === "feature" ? "요약내용 입력은 필수입니다." : false}
          isAdminDetail
        />
      ),
      required: categoryName === "feature" ? true : false,
      isUpperPosition: true,
    },
    {
      title: "리스트뷰 버튼 표시",
      height: "92px",
      components: (
        <ListViewButtonBundle list={list} categoryName={categoryName} codeName={list?.codeName} />
      ),
      required: true,
    },
  ];

  const handleSubmit = (data) => {
    if (isDefault === "true") data.codeName = list?.codeName;
    const { listviewList, listviewBasic } = data;
    const isDisabled = listviewList.includes(listviewBasic) ? false : true;
    if (isDisabled) {
      alert(checkListView);
      return;
    }

    handleMutate(mutate, { key, categoryName, data });
  };

  return (
    <AdminContentsContainer>
      <Box maxWidth="1024px">
        <AdminDetailFormContainer
          title={subTitle[categoryName]}
          defaultValues={defaultValues}
          handleSubmit={handleSubmit}
          isPending={isPending}
        >
          <AdminDetailForm formInfo={formInfo} />
        </AdminDetailFormContainer>
      </Box>
    </AdminContentsContainer>
  );
};

export default CategoryDetail;
