import { adminDefaultUrl } from "api/admin/url";
import Box from "components/atoms/box";
import Button from "components/atoms/button";
import Image from "components/atoms/image";
import Link from "components/atoms/link";
import Text from "components/atoms/text";
import SideBarMenu from "components/molecules/sideBarMenu";
import { useLogoutMutation } from "hooks/api/auth";
import useUrl from "hooks/utils/useUrl";
import React, { useState } from "react";
import useDropdownStore from "store/useDropdownStore";
import { common } from "text/admin/common";
import { sidebar } from "text/admin/sidebar";
import generateMenuIcons from "utils/generateMenuIcons";

const SideBar = () => {
  const { changeLink } = useUrl();

  const [closePage] = useState(false);

  const {
    default: { logout },
    success: { logout: logoutSuccess },
    failed: { logout: logoutFailed },
  } = common.alert;

  const {
    menuList,
    logout: { title },
  } = sidebar;

  const { activeDropdownIdx, setActiveDropdownIdx } = useDropdownStore();

  const handleSuccessLogout = () => {
    !closePage && window.alert(logoutSuccess);
    changeLink(`${adminDefaultUrl}/login`);
    sessionStorage.removeItem("userName");
    sessionStorage.removeItem("authority");
    sessionStorage.removeItem("selectedId");
  };

  const handleFailLogout = () => {
    window.alert(logoutFailed);
  };

  const logoutMutate = useLogoutMutation(handleSuccessLogout, handleFailLogout);

  const handleLogout = () => {
    const confirm = window.confirm(logout);
    if (confirm) logoutMutate();
  };

  return (
    <Box
      width="260px"
      height="100%"
      minHeight="100vh"
      display={Box.DISPLAY.FLEX}
      flexDirection="column"
      al="center"
      padding="76px 0 63px 0"
      bgColor={Box.COLOR.SIDEBAR}
    >
      <Link link={`${adminDefaultUrl}/dashboard`}>
        <Image
          src={"/assets/admin/sidebar_logo.png"}
          width="159px"
          height="26px"
          alt="sidebar_logo"
        />
      </Link>
      <Box
        className="admin-sidebar"
        width="100%"
        flex="1 1 0"
        padding="76px 0 0 0"
        overflow="scroll"
      >
        {menuList.map(({ title, link, isDropdown, menuNo, dropdownMenu, target }, idx) => (
          <SideBarMenu
            key={idx}
            title={title}
            link={link}
            isDropdown={isDropdown}
            menuNo={menuNo}
            dropdownMenu={dropdownMenu}
            target={target}
            idx={idx}
            activeDropdownIdx={activeDropdownIdx}
            setActiveDropdownIdx={setActiveDropdownIdx}
          />
        ))}
      </Box>
      <Box width="100%" padding="0 20px 0 36px" position="relative" bottom="-30px" isNotActiveMenu>
        <Button
          size="full"
          sort="start"
          padding="16px 0"
          themeStyle={Button.THEME.SIDEBARMENU}
          color={Button.COLOR.SIDEMENU}
          onClick={handleLogout}
        >
          <Box display={Box.DISPLAY.FLEX} al="center" gap="10px">
            {generateMenuIcons(title)}
            <Text
              as={Text.VARIANT.TEXT}
              size={Text.SIZE.ADMINCATEGORY}
              weight={Text.WEIGHT.BOLD}
              color={Text.COLOR.PRIMARY}
            >
              {title}
            </Text>
          </Box>
        </Button>
      </Box>
    </Box>
  );
};

export default SideBar;
