import {
  createSchedule,
  deleteSchedule,
  getScienceCalendarDetail,
  getScienceCalendarList,
  updateScheduleInfo,
} from "api/admin/calendar";
import useHandleAlert from "hooks/utils/useHandleAlert";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { common } from "text/admin/common";

const {
  success: { create, remove, update },
} = common.alert;

// 과학기술 캘린더 목록
export const useScienceCalendarList = ({
  page,
  itemsPerPage,
  searchCondition,
  searchValue,
  dateCondition,
  startDate,
  endDate,
}) => {
  const { data, isLoading, isSuccess, isError } = useQuery(
    [
      "calendarSchedule",
      page,
      itemsPerPage,
      searchCondition,
      searchValue,
      dateCondition,
      startDate,
      endDate,
    ],
    () =>
      getScienceCalendarList({
        page,
        itemsPerPage,
        searchCondition,
        searchValue,
        dateCondition,
        startDate,
        endDate,
      })
  );

  const list = data?.data.list;
  const totalCnt = data?.data.totalCnt;
  const totalPageIndex = data?.data.totalPageIndex;

  return {
    list,
    totalCnt,
    totalPageIndex,
    isLoading,
    isSuccess,
    isError,
  };
};

// 과학기술 캘린더 상세
export const useScienceCalendarDetail = ({ key }) => {
  const { data, isLoading, isSuccess, isError } = useQuery({
    queryKey: ["calendarDetail", key],
    queryFn: () => getScienceCalendarDetail({ key }),
    enabled: !!key,
  });

  const list = data?.data.adminCalendarDetail;
  const fileList = data?.data.adminCalendarFileList;

  return {
    list,
    fileList,
    isLoading,
    isSuccess,
    isError,
  };
};

// 과학기술 캘린더 등록
export const useCreateSchedule = (successCallback) => {
  const { handleGoBack } = useHandleAlert();
  const queryClient = useQueryClient();
  const createScheduleMutation = useMutation(createSchedule, {
    onSuccess: () => {
      alert(create);
      queryClient.invalidateQueries("calendarSchedule");
      successCallback();
      handleGoBack();
    },
  });

  const mutate = ({ data }) => {
    createScheduleMutation.mutate({ data });
  };

  return mutate;
};

// 과학기술 캘린더 수정
export const useUpdateScheduleInfo = (successCallback) => {
  const { handleGoBack } = useHandleAlert();
  const queryClient = useQueryClient();
  const updateScheduleInfoMutation = useMutation(updateScheduleInfo, {
    onSuccess: () => {
      alert(update);
      queryClient.invalidateQueries("calendarSchedule");
      successCallback();
      handleGoBack();
    },
  });

  const mutate = ({ key, data }) => {
    updateScheduleInfoMutation.mutate({ key, data });
  };

  return mutate;
};

// 과학기술 캘린더 삭제
export const useDeleteSchedule = (reset) => {
  const queryClient = useQueryClient();
  const deleteScheduleMutation = useMutation(deleteSchedule, {
    onSuccess: () => {
      alert(remove);
      queryClient.invalidateQueries("calendarSchedule");
      reset();
    },
  });

  const mutate = ({ data }) => {
    deleteScheduleMutation.mutate({ data });
  };

  return mutate;
};
