import NewsletterTemplate from "components/organisms/newsletterTemplate";
import { useNewsletterDetail } from "hooks/api/newsletter";
import useUrl from "hooks/utils/useUrl";
import React, { useEffect, useRef } from "react";
import useMobileStore from "store/useMobileStore";
import convertImage from "utils/convertImage";

const NewsletterDetail = () => {
  const parentRef = useRef(null);
  const { path } = useUrl();

  const { detail, isSuccess } = useNewsletterDetail(path[1]);

  const { isMobile } = useMobileStore();

  useEffect(() => {
    parentRef.current.style.height = isMobile ? "649px" : "1341px";
  }, [isMobile]);

  return (
    <div
      ref={parentRef}
      style={{
        overflow: "hidden",
      }}
    >
      <div
        style={{
          position: "absolute",
          left: "50%",
          transform: isMobile
            ? "scale(0.458) translate3d(-50%, 0px, 0px)"
            : "translateX(-50%)",
          transformOrigin: isMobile ? "0px 0px" : "none",
        }}
      >
        {isSuccess && (
          <NewsletterTemplate
            {...detail}
            id={detail.key}
            sec1ImgUrl={convertImage(detail.sec1Img, "newsletter")}
            sec2ImgUrl={convertImage(detail.sec2Img, "newsletter")}
          />
        )}
      </div>
    </div>
  );
};

export default NewsletterDetail;
