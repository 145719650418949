import { DELETE, GET, POST, PUT } from "api/config";
import { manage } from "../url";

const { managerUrl, authorityUrl, workstatusUrl, loginlogUrl } = manage;

// 관리자 목록
export const getManagerList = async (
  page = "1",
  itemsPerPage = "10",
  condition = "userId",
  searchValue = "",
  authorCode = "",
  inactive = ""
) =>
  await GET(
    `${managerUrl}?pageIndex=${page}&itemsPerPage=${itemsPerPage}&searchCondition=${condition}&searchKeyword=${searchValue}&authorCode=${authorCode}&inactive=${inactive}`
  );

// 관리자 상세
export const getManagerDetail = async (id) => await GET(`${managerUrl}/${id}`);

// 관리자 등록
export const createManager = async ({ data }) => {
  await POST(`${managerUrl}`, data);
};

// 관리자 수정
export const updateManagerInfo = async ({ key, data }) => {
  await PUT(`${managerUrl}/${key}`, data);
};

// 관리자 삭제
export const deleteManager = async ({ data }) => {
  await DELETE(`${managerUrl}`, data);
};

// 관리자 임시 비밀번호 발급
export const updateManagerPassword = async ({ key }) => {
  await PUT(`${managerUrl}/${key}/password`);
};

// 권한 목록
export const getAuthorityList = async (page = "1", searchValue = "") =>
  await GET(`${authorityUrl}?pageIndex=${page}&searchKeyword=${searchValue}`);

// 권한 정보
export const getAuthorityInfo = async (authorCode) => await GET(`${authorityUrl}/${authorCode}`);

// 권한 메뉴
export const getAuthorityMenuList = async () => await GET(`${authorityUrl}/menu`);

// 권한별 메뉴 사용 유무
export const getAuthorityMenuDetail = async (authorCode) =>
  await GET(`${authorityUrl}/${authorCode}/menu`);

// 권한 등록
export const createAuthority = async ({ data }) => {
  await POST(`${authorityUrl}`, data);
};

// 권한 수정
export const updateAuthorityInfo = async ({ authorCode, data }) => {
  await PUT(`${authorityUrl}/${authorCode}`, data);
};

// 권한 삭제
export const deleteAuthority = async ({ data }) => {
  await DELETE(`${authorityUrl}`, data);
};

// 운영자 작업 현황
export const getWorkStatusList = async ({
  page = "1",
  itemsPerPage = "10",
  startDate = "",
  endDate = "",
  currOption = "",
  searchValue = "",
  searchCondition = "",
  cate1 = "",
  cate2 = "",
}) =>
  await GET(
    `${workstatusUrl}?pageIndex=${page}&itemsPerPage=${itemsPerPage}&getStartDate=${startDate}&getEndDate=${endDate}&status=${currOption}&searchKeyword=${searchValue}&searchCondition=${searchCondition}&cate1=${cate1}&cate2=${cate2}`
  );

// 로그인 현황
export const getLoginLogList = async ({
  page = "1",
  itemsPerPage = "10",
  searchValue = "",
  searchCondition = "",
  startDate = "",
  endDate = "",
}) =>
  await GET(
    `${loginlogUrl}?pageIndex=${page}&itemsPerPage=${itemsPerPage}&searchKeyword=${searchValue}&searchCondition=${searchCondition}&startDate=${startDate}&endDate=${endDate}`
  );
