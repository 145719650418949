import Box from "components/atoms/box";
import Button from "components/atoms/button";
import Image from "components/atoms/image";
import Text from "components/atoms/text";
import useUrl from "hooks/utils/useUrl";
import React from "react";
import useMobileStore from "store/useMobileStore";
import notFoundText from "text/notFound";

const { title, contents, submit } = notFoundText;

const NotFound = () => {
  const { changeLink } = useUrl();
  const { isMobile } = useMobileStore();

  return (
    <Box
      display={Box.DISPLAY.FLEX}
      flexDirection="column"
      al="center"
      jc="center"
      width={"100vw"}
      height={"100vh"}
      padding={"0 36px"}
    >
      <Image
        src={"/assets/404/404.png"}
        width={isMobile ? "213px" : "auto"}
        alt={"not found logo"}
      />

      <Text
        as={Text.VARIANT.TITLE}
        size={isMobile ? Text.SIZE.MOBILECONTENT : Text.SIZE.CLIENTTITLE}
        margin={isMobile ? "3px 0 11px" : "40px 0 30px"}
      >
        {title}
      </Text>
      {contents.map((content) => (
        <Text
          as={Text.VARIANT.TEXT}
          size={isMobile ? Text.SIZE.MOBILECONTENT : Text.SIZE.CLIENTCATEGORY}
          color={isMobile ? Text.SIZE.BLACK : Text.COLOR.TEXTGRAY}
          lineHeight={isMobile ? "17px" : "35px"}
        >
          {content}
        </Text>
      ))}

      <Button
        width={isMobile ? "100%" : "200px"}
        maxWidth={"280px"}
        height={isMobile ? "36px" : "46px"}
        bgColor={Button.COLOR.SECONDARY}
        border={"none"}
        borderRadius={"40px"}
        color={Button.COLOR.WHITE}
        fontSize={
          isMobile
            ? Button.FONT_SIZE.MOBILECONTENT
            : Button.FONT_SIZE.CLIENTNORMAL
        }
        fontWeight={Button.FONT_WEIGHT.BOLD}
        margin={isMobile ? "40px 0 0" : "60px 0 0"}
        onClick={() => changeLink("/")}
      >
        {submit}
      </Button>
    </Box>
  );
};

export default NotFound;
