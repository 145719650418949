import Box from "components/atoms/box";
import Button from "components/atoms/button";
import Slider, { nextSlide, prevSlide, slideTo } from "components/atoms/slider";
import React, { useRef, useState } from "react";
import { BsChevronRight } from "@react-icons/all-files/bs/BsChevronRight";
import { BsChevronLeft } from "@react-icons/all-files/bs/BsChevronLeft";
import { BsCircle } from "@react-icons/all-files/bs/BsCircle";
import { BsCircleFill } from "@react-icons/all-files/bs/BsCircleFill";

const Banner = (props) => {
  const {
    imageList = [],
    slidesPerView = 1,
    spaceBetween,
    isNavigate,
    bottom,
    isAutoplay,
    simulateTouch = false,
    children: renderChildren,
  } = props;

  const sliderRef = useRef(null);
  const [activeSlide, setActiveSlide] = useState(0);

  return (
    <Box position="relative">
      <Slider
        direction={"horizontal"}
        slidesPerView={slidesPerView}
        spaceBetween={spaceBetween}
        loop
        isAuto={true}
        simulateTouch={simulateTouch}
        setActiveIndex={setActiveSlide}
        sliderRef={sliderRef}
      >
        {renderChildren(imageList)}
      </Slider>

      {bottom && (
        <Box
          display={Box.DISPLAY.FLEX}
          jc={"center"}
          al="center"
          position={"absolute"}
          bottom={bottom}
          left={"0"}
          right={"0"}
          zIndex={Box.LEVEL.MIDDLE}
          ariaLabel="배너 하단 네비게이션"
        >
          {isNavigate && (
            <Button
              themeStyle={Button.THEME.NONE}
              color={Button.COLOR.WHITE}
              sort={Button.SORT.CENTER}
              margin={"0 8px 0 0"}
              onClick={prevSlide(sliderRef)}
              ariaLabel="이전 배너로 이동"
            >
              <BsChevronLeft size={15} />
            </Button>
          )}
          {imageList.map((_, index) => (
            <Button
              themeStyle={Button.THEME.NONE}
              color={Button.COLOR.WHITE}
              margin={"0 8px"}
              onClick={() => slideTo(sliderRef)(index)}
              key={index}
              ariaLabel={`${index + 1}번째 배너로 이동`}
            >
              {index === activeSlide ? (
                <BsCircleFill size={8} />
              ) : (
                <BsCircle size={8} />
              )}
            </Button>
          ))}
          {isNavigate && (
            <Button
              themeStyle={Button.THEME.NONE}
              color={Button.COLOR.WHITE}
              sort={Button.SORT.CENTER}
              margin={"0 0 0 8px"}
              onClick={nextSlide(sliderRef)}
              ariaLabel="다음 배너로 이동"
            >
              <BsChevronRight size={15} />
            </Button>
          )}
        </Box>
      )}
    </Box>
  );
};

Banner.Slide = Slider.Slide;

export default Banner;
