import {
  changeArticleCategory,
  createArticle,
  deleteArticle,
  deleteAttachedFiles,
  getNewsboardDetailList,
  getNewsboardList,
  updateArticleInfo,
} from "api/admin/news_board";
import useHandleAlert from "hooks/utils/useHandleAlert";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { common } from "text/admin/common";

const {
  success: { create, update, remove, move },
} = common.alert;

// 뉴스게시판 목록
export const useNewsBoardList = ({
  page,
  itemsPerPage,
  categoryName,
  cate1,
  cate2,
  searchCondition,
  searchValue,
}) => {
  const { data, isLoading, isSuccess, isError } = useQuery(
    ["newsBoardList", page, itemsPerPage, categoryName, cate1, cate2, searchCondition, searchValue],
    () =>
      getNewsboardList({
        page,
        itemsPerPage,
        categoryName,
        cate1,
        cate2,
        searchCondition,
        searchValue,
      })
  );

  const list = data?.data.list;
  const totalCnt = data?.data.totalCnt;
  const totalPageIndex = data?.data.totalPageIndex;

  return {
    list,
    totalCnt,
    totalPageIndex,
    isLoading,
    isSuccess,
    isError,
  };
};

// 뉴스게시판 상세 목록
export const useNewsBoardDetailList = ({ categoryName, key }) => {
  const { data, isLoading, isSuccess, isError } = useQuery({
    queryKey: ["newsBoardDetailList", categoryName, key],
    queryFn: () => getNewsboardDetailList({ categoryName, key }),
    enabled: !!key,
  });

  const list = data?.data.adminArticleDetail;
  const totalCnt = data?.data.totalCnt;
  const totalPageIndex = data?.data.totalPageIndex;
  const fileList = data?.data.adminArticleFileList;

  return {
    list,
    totalCnt,
    totalPageIndex,
    fileList,
    isLoading,
    isSuccess,
    isError,
  };
};

// 기사 등록
export const useCreateArticle = (successCallback) => {
  const { handleGoBack } = useHandleAlert();
  const queryClient = useQueryClient();
  const createArticleMutation = useMutation(createArticle, {
    onSuccess: () => {
      alert(create);
      queryClient.invalidateQueries("newsBoardList");
      successCallback();
      handleGoBack();
    },
  });

  const mutate = ({ data }) => {
    createArticleMutation.mutate({ data });
  };

  return mutate;
};

// 기사 수정
export const useUpdateArticleInfo = (successCallback) => {
  const { handleGoBack } = useHandleAlert();
  const queryClient = useQueryClient();
  const updateArticleInfoMutation = useMutation(updateArticleInfo, {
    onSuccess: () => {
      alert(update);
      queryClient.invalidateQueries(["newsBoardList", "newsBoardDetailList"]);
      successCallback();
      handleGoBack();
    },
  });

  const mutate = ({ key, data }) => {
    updateArticleInfoMutation.mutate({ key, data });
  };

  return mutate;
};

// 기사 삭제
export const useDeleteArticle = () => {
  const queryClient = useQueryClient();
  const deleteArticleMutation = useMutation(deleteArticle, {
    onSuccess: () => {
      alert(remove);
      queryClient.invalidateQueries("newsBoardList");
    },
  });

  const mutate = ({ data }) => {
    deleteArticleMutation.mutate({ data });
  };

  return mutate;
};

// 기사 이동
export const useChangeArticleCategory = (setIsOpen) => {
  const queryClient = useQueryClient();
  const changeArticleCategoryMutation = useMutation(changeArticleCategory, {
    onSuccess: () => {
      alert(move);
      queryClient.invalidateQueries("newsBoardList");
      setIsOpen(false);
    },
  });

  const mutate = ({ data }) => {
    changeArticleCategoryMutation.mutate({ data });
  };

  return mutate;
};

// 첨부파일 삭제
export const useDeleteAttachedFiles = () => {
  const queryClient = useQueryClient();
  const deleteAttachedFilesMutation = useMutation(deleteAttachedFiles, {
    onSuccess: () => {
      alert(remove);
      queryClient.invalidateQueries("newsBoardDetailList");
      queryClient.invalidateQueries("calendarDetail");
      queryClient.invalidateQueries("coverageDetail");
    },
  });

  const mutate = ({ key, fileSN }) => {
    deleteAttachedFilesMutation.mutate({ key, fileSN });
  };

  return mutate;
};
