import React, { useRef, useState } from "react";
import {
  useHeadLineNews,
  useRankWeekNews,
  useRankMonthNews,
} from "hooks/api/news";
import Box from "components/atoms/box";
import NewsList from "components/organisms/newsList";
import List from "components/molecules/list";
import Banner from "components/molecules/banner";
import Image from "components/atoms/image";
import Link from "components/atoms/link";
import Text from "components/atoms/text";
import { useGetBanner } from "hooks/api/banner";
import convertImage from "utils/convertImage";
import Button from "components/atoms/button";
import useCategory from "hooks/utils/useCategory";
import Slider from "components/atoms/slider";

const MobileSideNews = () => {
  const [rankNews, setRankNews, rankNewsListApi] = useCategory([
    useRankWeekNews,
    useRankMonthNews,
  ]);

  const [active, setActive] = useState(0);
  const sliderRef = useRef();

  const link = ["/daily?category=week", "/daily?category=month"];

  return (
    <>
      <Box padding={"0 12px"}>
        <Box
          display={Box.DISPLAY.FLEX}
          width={"100%"}
          padding={`20px 24px`}
          margin={"10px 0 10px"}
          bgColor={Box.COLOR.LIGHTGRAY}
          borderRadius="10px"
        >
          <NewsList
            size={Text.SIZE.MOBILETITLE}
            bold={"주요 NEWS"}
            margin={"0 0 20px"}
          >
            <List api={useHeadLineNews}>
              {(list) =>
                list && (
                  <Box position={"relative"} overflow={"hidden"}>
                    <Slider
                      slidesPerView={"auto"}
                      spaceBetween={30}
                      simulateTouch={false}
                      setActiveIndex={setActive}
                      sliderRef={sliderRef}
                    >
                      {list.slice(0, 3).map((data) => (
                        <Slider.Slide key={data.key}>
                          <NewsList.TEMPLATE.SmallImageNews
                            link={data.key}
                            category={"news"}
                            thumbnail={data.thumbnail}
                            title={data.title}
                            width={"184px"}
                            margin={"10px 0 0"}
                            aspectRatio={"184/120"}
                            ariaLabel={"주요 News"}
                            isSort={false}
                            titleSize={Text.SIZE.MOBILECONTENT}
                          />
                        </Slider.Slide>
                      ))}
                    </Slider>
                    <Box
                      position={"absolute"}
                      top={"0"}
                      right={"-1.8px"}
                      zIndex={Box.LEVEL.TOP}
                    >
                      <Image src={"/assets/common/blur.png"} />
                    </Box>
                  </Box>
                )
              }
            </List>
          </NewsList>
        </Box>
      </Box>

      <List api={useGetBanner} params={{ type: "sns" }}>
        {(list) =>
          list && (
            <Banner
              imageList={list}
              slidesPerView={1}
              bottom={"4px"}
              simulateTouch
            >
              {(imageList) => {
                const isMaxBanner = imageList.length === 3;

                return imageList.map(({ key, filePath, url }, idx) => (
                  <Banner.Slide key={key}>
                    <Box
                      aspectRatio={isMaxBanner ? "277/88" : "277/118"}
                      borderRadius={Box.RADIUS.NORMAL}
                      overflow="hidden"
                    >
                      <Link
                        width={"100%"}
                        height={"100%"}
                        link={url}
                        target={"_blank"}
                      >
                        <Image
                          src={convertImage(filePath)}
                          alt="banner"
                          width="100%"
                          height="100%"
                          fit="cover"
                        />
                      </Link>
                    </Box>
                  </Banner.Slide>
                ));
              }}
            </Banner>
          )
        }
      </List>

      <Box padding={"0 12px"} margin={"10px 0 10px"}>
        <Box
          width={"100%"}
          bgColor={Box.COLOR.LIGHTGRAY}
          borderRadius={"10px"}
          padding={"20px 24px"}
        >
          <Box
            width={"100%"}
            borderBottom={"1px solid"}
            padding={"0 0 1px"}
            margin={"0 0 30px"}
            borderColor={Box.COLOR.LINEGRAY}
          >
            <Button
              width={"50%"}
              padding={"0 0 13px"}
              fontSize={Button.FONT_SIZE.MOBILECATEGORY}
              fontWeight={
                rankNews === 0
                  ? Button.FONT_WEIGHT.BOLD
                  : Button.FONT_WEIGHT.NORMAL
              }
              borderBottom={rankNews === 0 ? "2px solid" : ""}
              borderColor={Button.COLOR.SECONDARY}
              color={
                rankNews === 0 ? Button.COLOR.SECONDARY : Button.COLOR.BLACK
              }
              themeStyle={Button.THEME.NONE}
              onClick={() => setRankNews(0)}
            >
              주간 Main News
            </Button>
            <Button
              width={"50%"}
              padding={"0 0 13px"}
              fontSize={Button.FONT_SIZE.MOBILECATEGORY}
              fontWeight={
                rankNews === 1
                  ? Button.FONT_WEIGHT.BOLD
                  : Button.FONT_WEIGHT.NORMAL
              }
              borderBottom={rankNews === 1 ? "2px solid" : ""}
              borderColor={Button.COLOR.SECONDARY}
              color={
                rankNews === 1 ? Button.COLOR.SECONDARY : Button.COLOR.BLACK
              }
              themeStyle={Button.THEME.NONE}
              onClick={() => setRankNews(1)}
            >
              월간 Main News
            </Button>
          </Box>
          <NewsList
            size={Text.SIZE.MOBILETITLE}
            margin={"0 0 20px"}
            link={link[rankNews]}
            isMobile
          >
            <List api={rankNewsListApi} params={"news"}>
              {(list) =>
                list && (
                  <NewsList.LAYOUT.SideLineNews
                    list={list}
                    isMobile
                    category={"news"}
                    ariaLabel={
                      rankNews === 1 ? "월간 Main News" : "주간 Main News"
                    }
                  />
                )
              }
            </List>
          </NewsList>
        </Box>
      </Box>
    </>
  );
};

export default MobileSideNews;
