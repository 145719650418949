import Box from "components/atoms/box";
import Text from "components/atoms/text";
import React, { useState } from "react";

const Tooltip = (props) => {
  const { text, width = "653px", children } = props;

  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <Box
      position="relative"
      display={Box.DISPLAY.FLEX}
      al="center"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
      {showTooltip && (
        <Box
          position="absolute"
          top="20px"
          width={width}
          padding="15px"
          border="1px solid"
          borderColor={Box.COLOR.ADMINLINEGRAY}
          bgColor={Box.COLOR.WHITE}
          zIndex="9999"
        >
          {text.map((item, idx) => (
            <Text
              as={Text.VARIANT.TEXT}
              size={Text.SIZE.ADMINCONTENTDETAIL}
              lineHeight="1.2"
              key={idx}
            >
              {item}
            </Text>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default Tooltip;
