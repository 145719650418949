import { DELETE, GET, POST, PUT } from "api/config";
import { article } from "../url";

const { articleUrl } = article;

// 관리자 목록
export const getNewsboardList = async ({
  page = "1",
  itemsPerPage = "10",
  categoryName = "all",
  cate1 = "",
  cate2 = "",
  searchCondition = "",
  searchValue = "",
}) =>
  await GET(
    `${articleUrl}/${categoryName}?pageIndex=${page}&itemsPerPage=${itemsPerPage}&cate1=${cate1}&cate2=${cate2}&searchCondition=${searchCondition}&searchKeyword=${searchValue}`
  );

// 관리자 상세 목록
export const getNewsboardDetailList = async ({ categoryName = "all", key }) =>
  await GET(`${articleUrl}/${categoryName}/${key}`);

// 기사 등록
export const createArticle = async ({ data }) => {
  await POST(`${articleUrl}`, data);
};

// 기사 수정
export const updateArticleInfo = async ({ key, data }) => {
  await PUT(`${articleUrl}/${key}`, data);
};

// 기사 삭제
export const deleteArticle = async ({ data }) => {
  await DELETE(`${articleUrl}`, data);
};

// 기사 이동
export const changeArticleCategory = async ({ data }) => {
  await PUT(`${articleUrl}`, data);
};

// 기사 이동
export const deleteAttachedFiles = async ({ key, fileSN }) => {
  await DELETE(`${articleUrl}/${key}?fileSn=${fileSN}`);
};
