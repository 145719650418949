import AdminMemberList from "components/organisms/adminMemberList";
import SearchCondition from "components/organisms/searchCondition";
import { AdminContentsContainer } from "components/templates/admin/admin.style";
import useUrl from "hooks/utils/useUrl";
import React, { useState } from "react";
import { member } from "text/admin/member";

const ManagementMember = () => {
  const { newsletterButtonList, memberButtonList, defaultValues, keywordOptions } = member;

  const { pathname, changeLink } = useUrl();

  const [selectedMenu, setSelectedMenu] = useState("userName");
  const [filterData, setFilterData] = useState([]);

  const onSubmit = (data) => {
    const {
      index: searchCondition,
      keyword: searchValue,
      newsYN: receptionStatus,
      loginStatus,
    } = data;

    setFilterData({
      searchCondition,
      searchValue,
      receptionStatus,
      loginStatus,
    });

    changeLink(pathname);
  };

  return (
    <AdminContentsContainer>
      <SearchCondition onSubmit={onSubmit} defaultValues={defaultValues}>
        <SearchCondition.RadioButtonList
          title="뉴스레터 수신"
          registerName="newsYN"
          list={newsletterButtonList}
          name="newsYN"
          currOption=""
        />
        <SearchCondition.RadioButtonList
          title="회원 분류"
          registerName="loginStatus"
          list={memberButtonList}
          name="loginStatus"
          currOption=""
        />
        <SearchCondition.Select
          title="검색 분류"
          registerName="keyword"
          selectedMenu={selectedMenu}
          setSelectedMenu={setSelectedMenu}
          options={keywordOptions}
          width="104px"
        />
      </SearchCondition>
      <AdminMemberList filterData={filterData} />
    </AdminContentsContainer>
  );
};

export default ManagementMember;
