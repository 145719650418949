import { DELETE, GET, POST, PUT } from "api/config";
import { calendar } from "../url";

const { calendarUrl } = calendar;

// 과학기술 캘린더 목록
export const getScienceCalendarList = async ({
  page = "1",
  itemsPerPage = "10",
  searchCondition = "",
  searchValue = "",
  dateCondition = "",
  startDate = "",
  endDate = "",
}) =>
  await GET(
    `${calendarUrl}?pageIndex=${page}&itemsPerPage=${itemsPerPage}&searchCondition=${searchCondition}&searchKeyword=${searchValue}&searchDateCondition=${dateCondition}&startDate=${startDate}&endDate=${endDate}`
  );

// 과학기술 캘린더 상세
export const getScienceCalendarDetail = async ({ key }) => await GET(`${calendarUrl}/${key}`);

// 과학기술 캘린더 등록
export const createSchedule = async ({ data }) => {
  await POST(`${calendarUrl}`, data);
};

// 과학기술 캘린더 수정
export const updateScheduleInfo = async ({ key, data }) => {
  await PUT(`${calendarUrl}/${key}`, data);
};

// 과학기술 캘린더 삭제
export const deleteSchedule = async ({ data }) => {
  await DELETE(`${calendarUrl}`, data);
};
