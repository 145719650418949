import { DatePicker } from "antd";
import locale from "antd/es/date-picker/locale/ko_KR";
import Box from "components/atoms/box";
import Image from "components/atoms/image";
import Text from "components/atoms/text";
import TimeList from "components/molecules/timeList";
import dayjs from "dayjs";
import useUrl from "hooks/utils/useUrl";
import React from "react";
import { useEffect } from "react";
import useEventStore from "store/useEventStore";
import convertDateWithHyphen from "utils/convertDateWithHyphen";

const EventDatePicker = (props) => {
  const { list, isRegisterDate = false } = props;

  const { path } = useUrl();
  const currPage = path[1];

  const {
    selectedStartDate,
    setSelectedStartDate,
    selectedEndDate,
    setSelectedEndDate,
    selectedStartHours,
    selectedStartMinutes,
    selectedEndHours,
    selectedEndMinutes,
    setSelectedStartHours,
    setSelectedEndHours,
    setSelectedStartMinutes,
    setSelectedEndMinutes,
  } = useEventStore();

  useEffect(() => {
    if (list) {
      const {
        eventBeginDate,
        eventEndDate,
        eventBeginHour,
        eventBeginMin,
        eventEndHour,
        eventEndMin,
        regDate,
      } = list;
      const registerDate = convertDateWithHyphen(regDate);
      const registerHour = regDate.substr(8, 2);
      const registerMinute = regDate.substr(10, 2);
      setSelectedStartDate(dayjs(eventBeginDate));
      setSelectedEndDate(dayjs(registerDate ? registerDate : eventEndDate));
      setSelectedStartHours(eventBeginHour ?? registerHour);
      setSelectedStartMinutes(eventBeginMin ?? registerMinute);
      setSelectedEndHours(eventEndHour ?? registerHour);
      setSelectedEndMinutes(eventEndMin ?? registerMinute);

      return () => {
        const today = dayjs();
        setSelectedStartDate(today);
        setSelectedEndDate(today);
        setSelectedStartHours("00");
        setSelectedStartMinutes("00");
        setSelectedEndHours("00");
        setSelectedEndMinutes("00");
      };
    }
  }, [
    list,
    setSelectedStartDate,
    setSelectedEndDate,
    setSelectedStartHours,
    setSelectedStartMinutes,
    setSelectedEndHours,
    setSelectedEndMinutes,
    currPage,
  ]);

  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setSelectedEndDate(date);
  };

  const dateFormat = "YYYY-MM-DD";

  return (
    <Box display={Box.DISPLAY.FLEX} al="center" gap="15px">
      {!isRegisterDate && (
        <>
          <Box display={Box.DISPLAY.FLEX} al="center" gap="15px">
            <Box as="label" display={Box.DISPLAY.FLEX} al="center" gap="6px">
              <DatePicker
                locale={locale}
                format={dateFormat}
                onChange={handleStartDateChange}
                value={selectedStartDate}
                className="admin-date-picker"
              />
              <Image src="/assets/admin/calendarIcon.png" width="24px" height="24px" />
            </Box>
            <Box>
              <TimeList
                selectedHours={selectedStartHours}
                setSelectedHours={setSelectedStartHours}
                selectedMinutes={selectedStartMinutes}
                setSelectedMinutes={setSelectedStartMinutes}
              />
            </Box>
          </Box>
          <Text as={Text.VARIANT.SPAN}>~</Text>
        </>
      )}
      <Box display={Box.DISPLAY.FLEX} al="center" gap="15px">
        <Box as="label" display={Box.DISPLAY.FLEX} al="center" gap="6px">
          <DatePicker
            locale={locale}
            format={dateFormat}
            onChange={handleEndDateChange}
            value={selectedEndDate}
            className="admin-date-picker"
          />
          <Image src="/assets/admin/calendarIcon.png" width="24px" height="24px" />
        </Box>
        <Box>
          <TimeList
            selectedHours={selectedEndHours}
            setSelectedHours={setSelectedEndHours}
            selectedMinutes={selectedEndMinutes}
            setSelectedMinutes={setSelectedEndMinutes}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default EventDatePicker;
