import Box from "components/atoms/box";
import Button from "components/atoms/button";
import Text from "components/atoms/text";
import List from "components/molecules/list";
import { useBoardFeature } from "hooks/api/board";
import useUrl from "hooks/utils/useUrl";
import React, { useEffect, useState } from "react";
import { BsChevronDown } from "@react-icons/all-files/bs/BsChevronDown";
import { BsChevronUp } from "@react-icons/all-files/bs/BsChevronUp";
import { IoNewspaperOutline } from "@react-icons/all-files/io5/IoNewspaperOutline";
import { Fragment } from "react";
import featureText from "text/feature";
import MobileFeatureContent from "components/organisms/mobileFeatureContent";

const { more, unit, button } = featureText;

const MobileFeature = () => {
  const {
    query: { page = 1 },
    changeLink,
  } = useUrl();

  const [isMore, setIsMore] = useState([]);

  const handleToggleMore = (idx) => {
    if (isMore.includes(idx)) {
      setIsMore(isMore.filter((i) => i !== idx));
    } else {
      setIsMore([...isMore, idx]);
    }
  };

  useEffect(() => {
    setIsMore([]);
  }, [page]);

  return (
    <Box
      display={Box.DISPLAY.FLEX}
      flexDirection="column"
      al="center"
      padding={"135px 0 32px"}
    >
      <List api={useBoardFeature} params={{ page, limit: 4 }} margin={"50px 0"}>
        {(list) =>
          list &&
          list.map(
            ({ title, cate2, articleCnt, contents, listviewBasic }, idx) => {
              const isActive = isMore.includes(idx);
              const isLast = idx === list.length - 1;

              return (
                <Fragment key={cate2}>
                  <Box
                    display={Box.DISPLAY.FLEX}
                    flexDirection="column"
                    al="flex-end"
                    padding={"0 40px"}
                    margin={"0 0 20px"}
                    width={"calc(100% - 24px)"}
                    bgColor={Box.COLOR.LIGHTGRAY}
                    borderRadius="10px"
                  >
                    <Box
                      width={"100%"}
                      borderBottom={"1px solid"}
                      borderColor={Box.COLOR.GRAY}
                      borderRadius={"0 20px"}
                    >
                      <Text
                        width={"100%"}
                        bgColor={Text.COLOR.PRIMARY}
                        borderRadius={"0 20px"}
                        padding={"10px 0"}
                        color={Text.COLOR.WHITE}
                        size={Text.SIZE.MOBILECATEGORY}
                        as={Text.VARIANT.SUBTITLE}
                        textAlign={"center"}
                      >
                        {title}
                      </Text>
                    </Box>

                    <MobileFeatureContent contents={contents} />
                  </Box>
                  <Button
                    width={"303px"}
                    height={"36px"}
                    margin={isLast ? "0" : "0 0 60px"}
                    themeStyle={Button.THEME.NONE}
                    borderRadius={"20px"}
                    border={"1px solid"}
                    borderColor={Button.COLOR.LINEGRAY}
                    bgColor={Button.COLOR.LIGHTGRAY}
                    fontSize={Button.FONT_SIZE.MOBILECONTENT}
                    color={Button.COLOR.TEXTGRAY}
                    onClick={() =>
                      changeLink(
                        `/feature/detail/${cate2}?type=${listviewBasic}`
                      )
                    }
                  >
                    <Text isSort as={Text.VARIANT.SPAN}>
                      <IoNewspaperOutline size={16} />

                      <Text as={Text.VARIANT.SPAN} margin={"0 2px 0 8px"}>
                        {more}
                      </Text>
                      <Text as={Text.VARIANT.SPAN} weight={Text.WEIGHT.BOLD}>
                        {articleCnt}
                        {unit}
                      </Text>
                    </Text>
                  </Button>
                </Fragment>
              );
            }
          )
        }
      </List>
    </Box>
  );
};

export default MobileFeature;
