import Box from "components/atoms/box";
import Text from "components/atoms/text";
import React from "react";
import CheckboxForm from "../checkboxForm";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";

const AdminDisplayOption = (props) => {
  const { name, displayStatus } = props;

  const { setValue } = useFormContext();

  useEffect(() => {
    if (displayStatus) {
      displayStatus === "Y" ? setValue(name, true) : setValue(name, false);
    }
  }, [name, displayStatus]);

  return (
    <Box
      display={Box.DISPLAY.FLEX}
      al="center"
      gap="5px"
      width="395px"
      height="100%"
      border="1px solid"
      borderColor={Box.COLOR.ADMINLINEGRAY}
      borderRadius={Box.RADIUS.SMALL}
    >
      <Box
        display={Box.DISPLAY.FLEX}
        al="center"
        height="100%"
        padding="0 17px"
        borderRight="1px solid"
        borderColor={Box.COLOR.ADMINLINEGRAY}
      >
        <Text as={Text.VARIANT.SUBTITLE} size={Text.SIZE.ADMINCONTENT}>
          메인페이지 - 주요 NEWS 노출
        </Text>
      </Box>
      <Box
        as="label"
        width="fit-content"
        display={Box.DISPLAY.FLEX}
        al="center"
        gap="5px"
        cursor="pointer"
        padding="0 17px"
        height="100%"
      >
        <CheckboxForm
          name={name}
          Label={() => (
            <Text as={Text.VARIANT.TEXT} size={Text.SIZE.ADMINCONTENT}>
              노출함
            </Text>
          )}
          // checked={list?.topNoticeYN === "Y" ? true : false}
          ariaLabel="뉴스 게시물 노출 여부"
        />
      </Box>
    </Box>
  );
};

export default AdminDisplayOption;
