import Box from "components/atoms/box";
import Text from "components/atoms/text";
import Comment from "components/organisms/comment";
import { NewsInfo } from "components/organisms/newsList";
import SideNews from "components/organisms/sideNews";
import { useBoardDetail, useUpdateViewCountMutation } from "hooks/api/board";
import useUrl from "hooks/utils/useUrl";
import React, { useEffect, useRef, useState } from "react";
import { BiChevronRight } from "@react-icons/all-files/bi/BiChevronRight";
import Button from "components/atoms/button";
import Image from "components/atoms/image";
import Print from "components/organisms/print";
import ReactToPrint from "react-to-print";
import useExternalToggle from "hooks/utils/useExternalToggle";
import ShareBox from "components/organisms/shareBox";
import TextSizeBox from "components/organisms/textSizeBox";
import { createPeriod } from "utils/calendar";
import { BsPaperclip } from "@react-icons/all-files/bs/BsPaperclip";
import downloadFile from "utils/downloadFile";
import detailText from "text/detail";
import Link from "components/atoms/link";
import { API_VERSION, SERVER_URL } from "api/config";

const { coverage, category, event, file } = detailText;

const Detail = () => {
  const { path, query } = useUrl();

  const { data, isSuccess } = useBoardDetail(path[1]);
  const shareRef = useRef();
  const textRef = useRef();
  const printRef = useRef(null);

  const updateViewCountMutation = useUpdateViewCountMutation();

  const [textSize, setTextSize] = useState(0);
  const [contents, setContents] = useState("");
  const [isShare, toggleIsShare] = useExternalToggle({
    defaultValue: false,
    target: shareRef,
  });
  const [isText, toggleIsText] = useExternalToggle({
    defaultValue: false,
    target: textRef,
  });

  const isCalendar = path[0] === "calendar";
  const categoryCode = query.category ?? "";
  const isCoverage = categoryCode === "COM045_AspbHPe";
  const [cate1, _] = categoryCode.split("_");

  useEffect(() => {
    if (isSuccess) {
      let contents = data.article.contents;
      const ptRegex = /FONT-SIZE: (\d+)pt/gi; // 'g' flag for global search
      const pxRegex = /FONT-SIZE: (\d+)px/gi; // 'g' flag for global search

      contents = contents.replace(ptRegex, function (match, p1) {
        return `FONT-SIZE: calc(${p1}pt + ${textSize * 3}pt)`;
      });

      contents = contents.replace(pxRegex, function (match, p1) {
        return `FONT-SIZE: calc(${p1}px + ${textSize * 3}pt)`;
      });

      setContents(contents);
    }
  }, [textSize, isSuccess, data, setContents]);

  useEffect(() => {
    updateViewCountMutation(path[1]);
  }, [path[1]]);

  return (
    isSuccess && (
      <>
        <Box
          display={Box.DISPLAY.FLEX}
          width={"1260px"}
          padding={"32px  0 32px 40px"}
          margin={"80px auto 40px"}
          bgColor={Box.COLOR.LIGHTGRAY}
          borderRadius="10px"
        >
          <Box width={"790px"} margin={"0 40px 0 0"}>
            <Box>
              <Text
                isSort
                as={Text.VARIANT.TEXT}
                size={Text.SIZE.CLIENTCONTENT}
                color={Text.COLOR.TEXTGRAY}
              >
                {data.article.cate1Name ?? category.cate1Name}{" "}
                <BiChevronRight />{" "}
                {data.article.cate2Name ?? category.cate2Name}
              </Text>

              <Text
                as={Text.VARIANT.TITLE}
                size={Text.SIZE.CLIENTTITLE}
                margin={"10px 0 8px"}
              >
                {data.article.title}
              </Text>

              <NewsInfo
                margin={"8px 0 16px"}
                size={Text.SIZE.CLIENTCONTENTDETAIL}
                writer={data.article.writer}
                regDate={data.article.regDate}
                commentCnt={data.article.commentCnt}
              />
            </Box>
            <Box
              padding="10px 0 50px"
              borderTop="1px solid"
              borderBottom="1px solid"
              borderColor={Box.COLOR.LINEGRAY}
            >
              <Box
                display={Box.DISPLAY.FLEX}
                jc={"flex-end"}
                wrap={"wrap"}
                width={"100%"}
                position="relative"
                margin={"0 0 40px"}
              >
                <ReactToPrint
                  trigger={() => (
                    <Button
                      themeStyle={Button.THEME.NONE}
                      margin={"0 0 0 14px"}
                    >
                      <Image src={"/assets/detail/print.svg"} alt={"print"} />
                    </Button>
                  )}
                  content={() => printRef.current}
                />

                <Box ref={textRef}>
                  <Button
                    themeStyle={Button.THEME.NONE}
                    margin={"0 0 0 14px"}
                    onClick={toggleIsText}
                  >
                    <Image
                      width={"32px"}
                      src={
                        isText
                          ? "/assets/detail/textActive.svg"
                          : "/assets/detail/text.svg"
                      }
                      alt={"text"}
                    />
                  </Button>

                  {isText && (
                    <TextSizeBox size={textSize} setSize={setTextSize} />
                  )}
                </Box>
                <Box ref={shareRef}>
                  <Button
                    themeStyle={Button.THEME.NONE}
                    margin={"0 0 0 14px"}
                    onClick={toggleIsShare}
                  >
                    <Image
                      src={
                        isShare
                          ? "/assets/detail/shareActive.svg"
                          : "/assets/detail/share.svg"
                      }
                      alt={"share"}
                    />
                  </Button>
                  {isShare && <ShareBox data={data} />}

                  <Link
                    margin={"0 0 0 14px"}
                    link={
                      isCalendar
                        ? `${SERVER_URL}/api/${API_VERSION}/article/rss/calendar`
                        : isCoverage
                        ? `${SERVER_URL}/api/${API_VERSION}/article/rss/coverage`
                        : `${SERVER_URL}/api/${API_VERSION}/article/rss/${cate1}/${query.category}`
                    }
                    target={"_blank"}
                  >
                    <Image src={"/assets/detail/rss.svg"} alt={"rss"} />
                  </Link>
                </Box>

                {(isCalendar || isCoverage) && (
                  <Box
                    width={"100%"}
                    bgColor={Box.COLOR.WHITE}
                    borderRadius={"5px"}
                    padding={"20px 26px"}
                    margin={"20px 0 0"}
                  >
                    <Text
                      as={Text.VARIANT.TEXT}
                      color={Text.COLOR.TEXTGRAY}
                      size={Text.SIZE.CLIENTETC}
                      isSort
                      width={"100% "}
                    >
                      <Text
                        as={Text.VARIANT.SPAN}
                        width={"68px"}
                        textAlign={"center"}
                        padding={"1px 10px"}
                        margin={"0 8px 0 0"}
                        bgColor={Text.COLOR.SECONDARY}
                        color={Text.COLOR.WHITE}
                        size={Text.SIZE.CLIENTETC}
                        borderRadius={"60px"}
                      >
                        {isCoverage ? coverage.period : event.period}
                      </Text>
                      {data.article.eventBeginDate} ~{" "}
                      {data.article.eventEndDate}
                      {createPeriod(
                        data.article.eventBeginHour,
                        data.article.eventBeginMin,
                        data.article.eventEndHour,
                        data.article.eventEndMin
                      )}
                    </Text>
                    {!isCoverage && (
                      <>
                        <Text
                          as={Text.VARIANT.TEXT}
                          color={Text.COLOR.TEXTGRAY}
                          size={Text.SIZE.CLIENTETC}
                          isSort
                          width={"100% "}
                          margin={"8px 0"}
                        >
                          <Text
                            as={Text.VARIANT.SPAN}
                            width={"68px"}
                            textAlign={"center"}
                            padding={"1px 10px"}
                            margin={"0 8px 0 0"}
                            bgColor={Text.COLOR.SECONDARY}
                            color={Text.COLOR.WHITE}
                            size={Text.SIZE.CLIENTETC}
                            borderRadius={"60px"}
                          >
                            {event.pre}
                          </Text>
                          {data.article.preBeginDate &&
                            `${data.article.preBeginDate} ~ ${data.article.preEndDate}`}
                        </Text>
                        <Text
                          as={Text.VARIANT.TEXT}
                          color={Text.COLOR.TEXTGRAY}
                          size={Text.SIZE.CLIENTETC}
                          isSort
                          width={"100% "}
                        >
                          <Text
                            as={Text.VARIANT.SPAN}
                            width={"68px"}
                            textAlign={"center"}
                            padding={"1px 10px"}
                            margin={"0 8px 0 0"}
                            bgColor={Text.COLOR.SECONDARY}
                            color={Text.COLOR.WHITE}
                            size={Text.SIZE.CLIENTETC}
                            borderRadius={"60px"}
                          >
                            {event.place}
                          </Text>

                          {data.article.eventPlace}
                        </Text>
                      </>
                    )}
                  </Box>
                )}
              </Box>
              <div
                className="detailContents"
                style={{
                  fontSize: `calc(16px + ${textSize * 3}pt)`,
                }}
                dangerouslySetInnerHTML={{ __html: contents }}
              />
              {data.fileList && data.fileList.length > 0 && (
                <Box
                  width={"100%"}
                  margin={"80px 0 0"}
                  padding={"20px"}
                  border="1px solid"
                  borderColor={Box.COLOR.LINEGRAY}
                  borderRadius={"20px"}
                  bgColor={Box.COLOR.WHITE}
                >
                  <Text
                    as={Text.VARIANT.TEXT}
                    size={Text.SIZE.CLIENTCONTENT}
                    weight={Text.WEIGHT.BOLD}
                  >
                    {file}
                  </Text>

                  {data.fileList.map(
                    ({
                      fileId,
                      fileName,
                      filePath,
                      fileExtsn,
                      originFileName,
                    }) => (
                      <Box
                        display={Box.DISPLAY.FLEX}
                        wrap={"wrap"}
                        margin={"20px 0 0"}
                        gap={"0 30px"}
                        key={fileId}
                      >
                        <Button
                          themeStyle={Button.THEME.NONE}
                          onClick={() =>
                            downloadFile(
                              filePath,
                              fileName,
                              fileExtsn,
                              originFileName
                            )
                          }
                          margin={"0 0 12px 0"}
                        >
                          <Box display={Box.DISPLAY.FLEX}>
                            <BsPaperclip size={20} color={"#747474"} />
                            <Text
                              as={Text.VARIANT.SPAN}
                              size={Text.SIZE.CLIENTCONTENT}
                              lineHeight={"22px"}
                              borderBottom={"1px solid"}
                              borderColor={Text.COLOR.LINEGRAY}
                              color={Text.COLOR.TEXTGRAY}
                              maxWidth={"200px"}
                              lineClamp={1}
                              wordBreak={"break-all"}
                            >
                              {originFileName.split(".")[0]}
                            </Text>
                            <Text
                              as={Text.VARIANT.SPAN}
                              size={Text.SIZE.CLIENTCONTENT}
                              lineHeight={"22px"}
                              borderBottom={"1px solid"}
                              borderColor={Text.COLOR.LINEGRAY}
                              color={Text.COLOR.TEXTGRAY}
                            >
                              .{originFileName.split(".")[1]}
                            </Text>
                          </Box>
                        </Button>
                      </Box>
                    )
                  )}
                </Box>
              )}
            </Box>

            <Comment
              commentCnt={data.article.commentCnt}
              commentOn={data.commentOnOff === "Y"}
            />
          </Box>
          <SideNews />
        </Box>

        <Print data={data} ref={printRef} />
      </>
    )
  );
};

export default Detail;
