import { SERVER_URL } from "api/config";

const convertImage = (image, type) => {
  return image
    ? type
      ? `${SERVER_URL}/upload/${type}/${image}`
      : SERVER_URL + image
    : null;
};

export default convertImage;
