import Box from "components/atoms/box";
import ImageUploader from "components/molecules/imageUploader";
import InputForm from "components/molecules/inputForm";
import TextArea from "components/molecules/textarea";
import React, { useState } from "react";
import LinkList from "../linkList";
import Text from "components/atoms/text";
import Button from "components/atoms/button";
import Image from "components/atoms/image";

const AdminNewsletterForm = (props) => {
  const { formIdx, defaultList, no } = props;

  const [isDisabled, setIsDisabled] = useState(false);

  const generateContentsFormInfo = (idxValue, defaultList) => [
    {
      type: "title",
      title: "제목",
      minHeight: "48px",
      required: true,
      isUpperPosition: false,
      components: () => (
        <InputForm
          name={`sec${idxValue + 1}Title`}
          defaultName={defaultList ? defaultList[`sec${idxValue + 1}Title`] : ""}
          type="text"
          width="431px"
          height="32px"
          padding="0 12px"
          theme="secondary"
          required="필수값입니다."
          ariaLabel="회차"
        />
      ),
    },
    {
      type: "contents",
      title: "내용",
      minHeight: "48px",
      required: true,
      isUpperPosition: true,
      components: () => (
        <TextArea
          name={`sec${idxValue + 1}Contents`}
          defaultValue={defaultList ? defaultList[`sec${idxValue + 1}Contents`] : ""}
          width="727px"
          height="144px"
          padding="12px"
          ariaLabel="요약내용"
          required="필수값 입니다."
        />
      ),
    },
    {
      type: "image",
      title: "이미지 등록",
      minHeight: "296px",
      required: true,
      isUpperPosition: true,
      components: () => (
        <ImageUploader
          registerName={`sec${idxValue + 1}Img`}
          defaultPath={defaultList ? defaultList[`sec${idxValue + 1}Img`] : ""}
          comments={["- 권장 이미지 : 506px*326px / 10mb 이하 / jpg,png 파일"]}
          serverImgPath="newsletter"
          required={defaultList ? false : "필수값입니다."}
        />
      ),
    },
    {
      type: "link",
      title: "링크",
      minHeight: isDisabled ? "auto" : "102px",
      required: true,
      isUpperPosition: true,
      components: () => (
        <LinkList
          radioName={`sec${idxValue + 1}Link`}
          inputName={`sec${idxValue + 1}Url`}
          checkName={`sec${idxValue + 1}NewWindow`}
          defaultLink={defaultList ? defaultList[`sec${idxValue + 1}Url`] : ""}
          defaultCheckValue={defaultList ? defaultList[`sec${idxValue + 1}NewWindow`] : "N"}
          required={defaultList ? defaultList[`sec${idxValue + 1}Url`] && false : "필수값 입니다."}
          setIsDisabled={setIsDisabled}
        />
      ),
    },
  ];

  const formIdxList = [0, 1];

  const generateFormInfos = (formIdxList, defaultList) =>
    formIdxList.map((idx) => generateContentsFormInfo(idx, defaultList));

  const newContentsFormInfo = generateFormInfos(formIdxList, defaultList);

  const [isOpen, setIsOpen] = useState([true, true]);

  const handleShowContents = (e, idx) => {
    e.preventDefault();
    const updatedState = [...isOpen];
    updatedState[idx] = !updatedState[idx];
    setIsOpen(updatedState);
  };

  const ContentsBox = ({ idx, handleShowContents }) => {
    return (
      <Box key={idx} margin={idx === 1 ? "20px 0 0 0" : "0"}>
        <Box
          display={Box.DISPLAY.FLEX}
          al="center"
          jc="space-between"
          padding="17px 20px"
          borderLeft="1px solid"
          borderRight="1px solid"
          borderTop="1px solid"
          borderBottom="1px solid"
          borderColor={Box.COLOR.ADMINLINEGRAY}
        >
          <Box
            display={Box.DISPLAY.FLEX}
            al="center"
            gap="15px"
            borderColor={Box.COLOR.ADMINLINEGRAY}
          >
            <Box>
              <Text as={Text.VARIANT.SUBTITLE}>콘텐츠 내용</Text>
            </Box>
          </Box>
          <Button themeStyle="none" onClick={(e) => handleShowContents(e, idx)}>
            <Image src={`/assets/admin/arrow_${isOpen[idx] ? "down" : "up"}.png`} />
          </Button>
        </Box>
      </Box>
    );
  };

  return (
    <>
      <ContentsBox idx={no} handleShowContents={handleShowContents} />
      {isOpen[formIdx] &&
        newContentsFormInfo[formIdx].map(
          ({ title, height, minHeight, required, isUpperPosition, components }, idx) => (
            <Box
              display={Box.DISPLAY.FLEX}
              al="center"
              height={height}
              key={idx}
              borderBottom="1px solid"
              borderLeft="1px solid"
              borderRight="1px solid"
              borderColor={Box.COLOR.ADMINLINEGRAY}
              bgColor={Box.COLOR.ADMINCONTENTSBG}
            >
              <Box
                width="160px"
                height="100%"
                minHeight={minHeight}
                padding="12px 16px"
                display={Box.DISPLAY.FLEX}
                gap="5px"
                al={isUpperPosition ? "flex-start" : "center"}
              >
                <Text size={Text.SIZE.ADMINCONTENT} as={Text.VARIANT.SUBTITLE}>
                  {title}
                </Text>
                {required && (
                  <Text
                    color={Text.COLOR.SECONDARY}
                    size={Text.SIZE.ADMINCONTENT}
                    as={Text.VARIANT.SPAN}
                  >
                    *
                  </Text>
                )}
              </Box>
              <Box
                position="relative"
                width="100%"
                height="100%"
                minHeight={minHeight}
                padding="8px 12px"
                borderLeft="1px solid"
                borderColor={Box.COLOR.ADMINLINEGRAY}
                bgColor={Box.COLOR.WHITE}
              >
                {components(formIdx)}
              </Box>
            </Box>
          )
        )}
    </>
  );
};

export default AdminNewsletterForm;
