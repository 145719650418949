import { GET, POST, PUT } from "api/config";
import { membership } from "../url";

const { memberUrl, loginLogUrl, terminationUrl } = membership;

// 회원관리 목록
export const getMemberList = async ({
  page = "1",
  itemsPerPage = "10",
  receptionStatus = "",
  loginStatus = "",
  searchCondition = "",
  searchValue = "",
}) =>
  await GET(
    `${memberUrl}?pageIndex=${page}&itemsPerPage=${itemsPerPage}&newsYN=${receptionStatus}&userType=${loginStatus}&searchCondition=${searchCondition}&searchKeyword=${searchValue}`
  );

// 회원관리 상세 목록
export const getMemberDetailList = async ({ key }) => await GET(`${memberUrl}/${key}`);

// 회원 등록
export const createMember = async ({ data }) => {
  await POST(`${memberUrl}`, data);
};

// 회원 수정
export const updateMemberInfo = async ({ key, data }) => {
  await PUT(`${memberUrl}/${key}`, data);
};

// 회원 임시 비밀번호 발급
export const updateMemberPassword = async ({ key }) => {
  await PUT(`${memberUrl}/${key}/password`);
};

// 회원 삭제
export const deleteMember = async ({ data }) => {
  await PUT(`${memberUrl}`, data);
};

// 회원 로그인 현황
export const getMemberLoginLog = async ({
  page = "1",
  itemsPerPage = "10",
  searchValue = "",
  searchCondition = "",
  startDate = "",
  endDate = "",
}) =>
  await GET(
    `${loginLogUrl}?pageIndex=${page}&itemsPerPage=${itemsPerPage}&searchKeyword=${searchValue}&searchCondition=${searchCondition}&startDate=${startDate}&endDate=${endDate}`
  );

// 탈퇴회원 목록
export const getTerminationMemberList = async ({
  page = "1",
  itemsPerPage = "10",
  receptionStatus = "",
  searchCondition = "",
  searchValue = "",
}) =>
  await GET(
    `${terminationUrl}?pageIndex=${page}&itemsPerPage=${itemsPerPage}&newsYN=${receptionStatus}&searchCondition=${searchCondition}&searchKeyword=${searchValue}`
  );
