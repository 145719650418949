import { GET, POST } from "api/config";

export const getBoardNews = async (boardType, boardCategory, page, limit = 8) =>
  await GET(
    `/board/${boardType}/${boardCategory}?pageIndex=${page}&itemsPerPage=${limit}`
  );

export const getBoardDetail = async (id) => {
  try {
    return await GET(`/article/${id}`);
  } catch (error) {}
};

export const getBoardDaily = async (boardCategory, getDate, page, limit) =>
  await GET(
    `/board/dailynews/${boardCategory}/${getDate}?pageIndex=${page}&itemsPerPage=${limit}`
  );

export const getBoardDailyMonth = async (month) =>
  await GET(`/board/dailynews/monthAll/${month}`);

export const getBoardCalendar = async (
  type,
  category,
  date,
  page,
  searchKeyword
) =>
  await GET(
    `/board/${type}/${category}/${date}?pageIndex=${page}&searchKeyword=${searchKeyword}`
  );

export const getBoardCalendarMonth = async (type, category, date) =>
  await GET(`/board/${type}/${category}/month/${date}`);

export const getBoardFeature = async (page, limit = 5) =>
  await GET(`/board/feature?pageIndex=${page}&itemsPerPage=${limit}`);

export const getBoardFeatureDetail = async (id, page) =>
  await GET(`/board/feature/${id}?pageIndex=${page}`);

export const updateViewCount = async (id) => await POST(`/views/${id}`);
