import Box from "components/atoms/box";
import Button from "components/atoms/button";
import Image from "components/atoms/image";
import useUrl from "hooks/utils/useUrl";
import React from "react";
import { useEffect } from "react";
import { forwardRef } from "react";

const ModalContainer = forwardRef((props, ref) => {
  const {
    width,
    height,
    padding = "15px",
    setIsOpen,
    radius = "10px",
    children,
    isPopupBg = false,
  } = props;

  const { path } = useUrl();
  const isNewsletter = path[1] === "management_newsletter";

  useEffect(() => {
    if (isNewsletter) {
      document.body.style.overflow = "auto";
    } else {
      document.body.style.overflow = "hidden";
    }

    if (!ref) return;

    const handleClickOutside = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        setIsOpen(false);
      }
    };
    document.body.addEventListener("click", handleClickOutside);

    return () => {
      document.body.style.overflow = "auto";
      document.body.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <Box
      position="absolute"
      height="100vh"
      width="100%"
      left="0"
      top="0"
      al="center"
      jc="center"
      bgColor={isPopupBg ? Box.COLOR.TRANSPARENT : Box.COLOR.MODALBG}
    >
      <Box
        width={width}
        height="100%"
        margin="0 auto"
        display={Box.DISPLAY.FLEX}
        flexDirection="column"
        jc="center"
      >
        <Box
          ref={ref}
          width="100%"
          height={height}
          padding={padding}
          bgColor={Box.COLOR.WHITE}
          borderRadius={radius}
        >
          <Box width="100%" display={Box.DISPLAY.FLEX} jc="flex-end" margin="0 0 5px 0">
            <Button themeStyle="none" onClick={() => setIsOpen(false)}>
              <Image src="/assets/admin/round_close.png" />
            </Button>
          </Box>
          {children}
        </Box>
      </Box>
    </Box>
  );
});

export default ModalContainer;
