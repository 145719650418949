import {
  deleteRequest,
  getManagerEmail,
  getRequestDetailList,
  getRequestList,
  updateManagerEmail,
  updateRequestInfo,
} from "api/admin/cs";
import useHandleAlert from "hooks/utils/useHandleAlert";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { common } from "text/admin/common";

const {
  success: { remove, update },
} = common.alert;

// 관리자에게 목록
export const useRequestToManagerList = ({
  page,
  itemsPerPage,
  status,
  searchCondition,
  searchValue,
}) => {
  const { data, isLoading, isSuccess, isError } = useQuery(
    ["requestToManagerList", page, itemsPerPage, status, searchCondition, searchValue],
    () =>
      getRequestList({
        page,
        itemsPerPage,
        status,
        searchCondition,
        searchValue,
      })
  );

  const list = data?.data.list;
  const totalCnt = data?.data.totalCnt;
  const totalPageIndex = data?.data.totalPageIndex;

  return {
    list,
    totalCnt,
    totalPageIndex,
    isLoading,
    isSuccess,
    isError,
  };
};

// 관리자에게 상세 목록
export const useRequestDetailList = ({ key }) => {
  const { data, isLoading, isSuccess, isError } = useQuery({
    queryKey: ["equestDetailList", key],
    queryFn: () => getRequestDetailList({ key }),
    enabled: !!key,
  });

  const list = data?.data.adminRequestDetail;

  return {
    list,
    isLoading,
    isSuccess,
    isError,
  };
};

// 관리자에게 수정
export const useUpdateRequestInfo = () => {
  const { handleGoBack } = useHandleAlert();
  const queryClient = useQueryClient();
  const updateRequestInfoMutation = useMutation(updateRequestInfo, {
    onSuccess: () => {
      alert(update);
      queryClient.invalidateQueries("requestToManagerList");
      handleGoBack();
    },
  });

  const mutate = ({ key, data }) => {
    updateRequestInfoMutation.mutate({ key, data });
  };

  return mutate;
};

// 관리자에게 삭제
export const useDeleteRequest = (reset) => {
  const queryClient = useQueryClient();
  const deleteRequestMutation = useMutation(deleteRequest, {
    onSuccess: () => {
      alert(remove);
      queryClient.invalidateQueries("requestToManagerList");
      reset();
    },
  });

  const mutate = ({ data }) => {
    deleteRequestMutation.mutate({ data });
  };

  return mutate;
};

// 관리자에게 목록
export const useGetManagerEmail = () => {
  const { data, isLoading, isSuccess, isError } = useQuery(["managerEmail"], () =>
    getManagerEmail()
  );

  return {
    data,
    isLoading,
    isSuccess,
    isError,
  };
};

// 관리자에게 이메일 수정
export const useUpdateManagerEmail = () => {
  const queryClient = useQueryClient();
  const updateManagerEmailMutation = useMutation(updateManagerEmail, {
    onSuccess: () => {
      alert(update);
      queryClient.invalidateQueries("managerEmail");
    },
  });

  const mutate = ({ data }) => {
    updateManagerEmailMutation.mutate({ data });
  };

  return mutate;
};
