import { create } from "zustand";

const useUserStore = create((set) => ({
  user: null,
  authority: sessionStorage.getItem("authority") || null,

  setUser: (user) => set({ user }),
  setAuthority: (authority) => {
    sessionStorage.setItem("authority", JSON.stringify(authority));
    set({ authority });
  },
}));

export default useUserStore;
