import React, { useEffect, useState } from "react";
import Box from "components/atoms/box";
import InputForm from "components/molecules/inputForm";
import Text from "components/atoms/text";
import RadioButton from "components/molecules/radioButton";
import { useFormContext } from "react-hook-form";

const CompanyGroup = (props) => {
  const { companyGroup, companyName } = props;

  const buttonList = [
    { title: "내부 직원", value: "1", ariaLabel: "내부 직원" },
    { title: "외부 직원", value: "2", ariaLabel: "외부 직원" },
  ];

  const { setValue } = useFormContext();

  const [selectedRadioOption, setSelectedRadioOption] = useState("");

  useEffect(() => {
    setSelectedRadioOption(companyGroup);
  }, [companyGroup]);

  useEffect(() => {
    setValue("companyGroup", selectedRadioOption);
  }, [selectedRadioOption]);

  return (
    <Box display={Box.DISPLAY.FLEX} jc="center" flexDirection="column" height="100%">
      <Box display={Box.DISPLAY.FLEX} al="center" gap="20px">
        <RadioButton
          list={buttonList}
          registerName="companyGroup"
          selectedRadioOption={selectedRadioOption}
          setSelectedRadioOption={setSelectedRadioOption}
          errorMessage="필수값 입니다."
          name="companyGroup"
          required
        />
      </Box>
      <InputForm
        name="companyName"
        type="text"
        width="240px"
        height="32px"
        padding="0 12px"
        margin="13px 0 8px 0"
        size="adminContentDetail"
        theme="secondary"
        defaultName={companyName ?? ""}
        disabled={selectedRadioOption === "1"}
        ariaLabel="내부/외부 직원 선택"
      />
      <Box
        as="label"
        width="fit-content"
        display={Box.DISPLAY.FLEX}
        al="center"
        gap="5px"
        cursor="pointer"
      >
        <Text as={Text.VARIANT.TEXT} size={Text.SIZE.ADMINCONTENTDETAIL}>
          - 외부직원의 경우 대표 소속 입력 (내부직원은 입력 불필요)
        </Text>
      </Box>
    </Box>
  );
};

export default CompanyGroup;
