import Box from "components/atoms/box";
import Text from "components/atoms/text";
import React, { useEffect, useState } from "react";
import generateMenuIcons from "utils/generateMenuIcons";
import { HiOutlineChevronRight } from "@react-icons/all-files/hi/HiOutlineChevronRight";
import { HiOutlineExternalLink } from "@react-icons/all-files/hi/HiOutlineExternalLink";
import Button from "components/atoms/button";
import useUrl from "hooks/utils/useUrl";
import { useMenuAuthorityMutation } from "hooks/api/admin/authority";
import { common } from "text/admin/common";

const SideBarMenu = (props) => {
  const {
    title,
    link,
    isDropdown,
    menuNo,
    dropdownMenu,
    idx,
    activeDropdownIdx,
    setActiveDropdownIdx,
  } = props;

  const { authority } = common.alert.failed;

  const [isActiveMenu, setIsActiveMenu] = useState(false);
  const isOpenMenu = parseInt(activeDropdownIdx) === idx;

  const { mutate } = useMenuAuthorityMutation();

  const { path, changeLink } = useUrl();
  const isDetailPage = path[3] === "detail";
  const currCategory = isDetailPage ? path[path.length - 2] : path[path.length - 1];

  // 운영자 계정별 메뉴 권한 (대시보드 제외)
  const handleRouter = (title, link, menuNo) => {
    if (title === "대시보드") {
      changeLink(link);
      setActiveDropdownIdx(null);
    } else {
      mutate(
        { menuNo },
        {
          onSuccess: () => {
            if (title === "통계") {
              window.open(link, "_blank");
              setActiveDropdownIdx(null);
            } else if (isOpenMenu) {
              changeLink(link);
            }
          },
          onError: () => {
            alert(authority);
          },
        }
      );
    }
  };

  const handleDropdown = (idx) => {
    const defaultLink = dropdownMenu[0].link;
    setActiveDropdownIdx(idx === parseInt(activeDropdownIdx) ? null : idx);
    changeLink(defaultLink);
  };

  const getCurrPath = (link) => {
    const currPath = link?.split("/");
    return currPath[currPath.length - 1];
  };

  useEffect(() => {
    let isActive = false;

    if (isOpenMenu) {
      dropdownMenu.forEach((menu) => {
        if (getCurrPath(menu.link) === currCategory) {
          isActive = true;
          sessionStorage.setItem("selectedId", idx);
        } else if (isDetailPage) {
          isActive = true;
        }
      });
    } else if (link) {
      if (getCurrPath(link) === currCategory) {
        isActive = true;
        sessionStorage.removeItem("selectedId");
      }
    }

    setIsActiveMenu(isActive);
  }, [path, link, isDetailPage]);

  return (
    <>
      <Box
        display={Box.DISPLAY.FLEX}
        al="center"
        padding="0 20px 0 36px"
        isActiveMenu={isActiveMenu}
        isNotActiveMenu={!isActiveMenu}
        bgColor={isOpenMenu ? Box.COLOR.SECONDARY : Box.COLOR.SIDEBAR}
      >
        <Button
          onClick={isDropdown ? () => handleDropdown(idx) : () => handleRouter(title, link, menuNo)}
          size="full"
          sort="between"
          padding="16px 0"
          themeStyle={Button.THEME.SIDEBARMENU}
          color={isOpenMenu ? Button.COLOR.WHITE : Button.COLOR.SIDEMENU}
        >
          <Box display={Box.DISPLAY.FLEX} al="center" gap="10px">
            {generateMenuIcons(title, isActiveMenu, isOpenMenu)}
            <Text
              as={Text.VARIANT.TEXT}
              size={Text.SIZE.ADMINCATEGORY}
              weight={Text.WEIGHT.NORMAL}
              color={isOpenMenu ? Text.COLOR.WHITE : "none"}
            >
              {title}
            </Text>
          </Box>
          {title === "통계" ? (
            <HiOutlineExternalLink size="16" />
          ) : (
            <HiOutlineChevronRight size="16" />
          )}
        </Button>
      </Box>
      {isOpenMenu && (
        <Box
          display={Box.DISPLAY.FLEX}
          flexDirection="column"
          al="center"
          gap="18px"
          margin="18px 0"
          themeStyles="dropdown"
        >
          {dropdownMenu?.map(({ title, link, menuNo }, idx) => (
            <Box
              width="100%"
              key={idx}
              padding="0 20px 0 70px"
              isActiveDropdownMenu={getCurrPath(link) === currCategory}
            >
              <Button
                onClick={() => handleRouter(title, link, menuNo)}
                size="full"
                sort="start"
                themeStyle={Button.THEME.SIDEBARMENU}
                color={Button.COLOR.SIDEMENU}
              >
                <Text
                  as={Text.VARIANT.TEXT}
                  size={Text.SIZE.ADMINCONTENT}
                  weight={Text.WEIGHT.NORMAL}
                >
                  {title}
                </Text>
              </Button>
            </Box>
          ))}
        </Box>
      )}
    </>
  );
};

export default SideBarMenu;
