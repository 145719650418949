import Box from "components/atoms/box";
import MobileBoard from "components/organisms/mobileBoard";
import useUrl from "hooks/utils/useUrl";
import React from "react";

const MobilePeople = () => {
  const {
    query: { category = "all" },
  } = useUrl();

  const boardType = "people";

  return (
    <>
      <Box margin={"145px 0 0 0"} padding={"20px 0 0"}></Box>
      <MobileBoard isAllPage={false}>
        <MobileBoard.News category={category} boardType={boardType} />
      </MobileBoard>
    </>
  );
};

export default MobilePeople;
