import adminUrl from "./adminUrl.json";

export const {
  dashboard,
  category,
  site,
  manage,
  calendar,
  coverage,
  article,
  newsletter,
  cs,
  membership,
  adminDefaultUrl,
} = adminUrl;
