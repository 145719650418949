import Box from "components/atoms/box";
import Button from "components/atoms/button";
import Text from "components/atoms/text";
import React from "react";
import { useState } from "react";
import DashboardChart from "components/molecules/dashboardChart";
import Image from "components/atoms/image";
import { dashboard } from "text/admin/dashboard";
import { useTodayVisiorsCount } from "hooks/api/admin/dashboard";
import { useEffect } from "react";
import Loading from "components/atoms/loading";

const DashboardVisitorCount = () => {
  const [selectedType, setSelectedType] = useState("today");
  const [gaData, setGaData] = useState([]);

  const { list, isSuccess, isLoading } = useTodayVisiorsCount({ type: selectedType });

  useEffect(() => {
    if (list) {
      const data = list.map((item) => ({
        name: item.date,
        All: parseInt(item.total ?? 0, 10),
        PC: parseInt(item.desktop ?? 0, 10),
        Mobile: parseInt(item.mobile ?? 0, 10),
      }));
      setGaData(data);
    }
  }, [list]);

  const { buttonList, upperIconList } = dashboard.visitor;

  return (
    <Box flex="1 1 0">
      <Text as={Text.VARIANT.SUBTITLE} weight={Text.WEIGHT.BOLD} ariaLabel="방문자 수">
        방문자 수
      </Text>
      <Box
        height="418px"
        display={Box.DISPLAY.FLEX}
        flexDirection="column"
        al="center"
        border="1px solid"
        borderColor={Box.COLOR.LINEGRAY}
        margin="8px 0 0 0"
      >
        <Box
          width="100%"
          display={Box.DISPLAY.FLEX}
          al="center"
          jc="space-between"
          padding="15px 20px 0"
          borderBottom="1px solid"
          borderColor={Box.COLOR.LINEGRAY}
        >
          <Box>
            {buttonList.map(({ title, value, ariaLabel }, idx) => (
              <Button
                onClick={() => setSelectedType(value)}
                themeStyle="none"
                padding="10px 17px 20px"
                borderBottom={selectedType === value ? "2px solid" : "none"}
                borderColor={selectedType === value ? Button.COLOR.SECONDARY : ""}
                ariaLabel={ariaLabel}
                key={idx}
              >
                <Text
                  as={Text.VARIANT.SUBTITLE}
                  size={Text.SIZE.ADMINCONTENT}
                  weight={selectedType === value ? Text.WEIGHT.BOLD : Text.WEIGHT.NORMAL}
                  color={selectedType === value ? Text.COLOR.SECONDARY : Text.COLOR.BLACK}
                >
                  {title}
                </Text>
              </Button>
            ))}
          </Box>
          <Box display={Box.DISPLAY.FLEX} al="center" gap="15px" padding="0 0 14px 0">
            {upperIconList.map(({ title, src, alt }, idx) => (
              <Box display={Box.DISPLAY.FLEX} al="center" gap="5px" key={idx}>
                <Image src={src} alt={alt} />
                <Text as={Text.VARIANT.SPAN} size={Text.SIZE.ADMINCONTENTDETAIL}>
                  {title}
                </Text>
              </Box>
            ))}
          </Box>
        </Box>
        <Box
          margin={isLoading ? "0" : "30px 0 0 0"}
          flex="1 1 0"
          display={Box.DISPLAY.FLEX}
          al="center"
        >
          {isLoading && <Loading />}
          {isSuccess && <DashboardChart data={gaData} />}
        </Box>
      </Box>
    </Box>
  );
};

export default DashboardVisitorCount;
