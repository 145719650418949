import Box from "components/atoms/box";
import Button from "components/atoms/button";
import Link from "components/atoms/link";
import Text from "components/atoms/text";
import useToggle from "hooks/utils/useToggle";
import useUrl from "hooks/utils/useUrl";
import React from "react";
import { BsChevronDown } from "@react-icons/all-files/bs/BsChevronDown";
import { BsChevronUp } from "@react-icons/all-files/bs/BsChevronUp";

const MobileMenu = (props) => {
  const { title, link, subMenu } = props;
  const [isToggle, toggle] = useToggle(false);
  const { changeLink } = useUrl();

  const isSubMenu = subMenu && subMenu.length > 1;
  const handleClickButton = isSubMenu ? toggle : () => changeLink(link);

  return (
    <Box margin={"0 0 16px"}>
      <Button
        width={"100%"}
        themeStyle={Button.THEME.NONE}
        onClick={handleClickButton}
        display={Box.DISPLAY.FLEX}
        jc={"space-between"}
      >
        <Text as={Text.VARIANT.SPAN} size={Text.SIZE.CLIENTCONTENT}>
          {title}
        </Text>

        {isSubMenu &&
          (isToggle ? (
            <BsChevronUp color={"#747474"} size={16} />
          ) : (
            <BsChevronDown color={"#747474"} size={16} />
          ))}
      </Button>

      {isToggle && (
        <Box
          display={Box.DISPLAY.FLEX}
          flexDirection={"column"}
          padding={"0 0 0 16px"}
        >
          {subMenu.map(({ code, codeName, root, listviewBasic }, index) => (
            <Link
              link={`/${root}?category=${code}&${
                listviewBasic ? `type=${listviewBasic}` : ""
              }`}
              key={codeName}
              ariaLabel={`${root} 서브 메뉴 ${codeName} 링크`}
              margin={"16px 0 0"}
            >
              <Text
                as={Text.VARIANT.TEXT}
                color={Text.COLOR.TEXTGRAY}
                size={Text.SIZE.CLIENTCONTENT}
              >
                {codeName}
              </Text>
            </Link>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default MobileMenu;
