import Box from "components/atoms/box";
import List from "components/molecules/list";
import Board from "components/organisms/board";
import CalendarHeader from "components/organisms/calendarHeader";
import NewsList from "components/organisms/newsList";
import dayjs from "dayjs";
import { useBoardDaily, useBoardDailyMonth } from "hooks/api/board";
import useUrl from "hooks/utils/useUrl";
import React from "react";
import Text from "components/atoms/text";
import { FiChevronLeft } from "@react-icons/all-files/fi/FiChevronLeft";
import { FiChevronRight } from "@react-icons/all-files/fi/FiChevronRight";
import Button from "components/atoms/button";
import {
  changeWeek,
  getDates,
  getMonths,
  getWeeks,
  updateDate,
} from "utils/calendar";
import Blank from "components/molecules/blank";
import dailyText from "text/daily";

const { categoryFormat } = dailyText;

const categoryForm = {
  daily: {
    ...categoryFormat.daily,
    picker: "date",
    headListForm: getDates,
    defaultValue: dayjs().format("YYYYMMDD"),
    handleChangeDate: (changeQuery) => (date) => {
      changeQuery({
        date: date.format("YYYYMMDD"),
        calendar: date.format("YYYYMMDD"),
      });
    },
  },
  week: {
    ...categoryFormat.week,
    picker: "week",
    headListForm: getWeeks,
    defaultValue: changeWeek(dayjs()),
    handleChangeDate: (changeQuery) => (date, week) => {
      changeQuery({
        date: changeWeek(date),
        calendar: date.format("YYYYMMDD"),
      });
    },
  },
  month: {
    ...categoryFormat.month,
    picker: "month",
    headListForm: getMonths,
    defaultValue: dayjs().format("YYYYMM"),
    handleChangeDate: (changeQuery) => (date) => {
      changeQuery({
        date: date.format("YYYYMM"),
        calendar: date.format("YYYYMMDD"),
      });
    },
  },
};

const handleCheckActive = (list, date) => {
  let isActive = false;
  list.forEach(({ regDate }) => {
    const regDay = dayjs(regDate).format("MMDD");

    if (date === regDay) {
      isActive = true;
    }
  });

  return isActive;
};

const Daily = () => {
  const {
    query: { page = "1", category = "daily", date, calendar = dayjs() },
    changeQuery,
  } = useUrl();

  const calendarDate = categoryForm[category];
  const headList = calendarDate.headListForm(dayjs(calendar));

  const handleChangeDate = (dateStr, value, type) => {
    const { calendar, date } = updateDate(dateStr, value, type);

    changeQuery({
      calendar,
      date,
      page: 1,
    });
  };

  return (
    <Box
      width="100%"
      display={Box.DISPLAY.FLEX}
      flexDirection="column"
      al="center"
    >
      <Board isAllPage={false}>
        <CalendarHeader
          {...calendarDate}
          isDaily
          isCategory
          preFetch={useBoardDailyMonth}
          margin={"30px 0 50px"}
          handleCheckActive={handleCheckActive}
          head={() => (
            <Box
              display={Box.DISPLAY.FLEX}
              jc={"space-between"}
              al={"center"}
              borderBottom={"1px solid"}
              borderColor={Box.COLOR.LINEGRAY}
              padding={"50px 20px"}
            >
              <Button
                themeStyle={Button.THEME.NONE}
                onClick={() =>
                  handleChangeDate(headList[0], 1, calendarDate.picker)
                }
              >
                <Text as={Text.VARIANT.SPAN} color={Text.COLOR.PRIMARY}>
                  <FiChevronLeft size={20} />
                </Text>
              </Button>
              {headList.map((link, idx) => {
                const { calendar } = updateDate(link, 0, calendarDate.picker);
                const isActive = link === date || (!date && idx === 0);

                return (
                  <Button
                    key={link}
                    themeStyle={Button.THEME.NONE}
                    onClick={() =>
                      changeQuery({
                        date: link,
                        calendar,
                        page: "1",
                      })
                    }
                  >
                    <Text
                      as={Text.VARIANT.SPAN}
                      color={Text.COLOR.PRIMARY}
                      size={Text.SIZE.CLIENTNORMAL}
                      weight={isActive ? Text.WEIGHT.BOLD : Text.WEIGHT.NORMAL}
                    >
                      {dayjs(link).format(calendarDate.headerFormat)}
                    </Text>
                  </Button>
                );
              })}
              <Button
                themeStyle={Button.THEME.NONE}
                onClick={() =>
                  handleChangeDate(headList[4], -1, calendarDate.picker)
                }
              >
                <Text as={Text.VARIANT.SPAN} color={Text.COLOR.PRIMARY}>
                  <FiChevronRight size={20} />
                </Text>
              </Button>
            </Box>
          )}
        />
        <List
          api={useBoardDaily}
          params={{
            category,
            page,
            date: date ?? calendarDate.defaultValue,
            limit: 8,
          }}
        >
          {(list) =>
            list && (
              <>
                {list.length === 0 && <Blank />}
                {list.length !== 0 && (
                  <NewsList.LAYOUT.LineSmallImageNews list={list} />
                )}
              </>
            )
          }
        </List>
      </Board>
    </Box>
  );
};

export default Daily;
