import Box from "components/atoms/box";
import Loading from "components/atoms/loading";
import AuthorityList from "components/molecules/authorityList";
import InputForm from "components/molecules/inputForm";
import TextArea from "components/molecules/textarea";
import AdminDetailForm from "components/organisms/adminDetailForm";
import AdminDetailFormContainer from "components/organisms/adminDetailFormContainer";
import { AdminContentsContainer } from "components/templates/admin/admin.style";
import {
  useAuthorityInfo,
  useAuthorityMenuDetail,
  useAuthorityMenuList,
  useCreateAuthority,
  useUpdateAuthorityInfo,
} from "hooks/api/admin/manager";
import usePendingMutate from "hooks/utils/usePendingMutate";
import useUrl from "hooks/utils/useUrl";
import React, { useState } from "react";
import { management } from "text/admin/management";

const AuthorityDetail = () => {
  const {
    query: { authorCode },
  } = useUrl();

  const [menuNoList, setMenuNoList] = useState([]);
  const { isPending, handleMutate } = usePendingMutate();

  const { defaultValues } = management.authority.detail;

  const api = authorCode ? useAuthorityMenuDetail : useAuthorityMenuList;
  const mutateApi = authorCode ? useUpdateAuthorityInfo : useCreateAuthority;

  const { list: defaultList } = useAuthorityInfo({ authorCode });
  const { list, isLoading } = api(authorCode ? { authorCode } : "");

  const mutate = mutateApi();

  const formInfo = [
    {
      title: "권한 명",
      minHeight: "48px",
      components: (
        <InputForm
          name="authorName"
          type="text"
          width="240px"
          height="32px"
          padding="0 12px"
          theme="secondary"
          defaultName={defaultList?.authorName ?? ""}
          required="필수값입니다."
          ariaLabel="권한 명"
        />
      ),
      required: true,
    },
    {
      title: "권한 설정",
      height: "445px",
      components: <AuthorityList list={list} setMenuNoList={setMenuNoList} />,
      required: true,
    },
    {
      title: "설명",
      minHeight: "168px",
      components: (
        <TextArea
          name="authorDc"
          defaultValue={defaultList?.authorDc ?? ""}
          width="500px"
          height="152px"
          required="필수값입니다."
        />
      ),
      required: true,
    },
  ];

  const handleSubmit = (data) => {
    const { authorName, authorDc } = data;

    const submenuNoList = Object.entries(menuNoList.sub)
      .filter(([_, value]) => value)
      .map(([key, _]) => key);

    const submitData = {
      authorName,
      authorDc,
      menuNoList: submenuNoList,
    };

    handleMutate(mutate, { authorCode, data: submitData });
  };

  if (isLoading) return <Loading />;

  return (
    <AdminContentsContainer>
      <Box maxWidth="1024px">
        <AdminDetailFormContainer
          title="운영자 등록"
          defaultValues={defaultValues}
          handleSubmit={handleSubmit}
          isPending={isPending}
        >
          <AdminDetailForm formInfo={formInfo} />
        </AdminDetailFormContainer>
      </Box>
    </AdminContentsContainer>
  );
};

export default AuthorityDetail;
