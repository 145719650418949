import Box from "components/atoms/box";
import Button from "components/atoms/button";
import Image from "components/atoms/image";
import Text from "components/atoms/text";
import FindPassword from "components/molecules/findPassword";
import LoginForm from "components/organisms/loginForm";
import { LoginBox, AdminLoginContainer } from "components/templates/login/login.style";
import React from "react";
import { useState } from "react";

const Login = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <AdminLoginContainer>
      <LoginBox>
        <Image
          src={"/assets/common/login_banner.png"}
          alt="login_banner_img"
          ariaLabel="한국과총 웹진 로고"
        />
        <Text
          as={Text.VARIANT.TEXT}
          textAlign="center"
          size={Text.SIZE.CLIENTCONTENT}
          margin="5px 0 0 0"
          ariaLabel="소통과 상생으로 함께하는 과총"
        >
          소통과 상생으로 함께하는 과총
        </Text>

        <LoginForm borderRadius="5px" />

        <Box display={Box.DISPLAY.FLEX} jc="center" al="center" gap="10px">
          <Button themeStyle="none" onClick={() => setIsOpen(true)}>
            <Text
              as={Text.VARIANT.TEXT}
              textAlign="center"
              size={Text.SIZE.CLIENTCONTENTDETAIL}
              ariaLabel="비밀번호 찾기"
              color={Text.COLOR.TEXTGRAY}
            >
              비밀번호 찾기
            </Text>
          </Button>
        </Box>
        {isOpen && <FindPassword setIsOpen={setIsOpen} />}
      </LoginBox>
    </AdminLoginContainer>
  );
};

export default Login;
