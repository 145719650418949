import Box from "components/atoms/box";
import Text from "components/atoms/text";
import useContextForm from "hooks/utils/useContextForm";
import React from "react";
import AdminTable from "../adminTable";
import { useState } from "react";
import useUrl from "hooks/utils/useUrl";
import { useNewsletterRejectList } from "hooks/api/admin/news_letter";
import RejectTable from "components/molecules/rejectTable";
import { newsletter } from "text/admin/newsletter";

const AdminNewsletterRejectList = (props) => {
  const {
    filterData: { searchCondition, keyword },
  } = props;

  const { title, tableHeader, defaultValues } = newsletter.rejection.table;

  const {
    query: { page },
  } = useUrl();

  const [selectedMenu, setSelectedMenu] = useState("10");

  const { Provider } = useContextForm(defaultValues);

  return (
    <Box maxWidth="1024px" display={Box.DISPLAY.FLEX} flexDirection="column" margin="40px 0 0 0">
      <Text as={Text.VARIANT.SUBTITLE} ariaLabel={title} margin="0 0 10px 0">
        {title}
      </Text>
      <Provider>
        <AdminTable
          type={["countBar"]}
          hasSelectMenu={true}
          //
          api={useNewsletterRejectList}
          tableHeader={tableHeader}
          searchValue={keyword}
          searchCondition={searchCondition}
          page={page}
          //
          selectedMenu={selectedMenu}
          setSelectedMenu={setSelectedMenu}
          width="120px"
        >
          <RejectTable searchCondition={searchCondition} keyword={keyword} />
        </AdminTable>
      </Provider>
    </Box>
  );
};

export default AdminNewsletterRejectList;
