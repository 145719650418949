import Box from "components/atoms/box";
import Button from "components/atoms/button";
import Text from "components/atoms/text";
import dayjs from "dayjs";
import useToggle from "hooks/utils/useToggle";
import useUrl from "hooks/utils/useUrl";
import React, { useEffect, useState } from "react";
import { BiCalendar } from "@react-icons/all-files/bi/BiCalendar";
import { IoMdClose } from "@react-icons/all-files/io/IoMdClose";
import Picker from "react-mobile-picker";
import { getYearWeeks, updateDate } from "utils/calendar";
import dailyText from "text/daily";

const { header } = dailyText;

const year = [];
const month = [];

for (let i = 2003; i <= dayjs().add(1, "year").format("YYYY"); i++) {
  year.push(i + header.yearUnit);
}

for (let i = 1; i <= 12; i++) {
  month.push(String(i).padStart(2, "0") + header.monthUnit);
}

const optionGroups = {
  year,
  month,
};

const MobileCalendarHeader = (props) => {
  const { calendar, format, category } = props;
  const [isCalendar, setIsCalendar] = useToggle(false);
  const [selectCalendar, setSelectCalendar] = useState({
    year: dayjs(calendar).format(header.year),
    month: dayjs(calendar).format(header.month),
    date: dayjs(calendar).format(
      category === "date" ? header.date : header.week
    ),
  });
  const { changeQuery } = useUrl();
  const endOfMonth = dayjs(calendar).endOf("month").date();
  const endOfYear = dayjs(calendar).format("YYYY-12-31");

  const date = [];

  for (let i = 1; i <= endOfMonth; i++) {
    date.push(i + header.dateUnit);
  }

  const handleChange = (name, value) => {
    setSelectCalendar((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const week = getYearWeeks(dayjs(endOfYear), 60)
    .filter((date, idx) => {
      return (
        date.slice(0, 6) ===
        selectCalendar.year.slice(0, 4) + selectCalendar.month.slice(0, 2)
      );
    })
    .map((_, idx) => {
      return idx + 1 + header.weekUnit;
    });

  const handleSubmit = () => {
    const date = selectCalendar.date.slice(0, -1);
    const month = selectCalendar.month.slice(0, -1);
    const year = selectCalendar.year.slice(0, -1);

    const calendar = dayjs(`${year}-${month}-${date}`).format("YYYYMMDD");
    const changeCalendar = updateDate(calendar, 0, category);

    changeQuery({ ...changeCalendar, page: 1 });
    setIsCalendar();
  };

  useEffect(() => {
    setSelectCalendar({
      year: dayjs(calendar).format(header.year),
      month: dayjs(calendar).format(header.month),
      date: dayjs(calendar).format(
        category === "date" ? header.date : header.week
      ),
    });
  }, [calendar, category]);

  return (
    <>
      <Box display={Box.DISPLAY.FLEX} al={"center"} margin={"6px 0 0"}>
        <Text
          as={Text.VARIANT.TEXT}
          size={Text.SIZE.MOBILETITLE}
          weight={Text.WEIGHT.BOLD}
          margin={"0 10px 0 0"}
        >
          {dayjs(calendar).format(format)}
        </Text>
        <Button
          display={"flex"}
          al={"center"}
          themeStyle={Button.THEME.NONE}
          onClick={setIsCalendar}
          color={Button.COLOR.PRIMARY}
        >
          <BiCalendar size={22} />
        </Button>
      </Box>

      {isCalendar && (
        <Box
          position={"fixed"}
          top={"0"}
          left={"0"}
          width={"100%"}
          height={"100vh"}
          display={Box.DISPLAY.FLEX}
          jc={"center"}
          al={"center"}
          zIndex={Box.LEVEL.ROOT}
          bgColor={Box.COLOR.SHADOW}
          onClick={setIsCalendar}
        >
          <Box
            display={Box.DISPLAY.FLEX}
            flexDirection={"column"}
            al={"center"}
            jc={"space-between"}
            position={"relative"}
            width="275px"
            height={"295px"}
            bgColor={Box.COLOR.WHITE}
            padding={"12px 10px"}
            border={"1px solid"}
            borderColor={Box.COLOR.SECONDARY}
            borderRadius={"20px"}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Text
              as={Text.VARIANT.SUBTITLE}
              size={Text.SIZE.MOBILETITLE}
              weight={Text.WEIGHT.BOLD}
              textAlign={"center"}
            >
              Calendar
            </Text>

            <Box position={"absolute"} top={"12px"} right={"22px"}>
              <Button themeStyle={Button.THEME.NONE} onClick={setIsCalendar}>
                <IoMdClose size={18} />
              </Button>
            </Box>
            <Picker
              optionGroups={{
                ...optionGroups,
                date: category === "week" ? week : date,
              }}
              valueGroups={selectCalendar}
              onChange={handleChange}
            />

            <Button
              themeStyle={Button.THEME.NONE}
              width={"100%"}
              padding={"9px 0"}
              fontSize={Button.FONT_SIZE.MOBILETITLE}
              fontWeight={Button.FONT_WEIGHT.BOLD}
              color={Button.COLOR.WHITE}
              bgColor={Button.COLOR.SECONDARY}
              borderRadius={"17.5px"}
              onClick={handleSubmit}
            >
              {header.submit}
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
};

export default MobileCalendarHeader;
