import Box from "components/atoms/box";
import Image from "components/atoms/image";
import Link from "components/atoms/link";
import Text from "components/atoms/text";
import React from "react";
import layoutText from "text/layout";

const { footer } = layoutText;

const Footer = () => {
  return (
    <Box minWidth={"1260px"} ariaLabel="푸터 영역">
      <Box
        display={Box.DISPLAY.FLEX}
        jc="center"
        bgColor={Box.COLOR.PRIMARY}
        ariaLabel="이용약관 개인정보처리방침 저작권보호정책 링크 영역"
      >
        <Box
          display={Box.DISPLAY.FLEX}
          padding={"15px 0"}
          width={"100%"}
          maxWidth={"1260px"}
          ariaLabel="이용약관 개인정보처리방침 저작권보호정책 링크 영역"
        >
          <Link link={"/service?category=privacy"}>
            <Text
              as={Text.VARIANT.TEXT}
              color={Text.COLOR.WHITE}
              size={Text.SIZE.CLIENTCONTENTDETAIL}
              weight={Text.WEIGHT.BOLD}
              margin={"0 30px 0 0"}
              ariaLabel="개인정보처리방침 링크"
            >
              {footer.service.privacy}
            </Text>
          </Link>

          <Link link={"/service?category=termsOfUse"}>
            <Text
              as={Text.VARIANT.TEXT}
              color={Text.COLOR.WHITE}
              size={Text.SIZE.CLIENTCONTENTDETAIL}
              weight={Text.WEIGHT.BOLD}
              margin={"0 30px 0 0"}
              ariaLabel="이용약관 링크"
            >
              {footer.service.termsOfUse}
            </Text>
          </Link>

          <Link link={"/service?category=copyright"}>
            <Text
              as={Text.VARIANT.TEXT}
              color={Text.COLOR.WHITE}
              size={Text.SIZE.CLIENTCONTENTDETAIL}
              weight={Text.WEIGHT.BOLD}
              margin={"0 30px 0 0"}
              ariaLabel="저작권보호정책 링크"
            >
              {footer.service.copyright}
            </Text>
          </Link>
        </Box>
      </Box>
      <Box display={Box.DISPLAY.FLEX} jc="center" padding={"63px 0"}>
        <Link link="/">
          <Image
            src={"/assets/footer/footerLogo.svg"}
            width={"189px"}
            height={"61px"}
            ariaLabel="한국과학기술단체총연합회 로고"
            alt="한국과학기술단체총연합회 로고"
          />
        </Link>
        <Box margin={"0 0 0 60px"}>
          <Text
            lineHeight={"22px"}
            size={Text.SIZE.CLIENTCONTENTDETAIL}
            ariaLabel="한국과학기술단체총연합회 주소 : [06130] 서울시 강남구 테헤란로 7길 22(역삼동 635-4) 한국과학기술회관
            일관 2층 | 연락처 : 02-3420-1242 | 팩스 : 02-563-4931"
          >
            {footer.info[0]}
          </Text>
          <Text
            lineHeight={"22px"}
            size={Text.SIZE.CLIENTCONTENTDETAIL}
            ariaLabel="정기간행물 등록번호 : 서울 아 00067 | 등록일자 : 2005.09.26 | 발행인 : 이태식 | 편집인 : 강건기"
          >
            정기간행물 등록번호 : 서울 아 00067 | 등록일자 : 2005.09.26 |
            발행일자 : 2005.09.27 | 발행인 : 이태식 | 편집인 : 강건기
          </Text>
          <Text
            lineHeight={"22px"}
            size={Text.SIZE.CLIENTCONTENTDETAIL}
            ariaLabel="청소년보호책임자 : 오승원"
          >
            청소년보호책임자 : 오승원
          </Text>
          <Text
            lineHeight={"22px"}
            size={Text.SIZE.CLIENTCONTENTDETAIL}
            ariaLabel="COPYRIGHT (C) KOFST. ALL RIGHTS RESERVED"
          >
            {footer.info[2]}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
