import Box from "components/atoms/box";
import Button from "components/atoms/button";
import Image from "components/atoms/image";
import Text from "components/atoms/text";
import ChangeOrderButton from "components/molecules/changeOrderButton";
import React from "react";
import convertDayWithTime from "utils/convertDayWithTime";
import { useNavigate } from "react-router-dom";
import { useChangeOrderStore } from "store/useChangeStore";
import ChangeOrderContainer from "components/molecules/changeOrder";
import { Draggable } from "react-beautiful-dnd";
import { useEffect } from "react";
import { useState } from "react";
import { AdminContentsContainer } from "components/templates/admin/admin.style";
import {
  useNewsletterBannerList,
  useUpdateNewsletterBannerOrder,
} from "hooks/api/admin/news_letter";
import Loading from "components/atoms/loading";
import { newsletter } from "text/admin/newsletter";
import { common } from "text/admin/common";
import { SERVER_URL } from "api/config";
import { adminDefaultUrl } from "api/admin/url";

const NewsletterBanner = () => {
  const { title, tableHeader } = newsletter.banner;
  const { orderChange } = common.alert;

  const { list, isLoading } = useNewsletterBannerList();

  const [newList, setNewList] = useState(list);

  useEffect(() => {
    setNewList(list);
  }, [list]);

  const navigate = useNavigate();

  const { isChangeOrderList } = useChangeOrderStore();

  const updateOrderMutate = useUpdateNewsletterBannerOrder();

  const handleSaveOrder = () => {
    const confirm = window.confirm(orderChange);
    if (confirm) {
      const newOrderList = newList.map(({ key }, idx) => ({
        key,
        sortOrdr: idx + 1,
      }));
      const data = { list: newOrderList };
      updateOrderMutate({ data });
    }
  };

  const handleUpdateBanner = (key) => {
    navigate(`${adminDefaultUrl}/newsletter/below_banner/detail?key=${key}`);
  };

  if (isLoading) return <Loading />;

  return (
    <AdminContentsContainer>
      <Box maxWidth="1024px">
        <Text as={Text.VARIANT.SUBTITLE} margin="0 0 10px 0" ariaLabel={title}>
          {title}
        </Text>
        <Box
          display={Box.DISPLAY.FLEX}
          al="center"
          height="32px"
          borderTop="1px solid"
          borderColor={Box.COLOR.ADMINLINEGRAY}
          bgColor={Box.COLOR.ADMINCONTENTSBG}
        >
          {tableHeader.map((header, idx) => (
            <Box
              width={header.width}
              height="100%"
              display={Box.DISPLAY.FLEX}
              al="center"
              jc="center"
              key={idx}
              borderLeft="1px solid"
              borderRight={idx === tableHeader.length - 1 ? "1px solid" : "none"}
              borderBottom="1px solid"
              borderColor={Box.COLOR.ADMINLINEGRAY}
            >
              <Text
                as={Text.VARIANT.TEXT}
                weight={Text.WEIGHT.BOLD}
                size={Text.SIZE.CLIENTCONTENTDETAIL}
              >
                {header.name}
              </Text>
            </Box>
          ))}
        </Box>
        {!isChangeOrderList &&
          list.map(({ filePath, key, regDate, title }, idx) => {
            const imagePath = filePath && `${SERVER_URL}${filePath}`;

            return (
              <Box
                display={Box.DISPLAY.FLEX}
                al="center"
                key={key}
                height="106px"
                borderBottom="1px solid"
                borderColor={Box.COLOR.ADMINLINEGRAY}
                bgColor={Box.COLOR.WHITE}
              >
                <Box
                  width="5%"
                  height="100%"
                  display={Box.DISPLAY.FLEX}
                  jc="center"
                  borderLeft="1px solid"
                  borderColor={Box.COLOR.ADMINLINEGRAY}
                  al="center"
                >
                  <Text as={Text.VARIANT.TEXT} size={Text.SIZE.ADMINCONTENT}>
                    {idx + 1}
                  </Text>
                </Box>
                <Box
                  width="28%"
                  height="100%"
                  padding="10px"
                  display={Box.DISPLAY.FLEX}
                  jc="center"
                  borderLeft="1px solid"
                  borderColor={Box.COLOR.ADMINLINEGRAY}
                  al="center"
                >
                  <Image src={imagePath} width="100%" height="100%" />
                </Box>
                <Box
                  width="33%"
                  height="100%"
                  display={Box.DISPLAY.FLEX}
                  jc="center"
                  borderLeft="1px solid"
                  borderColor={Box.COLOR.ADMINLINEGRAY}
                  al="center"
                >
                  <Text as={Text.VARIANT.TEXT} size={Text.SIZE.ADMINCONTENT}>
                    {title}
                  </Text>
                </Box>
                <Box
                  width="15%"
                  height="100%"
                  display={Box.DISPLAY.FLEX}
                  jc="center"
                  borderLeft="1px solid"
                  borderColor={Box.COLOR.ADMINLINEGRAY}
                  al="center"
                >
                  <Text as={Text.VARIANT.TEXT} size={Text.SIZE.ADMINCONTENT}>
                    {convertDayWithTime(regDate)}
                  </Text>
                </Box>
                <Box
                  width="19%"
                  height="100%"
                  display={Box.DISPLAY.FLEX}
                  jc="center"
                  al="center"
                  gap="12px"
                  borderLeft="1px solid"
                  borderRight="1px solid"
                  borderColor={Box.COLOR.ADMINLINEGRAY}
                >
                  <Button
                    themeStyle="adminCommon"
                    padding="7px 24px"
                    onClick={() => handleUpdateBanner(key)}
                  >
                    수정
                  </Button>
                </Box>
              </Box>
            );
          })}
        {isChangeOrderList && (
          <ChangeOrderContainer newList={newList} setNewList={setNewList}>
            {newList.map(({ filePath, key, regDate, title }, idx) => {
              const imagePath = filePath && `${SERVER_URL}${filePath}`;

              return (
                <Draggable draggableId={key} index={idx} key={key}>
                  {(provided) => (
                    <Box
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      display={Box.DISPLAY.FLEX}
                      al="center"
                      key={key}
                      height="106px"
                      borderBottom="1px solid"
                      borderColor={Box.COLOR.ADMINLINEGRAY}
                      bgColor={Box.COLOR.WHITE}
                    >
                      <Box
                        width="5%"
                        height="100%"
                        display={Box.DISPLAY.FLEX}
                        jc="center"
                        borderLeft="1px solid"
                        borderColor={Box.COLOR.ADMINLINEGRAY}
                        al="center"
                      >
                        <Text as={Text.VARIANT.TEXT} size={Text.SIZE.ADMINCONTENT}>
                          {idx + 1}
                        </Text>
                      </Box>
                      <Box
                        width="28%"
                        height="100%"
                        display={Box.DISPLAY.FLEX}
                        jc="center"
                        padding="10px"
                        borderLeft="1px solid"
                        borderColor={Box.COLOR.ADMINLINEGRAY}
                        al="center"
                      >
                        <Image src={imagePath} width="100%" height="100%" />
                      </Box>
                      <Box
                        width="33%"
                        height="100%"
                        display={Box.DISPLAY.FLEX}
                        jc="center"
                        borderLeft="1px solid"
                        borderColor={Box.COLOR.ADMINLINEGRAY}
                        al="center"
                      >
                        <Text as={Text.VARIANT.TEXT} size={Text.SIZE.ADMINCONTENT}>
                          {title}
                        </Text>
                      </Box>
                      <Box
                        width="15%"
                        height="100%"
                        display={Box.DISPLAY.FLEX}
                        jc="center"
                        borderLeft="1px solid"
                        borderColor={Box.COLOR.ADMINLINEGRAY}
                        al="center"
                      >
                        <Text as={Text.VARIANT.TEXT} size={Text.SIZE.ADMINCONTENT}>
                          {convertDayWithTime(regDate)}
                        </Text>
                      </Box>
                      <Box
                        width="19%"
                        height="100%"
                        display={Box.DISPLAY.FLEX}
                        jc="center"
                        al="center"
                        gap="12px"
                        borderLeft="1px solid"
                        borderRight="1px solid"
                        borderColor={Box.COLOR.ADMINLINEGRAY}
                      >
                        <Button
                          themeStyle="adminCommon"
                          padding="7px 24px"
                          onClick={() => handleUpdateBanner(key)}
                        >
                          수정
                        </Button>
                      </Box>
                    </Box>
                  )}
                </Draggable>
              );
            })}
          </ChangeOrderContainer>
        )}
        <Box display={Box.DISPLAY.FLEX} jc="flex-end" margin="10px 0 0 0">
          <ChangeOrderButton
            handleSaveOrder={handleSaveOrder}
            list={list}
            setNewList={setNewList}
          />
        </Box>
      </Box>
    </AdminContentsContainer>
  );
};

export default NewsletterBanner;
