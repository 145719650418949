import Box from "components/atoms/box";
import React, { useEffect, useState } from "react";
import Text from "components/atoms/text";
import Link from "components/atoms/link";
import Input from "components/atoms/input";
import { Controller, FormProvider, useForm } from "react-hook-form";
import Button from "components/atoms/button";
import { useCreateRequestMutation } from "hooks/api/service";
import SignupAgreement from "../signupAgreement";
import usePattern from "hooks/utils/usePattern";
import Image from "components/atoms/image";
import useToggle from "hooks/utils/useToggle";
import { IoMdClose } from "@react-icons/all-files/io/IoMdClose";
import Picker from "react-mobile-picker";
import { Select } from "antd";
import useMenu from "hooks/utils/useMenu";
import useMobileStore from "store/useMobileStore";
import helpText from "text/help";
import { BsChevronDown } from "@react-icons/all-files/bs/BsChevronDown";

const { sitemap, rss, admin } = helpText;

const Title = (props) => {
  const { children, isMobile } = props;

  return (
    <Text
      as={Text.VARIANT.TITLE}
      size={isMobile ? Text.SIZE.MOBILETITLE : Text.SIZE.CLIENTTITLE}
    >
      {children}
    </Text>
  );
};

export const Sitemap = ({ isMobile }) => {
  const { mainMenu, subMenu } = useMenu(
    sitemap.customSubMenu,
    sitemap.customMenu
  );

  return (
    <>
      <Box
        width={isMobile ? "100%" : "calc(100% - 390px)"}
        padding={isMobile ? "0 24px" : "32px 0 0 40px"}
      >
        {!isMobile && <Title>{sitemap.title}</Title>}

        <Box
          display={Box.DISPLAY.FLEX}
          wrap={"wrap"}
          margin={isMobile ? "0" : "73px 0 0 0"}
        >
          {mainMenu.map(({ title, link, ariaLabel }, idx) => {
            const list = subMenu[link];

            return (
              <Box
                width={isMobile ? "100%" : "182px"}
                padding={isMobile ? "0 0 40px" : "0"}
                margin={
                  isMobile
                    ? "0"
                    : (idx + 1) % 4 !== 0
                    ? "0 20px 100px 0"
                    : "0 0 100px"
                }
                key={title}
                ariaLabel={ariaLabel}
              >
                <Link width="100%" link={`/${link}`}>
                  <Box
                    width="100%"
                    padding={isMobile ? "11px 0 11px 30px" : "11px 0 11px 40px"}
                    bgColor={Box.COLOR.PRIMARY}
                    borderRadius={"0 20px"}
                  >
                    <Text
                      as={Text.VARIANT.TEXT}
                      color={Text.COLOR.WHITE}
                      size={Text.SIZE.CLIENTCONTENT}
                      weight={Text.WEIGHT.BOLD}
                    >
                      {title}
                    </Text>
                  </Box>
                </Link>

                {list && (
                  <Box
                    margin={"0 0 0 18px"}
                    padding={"10px 0 0 0"}
                    borderLeft={isMobile ? "none" : "1px solid"}
                    borderColor={Box.COLOR.LINEGRAY}
                  >
                    {list.map(({ code, codeName, listviewBasic, root }) => (
                      <Link
                        link={`/${root}?category=${code}&${
                          listviewBasic ? `type=${listviewBasic}` : ""
                        }`}
                        key={codeName}
                        width={"100%"}
                      >
                        <Text
                          padding={"0 0 0 12px"}
                          margin={isMobile ? "10px 0 0" : "20px 0 0 0"}
                          size={Text.SIZE.CLIENTCONTENT}
                          weight={Text.WEIGHT.BOLD}
                          color={Text.COLOR.PRIMARY}
                        >
                          {codeName}
                        </Text>
                      </Link>
                    ))}
                  </Box>
                )}
              </Box>
            );
          })}
        </Box>
      </Box>
    </>
  );
};

export const MobileRss = () => {
  return (
    <>
      <Box width={"100%"} padding={"20px 24px"}>
        <Title isMobile>RSS</Title>

        <Text
          as={Text.VARIANT.SUBTITLE}
          size={Text.SIZE.MOBILECONTENT}
          color={Text.COLOR.SECONDARY}
          margin={"12px 0"}
        >
          편리한 RSS 서비스를 이용하세요!
        </Text>

        <>
          <Text
            as={Text.VARIANT.TEXT}
            size={Text.SIZE.MOBILECONTENT}
            color={Text.COLOR.TEXTGRAY}
          >
            RSS 구독을 원하시는 게시판의 주소를 복사하여 사용하시는 RSS 리더기에
            등록하시면, 새로 업데이트되는 정보를 쉽고 편리하게 받아보실 수
            있습니다.
          </Text>
        </>

        <Box display={Box.DISPLAY.FLEX} margin={"30px 0 0"}>
          <Text as={Text.VARIANT.SPAN} lineHeight={"28px"}>
            ・
          </Text>
          <Text
            as={Text.VARIANT.SPAN}
            size={Text.SIZE.MOBILECONTENT}
            whiteSpace={"pre-wrap"}
            lineHeight={"28px"}
          >
            {`RSS는 Really Syndication (또는 Rich Site Summary)의 줄임말로 자주 업데이트 되는 컨텐츠를 사용자에게 쉽게 제공하기 위해 만들어진 콘텐츠의 배급 포맷입니다.
`}
          </Text>
        </Box>

        <Box display={Box.DISPLAY.FLEX}>
          <Text as={Text.VARIANT.SPAN} lineHeight={"28px"}>
            ・
          </Text>
          <Text
            as={Text.VARIANT.SPAN}
            size={Text.SIZE.MOBILECONTENT}
            whiteSpace={"pre-wrap"}
            lineHeight={"28px"}
          >
            {`사이트를 직접 방문하지 않고도 업데이트되는 콘텐츠에 대한 최소한의 정보를 사용자가 받아 볼 수 있도록 해주는 기술을 의미합니다.
`}
          </Text>
        </Box>

        <Box display={Box.DISPLAY.FLEX}>
          <Text as={Text.VARIANT.SPAN} lineHeight={"28px"}>
            ・
          </Text>
          <Text
            as={Text.VARIANT.SPAN}
            size={Text.SIZE.MOBILECONTENT}
            whiteSpace={"pre-wrap"}
            lineHeight={"28px"}
          >
            {`한국과학기술단체총연합회의 과학기술관련 정책, 기술동향, 인물, 과학상식, 과학관련소식, 자유로운 토론 등을 즐기실 수 있습니다.
`}
          </Text>
        </Box>

        <Text
          as={Text.VARIANT.SUBTITLE}
          size={Text.SIZE.MOBILECONTENT}
          margin={"30px 0 12px"}
        >
          RSS 사용방법
        </Text>

        <Box display={Box.DISPLAY.FLEX}>
          <Text as={Text.VARIANT.SPAN} lineHeight={"28px"}>
            ・
          </Text>
          <Text
            as={Text.VARIANT.SPAN}
            size={Text.SIZE.MOBILECONTENT}
            whiteSpace={"pre-wrap"}
            lineHeight={"28px"}
          >
            {`기사페이지 상단 및 하단에 있는 RSS 버튼을 클릭하시면 해당하는 카테고리의 주소가 복사됩니다.
`}
          </Text>
        </Box>

        <Box display={Box.DISPLAY.FLEX}>
          <Text as={Text.VARIANT.SPAN} lineHeight={"28px"}>
            ・
          </Text>
          <Text
            as={Text.VARIANT.SPAN}
            size={Text.SIZE.MOBILECONTENT}
            whiteSpace={"pre-wrap"}
            lineHeight={"28px"}
          >
            {`그 주소를 Ctrl+V 단축버튼을 사용하여 사용하고 계신 RSS 리더에 복사해서 붙이세요.
`}
          </Text>
        </Box>

        <Box display={Box.DISPLAY.FLEX}>
          <Text as={Text.VARIANT.SPAN} lineHeight={"28px"}>
            ・
          </Text>
          <Text
            as={Text.VARIANT.SPAN}
            size={Text.SIZE.MOBILECONTENT}
            whiteSpace={"pre-wrap"}
            lineHeight={"28px"}
          >
            {`일부러 찾아다니지 않아도 실시간으로 업데이트 되는 뉴스를 구독 하실 수 있습니다.
`}
          </Text>
        </Box>

        <Text
          as={Text.VARIANT.SUBTITLE}
          size={Text.SIZE.MOBILECONTENT}
          margin={"30px 0 12px"}
        >
          RSS 한글 리더기 [연모]
        </Text>

        <Box display={Box.DISPLAY.FLEX}>
          <Text as={Text.VARIANT.SPAN} lineHeight={"28px"}>
            ・
          </Text>
          <Text
            as={Text.VARIANT.SPAN}
            size={Text.SIZE.MOBILECONTENT}
            whiteSpace={"pre-wrap"}
            lineHeight={"28px"}
          >
            {`연모 프로그램을 사용하여 한국과총의 기사를 실시간으로 받아오세요!
`}
          </Text>
        </Box>
      </Box>
    </>
  );
};

export const Rss = () => {
  return (
    <>
      <Box width={"calc(100% - 390px)"} padding={"32px 0 0 40px"}>
        <Title>{rss.title}</Title>

        <Text
          as={Text.VARIANT.SUBTITLE}
          size={Text.SIZE.CLIENTCATEGORY}
          color={Text.COLOR.SECONDARY}
          margin={"40px 0 12px"}
        >
          {rss.subTitle}
        </Text>

        <Text
          as={Text.VARIANT.TEXT}
          size={Text.SIZE.CLIENTCATEGORY}
          color={Text.COLOR.TEXTGRAY}
        >
          {rss.info[0]}
        </Text>
        <Text
          as={Text.VARIANT.TEXT}
          size={Text.SIZE.CLIENTCATEGORY}
          color={Text.COLOR.TEXTGRAY}
        >
          {rss.info[1]}
        </Text>

        <Text
          as={Text.VARIANT.TEXT}
          size={Text.SIZE.CLIENTNORMAL}
          whiteSpace={"pre-wrap"}
          lineHeight={"28px"}
          margin={"50px 0 0"}
        >
          {rss.intro}
        </Text>

        <Text
          as={Text.VARIANT.SUBTITLE}
          size={Text.SIZE.CLIENTNORMAL}
          margin={"80px 0 0"}
        >
          {rss.using}
        </Text>

        <Text
          as={Text.VARIANT.TEXT}
          size={Text.SIZE.CLIENTNORMAL}
          whiteSpace={"pre-wrap"}
          lineHeight={"40px"}
        >
          {rss.usingIntro}
        </Text>

        <Text
          as={Text.VARIANT.SUBTITLE}
          size={Text.SIZE.CLIENTNORMAL}
          margin={"50px 0 12px"}
        >
          {rss.reader}
        </Text>

        <Text
          as={Text.VARIANT.TEXT}
          size={Text.SIZE.CLIENTNORMAL}
          whiteSpace={"pre-wrap"}
          lineHeight={"28px"}
        >
          {rss.readerIntro}
        </Text>
      </Box>
    </>
  );
};

const optionGroups = {
  select: [
    admin.pending.form.emailSelect.title,
    "kofst.or.kr",
    "dreamwiz.com",
    "empal.com",
    "freechal.com",
    "gmail.com",
    "hanafos.com",
    "hanmail.net",
    "hanmir.com",
    "hotmail.com",
    "korea.com",
    "lycos.com",
    "nate.com",
    "naver.com",
    "netian.com",
    "paran.com",
    "yahoo.com.kr",
    "yahoo.com",
    "chol.com",
  ],
};

export const Admin = ({ setIsHidden }) => {
  const form = useForm({
    defaultValues: {
      writer: "",
      email: "",
      emailDomain: "",
      emailSelect: "",
      title: "",
      content: "",
      isPrivacy: false,
    },
  });

  const { isMobile } = useMobileStore();

  const { register, watch, setValue, reset, handleSubmit, control } = form;
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [request, setRequest] = useState("pending");

  const handleSuccess = () => {
    reset();
    setRequest("success");
    setIsHidden(true);

    window.scrollTo(0, 0);
  };

  const handleFail = () => {
    setRequest("fail");
    setIsHidden(true);

    window.scrollTo(0, 0);
  };

  const handleReset = () => {
    reset();
    setRequest("pending");
    setIsHidden(false);
  };

  const pattern = usePattern();

  const createRequestMutation = useCreateRequestMutation(
    handleSuccess,
    handleFail
  );

  const [isEmail, setIsEmail] = useToggle(false);
  const [selectEmail, setSelectEmail] = useState({
    select: admin.pending.form.emailSelect.title,
  });

  const emailSelect = watch("emailSelect");

  const handleSubmitForm = (data) => {
    createRequestMutation({
      title: data.title,
      contents: data.content,
      email1: data.email,
      email2: data.emailDomain,
      writer: data.writer,
    });
  };

  const handleChange = (name, value) => {
    setSelectEmail({
      [name]: value,
    });
  };

  const handleSubmitEmail = () => {
    setValue("emailSelect", selectEmail.select);
    setIsEmail();
  };

  useEffect(() => {
    if (emailSelect) {
      const emailSelectValue =
        emailSelect === admin.pending.form.emailSelect.title ? "" : emailSelect;

      setValue("emailDomain", emailSelectValue);

      if (emailSelectValue === "") {
        setIsReadOnly(false);
      } else {
        setIsReadOnly(true);
      }
    }
  }, [setValue, emailSelect]);

  const isActiveButton =
    watch("isPrivacy") &&
    watch("writer").trim() !== "" &&
    watch("email").trim() !== "" &&
    watch("emailDomain").trim() !== "" &&
    watch("title").trim() !== "" &&
    watch("content").trim() !== "" &&
    pattern.email.value.test(`${watch("email")}@${watch("emailDomain")}`);

  return (
    <>
      {request === "success" && (
        <Box
          margin={"138px auto 200px"}
          width={"328px"}
          display={Box.DISPLAY.FLEX}
          flexDirection={"column"}
          al="center"
        >
          <Image src={"/assets/auth/checked.svg"} />
          <Text
            as={Text.VARIANT.SUBTITLE}
            size={isMobile ? Text.SIZE.MOBILETITLE : Text.SIZE.CLIENTCATEGORY}
            weight={Text.WEIGHT.NORMAL}
            color={Text.COLOR.SECONDARY}
            textAlign={"center"}
            margin={"24px 0 0"}
          >
            {admin.success.title[0]}
          </Text>
          <Text
            as={Text.VARIANT.SUBTITLE}
            size={isMobile ? Text.SIZE.MOBILETITLE : Text.SIZE.CLIENTCATEGORY}
            weight={Text.WEIGHT.NORMAL}
            color={Text.COLOR.SECONDARY}
            textAlign={"center"}
            margin={"0 0 35px"}
          >
            {admin.success.title[1]}
          </Text>

          <Button
            width={isMobile ? "303px" : "100%"}
            padding={isMobile ? "10px 0" : "13px 0"}
            border={"1px solid"}
            borderColor={Button.COLOR.SECONDARY}
            borderRadius={"60px"}
            bgColor={Button.COLOR.SECONDARY}
            color={Button.COLOR.WHITE}
            fontSize={
              isMobile
                ? Button.FONT_SIZE.MOBILECONTENT
                : Button.FONT_SIZE.CLIENTCONTENT
            }
            fontWeight={Button.FONT_WEIGHT.BOLD}
            onClick={handleReset}
          >
            {admin.success.submit}
          </Button>
        </Box>
      )}
      {request === "fail" && (
        <Box
          margin={"138px auto 200px"}
          width={"328px"}
          display={Box.DISPLAY.FLEX}
          flexDirection={"column"}
          al="center"
        >
          <Image src={"/assets/auth/Error.svg"} />
          <Text
            as={Text.VARIANT.SUBTITLE}
            size={isMobile ? Text.SIZE.MOBILETITLE : Text.SIZE.CLIENTCATEGORY}
            weight={Text.WEIGHT.NORMAL}
            color={Text.COLOR.SECONDARY}
            textAlign={"center"}
            margin={"24px 0 0"}
          >
            {admin.fail.title[0]}
          </Text>
          <Text
            as={Text.VARIANT.SUBTITLE}
            size={isMobile ? Text.SIZE.MOBILETITLE : Text.SIZE.CLIENTCATEGORY}
            weight={Text.WEIGHT.NORMAL}
            color={Text.COLOR.SECONDARY}
            textAlign={"center"}
            margin={"0 0 35px"}
          >
            {admin.fail.title[1]}
          </Text>

          <Button
            width={isMobile ? "303px" : "100%"}
            padding={isMobile ? "10px 0" : "13px 0"}
            borderColor={Button.COLOR.SECONDARY}
            borderRadius={"60px"}
            bgColor={Button.COLOR.SECONDARY}
            color={Button.COLOR.WHITE}
            fontSize={
              isMobile
                ? Button.FONT_SIZE.MOBILECONTENT
                : Button.FONT_SIZE.CLIENTCONTENT
            }
            fontWeight={Button.FONT_WEIGHT.BOLD}
            onClick={handleReset}
          >
            {admin.fail.submit}
          </Button>
        </Box>
      )}
      {request === "pending" && (
        <FormProvider {...form}>
          <Box
            width={isMobile ? "100%" : "calc(100% - 390px)"}
            padding={isMobile ? "20px 24px" : "32px 40px 15px"}
          >
            <Title isMobile={isMobile}>{admin.pending.title}</Title>

            <Text
              as={Text.VARIANT.SUBTITLE}
              size={
                isMobile ? Text.SIZE.MOBILECONTENT : Text.SIZE.CLIENTCATEGORY
              }
              color={Text.COLOR.SECONDARY}
              margin={isMobile ? "12px 0 30px" : "12px 0 80px"}
            >
              {admin.pending.description}
            </Text>
            <Text
              as={Text.VARIANT.TEXT}
              color={Text.COLOR.TEXTGRAY}
              size={
                isMobile ? Text.SIZE.MOBILECONTENT : Text.SIZE.CLIENTCONTENT
              }
            >
              <Text as={Text.VARIANT.SPAN} color={Text.COLOR.SECONDARY}>
                *
              </Text>
              {admin.pending.info.common[0]}
              <Text as={Text.VARIANT.SPAN} weight={Text.WEIGHT.BOLD}>
                {admin.pending.info.bold[0]}
              </Text>
              {admin.pending.info.common[1]}
            </Text>

            <Box
              display={Box.DISPLAY.FLEX}
              padding={isMobile ? "10px 12px" : "22px 40px"}
              margin={"12px 0"}
              border={"1px solid"}
              borderColor={Box.COLOR.LINEGRAY}
              borderRadius={isMobile ? "5px" : "20px"}
              bgColor={Text.COLOR.WHITE}
            >
              <Text
                as={Text.VARIANT.TEXT}
                size={
                  isMobile ? Text.SIZE.MOBILECONTENT : Text.SIZE.CLIENTCONTENT
                }
                width={isMobile ? "50px" : "82px"}
                margin={isMobile ? "0 8px 0 0" : "0 28px 0 0"}
                borderRight={"1px solid"}
                borderColor={Text.COLOR.LINEGRAY}
                weight={Text.WEIGHT.BOLD}
                color={Text.COLOR.TEXTGRAY}
                isSort
              >
                {admin.pending.form.writer.title}
                <Text as={Text.VARIANT.SPAN} color={Text.COLOR.SECONDARY}>
                  *
                </Text>
              </Text>

              <Input
                width={"calc(100% - 110px)"}
                border={"none"}
                size={
                  isMobile ? Text.SIZE.MOBILECONTENT : Input.SIZE.CLIENTCONTENT
                }
                color={Input.COLOR.TEXTGRAY}
                {...register("writer")}
              />
            </Box>

            <Box display={Box.DISPLAY.FLEX} margin={"12px 0"}>
              <Box
                display={Box.DISPLAY.FLEX}
                width={"calc(100% - 101px)"}
                margin={"0 10px 0 0"}
                padding={isMobile ? "10px 12px" : "22px 40px"}
                border={"1px solid"}
                borderColor={Box.COLOR.LINEGRAY}
                borderRadius={isMobile ? "5px" : "20px"}
                bgColor={Text.COLOR.WHITE}
              >
                <Text
                  as={Text.VARIANT.TEXT}
                  size={
                    isMobile ? Text.SIZE.MOBILECONTENT : Text.SIZE.CLIENTCONTENT
                  }
                  width={isMobile ? "50px" : "82px"}
                  margin={isMobile ? "0 8px 0 0" : "0 28px 0 0"}
                  borderRight={"1px solid"}
                  borderColor={Text.COLOR.LINEGRAY}
                  weight={Text.WEIGHT.BOLD}
                  color={Text.COLOR.TEXTGRAY}
                  isSort
                >
                  {admin.pending.form.email.title}
                  <Text as={Text.VARIANT.SPAN} color={Text.COLOR.SECONDARY}>
                    *
                  </Text>
                </Text>
                <Input
                  type={"email"}
                  width={isMobile ? "calc(50% - 44px)" : "calc(50% - 67px)"}
                  border={"none"}
                  size={
                    isMobile
                      ? Text.SIZE.MOBILECONTENT
                      : Input.SIZE.CLIENTCONTENT
                  }
                  color={Input.COLOR.TEXTGRAY}
                  {...register("email")}
                />
                <Text
                  as={Text.VARIANT.TEXT}
                  color={Text.COLOR.TEXTGRAY}
                  weight={Text.WEIGHT.BOLD}
                  margin={"0 5px"}
                  size={
                    isMobile
                      ? Text.SIZE.MOBILECONTENT
                      : Input.SIZE.CLIENTCONTENT
                  }
                  isSort
                >
                  @
                </Text>
                <Input
                  type={"email"}
                  width={isMobile ? "calc(50% - 44px)" : "calc(50% - 67px)"}
                  border={"none"}
                  size={
                    isMobile
                      ? Text.SIZE.MOBILECONTENT
                      : Input.SIZE.CLIENTCONTENT
                  }
                  color={Input.COLOR.TEXTGRAY}
                  readOnly={isReadOnly}
                  {...register("emailDomain")}
                />
              </Box>

              {isMobile ? (
                <Button
                  border={"1px solid"}
                  borderColor={Button.COLOR.LINEGRAY}
                  borderRadius={"5px"}
                  width={"91px"}
                  bgColor={Button.COLOR.WHITE}
                  color={Button.COLOR.TEXTGRAY}
                  fontSize={Button.FONT_SIZE.MOBILECONTENT}
                  fontWeight={Button.FONT_WEIGHT.BOLD}
                  onClick={setIsEmail}
                >
                  {emailSelect === ""
                    ? admin.pending.form.emailSelect.title
                    : emailSelect}
                </Button>
              ) : (
                <Controller
                  name="emailSelect"
                  defaultValue=""
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      className="admin-select"
                      suffixIcon={
                        <Text
                          weight={Text.WEIGHT.BOLD}
                          color={Text.COLOR.TEXTGRAY}
                        >
                          <BsChevronDown size={16} />
                        </Text>
                      }
                      style={{
                        width: "176px",
                        height: "62px",
                        borderRadius: "20px",
                      }}
                      options={[
                        {
                          label: admin.pending.form.emailSelect.title,
                          value: "",
                        },
                        { value: "kofst.or.kr" },
                        { value: "dreamwiz.com" },
                        { value: "empal.com" },
                        { value: "freechal.com" },
                        { value: "gmail.com" },
                        { value: "hanafos.com" },
                        { value: "hanmail.net" },
                        { value: "hanmir.com" },
                        { value: "hotmail.com" },
                        { value: "korea.com" },
                        { value: "lycos.com" },
                        { value: "nate.com" },
                        { value: "naver.com" },
                        { value: "netian.com" },
                        { value: "paran.com" },
                        { value: "yahoo.com.kr" },
                        { value: "yahoo.com" },
                        { value: "chol.com" },
                      ]}
                    />
                  )}
                />
              )}
            </Box>

            <Box
              display={Box.DISPLAY.FLEX}
              padding={isMobile ? "10px 12px" : "22px 40px"}
              margin={"12px 0"}
              border={"1px solid"}
              borderColor={Box.COLOR.LINEGRAY}
              borderRadius={isMobile ? "5px" : "20px"}
              bgColor={Text.COLOR.WHITE}
            >
              <Text
                as={Text.VARIANT.TEXT}
                size={
                  isMobile ? Text.SIZE.MOBILECONTENT : Text.SIZE.CLIENTCONTENT
                }
                width={isMobile ? "50px" : "82px"}
                margin={isMobile ? "0 8px 0 0" : "0 28px 0 0"}
                borderRight={"1px solid"}
                borderColor={Text.COLOR.LINEGRAY}
                weight={Text.WEIGHT.BOLD}
                color={Text.COLOR.TEXTGRAY}
                isSort
              >
                {admin.pending.form.title.title}
                <Text as={Text.VARIANT.SPAN} color={Text.COLOR.SECONDARY}>
                  *
                </Text>
              </Text>

              <Input
                width={"calc(100% - 110px)"}
                border={"none"}
                size={
                  isMobile ? Text.SIZE.MOBILECONTENT : Input.SIZE.CLIENTCONTENT
                }
                color={Input.COLOR.TEXTGRAY}
                {...register("title")}
              />
            </Box>

            <Box
              display={Box.DISPLAY.FLEX}
              flexDirection={"column"}
              padding={isMobile ? "10px 12px" : "22px 40px 40px"}
              margin={"12px 0"}
              border={"1px solid"}
              borderColor={Box.COLOR.LINEGRAY}
              borderRadius={isMobile ? "5px" : "20px"}
              bgColor={Text.COLOR.WHITE}
            >
              <Text
                as={Text.VARIANT.TEXT}
                size={
                  isMobile ? Text.SIZE.MOBILECONTENT : Text.SIZE.CLIENTCONTENT
                }
                width={isMobile ? "50px" : "82px"}
                lineHeight={isMobile ? "16px" : "19px"}
                borderRight={"1px solid"}
                borderColor={Text.COLOR.LINEGRAY}
                weight={Text.WEIGHT.BOLD}
                color={Text.COLOR.TEXTGRAY}
                isSort
              >
                {admin.pending.form.content.title}
                <Text as={Text.VARIANT.SPAN} color={Text.COLOR.SECONDARY}>
                  *
                </Text>
              </Text>

              <Input
                as={Input.VARIANT.TEXTAREA}
                width={"100%"}
                height={isMobile ? "174px" : "330px"}
                margin={"22px 0 0 0"}
                border={"none"}
                size={
                  isMobile ? Text.SIZE.MOBILECONTENT : Input.SIZE.CLIENTCONTENT
                }
                color={Input.COLOR.TEXTGRAY}
                resize={"none"}
                {...register("content")}
              />
            </Box>

            <Text
              as={Text.VARIANT.SUBTITLE}
              color={Input.COLOR.TEXTGRAY}
              size={isMobile ? Text.SIZE.MOBILECONTENT : Text.SIZE.CLIENTNORMAL}
              borderBottom={"1px solid"}
              borderColor={Text.COLOR.LINEGRAY}
              margin={isMobile ? "30px 0 0" : "80px 0 0"}
              padding={"0 0 10px"}
            >
              {admin.pending.form.privacy.title}
              <Text as={Text.VARIANT.SPAN} color={Text.COLOR.SECONDARY}>
                *
              </Text>
            </Text>
            <Box></Box>

            <SignupAgreement isPrivacyAble isMobile={isMobile} />

            <Box
              display={Box.DISPLAY.FLEX}
              jc={"center"}
              flexDirection={isMobile ? "column" : "row"}
              margin={isMobile ? "40px 0 0 0" : "76px 0 0 0"}
            >
              {isMobile ? (
                <>
                  <Button
                    width={"100%"}
                    height={"36px"}
                    color={Button.COLOR.WHITE}
                    bgColor={
                      isActiveButton
                        ? Button.COLOR.SECONDARY
                        : Button.COLOR.LINEGRAY
                    }
                    border={"1px solid"}
                    borderColor={
                      isActiveButton
                        ? Button.COLOR.SECONDARY
                        : Button.COLOR.LINEGRAY
                    }
                    borderRadius={"20px"}
                    fontSize={Button.FONT_SIZE.MOBILECONTENT}
                    fontWeight={Button.FONT_WEIGHT.BOLD}
                    disabled={!isActiveButton}
                    onClick={handleSubmit(handleSubmitForm)}
                  >
                    {admin.pending.form.submit.accept}
                  </Button>
                  <Button
                    width={"100%"}
                    height={"36px"}
                    margin={"12px 0  0"}
                    color={Button.COLOR.SECONDARY}
                    bgColor={Button.COLOR.WHITE}
                    border={"1px solid"}
                    borderColor={Button.COLOR.SECONDARY}
                    borderRadius={"20px"}
                    fontSize={Button.FONT_SIZE.MOBILECONTENT}
                    fontWeight={Button.FONT_WEIGHT.BOLD}
                    onClick={() => reset()}
                  >
                    {admin.pending.form.submit.cancel}
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    width={"120px"}
                    height={"46px"}
                    margin={"0 20px 0 0"}
                    color={
                      isActiveButton
                        ? Button.COLOR.SECONDARY
                        : Button.COLOR.WHITE
                    }
                    bgColor={
                      isActiveButton
                        ? Button.COLOR.WHITE
                        : Button.COLOR.LINEGRAY
                    }
                    border={"1px solid"}
                    borderColor={
                      isActiveButton
                        ? Button.COLOR.SECONDARY
                        : Button.COLOR.LINEGRAY
                    }
                    borderRadius={isMobile ? "5px" : "20px"}
                    fontSize={Button.FONT_SIZE.CLIENTNORMAL}
                    fontWeight={Button.FONT_WEIGHT.BOLD}
                    disabled={!isActiveButton}
                    onClick={handleSubmit(handleSubmitForm)}
                  >
                    {admin.pending.form.submit.accept}
                  </Button>
                  <Button
                    width={"120px"}
                    height={"46px"}
                    margin={"0 20px 0 0"}
                    color={Button.COLOR.WHITE}
                    bgColor={Button.COLOR.SECONDARY}
                    border={"1px solid"}
                    borderColor={Button.COLOR.SECONDARY}
                    borderRadius={isMobile ? "5px" : "20px"}
                    fontSize={Button.FONT_SIZE.CLIENTNORMAL}
                    fontWeight={Button.FONT_WEIGHT.BOLD}
                    onClick={() => reset()}
                  >
                    {admin.pending.form.submit.cancel}
                  </Button>
                </>
              )}
            </Box>

            {isEmail && (
              <Box
                position={"fixed"}
                top={"0"}
                left={"0"}
                width={"100%"}
                height={"100vh"}
                display={Box.DISPLAY.FLEX}
                jc={"center"}
                al={"center"}
                zIndex={Box.LEVEL.ROOT}
                bgColor={Box.COLOR.SHADOW}
                onClick={setIsEmail}
              >
                <Box
                  display={Box.DISPLAY.FLEX}
                  flexDirection={"column"}
                  al={"center"}
                  jc={"space-between"}
                  position={"relative"}
                  width="275px"
                  height={"295px"}
                  bgColor={Box.COLOR.WHITE}
                  padding={"12px 10px"}
                  border={"1px solid"}
                  borderColor={Box.COLOR.SECONDARY}
                  borderRadius={"5px"}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <Text
                    as={Text.VARIANT.SUBTITLE}
                    size={Text.SIZE.MOBILETITLE}
                    weight={Text.WEIGHT.BOLD}
                    textAlign={"center"}
                  >
                    {admin.pending.form.modal.title}
                  </Text>

                  <Box position={"absolute"} top={"12px"} right={"22px"}>
                    <Button themeStyle={Button.THEME.NONE} onClick={setIsEmail}>
                      <IoMdClose size={18} />
                    </Button>
                  </Box>
                  <Picker
                    optionGroups={optionGroups}
                    valueGroups={selectEmail}
                    onChange={handleChange}
                  />

                  <Button
                    themeStyle={Button.THEME.NONE}
                    width={"100%"}
                    padding={"9px 0"}
                    fontSize={Button.FONT_SIZE.MOBILETITLE}
                    fontWeight={Button.FONT_WEIGHT.BOLD}
                    color={Button.COLOR.WHITE}
                    bgColor={Button.COLOR.SECONDARY}
                    borderRadius={"17.5px"}
                    onClick={handleSubmitEmail}
                  >
                    {admin.pending.form.modal.submit}
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
        </FormProvider>
      )}
    </>
  );
};
