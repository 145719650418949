import React from "react";
import TableCountBar from "../tableCountBar";
import List from "components/molecules/list";
import Box from "components/atoms/box";
import TableDeleteBar from "components/molecules/tableDeleteBar";

const AdminTable = (props) => {
  const {
    children,
    //
    type,
    hasSelectMenu,
    api,
    tableHeader,
    categoryName,
    //
    page,
    status,
    searchValue,
    searchCondition,
    dateCondition,
    receptionStatus,
    loginStatus,
    //
    title,
    handleDelete,
    handleSave,
    //
    cate1,
    cate2,
    currOption,
    startDate,
    endDate,
    //
    selectedMenu,
    setSelectedMenu,
    options,
    width,
    //
    handleExcelDownload,
    handleReject,
    handleMove,
  } = props;

  const renderer = (type) => {
    switch (type) {
      case "countBar":
        return (
          <TableCountBar
            api={api}
            params={{
              page,
              itemsPerPage: selectedMenu,
              status,
              searchValue,
              searchCondition,
              dateCondition,
              categoryName,
              cate1,
              cate2,
              currOption,
              startDate,
              endDate,
              receptionStatus,
              loginStatus,
            }}
            hasSelectMenu={hasSelectMenu}
            selectedMenu={selectedMenu}
            setSelectedMenu={setSelectedMenu}
            options={options}
            width={width}
          />
        );
      case "deleteBar":
        return (
          <TableDeleteBar
            title={title}
            handleDelete={handleDelete}
            handleSave={handleSave}
            handleExcelDownload={handleExcelDownload}
            handleReject={handleReject}
            handleMove={handleMove}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      {type.includes("countBar") && renderer("countBar")}
      {type.includes("deleteBar") && renderer("deleteBar")}
      <List
        api={api}
        params={{
          categoryName,
          page,
          itemsPerPage: selectedMenu,
          searchValue,
          searchCondition,
          dateCondition,
          status,
          cate1,
          cate2,
          currOption,
          startDate,
          endDate,
          receptionStatus,
          loginStatus,
        }}
        margin="40px 0"
        pagePerBlock={10}
        hasSubCategory
        isAdmin
      >
        {(list) =>
          list && (
            <>
              <Box
                display={Box.DISPLAY.FLEX}
                flexDirection="column"
                width="100%"
                bgColor={Box.COLOR.WHITE}
              >
                {React.cloneElement(children, {
                  list,
                  tableHeader,
                  categoryName,
                  itemsPerPage: selectedMenu,
                })}
              </Box>
            </>
          )
        }
      </List>
    </>
  );
};

export default AdminTable;
