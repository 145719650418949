import Box from "components/atoms/box";
import Text from "components/atoms/text";
import Blank from "components/molecules/blank";
import List from "components/molecules/list";
import SearchInput from "components/molecules/searchInput";
import NewsList from "components/organisms/newsList";
import dayjs from "dayjs";
import { useSearchNews } from "hooks/api/search";
import useUrl from "hooks/utils/useUrl";
import React, { useEffect } from "react";
import { useState } from "react";

const MobileSearch = () => {
  const {
    query: { searchKeyword, year = "2023", page = 1 },
    changeQuery,
  } = useUrl();
  const [yearList, setYearList] = useState([]);
  const [total, setTotal] = useState(0);

  const handleSearch = (searchKeyword, year) => {
    changeQuery({ searchKeyword, year });
  };

  useEffect(() => {
    let currentYear = dayjs().year();
    let years = [];

    for (let year = currentYear; year >= 2003; year--) {
      years.push({ value: year });
    }

    setYearList(years);
  }, []);

  return (
    <Box
      width="100%"
      display={Box.DISPLAY.FLEX}
      flexDirection="column"
      al="center"
      padding={"80px 12px 10px"}
    >
      <Box
        width={"100%"}
        padding={"0 40px 150px"}
        bgColor={Box.COLOR.LIGHTGRAY}
        borderRadius="10px"
      >
        <Box
          display={Box.DISPLAY.FLEX}
          flexDirection="column"
          al="center"
          width={"100%"}
          borderBottom="1px solid"
          borderColor={Box.COLOR.LINEGRAY}
          padding={"0 0 60px 0"}
        >
          <Text
            as={Text.VARIANT.TEXT}
            size={Text.SIZE.MOBILECATEGORY}
            weight={Text.WEIGHT.BOLD}
            margin={"20px 0 30px"}
          >
            <Text
              as={Text.VARIANT.SPAN}
              color={Text.COLOR.SECONDARY}
              weight={Text.WEIGHT.BOLD}
            >
              {searchKeyword}
            </Text>{" "}
            에 대한{" "}
            <Text
              as={Text.VARIANT.SPAN}
              color={Text.COLOR.SECONDARY}
              weight={Text.WEIGHT.BOLD}
            >
              {total}
            </Text>{" "}
            건의 검색결과가 있습니다.
          </Text>
          <SearchInput
            theme={SearchInput.THEME.MOBILESEARCH}
            submit={handleSearch}
            isSelect
            isMobile
            selectList={yearList}
            value={year}
          />
        </Box>

        <NewsList>
          <List
            api={useSearchNews}
            params={{ searchKeyword, getDate: year, page, limit: 4 }}
            setTotal={setTotal}
            margin={"50px 0"}
          >
            {(list) => (
              <>
                {list.length === 0 && <Blank isMobile />}
                {list.length !== 0 && (
                  <NewsList.LAYOUT.TextNews
                    list={list}
                    highlight={searchKeyword}
                    isSearch
                    isMobile
                  />
                )}
              </>
            )}
          </List>
        </NewsList>
      </Box>
    </Box>
  );
};

export default MobileSearch;
