import { css } from "styled-components";

const antd = css`
  .ant-picker-calendar .ant-picker-cell .ant-picker-cell-inner {
    border-radius: 50%;

    height: 32px;
    width: 32px;
  }

  .ant-picker-calendar
    .ant-picker-cell-in-view.ant-picker-cell-today
    .ant-picker-cell-inner::before {
    border: none;
  }

  .ant-picker-week-panel-row-selected {
    /* background-color: ${(props) => props.theme.color.darkGray} !important; */
  }

  .ant-picker-week-panel-row {
    :hover {
      background-color: ${(props) => props.theme.color.lightGray} !important;
    }
  }

  .science .ant-picker-body {
    font-size: ${(props) => props.theme.font.clientNormal} !important;
    font-family: "Pretendard Variable", "Pretendard" !important;
  }

  .mobileScience .ant-picker-body .ant-picker-cell {
    font-size: ${(props) => props.theme.font.mobileContent} !important;
    font-family: "Pretendard Variable", "Pretendard" !important;
  }

  .ant-picker-cell {
    ::before {
      display: none !important;
    }

    .ant-picker-cell-inner {
      display: none !important;
    }
  }

  .ant-picker-cell-in-view {
    .ant-picker-cell-inner {
      display: inline-block !important;
    }
  }

  .ant-picker-calendar {
    background: transparent !important;
  }

  .ant-picker-panel {
    background: transparent !important;
  }

  .ant-picker-calendar .ant-picker-panel .ant-picker-body {
    padding: 8px 10px;
  }

  .ant-picker-calendar .ant-picker-date-panel .ant-picker-content th {
    position: relative;
    padding: 12px 0;

    text-indent: 100%;
    overflow: hidden;
    white-space: nowrap;
  }

  .ant-picker-calendar .ant-picker-date-panel .ant-picker-content th::before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    text-indent: 0%;

    opacity: 1;

    color: ${(props) => props.theme.color.darkGray};
  }

  .ant-picker-calendar
    .ant-picker-date-panel
    .ant-picker-content
    th:nth-child(1)::before {
    content: "일";
  }

  .ant-picker-calendar
    .ant-picker-date-panel
    .ant-picker-content
    th:nth-child(2)::before {
    content: "월";
  }

  .ant-picker-calendar
    .ant-picker-date-panel
    .ant-picker-content
    th:nth-child(3)::before {
    content: "화";
  }

  .ant-picker-calendar
    .ant-picker-date-panel
    .ant-picker-content
    th:nth-child(4)::before {
    content: "수";
  }

  .ant-picker-calendar
    .ant-picker-date-panel
    .ant-picker-content
    th:nth-child(5)::before {
    content: "목";
  }

  .ant-picker-calendar
    .ant-picker-date-panel
    .ant-picker-content
    th:nth-child(6)::before {
    content: "금";
  }

  .ant-picker-calendar
    .ant-picker-date-panel
    .ant-picker-content
    th:nth-child(7)::before {
    content: "토";
  }

  .ant-picker-cell p {
    color: ${(props) => props.theme.color.textGray} !important;
  }

  .ant-picker-cell.ant-picker-cell-in-view p {
    color: ${(props) => props.theme.color.black} !important;
    /* font-size: ${(props) => props.theme.font.clientNormal}; */
  }

  .ant-picker-calendar-date-value {
    padding: 4px;
  }

  .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-selected
    > .ant-picker-cell-inner {
    background-color: ${(props) => props.theme.color.secondary};
  }

  .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-selected {
    font-weight: ${(props) => props.theme.weight.bold};
    color: ${(props) => props.theme.color.white};
  }

  .calendar {
    border: none;
    background-color: transparent;
    padding: 0;
    margin: 0 0 0 12px;
    cursor: pointer;

    .ant-picker-input input {
      display: none;
    }

    .ant-picker-suffix {
      margin: 0;

      color: ${(props) => props.theme.color.secondary};
      font-size: ${(props) => props.theme.font.clientTitle};
    }

    .anticon-close-circle {
      display: none;
    }

    .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-selected {
      font-weight: ${(props) => props.theme.weight.bold};
      color: ${(props) => props.theme.color.primary};
    }
  }

  .ant-picker-cell-in-view.ant-picker-cell-other-month {
    visibility: hidden;
  }

  .ant-picker-week-panel table thead tr th:first-child {
    display: none;
  }

  .ant-picker-cell.ant-picker-cell-week {
    display: none;
  }

  .ant-select-single .ant-select-selector {
    border-radius: 3px;
  }

  .admin-select .ant-select-selector {
    height: 100% !important;
    border-radius: 20px;

    .ant-select-selection-item {
      display: flex;
      align-items: center;
      justify-content: center;

      font-size: ${(props) => props.theme.font.clientContent};
      color: ${(props) => props.theme.color.textGray};
      font-weight: ${(props) => props.theme.weight.bold};
    }
  }

  .admin-select .ant-select-arrow {
    right: 40px;
  }

  .admin-search-select .ant-select-selector {
    height: 100% !important;

    .ant-select-selection-item {
      font-size: ${(props) => props.theme.font.adminContentDetail};
    }

    &:focus,
    &:active,
    &:hover {
      border-color: ${(props) => props.theme.color.secondary} !important;
    }
  }

  .search .ant-select-selector {
    width: 118px;
    background-color: ${(props) => props.theme.color.transparent} !important;
    border: none !important;
    box-shadow: none !important;

    .ant-select-selection-item {
      font-size: ${(props) => props.theme.font.clientCategory};
      color: ${(props) => props.theme.color.textGray};
    }
  }

  .mobileSearch .ant-select-selector {
    width: 64px !important;
    background-color: ${(props) => props.theme.color.transparent} !important;
    border: none !important;
    border-left: 1px solid;
    box-shadow: none !important;
    padding: 0 !important;

    .ant-select-selection-item {
      font-size: ${(props) => props.theme.font.mobileContent};
      color: ${(props) => props.theme.color.textGray};
    }
    .ant-select-item-option-content {
      font-size: ${(props) => props.theme.font.mobileContent};
    }
  }

  .mobileOption {
    font-size: 11px;
    width: 70px;
  }

  .news-select .ant-select-selector {
    border-radius: 80px;

    .ant-select-selection-item {
      font-size: ${(props) => props.theme.font.clientContent};
      color: ${(props) => props.theme.color.textGray};
      font-weight: ${(props) => props.theme.weight.bold};
    }
  }

  :where(.css-dev-only-do-not-override-12jzuas).ant-picker-dropdown
    .ant-picker-cell-disabled {
    color: ${(props) => props.theme.color.darkGray} !important;
  }

  .admin-date-picker .ant-picker-suffix {
    display: none;
  }

  .newsletter-admin-picker {
    border-radius: ${(props) => props.theme.radius.small};
    width: 104px;
    .ant-picker-suffix {
      display: none;
    }
  }

  .ant-select-selector:hover {
    border-color: ${(props) => props.theme.color.secondary} !important;
    box-shadow: none !important;
  }

  .ant-select-open .ant-select-selector {
    border-color: ${(props) => props.theme.color.secondary} !important;
    box-shadow: none !important;
  }
`;

export default antd;
