import Box from "components/atoms/box";
import Text from "components/atoms/text";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";

const TextArea = (props) => {
  const {
    name,
    rows,
    cols,
    placeholder,
    ariaLabel,
    errorMessage,
    required,
    defaultValue,
    isAdminDetail,
    padding = "10px",
  } = props;

  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext();

  const hasError = errors[name];

  // 초기값 세팅
  useEffect(() => {
    if (!defaultValue) return;
    setValue(name, defaultValue);
  }, [defaultValue, name, setValue]);

  return (
    <>
      <Box
        {...register(name, { required })}
        as="textarea"
        name={name}
        rows={rows}
        cols={cols}
        placeholder={placeholder}
        ariaLabel={ariaLabel}
        themeStyle="textarea"
        padding={padding}
        {...props}
      />
      {hasError && (
        <Box
          position={isAdminDetail ? "absolute" : "relative"}
          top={isAdminDetail ? "50%" : ""}
          transform={isAdminDetail ? "translateY(-50%)" : "none"}
        >
          <Text
            as={Text.VARIANT.TEXT}
            color={Text.COLOR.RED}
            whiteSpace="pre-line"
            size={Text.SIZE.CLIENTCONTENTDETAIL}
            padding="0 0 0 8px"
            ariaLabel={hasError.message || errorMessage}
            lineHeight="1.2"
          >
            {hasError.message || errorMessage}
          </Text>
        </Box>
      )}
    </>
  );
};

export default TextArea;
