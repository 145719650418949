import Box from "components/atoms/box";
import MobileBoard from "components/organisms/mobileBoard";
import useUrl from "hooks/utils/useUrl";
import React from "react";

const MobileFeatureDetail = () => {
  const {
    params: { id },
  } = useUrl();

  const boardType = "feature";

  return (
    <>
      <Box margin={"135px 0 0 0"} />
      <MobileBoard padding={"20px 24px"}>
        <MobileBoard.News category={id} boardType={boardType} />
      </MobileBoard>
    </>
  );
};

export default MobileFeatureDetail;
