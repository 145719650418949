import AdminWorkStatusList from "components/organisms/adminWorkStatusList";
import SearchCondition from "components/organisms/searchCondition";
import { AdminContentsContainer } from "components/templates/admin/admin.style";
import dayjs from "dayjs";
import { useCategoryList } from "hooks/api/admin/category";
import useUrl from "hooks/utils/useUrl";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { management } from "text/admin/management";
import convertDateWithYear from "utils/convertDateWithYear";

const ManagementWorkStatus = () => {
  const today = dayjs();

  const { depth1Options, keywordOptions, buttonList, defaultValues } = management.status;

  const { pathname, changeLink } = useUrl();

  const [searchValue, setSearchValue] = useState("");
  const [selectedMenu, setSelectedMenu] = useState("title");
  const [depth1Menu, setDepth1Menu] = useState("");
  const [depth2Menu, setDepth2Menu] = useState("");
  const [depth2Options, setDepth2Options] = useState([]);
  const [selectedStartDate, setSelectedStartDate] = useState(today.subtract(1, "month"));
  const [selectedEndDate, setSelectedEndDate] = useState(today);
  const [filterData, setFilterData] = useState([]);

  const { list } = useCategoryList({ categoryName: depth1Menu });

  useEffect(() => {
    if (list) {
      setDepth2Options(
        list?.map(({ categoryName, key }) => ({
          title: categoryName,
          value: key,
        }))
      );
    }
  }, [list]);

  const onSubmit = (data) => {
    const startDate = convertDateWithYear(selectedStartDate);
    const endDate = convertDateWithYear(selectedEndDate);
    const { keyword, currOption, cate1, cate2 } = data;
    const cate1Value = {
      news: "COM045",
      feature: "COM046",
      people: "COM047",
      science: "COM048",
    };
    setFilterData({
      keyword,
      currOption,
      cate1: cate1Value[cate1],
      cate2,
      startDate,
      endDate,
      searchCondition: selectedMenu,
      searchValue,
    });
    setDepth2Menu(cate2);
    setSearchValue(keyword);
    changeLink(pathname);
  };

  return (
    <AdminContentsContainer>
      <SearchCondition onSubmit={onSubmit} defaultValues={defaultValues}>
        <SearchCondition.TwoDepthSearchMenu
          title="카테고리 선택"
          depth1Menu={depth1Menu}
          setDepth1Menu={setDepth1Menu}
          depth2Menu={depth2Menu}
          setDepth2Menu={setDepth2Menu}
          depth1Options={depth1Options}
          depth2Options={depth2Options}
          width="150px"
        />
        <SearchCondition.RadioButtonList
          title="형태"
          registerName="currOption"
          list={buttonList}
          name="currOption"
          currOption=""
        />
        <SearchCondition.DatePicker
          title="등록 일시"
          selectedStartDate={selectedStartDate}
          setSelectedStartDate={setSelectedStartDate}
          selectedEndDate={selectedEndDate}
          setSelectedEndDate={setSelectedEndDate}
        />
        <SearchCondition.Select
          title="키워드 검색"
          registerName="keyword"
          selectedMenu={selectedMenu}
          setSelectedMenu={setSelectedMenu}
          options={keywordOptions}
          width="104px"
        />
      </SearchCondition>
      <AdminWorkStatusList filterData={filterData} />
    </AdminContentsContainer>
  );
};

export default ManagementWorkStatus;
