import Box from "components/atoms/box";
import Button from "components/atoms/button";
import Text from "components/atoms/text";
import useContextForm from "hooks/utils/useContextForm";
import React from "react";
import InputForm from "../inputForm";
import { useRef } from "react";
import ModalContainer from "../modalContainer";
import convertEmailFormatter from "utils/convertEmailFormatter";
import { useState } from "react";
import { useFindAdminPwMutation } from "hooks/api/auth";
import Image from "components/atoms/image";

const FindPassword = (props) => {
  const { setIsOpen } = props;
  const [successFind, setSuccessFind] = useState(false);

  const profileRef = useRef();

  const defaultValues = {
    userId: "",
    email: "",
  };

  const { methods, Provider } = useContextForm(defaultValues);

  const successFindPassword = (message) => {
    if (message === "SUCCESS") {
      setSuccessFind(true);
    }
  };

  const failFindPassword = () => {
    window.alert("비밀번호 찾기에 실패하였습니다.");
  };

  const findMutate = useFindAdminPwMutation(
    successFindPassword,
    failFindPassword
  );

  const onSubmit = (data) => {
    const { userId, email } = data;
    const { email1, email2 } = convertEmailFormatter(email);

    const confirm = window.confirm("임시 비밀번호를 발급받으시겠습니까?");
    if (confirm) findMutate({ userId, email1, email2 });
  };

  return (
    <ModalContainer
      width="418px"
      height="310px"
      setIsOpen={setIsOpen}
      ref={profileRef}
    >
      <Box display={Box.DISPLAY.FLEX} flexDirection="column" gap="5px">
        <Box>
          <Text as={Text.VARIANT.SUBTITLE}>비밀번호 찾기</Text>
        </Box>
        {!successFind && (
          <Box>
            <Text as={Text.VARIANT.TEXT} size={Text.SIZE.ADMINCATEGORY}>
              임시 비밀번호를 발급받기 위해 계정 정보를 입력해주세요.
            </Text>
          </Box>
        )}
      </Box>
      <Provider>
        {!successFind && (
          <Box
            as="form"
            onSubmit={methods.handleSubmit(onSubmit)}
            margin="15px 0 0 0"
          >
            <Box
              display={Box.DISPLAY.FLEX}
              flexDirection="column"
              gap="10px"
              margin="0 0 20px 0"
            >
              <Box display={Box.DISPLAY.FLEX} flexDirection="column" gap="5px">
                <Text
                  as={Text.VARIANT.TEXT}
                  size={Text.SIZE.ADMINCONTENTDETAIL}
                  weight={Text.WEIGHT.BOLD}
                >
                  ID
                </Text>
                <InputForm
                  type="text"
                  name="userId"
                  height="32px"
                  padding="0 8px"
                  borderRadius="3px"
                  theme="secondary"
                  ariaLabel="로그인 아이디"
                />
              </Box>
              <Box display={Box.DISPLAY.FLEX} flexDirection="column" gap="5px">
                <Text
                  as={Text.VARIANT.TEXT}
                  size={Text.SIZE.ADMINCONTENTDETAIL}
                  weight={Text.WEIGHT.BOLD}
                >
                  E-mail
                </Text>
                <InputForm
                  type="email"
                  name="email"
                  height="32px"
                  padding="0 8px"
                  borderRadius="3px"
                  theme="secondary"
                  ariaLabel="이메일"
                />
              </Box>
            </Box>
            <Button
              width="100%"
              themeStyle="secondary"
              height="48px"
              fontSize={Button.FONT_SIZE.CLIENTCONTENT}
              borderRadius={Button.RADIUS.SMALL}
              ariaLabel="임시 비밀번호 발급 버튼"
            >
              <Text
                as={Text.VARIANT.TEXT}
                size={Text.SIZE.ADMINCONTENT}
                weight={Text.WEIGHT.BOLD}
              >
                임시 비밀번호 발급
              </Text>
            </Button>
          </Box>
        )}
        {successFind && (
          <Box
            display={Box.DISPLAY.FLEX}
            flexDirection="column"
            gap="10px"
            margin="0 0 20px 0"
          >
            <Box>
              <Box
                display={Box.DISPLAY.FLEX}
                al="center"
                jc="center"
                margin="30px 0 0 0"
              >
                <Image src="/assets/admin/success.png" />
              </Box>
              <Box margin="10px 0 30px 0">
                <Text
                  as={Text.VARIANT.TEXT}
                  size={Text.SIZE.ADMINCATEGORY}
                  textAlign="center"
                >
                  등록하신 이메일로 임시 비밀번호가 발송되었습니다.
                  <br />
                  이메일을 확인해 주세요.
                </Text>
              </Box>
              <Button
                width="100%"
                themeStyle="secondary"
                height="48px"
                fontSize={Button.FONT_SIZE.CLIENTCONTENT}
                borderRadius={Button.RADIUS.SMALL}
                ariaLabel="확인"
                onClick={() => setIsOpen(false)}
              >
                <Text
                  as={Text.VARIANT.TEXT}
                  size={Text.SIZE.ADMINCONTENT}
                  weight={Text.WEIGHT.BOLD}
                >
                  확인
                </Text>
              </Button>
            </Box>
          </Box>
        )}
      </Provider>
    </ModalContainer>
  );
};

export default FindPassword;
