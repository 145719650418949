import NewsletterTemplate from "components/organisms/newsletterTemplate";
import { AdminContentsContainer } from "components/templates/admin/admin.style";
import { useNewsletterDetail } from "hooks/api/newsletter";
import useUrl from "hooks/utils/useUrl";
import React from "react";
import convertImage from "utils/convertImage";

const NewsletterPreview = () => {
  const {
    query: { key },
  } = useUrl();

  const { detail, isSuccess } = useNewsletterDetail(key);

  return (
    isSuccess && (
      <AdminContentsContainer>
        <NewsletterTemplate
          {...detail}
          id={detail?.key}
          sec1ImgUrl={convertImage(detail.sec1Img, "newsletter")}
          sec2ImgUrl={convertImage(detail.sec2Img, "newsletter")}
        />
      </AdminContentsContainer>
    )
  );
};

export default NewsletterPreview;
