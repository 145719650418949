import useNewsletterRedirect from "hooks/api/redirect";
import useUrl from "hooks/utils/useUrl";
import React from "react";
import { useEffect } from "react";

const Redirect = () => {
  const {
    query: { seq },
    changeLink,
  } = useUrl();
  const { data, isError } = useNewsletterRedirect(seq);

  useEffect(() => {
    if (isError) {
      changeLink(`/error/${seq}`);
    }
  }, [isError]);

  useEffect(() => {
    if (data) {
      if (data.data.prevNewsletter) {
        changeLink(`/prev/${seq}`);
      } else {
        changeLink(`/newsletter/${seq}`);
      }
    }
  }, [data]);

  return <></>;
};

export default Redirect;
