import Box from "components/atoms/box";
import Image from "components/atoms/image";
import Link from "components/atoms/link";
import Text from "components/atoms/text";
import Blank from "components/molecules/blank";
import dayjs from "dayjs";
import useUrl from "hooks/utils/useUrl";
import React, { useEffect } from "react";
import useMobileStore from "store/useMobileStore";
import { createPeriod } from "utils/calendar";
import convertImage from "utils/convertImage";

const CalendarList = ({
  list,
  setLength,
  setTypeForm,
  setBasicForm,
  listviewList,
  listviewBasic,
  isCoverage,
}) => {
  const { isMobile } = useMobileStore();

  const {
    query: { type, category, date = dayjs().format("YYYYMMDD") },
  } = useUrl();

  const isSummary = (type ?? listviewBasic) === "summary";

  useEffect(() => {
    setLength(list.length);
  }, [list]);

  useEffect(() => {
    setTypeForm(listviewList);
  }, [listviewList]);

  useEffect(() => {
    if (listviewBasic) {
      setBasicForm(listviewBasic);
    }
  }, [listviewBasic]);

  return (
    <>
      {list.length === 0 && (
        <Blank isMobile={isMobile} isBorderTop={isMobile} />
      )}
      {list.length !== 0 &&
        list.map(
          ({
            key,
            thumbnail,
            title,
            eventTitle,
            preBeginDate,
            preEndDate,
            eventBeginDate,
            eventEndDate,
            eventBeginHour,
            eventBeginMin,
            eventEndHour,
            eventEndMin,
            eventPlace,
          }) => (
            <Link
              link={
                isCoverage
                  ? `/news/${key}?category=${category}`
                  : `/calendar/${key}`
              }
              width="100%"
              key={key}
            >
              <Box
                display={Box.DISPLAY.FLEX}
                padding={isMobile ? "12px 24px 0" : "30px 40px 0"}
                bgColor={
                  isCoverage &&
                  date === dayjs(eventBeginDate).format("YYYYMMDD")
                    ? Box.COLOR.COVERAGE
                    : Box.COLOR.LIGHTGRAY
                }
              >
                <Box
                  width={"100%"}
                  display={Box.DISPLAY.FLEX}
                  padding={isMobile ? "0 0 12px" : "0 0 30px"}
                  borderBottom="1px solid"
                  borderColor={
                    isCoverage &&
                    date === dayjs(eventBeginDate).format("YYYYMMDD")
                      ? Box.COLOR.COVERAGELINE
                      : Box.COLOR.LINEGRAY
                  }
                >
                  {isSummary && (
                    <Image
                      aspectRatio={"284/180"}
                      width={isMobile ? "120px" : "284px"}
                      height={isMobile ? "87px" : "180px"}
                      src={convertImage(thumbnail, "thumbnail")}
                      alt={`${eventTitle ?? title} 썸네일 이미지`}
                      ariaLabel={`${eventTitle ?? title} 썸네일 이미지`}
                      radius={"0 20px"}
                    />
                  )}
                  <Box
                    display={Box.DISPLAY.FLEX}
                    flexDirection="column"
                    jc={"center"}
                    padding={
                      isSummary ? (isMobile ? "0 0 0 10px" : "0 0 0 40px") : "0"
                    }
                    width={
                      isSummary
                        ? isMobile
                          ? "calc(100% - 120px)"
                          : "calc(100% - 284px)"
                        : "100%"
                    }
                  >
                    <Text
                      as={Text.VARIANT.SUBTITLE}
                      size={
                        isMobile
                          ? Text.SIZE.MOBILECONTENT
                          : Text.SIZE.CLIENTSUBTITLE
                      }
                      margin={
                        isMobile
                          ? "0 0 5px"
                          : isSummary
                          ? "0 0 25px"
                          : "0 0 53px"
                      }
                      lineHeight={isMobile ? "14px" : "22p"}
                      lineClamp={isMobile ? "1" : "2"}
                    >
                      {eventTitle ?? title}
                    </Text>
                    <Box
                      display={Box.DISPLAY.FLEX}
                      flexDirection={isMobile ? "column" : "row"}
                      width={"100%"}
                    >
                      <Text
                        as={Text.VARIANT.TEXT}
                        width={"68px"}
                        textAlign={isMobile ? "left" : "center"}
                        padding={isMobile ? "4px 0 2px" : "2px 8px"}
                        margin={"0 8px 0 0"}
                        weight={Text.WEIGHT.BOLD}
                        bgColor={
                          isMobile
                            ? Text.COLOR.TRANSPARENT
                            : Text.COLOR.SECONDARY
                        }
                        color={
                          isMobile ? Text.COLOR.SECONDARY : Text.COLOR.WHITE
                        }
                        borderRadius={"60px"}
                        size={
                          isMobile
                            ? Text.SIZE.MOBILECONTENT
                            : Text.SIZE.CLIENTCONTENTDETAIL
                        }
                      >
                        {isCoverage ? "등록일" : "행사 기간"}
                      </Text>
                      <Text
                        as={Text.VARIANT.TEXT}
                        width={isMobile ? "100%" : "calc(100% - 68px)"}
                        lineHeight={"17px"}
                        lineClamp={isMobile ? "2" : "1"}
                        color={Text.COLOR.TEXTGRAY}
                        size={
                          isMobile
                            ? Text.SIZE.MOBILECONTENT
                            : Text.SIZE.CLIENTCONTENTDETAIL
                        }
                      >
                        {eventBeginDate} ~ {eventEndDate}
                        {createPeriod(
                          eventBeginHour,
                          eventBeginMin,
                          eventEndHour,
                          eventEndMin
                        )}
                      </Text>
                    </Box>
                    {!isCoverage && (
                      <>
                        <Box
                          display={Box.DISPLAY.FLEX}
                          flexDirection={isMobile ? "column" : "row"}
                          width={"100% "}
                          margin={isMobile ? "0" : "10px 0"}
                        >
                          <Text
                            as={Text.VARIANT.TEXT}
                            width={"68px"}
                            textAlign={isMobile ? "left" : "center"}
                            padding={isMobile ? "4px 0 2px" : "2px 8px"}
                            margin={"0 8px 0 0"}
                            weight={Text.WEIGHT.BOLD}
                            bgColor={
                              isMobile
                                ? Text.COLOR.TRANSPARENT
                                : Text.COLOR.SECONDARY
                            }
                            color={
                              isMobile ? Text.COLOR.SECONDARY : Text.COLOR.WHITE
                            }
                            borderRadius={"60px"}
                            size={
                              isMobile
                                ? Text.SIZE.MOBILECONTENT
                                : Text.SIZE.CLIENTCONTENTDETAIL
                            }
                          >
                            사전 등록
                          </Text>
                          <Text
                            width={isMobile ? "100%" : "calc(100% - 68px)"}
                            as={Text.VARIANT.TEXT}
                            lineClamp={"1"}
                            lineHeight={"17px"}
                            color={Text.COLOR.TEXTGRAY}
                            size={
                              isMobile
                                ? Text.SIZE.MOBILECONTENT
                                : Text.SIZE.CLIENTCONTENTDETAIL
                            }
                          >
                            {preBeginDate
                              ? `${preBeginDate} ~ ${preEndDate}`
                              : "-"}
                          </Text>
                        </Box>
                        <Box
                          display={Box.DISPLAY.FLEX}
                          flexDirection={isMobile ? "column" : "row"}
                          width={"100% "}
                        >
                          <Text
                            as={Text.VARIANT.TEXT}
                            width={"68px"}
                            textAlign={isMobile ? "left" : "center"}
                            padding={isMobile ? "4px 0 2px" : "2px 8px"}
                            margin={"0 8px 0 0"}
                            weight={Text.WEIGHT.BOLD}
                            bgColor={
                              isMobile
                                ? Text.COLOR.TRANSPARENT
                                : Text.COLOR.SECONDARY
                            }
                            color={
                              isMobile ? Text.COLOR.SECONDARY : Text.COLOR.WHITE
                            }
                            borderRadius={"60px"}
                            size={
                              isMobile
                                ? Text.SIZE.MOBILECONTENT
                                : Text.SIZE.CLIENTCONTENTDETAIL
                            }
                          >
                            행사 장소
                          </Text>

                          <Text
                            as={Text.VARIANT.TEXT}
                            width={isMobile ? "100%" : "calc(100% - 68px)"}
                            lineHeight={"17px"}
                            lineClamp={"1"}
                            color={Text.COLOR.TEXTGRAY}
                            size={
                              isMobile
                                ? Text.SIZE.MOBILECONTENT
                                : Text.SIZE.CLIENTCONTENTDETAIL
                            }
                          >
                            {eventPlace ?? "-"}
                          </Text>
                        </Box>
                      </>
                    )}
                  </Box>
                </Box>
              </Box>
            </Link>
          )
        )}
    </>
  );
};
export default CalendarList;
