import Box from "components/atoms/box";
import Input from "components/atoms/input";
import Text from "components/atoms/text";
import useAllCheckbox from "hooks/utils/useAllCheckbox";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

const AuthorityList = (props) => {
  const { list, setMenuNoList } = props;

  const { setValue } = useFormContext();

  const [allMenuNo, setAllMenuNo] = useState([]);
  const [subCategoryObj, setSubCategoryObj] = useState({});
  let subMenuNoArr = [];

  const ROOT_AUTHOR = "0";
  const ROOT_AUTHOR_CATEGORY = "10000";

  const tableBody = () => {
    const data = [];
    list.filter((item) => {
      if (item.upperMenuNo === ROOT_AUTHOR || item.upperMenuNo === ROOT_AUTHOR_CATEGORY)
        data.push(item);
    });
    return data;
  };

  const tableBodyData = tableBody();

  const subCategoryList = (menuNo) => {
    const data = [];
    list.filter((item) => {
      if (item.upperMenuNo === menuNo) data.push(item);
    });
    return data;
  };

  const {
    checkAll,
    checkboxes,
    handleCheckAllChange,
    handleCheckboxChange,
    handleSubCheckboxChange,
  } = useAllCheckbox(tableBodyData.length, allMenuNo, subCategoryObj, subCategoryList);

  const tableHeader = [
    { title: "메인 카테고리", width: "19%" },
    { title: "서브 카테고리", width: "77%" },
  ];

  useEffect(() => {
    if (list) {
      const subCategoryList = () => {
        const data = [];
        list.filter((item) => {
          if (item.upperMenuNo !== "0" && item.upperMenuNo !== "10000") data.push(item);
        });
        return data;
      };
      const subCategory = subCategoryList();

      subCategory.forEach(({ menuNo, createMenuChk }) => {
        setValue(menuNo, createMenuChk === "1" ? true : false);
      });

      tableBodyData.filter((item) => setAllMenuNo((p) => [...p, item.menuNo]));
    }
  }, [list]);

  useEffect(() => {
    setMenuNoList(checkboxes);
  }, [checkboxes]);

  useEffect(() => {
    let newSubCategoryObj = {};
    tableBodyData.forEach(({ menuNo }) => {
      newSubCategoryObj = {
        ...newSubCategoryObj,
        ...subCategoryList(menuNo).reduce((acc, { menuName, menuNo, createMenuChk }) => {
          const isChecked = createMenuChk === "1" ? true : false;
          acc[menuNo] = isChecked;
          return acc;
        }, {}),
      };
    });

    setSubCategoryObj(newSubCategoryObj);
  }, []);

  return (
    <Box maxWidth="795px">
      <Box
        display={Box.DISPLAY.FLEX}
        al="center"
        height="32px"
        borderTop="1px solid"
        borderColor={Box.COLOR.ADMINLINEGRAY}
        bgColor={Box.COLOR.WHITE}
      >
        <Box
          width="4%"
          height="100%"
          display={Box.DISPLAY.FLEX}
          al="center"
          jc="center"
          borderLeft="1px solid"
          borderBottom="1px solid"
          borderColor={Box.COLOR.ADMINLINEGRAY}
        >
          <Input
            type="checkbox"
            width="16px"
            height="16px"
            borderColor={Input.COLOR.ADMINLINEGRAY}
            onChange={handleCheckAllChange}
            checked={checkAll}
          />
        </Box>
        {tableHeader.map(({ title, width }, idx) => (
          <Box
            width={width}
            height="100%"
            display={Box.DISPLAY.FLEX}
            al="center"
            jc="center"
            key={idx}
            borderLeft="1px solid"
            borderRight={idx === tableHeader.length - 1 ? "1px solid" : "none"}
            borderBottom="1px solid"
            borderColor={Box.COLOR.ADMINLINEGRAY}
          >
            <Text
              as={Text.VARIANT.TEXT}
              weight={Text.WEIGHT.BOLD}
              size={Text.SIZE.CLIENTCONTENTDETAIL}
            >
              {title}
            </Text>
          </Box>
        ))}
      </Box>
      {tableBodyData.map(({ menuNo, menuName }, idx) => {
        return (
          <Box
            display={Box.DISPLAY.FLEX}
            al="center"
            key={idx}
            height="38px"
            borderBottom="1px solid"
            borderColor={Box.COLOR.ADMINLINEGRAY}
            bgColor={Box.COLOR.WHITE}
          >
            <Box
              width="4%"
              height="100%"
              display={Box.DISPLAY.FLEX}
              jc="center"
              borderLeft="1px solid"
              borderColor={Box.COLOR.ADMINLINEGRAY}
              al="center"
            >
              <Input
                type="checkbox"
                name={menuNo}
                width="16px"
                height="16px"
                borderColor={Input.COLOR.ADMINLINEGRAY}
                checked={checkboxes.main[menuNo] ?? false}
                onChange={(e) => handleCheckboxChange({ e, idx })}
              />
            </Box>
            <Box
              width="19%"
              height="100%"
              display={Box.DISPLAY.FLEX}
              jc="center"
              borderLeft="1px solid"
              borderColor={Box.COLOR.ADMINLINEGRAY}
              al="center"
            >
              <Text as={Text.VARIANT.TEXT} size={Text.SIZE.ADMINCONTENT}>
                {menuName}
              </Text>
            </Box>
            <Box
              width="77%"
              height="100%"
              padding="0 10px"
              display={Box.DISPLAY.FLEX}
              gap="20px"
              borderLeft="1px solid"
              borderColor={Box.COLOR.ADMINLINEGRAY}
              al="center"
            >
              {subCategoryList(menuNo).map(({ menuName, menuNo: subMenuNo, createMenuChk }) => {
                subMenuNoArr.push(subMenuNo);

                return (
                  <Box
                    as="label"
                    width="fit-content"
                    display={Box.DISPLAY.FLEX}
                    al="center"
                    gap="5px"
                    cursor="pointer"
                    key={subMenuNo}
                  >
                    <Box display={Box.DISPLAY.FLEX} al="center" gap="5px">
                      <Input
                        type="checkbox"
                        name={subMenuNo}
                        width="16px"
                        height="16px"
                        borderColor={Input.COLOR.ADMINLINEGRAY}
                        checked={checkboxes.sub[subMenuNo] ?? false}
                        onChange={(e) => handleSubCheckboxChange({ e, menuNo })}
                        ariaLabel={menuName}
                      />
                      <Text as={Text.VARIANT.TEXT} size={Text.SIZE.CLIENTCONTENTDETAIL}>
                        {menuName}
                      </Text>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default AuthorityList;
