import Box from "components/atoms/box";
import Loading from "components/atoms/loading";
import InputForm from "components/molecules/inputForm";
import AdminDetailForm from "components/organisms/adminDetailForm";
import AdminDetailFormContainer from "components/organisms/adminDetailFormContainer";
import { AdminContentsContainer } from "components/templates/admin/admin.style";
import { useGetManagerEmail, useUpdateManagerEmail } from "hooks/api/admin/cs";
import React from "react";
import { common } from "text/admin/common";
import { newsletter } from "text/admin/newsletter";

const UpdateAdminMail = () => {
  const { defaultValues } = newsletter.banner.detail;

  const { save } = common.alert.default;

  const mutate = useUpdateManagerEmail();

  const { data, isLoading } = useGetManagerEmail();

  const formInfo = [
    {
      title: "메일 주소",
      minHeight: "48px",
      components: (
        <InputForm
          name="email"
          type="email"
          width="430px"
          height="32px"
          padding="0 12px"
          theme="secondary"
          defaultName={data?.email}
          required="필수값 입니다."
          ariaLabel="관리자 이메일 주소"
        />
      ),
      required: true,
    },
  ];

  const handleSubmit = (data) => {
    const confirm = window.confirm(save);
    if (confirm) mutate({ data });
  };

  if (isLoading) return <Loading />;

  return (
    <AdminContentsContainer>
      <Box maxWidth="1024px">
        <AdminDetailFormContainer
          title="관리자에게 메일 주소"
          defaultValues={defaultValues}
          handleSubmit={handleSubmit}
          isEmailView={true}
          isHidden={true}
        >
          <AdminDetailForm formInfo={formInfo} />
        </AdminDetailFormContainer>
      </Box>
    </AdminContentsContainer>
  );
};

export default UpdateAdminMail;
