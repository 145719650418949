import { adminDefaultUrl } from "api/admin/url";
import AdminNewsLetterList from "components/organisms/adminNewsLetterList";
import SearchCondition from "components/organisms/searchCondition";
import { AdminContentsContainer } from "components/templates/admin/admin.style";
import useUrl from "hooks/utils/useUrl";
import React, { useState } from "react";
import { newsletter } from "text/admin/newsletter";

const ManagementNewsLetter = () => {
  const { keywordOptions, defaultValues } = newsletter;

  const { changeLink } = useUrl();
  const [selectedMenu, setSelectedMenu] = useState("title");
  const [filterData, setFilterData] = useState({});

  const onSubmit = (data) => {
    const { index: searchCondition, keyword } = data;
    setFilterData({
      searchCondition,
      keyword,
    });
    changeLink(`${adminDefaultUrl}/newsletter/management`);
  };

  return (
    <AdminContentsContainer>
      <SearchCondition onSubmit={onSubmit} defaultValues={defaultValues}>
        <SearchCondition.Select
          title="키워드 검색"
          registerName="keyword"
          selectedMenu={selectedMenu}
          setSelectedMenu={setSelectedMenu}
          options={keywordOptions}
          width="104px"
        />
      </SearchCondition>
      <AdminNewsLetterList filterData={filterData} />
    </AdminContentsContainer>
  );
};

export default ManagementNewsLetter;
