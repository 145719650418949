import { DELETE, GET, PATCH, POST, PUT } from "api/config";

export const getComment = async (id, sort, page) =>
  await GET(`/comment/${id}?sortBy=${sort}&pageIndex=${page}`);

export const createComment = async ({ key, body }) => {
  return await POST(`/comment/${key}`, body);
};

export const createReply = async (key, seq, body) =>
  await POST(`/comment/${key}?refNo=${seq}`, body);

export const updateComment = async ({ key, seq, body }) =>
  await PUT(`/comment/${key}/${seq}`, body);

export const deleteComment = async ({ key, seq }) =>
  await PATCH(`/comment/${key}/${seq}`);

export const createLike = async ({ key, seq }) =>
  await POST(`/comment/${key}/${seq}/like`);

export const deleteLike = async ({ key, seq }) =>
  await DELETE(`/comment/${key}/${seq}/like`);
