import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { BoxComponent } from "./box.style";
import { COLOR, RADIUS } from "styles/theme";

const LEVEL = {
  BOTTOM: "0",
  MIDDLE: "1",
  TOP: "2",

  ROOT: "9999",
};

const DISPLAY = {
  FLEX: "flex",
  BLOCK: "block",
  INLINE_BLOCK: "inline-block",
  INLINE_FLEX: "inline-flex",
  GRID: "grid",
  NONE: "none",
  TABLE: "table",
};

const THEME = {
  NEWSLETTERFORM_INPUT: "newsletterFormInput",
  NEWSLETTERFORM_BACKGROUND: "newsletterFormBackground",
};

const Box = forwardRef((props, ref) => {
  const {
    as,
    children,
    //
    className,
    name,
    //
    display,
    flexDirection,
    wrap,
    jc,
    al,
    gap,
    flex,
    //
    position,
    top,
    left,
    right,
    bottom,
    //
    zIndex,
    //
    float,
    //
    overflow,
    overflowX,
    overflowY,
    opacity,
    //
    width,
    maxWidth,
    minWidth,
    height,
    minHeight,
    maxHeight,
    //
    margin,
    padding,
    //
    bgColor,
    bgImage,
    bgSize,
    bgRepeat,
    //
    border,
    borderTop,
    borderBottom,
    borderLeft,
    borderRight,
    borderRadius,
    borderColor,
    borderCollapse,
    //
    isShadow,
    isActiveMenu,
    isNotActiveMenu,
    isActiveDropdownMenu,
    //
    cursor,
    //
    aspectRatio,
    //
    color,
    themeStyle,
    //
    gridColumns,
    isClearBlank,
    //
    onChange,
    //
    value,
    //
    onSubmit,
    //
    rows,
    cols,
    defaultValue,
    //
    transform,
    //
    ariaLabel,
    //
    id,
    //
    wordBreak,
    //
    ...etc
  } = props;

  return (
    <BoxComponent
      as={as}
      //
      id={id}
      name={name}
      className={className}
      //
      $display={display}
      flexDirection={flexDirection}
      jc={jc}
      al={al}
      $wrap={wrap}
      gap={gap}
      flex={flex}
      //
      position={position}
      top={top}
      left={left}
      right={right}
      bottom={bottom}
      //
      zIndex={zIndex}
      //
      $overflow={overflow}
      overflowX={overflowX}
      overflowY={overflowY}
      $opacity={opacity}
      //
      float={float}
      //
      $width={width}
      maxWidth={maxWidth}
      minWidth={minWidth}
      $height={height}
      minHeight={minHeight}
      maxHeight={maxHeight}
      //
      margin={margin}
      padding={padding}
      //
      bgColor={bgColor}
      bgImage={bgImage}
      bgSize={bgSize}
      bgRepeat={bgRepeat}
      //
      border={border}
      borderTop={borderTop}
      borderBottom={borderBottom}
      borderLeft={borderLeft}
      borderRight={borderRight}
      borderRadius={borderRadius}
      borderColor={borderColor}
      borderCollapse={borderCollapse}
      //
      aspectRatio={aspectRatio}
      //
      isShadow={isShadow}
      isActiveMenu={isActiveMenu}
      isNotActiveMenu={isNotActiveMenu}
      isActiveDropdownMenu={isActiveDropdownMenu}
      //
      $cursor={cursor}
      //
      $color={color}
      themeStyle={themeStyle}
      //
      gridColumns={gridColumns}
      isClearBlank={isClearBlank}
      //
      onChange={onChange}
      //
      value={value}
      //
      onSubmit={onSubmit}
      //
      rows={rows}
      cols={cols}
      defaultValue={defaultValue}
      //
      ref={ref}
      //
      $transform={transform}
      //
      wordBreak={wordBreak}
      //
      {...etc}
    >
      {children}
    </BoxComponent>
  );
});

Box.LEVEL = LEVEL;
Box.RADIUS = RADIUS;
Box.COLOR = COLOR;
Box.DISPLAY = DISPLAY;

Box.propTypes = {
  /**
   * The content of the component.
   */
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),

  /**
   * The display of the component.
   * use Box.DISPLAY
   */
  display: PropTypes.oneOf(Object.values(DISPLAY)),
  /**
   * The flexDirection of the component.
   */
  flexDirection: PropTypes.oneOf(["row", "column", "row-reverse", "column-reverse"]),
  /**
   * The justifyContent of the component.
   */
  justifyContent: PropTypes.oneOf([
    "flex-start",
    "flex-end",
    "center",
    "space-between",
    "space-around",
  ]),
  /**
   * The alignItems of the component.
   */
  alignItems: PropTypes.oneOf(["flex-start", "flex-end", "center"]),

  /**
   * The position of the component.
   */
  position: PropTypes.oneOf(["relative", "absolute", "fixed"]),
  /**
   * The top of the component.
   * ex) "10px"
   */
  top: PropTypes.string,
  /**
   * The left of the component.
   * ex) "10px"
   */
  left: PropTypes.string,
  /**
   * The right of the component.
   * ex) "10px"
   */
  right: PropTypes.string,
  /**
   * The bottom of the component.
   * ex) "10px"
   */
  bottom: PropTypes.string,

  /**
   * The zIndex of the component.
   * use Box.LEVEL
   */
  zIndex: PropTypes.oneOf(Object.values(LEVEL)),

  /**
   * The width of the component.
   * ex) "100px"
   */
  width: PropTypes.string,
  /**
   * The maxWidth of the component.
   * ex) "100px"
   */
  maxWidth: PropTypes.string,
  /**
   * The height of the component.
   * ex) "100px"
   */
  height: PropTypes.string,

  /**
   * The margin of the component.
   * ex) "10px 10px 0 10px"
   * ex) "10px 10px"
   * ex) "10px"
   */
  margin: PropTypes.string,
  /**
   * The padding of the component.
   * ex) "10px 10px 0 10px"
   * ex) "10px 10px"
   * ex) "10px"
   */
  padding: PropTypes.string,

  /**
   * The bgColor of the component.
   * use Box.COLOR
   */
  bgColor: PropTypes.oneOf(Object.values(COLOR)),

  /**
   * The border of the component.
   * ex) "1px solid #000"
   */
  border: PropTypes.string,
  /**
   * The borderTop of the component.
   * ex) "1px solid #000"
   */
  borderTop: PropTypes.string,
  /**
   * The borderBottom of the component.
   * ex) "1px solid #000"
   */
  borderBottom: PropTypes.string,
  /**
   * The borderLeft of the component.
   * ex) "1px solid #000"
   */
  borderLeft: PropTypes.string,
  /**
   * The borderRight of the component.
   * ex) "1px solid #000"
   */
  borderRight: PropTypes.string,
  /**
   * The borderRadius of the component.
   * use Box.RADIUS
   * ex) "10px"
   */
  borderRadius: PropTypes.oneOfType([PropTypes.oneOf(Object.values(RADIUS)), PropTypes.string]),
  /**
   * The borderColor of the component.
   * use Box.COLOR
   */
  borderColor: PropTypes.oneOf(Object.values(COLOR)),

  /**
   * The shadow of the component.
   * ex) true
   */
  isShadow: PropTypes.bool,
};

Box.THEME = THEME;

export default Box;
