import Box from "components/atoms/box";
import List from "components/molecules/list";
import Board from "components/organisms/board";
import NewsCategory from "components/organisms/newsCategory";
import NewsList from "components/organisms/newsList";
import { useHeadLineNews } from "hooks/api/news";
import useUrl from "hooks/utils/useUrl";
import React from "react";
import newsText from "text/news";

const { newsTitle, dailyNewsList } = newsText;

const News = () => {
  const {
    query: { category = "all" },
  } = useUrl();

  const boardType = "news";
  const isAllPage = category === "all";
  const isSchedule = category === "COM045_AspbHPe";

  return (
    <Box
      width="100%"
      display={Box.DISPLAY.FLEX}
      flexDirection="column"
      al="center"
    >
      {isAllPage && (
        <>
          <Box
            display={Box.DISPLAY.FLEX}
            width={"1260px"}
            padding={"32px 40px"}
            margin={"80px 0 40px"}
            bgColor={Box.COLOR.LIGHTGRAY}
            borderRadius="10px"
          >
            <NewsList
              title={newsTitle.headline.text}
              bold={newsTitle.headline.bold}
            >
              <List api={useHeadLineNews} isBorderTop>
                {(list) => list && <NewsList.LAYOUT.HeadLineNews list={list} />}
              </List>
            </NewsList>
          </Box>

          <NewsCategory newsInfoList={dailyNewsList} category={boardType} />
        </>
      )}

      <Board isAllPage={isAllPage} isCalendar={isSchedule}>
        {isSchedule ? (
          <Board.Calendar isCoverage />
        ) : (
          <Board.News category={category} boardType={boardType} />
        )}
      </Board>
    </Box>
  );
};

export default News;
