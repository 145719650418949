import Box from "components/atoms/box";
import MobileBoard from "components/organisms/mobileBoard";
import NewsletterList from "components/organisms/newsletterList";
import NewsletterRequest from "components/organisms/newsletterRequest";
import useUrl from "hooks/utils/useUrl";
import React, { useState } from "react";
import { useEffect } from "react";

const MobileNewsLetter = () => {
  const {
    query: { category },
  } = useUrl();
  const [isHidden, setIsHidden] = useState(true);

  const isBefore = category === "before";
  const isRequest = category === "request";

  useEffect(() => {
    setIsHidden(false);
  }, [category]);

  return (
    <>
      <Box margin={"145px 0 0 0"} padding={"20px 0 0"} />
      <MobileBoard isAllPage={isHidden} isTransparent={isHidden}>
        {isRequest ? (
          <NewsletterRequest isMobile setIsHidden={setIsHidden} />
        ) : (
          <NewsletterList isBefore={isBefore} isMobile />
        )}
      </MobileBoard>
    </>
  );
};

export default MobileNewsLetter;
