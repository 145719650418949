import Box from "components/atoms/box";
import Slider, { nextSlide, prevSlide } from "components/atoms/slider";
import Text from "components/atoms/text";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import React, { useRef } from "react";
import { VscCircleFilled } from "@react-icons/all-files/vsc/VscCircleFilled";
import { BsChevronDown } from "@react-icons/all-files/bs/BsChevronDown";
import { BsChevronUp } from "@react-icons/all-files/bs/BsChevronUp";
import Button from "components/atoms/button";
import { createPeriod } from "utils/calendar";
import Link from "components/atoms/link";
import useMobileStore from "store/useMobileStore";
import homeText from "text/home";

const { scienceCalendar } = homeText;

dayjs.extend(isBetween);

const preTitle = (title) => {
  const splitTitle = title.split("]");

  return splitTitle[0] + "]";
};

const changeDate = (date) => {
  const dayObj = dayjs(date);

  return dayObj.format("YY.MM.DD");
};

const dayList = [
  "일요일",
  "월요일",
  "화요일",
  "수요일",
  "목요일",
  "금요일",
  "토요일",
];

const slideOption = {
  direction: "vertical",
  slidesPerView: 1,
  simulateTouch: false,
  loop: true,
  navigation: true,
};

const ScienceCalendarDate = (props) => {
  const {
    getEventBeginCalendarList = [],
    getPreBeginCalendarList = [],
    scienceDate,
  } = props;
  const preEventSliderRef = useRef(null);
  const eventSliderRef = useRef(null);
  const { isMobile } = useMobileStore();

  const date = scienceDate.format("YYYY-MM-DD");
  const checkDate = dayjs(date);

  const eventDateList = getEventBeginCalendarList.filter(
    ({ eventBeginDate, eventEndDate }) => {
      const startDate = dayjs(eventBeginDate);
      const endDate = dayjs(eventEndDate);

      return checkDate.isBetween(startDate, endDate, null, "[]");
    }
  );
  const preEventDateList = getPreBeginCalendarList.filter(
    ({ preBeginDate, preEndDate }) => {
      const startDate = dayjs(preBeginDate);
      const endDate = dayjs(preEndDate);

      return checkDate.isBetween(startDate, endDate, null, "[]");
    }
  );

  const eventDate = scienceDate.format("DD");
  const eventDay = dayList[scienceDate.day()];

  return (
    <Box>
      <Box
        display={Box.DISPLAY.FLEX}
        jc={"space-between"}
        wrap={isMobile ? "wrap" : "nowrap"}
        position="relative"
        padding={isMobile ? "20px 0" : "32px 0 26px"}
        borderTop="1px solid"
        borderBottom="1px solid"
        borderColor={Box.COLOR.LINEGRAY}
        ariaLabel={`${date} 과학기술 사전 등록 슬라이더`}
      >
        <Box
          display={Box.DISPLAY.FLEX}
          flexDirection={isMobile ? "column" : "row"}
          width={"100%"}
          al={isMobile ? "flex-start" : "center"}
        >
          <Text as={Text.VARIANT.TEXT}>
            <Text
              as={Text.VARIANT.SPAN}
              color={Text.COLOR.THIRD}
              lineHeight={"1px"}
            >
              <VscCircleFilled size={10} />
            </Text>
            <Text
              as={Text.VARIANT.SPAN}
              padding={"2px 10px"}
              bgColor={Text.COLOR.THIRD}
              color={Text.COLOR.WHITE}
              weight={Text.WEIGHT.BOLD}
              size={
                isMobile
                  ? Text.SIZE.MOBILECONTENT
                  : Text.SIZE.CLIENTCONTENTDETAIL
              }
              borderRadius={"60px"}
              lineHeight={"15.73px"}
            >
              {scienceCalendar.prev}
            </Text>
          </Text>

          <Box
            width={isMobile ? "100%" : "317px"}
            margin={isMobile ? "11px 0 0" : "0 0 0 16px"}
          >
            <Slider
              {...slideOption}
              spaceBetween={"10"}
              width={isMobile ? "100%" : "317px"}
              height={isMobile ? "54px" : "14px"}
              sliderRef={preEventSliderRef}
            >
              {preEventDateList.length === 0 && (
                <Slider.Slide>
                  <Text
                    as={Text.VARIANT.TEXT}
                    size={
                      isMobile
                        ? Text.SIZE.MOBILECONTENT
                        : Text.SIZE.CLIENTCONTENTDETAIL
                    }
                    lineClamp={1}
                    wordBreak="break-all"
                    ariaLabel="등록된 일정이 없습니다."
                    padding={isMobile ? "10px 18px" : ""}
                    border={isMobile ? "1px solid" : ""}
                    borderColor={Box.COLOR.LINEGRAY}
                    borderRadius={isMobile ? "5px" : ""}
                    height={"100%"}
                  >
                    {scienceCalendar.prevBlank}
                  </Text>
                </Slider.Slide>
              )}
              {preEventDateList.map(
                ({ key, preBeginDate, preEndDate, eventTitle }) => (
                  <Slider.Slide key={key}>
                    <Link link={`/calendar/${key}`} width={"100%"}>
                      <Box
                        width={"100%"}
                        padding={isMobile ? "10px 18px" : ""}
                        border={isMobile ? "1px solid" : ""}
                        borderColor={Box.COLOR.LINEGRAY}
                        borderRadius={isMobile ? "5px" : ""}
                        ariaLabel={`${eventTitle} 사전등록 기간 ${preBeginDate} ~ ${preEndDate}`}
                      >
                        <Text
                          as={Text.VARIANT.TEXT}
                          size={
                            isMobile
                              ? Text.SIZE.MOBILECONTENT
                              : Text.SIZE.CLIENTCONTENTDETAIL
                          }
                          lineClamp={1}
                          wordBreak="break-all"
                          ariaLabel={`${eventTitle} 사전등록 기간 ${preBeginDate} ~ ${preEndDate}`}
                        >
                          {preTitle(eventTitle)}

                          {!isMobile && (
                            <Text
                              as={Text.VARIANT.SPAN}
                              color={Text.COLOR.TEXTGRAY}
                              size={
                                isMobile
                                  ? Text.SIZE.MOBILECONTENT
                                  : Text.SIZE.CLIENTCONTENTDETAIL
                              }
                              margin={"0 0 0 5px"}
                              ariaLabel={`${eventTitle} 사전등록 기간 ${preBeginDate} ~ ${preEndDate}`}
                            >
                              {changeDate(preBeginDate)} ~{" "}
                              {changeDate(preEndDate)}
                            </Text>
                          )}
                        </Text>
                        {isMobile && (
                          <Text
                            as={Text.VARIANT.TEXT}
                            color={Text.COLOR.TEXTGRAY}
                            size={
                              isMobile
                                ? Text.SIZE.MOBILECONTENT
                                : Text.SIZE.CLIENTCONTENTDETAIL
                            }
                            margin={"4px 0 0"}
                            ariaLabel={`${eventTitle} 사전등록 기간 ${preBeginDate} ~ ${preEndDate}`}
                          >
                            {changeDate(preBeginDate)} ~{" "}
                            {changeDate(preEndDate)}
                          </Text>
                        )}
                      </Box>
                    </Link>
                  </Slider.Slide>
                )
              )}
            </Slider>
          </Box>
        </Box>
        {!isMobile && (
          <Button
            themeStyle={Button.THEME.NONE}
            onClick={nextSlide(preEventSliderRef)}
            ariaLabel={"다음 사전등록 기간 슬라이더 보기"}
          >
            <BsChevronDown />
          </Button>
        )}
        {isMobile && preEventDateList.length !== 0 && (
          <Box display={Box.DISPLAY.FLEX} gap="5.5px" width={"100%"}>
            <Button
              width={"50%"}
              height={"24px"}
              border="1px solid"
              borderColor={Button.COLOR.LINEGRAY}
              borderRadius={"5px"}
              themeStyle={Button.THEME.NONE}
              bgColor={
                preEventDateList.length === 0
                  ? Button.COLOR.GRAY
                  : Button.COLOR.WHITE
              }
              color={Button.COLOR.TEXTGRAY}
              onClick={prevSlide(preEventSliderRef)}
              ariaLabel={"이전 사전등록 기간 슬라이더 보기"}
            >
              <BsChevronUp />
            </Button>
            <Button
              width={"50%"}
              height={"24px"}
              border="1px solid"
              borderColor={Button.COLOR.LINEGRAY}
              borderRadius={"5px"}
              themeStyle={Button.THEME.NONE}
              bgColor={
                preEventDateList.length === 0
                  ? Button.COLOR.GRAY
                  : Button.COLOR.WHITE
              }
              color={Button.COLOR.TEXTGRAY}
              onClick={nextSlide(preEventSliderRef)}
              ariaLabel={"다음 사전등록 기간 슬라이더 보기"}
            >
              <BsChevronDown />
            </Button>
          </Box>
        )}
      </Box>

      <Box padding={"20px 0 0px"}>
        {!isMobile && (
          <Text
            as={Text.VARIANT.TEXT}
            size={Text.SIZE.CLIENTCONTENT}
            weight={Text.WEIGHT.BOLD}
            padding={"14px 0 11px"}
            ariaLabel={"행사기간"}
          >
            {scienceCalendar.period}
          </Text>
        )}

        <Box
          display={Box.DISPLAY.FLEX}
          jc={"space-between"}
          height={isMobile ? "111px" : "105px"}
          ariaLabel={`${date} 과학기술 행사 슬라이더`}
        >
          {!isMobile && (
            <Box
              display={Box.DISPLAY.FLEX}
              bgColor={Box.COLOR.WHITE}
              width={isMobile ? "100%" : "calc(100% - 24px - 4px)"}
              height={"100%"}
              padding={"13px 20px"}
              ariaLabel={`${date} 과학기술 행사 슬라이더`}
            >
              <Box
                width={"46px"}
                display={Box.DISPLAY.FLEX}
                jc="center"
                al="center"
                flexDirection="column"
                margin={"0 20px 0 0"}
                ariaLabel={`${date} 과학기술 행사`}
              >
                <Text
                  as={Text.VARIANT.TEXT}
                  margin={"0 0 5px"}
                  color={Text.COLOR.PRIMARY}
                  size={Text.SIZE.CLIENTLARGE}
                  weight={Text.WEIGHT.BOLD}
                  ariaLabel={`${date} 과학기술 행사`}
                >
                  {eventDate}
                </Text>

                <Text
                  size={Text.SIZE.CLIENTCONTENT}
                  ariaLabel={`${date} 과학기술 행사`}
                >
                  {eventDay}
                </Text>
              </Box>

              <Slider
                {...slideOption}
                width={isMobile ? "100%" : "calc(100% - 46px - 20px)"}
                height={"100%"}
                sliderRef={eventSliderRef}
              >
                {eventDateList.length === 0 && (
                  <Slider.Slide>
                    <Box
                      display={Box.DISPLAY.FLEX}
                      al="center"
                      height={"100%"}
                      ariaLabel={`등록된 행사가 없습니다.`}
                    >
                      <Text
                        as={Text.VARIANT.TEXT}
                        lineClamp={1}
                        wordBreak="break-all"
                        ariaLabel={`등록된 행사가 없습니다.`}
                      >
                        <Text
                          as={Text.VARIANT.SPAN}
                          margin={"0 6px 0 0"}
                          lineHeight={"1px"}
                          ariaLabel={`등록된 행사가 없습니다.`}
                        >
                          <VscCircleFilled size={10} />
                        </Text>
                        {scienceCalendar.blank}
                      </Text>
                    </Box>
                  </Slider.Slide>
                )}
                {eventDateList.map(
                  ({
                    key,
                    eventTitle,
                    eventPlace,
                    eventBeginDate,
                    eventEndDate,
                    eventBeginHour,
                    eventBeginMin,
                    eventEndHour,
                    eventEndMin,
                  }) => (
                    <Slider.Slide key={key}>
                      <Link
                        link={`/calendar/${key}`}
                        width={"100%"}
                        height={"105px"}
                      >
                        <Box borderColor={Box.COLOR.LINEGRAY}>
                          <Text
                            as={Text.VARIANT.TEXT}
                            lineClamp={1}
                            wordBreak="break-all"
                            size={Text.SIZE.CLIENTCONTENT}
                            weight={Text.WEIGHT.BOLD}
                            ariaLabel={`${eventTitle} 사전등록 기간 ${eventBeginDate} ~ ${eventEndDate} ${
                              eventPlace ? `행사 장소 : ${eventPlace}` : ""
                            }`}
                          >
                            <Text
                              as={Text.VARIANT.SPAN}
                              margin={"0 6px 0 0"}
                              lineHeight={"1px"}
                            >
                              <VscCircleFilled size={10} />
                            </Text>
                            {eventTitle}
                          </Text>

                          <Text
                            as={Text.VARIANT.TEXT}
                            margin={"12px 0 8px"}
                            lineClamp={1}
                            wordBreak="break-all"
                            size={Text.SIZE.CLIENTCONTENTDETAIL}
                            color={Text.COLOR.TEXTGRAY}
                            ariaLabel={`${eventTitle} 사전등록 기간 ${eventBeginDate} ~ ${eventEndDate} ${
                              eventPlace ? `행사 장소 : ${eventPlace}` : ""
                            }`}
                          >
                            <Text
                              as={Text.VARIANT.SPAN}
                              padding={"2px 10px"}
                              margin={"0 12px 0 0"}
                              bgColor={Text.COLOR.SECONDARY}
                              color={Text.COLOR.WHITE}
                              weight={Text.WEIGHT.BOLD}
                              borderRadius={"60px"}
                            >
                              {scienceCalendar.period}
                            </Text>
                            {changeDate(eventBeginDate)} ~{" "}
                            {changeDate(eventEndDate)}{" "}
                            {createPeriod(
                              eventBeginHour,
                              eventBeginMin,
                              eventEndHour,
                              eventEndMin
                            )}
                          </Text>

                          <Text
                            as={Text.VARIANT.TEXT}
                            lineClamp={1}
                            wordBreak="break-all"
                            size={Text.SIZE.CLIENTCONTENTDETAIL}
                            color={Text.COLOR.TEXTGRAY}
                            ariaLabel={`${eventTitle} 사전등록 기간 ${eventBeginDate} ~ ${eventEndDate} ${
                              eventPlace ? `행사 장소 : ${eventPlace}` : ""
                            }`}
                          >
                            <Text
                              as={Text.VARIANT.SPAN}
                              padding={"2px 10px"}
                              margin={"0 12px 0 0"}
                              bgColor={Text.COLOR.SECONDARY}
                              color={Text.COLOR.WHITE}
                              weight={Text.WEIGHT.BOLD}
                              borderRadius={"60px"}
                              ariaLabel={`${eventTitle} 사전등록 기간 ${eventBeginDate} ~ ${eventEndDate} ${
                                eventPlace ? `행사 장소 : ${eventPlace}` : ""
                              }`}
                            >
                              {scienceCalendar.place}
                            </Text>
                            {eventPlace && eventPlace}
                          </Text>
                        </Box>
                      </Link>
                    </Slider.Slide>
                  )
                )}
              </Slider>
            </Box>
          )}

          {isMobile && (
            <Slider
              {...slideOption}
              width={isMobile ? "100%" : "calc(100% - 46px - 20px)"}
              height={"100%"}
              sliderRef={eventSliderRef}
            >
              {eventDateList.length === 0 && (
                <Slider.Slide>
                  <Box
                    display={Box.DISPLAY.FLEX}
                    padding={isMobile ? "10px 13px" : ""}
                    border={isMobile ? "1px solid" : ""}
                    borderColor={Box.COLOR.LINEGRAY}
                    borderRadius={isMobile ? "5px" : ""}
                    ariaLabel={`등록된 행사가 없습니다.`}
                    height={"100%"}
                  >
                    <Box
                      width={"31px"}
                      display={Box.DISPLAY.FLEX}
                      jc="flex-start"
                      al="center"
                      flexDirection="column"
                      margin={"0 8px 0 0"}
                      ariaLabel={`${date} 과학기술 행사`}
                    >
                      <Text
                        as={Text.VARIANT.TEXT}
                        margin={"0 0 5px"}
                        color={Text.COLOR.PRIMARY}
                        size={
                          isMobile
                            ? Text.SIZE.MOBILEHEAD
                            : Text.SIZE.CLIENTLARGE
                        }
                        weight={Text.WEIGHT.BOLD}
                        ariaLabel={`${date} 과학기술 행사`}
                      >
                        {eventDate}
                      </Text>

                      <Text
                        size={
                          isMobile
                            ? Text.SIZE.MOBILEETC
                            : Text.SIZE.CLIENTCONTENT
                        }
                        ariaLabel={`${date} 과학기술 행사`}
                      >
                        {eventDay}
                      </Text>
                    </Box>

                    <Box
                      width={"calc(100% - 39px)"}
                      display={Box.DISPLAY.FLEX}
                      flexDirection="column"
                    >
                      <Text
                        as={Text.VARIANT.TEXT}
                        lineClamp={2}
                        wordBreak="break-all"
                        size={Text.SIZE.MOBILEETC}
                        ariaLabel={`등록된 행사가 없습니다.`}
                      >
                        <Text
                          as={Text.VARIANT.SPAN}
                          margin={"0 6px 0 0"}
                          lineHeight={"1px"}
                        >
                          <VscCircleFilled size={10} />
                        </Text>
                        {scienceCalendar.blank}
                      </Text>
                    </Box>
                  </Box>
                </Slider.Slide>
              )}
              {eventDateList.map(
                ({
                  key,
                  eventTitle,
                  eventPlace,
                  eventBeginDate,
                  eventEndDate,
                  eventBeginHour,
                  eventBeginMin,
                  eventEndHour,
                  eventEndMin,
                }) => (
                  <Slider.Slide key={key}>
                    <Link
                      link={`/calendar/${key}`}
                      width={"100%"}
                      height={"111px"}
                    >
                      <Box
                        width={"100%"}
                        height={"100%"}
                        display={Box.DISPLAY.FLEX}
                        padding={"10px 13px"}
                        border={"1px solid"}
                        borderColor={Box.COLOR.LINEGRAY}
                        borderRadius={"5px"}
                        ariaLabel={`${eventTitle} 사전등록 기간 ${eventBeginDate} ~ ${eventEndDate} ${
                          eventPlace ? `행사 장소 : ${eventPlace}` : ""
                        }`}
                      >
                        <Box
                          width={"31px"}
                          display={Box.DISPLAY.FLEX}
                          jc="flex-start"
                          al="center"
                          flexDirection="column"
                          margin={"0 8px 0 0"}
                          ariaLabel={`${date} 과학기술 행사`}
                        >
                          <Text
                            as={Text.VARIANT.TEXT}
                            margin={"0 0 5px"}
                            color={Text.COLOR.PRIMARY}
                            size={Text.SIZE.MOBILEHEAD}
                            weight={Text.WEIGHT.BOLD}
                            ariaLabel={`${date} 과학기술 행사`}
                          >
                            {eventDate}
                          </Text>

                          <Text
                            size={Text.SIZE.MOBILEETC}
                            ariaLabel={`${date} 과학기술 행사`}
                          >
                            {eventDay}
                          </Text>
                        </Box>

                        <Box
                          width={"calc(100% - 39px)"}
                          display={Box.DISPLAY.FLEX}
                          flexDirection="column"
                        >
                          <Box display={Box.DISPLAY.FLEX}>
                            <Text
                              as={Text.VARIANT.SPAN}
                              margin={"0 6px 0 0"}
                              lineHeight={"1px"}
                            >
                              <VscCircleFilled size={10} />
                            </Text>
                            <Text
                              as={Text.VARIANT.SPAN}
                              lineClamp={2}
                              wordBreak="break-all"
                              size={Text.SIZE.MOBILECONTENT}
                              weight={Text.WEIGHT.BOLD}
                              ariaLabel={`${eventTitle} 사전등록 기간 ${eventBeginDate} ~ ${eventEndDate} ${
                                eventPlace ? `행사 장소 : ${eventPlace}` : ""
                              }`}
                              lineHeight={"14px"}
                            >
                              {eventTitle}
                            </Text>
                          </Box>

                          <Box
                            display={Box.DISPLAY.FLEX}
                            al="center"
                            margin={"10px 0 8px 16px"}
                            ariaLabel={`${eventTitle} 사전등록 기간 ${eventBeginDate} ~ ${eventEndDate} ${
                              eventPlace ? `행사 장소 : ${eventPlace}` : ""
                            }`}
                          >
                            <Text
                              width={"67px"}
                              height={"17px"}
                              as={Text.VARIANT.SPAN}
                              padding={"2px 10px"}
                              margin={"0 8px 0 0"}
                              bgColor={Text.COLOR.SECONDARY}
                              size={Text.SIZE.MOBILECONTENT}
                              color={Text.COLOR.WHITE}
                              weight={Text.WEIGHT.BOLD}
                              textAlign={"center"}
                              borderRadius={"60px"}
                              isSort
                            >
                              {scienceCalendar.period}
                            </Text>
                            <Text
                              width={"calc(100% - 75px)"}
                              as={Text.VARIANT.SPAN}
                              lineClamp={2}
                              wordBreak="break-all"
                              size={Text.SIZE.MOBILECONTENT}
                              color={Text.COLOR.TEXTGRAY}
                              isSort
                            >
                              {changeDate(eventBeginDate)} ~{" "}
                              {changeDate(eventEndDate)}{" "}
                              {createPeriod(
                                eventBeginHour,
                                eventBeginMin,
                                eventEndHour,
                                eventEndMin
                              )}
                            </Text>
                          </Box>

                          <Box
                            margin={"0 0 0 16px"}
                            display={Box.DISPLAY.FLEX}
                            al="center"
                            ariaLabel={`${eventTitle} 사전등록 기간 ${eventBeginDate} ~ ${eventEndDate} ${
                              eventPlace ? `행사 장소 : ${eventPlace}` : ""
                            }`}
                          >
                            <Text
                              width={"67px"}
                              height={"17px"}
                              as={Text.VARIANT.SPAN}
                              padding={"2px 10px"}
                              margin={"0 8px 0 0"}
                              bgColor={Text.COLOR.SECONDARY}
                              size={Text.SIZE.MOBILECONTENT}
                              color={Text.COLOR.WHITE}
                              weight={Text.WEIGHT.BOLD}
                              borderRadius={"60px"}
                              textAlign={"center"}
                              isSort
                            >
                              {scienceCalendar.place}
                            </Text>
                            <Text
                              width={"calc(100% - 75px)"}
                              as={Text.VARIANT.SPAN}
                              lineClamp={2}
                              wordBreak="break-all"
                              size={Text.SIZE.MOBILECONTENT}
                              color={Text.COLOR.TEXTGRAY}
                              isSort
                            >
                              {eventPlace && eventPlace}
                            </Text>
                          </Box>
                        </Box>
                      </Box>
                    </Link>
                  </Slider.Slide>
                )
              )}
            </Slider>
          )}
          {!isMobile && (
            <Box
              width={"24px"}
              ariaLabel={"과학기술 행사 슬라이더 네비게이션 영역"}
            >
              <Button
                height={"51px"}
                padding={"17px 7px"}
                margin={"0 0 3px"}
                color={Button.COLOR.DARKGRAY}
                bgColor={Button.COLOR.WHITE}
                themeStyle={Button.THEME.NONE}
                onClick={prevSlide(eventSliderRef)}
                ariaLabel={"이전 행사 슬라이더 보기"}
              >
                <BsChevronUp />
              </Button>
              <Button
                height={"51px"}
                padding={"17px 7px"}
                color={Button.COLOR.DARKGRAY}
                bgColor={Button.COLOR.WHITE}
                themeStyle={Button.THEME.NONE}
                onClick={nextSlide(eventSliderRef)}
                ariaLabel={"다음 행사 슬라이더 보기"}
              >
                <BsChevronDown />
              </Button>
            </Box>
          )}
        </Box>
        {isMobile && eventDateList.length !== 0 && (
          <Box
            display={Box.DISPLAY.FLEX}
            gap="5.5px"
            width={"100%"}
            margin={"6px 0 0"}
          >
            <Button
              width={"50%"}
              height={"24px"}
              border="1px solid"
              borderColor={Button.COLOR.LINEGRAY}
              borderRadius={"5px"}
              themeStyle={Button.THEME.NONE}
              bgColor={
                eventDateList.length === 0
                  ? Button.COLOR.GRAY
                  : Button.COLOR.WHITE
              }
              color={Button.COLOR.TEXTGRAY}
              onClick={prevSlide(eventSliderRef)}
              ariaLabel={"이전 행사 기간 슬라이더 보기"}
            >
              <BsChevronUp />
            </Button>
            <Button
              width={"50%"}
              height={"24px"}
              border="1px solid"
              borderColor={Button.COLOR.LINEGRAY}
              borderRadius={"5px"}
              themeStyle={Button.THEME.NONE}
              bgColor={
                eventDateList.length === 0
                  ? Button.COLOR.GRAY
                  : Button.COLOR.WHITE
              }
              color={Button.COLOR.TEXTGRAY}
              onClick={nextSlide(eventSliderRef)}
              ariaLabel={"다음 행사 기간 슬라이더 보기"}
            >
              <BsChevronDown />
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ScienceCalendarDate;
