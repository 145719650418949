import Box from "components/atoms/box";
import useCategory from "hooks/utils/useCategory";
import React from "react";
import NewsList from "../newsList";
import List from "components/molecules/list";
import CategorySelect from "components/molecules/categorySelect";
import {
  usePreFetchRankNews,
  useRankMonthNews,
  useRankWeekNews,
} from "hooks/api/news";

const dailyNews = [useRankWeekNews, useRankMonthNews];

const NewsCategory = (props) => {
  const { newsInfoList, type = "news", category } = props;

  const [newsCategory, setNewsCategory, newsListApi] = useCategory(dailyNews);

  usePreFetchRankNews(type);

  return (
    <Box
      display={Box.DISPLAY.FLEX}
      flexDirection="column"
      width={"1260px"}
      padding={"0 40px 32px"}
      margin={"80px 0 40px"}
      bgColor={Box.COLOR.LIGHTGRAY}
      borderRadius="10px"
    >
      <CategorySelect
        margin={"0 0 60px"}
        list={newsInfoList}
        current={newsCategory}
        onClick={setNewsCategory}
      />

      <NewsList title={""}>
        <List api={newsListApi} params={type} isBorderTop>
          {(list) =>
            list && (
              <NewsList.LAYOUT.ThumbnailNews
                list={list.slice(0, 4)}
                category={category}
              />
            )
          }
        </List>
      </NewsList>
    </Box>
  );
};

export default NewsCategory;
