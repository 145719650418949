import {
  createMember,
  deleteMember,
  getMemberDetailList,
  getMemberList,
  getMemberLoginLog,
  getTerminationMemberList,
  updateMemberInfo,
  updateMemberPassword,
} from "api/admin/member";
import useHandleAlert from "hooks/utils/useHandleAlert";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { common } from "text/admin/common";

const {
  success: { create, update, remove },
  temporaryPassword: { success },
} = common.alert;

// 회원관리 목록
export const useMemberList = ({
  page,
  itemsPerPage,
  searchCondition,
  searchValue,
  receptionStatus,
  loginStatus,
}) => {
  const { data, isLoading, isSuccess, isError } = useQuery(
    ["memberList", page, itemsPerPage, searchCondition, searchValue, receptionStatus, loginStatus],
    () =>
      getMemberList({
        page,
        itemsPerPage,
        searchCondition,
        searchValue,
        receptionStatus,
        loginStatus,
      })
  );

  const list = data?.data.list;
  const totalCnt = data?.data.totalCnt;
  const totalPageIndex = data?.data.totalPageIndex;

  return {
    list,
    totalCnt,
    totalPageIndex,
    isLoading,
    isSuccess,
    isError,
  };
};

// 회원관리 상세 목록
export const useMemberDetailList = ({ key }) => {
  const { data, isLoading, isSuccess, isError } = useQuery({
    queryKey: ["memberDetailList", key],
    queryFn: () => getMemberDetailList({ key }),
    enabled: !!key,
  });

  const list = data?.data.adminMemberDetail;

  return {
    list,
    isLoading,
    isSuccess,
    isError,
  };
};

// 회원 등록
export const useCreateMember = () => {
  const { handleGoBack } = useHandleAlert();
  const queryClient = useQueryClient();
  const createMemberMutation = useMutation(createMember, {
    onSuccess: () => {
      alert(create);
      queryClient.invalidateQueries("memberList");
      handleGoBack();
    },
    onError: (error) => {
      alert(error.response.data.message);
    },
  });

  const mutate = ({ data }) => {
    createMemberMutation.mutate({ data });
  };

  return mutate;
};

// 회원 수정
export const useUpdateMemberInfo = () => {
  const { handleGoBack } = useHandleAlert();
  const queryClient = useQueryClient();
  const updateMemberInfoMutation = useMutation(updateMemberInfo, {
    onSuccess: () => {
      alert(update);
      queryClient.invalidateQueries("memberList");
      handleGoBack();
    },
  });

  const mutate = ({ key, data }) => {
    updateMemberInfoMutation.mutate({ key, data });
  };

  return mutate;
};

// 회원 임시 비밀번호 발급
export const useUpdateMemberPassword = (setIsCreatedPw) => {
  const queryClient = useQueryClient();
  const updateMemberPasswordMutation = useMutation(updateMemberPassword, {
    onSuccess: () => {
      alert(success);
      queryClient.invalidateQueries("memberList");
      setIsCreatedPw(true);
    },
  });

  const mutate = ({ key }) => {
    updateMemberPasswordMutation.mutate({ key });
  };

  return mutate;
};

// 회원 삭제
export const useDeleteMember = (reset) => {
  const queryClient = useQueryClient();
  const deleteMemberMutation = useMutation(deleteMember, {
    onSuccess: () => {
      alert(remove);
      queryClient.invalidateQueries("memberList");
      reset();
    },
  });

  const mutate = ({ data }) => {
    deleteMemberMutation.mutate({ data });
  };

  return mutate;
};

// 회원 로그인 현황
export const useMemberLoginLog = ({
  page,
  itemsPerPage,
  searchValue,
  searchCondition,
  startDate,
  endDate,
}) => {
  const { data, isLoading, isSuccess, isError } = useQuery(
    ["memberLoginLog", page, itemsPerPage, searchValue, searchCondition, startDate, endDate],
    () =>
      getMemberLoginLog({
        page,
        itemsPerPage,
        searchValue,
        searchCondition,
        startDate,
        endDate,
      })
  );

  const list = data?.data.list;
  const totalCnt = data?.data.totalCnt;
  const totalPageIndex = data?.data.totalPageIndex;

  return {
    list,
    totalCnt,
    totalPageIndex,
    isLoading,
    isSuccess,
    isError,
  };
};

// 탈퇴회원 목록
export const useTerminationMemberList = ({
  page,
  itemsPerPage,
  searchCondition,
  searchValue,
  receptionStatus,
}) => {
  const { data, isLoading, isSuccess, isError } = useQuery(
    ["terminationMemberList", page, itemsPerPage, searchCondition, searchValue, receptionStatus],
    () =>
      getTerminationMemberList({
        page,
        itemsPerPage,
        searchCondition,
        searchValue,
        receptionStatus,
      })
  );

  const list = data?.data.list;
  const totalCnt = data?.data.totalCnt;
  const totalPageIndex = data?.data.totalPageIndex;

  return {
    list,
    totalCnt,
    totalPageIndex,
    isLoading,
    isSuccess,
    isError,
  };
};
