import Box from "components/atoms/box";
import {
  CopyRight,
  Email,
  Privacy,
  Using,
} from "components/organisms/serviceContent";
import useUrl from "hooks/utils/useUrl";
import React from "react";

const ServiceForm = {
  privacy: Privacy,
  termsOfUse: Using,
  email: Email,
  copyright: CopyRight,
};

const MobileService = () => {
  const {
    query: { category = "privacy" },
  } = useUrl();

  const ServiceContent = ServiceForm[category];

  return (
    <Box display={Box.DISPLAY.FLEX} jc="center">
      <Box
        width={"calc(100% - 24px)"}
        padding={"20px 24px"}
        margin={"165px 0 20px"}
        borderRadius={"10px"}
        bgColor={Box.COLOR.LIGHTGRAY}
      >
        <ServiceContent isMobile />
      </Box>
    </Box>
  );
};

export default MobileService;
