const convertDayWithTime = (date) => {
  if (!date) return null;

  const year = date.substr(0, 4);
  const month = date.substr(4, 2);
  const day = date.substr(6, 2);
  const hour = date.substr(8, 2);
  const minute = date.substr(10, 2);

  const formattedTime = `${year}-${month}-${day} ${hour}:${minute}`;

  return formattedTime;
};

export default convertDayWithTime;
