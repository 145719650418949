import Box from "components/atoms/box";
import Button from "components/atoms/button";
import Input from "components/atoms/input";
import Text from "components/atoms/text";
import CheckBox from "components/molecules/checkbox";
import React from "react";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { BsChevronDown } from "@react-icons/all-files/bs/BsChevronDown";
import { BsChevronUp } from "@react-icons/all-files/bs/BsChevronUp";
import { PrivacyRule, UsingRule } from "../serviceContent";
import useToggle from "hooks/utils/useToggle";
import useMobileStore from "store/useMobileStore";
import signUpText from "text/signup";

const { pending } = signUpText;

const SignupAgreement = ({ isPrivacyAble }) => {
  const { register, setValue } = useFormContext();
  const [checkAll, setCheckAll] = useState(false);

  const { isMobile } = useMobileStore();

  const [isPrivacy, setIsPrivacy] = useToggle(false);
  const [isUsing, setIsUsing] = useToggle(false);

  const handleCheckAll = (e) => {
    setCheckAll(e.target.checked);

    setValue("isUsing", e.target.checked);
    setValue("isPrivacy", e.target.checked);
  };

  return (
    <>
      {!isPrivacyAble && (
        <Box
          as={"label"}
          display={Box.DISPLAY.FLEX}
          al={"center"}
          padding={"12px 0"}
          borderBottom={"1px solid"}
          borderColor={Box.COLOR.LINEGRAY}
        >
          <CheckBox
            size={isMobile ? "10px" : "16px"}
            checked={checkAll}
            onChange={handleCheckAll}
            borderColor={Input.COLOR.SECONDARY}
          />
          <Text
            as={Text.VARIANT.SPAN}
            color={Text.COLOR.TEXTGRAY}
            margin={"0 0 0 6px"}
            size={isMobile ? Text.SIZE.MOBILEETC : Text.SIZE.CLIENTCONTENT}
          >
            {pending.agreement.all}
          </Text>
        </Box>
      )}

      {!isPrivacyAble && (
        <Box
          position={"relative"}
          display={Box.DISPLAY.FLEX}
          al={"center"}
          padding={"12px 0"}
          borderBottom={"1px solid"}
          borderColor={Text.COLOR.LINEGRAY}
        >
          <Box as={"label"} display={Box.DISPLAY.FLEX} width={"100%"}>
            <CheckBox
              {...register("isUsing")}
              size={isMobile ? "10px" : "16px"}
              borderColor={Input.COLOR.SECONDARY}
            />
            <Text
              as={Text.VARIANT.SPAN}
              color={Text.COLOR.TEXTGRAY}
              margin={"0 0 0 6px"}
              size={isMobile ? Text.SIZE.MOBILEETC : Text.SIZE.CLIENTCONTENT}
            >
              {pending.agreement.termsOfUse}
            </Text>
          </Box>

          <Box
            position="absolute"
            top={"0"}
            right={"0"}
            width={"13px"}
            height={"100%"}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Button
              themeStyle={Button.THEME.NONE}
              color={Button.COLOR.TEXTGRAY}
              width={"100%"}
              height={"100%"}
              onClick={setIsUsing}
            >
              {isUsing ? <BsChevronUp /> : <BsChevronDown />}
            </Button>
          </Box>
        </Box>
      )}
      {isUsing && (
        <Box
          height={isMobile ? "212px" : "480px"}
          borderBottom={"1px solid"}
          border={isMobile ? "1px solid" : "none"}
          borderColor={Text.COLOR.LINEGRAY}
          borderRadius={isMobile ? "5px" : "0"}
          margin={isMobile ? "18px 0" : "0"}
          padding={isMobile ? "14px" : "40px 22px"}
          bgColor={Box.COLOR.WHITE}
          overflowY={"auto"}
        >
          <UsingRule isMobile={isMobile} />
        </Box>
      )}

      <Box
        position={"relative"}
        display={Box.DISPLAY.FLEX}
        al={"center"}
        padding={"12px 0"}
        borderBottom={"1px solid"}
        borderColor={Text.COLOR.LINEGRAY}
      >
        <Box as={"label"} display={Box.DISPLAY.FLEX} width={"100%"}>
          <CheckBox
            size={isMobile ? "10px" : "16px"}
            {...register("isPrivacy")}
            borderColor={Input.COLOR.SECONDARY}
          />
          <Text
            as={Text.VARIANT.SPAN}
            color={Text.COLOR.TEXTGRAY}
            margin={"0 0 0 6px"}
            size={isMobile ? Text.SIZE.MOBILEETC : Text.SIZE.CLIENTCONTENT}
          >
            {pending.agreement.privacy}
          </Text>
        </Box>
        <Box
          position="absolute"
          top={"0"}
          right={"0"}
          width={"13px"}
          height={"100%"}
        >
          <Button
            themeStyle={Button.THEME.NONE}
            color={Button.COLOR.TEXTGRAY}
            width={"100%"}
            height={"100%"}
            onClick={setIsPrivacy}
          >
            {isPrivacy ? <BsChevronUp /> : <BsChevronDown />}
          </Button>
        </Box>
      </Box>
      {isPrivacy && (
        <Box
          height={isMobile ? "212px" : "480px"}
          borderBottom={"1px solid"}
          border={isMobile ? "1px solid" : "none"}
          borderColor={Text.COLOR.LINEGRAY}
          borderRadius={isMobile ? "5px" : "0"}
          margin={isMobile ? "18px 0" : "0"}
          padding={isMobile ? "14px" : "40px 22px"}
          bgColor={Box.COLOR.WHITE}
          overflowY={"auto"}
        >
          <PrivacyRule isMobile={isMobile} />
        </Box>
      )}
    </>
  );
};

export default SignupAgreement;
