import { adminDefaultUrl } from "api/admin/url";
import Box from "components/atoms/box";
import Text from "components/atoms/text";
import ArticleTodayCountTable from "components/molecules/articleTodayCountTable";
import dayjs from "dayjs";
import { useDashboardMembership } from "hooks/api/admin/dashboard";
import React from "react";
import { dashboard } from "text/admin/dashboard";

const DashboardMemberCount = () => {
  const today = dayjs();
  const formattedToday = today.format("MM월DD일 dddd");

  const { title, subTitle } = dashboard.memberCount;

  const { list } = useDashboardMembership();

  const countList = [
    { title: subTitle[0], count: list?.memberCnt, href: `${adminDefaultUrl}/member/membership` },
    {
      title: subTitle[1],
      count: list?.terminationCnt,
      href: `${adminDefaultUrl}/member/termination`,
    },
    {
      title: subTitle[2],
      count: list?.totalMemberCnt,
      href: `${adminDefaultUrl}/member/membership`,
    },
  ];

  return (
    <Box maxWidth="1024px" display={Box.DISPLAY.FLEX} flexDirection="column" gap="10px">
      <Box display={Box.DISPLAY.FLEX} al="center" jc="space-between">
        <Text as={Text.VARIANT.SUBTITLE} weight={Text.WEIGHT.BOLD} ariaLabel="금일 기사 등록 건수">
          {title}
        </Text>
        <Text
          as={Text.VARIANT.TEXT}
          size={Text.SIZE.CLIENTCONTENTDETAIL}
          color={Text.COLOR.TEXTGRAY}
          ariaLabel={`오늘 날짜: ${formattedToday}`}
        >
          {formattedToday}
        </Text>
      </Box>
      <ArticleTodayCountTable list={countList} />
    </Box>
  );
};

export default DashboardMemberCount;
