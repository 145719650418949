import Box from "components/atoms/box";
import Button from "components/atoms/button";
import Text from "components/atoms/text";
import React from "react";
import { FaTimes } from "@react-icons/all-files/fa/FaTimes";

const TableDeleteBar = (props) => {
  const {
    title,
    handleDelete,
    handleSave,
    handleMove,
    handleExcelDownload,
    handleReject,
    hasRadius,
  } = props;

  return (
    <Box
      display={Box.DISPLAY.FLEX}
      al="center"
      jc="space-between"
      padding="9px 10px"
      borderTop="1px solid"
      borderLeft="1px solid"
      borderRight="1px solid"
      borderColor={Box.COLOR.ADMINLINEGRAY}
      borderRadius={hasRadius ? "3px 3px 0 0" : "none"}
      bgColor={Box.COLOR.WHITE}
    >
      <Box display={Box.DISPLAY.FLEX} al="center" gap="7px">
        <Button
          themeStyle="adminCommon"
          padding="6px 10px"
          sort="between"
          onClick={handleDelete}
        >
          <Box
            color={Box.COLOR.RED}
            display={Box.DISPLAY.FLEX}
            al="center"
            jc="center"
          >
            <FaTimes />
          </Box>
          <Text as={Text.VARIANT.Text} size={Text.SIZE.ADMINCONTENTDETAIL}>
            삭제
          </Text>
        </Button>
        {handleMove && (
          <Button
            themeStyle="adminCommon"
            padding="6px 10px"
            sort="between"
            onClick={handleMove}
          >
            <Text as={Text.VARIANT.Text} size={Text.SIZE.ADMINCONTENTDETAIL}>
              이동하기
            </Text>
          </Button>
        )}
        {handleReject && (
          <Button
            themeStyle="adminCommon"
            padding="6px 10px"
            sort="between"
            onClick={handleReject}
          >
            <Text as={Text.VARIANT.Text} size={Text.SIZE.ADMINCONTENTDETAIL}>
              거부철회
            </Text>
          </Button>
        )}
      </Box>
      <Box display={Box.DISPLAY.FLEX} al="center" gap="7px">
        {handleSave && (
          <Button
            themeStyle="adminSave"
            fontWeight={Button.FONT_WEIGHT.NORMAL}
            padding="6px 10px"
            onClick={handleSave}
          >
            <Text as={Text.VARIANT.Text} size={Text.SIZE.ADMINCONTENTDETAIL}>
              {title}
            </Text>
          </Button>
        )}
        {handleExcelDownload && (
          <Button
            themeStyle="adminSave"
            fontWeight={Button.FONT_WEIGHT.NORMAL}
            padding="6px 10px"
            onClick={handleExcelDownload}
          >
            <Text as={Text.VARIANT.Text} size={Text.SIZE.ADMINCONTENTDETAIL}>
              엑셀 다운로드
            </Text>
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default TableDeleteBar;
