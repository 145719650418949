import { SERVER_URL } from "api/config";
import Box from "components/atoms/box";
import Button from "components/atoms/button";
import ImageComponent from "components/atoms/image";
import Input from "components/atoms/input";
import Text from "components/atoms/text";
import React, { Fragment, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useFormContext } from "react-hook-form";

const ImageUploader = (props) => {
  const {
    registerName,
    errorMessage,
    required,
    comments,
    serverImgPath,
    defaultPath,
    setPopupPreviewPath,
  } = props;

  const defaultPreview = defaultPath
    ? `${SERVER_URL}/upload/${serverImgPath}/${defaultPath}`
    : null;

  const {
    register,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();

  const [base64Image, setBase64Image] = useState("");
  const [files, setFiles] = useState([]);
  const [imageHeight, setImageHeight] = useState(0);

  const hasError = errors[registerName];

  const valueFromForm = watch(registerName);

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpeg"],
    },
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0];
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
      setValue(registerName, file, { shouldValidate: true });
    },
  });

  const handleImageUpload = (e) => {
    e.preventDefault();
    open();
  };

  useEffect(() => {
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, []);

  useEffect(() => {
    if (files.length === 0) {
      setPopupPreviewPath && setPopupPreviewPath(defaultPreview);
    } else {
      setValue(registerName, files[0], { shouldValidate: true });
      setPopupPreviewPath && setPopupPreviewPath(files[0].preview);
    }
  }, [files]);

  const getImageDimensions = (file) => {
    if (!file || !(file instanceof File)) return;
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new window.Image();

        img.onload = () => {
          const { naturalHeight: height } = img;
          resolve({ height });
        };
        img.onerror = (error) => {
          reject(error);
        };
        img.src = event.target.result;
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  };

  const handleImageChange = (file) => {
    if (!file || !(file instanceof File)) return;
    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64String = reader.result.split(",")[1];
        setBase64Image(base64String);
      };

      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    if (valueFromForm) {
      handleImageChange(valueFromForm);
      getImageDimensions(valueFromForm) &&
        getImageDimensions(valueFromForm).then((dimensions) => setImageHeight(dimensions.height));
    }
  }, [valueFromForm]);

  useEffect(() => {
    if (defaultPreview) {
      const img = new Image();
      img.src = defaultPreview;

      img.onload = () => {
        setImageHeight(img.height);
      };
    }
  }, [defaultPreview]);

  return (
    <Box>
      <Box
        {...getRootProps({ className: "dropzone" })}
        width="200px"
        height="200px"
        border="1px solid"
        borderColor={Box.COLOR.ADMINLINEGRAY}
        display={Box.DISPLAY.FLEX}
        jc="center"
        al="center"
        cursor="pointer"
        borderRadius={Box.RADIUS.SMALL}
      >
        {!base64Image && (
          <>
            <Input
              {...register(registerName, { required })}
              {...getInputProps()}
              name={registerName}
              display="none"
            />
            <ImageComponent
              src={defaultPreview ?? "/assets/admin/preview.png"}
              width={defaultPreview ? "100%" : "auto"}
              height={imageHeight > 200 ? "200px" : "auto"}
            />
          </>
        )}
        {base64Image && (
          <Box className="preview">
            <Box maxWidth="200px">
              <Box>
                <ImageComponent
                  width="200px"
                  height={imageHeight > 200 ? "200px" : "auto"}
                  src={`data:image/png;base64,${base64Image}`}
                />
              </Box>
            </Box>
          </Box>
        )}
      </Box>
      <Button
        themeStyle="adminCommon"
        onClick={handleImageUpload}
        padding="5px 34px"
        color={Button.COLOR.SECONDARY}
        borderColor={Button.COLOR.SECONDARY}
        margin="12px 0 10px 0"
      >
        이미지 업로드
      </Button>
      <Box>
        {comments.map((comment, idx) => (
          <Fragment key={idx}>
            <Text as={Text.VARIANT.TEXT} size={Text.SIZE.ADMINCONTENT}>
              {comment}
            </Text>
          </Fragment>
        ))}
      </Box>
      {hasError && (
        <Text
          as={Text.VARIANT.TEXT}
          color={Text.COLOR.RED}
          whiteSpace="pre-line"
          size={Text.SIZE.CLIENTCONTENTDETAIL}
          padding="0 0 0 8px"
          ariaLabel={hasError.message || errorMessage}
          lineHeight="1.2"
        >
          {hasError.message || errorMessage}
        </Text>
      )}
    </Box>
  );
};

export default ImageUploader;
