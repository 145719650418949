import Box from "components/atoms/box";
import MobileBoard from "components/organisms/mobileBoard";
import MobileNewsCategory from "components/organisms/mobileNewsCategory";
import useUrl from "hooks/utils/useUrl";
import React from "react";

const MobileScience = () => {
  const {
    query: { category = "all" },
  } = useUrl();

  const boardType = "science";

  const isAllPage = category === "all";

  return (
    <>
      <Box margin={"145px 0 0 0"} padding={"20px 12px 10px"}>
        {isAllPage && <MobileNewsCategory type={"science"} />}
      </Box>

      <MobileBoard isAllPage={isAllPage}>
        <MobileBoard.News category={category} boardType={boardType} />
      </MobileBoard>
    </>
  );
};

export default MobileScience;
