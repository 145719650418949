import Box from "components/atoms/box";
import Button from "components/atoms/button";
import Image from "components/atoms/image";
import Link from "components/atoms/link";
import Text from "components/atoms/text";
import SearchInput from "components/molecules/searchInput";
import useToggle from "hooks/utils/useToggle";
import useUrl from "hooks/utils/useUrl";
import React from "react";
import { HiMenuAlt1 } from "@react-icons/all-files/hi/HiMenuAlt1";
import { IoMdClose } from "@react-icons/all-files/io/IoMdClose";
import SnsList from "../snsList";
import MobileMenu from "../mobileMenu";
import useMenu from "hooks/utils/useMenu";
import { useEffect } from "react";
import { useState } from "react";
import Slider from "components/atoms/slider";
import useScrollDisable from "hooks/utils/useScrollDisable";
import useUserStore from "store/useUserStore";
import { useLogoutMutation, useTokenMutation } from "hooks/api/auth";

const firstCategory = {
  daily: "daily",
  news: "all",
  people: "all",
  science: "all",
  newsletter: "list",
  calendar: "science",
  help: "sitemap",
  service: "privacy",
};

const MobileHeader = () => {
  const [isMenu, toggleMenu, setToggle] = useToggle(true);
  const {
    path,
    query: { category = "", searchKeyword, search, hash },
    pathname,
    changeLink,
  } = useUrl();

  const [isScroll, setIsScroll] = useState(false);
  const [prevScrollY, setPrevScrollY] = useState(window.scrollY);
  const { user, setUser } = useUserStore();

  const handleSuccessToken = (list) => {
    setUser(list);
  };

  const tokenMutation = useTokenMutation(handleSuccessToken);

  const { mainMenu, subMenu } = useMenu({
    feature: [
      {
        codeName: "",
        code: "list",
        root: "feature",
        rootName: "특집",
      },
    ],
  });

  const handleSuccessLogout = () => {
    setUser(null);
  };

  const handleFailLogout = () => {
    alert("로그아웃에 실패하였습니다.");
  };

  const logoutMutation = useLogoutMutation(
    handleSuccessLogout,
    handleFailLogout
  );

  // useScrollDisable(isMenu);

  const subData = subMenu[path[0]];
  let subMenuSlider = null;

  if (subData) {
    subMenuSlider = [...subData];

    subMenuSlider.unshift({
      code: "all",
      codeName: "",
      sortSeq: "-1",
      root: subData[0].root,
      rootName: subData[0].rootName,
    });
    subMenuSlider.push({
      code: "all",
      codeName: "",
      sortSeq: "-1",
      root: subData[0].root,
      rootName: subData[0].rootName,
    });
  }

  const activeIndex =
    subMenuSlider && subMenuSlider.findIndex((menu) => menu.code === category);

  const handleSearch = (keyword) => {
    changeLink(`/search?searchKeyword=${keyword}`);
  };

  const handleToggleMenu = () => {
    toggleMenu();
    setIsScroll(false);
  };

  const handleScroll = () => {
    const currentScrollY = window.scrollY;
    const min = subData ? 55 : 0;

    // 스크롤이 내려갈 때
    if (currentScrollY > min && currentScrollY > prevScrollY) {
      setIsScroll(true);
    }
    // 스크롤이 올라갈 때
    else {
      setIsScroll(false);
    }

    setPrevScrollY(currentScrollY);
  };

  useEffect(() => {
    document.getElementById("root").scrollTop = 0;
  }, [pathname, category]);

  useEffect(() => {
    setToggle(false);
  }, [pathname, category, searchKeyword]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollY, subData]);

  useEffect(() => {
    if (!user) {
      tokenMutation();
    }
  }, [user, pathname, search, hash]);

  useEffect(() => {
    if (isMenu) document.body.style.overflow = "hidden";

    return () => (document.body.style.overflow = "auto");
  }, [isMenu]);

  useEffect(() => {
    const setViewportHeight = () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };

    setViewportHeight();

    window.addEventListener("resize", setViewportHeight);

    return () => {
      window.removeEventListener("resize", setViewportHeight);
    };
  }, []);

  return (
    <>
      <Box
        display={Box.DISPLAY.FLEX}
        jc={"center"}
        al={"center"}
        position={"fixed"}
        zIndex={Box.LEVEL.ROOT}
        top={"0"}
        left={"0"}
        width={"100%"}
        height={"55px"}
        bgColor={Box.COLOR.WHITE}
        padding={"0 0 0 24px"}
        opacity={isScroll && !isMenu ? "0.3" : "1"}
      >
        <Box position={"absolute"} left={"24px"} top={"14px"}>
          <Button themeStyle={Button.THEME.NONE} onClick={handleToggleMenu}>
            <HiMenuAlt1 size={"24px"} />
          </Button>
        </Box>
        <Link link={"/"} height={"20px"}>
          <Image src={"/assets/common/logo.png"} width={"143px"} alt={"logo"} />
        </Link>

        {isMenu && (
          <Box
            width={"100%"}
            // height={"calc(100 * var(--vh))"}
            position={"fixed"}
            top={"0"}
            left={"0"}
            zIndex={Box.LEVEL.ROOT}
            bgColor={Box.COLOR.SHADOW}
            onClick={toggleMenu}
            themeStyle={"sideMenu"}
          >
            <Box
              position={"relative"}
              width={"80%"}
              minWidth={"324px"}
              height={"100%"}
              padding={"60px 36px 0"}
              bgColor={Box.COLOR.WHITE}
              overflowY={"auto"}
              overflowX={"hidden"}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Box position={"absolute"} top={"10px"} right={"10px"}>
                <Button themeStyle={Button.THEME.NONE} onClick={toggleMenu}>
                  <IoMdClose size={28} />
                </Button>
              </Box>

              <Text
                as={Text.VARIANT.SUBTITLE}
                weight={Text.WEIGHT.NORMAL}
                size={Text.SIZE.CLIENTCATEGORY}
              >
                {user ? `${user.userName}님 안녕하세요.` : "로그인 해주세요."}
              </Text>
              <Text
                as={Text.VARIANT.TEXT}
                size={Text.SIZE.CLIENTCONTENTDETAIL}
                margin={"16px 0 60px"}
                isSort
              >
                {user ? (
                  <Text as={Text.VARIANT.SPAN} onClick={logoutMutation}>
                    로그아웃
                  </Text>
                ) : (
                  <>
                    <Text as={Text.VARIANT.SPAN}>
                      <Link link="/login">로그인</Link>
                    </Text>
                    <Text
                      as={Text.VARIANT.SPAN}
                      height={"13px"}
                      margin={"0 12px"}
                      borderLeft={"1px solid"}
                      borderColor={Text.COLOR.LINEGRAY}
                    />
                    <Text as={Text.VARIANT.SPAN}>
                      <Link link="/signup">회원가입</Link>
                    </Text>
                  </>
                )}
              </Text>

              <SearchInput
                placeholder="검색어를 입력하세요."
                ariaLabel="뉴스 검색창"
                theme={SearchInput.THEME.HEADER}
                submit={handleSearch}
              />

              <Box
                borderTop={"1px solid"}
                borderBottom={"1px solid"}
                borderColor={Box.COLOR.LINEGRAY}
                margin={"24px 0 0"}
                padding={"40px 0 20px"}
              >
                {mainMenu.map((menu, idx) => (
                  <MobileMenu
                    {...menu}
                    subMenu={subMenu[menu.link]}
                    key={menu.link}
                  />
                ))}
              </Box>

              <Link
                link="/help?category=sitemap&type=title"
                width={"100%"}
                margin={"0 0 24px"}
              >
                <Text
                  as={Text.VARIANT.TEXT}
                  color={Text.COLOR.TEXTGRAY}
                  size={Text.SIZE.MOBILECATEGORY}
                  padding={"24px 0"}
                  borderBottom={"1px solid"}
                  borderColor={Text.COLOR.LINEGRAY}
                >
                  고객센터
                </Text>
              </Link>

              <SnsList margin={"0 10px 20px 0"} />
            </Box>
          </Box>
        )}

        {subMenuSlider && (
          <Box
            position={"absolute"}
            top={"55px"}
            left={"0"}
            width={"100%"}
            borderTop={"2px solid"}
            borderBottom={"1px solid"}
            borderColor={Box.COLOR.LINEGRAY}
            bgColor={Box.COLOR.WHITE}
            themeStyle={
              isScroll
                ? `${subMenuSlider.length > 3 ? "slider" : "single"}HeaderUp`
                : `${subMenuSlider.length > 3 ? "slider" : "single"}HeaderDown`
            }
          >
            <Text
              as={Text.VARIANT.SUBTITLE}
              size={Text.SIZE.MOBILETITLE}
              weight={Text.WEIGHT.BOLD}
              textAlign={"center"}
              margin={"20px 0"}
              ariaLabel={`카테고리 : ${subMenuSlider.title}`}
            >
              {subMenuSlider[0].rootName}
            </Text>
            {subMenuSlider.length > 3 && (
              <Slider
                slidesPerView={"auto"}
                spaceBetween={30}
                simulateTouch={false}
                initialSlide={activeIndex}
                isAuto={false}
              >
                {subMenuSlider.map(
                  ({ code, codeName, listviewBasic, root }, idx) => {
                    const firstMenu = category === "" && firstCategory[root];

                    const isActive = firstMenu === code || category === code;

                    return (
                      <Slider.Slide key={idx}>
                        <Link
                          link={`/${root}?category=${code}&${
                            listviewBasic ? `type=${listviewBasic}` : ""
                          }`}
                        >
                          <Text
                            padding={"0 0 12px"}
                            color={
                              isActive
                                ? Button.COLOR.SECONDARY
                                : Button.COLOR.BLACK
                            }
                            size={Text.SIZE.MOBILECATEGORY}
                            weight={
                              isActive
                                ? Button.FONT_WEIGHT.BOLD
                                : Button.FONT_WEIGHT.NORMAL
                            }
                            borderBottom={isActive ? "2px solid" : ""}
                            borderColor={Button.COLOR.SECONDARY}
                          >
                            {codeName}
                          </Text>
                        </Link>
                      </Slider.Slide>
                    );
                  }
                )}
              </Slider>
            )}
          </Box>
        )}
      </Box>
    </>
  );
};

export default MobileHeader;
