import Box from "components/atoms/box";
import Button from "components/atoms/button";
import Loading from "components/atoms/loading";
import Text from "components/atoms/text";
import InputForm from "components/molecules/inputForm";
import AdminDetailForm from "components/organisms/adminDetailForm";
import AdminDetailFormContainer from "components/organisms/adminDetailFormContainer";
import ListView from "components/organisms/listView";
import { AdminContentsContainer } from "components/templates/admin/admin.style";
import {
  useCreateMember,
  useMemberDetailList,
  useUpdateMemberInfo,
  useUpdateMemberPassword,
} from "hooks/api/admin/member";
import usePendingMutate from "hooks/utils/usePendingMutate";
import useUrl from "hooks/utils/useUrl";
import React from "react";
import { useState } from "react";
import { common } from "text/admin/common";
import { member } from "text/admin/member";
import convertContactNum from "utils/convertContactNum";
import convertEmailFormatter from "utils/convertEmailFormatter";

const MemberDetail = () => {
  const {
    query: { key },
  } = useUrl();

  const [isCreatedPw, setIsCreatedPw] = useState(false);

  const { buttonList, defaultValues } = member.detail;
  const {
    temporaryPassword: { request },
  } = common.alert;

  const { list, isLoading } = useMemberDetailList({ key });
  const { isPending, handleMutate } = usePendingMutate();

  const api = key ? useUpdateMemberInfo : useCreateMember;

  const passwordMutate = useUpdateMemberPassword(setIsCreatedPw);
  const mutate = api();

  const formTitle = key ? "회원 수정" : "회원 등록";

  const handleTemporaryPassword = (e) => {
    e.preventDefault();
    const confirm = window.confirm(request);
    if (confirm) passwordMutate({ key });
  };

  const formInfo = [
    {
      title: "ID",
      minHeight: "48px",
      components: key ? (
        <Box display={Box.DISPLAY.FLEX} al="center" height="31px" ariaLabel="ID">
          <Text as={Text.VARIANT.TEXT} size={Text.SIZE.ADMINCONTENT}>
            {list?.userId}
          </Text>
        </Box>
      ) : (
        <InputForm
          name="userId"
          type="text"
          width="240px"
          height="32px"
          padding="0 12px"
          theme="secondary"
          defaultName={list?.userId}
          required={true}
          errorMessage="필수값입니다."
          ariaLabel="ID"
        />
      ),
      required: true,
    },
    {
      title: "비밀번호",
      height: "48px",
      components: key ? (
        list?.userType === "간편 로그인" ? (
          ""
        ) : (
          <Button
            themeStyle={isCreatedPw ? "disabled" : "adminCommon"}
            padding="8px 14px"
            borderColor={Button.COLOR.SECONDARY}
            ariaLabel="임시 비밀번호 발급"
            onClick={handleTemporaryPassword}
            disabled={isCreatedPw ? true : false}
          >
            <Text
              as={Text.VARIANT.TEXT}
              size={Text.SIZE.ADMINCONTENT}
              color={Text.COLOR.SECONDARY}
              cursor={isCreatedPw ? "not-allowed" : "pointer"}
            >
              임시 비밀번호 발급
            </Text>
          </Button>
        )
      ) : (
        <Box
          display={Box.DISPLAY.FLEX}
          al="center"
          height="100%"
          ariaLabel="계정 생성 시 자동으로 임시 비밀번호가 발급 됩니다."
        >
          <Text as={Text.VARIANT.TEXT} size={Text.SIZE.ADMINCONTENT} color={Text.COLOR.SECONDARY}>
            * 계정 생성 시 자동으로 임시 비밀번호가 발급 됩니다.
          </Text>
        </Box>
      ),
      required: !list ? false : true,
    },
    {
      title: "회원 분류",
      height: "48px",
      components: key ? (
        <Box display={Box.DISPLAY.FLEX} al="center" height="100%" ariaLabel="회원 분류">
          <Text as={Text.VARIANT.TEXT} size={Text.SIZE.ADMINCONTENT}>
            {list?.userType}
          </Text>
        </Box>
      ) : (
        <Box
          display={Box.DISPLAY.FLEX}
          al="center"
          height="100%"
          ariaLabel="계정 생성 시 일반 회원으로 자동 분류됩니다."
        >
          <Text as={Text.VARIANT.TEXT} size={Text.SIZE.ADMINCONTENT} color={Text.COLOR.SECONDARY}>
            * 계정 생성 시 일반 회원으로 자동 분류됩니다.
          </Text>
        </Box>
      ),
      required: false,
    },
    {
      title: "이름",
      minHeight: "48px",
      components: key ? (
        <Box display={Box.DISPLAY.FLEX} al="center" height="31px" ariaLabel={list?.name ?? "이름"}>
          <Text as={Text.VARIANT.TEXT} size={Text.SIZE.ADMINCONTENT}>
            {list?.userName}
          </Text>
        </Box>
      ) : (
        <InputForm
          name="userName"
          type="text"
          width="240px"
          height="32px"
          padding="0 12px"
          theme="secondary"
          defaultName={list?.userName}
          required="필수값입니다."
          ariaLabel="이름"
        />
      ),
      required: key ? false : true,
    },
    {
      title: "이메일",
      minHeight: "48px",
      components: key ? (
        <Box
          display={Box.DISPLAY.FLEX}
          al="center"
          height="31px"
          ariaLabel={list?.email1 && list?.email2 ? `이메일: ${list?.email1}@${list?.email2}` : ""}
        >
          <Text as={Text.VARIANT.TEXT} size={Text.SIZE.ADMINCONTENT}>
            {list?.userType === "간편 로그인" ? "" : `${list?.email1}@${list?.email2}`}
          </Text>
        </Box>
      ) : (
        <InputForm
          name="email"
          type="text"
          width="240px"
          height="32px"
          padding="0 12px"
          theme="secondary"
          defaultName={list ? `${list?.email1}@${list?.email2}` : ""}
          required="필수값입니다."
          ariaLabel={`이메일: ${list?.email1}@${list?.email2}`}
        />
      ),
      required: key ? false : true,
    },
    {
      title: "연락처",
      minHeight: "48px",
      components:
        key && list?.userType === "간편 로그인" ? (
          <Box
            display={Box.DISPLAY.FLEX}
            al="center"
            height="31px"
            ariaLabel={
              list?.hp1 && list?.hp2 && list?.hp3 ? `${list?.hp1}-${list?.hp2}-${list?.hp3}` : ""
            }
          >
            <Text as={Text.VARIANT.TEXT} size={Text.SIZE.ADMINCONTENT}>
              {list?.userType === "간편 로그인" ? "" : `${list?.hp1}-${list?.hp2}-${list?.hp3}`}
            </Text>
          </Box>
        ) : (
          <InputForm
            name="hp"
            type="text"
            width="240px"
            height="32px"
            padding="0 12px"
            theme="secondary"
            maxLength={13}
            defaultName={
              list?.hp1 && list?.hp2 && list?.hp3 ? `${list?.hp1}-${list?.hp2}-${list?.hp3}` : ""
            }
            required="필수값입니다."
            ariaLabel={`연락처: ${list?.hp1}-${list?.hp2}-${list?.hp3}`}
          />
        ),
      required: key ? false : true,
    },
    {
      title: "뉴스레터 수신",
      height: "40px",
      components:
        list?.userType === "간편 로그인" ? (
          ""
        ) : (
          <ListView
            buttonList={buttonList}
            registerName="newsYN"
            isRenderedListView={list?.newsYN ?? "Y"}
            required="필수값입니다."
          />
        ),
      required: true,
    },
  ];

  const handleSubmit = (data) => {
    const { userId, userName, email, hp, newsYN } = data;
    const { hp1, hp2, hp3 } = convertContactNum(hp);
    const { email1, email2 } = convertEmailFormatter(email);

    const submitData = key
      ? { hp1, hp2, hp3, newsYN }
      : {
          userId,
          userName,
          hp1,
          hp2,
          hp3,
          email1,
          email2,
          newsYN,
        };

    handleMutate(mutate, { key, data: submitData });
  };

  if (isLoading) return <Loading />;

  return (
    <AdminContentsContainer>
      <Box maxWidth="1024px">
        <AdminDetailFormContainer
          title={formTitle}
          defaultValues={defaultValues}
          handleSubmit={handleSubmit}
          isPending={isPending}
        >
          <AdminDetailForm formInfo={formInfo} />
        </AdminDetailFormContainer>
      </Box>
    </AdminContentsContainer>
  );
};

export default MemberDetail;
