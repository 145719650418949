import Box from "components/atoms/box";
import Text from "components/atoms/text";
import React from "react";
import convertDayWithTime from "utils/convertDayWithTime";
import HasNotResult from "../HasNotResult";
import useAllCheckbox from "hooks/utils/useAllCheckbox";
import Input from "components/atoms/input";
import Link from "components/atoms/link";
import { useState } from "react";
import { useEffect } from "react";
import TableDeleteBar from "../tableDeleteBar";
import { useDeleteRequest } from "hooks/api/admin/cs";
import { common } from "text/admin/common";
import useUrl from "hooks/utils/useUrl";
import { adminDefaultUrl } from "api/admin/url";

const RequestTable = (props) => {
  const { list, tableHeader } = props;

  const {
    selectRemoveItems,
    default: { remove },
  } = common.alert;

  const {
    query: { page = 1 },
  } = useUrl();

  const currPage = parseInt(page);

  let keyArr = [];
  const [allKeyList, setAllKeyList] = useState([]);

  useEffect(() => {
    setAllKeyList(keyArr);
  }, [list]);

  const {
    checkAll,
    checkboxes,
    resetCheckbox,
    selectedKeys,
    handleCheckAllChange,
    handleCheckboxChange,
  } = useAllCheckbox(list.length, allKeyList);

  const isNoneSelected = selectedKeys.length === 0;

  const mutate = useDeleteRequest(resetCheckbox);

  const handleDeleteSelectedNewsletter = () => {
    const data = { keyList: selectedKeys };
    if (isNoneSelected) {
      alert(selectRemoveItems);
      return;
    }

    const confirm = window.confirm(remove);
    if (confirm) mutate({ data });
  };

  return (
    <>
      <TableDeleteBar handleDelete={handleDeleteSelectedNewsletter} />
      <Box
        display={Box.DISPLAY.FLEX}
        height="47px"
        borderTop="1px solid"
        borderColor={Box.COLOR.LINEGRAY}
      >
        <Box
          width="3%"
          height="100%"
          display={Box.DISPLAY.FLEX}
          al="center"
          jc="center"
          borderLeft="1px solid"
          borderColor={Box.COLOR.ADMINLINEGRAY}
          bgColor={Box.COLOR.ADMINCONTENTSBG}
        >
          <Input
            type="checkbox"
            width="16px"
            height="16px"
            borderColor={Input.COLOR.ADMINLINEGRAY}
            onChange={handleCheckAllChange}
            checked={checkAll}
          />
        </Box>
        {tableHeader.map(({ title, width, ariaLabel }, idx) => (
          <Box
            display={Box.DISPLAY.FLEX}
            al="center"
            jc="center"
            width={width}
            padding={"8px 0"}
            key={idx}
            borderBottom={list.length === 0 ? "1px solid" : ""}
            borderRight={idx === tableHeader.length - 1 ? "1px solid" : ""}
            borderLeft="1px solid"
            borderColor={Box.COLOR.LINEGRAY}
            bgColor={Box.COLOR.ADMINCONTENTSBG}
          >
            <Text
              as={Text.VARIANT.TEXT}
              weight={Text.WEIGHT.BOLD}
              size={Text.SIZE.CLIENTCONTENTDETAIL}
              textAlign="center"
              ariaLabel={ariaLabel}
            >
              {title}
            </Text>
          </Box>
        ))}
      </Box>
      {list.length === 0 && <HasNotResult />}
      {list.length !== 0 &&
        list.map(({ key, regDate, title, writer, email, status }, idx) => {
          const pageIdx = currPage > 1 ? (currPage - 1) * 10 + (idx + 1) : idx + 1;
          keyArr.push(key);

          return (
            <Box
              key={idx}
              borderTop={idx === 0 ? "1px solid" : ""}
              borderBottom="1px solid"
              borderColor={Box.COLOR.LINEGRAY}
              display={Box.DISPLAY.FLEX}
              height="53px"
            >
              <Box
                width="3%"
                height="100%"
                display={Box.DISPLAY.FLEX}
                jc="center"
                borderLeft="1px solid"
                borderColor={Box.COLOR.ADMINLINEGRAY}
                al="center"
              >
                <Input
                  type="checkbox"
                  name={key}
                  width="16px"
                  height="16px"
                  borderColor={Input.COLOR.ADMINLINEGRAY}
                  checked={checkboxes[key] ?? false}
                  onChange={(e) => handleCheckboxChange({ e, key })}
                />
              </Box>
              <Box
                width="7%"
                display={Box.DISPLAY.FLEX}
                jc="center"
                al="center"
                borderLeft="1px solid"
                borderColor={Box.COLOR.LINEGRAY}
              >
                <Text
                  as={Text.VARIANT.TEXT}
                  size={Text.SIZE.CLIENTCONTENTDETAIL}
                  textAlign="center"
                  ariaLabel={`게시물 번호 : ${pageIdx} `}
                >
                  {pageIdx}
                </Text>
              </Box>
              <Box
                width="39%"
                display={Box.DISPLAY.FLEX}
                al="center"
                borderLeft="1px solid"
                borderColor={Box.COLOR.LINEGRAY}
                padding="0 10px"
              >
                <Link link={`${adminDefaultUrl}/customer_service/request/detail?key=${key}`}>
                  <Text
                    as={Text.VARIANT.TEXT}
                    size={Text.SIZE.CLIENTCONTENTDETAIL}
                    decoration="underline"
                    lineHeight="1.2"
                    ariaLabel={`관리자에게 목록 제목: ${title}`}
                  >
                    {title}
                  </Text>
                </Link>
              </Box>
              <Box
                width="15%"
                display={Box.DISPLAY.FLEX}
                jc="center"
                al="center"
                borderLeft="1px solid"
                borderColor={Box.COLOR.LINEGRAY}
                padding="0 10px"
              >
                <Text
                  as={Text.VARIANT.TEXT}
                  size={Text.SIZE.CLIENTCONTENTDETAIL}
                  textAlign="center"
                  ariaLabel={writer}
                >
                  {writer}
                </Text>
              </Box>
              <Box
                width="15%"
                display={Box.DISPLAY.FLEX}
                jc="center"
                al="center"
                borderLeft="1px solid"
                borderColor={Box.COLOR.LINEGRAY}
                padding="0 10px"
                wordBreak="break-all"
              >
                <Text
                  as={Text.VARIANT.TEXT}
                  size={Text.SIZE.CLIENTCONTENTDETAIL}
                  textAlign="center"
                  ariaLabel={email}
                >
                  {email}
                </Text>
              </Box>
              <Box
                width="14%"
                display={Box.DISPLAY.FLEX}
                jc="center"
                al="center"
                borderLeft="1px solid"
                borderColor={Box.COLOR.LINEGRAY}
              >
                <Text
                  as={Text.VARIANT.TEXT}
                  size={Text.SIZE.CLIENTCONTENTDETAIL}
                  textAlign="center"
                  ariaLabel={regDate}
                >
                  {convertDayWithTime(regDate)}
                </Text>
              </Box>
              <Box
                width="7%"
                display={Box.DISPLAY.FLEX}
                jc="center"
                al="center"
                borderLeft="1px solid"
                borderRight="1px solid"
                borderColor={Box.COLOR.LINEGRAY}
              >
                <Text
                  as={Text.VARIANT.TEXT}
                  size={Text.SIZE.CLIENTCONTENTDETAIL}
                  textAlign="center"
                  ariaLabel={status}
                >
                  {status === "Y" ? "확인" : "미확인"}
                </Text>
              </Box>
            </Box>
          );
        })}
    </>
  );
};

export default RequestTable;
