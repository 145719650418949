import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";
import antd from "./antd";
import swiper from "./swiper";
import picker from "./picker";

const GlobalStyle = createGlobalStyle`
    ${reset}
    ${antd}
    ${swiper}
    ${picker}



    * {
        box-sizing: border-box;
    }

    body {
        min-width: 1260px;

        font-family: "Pretendard Variable", "Pretendard";
        font-size: 16px;

        color: ${(props) => props.theme.color.black};

        > iframe {
            display: none;
        }
    }

    #root{
        position:fixed;
        width:100%;height:100%;
        overflow:auto;
        }

    button {
        font-family: "Pretendard Variable", "Pretendard";
    }

    textarea {
        font-family: "Pretendard Variable", "Pretendard";
    }


    form {
        width: 100%;
    }

    a {
        display: inline-block;
        
        color: ${(props) => props.theme.color.black};
        text-decoration: none;
    }

    input {
        border: 1px solid;
    }

    /* input:focus {
        background-color: transparent;  
        outline: none; 
    } */

    input:disabled {
        background-color: ${(props) => props.theme.color.disabledBg};
        border: 1px solid ${(props) => props.theme.color.gray} !important;
        cursor: not-allowed;
    }

    input[type="checkbox"] {
        cursor: pointer;
        margin:0;
        accent-color:#3A47B8;

        filter: none !important
    }

    input[type=checkbox]:hover{ filter: none !important }
    input[type=checkbox]:active{ filter: none !important }
    input[type=checkbox]:focus{ filter: none !important }
    input[type=checkbox]:checked{ filter: none !important }


    input[type="radio"] {
        cursor: pointer;
        margin:0;
        accent-color:#3A47B8;
    }

    input[type="radio"]:disabled {
        cursor: not-allowed;
    }

    .print{
        display: none;
    }

    // remove scrollbar (Chrome, Safari, Edge)
    .admin-sidebar::-webkit-scrollbar {
        display: none;
    }
    // remove scrollbar (Firefox)
    .admin-sidebar {
        scrollbar-width: none;
    }

    /*  */
    @media  (max-width: 1260px) {
        body {
            width: 100%;
            min-width: 100%;
        }
    }

    @media print {
        body * {
            visibility: hidden;
        }

        .print, .print * {
            visibility: visible !important;
            page-break-inside: avoid !important;
        }


        .print {
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
        }
    }
`;

export default GlobalStyle;
