import React from "react";
import NewsList from "../newsList";
import Box from "components/atoms/box";
import Image from "components/atoms/image";
import Text from "components/atoms/text";
import useUrl from "hooks/utils/useUrl";
import dayjs from "dayjs";
import useMobileStore from "store/useMobileStore";
import { useNewsletterList, usePrevNewsletterList } from "hooks/api/newsletter";
import List from "components/molecules/list";
import Blank from "components/molecules/blank";
import Link from "components/atoms/link";
import newsletterText from "text/newsletter";
import convertImage from "utils/convertImage";

const { newsletterList } = newsletterText;

const years = [];

for (let year = 2023; year >= 2003; year--) {
  years.push({ value: year });
}

const NewsletterList = (props) => {
  const { isBefore } = props;
  const {
    query: { page = 1, year = years[0].value },
    changeQuery,
  } = useUrl();
  const { isMobile } = useMobileStore();

  const getNewsletterApi = isBefore ? usePrevNewsletterList : useNewsletterList;
  const route = isBefore ? "prev" : "newsletter";

  const handleChangeSelect = (value) => {
    changeQuery({ year: value, page: 1 });
  };

  const formatDateFormDayjs = (dateString) => {
    const standardized = dateString.replace(/\s+/g, "").replace(/\./g, "-");

    return standardized.endsWith("-")
      ? standardized.slice(0, -1)
      : standardized;
  };

  return (
    <NewsList
      sideBar={
        isBefore
          ? () => (
              <NewsList.Select
                width={"122px"}
                list={years}
                value={year}
                onChange={handleChangeSelect}
              />
            )
          : false
      }
      isBorder={isBefore}
    >
      <Box
        width={"100%"}
        display={Box.DISPLAY.FLEX}
        wrap={"wrap"}
        gap={isMobile ? "20px 7px" : "0 15px"}
      >
        <List
          api={getNewsletterApi}
          params={{
            page,
            year,
            limit: isMobile ? 4 : 15,
          }}
        >
          {(list) =>
            list && (
              <>
                {list.length === 0 && <Blank />}
                {list.map(({ title, cnt, period, thumbnail, key }, idx) => (
                  <Link
                    width={`${
                      isMobile
                        ? "calc((100% - 7px) / 2)"
                        : "calc((100% - 30px) / 3)"
                    }`}
                    link={`/${route}/${key}`}
                    target={"_blank"}
                  >
                    <Box
                      width={"100%"}
                      margin={idx >= 3 && !isMobile ? "80px 0 0 " : "0"}
                    >
                      {!isBefore && (
                        <Image
                          src={convertImage(thumbnail, "thumbnail")}
                          alt={"thumbnail"}
                          width={"100%"}
                          aspectRatio={"253/163"}
                          radius={"0 20px"}
                        />
                      )}
                      <Text
                        as={Text.VARIANT.SPAN}
                        bgColor={
                          isMobile
                            ? Text.COLOR.TRANSPARENT
                            : Text.COLOR.SECONDARY
                        }
                        margin={isMobile ? "10px 0" : "32px 0 12px"}
                        padding={isMobile ? "0" : "2px 13px"}
                        borderRadius={isMobile ? "0" : "60px"}
                        color={
                          isMobile ? Text.COLOR.SECONDARY : Text.COLOR.WHITE
                        }
                        weight={
                          isMobile ? Text.WEIGHT.BOLD : Text.WEIGHT.NORMAL
                        }
                        size={
                          isMobile
                            ? Text.SIZE.MOBILECONTENT
                            : Text.SIZE.CLIENTETC
                        }
                      >
                        {cnt}
                        {newsletterList.unit}
                      </Text>
                      <Text
                        as={Text.VARIANT.TEXT}
                        size={
                          isMobile
                            ? Text.SIZE.MOBILECONTENT
                            : Text.SIZE.CLIENTNORMAL
                        }
                        weight={Text.WEIGHT.BOLD}
                        margin={"0 0 6px"}
                      >
                        {dayjs(formatDateFormDayjs(period)).format(
                          "YYYY-MM-DD"
                        )}
                      </Text>
                      <Text
                        as={Text.VARIANT.TEXT}
                        size={
                          isMobile
                            ? Text.SIZE.MOBILECONTENT
                            : Text.SIZE.CLIENTCONTENTDETAIL
                        }
                        lineHeight={"18px "}
                        height={isMobile ? "54px" : "36px"}
                        lineClamp={isMobile ? 3 : 2}
                      >
                        {title}
                      </Text>
                    </Box>
                  </Link>
                ))}
              </>
            )
          }
        </List>
      </Box>
    </NewsList>
  );
};

export default NewsletterList;
