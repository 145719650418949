import Box from "components/atoms/box";
import Board from "components/organisms/board";
import React from "react";

const Calendar = () => {
  return (
    <Box
      width="100%"
      display={Box.DISPLAY.FLEX}
      flexDirection="column"
      al="center"
    >
      <Board isAllPage={false} isCalendar>
        <Board.Calendar />
      </Board>
    </Box>
  );
};

export default Calendar;
