import {
  createPopup,
  createSlideBanner,
  deletePopup,
  deleteSlideBanner,
  getCommentOnOff,
  getSNSBannerDetail,
  getSiteBannerDetail,
  getSiteBannerList,
  getSitePopupDetail,
  getSitePopupList,
  updateBannerOrder,
  updateBannerType,
  updateCommentOnOff,
  updatePopup,
  updateSNSBanner,
  updateSlideBanner,
} from "api/admin/management_site";
import useHandleAlert from "hooks/utils/useHandleAlert";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useChangeOrderStore } from "store/useChangeStore";
import usePopupStore from "store/usePopupStore";
import { common } from "text/admin/common";

const {
  success: { create, order, update, remove },
  updateBanner: { success, fail, essential },
} = common.alert;

// 배너 관리
export const useSiteBannerList = (category) => {
  const { data, isLoading, isSuccess, isError } = useQuery(["siteBanner", category], () =>
    getSiteBannerList(category)
  );

  const list = data?.data.list;
  const form = data?.data.snsForm;
  const totalCnt = data?.data.totalCnt;
  const totalPageIndex = data?.data.totalPageIndex;

  return {
    list,
    form,
    totalCnt,
    totalPageIndex,
    isLoading,
    isSuccess,
    isError,
  };
};

// 배너 상세 (슬라이드, 중간)
export const useSiteBannerDetail = ({ category, key }) => {
  const { data, isLoading, isSuccess, isError } = useQuery({
    queryKey: ["siteBannerDetail", category, key],
    queryFn: () => getSiteBannerDetail({ category, key }),
    enabled: !!key,
  });

  const list = data?.data.bannerDetail;

  return {
    list,
    isLoading,
    isSuccess,
    isError,
  };
};

// 배너 상세 (SNS)
export const useSNSBannerDetail = ({ category, key, snsForm }) => {
  const { data, isLoading, isSuccess, isError } = useQuery({
    queryKey: ["snsBannerDetail", category, key, snsForm],
    queryFn: () => getSNSBannerDetail({ category, key, snsForm }),
    enabled: !!key,
  });

  const list = data?.data.bannerDetail;

  return {
    list,
    isLoading,
    isSuccess,
    isError,
  };
};

// 배너 수정
export const useUpdateBannerType = () => {
  const queryClient = useQueryClient();
  const updateBannerTypeMutation = useMutation(updateBannerType, {
    onSuccess: () => {
      alert(success);
      queryClient.invalidateQueries("siteBanner");
    },
    onError: () => {
      alert(fail);
    },
  });

  const mutate = ({ data }) => {
    updateBannerTypeMutation.mutate({ data });
  };

  return mutate;
};

// 배너 순서 변경
export const useUpdateBannerOrder = () => {
  const { setIsChangeOrderList } = useChangeOrderStore();
  const queryClient = useQueryClient();
  const updateBannerOrderMutation = useMutation(updateBannerOrder, {
    onSuccess: () => {
      alert(order);
      queryClient.invalidateQueries("siteBanner");
      setIsChangeOrderList(false);
    },
  });

  const mutate = ({ categoryName, data, snsForm }) => {
    updateBannerOrderMutation.mutate({ categoryName, data, snsForm });
  };

  return mutate;
};

// 슬라이드 (메인, 중간) 배너 등록
export const useCreateSlideBanner = () => {
  const { handleGoBack } = useHandleAlert();
  const queryClient = useQueryClient();
  const createSlideBannerMutation = useMutation(createSlideBanner, {
    onSuccess: () => {
      alert(create);
      queryClient.invalidateQueries("siteBanner");
      handleGoBack();
    },
  });

  const mutate = ({ categoryName, formData }) => {
    createSlideBannerMutation.mutate({ categoryName, formData });
  };

  return mutate;
};

// 슬라이드 (메인, 중간) 배너 수정
export const useUpdateSlideBanner = () => {
  const { handleGoBack } = useHandleAlert();
  const queryClient = useQueryClient();
  const updateSlideBannerMutation = useMutation(updateSlideBanner, {
    onSuccess: () => {
      alert(update);
      queryClient.invalidateQueries("siteBanner");
      handleGoBack();
    },
  });

  const mutate = ({ categoryName, key, formData }) => {
    updateSlideBannerMutation.mutate({ categoryName, key, formData });
  };

  return mutate;
};

// 슬라이드 (SNS) 배너 수정
export const useUpdateSNSBanner = () => {
  const { handleGoBack } = useHandleAlert();
  const queryClient = useQueryClient();
  const updateSNSBannerMutation = useMutation(updateSNSBanner, {
    onSuccess: () => {
      alert(update);
      queryClient.invalidateQueries("siteBanner");
      handleGoBack();
    },
  });

  const mutate = ({ categoryName, key, snsForm, formData }) => {
    updateSNSBannerMutation.mutate({ categoryName, key, snsForm, formData });
  };

  return mutate;
};

// 슬라이드 (메인, 중간) 배너 삭제
export const useDeleteSlideBanner = (reset) => {
  const queryClient = useQueryClient();
  const deleteSlideBannerMutation = useMutation(deleteSlideBanner, {
    onSuccess: () => {
      alert(remove);
      queryClient.invalidateQueries("siteBanner");
      reset();
    },
    onError: (error) => {
      const errorMessage = error?.response?.data?.message;
      if (errorMessage === "ABSOLUTELY NECESSARY") {
        alert(essential);
      }
    },
  });

  const mutate = ({ categoryName, data }) => {
    deleteSlideBannerMutation.mutate({ categoryName, data });
  };

  return mutate;
};

// 댓글 ON/OFF
export const useGetCommentOnOff = () => {
  const { data, isLoading, isSuccess, isError } = useQuery("commentOnOff", () => getCommentOnOff());

  const commentState = data?.data.commentOnOff;

  return {
    commentState,
    isLoading,
    isSuccess,
    isError,
  };
};

export const useUpdateCommentOnOff = () => {
  const queryClient = useQueryClient();
  const updateCommentOnOffMutation = useMutation(updateCommentOnOff, {
    onSuccess: () => {
      alert(update);
      queryClient.invalidateQueries("commentOnOff");
    },
  });

  const mutate = ({ data }) => {
    updateCommentOnOffMutation.mutate({ data });
  };

  return mutate;
};

// 팝업 관리
export const useSitePopupList = ({ page, itemsPerPage, searchValue }) => {
  const { data, isLoading, isSuccess, isError } = useQuery(
    ["popupList", page, itemsPerPage, searchValue],
    () => getSitePopupList(page, itemsPerPage, searchValue)
  );

  const list = data?.data.list;
  const totalCnt = data?.data.totalCnt;
  const totalPageIndex = data?.data.totalPageIndex;

  return {
    list,
    totalCnt,
    totalPageIndex,
    isLoading,
    isSuccess,
    isError,
  };
};

// 팝업 상세
export const useSitePopupDetailList = ({ key }) => {
  const { data, isLoading, isSuccess, isError } = useQuery({
    queryKey: ["sitePopupDetail", key],
    queryFn: () => getSitePopupDetail({ key }),
    enabled: !!key,
  });

  const list = data?.data.adminPopupDetail;

  return {
    list,
    isLoading,
    isSuccess,
    isError,
  };
};

// 팝업 등록
export const useCreatePopup = () => {
  const { handleGoBack } = useHandleAlert();
  const queryClient = useQueryClient();
  const { reset } = usePopupStore();
  const createPopupMutation = useMutation(createPopup, {
    onSuccess: () => {
      alert(create);
      queryClient.invalidateQueries("popupList");
      handleGoBack();
      reset();
    },
  });

  const mutate = ({ formData }) => {
    createPopupMutation.mutate({ formData });
  };

  return mutate;
};

// 팝업 수정
export const useUpdatePopup = () => {
  const { handleGoBack } = useHandleAlert();
  const queryClient = useQueryClient();
  const { reset } = usePopupStore();
  const updatePopupMutation = useMutation(updatePopup, {
    onSuccess: () => {
      alert(update);
      queryClient.invalidateQueries("popupList");
      handleGoBack();
      reset();
    },
  });

  const mutate = ({ key, formData }) => {
    updatePopupMutation.mutate({ key, formData });
  };

  return mutate;
};

// 팝업 삭제
export const useDeletePopup = (reset) => {
  const queryClient = useQueryClient();
  const deletePopupMutation = useMutation(deletePopup, {
    onSuccess: () => {
      alert(remove);
      queryClient.invalidateQueries("popupList");
      reset();
    },
  });

  const mutate = ({ data }) => {
    deletePopupMutation.mutate({ data });
  };

  return mutate;
};
