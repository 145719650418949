import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
import "swiper/swiper.min.css";

SwiperCore.use([Navigation, Pagination, Autoplay]);

export const nextSlide = (ref) => () => {
  if (ref.current) {
    ref.current.slideNext();
  }
};

export const prevSlide = (ref) => () => {
  if (ref.current) {
    ref.current.slidePrev();
  }
};

export const slideTo = (ref) => (index) => {
  if (ref.current) {
    ref.current.slideToLoop(index);
  }
};

const Slider = (props) => {
  const {
    direction,
    spaceBetween,
    slidesPerView,
    mousewheel,
    simulateTouch,
    loop,
    isAuto = false,
    navigation,
    centeredSlides,
    initialSlide = 0,
    //
    width,
    height,
    isTest,
    //
    setActiveIndex,
    //
    children,
    sliderRef,
  } = props;

  // if (isTest) console.log(initialSlide);

  useEffect(() => {
    if (setActiveIndex)
      if (sliderRef.current) {
        const swiperInstance = sliderRef.current;
        swiperInstance.on("slideChange", () => {
          let realIndex = swiperInstance.realIndex;
          setActiveIndex(realIndex);
        });
      }

    // Clean up effect
    return () => {
      if (setActiveIndex) {
        if (sliderRef.current) {
          sliderRef.current.off("slideChange");
        }
      }
    };
  }, [setActiveIndex, sliderRef]);

  return (
    <Swiper
      direction={direction}
      spaceBetween={spaceBetween}
      slidesPerView={slidesPerView}
      mousewheel={mousewheel}
      simulateTouch={simulateTouch}
      centeredSlides={centeredSlides}
      loop={loop}
      autoplay={isAuto}
      navigation={navigation}
      initialSlide={initialSlide}
      onSwiper={(swiper) => {
        if (sliderRef) sliderRef.current = swiper;
      }}
      style={{ width, height }}
    >
      {children}
    </Swiper>
  );
};

Slider.Slide = SwiperSlide;

export default Slider;
