import Box from "components/atoms/box";
import Text from "components/atoms/text";
import InputForm from "components/molecules/inputForm";
import AdminDetailForm from "components/organisms/adminDetailForm";
import AdminDetailFormContainer from "components/organisms/adminDetailFormContainer";
import { AdminContentsContainer } from "components/templates/admin/admin.style";
import { useMyInfo, useUpdateMyInfo } from "hooks/api/admin/dashboard";
import React from "react";
import { useState } from "react";
import convertContactNum from "utils/convertContactNum";
import convertEmailFormatter from "utils/convertEmailFormatter";

const MyInfo = () => {
  const [incorrectPassword, setIncorrectPassword] = useState(false);

  const defaultValues = {
    email: "",
    department: "",
    userName: "",
    hp: "",
    password: "",
    passwordConfirm: "",
  };

  const { list } = useMyInfo();
  const mutate = useUpdateMyInfo();

  const formInfo = [
    {
      title: "ID",
      minHeight: "48px",
      components: (
        <Box display={Box.DISPLAY.FLEX} al="center" height="31px" ariaLabel={"계정명"}>
          <Text as={Text.VARIANT.TEXT} size={Text.SIZE.ADMINCONTENT}>
            {list?.userId ?? ""}
          </Text>
        </Box>
      ),
      required: true,
    },
    {
      title: "이름",
      minHeight: "48px",
      components: (
        <InputForm
          name="userName"
          type="text"
          width="240px"
          height="32px"
          padding="0 12px"
          theme="secondary"
          defaultName={list?.userName ?? ""}
          required={true}
          errorMessage="필수값 입니다."
          ariaLabel="이름"
        />
      ),
      required: true,
    },
    {
      title: "부서",
      minHeight: "48px",
      components: (
        <InputForm
          name="department"
          type="text"
          width="240px"
          height="32px"
          padding="0 12px"
          theme="secondary"
          defaultName={list?.department ?? ""}
          required={true}
          errorMessage="필수값 입니다."
          ariaLabel="부서"
        />
      ),
      required: true,
    },
    {
      title: "비밀번호",
      minHeight: "48px",
      components: (
        <>
          <InputForm
            name="password"
            type="password"
            width="240px"
            height="32px"
            padding="0 12px"
            theme="secondary"
            defaultName={""}
            required={false}
            errorMessage="필수값 입니다."
            ariaLabel="비밀번호"
          />
          <Box margin="8px 0 0 0">
            <Text
              as={Text.VARIANT.TEXT}
              size={Text.SIZE.ADMINCONTENTDETAIL}
              color={Text.COLOR.SECONDARY}
            >
              - 영문 대소문자/숫자/특수문자 중 두가지 이상 조합으로 8자리~16자리 입력
            </Text>
          </Box>
        </>
      ),
      required: false,
    },
    {
      title: "비밀번호 확인",
      minHeight: "48px",
      components: (
        <>
          <InputForm
            name="passwordConfirm"
            type="password"
            width="240px"
            height="32px"
            padding="0 12px"
            theme="secondary"
            defaultName={""}
            required={false}
            ariaLabel="비밀번호"
          />
          {incorrectPassword && (
            <Box margin="8px 0 0 0">
              <Text
                as={Text.VARIANT.TEXT}
                size={Text.SIZE.ADMINCONTENTDETAIL}
                color={Text.COLOR.SECONDARY}
              >
                - 비밀번호가 일치하지 않습니다.
              </Text>
            </Box>
          )}
        </>
      ),
      required: false,
    },
    {
      title: "연락처",
      minHeight: "48px",
      components: (
        <InputForm
          name="hp"
          type="text"
          width="240px"
          height="32px"
          padding="0 12px"
          theme="secondary"
          maxLength="13"
          defaultName={
            list?.hp1 && list?.hp2 && list?.hp3 ? `${list?.hp1}-${list?.hp2}-${list?.hp3}` : ""
          }
          required={true}
          errorMessage="필수값입니다."
          ariaLabel={`연락처: ${list?.hp1}-${list?.hp2}-${list?.hp3}`}
        />
      ),
      required: true,
    },
    {
      title: "이메일",
      minHeight: "48px",
      components: (
        <InputForm
          name="email"
          type="text"
          width="240px"
          height="32px"
          padding="0 12px"
          theme="secondary"
          defaultName={list?.email1 && list?.email2 ? `${list?.email1}@${list?.email2}` : ""}
          required={true}
          errorMessage="필수값입니다."
          ariaLabel={`이메일: ${list?.email1}@${list?.email2}`}
        />
      ),
      required: true,
    },
  ];

  const handleSubmit = (data) => {
    const { password, passwordConfirm, department, email, hp, userName } = data;
    const checkPw = password !== passwordConfirm;
    const { hp1, hp2, hp3 } = convertContactNum(hp);
    const { email1, email2 } = convertEmailFormatter(email);
    setIncorrectPassword(checkPw);

    const submitData = password
      ? { password, department, hp1, hp2, hp3, email1, email2, userName }
      : { department, hp1, hp2, hp3, email1, email2, userName };

    const confirm = window.confirm("수정 하시겠습니까?");
    if (confirm) mutate({ data: submitData });
  };

  return (
    <AdminContentsContainer>
      <Box maxWidth="1024px">
        <AdminDetailFormContainer
          title="내 정보 수정"
          defaultValues={defaultValues}
          handleSubmit={handleSubmit}
        >
          <AdminDetailForm formInfo={formInfo} />
        </AdminDetailFormContainer>
      </Box>
    </AdminContentsContainer>
  );
};

export default MyInfo;
