import { adminDefaultUrl } from "api/admin/url";
import Box from "components/atoms/box";
import Button from "components/atoms/button";
import Text from "components/atoms/text";
import CheckboxForm from "components/molecules/checkboxForm";
import InputForm from "components/molecules/inputForm";
import { useLoginMutation } from "hooks/api/auth";
import useContextForm from "hooks/utils/useContextForm";
import useUrl from "hooks/utils/useUrl";
import React, { useEffect } from "react";
import { useState } from "react";
import useMobileStore from "store/useMobileStore";
import useUserStore from "store/useUserStore";
import loginText from "text/login";

const { loginForm, alert } = loginText;

const LoginForm = ({ borderRadius = "15px" }) => {
  const defaultValues = {
    userId: "",
    password: "",
  };

  const [currUserId, setCurrUserId] = useState("");

  const { setUser, setAuthority } = useUserStore();
  const { isMobile } = useMobileStore();
  const { changeLink, pathname } = useUrl();
  const isAdminLoginPage = pathname === `${adminDefaultUrl}/login`;

  const { methods, Provider } = useContextForm(defaultValues);

  const handleSuccessLogin = (list) => {
    const { userName, role } = list;
    const adminAuthority = role[1];

    const redirect =
      sessionStorage.getItem("redirect") ?? isAdminLoginPage ? `${adminDefaultUrl}/dashboard` : "/";

    setUser(list);
    setAuthority(adminAuthority);

    sessionStorage.setItem("userName", JSON.stringify(userName));
    sessionStorage.setItem("authority", JSON.stringify(adminAuthority));

    changeLink(redirect);
  };

  const handleFailLogin = () => {
    window.alert(alert.fail);
  };

  const loginMutation = useLoginMutation(handleSuccessLogin, handleFailLogin);

  // 로그인 아이디 저장
  useEffect(() => {
    const storedUserId = localStorage.getItem("userId");
    const hasRememberId = localStorage.getItem("rememberId");

    if (storedUserId && hasRememberId) {
      setCurrUserId(storedUserId);
      methods.setValue("rememberId", hasRememberId);
    }
  }, [methods]);

  const onSubmit = (data) => {
    const { userId: id, password } = data;

    if (data.rememberId) {
      localStorage.setItem("userId", data.userId);
      localStorage.setItem("rememberId", true);
    } else {
      localStorage.removeItem("userId");
      localStorage.removeItem("rememberId");
    }

    loginMutation({ id, password });
  };

  return (
    <Box margin="32px 0 30px 0">
      <Provider>
        <Box as={"form"}>
          <Box display={Box.DISPLAY.FLEX} flexDirection="column" gap="15px">
            <InputForm
              type="text"
              name="userId"
              height={isMobile ? "36px" : "48px"}
              padding="0 20px"
              borderRadius={borderRadius}
              theme="secondary"
              placeholder={loginForm.id.title}
              ariaLabel="로그인 아이디"
              errorMessage={loginForm.id.errorMessage}
              defaultName={currUserId}
              required={true}
            />
            <InputForm
              type="password"
              name="password"
              height={isMobile ? "36px" : "48px"}
              padding="0 20px"
              borderRadius={borderRadius}
              theme="secondary"
              placeholder={loginForm.password.title}
              ariaLabel="로그인 비밀번호"
              errorMessage={loginForm.password.errorMessage}
              required={true}
            />
          </Box>

          <Box
            as="label"
            width="fit-content"
            display={Box.DISPLAY.FLEX}
            al="center"
            gap="5px"
            margin={"10px 0 30px 0"}
            cursor="pointer"
          >
            <CheckboxForm
              name="rememberId"
              Label={() => (
                <Text as={Text.VARIANT.SPAN} size={Text.SIZE.CLIENTCONTENTDETAIL}>
                  {loginForm.rememberId.title}
                </Text>
              )}
              ariaLabel="아이디 저장"
            />
          </Box>

          <Button
            width="100%"
            themeStyle="secondary"
            height={isMobile ? "36px" : "48px"}
            fontSize={Button.FONT_SIZE.CLIENTCONTENT}
            borderRadius={borderRadius}
            ariaLabel="로그인 버튼"
            onClick={methods.handleSubmit(onSubmit)}
          >
            {loginForm.submit}
          </Button>
        </Box>
      </Provider>
    </Box>
  );
};

export default LoginForm;
