import Box from "components/atoms/box";
import Button from "components/atoms/button";
import Text from "components/atoms/text";
import { useUpdateBannerType } from "hooks/api/admin/management_site";
import React, { Fragment } from "react";
import { useEffect } from "react";
import useBannerStore from "store/useBannerStore";
import { common } from "text/admin/common";
import { site } from "text/admin/site";

const BannerType = (props) => {
  const { defaultType } = props;

  const { typeList, title } = site.banner.type;
  const { request } = common.alert.updateBanner;

  const { bannerType, setBannerType } = useBannerStore();

  useEffect(() => {
    setBannerType(defaultType);
  }, [defaultType]);

  const mutate = useUpdateBannerType();

  const handleBannerBtn = (category) => {
    setBannerType(category);
  };

  const handleBannerUpdate = () => {
    const confirm = window.confirm(request);
    if (confirm) {
      const data = { snsForm: bannerType };
      mutate({ data });
    }
  };

  return (
    <Box
      display={Box.DISPLAY.FLEX}
      flexDirection="column"
      gap="15px"
      margin="0 0 40px 0"
      maxWidth="1024px"
    >
      <Text as={Text.VARIANT.SUBTITLE}>{title}</Text>
      <Box
        display={Box.DISPLAY.FLEX}
        al="center"
        jc="space-between"
        gap="20px"
        padding="15px 20px"
        bgColor={Box.COLOR.WHITE}
        border="1px solid"
        borderColor={Box.COLOR.ADMINLINEGRAY}
        borderRadius={Box.RADIUS.SMALL}
      >
        <Box display={Box.DISPLAY.FLEX} al="center" gap="16px">
          {typeList.map(({ title, type }, idx) => (
            <Fragment key={idx}>
              <Button
                padding="12px 20px"
                themeStyle="adminCommon"
                isActive={bannerType === type}
                onClick={() => handleBannerBtn(type)}
              >
                <Text
                  as={Text.VARIANT.TEXT}
                  weight={Text.WEIGHT.BOLD}
                  size={Text.SIZE.ADMINCONTENTDETAIL}
                >
                  {title}
                </Text>
              </Button>
            </Fragment>
          ))}
        </Box>
        <Box>
          <Button themeStyle="adminCommon" padding="12px 40px" onClick={handleBannerUpdate}>
            <Text as={Text.VARIANT.TEXT} weight={Text.WEIGHT.BOLD} size={Text.SIZE.ADMINCONTENT}>
              형태 저장
            </Text>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default BannerType;
