import Box from "components/atoms/box";
import Text from "components/atoms/text";
import React from "react";
import HasNotResult from "../HasNotResult";
import useUrl from "hooks/utils/useUrl";

const TerminationMemberTable = (props) => {
  const { list, tableHeader } = props;

  const {
    query: { page = 1 },
  } = useUrl();

  const currPage = parseInt(page);

  return (
    <>
      <Box
        display={Box.DISPLAY.FLEX}
        height="32px"
        borderTop="1px solid"
        borderColor={Box.COLOR.LINEGRAY}
      >
        {tableHeader.map(({ title, width, ariaLabel }, idx) => (
          <Box
            width={width}
            padding="8px 0"
            key={idx}
            borderLeft="1px solid"
            borderRight={idx === tableHeader.length - 1 ? "1px solid" : ""}
            borderBottom={list.length === 0 ? "1px solid" : ""}
            borderColor={Box.COLOR.LINEGRAY}
            bgColor={Box.COLOR.ADMINCONTENTSBG}
          >
            <Text
              as={Text.VARIANT.TEXT}
              size={Text.SIZE.CLIENTCONTENTDETAIL}
              weight={Text.WEIGHT.BOLD}
              textAlign="center"
              ariaLabel={ariaLabel}
            >
              {title}
            </Text>
          </Box>
        ))}
      </Box>
      {list.length === 0 && <HasNotResult />}
      {list.length > 0 &&
        list.map(
          ({ email1, email2, hp1, hp2, hp3, newsYN, regDate, userId, userName, key }, idx) => {
            const hp =
              hp1 !== undefined && hp2 !== undefined && hp3 !== undefined && `${hp1}-${hp2}-${hp3}`;
            const email = email1 !== undefined && email2 !== undefined && `${email1}@${email2}`;
            const pageIdx = currPage > 1 ? (currPage - 1) * 10 + (idx + 1) : idx + 1;

            return (
              <Box
                key={key}
                borderTop={idx === 0 ? "1px solid" : ""}
                borderBottom="1px solid"
                borderColor={Box.COLOR.LINEGRAY}
                display={Box.DISPLAY.FLEX}
                height="77px"
              >
                <Box
                  width="7%"
                  display={Box.DISPLAY.FLEX}
                  jc="center"
                  al="center"
                  wordBreak="break-all"
                  borderLeft="1px solid"
                  borderColor={Box.COLOR.LINEGRAY}
                  padding="0 10px"
                >
                  <Text
                    as={Text.VARIANT.TEXT}
                    size={Text.SIZE.CLIENTCONTENTDETAIL}
                    textAlign="center"
                    ariaLabel={pageIdx}
                  >
                    {pageIdx}
                  </Text>
                </Box>
                <Box
                  width="20%"
                  display={Box.DISPLAY.FLEX}
                  jc="center"
                  al="center"
                  wordBreak="break-all"
                  borderLeft="1px solid"
                  borderColor={Box.COLOR.LINEGRAY}
                  padding="0 10px"
                >
                  <Text
                    as={Text.VARIANT.TEXT}
                    size={Text.SIZE.CLIENTCONTENTDETAIL}
                    textAlign="center"
                    ariaLabel={userId}
                  >
                    {userId}
                  </Text>
                </Box>
                <Box
                  width="10%"
                  display={Box.DISPLAY.FLEX}
                  jc="center"
                  al="center"
                  wordBreak="break-all"
                  borderLeft="1px solid"
                  borderColor={Box.COLOR.LINEGRAY}
                  padding="0 10px"
                >
                  <Text
                    as={Text.VARIANT.TEXT}
                    size={Text.SIZE.CLIENTCONTENTDETAIL}
                    textAlign="center"
                    ariaLabel={userName}
                  >
                    {userName}
                  </Text>
                </Box>
                <Box
                  width="14%"
                  display={Box.DISPLAY.FLEX}
                  jc="center"
                  al="center"
                  wordBreak="break-all"
                  borderLeft="1px solid"
                  borderColor={Box.COLOR.LINEGRAY}
                  padding="0 10px"
                >
                  <Text
                    as={Text.VARIANT.TEXT}
                    size={Text.SIZE.CLIENTCONTENTDETAIL}
                    textAlign="center"
                    ariaLabel={hp}
                  >
                    {hp}
                  </Text>
                </Box>
                <Box
                  width="19%"
                  display={Box.DISPLAY.FLEX}
                  jc="center"
                  al="center"
                  wordBreak="break-all"
                  borderLeft="1px solid"
                  borderColor={Box.COLOR.LINEGRAY}
                  padding="0 10px"
                >
                  <Text
                    as={Text.VARIANT.TEXT}
                    size={Text.SIZE.CLIENTCONTENTDETAIL}
                    textAlign="center"
                    ariaLabel={email}
                  >
                    {email}
                  </Text>
                </Box>
                <Box
                  width="14%"
                  display={Box.DISPLAY.FLEX}
                  jc="center"
                  al="center"
                  wordBreak="break-all"
                  borderLeft="1px solid"
                  borderColor={Box.COLOR.LINEGRAY}
                  padding="0 10px"
                >
                  <Text
                    as={Text.VARIANT.TEXT}
                    size={Text.SIZE.CLIENTCONTENTDETAIL}
                    textAlign="center"
                    ariaLabel={newsYN === "Y" ? "뉴스레터 수신" : "뉴스레터 수신거부"}
                  >
                    {newsYN}
                  </Text>
                </Box>
                <Box
                  width="16%"
                  display={Box.DISPLAY.FLEX}
                  jc="center"
                  al="center"
                  wordBreak="break-all"
                  borderLeft="1px solid"
                  borderRight="1px solid"
                  padding="0 10px"
                  borderColor={Box.COLOR.LINEGRAY}
                >
                  <Text
                    as={Text.VARIANT.TEXT}
                    size={Text.SIZE.CLIENTCONTENTDETAIL}
                    textAlign="center"
                    ariaLabel={`가입일: ${regDate}`}
                  >
                    {regDate}
                  </Text>
                </Box>
              </Box>
            );
          }
        )}
    </>
  );
};

export default TerminationMemberTable;
