import Box from "components/atoms/box";
import Text from "components/atoms/text";
import Blank from "components/molecules/blank";
import List from "components/molecules/list";
import MobileBoard from "components/organisms/mobileBoard";
import MobileCalendarHeader from "components/organisms/mobileCalendarHeader";
import MobileDailyHeader from "components/organisms/mobileDailyHeader";
import NewsList from "components/organisms/newsList";
import dayjs from "dayjs";
import { useBoardDaily } from "hooks/api/board";
import useUrl from "hooks/utils/useUrl";
import React from "react";
import { changeWeek, getDates, getMonths, getWeeks } from "utils/calendar";
import dailyText from "text/daily";

const { categoryFormat } = dailyText;

const categoryForm = {
  daily: {
    ...categoryFormat.daily,
    picker: "date",
    light: 3,
    headListForm: getDates,
    defaultValue: dayjs().format("YYYYMMDD"),
    handleChangeDate: (changeQuery) => (date) => {
      changeQuery({
        date: date.format("YYYYMMDD"),
        calendar: date.format("YYYYMMDD"),
      });
    },
  },
  week: {
    ...categoryFormat.week,
    picker: "week",
    light: 4,
    headListForm: getWeeks,
    defaultValue: changeWeek(dayjs()),
    handleChangeDate: (changeQuery) => (date, week) => {
      changeQuery({
        date: changeWeek(date),
        calendar: date.format("YYYYMMDD"),
      });
    },
  },
  month: {
    ...categoryFormat.month,
    picker: "month",
    light: 6,
    headListForm: getMonths,
    defaultValue: dayjs().format("YYYYMM"),
    handleChangeDate: (changeQuery) => (date) => {
      changeQuery({
        date: date.format("YYYYMM"),
        calendar: date.format("YYYYMMDD"),
      });
    },
  },
};

const MobileDaily = () => {
  const {
    query: { page = "1", category = "daily", date },
  } = useUrl();

  const calendarDate = categoryForm[category];

  return (
    <>
      <Box margin={"145px 0 0 0"} padding={"20px 12px 10px"} />
      <MobileBoard isAllPage={false} padding={""}>
        <Box width={"100%"}>
          <Box width={"100%"}>
            <Box padding={"20px 40px 32px"} width={"100%"}>
              <Text as={Text.VARIANT.TEXT} size={Text.SIZE.MOBILECATEGORY}>
                {calendarDate.title}
              </Text>

              <MobileCalendarHeader
                calendar={date ?? calendarDate.defaultValue}
                format={calendarDate.format}
                category={calendarDate.picker}
              />
            </Box>

            <MobileDailyHeader calendarDate={calendarDate} key={category} />
          </Box>
          <Box
            width={"100%"}
            margin={"32px 0 0 0"}
            borderTop={"1px solid"}
            borderColor={Box.COLOR.LINEGRAY}
          >
            <List
              api={useBoardDaily}
              params={{
                category,
                page,
                date: date ?? calendarDate.defaultValue,
                limit: 4,
              }}
              margin={"50px 0"}
            >
              {(list) =>
                list && (
                  <>
                    {list.length === 0 && <Blank isMobile />}
                    {list.length !== 0 && (
                      <Box width={"100%"} padding={"0 24px"}>
                        <NewsList.LAYOUT.LineSmallImageNews
                          list={list}
                          category={"news"}
                          isMobile
                        />
                      </Box>
                    )}
                  </>
                )
              }
            </List>
          </Box>
        </Box>
      </MobileBoard>
    </>
  );
};

export default MobileDaily;
