import React from "react";
import Text from "components/atoms/text";
import Box from "components/atoms/box";
import Image from "components/atoms/image";
import useMobileStore from "store/useMobileStore";
import serviceText from "text/service";

const { privacy, email, copyright, termsOfUse } = serviceText;

const Title = (props) => {
  const { children, isMobile } = props;

  return (
    <Text
      as={Text.VARIANT.TITLE}
      size={isMobile ? Text.SIZE.MOBILECONTENT : Text.SIZE.CLIENTTITLE}
    >
      {children}
    </Text>
  );
};

const Category = (props) => {
  const { children, isMobile } = props;

  return (
    <Text
      as={Text.VARIANT.TEXT}
      size={isMobile ? Text.SIZE.MOBILECONTENT : Text.SIZE.CLIENTCATEGORY}
      margin={isMobile ? "22px 0 0" : "40px 0 0 "}
      weight={Text.WEIGHT.BOLD}
    >
      {children}
    </Text>
  );
};

const SubTitle = (props) => {
  const { children, size, margin, color = Text.COLOR.SECONDARY } = props;

  return (
    <Text as={Text.VARIANT.SUBTITLE} size={size} color={color} margin={margin}>
      {children}
    </Text>
  );
};

const Content = (props) => {
  const { children, margin, isMobile } = props;

  return (
    <Text
      as={Text.VARIANT.TEXT}
      whiteSpace={"pre-wrap"}
      size={isMobile ? Text.SIZE.MOBILECONTENT : Text.SIZE.CLIENTNARMAL}
      margin={margin}
      lineHeight={isMobile ? "15px" : "28px"}
    >
      {children}
    </Text>
  );
};

export const PrivacyRule = () => {
  const { isMobile } = useMobileStore();

  return (
    <>
      <Box display={Box.DISPLAY.FLEX} jc={isMobile ? "center" : "left"}>
        <Title isMobile={isMobile}>{privacy.title}</Title>
      </Box>

      <SubTitle
        color={isMobile ? Text.COLOR.BLACK : Text.COLOR.SECONDARY}
        size={isMobile ? Text.SIZE.MOBILECONTENT : Text.SIZE.CLIENTCATEGORY}
        margin={isMobile ? "22px 0 17px" : "40px 0 20px"}
      >
        {privacy.subTitle}
      </SubTitle>

      <Content isMobile={isMobile}>{privacy.content[0]}</Content>

      <SubTitle
        size={isMobile ? Text.SIZE.MOBILECONTENT : Text.SIZE.CLIENTNORMAL}
        margin={isMobile ? "17px 0 0" : "60px 0 12px"}
      >
        {privacy.thirdTitle[0]}
      </SubTitle>
      <Content isMobile={isMobile}>{privacy.content[1]}</Content>

      <SubTitle
        size={isMobile ? Text.SIZE.MOBILECONTENT : Text.SIZE.CLIENTNORMAL}
        margin={isMobile ? "17px 0 0" : "30px 0 12px"}
      >
        {privacy.thirdTitle[1]}
      </SubTitle>
      <Content isMobile={isMobile}>{privacy.content[2]}</Content>

      <SubTitle
        size={isMobile ? Text.SIZE.MOBILECONTENT : Text.SIZE.CLIENTNORMAL}
        margin={isMobile ? "17px 0 0" : "30px 0 12px"}
      >
        {privacy.thirdTitle[2]}
      </SubTitle>
      <Content isMobile={isMobile}>{privacy.content[3]}</Content>

      <SubTitle
        size={isMobile ? Text.SIZE.MOBILECONTENT : Text.SIZE.CLIENTNORMAL}
        margin={isMobile ? "17px 0 0" : "30px 0 12px"}
      >
        {privacy.thirdTitle[3]}
      </SubTitle>
      <Content isMobile={isMobile}>{privacy.content[4]}</Content>

      <SubTitle
        size={isMobile ? Text.SIZE.MOBILECONTENT : Text.SIZE.CLIENTNORMAL}
        margin={isMobile ? "17px 0 0" : "30px 0 12px"}
      >
        {privacy.thirdTitle[4]}
      </SubTitle>
      <Content isMobile={isMobile}>{privacy.content[5]}</Content>

      <SubTitle
        size={isMobile ? Text.SIZE.MOBILECONTENT : Text.SIZE.CLIENTNORMAL}
        margin={isMobile ? "17px 0 0" : "30px 0 12px"}
      >
        {privacy.thirdTitle[5]}
      </SubTitle>
      <Content isMobile={isMobile}>{privacy.content[6]}</Content>

      <SubTitle
        size={isMobile ? Text.SIZE.MOBILECONTENT : Text.SIZE.CLIENTNORMAL}
        margin={isMobile ? "17px 0 0" : "30px 0 12px"}
      >
        {privacy.thirdTitle[6]}
      </SubTitle>
      <Content isMobile={isMobile}>{privacy.content[7]}</Content>

      <SubTitle
        size={isMobile ? Text.SIZE.MOBILECONTENT : Text.SIZE.CLIENTNORMAL}
        margin={isMobile ? "17px 0 0" : "30px 0 12px"}
      >
        {privacy.thirdTitle[7]}
      </SubTitle>
      <Content isMobile={isMobile}>{privacy.content[8]}</Content>

      <SubTitle
        size={isMobile ? Text.SIZE.MOBILECONTENT : Text.SIZE.CLIENTNORMAL}
        margin={isMobile ? "17px 0 0" : "30px 0 12px"}
      >
        {privacy.thirdTitle[8]}
      </SubTitle>
      <Content isMobile={isMobile}>{privacy.content[9]}</Content>

      <SubTitle
        size={isMobile ? Text.SIZE.MOBILECONTENT : Text.SIZE.CLIENTNORMAL}
        margin={isMobile ? "17px 0 0" : "30px 0 12px"}
      >
        {privacy.thirdTitle[9]}
      </SubTitle>
      <Content isMobile={isMobile}>{privacy.content[10]}</Content>
    </>
  );
};

export const PrivacyInfo = () => {
  const { isMobile } = useMobileStore();

  return (
    <>
      <Box
        width={"100%"}
        height={"1px"}
        borderBottom={"1px solid"}
        borderColor={Box.COLOR.LINEGRAY}
        margin={isMobile ? "40px 0" : "60px 0"}
      />
      {isMobile ? (
        <Content
          isMobile
        >{`기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.
1. 개인정보침해신고센터 
(www.1336.or.kr/국번없이 118)
2. 정보보호마크인증위원회 
(www.eprivacy.or.kr/02-580-0533~4)
3. 대검찰청 인터넷범죄수사센터 
(http://icic.sppo.go.kr/02-3480-3600)
4. 경찰청 사이버테러대응센터 
(www.ctrc.go.kr/02-392-0330)`}</Content>
      ) : (
        <Content>{privacy.common[0]}</Content>
      )}
      <Box
        width={"100%"}
        height={"1px"}
        borderBottom={"1px solid"}
        borderColor={Box.COLOR.LINEGRAY}
        margin={isMobile ? "40px 0" : "60px 0"}
      />
      <Box>
        <Text
          as={Text.VARIANT.SPAN}
          width={
            isMobile
              ? "100%"
              : "calc(100% - 151px - 102px - 171px - 30px - 30px - 108px)"
          }
          size={isMobile ? Text.SIZE.MOBILECONTENT : Text.SIZE.CLIENTNARMAL}
          color={isMobile ? Text.COLOR.BLACK : Text.COLOR.TEXTGRAY}
          margin={isMobile ? "0 0 13px" : "0 108px 0 0"}
        >
          {privacy.info[0]}
        </Text>
        <Text
          as={Text.VARIANT.SPAN}
          width={isMobile ? "100%" : "151px"}
          size={isMobile ? Text.SIZE.MOBILECONTENT : Text.SIZE.CLIENTNARMAL}
          textAlign={isMobile ? "left" : "right"}
          color={Text.COLOR.TEXTGRAY}
          margin={"0 30px 0 0"}
        >
          <Text as={Text.VARIANT.SPAN} width={isMobile ? "45px" : "auto"}>
            {privacy.info[1]}
          </Text>{" "}
          {privacy.info[2]}
        </Text>
        <Text
          as={Text.VARIANT.SPAN}
          width={isMobile ? "100%" : "102px"}
          size={isMobile ? Text.SIZE.MOBILECONTENT : Text.SIZE.CLIENTNARMAL}
          textAlign={isMobile ? "left" : "right"}
          color={Text.COLOR.TEXTGRAY}
          margin={"0 30px 0 0"}
        >
          <Text as={Text.VARIANT.SPAN} width={isMobile ? "45px" : "auto"}>
            {privacy.info[3]}
          </Text>{" "}
          {privacy.info[4]}
        </Text>
        <Text
          as={Text.VARIANT.SPAN}
          width={isMobile ? "100%" : "171px"}
          size={isMobile ? Text.SIZE.MOBILECONTENT : Text.SIZE.CLIENTNARMAL}
          textAlign={isMobile ? "left" : "right"}
          color={Text.COLOR.TEXTGRAY}
        >
          <Text as={Text.VARIANT.SPAN} width={isMobile ? "45px" : "auto"}>
            {privacy.info[5]}
          </Text>{" "}
          {privacy.info[6]}
        </Text>
      </Box>
    </>
  );
};

export const Privacy = () => {
  return (
    <>
      <PrivacyRule />
      <PrivacyInfo />
    </>
  );
};

export const UsingRule = () => {
  const { isMobile } = useMobileStore();

  return (
    <>
      <Box display={Box.DISPLAY.FLEX} jc={isMobile ? "center" : "left"}>
        <Title isMobile={isMobile}>{termsOfUse.title}</Title>
      </Box>
      <Category isMobile={isMobile}>{termsOfUse.subTitle[0]}</Category>
      <SubTitle
        size={isMobile ? Text.SIZE.MOBILECONTENT : Text.SIZE.CLIENTNORMAL}
        margin={isMobile ? "17px 0 0" : "30px 0 12px"}
      >
        {termsOfUse.thirdTitle[0]}
      </SubTitle>
      <Content isMobile={isMobile}>{termsOfUse.content[0]}</Content>
      <SubTitle
        size={isMobile ? Text.SIZE.MOBILECONTENT : Text.SIZE.CLIENTNORMAL}
        margin={isMobile ? "17px 0 0" : "30px 0 12px"}
      >
        {termsOfUse.thirdTitle[1]}
      </SubTitle>
      <Content isMobile={isMobile}>{termsOfUse.content[1]}</Content>
      <SubTitle
        size={isMobile ? Text.SIZE.MOBILECONTENT : Text.SIZE.CLIENTNORMAL}
        margin={isMobile ? "17px 0 0" : "30px 0 12px"}
      >
        {termsOfUse.thirdTitle[2]}
      </SubTitle>
      <Content isMobile={isMobile}>{termsOfUse.content[2]}</Content>
      <SubTitle
        size={isMobile ? Text.SIZE.MOBILECONTENT : Text.SIZE.CLIENTNORMAL}
        margin={isMobile ? "17px 0 0" : "30px 0 12px"}
      >
        {termsOfUse.thirdTitle[3]}
      </SubTitle>
      <Content isMobile={isMobile}>{termsOfUse.content[3]}</Content>
      <Category isMobile={isMobile}>{termsOfUse.subTitle[1]}</Category>
      <SubTitle
        size={isMobile ? Text.SIZE.MOBILECONTENT : Text.SIZE.CLIENTNORMAL}
        margin={isMobile ? "17px 0 0" : "30px 0 12px"}
      >
        {termsOfUse.thirdTitle[4]}
      </SubTitle>
      <Content isMobile={isMobile}>{termsOfUse.content[4]}</Content>
      <SubTitle
        size={isMobile ? Text.SIZE.MOBILECONTENT : Text.SIZE.CLIENTNORMAL}
        margin={isMobile ? "17px 0 0" : "30px 0 12px"}
      >
        {termsOfUse.thirdTitle[5]}
      </SubTitle>
      <Content isMobile={isMobile}>{termsOfUse.content[5]}</Content>
      <SubTitle
        size={isMobile ? Text.SIZE.MOBILECONTENT : Text.SIZE.CLIENTNORMAL}
        margin={isMobile ? "17px 0 0" : "30px 0 12px"}
      >
        {termsOfUse.thirdTitle[6]}
      </SubTitle>
      <Content isMobile={isMobile}>{termsOfUse.content[6]}</Content>
      <SubTitle
        size={isMobile ? Text.SIZE.MOBILECONTENT : Text.SIZE.CLIENTNORMAL}
        margin={isMobile ? "17px 0 0" : "30px 0 12px"}
      >
        {termsOfUse.thirdTitle[7]}
      </SubTitle>
      <Content isMobile={isMobile}>{termsOfUse.content[7]}</Content>
      <Category isMobile={isMobile}>{termsOfUse.subTitle[2]}</Category>
      <SubTitle
        size={isMobile ? Text.SIZE.MOBILECONTENT : Text.SIZE.CLIENTNORMAL}
        margin={isMobile ? "17px 0 0" : "30px 0 12px"}
      >
        {termsOfUse.thirdTitle[8]}
      </SubTitle>
      <Content isMobile={isMobile}>{termsOfUse.content[8]}</Content>
      <SubTitle
        size={isMobile ? Text.SIZE.MOBILECONTENT : Text.SIZE.CLIENTNORMAL}
        margin={isMobile ? "17px 0 0" : "30px 0 12px"}
      >
        {termsOfUse.thirdTitle[9]}
      </SubTitle>
      <Content isMobile={isMobile}>{termsOfUse.content[9]}</Content>
      <SubTitle
        size={isMobile ? Text.SIZE.MOBILECONTENT : Text.SIZE.CLIENTNORMAL}
        margin={isMobile ? "17px 0 0" : "30px 0 12px"}
      >
        {termsOfUse.thirdTitle[10]}
      </SubTitle>
      <Content isMobile={isMobile}>{termsOfUse.content[10]}</Content>
      <SubTitle
        size={isMobile ? Text.SIZE.MOBILECONTENT : Text.SIZE.CLIENTNORMAL}
        margin={isMobile ? "17px 0 0" : "30px 0 12px"}
      >
        {termsOfUse.thirdTitle[11]}
      </SubTitle>
      <Content isMobile={isMobile}>{termsOfUse.content[11]}</Content>
      <SubTitle
        size={isMobile ? Text.SIZE.MOBILECONTENT : Text.SIZE.CLIENTNORMAL}
        margin={isMobile ? "17px 0 0" : "30px 0 12px"}
      >
        {termsOfUse.thirdTitle[12]}
      </SubTitle>
      <Content isMobile={isMobile}>{termsOfUse.content[12]}</Content>
      <SubTitle
        size={isMobile ? Text.SIZE.MOBILECONTENT : Text.SIZE.CLIENTNORMAL}
        margin={isMobile ? "17px 0 0" : "30px 0 12px"}
      >
        {termsOfUse.thirdTitle[13]}
      </SubTitle>
      <Content isMobile={isMobile}>{termsOfUse.content[13]}</Content>
      <Category isMobile={isMobile}>{termsOfUse.subTitle[3]}</Category>
      <SubTitle
        size={isMobile ? Text.SIZE.MOBILECONTENT : Text.SIZE.CLIENTNORMAL}
        margin={isMobile ? "17px 0 0" : "30px 0 12px"}
      >
        {termsOfUse.thirdTitle[14]}
      </SubTitle>
      <Content isMobile={isMobile}>{termsOfUse.content[14]}</Content>
      <Category isMobile={isMobile}>{termsOfUse.subTitle[4]}</Category>
      <SubTitle
        size={isMobile ? Text.SIZE.MOBILECONTENT : Text.SIZE.CLIENTNORMAL}
        margin={isMobile ? "17px 0 0" : "30px 0 12px"}
      >
        {termsOfUse.thirdTitle[15]}
      </SubTitle>
      <Content isMobile={isMobile}>{termsOfUse.content[15]}</Content>
      <Category isMobile={isMobile}>{termsOfUse.subTitle[5]}</Category>
      <SubTitle
        size={isMobile ? Text.SIZE.MOBILECONTENT : Text.SIZE.CLIENTNORMAL}
        margin={isMobile ? "17px 0 0" : "30px 0 12px"}
      >
        {termsOfUse.thirdTitle[16]}
      </SubTitle>
      <Content isMobile={isMobile}>{termsOfUse.content[16]}</Content>
      <SubTitle
        size={isMobile ? Text.SIZE.MOBILECONTENT : Text.SIZE.CLIENTNORMAL}
        margin={isMobile ? "17px 0 0" : "30px 0 12px"}
      >
        {termsOfUse.thirdTitle[17]}
      </SubTitle>
      <Content isMobile={isMobile}>{termsOfUse.content[17]}</Content>
      <SubTitle
        size={isMobile ? Text.SIZE.MOBILECONTENT : Text.SIZE.CLIENTNORMAL}
        margin={isMobile ? "17px 0 0" : "30px 0 12px"}
      >
        {termsOfUse.thirdTitle[18]}
      </SubTitle>
      <Content isMobile={isMobile}>{termsOfUse.content[18]}</Content>
    </>
  );
};

export const UsingInfo = () => {
  const { isMobile } = useMobileStore();

  return (
    <>
      <Box
        width={"100%"}
        height={"1px"}
        borderBottom={"1px solid"}
        borderColor={Box.COLOR.LINEGRAY}
        margin={isMobile ? "40px 0" : "60px 0"}
      />
      <Box>
        <Text
          as={Text.VARIANT.SPAN}
          width={
            isMobile
              ? "100%"
              : "calc(100% - 151px - 102px - 171px - 30px - 30px - 108px)"
          }
          size={isMobile ? Text.SIZE.MOBILECONTENT : Text.SIZE.CLIENTNARMAL}
          color={isMobile ? Text.COLOR.BLACK : Text.COLOR.TEXTGRAY}
          margin={isMobile ? "0 0 13px" : "0 108px 0 0"}
        >
          {termsOfUse.info[0]}
        </Text>
        <Text
          as={Text.VARIANT.SPAN}
          width={isMobile ? "100%" : "151px"}
          size={isMobile ? Text.SIZE.MOBILECONTENT : Text.SIZE.CLIENTNARMAL}
          textAlign={isMobile ? "left" : "right"}
          color={Text.COLOR.TEXTGRAY}
          margin={"0 30px 0 0"}
        >
          <Text as={Text.VARIANT.SPAN} width={isMobile ? "45px" : "auto"}>
            {termsOfUse.info[1]}
          </Text>{" "}
          {termsOfUse.info[2]}
        </Text>
        <Text
          as={Text.VARIANT.SPAN}
          width={isMobile ? "100%" : "102px"}
          size={isMobile ? Text.SIZE.MOBILECONTENT : Text.SIZE.CLIENTNARMAL}
          textAlign={isMobile ? "left" : "right"}
          color={Text.COLOR.TEXTGRAY}
          margin={"0 30px 0 0"}
        >
          <Text as={Text.VARIANT.SPAN} width={isMobile ? "45px" : "auto"}>
            {termsOfUse.info[3]}
          </Text>{" "}
          {termsOfUse.info[4]}
        </Text>
        <Text
          as={Text.VARIANT.SPAN}
          width={isMobile ? "100%" : "171px"}
          size={isMobile ? Text.SIZE.MOBILECONTENT : Text.SIZE.CLIENTNARMAL}
          textAlign={isMobile ? "left" : "right"}
          color={Text.COLOR.TEXTGRAY}
        >
          <Text as={Text.VARIANT.SPAN} width={isMobile ? "45px" : "auto"}>
            {termsOfUse.info[5]}
          </Text>{" "}
          {termsOfUse.info[6]}
        </Text>
      </Box>
    </>
  );
};

export const Using = () => {
  return (
    <>
      <UsingRule />
      <UsingInfo />
    </>
  );
};

export const Email = () => {
  const { isMobile } = useMobileStore();

  return (
    <>
      <Box display={Box.DISPLAY.FLEX} jc={isMobile ? "center" : "left"}>
        <Title isMobile={isMobile}>{email.title}</Title>
      </Box>
      <Box
        display={Box.DISPLAY.FLEX}
        flexDirection={"column"}
        al={"center"}
        margin={isMobile ? "63px 0 100px" : "80px 0 168px"}
      >
        <Image
          src={"/assets/service/email.png"}
          margin={"0 0 40px"}
          alt={"email"}
          width={isMobile ? "207px" : "auto"}
        />
        {isMobile ? (
          <>
            <Text
              as={Text.VARIANT.TEXT}
              size={Text.SIZE.MOBILECONTENT}
              lineHeight="15px"
            >
              본 웹사이트에 게시된 이메일의 무단수집을 거부하며,
            </Text>
            <Text
              as={Text.VARIANT.TEXT}
              size={Text.SIZE.MOBILECONTENT}
              lineHeight="15px"
            >
              전자우편 수집 프로그램이나 그 밖의 기술적 작치를 이용하여
            </Text>
            <Text
              as={Text.VARIANT.TEXT}
              size={Text.SIZE.MOBILECONTENT}
              lineHeight="15px"
            >
              무단으로 수집할 경우
              <Text as={Text.VARIANT.SPAN} color={Text.COLOR.RED}>
                정보통신망이용촉진 및 정보보호등에
              </Text>
            </Text>
            <Text
              as={Text.VARIANT.TEXT}
              size={Text.SIZE.MOBILECONTENT}
              lineHeight="15px"
            >
              <Text as={Text.VARIANT.SPAN} color={Text.COLOR.RED}>
                관한법률에 의해 형사처벌됨
              </Text>
              을 유념하시기 바랍니다.
            </Text>
          </>
        ) : (
          <>
            <Text
              as={Text.VARIANT.TEXT}
              size={Text.SIZE.CLIENTCATEGORY}
              lineHeight="35px"
            >
              {email.content.common[0]}
            </Text>
            <Text
              as={Text.VARIANT.TEXT}
              size={Text.SIZE.CLIENTCATEGORY}
              lineHeight="35px"
            >
              {email.content.common[1]}
            </Text>
            <Text
              as={Text.VARIANT.TEXT}
              size={Text.SIZE.CLIENTCATEGORY}
              lineHeight="35px"
            >
              <Text as={Text.VARIANT.SPAN} color={Text.COLOR.RED}>
                {email.content.bold[0]}
              </Text>
              {email.content.common[2]}
            </Text>
          </>
        )}
      </Box>
    </>
  );
};

export const CopyRight = () => {
  const { isMobile } = useMobileStore();

  return (
    <>
      <Box display={Box.DISPLAY.FLEX} jc={isMobile ? "center" : "left"}>
        <Title isMobile={isMobile}>{copyright.title}</Title>
      </Box>

      <Box display={Box.DISPLAY.FLEX} flexDirection={"column"} al={"center"}>
        <Image
          src={"/assets/service/copyright.png"}
          margin={isMobile ? "63px 0 3px" : "80px 0"}
          alt={"email"}
          width={isMobile ? "188px" : "auto"}
        />
        {isMobile ? (
          <>
            <Text
              as={Text.VARIANT.TEXT}
              size={Text.SIZE.MOBILECONTENT}
              textAlign="center"
              lineHeight="15px"
            >
              저작권법 제24조의2에 따라 한국과학기술단체총연합회에서
            </Text>

            <Text
              as={Text.VARIANT.TEXT}
              size={Text.SIZE.MOBILECONTENT}
              textAlign="center"
              lineHeight="15px"
            >
              <Text as={Text.VARIANT.SPAN} color={Text.COLOR.RED}>
                저작재산권의 전부를 보유한 저작물의 경우에는 자유이용이 가능
              </Text>
              합니다.
            </Text>
            <Text
              as={Text.VARIANT.TEXT}
              size={Text.SIZE.MOBILECONTENT}
              textAlign="center"
              lineHeight="15px"
            >
              단, 자유이용이 가능한 저작물인지를 담당자에게 사전에 확인한 후
              이용하시기 바랍니다.
            </Text>
            <Text
              as={Text.VARIANT.TEXT}
              size={Text.SIZE.MOBILECONTENT}
              textAlign="center"
              lineHeight="15px"
            >
              &nbsp;
            </Text>
            <Text
              as={Text.VARIANT.TEXT}
              size={Text.SIZE.MOBILECONTENT}
              textAlign="center"
              lineHeight="15px"
            >
              자유이용을 확인한 경우에는 반드시 저작물의 출처를 구체적으로
              표시하여야 합니다.
            </Text>
          </>
        ) : (
          <>
            <Text
              as={Text.VARIANT.TEXT}
              size={Text.SIZE.CLIENTCATEGORY}
              lineHeight="35px"
            >
              {copyright.content.common[0]}
            </Text>

            <Text
              as={Text.VARIANT.TEXT}
              size={Text.SIZE.CLIENTCATEGORY}
              lineHeight="35px"
            >
              <Text as={Text.VARIANT.SPAN} color={Text.COLOR.RED}>
                {copyright.content.bold[0]}
              </Text>
              {copyright.content.common[1]}
            </Text>
            <Text
              as={Text.VARIANT.TEXT}
              size={Text.SIZE.CLIENTCATEGORY}
              lineHeight="35px"
            >
              {copyright.content.common[2]}
            </Text>
            <Text
              as={Text.VARIANT.TEXT}
              size={Text.SIZE.CLIENTCATEGORY}
              lineHeight="35px"
            >
              &nbsp;
            </Text>
            <Text
              as={Text.VARIANT.TEXT}
              size={Text.SIZE.CLIENTCATEGORY}
              lineHeight="35px"
            >
              {copyright.content.common[3]}
            </Text>
          </>
        )}

        <Box
          display={Box.DISPLAY.FLEX}
          flexDirection={isMobile ? "column" : "row"}
          jc={"flex-end"}
          width={isMobile ? "100%" : "calc(100% - 40px - 40px)"}
          margin={isMobile ? "40px 0" : "90px 0 168px"}
          padding="24px 0"
          borderTop="1px solid"
          borderBottom="1px solid"
          borderColor={Box.COLOR.LINEGRAY}
        >
          <Text
            as={Text.VARIANT.TEXT}
            size={isMobile ? Text.SIZE.MOBILECONTENT : Text.SIZE.NORMAL}
            textAlign={isMobile ? "center" : "left"}
            lineHeight={isMobile ? "15px" : "35px"}
            color={isMobile ? Text.COLOR.BLACK : Text.COLOR.TEXTGRAY}
            margin={isMobile ? "0" : "0 30px 0 0"}
          >
            {copyright.info[0]}
          </Text>
          <Text
            as={Text.VARIANT.TEXT}
            size={isMobile ? Text.SIZE.MOBILECONTENT : Text.SIZE.NORMAL}
            textAlign={isMobile ? "center" : "left"}
            lineHeight={isMobile ? "15px" : "35px"}
            color={isMobile ? Text.COLOR.BLACK : Text.COLOR.TEXTGRAY}
          >
            {copyright.info[1]}
          </Text>
        </Box>
      </Box>
    </>
  );
};
