import Box from "components/atoms/box";
import Button from "components/atoms/button";
import Image from "components/atoms/image";
import Text from "components/atoms/text";
import Comment from "components/organisms/comment";
import { NewsInfo } from "components/organisms/newsList";
import ShareBox from "components/organisms/shareBox";
import TextSizeBox from "components/organisms/textSizeBox";
import { useBoardDetail, useUpdateViewCountMutation } from "hooks/api/board";
import useExternalToggle from "hooks/utils/useExternalToggle";
import useUrl from "hooks/utils/useUrl";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import { BsPaperclip } from "@react-icons/all-files/bs/BsPaperclip";
import { createPeriod } from "utils/calendar";
import downloadFile from "utils/downloadFile";
import "./content.css";
import MobileSideNews from "components/organisms/mobileSideNews";
import detailText from "text/detail";

const { coverage, event, file } = detailText;

const MobileDetail = () => {
  const { query, path } = useUrl();

  const {
    data = {
      article: {
        contents: "",
        regDate: "",
        title: "",
        viewCount: 0,
        writer: "",
        writerId: "",
        writerImg: "",
        writerType: "",
      },

      commentOnOff: "Y",
    },
    isSuccess,
  } = useBoardDetail(path[1]);

  const shareRef = useRef();
  const textRef = useRef();

  const updateViewCountMutation = useUpdateViewCountMutation();

  const [textSize, setTextSize] = useState(0);
  const [contents, setContents] = useState("");
  const [isShare, toggleIsShare] = useExternalToggle({
    defaultValue: false,
    target: shareRef,
  });
  const [isText, toggleIsText] = useExternalToggle({
    defaultValue: false,
    target: textRef,
  });

  const isCalendar = path[0] === "calendar";
  const categoryCode = query.category ?? "";
  const isCoverage = categoryCode === "COM045_AspbHPe";
  const [cate1, _] = categoryCode.split("_");

  useEffect(() => {
    if (isSuccess) {
      let contents = data.article.contents;
      const ptRegex = /FONT-SIZE: (\d+)pt/gi; // 'g' flag for global search
      const pxRegex = /FONT-SIZE: (\d+)px/gi; // 'g' flag for global search

      contents = contents.replace(ptRegex, function (match, p1) {
        return `FONT-SIZE: calc(${p1}pt + ${textSize * 3}pt)`;
      });

      contents = contents.replace(pxRegex, function (match, p1) {
        return `FONT-SIZE: calc(${p1}px + ${textSize * 3}pt)`;
      });

      setContents(contents);
    }
  }, [textSize, isSuccess, data]);

  useEffect(() => {
    updateViewCountMutation(path[1]);
  }, [path[1]]);

  return isSuccess ? (
    <>
      <Box
        display={Box.DISPLAY.FLEX}
        width={"100%"}
        padding={"0 12px"}
        margin={"165px auto 40px"}
      >
        <Box
          width={"100%"}
          margin={"0"}
          padding={"20px 24px"}
          bgColor={Box.COLOR.LIGHTGRAY}
          borderRadius="10px"
        >
          <Box>
            <Text
              as={Text.VARIANT.TITLE}
              size={Text.SIZE.MOBILETITLE}
              margin={"10px 0 8px"}
            >
              {data.article.title}
            </Text>

            <NewsInfo
              margin={"8px 0 16px"}
              size={Text.SIZE.CLIENTCONTENTDETAIL}
              writer={data.article.writer}
              regDate={data.article.regDate}
              commentCnt={data.article.commentCnt}
              isMobile
            />
          </Box>
          <Box
            padding="10px 0 50px"
            borderTop="1px solid"
            borderBottom="1px solid"
            borderColor={Box.COLOR.LINEGRAY}
          >
            <Box
              display={Box.DISPLAY.FLEX}
              jc={"flex-end"}
              wrap={"wrap"}
              width={"100%"}
              position="relative"
              margin={"0 0 40px"}
            >
              <Box ref={textRef}>
                <Button
                  themeStyle={Button.THEME.NONE}
                  margin={"0 0 0 14px"}
                  onClick={toggleIsText}
                >
                  <Image
                    width={"24px"}
                    src={
                      isText
                        ? "/assets/detail/textActive.svg"
                        : "/assets/detail/text.svg"
                    }
                    alt={"text"}
                  />
                </Button>

                {isText && (
                  <TextSizeBox
                    size={textSize}
                    toggle={toggleIsText}
                    setSize={setTextSize}
                    isMobile
                  />
                )}
              </Box>
              <Box ref={shareRef}>
                <Button
                  themeStyle={Button.THEME.NONE}
                  margin={"0 0 0 14px"}
                  onClick={toggleIsShare}
                >
                  <Image
                    width={"24px"}
                    src={
                      isShare
                        ? "/assets/detail/shareActive.svg"
                        : "/assets/detail/share.svg"
                    }
                    alt={"share"}
                  />
                </Button>
                {isShare && (
                  <ShareBox data={data} toggle={toggleIsShare} isMobile />
                )}
              </Box>

              {(isCalendar || isCoverage) && (
                <Box
                  width={"100%"}
                  bgColor={Box.COLOR.WHITE}
                  borderRadius={"5px"}
                  padding={"12px 10px"}
                  margin={"20px 0 0"}
                >
                  <Text
                    as={Text.VARIANT.TEXT}
                    color={Text.COLOR.TEXTGRAY}
                    size={Text.SIZE.MOBILECONTENT}
                    isSort
                    width={"100% "}
                  >
                    <Text
                      as={Text.VARIANT.SPAN}
                      width={"68px"}
                      textAlign={"center"}
                      margin={"0 6px 0 0"}
                      weight={Text.WEIGHT.BOLD}
                      color={Text.COLOR.SECONDARY}
                      borderRadius={"60px"}
                    >
                      {isCoverage ? coverage.period : event.period}
                    </Text>
                    {data.article.eventBeginDate} ~ {data.article.eventEndDate}
                    {createPeriod(
                      data.article.eventBeginHour,
                      data.article.eventBeginMin,
                      data.article.eventEndHour,
                      data.article.eventEndMin
                    )}
                  </Text>
                  {!isCoverage && (
                    <>
                      <Text
                        as={Text.VARIANT.TEXT}
                        color={Text.COLOR.TEXTGRAY}
                        size={Text.SIZE.MOBILECONTENT}
                        isSort
                        width={"100% "}
                        margin={"8px 0"}
                      >
                        <Text
                          as={Text.VARIANT.SPAN}
                          width={"68px"}
                          textAlign={"center"}
                          margin={"0 6px 0 0"}
                          weight={Text.WEIGHT.BOLD}
                          color={Text.COLOR.SECONDARY}
                          borderRadius={"60px"}
                        >
                          {event.pre}
                        </Text>
                        {data.article.preBeginDate &&
                          `${data.article.preBeginDate} ~ ${data.article.preEndDate}`}
                      </Text>
                      <Text
                        as={Text.VARIANT.TEXT}
                        color={Text.COLOR.TEXTGRAY}
                        size={Text.SIZE.MOBILECONTENT}
                        isSort
                        width={"100% "}
                      >
                        <Text
                          as={Text.VARIANT.SPAN}
                          width={"68px"}
                          textAlign={"center"}
                          margin={"0 6px 0 0"}
                          weight={Text.WEIGHT.BOLD}
                          color={Text.COLOR.SECONDARY}
                          borderRadius={"60px"}
                        >
                          {event.place}
                        </Text>

                        {data.article.eventPlace}
                      </Text>
                    </>
                  )}
                </Box>
              )}
            </Box>
            <div
              className="detailContents"
              style={{
                fontSize: `calc(16px + ${textSize * 3}pt)`,
              }}
              dangerouslySetInnerHTML={{ __html: contents }}
            />
            {data.fileList.length > 0 && (
              <Box
                width={"100%"}
                margin={"80px 0 0"}
                padding={"14px"}
                border="1px solid"
                borderColor={Box.COLOR.LINEGRAY}
                borderRadius={"20px"}
                bgColor={Box.COLOR.WHITE}
              >
                <Text
                  as={Text.VARIANT.TEXT}
                  size={Text.SIZE.MOBILECONTENT}
                  weight={Text.WEIGHT.BOLD}
                >
                  {file}
                </Text>
                {data.fileList.map(
                  ({
                    fileId,
                    fileName,
                    filePath,
                    fileExtsn,
                    originFileName,
                  }) => (
                    <Box
                      display={Box.DISPLAY.FLEX}
                      wrap={"wrap"}
                      margin={"20px 0 0"}
                      gap={"0 30px"}
                      key={fileId}
                    >
                      <Button
                        themeStyle={Button.THEME.NONE}
                        onClick={() =>
                          downloadFile(
                            filePath,
                            fileName,
                            fileExtsn,
                            originFileName
                          )
                        }
                        margin={"0 0 12px 0"}
                      >
                        <Box display={Box.DISPLAY.FLEX}>
                          <BsPaperclip size={16} color={"#747474"} />
                          <Text
                            as={Text.VARIANT.SPAN}
                            size={Text.SIZE.MOBILECONTENT}
                            borderBottom={"1px solid"}
                            borderColor={Text.COLOR.LINEGRAY}
                            color={Text.COLOR.TEXTGRAY}
                            maxWidth={"200px"}
                            lineClamp={1}
                            wordBreak={"break-all"}
                          >
                            {originFileName.split(".")[0]}
                          </Text>
                          <Text
                            as={Text.VARIANT.SPAN}
                            size={Text.SIZE.MOBILECONTENT}
                            borderBottom={"1px solid"}
                            borderColor={Text.COLOR.LINEGRAY}
                            color={Text.COLOR.TEXTGRAY}
                          >
                            .{originFileName.split(".")[1]}
                          </Text>
                        </Box>
                      </Button>
                    </Box>
                  )
                )}
              </Box>
            )}
          </Box>

          <Comment
            commentCnt={data.article.commentCnt}
            commentOn={data.commentOnOff === "Y"}
          />
        </Box>
      </Box>

      <MobileSideNews />
    </>
  ) : (
    <></>
  );
};

export default MobileDetail;
