import Box from "components/atoms/box";
import Button from "components/atoms/button";
import Text from "components/atoms/text";
import useUrl from "hooks/utils/useUrl";
import React from "react";
import useDropdownStore from "store/useDropdownStore";

const ArticleTodayCountTable = (props) => {
  const { list } = props;

  const { changeLink } = useUrl();

  const { setActiveDropdownIdx } = useDropdownStore();

  const handleRouter = (href) => {
    changeLink(href);
    setActiveDropdownIdx(9);
  };

  return (
    <Box display={Box.DISPLAY.GRID} gridColumns="repeat(3, 1fr)">
      {list.map(({ title, count, href }, idx) => (
        <Box
          key={idx}
          borderLeft={idx === 0 ? "1px solid" : ""}
          borderRight="1px solid"
          borderTop="1px solid"
          borderBottom="1px solid"
          borderColor={Box.COLOR.LINEGRAY}
        >
          <Box
            display={Box.DISPLAY.FLEX}
            al="center"
            jc="center"
            borderBottom="1px solid"
            borderColor={Box.COLOR.LINEGRAY}
            bgColor={Box.COLOR.DASHBOARDBLUE}
            height="45px"
          >
            <Text
              as={Text.VARIANT.TEXT}
              size={Text.SIZE.CLIENTCONTENTDETAIL}
              weight={Text.WEIGHT.BOLD}
              ariaLabel={title}
            >
              {title}
            </Text>
          </Box>
          <Box display={Box.DISPLAY.FLEX} al="center" jc="center" height="57px">
            <Button themeStyle="none" onClick={() => handleRouter(href)}>
              <Text
                as={Text.VARIANT.SPAN}
                color={Text.COLOR.SECONDARY}
                weight={Text.WEIGHT.BOLD}
                size={Text.SIZE.ADMINDASHBOARD}
                textAlign="center"
                decoration="underline"
                ariaLabel={`${title}: ${count} 명`}
              >
                {count ?? 0}
              </Text>
              <Text
                as={Text.VARIANT.SPAN}
                size={Text.SIZE.CLIENTCONTENTDETAIL}
                color={Text.COLOR.SECONDARY}
                weight={Text.WEIGHT.BOLD}
                textAlign="center"
                ariaLabel={`${count} 명`}
              >
                명
              </Text>
            </Button>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default ArticleTodayCountTable;
