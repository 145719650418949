import { useState } from "react";
import { common } from "text/admin/common";

const { save } = common.alert.default;

const usePendingMutate = () => {
  const [isPending, setIsPending] = useState(false);

  const handleMutate = (mutate, args) => {
    const confirm = window.confirm(save);
    if (!confirm) return;

    setIsPending(true);
    mutate(args);
  };

  return { isPending, handleMutate };
};

export default usePendingMutate;
