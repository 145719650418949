import { adminDefaultUrl } from "api/admin/url";
import { GET, POST, PUT } from "api/config";

export const token = async () => await GET("/users/info");

export const login = async ({ userId, password }) =>
  await POST("/users/login", {
    userId,
    password,
  });

export const loginSns = async ({ userType, userId, userName }) =>
  await POST("/users/login/sns", {
    userType,
    userId,
    userName,
  });

export const logout = async () => await POST("/users/logout");

export const findId = async ({ userName, hp1, hp2, hp3 }) =>
  await POST("/users/find/id", { userName, hp1, hp2, hp3 });

export const findPw = async ({ userName, email1, email2, hp1, hp2, hp3 }) =>
  await POST("/users/find/password", {
    userName,
    email1,
    email2,
    hp1,
    hp2,
    hp3,
  });

export const changePassword = async ({ password, key }) =>
  await PUT(`/users/find/password?key=${key}`, { password });

export const sendEmailCode = async ({ email1, email2 }) =>
  await POST("/users/join/email/code", {
    email1,
    email2,
  });

export const checkEmailCode = async ({ email1, email2, code }) =>
  await POST("/users/join/email/check", {
    email1,
    email2,
    code,
  });

export const createMember = async ({
  email1,
  email2,
  password,
  userName,
  hp1,
  hp2,
  hp3,
  userId,
  newsYN,
}) =>
  await POST("/users/join/member", {
    email1,
    email2,
    password,
    userName,
    hp1,
    hp2,
    hp3,
    userId,
    newsYN,
  });

export const findAdminPw = async ({ userId, email1, email2 }) =>
  await PUT(`${adminDefaultUrl}/find/password`, { userId, email1, email2 });
