import React, { useEffect, useState } from "react";
import Box from "components/atoms/box";
import InputForm from "components/molecules/inputForm";
import CheckboxForm from "components/molecules/checkboxForm";
import Text from "components/atoms/text";
import RadioButton from "components/molecules/radioButton";
import { useFormContext } from "react-hook-form";

const LinkList = (props) => {
  const {
    radioName,
    inputName = "url",
    checkName = "newWindow",
    defaultCheckValue,
    defaultLink,
    required,
    setIsDisabled,
    setPopupNewWindow,
  } = props;

  const { setValue, watch } = useFormContext();

  const buttonList = [
    { title: "링크 사용", value: "Y", ariaLabel: "배너 링크 사용" },
    { title: "사용 안함", value: "N", ariaLabel: "배너 링크 사용 안함" },
  ];

  const [selectedRadioOption, setSelectedRadioOption] = useState("");

  const disableLink = selectedRadioOption === "N";

  const isNewWindow = watch(checkName);
  const currInputValue = watch(inputName);

  useEffect(() => {
    setValue(radioName, selectedRadioOption);
    setValue(inputName, currInputValue);
    setValue(checkName, isNewWindow);
    setPopupNewWindow && setPopupNewWindow(isNewWindow);
  }, [selectedRadioOption, isNewWindow, currInputValue]);

  useEffect(() => {
    setValue(checkName, defaultCheckValue === "Y" ? true : false);
  }, [defaultCheckValue, checkName]);

  useEffect(() => {
    if (setIsDisabled) setIsDisabled(disableLink);
  }, [selectedRadioOption]);

  useEffect(() => {
    if (!defaultLink) {
      setSelectedRadioOption("N");
    } else {
      setSelectedRadioOption("Y");
      setValue(inputName, defaultLink);
    }
  }, [defaultLink]);

  return (
    <Box display={Box.DISPLAY.FLEX} flexDirection="column" height="100%" padding="4px 0">
      <Box display={Box.DISPLAY.FLEX} al="center" gap="20px">
        <RadioButton
          list={buttonList}
          registerName={radioName}
          selectedRadioOption={selectedRadioOption}
          setSelectedRadioOption={setSelectedRadioOption}
          errorMessage="필수값 입니다."
          name={radioName}
          required
        />
      </Box>
      {!disableLink && (
        <>
          <InputForm
            name={inputName}
            type="text"
            width="430px"
            height="32px"
            padding="0 12px"
            margin="13px 0 8px 0"
            size="adminContentDetail"
            theme="secondary"
            de
            defaultName={defaultLink ?? ""}
            required={required}
            ariaLabel="배너 링크"
            placeholder="팝업 클릭 시 이동할 링크 입력"
          />
          <Box
            as="label"
            width="fit-content"
            display={Box.DISPLAY.FLEX}
            al="center"
            gap="5px"
            cursor="pointer"
          >
            <CheckboxForm
              name={checkName}
              Label={() => (
                <Text as={Text.VARIANT.TEXT} size={Text.SIZE.CLIENTCONTENTDETAIL}>
                  새창에서 열기
                </Text>
              )}
              ariaLabel="새창에서 열기 체크"
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default LinkList;
