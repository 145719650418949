import React from "react";
import Text from "components/atoms/text";
import Link from "components/atoms/link";
import Image from "components/atoms/image";
import NaverLogin from "react-login-by-naver";
import Button from "components/atoms/button";
import Box from "components/atoms/box";
import KakaoLogin from "components/atoms/kakao";
import LoginForm from "components/organisms/loginForm";
import axios from "axios";
import {
  LoginBox,
  LoginContainer,
} from "components/templates/login/login.style";
import useMobileStore from "store/useMobileStore";
import { useLoginSnsMutation } from "hooks/api/auth";
import useUrl from "hooks/utils/useUrl";
import useUserStore from "store/useUserStore";
import { useEffect } from "react";
import loginText from "text/login";

const { title, loginUtil, snsLogin, alert } = loginText;

const Login = () => {
  const { isMobile } = useMobileStore();
  const { changeLink } = useUrl();
  const { user, setUser } = useUserStore();

  const handleSuccessLogin = (userData) => {
    const redirect = localStorage.getItem("redirect") ?? "/";

    setUser(userData);
    changeLink(redirect);
  };

  const handleFailLogin = () => {
    window.alert(alert.fail);
  };
  const loginSnsMutation = useLoginSnsMutation(
    handleSuccessLogin,
    handleFailLogin
  );

  const handleKakaoLogin = async ({ access_token }) => {
    const {
      data: { kakao_account },
    } = await axios.get("https://kapi.kakao.com/v2/user/me", {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });

    const {
      email,
      profile: { nickname },
    } = kakao_account;

    const userType = "M0103";

    loginSnsMutation({
      userId: email,
      userType,
      userName: nickname,
    });
  };

  const handleNaverLogin = ({ email, name }) => {
    const userType = "M0102";

    loginSnsMutation({
      userId: email,
      userType,
      userName: name,
    });
  };

  // useEffect(() => {
  //   if (user) {
  //     changeLink("/");
  //   }
  // }, [user, changeLink]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    return () => {
      localStorage.removeItem("redirect");
    };
  }, []);

  return (
    <LoginContainer isMobile={isMobile}>
      <LoginBox>
        <Text
          as={Text.VARIANT.TEXT}
          size={isMobile ? Text.SIZE.MOBILETITLE : Text.SIZE.CLIENTCATEGORY}
          textAlign="center"
        >
          {title}
        </Text>

        <LoginForm
          borderRadius={isMobile ? "20px" : "15px"}
          isMobile={isMobile}
        />

        <Box display={Box.DISPLAY.FLEX} jc="center" al="center" gap="10px">
          <Link link="/find/id">
            <Text
              as={Text.VARIANT.TEXT}
              textAlign="center"
              size={
                isMobile
                  ? Text.SIZE.MOBILECONTENT
                  : Text.SIZE.CLIENTCONTENTDETAIL
              }
              ariaLabel="아이디 찾기"
              color={Text.COLOR.TEXTGRAY}
            >
              {loginUtil.findId}
            </Text>
          </Link>

          <Link link="/find/pw">
            <Text
              as={Text.VARIANT.TEXT}
              textAlign="center"
              size={
                isMobile
                  ? Text.SIZE.MOBILECONTENT
                  : Text.SIZE.CLIENTCONTENTDETAIL
              }
              ariaLabel="비밀번호 찾기"
              color={Text.COLOR.TEXTGRAY}
              padding={"0 10px"}
              borderLeft={"1px solid"}
              borderRight={"1px solid"}
              borderColor={Box.COLOR.LINEGRAY}
            >
              {loginUtil.findPw}
            </Text>
          </Link>

          <Link link="/signup">
            <Text
              as={Text.VARIANT.TEXT}
              textAlign="center"
              size={
                isMobile
                  ? Text.SIZE.MOBILECONTENT
                  : Text.SIZE.CLIENTCONTENTDETAIL
              }
              ariaLabel="회원가입 "
              color={Text.COLOR.TEXTGRAY}
            >
              {loginUtil.signup}
            </Text>
          </Link>
        </Box>

        <Box
          display={Box.DISPLAY.FLEX}
          jc={"space-between"}
          margin={"50px 0 30px"}
        >
          <Box
            width={isMobile ? "90px" : "124px"}
            height={"11px"}
            borderBottom={"1px solid"}
            borderColor={Box.COLOR.LINEGRAY}
          ></Box>
          <Text
            as={Text.VARIANT.TEXT}
            color={Text.COLOR.TEXTGRAY}
            size={Text.SIZE.CLIENTETC}
            lineHeight={"22px"}
          >
            {snsLogin}
          </Text>
          <Box
            width={isMobile ? "90px" : "124px"}
            height={"11px"}
            borderBottom={"1px solid"}
            borderColor={Box.COLOR.LINEGRAY}
          ></Box>
        </Box>

        <KakaoLogin
          success={handleKakaoLogin}
          token={"d2a77a40fc11dda83f5984e99387eac2"}
          render={() => {
            return (
              <Button
                themeStyle={Button.THEME.NONE}
                width={"100%"}
                height={isMobile ? "36px" : "48px"}
                margin={"0 0 10px"}
              >
                <Image
                  src={"/assets/login/kakaoLogin.png"}
                  width={"100%"}
                  height={isMobile ? "36px" : "48px"}
                  radius={isMobile ? "20px" : "15px"}
                  fit="cover"
                  alt="카카오 로그인"
                />
              </Button>
            );
          }}
        />

        <NaverLogin
          clientId="jGrRw08li61BwsW9l36N"
          callbackUrl={`${window.location.origin}/login`}
          render={(props) => (
            <Button
              onClick={props.onClick}
              themeStyle={Button.THEME.NONE}
              width={"100%"}
              height={isMobile ? "36px" : "48px"}
            >
              <Image
                src={"/assets/login/naverLogin.png"}
                width={"100%"}
                height={isMobile ? "36px" : "48px"}
                radius={isMobile ? "20px" : "15px"}
                fit="cover"
                alt="네이버 로그인"
              />
            </Button>
          )}
          onSuccess={handleNaverLogin}
        />
      </LoginBox>
    </LoginContainer>
  );
};

export default Login;
