import Box from "components/atoms/box";
import Image from "components/atoms/image";
import Text from "components/atoms/text";
import React from "react";
import { useFormContext } from "react-hook-form";
import Input from "components/atoms/input";
import Tooltip from "components/molecules/tooltip";
import { useEffect } from "react";
import { category } from "text/admin/category";

const ListViewButtonBundle = (props) => {
  const { list, categoryName, codeName } = props;

  const { displayTypeList, exposureText, defaultText } = category.detail.listview;

  const { register, watch, setValue } = useFormContext();

  const disabledPhotoView = categoryName === "calendar" || codeName === "과총 동정" ? true : false;

  useEffect(() => {
    if (list) {
      setValue("listviewList", list.listviewList);
      setValue("listviewBasic", list.listviewBasic);
    }
  }, [list]);

  const currCheckedList = watch("listviewList");

  const checkboxComponents = displayTypeList.map(({ title, value, ariaLabel }, idx) => {
    if (disabledPhotoView && value === "photo") return;
    return (
      <Box as="label" height="100%" display={Box.DISPLAY.FLEX} al="center" gap="6px" key={idx}>
        <Input
          {...register("listviewList", value)}
          theme="CHECKBOX"
          type="checkbox"
          className="peer"
          name="listviewList"
          value={value}
          ariaLabel={ariaLabel}
          marign="0"
        />
        <Text as={Text.VARIANT.SPAN} size={Text.SIZE.ADMINCONTENT}>
          {title}
        </Text>
      </Box>
    );
  });

  const radioboxComponents = displayTypeList.map(({ title, value, ariaLabel }, idx) => {
    if (disabledPhotoView && value === "photo") return;
    return (
      <Box as="label" height="100%" display={Box.DISPLAY.FLEX} al="center" gap="6px" key={idx}>
        <Input
          {...register("listviewBasic", value)}
          theme="RADIO"
          type="radio"
          className="peer"
          name="listviewBasic"
          value={value}
          ariaLabel={ariaLabel}
          disabled={currCheckedList.includes(value) ? false : true}
          marign="0"
        />
        <Text as={Text.VARIANT.SPAN} size={Text.SIZE.ADMINCONTENT}>
          {title}
        </Text>
      </Box>
    );
  });

  const buttonList = [
    {
      title: "노출 리스트뷰 설정",
      tooltipText: exposureText,
      tooltipWidth: "317px",
      components: checkboxComponents,
    },
    {
      title: "기본 리스트뷰 설정",
      tooltipText: defaultText,
      tooltipWidth: "386px",
      components: radioboxComponents,
    },
  ];

  return (
    <Box
      display={Box.DISPLAY.FLEX}
      flexDirection="column"
      jc="center"
      height="100%"
      border="1px solid"
      borderColor={Box.COLOR.LINEGRAY}
    >
      {buttonList.map(({ title, tooltipText, tooltipWidth, components }, idx) => (
        <Box
          display={Box.DISPLAY.FLEX}
          al="center"
          height="38px"
          key={idx}
          borderBottom={idx === 1 ? "none" : "1px solid"}
          borderColor={Box.COLOR.LINEGRAY}
        >
          <Box
            display={Box.DISPLAY.FLEX}
            al="center"
            gap="5px"
            height="100%"
            padding="0 25px 0 13px"
            borderRight="1px solid"
            borderColor={Box.COLOR.LINEGRAY}
          >
            <Text size={Text.SIZE.ADMINCONTENT} as={Text.VARIANT.TEXT}>
              {title}
            </Text>
            <Tooltip text={tooltipText} width={tooltipWidth}>
              <Image src="/assets/admin/comment/question.png" />
            </Tooltip>
          </Box>
          <Box>
            <Box display={Box.DISPLAY.FLEX} al="center" gap="5px" cursor="pointer" padding="0 17px">
              {components}
            </Box>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default ListViewButtonBundle;
