import { DatePicker } from "antd";
import locale from "antd/es/date-picker/locale/ko_KR";
import Box from "components/atoms/box";
import Image from "components/atoms/image";
import Text from "components/atoms/text";
import TimeList from "components/molecules/timeList";
import dayjs from "dayjs";
import React from "react";
import { useEffect } from "react";
import usePopupStore from "store/usePopupStore";
import convertDateWithHyphen from "utils/convertDateWithHyphen";

const AdminDatePicker = (props) => {
  const { list } = props;

  const today = dayjs();

  const [startDate, endDate] = [list?.startDate, list?.endDate];

  const {
    selectedStartDate,
    setSelectedStartDate,
    selectedEndDate,
    setSelectedEndDate,
    selectedStartHours,
    selectedStartMinutes,
    selectedEndHours,
    selectedEndMinutes,
    setSelectedStartHours,
    setSelectedEndHours,
    setSelectedStartMinutes,
    setSelectedEndMinutes,
  } = usePopupStore();

  const defaultStartHour = !isNaN(parseInt(startDate?.substr(8, 2)))
    ? startDate?.substr(8, 2)
    : null;
  const defaultStartMinute = !isNaN(parseInt(startDate?.substr(10, 2)))
    ? startDate?.substr(10, 2)
    : null;
  const defaultEndHour = !isNaN(parseInt(endDate?.substr(8, 2))) ? endDate?.substr(8, 2) : null;
  const defaultEndMinute = !isNaN(parseInt(endDate?.substr(10, 2))) ? endDate?.substr(10, 2) : null;

  useEffect(() => {
    const defaultDate = today.format("YYYYMMDD");
    const startHours = defaultStartHour ?? selectedStartHours;
    const startMinutes = defaultStartMinute ?? selectedStartMinutes;
    const endHours = defaultEndHour ?? selectedEndHours;
    const endMinutes = defaultEndMinute ?? selectedEndMinutes;

    setSelectedStartDate(dayjs(convertDateWithHyphen(startDate ?? defaultDate), "YYYY-MM-DD"));
    setSelectedEndDate(dayjs(convertDateWithHyphen(endDate ?? defaultDate), "YYYY-MM-DD"));
    setSelectedStartHours(startHours);
    setSelectedStartMinutes(startMinutes);
    setSelectedEndHours(endHours);
    setSelectedEndMinutes(endMinutes);
  }, [startDate, endDate]);

  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setSelectedEndDate(date);
  };

  const dateFormat = "YYYY-MM-DD";

  return (
    <Box display={Box.DISPLAY.FLEX} al="center" gap="15px">
      <Box display={Box.DISPLAY.FLEX} al="center" gap="15px">
        <Box as="label" display={Box.DISPLAY.FLEX} al="center" gap="6px">
          <DatePicker
            locale={locale}
            format={dateFormat}
            onChange={handleStartDateChange}
            value={selectedStartDate}
          />
          <Image src="/assets/admin/calendarIcon.png" width="24px" height="24px" />
        </Box>
        <Box>
          <TimeList
            selectedHours={selectedStartHours}
            setSelectedHours={setSelectedStartHours}
            selectedMinutes={selectedStartMinutes}
            setSelectedMinutes={setSelectedStartMinutes}
          />
        </Box>
      </Box>
      <Text as={Text.VARIANT.SPAN}>~</Text>
      <Box display={Box.DISPLAY.FLEX} al="center" gap="15px">
        <Box as="label" display={Box.DISPLAY.FLEX} al="center" gap="6px">
          <DatePicker
            locale={locale}
            format={dateFormat}
            onChange={handleEndDateChange}
            value={selectedEndDate}
          />
          <Image src="/assets/admin/calendarIcon.png" width="24px" height="24px" />
        </Box>
        <Box>
          <TimeList
            selectedHours={selectedEndHours}
            setSelectedHours={setSelectedEndHours}
            selectedMinutes={selectedEndMinutes}
            setSelectedMinutes={setSelectedEndMinutes}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default AdminDatePicker;
