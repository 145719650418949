import AdminManagerList from "components/organisms/adminManagerList";
import SearchCondition from "components/organisms/searchCondition";
import { AdminContentsContainer } from "components/templates/admin/admin.style";
import useUrl from "hooks/utils/useUrl";
import React, { useState } from "react";
import { management } from "text/admin/management";

const ManagementManager = () => {
  const { options, defaultValues } = management.manager;

  const { pathname, changeLink } = useUrl();

  const [selectedMenu, setSelectedMenu] = useState("userId");
  const [searchValue, setSearchValue] = useState("");

  const onSubmit = (data) => {
    const { searchIndex } = data;
    setSearchValue(searchIndex);
    changeLink(pathname);
  };

  return (
    <AdminContentsContainer>
      <SearchCondition registerName="searchIndex" onSubmit={onSubmit} defaultValues={defaultValues}>
        <SearchCondition.Select
          title="검색 분류"
          registerName="searchIndex"
          selectedMenu={selectedMenu}
          setSelectedMenu={setSelectedMenu}
          options={options}
          width="120px"
        />
      </SearchCondition>
      <AdminManagerList searchCondition={selectedMenu} searchValue={searchValue} />
    </AdminContentsContainer>
  );
};

export default ManagementManager;
