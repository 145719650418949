import { css } from "styled-components";

const swiper = css`
  /* .swiper-pagination {
    position: absolute;
    bottom: 35px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
  }

  .swiper-pagination-bullet {
    color: ${(props) => props.theme.color.white};
    background-color: ${(props) => props.theme.color.transparent};
    border: none;

    cursor: pointer;

    .active {
      display: none;
    }
  }

  .swiper-pagination-bullet-active {
    .active {
      display: block;
    }
    .common {
      display: none;
    }
  } */
`;

export default swiper;
