import Box from "components/atoms/box";
import Button from "components/atoms/button";
import Text from "components/atoms/text";
import AdminDetailEditButton from "components/molecules/adminDetailEditButton";
import AdminEmailEditButton from "components/molecules/adminEmailEditButton";
import dayjs from "dayjs";
import useContextForm from "hooks/utils/useContextForm";
import useUrl from "hooks/utils/useUrl";
import React, { forwardRef, useEffect } from "react";
import useEventStore from "store/useEventStore";
import useTemporaryStore from "store/useTemporaryStore";
import convertDateWithHyphen from "utils/convertDateWithHyphen";

const AdminDetailFormContainer = forwardRef((props, ref) => {
  const {
    children,
    title,
    defaultValues,
    handleSubmit,
    isHidden = false,
    isPending = false,
    popupPreview = false,
    handlePreview,
    isEmailView = false,
    //
    setDepth1Menu,
    setDepth2Menu,
    regDate,
    eventDates,
    setPreSelectedStartDate,
    setPreSelectedEndDate,
    hasTemporaryFunc,
  } = props;

  const {
    setSelectedStartDate,
    setSelectedStartHours,
    setSelectedStartMinutes,
    setSelectedEndDate,
    setSelectedEndHours,
    setSelectedEndMinutes,
  } = useEventStore();

  const { methods, Provider } = useContextForm(defaultValues);
  const { setHasTempData } = useTemporaryStore();

  const {
    path,
    query: { key },
  } = useUrl();

  const currPage = path[1];

  const hasTempoSaveFunc = ["newsboard", "schedule", "science"].includes(currPage);

  const handleTemporarySave = (e) => {
    e.preventDefault();
    const confirm = window.confirm(
      "임시저장 하시겠습니까?\n파일 및 이미지는 임시저장 되지 않습니다."
    );
    if (!confirm) return;

    const currentData = {
      ...methods.getValues(),
      contents: ref.current.GetBodyValue(),
      ...getDateInformation(regDate, eventDates),
    };

    localStorage.setItem(currPage, JSON.stringify(currentData));
    alert("임시저장 되었습니다.");
  };

  const getDateInformation = (regDate, eventDates) => {
    if (regDate) {
      return {
        regDate: convertDateWithHyphen(regDate),
        regHour: regDate.substr(8, 2),
        regMinute: regDate.substr(10, 2),
      };
    } else {
      return eventDates;
    }
  };

  useEffect(() => {
    if (!hasTempoSaveFunc || key) return;

    const loadTemporaryData = () => {
      const tempData = localStorage.getItem(currPage);
      if (!tempData) return;

      setTimeout(() => {
        const confirmLoad = window.confirm("기존에 저장된 데이터가 있습니다.\n불러오시겠습니까?");
        if (!confirmLoad) return;

        setHasTempData(true);
        const parsedData = JSON.parse(tempData);
        setFormData(parsedData);
      }, 500);
    };

    const setFormData = (parsedData) => {
      if (currPage === "newsboard") {
        setDepth1Menu(parsedData.cate1);
        setDepth2Menu(parsedData.cate2);
        setSelectedEndDate(dayjs(parsedData.regDate));
        setSelectedEndHours(parsedData.regHour);
        setSelectedEndMinutes(parsedData.regMinute);
      } else if (currPage === "science") {
        setSelectedStartDate(dayjs(parsedData.eventBeginDate));
        setSelectedStartHours(parsedData.eventBeginHour);
        setSelectedStartMinutes(parsedData.eventBeginMin);
        setSelectedEndDate(dayjs(parsedData.eventEndDate));
        setSelectedEndHours(parsedData.eventEndHour);
        setSelectedEndMinutes(parsedData.eventEndMin);
        setPreSelectedStartDate(dayjs(parsedData.preBeginDate));
        setPreSelectedEndDate(dayjs(parsedData.preEndDate));
      } else {
        setSelectedStartDate(dayjs(parsedData.eventBeginDate));
        setSelectedEndDate(dayjs(parsedData.eventEndDate));
      }

      Object.keys(parsedData).forEach((key) => {
        methods.setValue(key, parsedData[key]);
      });
    };

    loadTemporaryData();

    return () => {
      const today = dayjs();
      setSelectedStartDate(today);
      setSelectedEndDate(today);
      setSelectedStartHours("00");
      setSelectedStartMinutes("00");
      setSelectedEndHours("00");
      setSelectedEndMinutes("00");
      setHasTempData(false);
    };
  }, [
    currPage,
    hasTempoSaveFunc,
    methods,
    setDepth1Menu,
    setDepth2Menu,
    setHasTempData,
    setSelectedEndDate,
  ]);

  return (
    <>
      <Text as={Text.VARIANT.SUBTITLE} margin="0 0 10px 0">
        {title}
      </Text>
      <Box bgColor={Box.COLOR.WHITE}>
        <Box
          display={popupPreview ? Box.DISPLAY.FLEX : Box.DISPLAY.BLOCK}
          jc={popupPreview ? "space-between" : "flex-start"}
          al="center"
          padding="29px 21px"
          border="1px solid"
          borderColor={Box.COLOR.ADMINLINEGRAY}
        >
          <Box>
            <Text as={Text.VARIANT.SPAN} color={Text.COLOR.SECONDARY}>
              *은
            </Text>{" "}
            <Text as={Text.VARIANT.SPAN} color={Text.COLOR.SECONDARY} weight={Text.WEIGHT.BOLD}>
              필수 입력 항목
            </Text>
            <Text as={Text.VARIANT.SPAN} color={Text.COLOR.SECONDARY}>
              입니다.
            </Text>
          </Box>
          {popupPreview && (
            <Button
              themeStyle="common"
              padding="8px 14px"
              border="1px solid"
              borderColor={Button.COLOR.SECONDARY}
              color={Button.COLOR.SECONDARY}
              onClick={handlePreview}
            >
              미리보기
            </Button>
          )}
        </Box>
        <Provider>
          <Box as="form" onSubmit={methods.handleSubmit(handleSubmit)} noValidate>
            {children}
            {!isHidden && (
              <AdminDetailEditButton
                isPending={isPending}
                handleTemporarySave={handleTemporarySave}
                hasTemporaryFunc={hasTemporaryFunc}
              />
            )}
            {isEmailView && <AdminEmailEditButton isPending={isPending} />}
          </Box>
        </Provider>
      </Box>
    </>
  );
});

export default AdminDetailFormContainer;
