import React, { useRef, useState } from "react";
import Box from "components/atoms/box";
import Link from "components/atoms/link";
import Image from "components/atoms/image";
import Slider from "components/atoms/slider";
import Text from "components/atoms/text";
import { useGetBanner } from "hooks/api/banner";
import convertImage from "utils/convertImage";

const MobileMainBanner = () => {
  const sliderRef = useRef(null);
  const [activeSlide, setActiveSlide] = useState(0);

  const { list, isSuccess } = useGetBanner({
    type: "slide-mobile",
  });

  return (
    isSuccess && (
      <Box position={"relative"} width={"100%"} ariaLabel="최상단 메인 배너">
        <Slider
          direction={"horizontal"}
          slidesPerView={1}
          loop
          isAutoplay={false}
          simulateTouch={true}
          setActiveIndex={setActiveSlide}
          sliderRef={sliderRef}
        >
          {list.map(({ key, filePath }, index) => (
            <Slider.Slide key={key}>
              <Box aspectRatio={"375/240"} overflow="hidden">
                <Link width={"100%"} height={"100%"} target="_blank">
                  <Image
                    src={convertImage(filePath)}
                    alt="banner"
                    width="100%"
                    height="100%"
                    fit="cover"
                    ariaLabel={`${index + 1}번째 최상단 메인 배너
                `}
                  />
                </Link>
              </Box>
            </Slider.Slide>
          ))}
        </Slider>

        <Box
          display={Box.DISPLAY.FLEX}
          jc={"center"}
          al={"center"}
          position={"absolute"}
          bottom={"12px"}
          right={"24px"}
          zIndex={Box.LEVEL.MIDDLE}
          width={"54px"}
          height={"16px"}
          bgColor={Box.COLOR.SHADOW}
          borderRadius={"20px"}
        >
          <Text
            as={Text.VARIANT.TEXT}
            color={Text.COLOR.WHITE}
            size={Text.SIZE.MOBILEETC}
          >
            {activeSlide + 1}
          </Text>
          <Box
            width={"1px"}
            height={"8px"}
            bgColor={Box.COLOR.LINEGRAY}
            margin={"0 8px"}
          />
          <Text
            as={Text.VARIANT.TEXT}
            color={Text.COLOR.TEXTGRAY}
            size={Text.SIZE.MOBILEETC}
          >
            {list.length}
          </Text>
        </Box>
      </Box>
    )
  );
};

export default MobileMainBanner;
