import React, { forwardRef } from "react";
import Input from "components/atoms/input";

const CheckBox = forwardRef((props, ref) => {
  const {
    checked,
    value,
    size = "16px",
    ariaLabel,
    borderColor = Input.COLOR.ADMINLINEGRAY,
    ...register
  } = props;

  return (
    <Input
      type="checkbox"
      checked={checked}
      value={value}
      borderColor={borderColor}
      ariaLabel={ariaLabel}
      ref={ref}
      {...register}
    />
  );
});

export default CheckBox;
