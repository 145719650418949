import Box from "components/atoms/box";
import Text from "components/atoms/text";
import React from "react";
import convertDayWithTime from "utils/convertDayWithTime";
import HasNotResult from "../HasNotResult";
import useUrl from "hooks/utils/useUrl";

const LoginLogTable = (props) => {
  const { list, tableHeader } = props;

  const {
    query: { page },
  } = useUrl();

  const currPage = parseInt(page);

  return (
    <>
      <Box
        display={Box.DISPLAY.FLEX}
        height="32px"
        borderTop="1px solid"
        borderColor={Box.COLOR.LINEGRAY}
      >
        {tableHeader.map(({ title, width, ariaLabel }, idx) => (
          <Box
            width={width}
            padding="8px 0"
            key={idx}
            borderLeft="1px solid"
            borderRight={idx === tableHeader.length - 1 ? "1px solid" : ""}
            borderBottom={list.length === 0 ? "1px solid" : ""}
            borderColor={Box.COLOR.LINEGRAY}
            bgColor={Box.COLOR.ADMINCONTENTSBG}
          >
            <Text
              as={Text.VARIANT.TEXT}
              size={Text.SIZE.CLIENTCONTENTDETAIL}
              weight={Text.WEIGHT.BOLD}
              textAlign="center"
              ariaLabel={ariaLabel}
            >
              {title}
            </Text>
          </Box>
        ))}
      </Box>
      {list.length === 0 && <HasNotResult />}
      {list.length > 0 &&
        list.map(({ conectIp, loginDate, logoutDate, userId, userName }, idx) => {
          const pageIdx = currPage > 1 ? (currPage - 1) * 10 + (idx + 1) : idx + 1;

          return (
            <Box
              key={idx}
              borderTop={idx === 0 ? "1px solid" : ""}
              borderBottom="1px solid"
              borderColor={Box.COLOR.LINEGRAY}
              display={Box.DISPLAY.FLEX}
              height="46px"
            >
              <Box
                width="6%"
                display={Box.DISPLAY.FLEX}
                jc="center"
                al="center"
                borderLeft="1px solid"
                borderColor={Box.COLOR.LINEGRAY}
              >
                <Text
                  as={Text.VARIANT.TEXT}
                  size={Text.SIZE.CLIENTCONTENTDETAIL}
                  textAlign="center"
                  ariaLabel={`로그인 현황 목록 번호 : ${pageIdx}`}
                >
                  {pageIdx}
                </Text>
              </Box>
              <Box
                width="24%"
                display={Box.DISPLAY.FLEX}
                jc="center"
                al="center"
                borderLeft="1px solid"
                borderColor={Box.COLOR.LINEGRAY}
                padding="0 10px"
              >
                <Text
                  as={Text.VARIANT.TEXT}
                  size={Text.SIZE.CLIENTCONTENTDETAIL}
                  textAlign="center"
                  ariaLabel={`성명/아이디: ${userName}(${userId})`}
                >
                  {userName} / {userId}
                </Text>
              </Box>
              <Box
                width="20%"
                display={Box.DISPLAY.FLEX}
                jc="center"
                al="center"
                borderLeft="1px solid"
                borderColor={Box.COLOR.LINEGRAY}
              >
                <Text
                  as={Text.VARIANT.TEXT}
                  size={Text.SIZE.CLIENTCONTENTDETAIL}
                  textAlign="center"
                  ariaLabel={`로그인 시간 : ${convertDayWithTime(loginDate)}`}
                >
                  {convertDayWithTime(loginDate)}
                </Text>
              </Box>
              <Box
                width="20%"
                display={Box.DISPLAY.FLEX}
                jc="center"
                al="center"
                borderLeft="1px solid"
                borderColor={Box.COLOR.LINEGRAY}
              >
                <Text
                  as={Text.VARIANT.TEXT}
                  size={Text.SIZE.CLIENTCONTENTDETAIL}
                  textAlign="center"
                  ariaLabel={`로그아웃 시간 : ${
                    convertDayWithTime(logoutDate) ?? "로그아웃 기록 없음"
                  }`}
                >
                  {convertDayWithTime(logoutDate) ?? ""}
                </Text>
              </Box>
              <Box
                width="30%"
                display={Box.DISPLAY.FLEX}
                jc="center"
                al="center"
                borderLeft="1px solid"
                borderRight="1px solid"
                borderColor={Box.COLOR.LINEGRAY}
              >
                <Text
                  as={Text.VARIANT.TEXT}
                  size={Text.SIZE.CLIENTCONTENTDETAIL}
                  textAlign="center"
                  ariaLabel={`성명/아이디: ${userName}(${userId})`}
                >
                  {conectIp}
                </Text>
              </Box>
            </Box>
          );
        })}
    </>
  );
};

export default LoginLogTable;
