import styled, { css } from "styled-components";

const theme = {
  primary: css`
    background-color: ${(props) => props.theme.color.primary};
    color: ${(props) => props.theme.color.white};

    border: 1px solid ${(props) => props.theme.color.primary};
    border-radius: ${(props) => props.theme.radius.normal};
  `,
  secondary: css`
    background-color: ${(props) => props.theme.color.secondary};
    color: ${(props) => props.theme.color.white};

    border: 1px solid ${(props) => props.theme.color.secondary};
    border-radius: ${(props) => props.theme.radius.normal};
  `,
  common: css`
    background-color: ${(props) => props.theme.color.white};
    color: ${(props) => props.theme.color.black};

    border: 1px solid ${(props) => props.theme.color.black};
    border-radius: ${(props) => props.theme.radius.normal};
  `,
  none: css`
    background-color: transparent;
    color: ${(props) => props.theme.color.black};

    border: none;

    padding: 0;
  `,
  disabled: css`
    background-color: ${(props) => props.theme.color.gray};
    color: ${(props) => props.theme.color.white};

    border: 1px solid ${(props) => props.theme.color.gray};
    border-radius: ${(props) => props.theme.radius.normal};
  `,
  category: css`
    font-size: ${(props) => props.theme.font.clientCategory};

    color: ${(props) => props.theme.color.black};

    border: none;
    padding: 12px 0px;

    background-color: transparent;

    ${(props) =>
      props.isActive &&
      css`
        color: ${props.theme.color.white};
        font-weight: ${props.theme.weight.bold};

        background-color: ${props.theme.color.primary};
        border-radius: 0 20px;
      `}
  `,
  sideBarMenu: css`
    background-color: transparent;
    border: none;
  `,
  adminCommon: css`
    background-color: transparent;
    color: ${(props) => props.theme.color.black};

    border: 1px solid;
    border-color: ${(props) => props.theme.color.lineGray};
    border-radius: ${(props) => props.theme.radius.small};

    &:hover {
      color: ${(props) => props.theme.color.secondary};
    }

    ${(props) =>
      props.isActive &&
      css`
        color: ${props.theme.color.secondary};
        border: 1px solid ${props.theme.color.secondary};
        background-color: rgba(58, 71, 184, 0.2);
      `}
  `,
  adminCancel: css`
    background-color: ${(props) => props.theme.color.lineGray};
    color: ${(props) => props.theme.color.white};

    border: 1px solid;
    border-color: ${(props) => props.theme.color.lineGray};
    border-radius: ${(props) => props.theme.radius.small};

    font-weight: ${(props) => props.theme.weight.bold};

    &:hover {
      color: ${(props) => props.theme.color.secondary};
    }
  `,
  adminSave: css`
    background-color: ${(props) => props.theme.color.secondary};
    color: ${(props) => props.theme.color.white};

    border: 1px solid ${(props) => props.theme.color.secondary};
    border-radius: ${(props) => props.theme.radius.normal};

    font-weight: ${(props) => props.theme.weight.bold};

    &:hover {
      background-color: ${(props) => props.theme.color.white};
      color: ${(props) => props.theme.color.secondary};
    }
  `,
};

const sort = {
  between: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  around: css`
    display: flex;
    justify-content: space-around;
  `,
  center: css`
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  start: css`
    display: flex;
    justify-content: flex-start;
    align-items: center;
  `,
  end: css`
    display: flex;
    justify-content: flex-end;
  `,
};

const size = {
  small: css`
    width: 100px;
  `,
  medium: css`
    width: 200px;
  `,
  large: css`
    width: 300px;
  `,
  full: css`
    width: 100%;
  `,
};

export const ButtonComponent = styled.button`
  ${(props) => theme[props.themeStyle]};
  ${(props) => sort[props.sort]};
  ${(props) => size[props.size]};

  display: ${(props) => props.$display};
  align-items: ${(props) => props.al};
  justify-content: ${(props) => props.jc};

  position: ${(props) => props.position};
  top: ${(props) => props.top};
  bottom: ${(props) => props.bottom};
  left: ${(props) => props.left};
  right: ${(props) => props.right};

  width: ${(props) => props.$width};
  max-width: ${(props) => props.maxWidth};
  height: ${(props) => props.$height};

  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  background-color: ${(props) =>
    props.theme.color[props.bgColor] ?? props.bgColor};

  font-size: ${(props) => props.theme.font[props.$fontSize]};
  font-weight: ${(props) => props.theme.weight[props.$fontWeight]};

  color: ${(props) => props.theme.color[props.$color]};

  border: ${(props) => props.border};
  border-top: ${(props) => props.borderTop};
  border-bottom: ${(props) => props.borderBottom};
  border-left: ${(props) => props.borderLeft};
  border-right: ${(props) => props.borderRight};

  border-radius: ${(props) =>
    props.theme.radius[props.borderRadius] ?? props.borderRadius};
  border-color: ${(props) => props.theme.color[props.borderColor]};

  cursor: pointer;
`;
