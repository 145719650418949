import Box from "components/atoms/box";
import RadioButton from "components/molecules/radioButton";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

const ListView = (props) => {
  const { buttonList, registerName, isRenderedListView, name, required = true, disabled } = props;

  const { setValue } = useFormContext();

  const [selectedRadioOption, setSelectedRadioOption] = useState("");

  useEffect(() => {
    setSelectedRadioOption(isRenderedListView);
  }, [isRenderedListView]);

  useEffect(() => {
    setValue(registerName, selectedRadioOption);
  }, [selectedRadioOption]);

  return (
    <Box display={Box.DISPLAY.FLEX} al="center" gap="20px" height="100%">
      <RadioButton
        list={buttonList}
        registerName={registerName}
        selectedRadioOption={selectedRadioOption}
        setSelectedRadioOption={setSelectedRadioOption}
        errorMessage="필수값 입니다."
        name={name}
        required={required}
        disabled={disabled}
      />
    </Box>
  );
};

export default ListView;
