import React, { useCallback } from "react";
import { FormProvider, useForm } from "react-hook-form";

const useContextForm = (defaultValues) => {
  const methods = useForm({ defaultValues });

  const Provider = useCallback(
    ({ children }) => {
      return <FormProvider {...methods}>{children}</FormProvider>;
    },
    [methods]
  );

  return {
    Provider,
    methods,
  };
};

export default useContextForm;
