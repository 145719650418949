import Box from "components/atoms/box";
import React from "react";
import DashboardVisitorCount from "components/molecules/dashboardVisitorCount";
import DashboardRankList from "components/molecules/dashboardRankList";

const DashboardContents = () => {
  return (
    <Box maxWidth="1024px" margin="40px 0 0 0" display={Box.DISPLAY.FLEX} al="center" gap="20px">
      <DashboardVisitorCount />
      <DashboardRankList />
    </Box>
  );
};

export default DashboardContents;
