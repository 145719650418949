import Box from "components/atoms/box";
import Button from "components/atoms/button";
import Input from "components/atoms/input";
import Text from "components/atoms/text";
import ModalContainer from "components/molecules/modalContainer";
import SelectMenu from "components/molecules/selectMenu";
import { useCategoryList } from "hooks/api/admin/category";
import { useChangeArticleCategory } from "hooks/api/admin/news_board";
import useContextForm from "hooks/utils/useContextForm";
import React, { useState } from "react";
import { useEffect } from "react";
import { forwardRef } from "react";
import { common } from "text/admin/common";
import { newsboard } from "text/admin/newsboard";

const MovementArticle = forwardRef((props, ref) => {
  const { width, height, setIsOpen, currCate1, currCate2, keyList } = props;

  const { mainTitle, subTitle, desc, defaultValues, cate1Name, depth1Options, code } =
    newsboard.movementModal;
  const {
    default: { movement },
    failed: { movement: failedMovement },
  } = common.alert;

  const isMixedCategory = currCate2.length > 1;

  const { methods, Provider } = useContextForm(defaultValues);

  const [depth1Menu, setDepth1Menu] = useState("");
  const [depth2Menu, setDepth2Menu] = useState("");
  const [depth2Options, setDepth2Options] = useState([]);

  const { list } = useCategoryList({ categoryName: depth1Menu });

  const mutate = useChangeArticleCategory(setIsOpen);

  useEffect(() => {
    if (list) {
      setDepth2Options(
        list?.map(({ categoryName, key }) => ({
          title: categoryName,
          value: key,
        }))
      );
    }
  }, [list]);

  const onSubmit = (data) => {
    const { cate1, cate2 } = data;
    if (!code[cate1] || cate2 === "") {
      alert(failedMovement);
      return;
    }
    const submitData = {
      cate1: code[cate1],
      cate2,
      keyList,
    };
    const confirm = window.confirm(movement);
    if (confirm) mutate({ data: submitData });
  };

  const handleCancel = (e) => {
    e.preventDefault();
    setIsOpen(false);
  };

  return (
    <ModalContainer width={width} height={height} setIsOpen={setIsOpen}>
      <Box display={Box.DISPLAY.FLEX} flexDirection="column" gap="5px">
        <Box>
          <Text as={Text.VARIANT.SUBTITLE}>{mainTitle}</Text>
        </Box>
        <Box>
          <Text as={Text.VARIANT.TEXT} size={Text.SIZE.ADMINCATEGORY}>
            {desc}
          </Text>
        </Box>
      </Box>
      <Box display={Box.DISPLAY.FLEX} flexDirection="column" gap="5px" margin="15px 0 30px 0">
        <Box>
          <Text as={Text.VARIANT.SUBTITLE} size={Text.SIZE.ADMINCONTENTDETAIL}>
            {subTitle}
          </Text>
        </Box>
        <Box display={Box.DISPLAY.FLEX} width="100%" height="32px" al="center" gap="5px">
          <Input
            value={cate1Name[currCate1]}
            width="148px"
            height="100%"
            padding="0 11px"
            fontSize={Input.SIZE.ADMINCONTENTDETAIL}
            disabled={true}
            borderRadius={Input.RADIUS.SMALL}
          />
          <Input
            value={isMixedCategory ? "Mixed" : currCate2[0]}
            width="209px"
            height="100%"
            padding="0 11px"
            fontSize={Input.SIZE.ADMINCONTENTDETAIL}
            disabled={true}
            borderRadius={Input.RADIUS.SMALL}
          />
        </Box>
      </Box>
      <Provider>
        <Box as="form" onSubmit={methods.handleSubmit(onSubmit)}>
          <Box display={Box.DISPLAY.FLEX} width="100%" al="center" gap="5px">
            <SelectMenu
              width="148px"
              selectedMenu={depth1Menu}
              setSelectedMenu={setDepth1Menu}
              options={depth1Options}
              registerName="cate1"
            />
            <SelectMenu
              width="209px"
              selectedMenu={depth2Menu}
              setSelectedMenu={setDepth2Menu}
              options={depth2Options}
              registerName="cate2"
            />
          </Box>
          <Box
            display={Box.DISPLAY.FLEX}
            width="100%"
            al="center"
            jc="center"
            gap="12px"
            margin="20px 0 0 0"
          >
            <Button themeStyle="adminCancel" padding="12px 40px" onClick={handleCancel}>
              취소
            </Button>
            <Button themeStyle="adminSave" padding="12px 40px">
              이동
            </Button>
          </Box>
        </Box>
      </Provider>
    </ModalContainer>
  );
});

export default MovementArticle;
