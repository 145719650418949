import Box from "components/atoms/box";
import Button from "components/atoms/button";
import Image from "components/atoms/image";
import Input from "components/atoms/input";
import Loading from "components/atoms/loading";
import Text from "components/atoms/text";
import { useFindPwMutation } from "hooks/api/auth";
import usePattern from "hooks/utils/usePattern";
import useUrl from "hooks/utils/useUrl";
import React from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import findPwText from "text/findPw";

const { success, fail, pending } = findPwText;

const FindPw = () => {
  const { changeLink } = useUrl();
  const { register, watch, setValue, reset, handleSubmit } = useForm({
    defaultValues: {
      name: "",
      email: "",
      phone1: "",
      phone2: "",
      phone3: "",
    },
  });
  const pattern = usePattern();
  const [request, setRequest] = useState("pending");
  const [isMailLoading, setIsMailLoading] = useState(false);

  const handleSuccessFindPw = () => {
    setRequest("success");
    setIsMailLoading(false);
  };

  const handleFailFindPw = () => {
    setRequest("fail");
    setIsMailLoading(false);
  };

  const findPwMutation = useFindPwMutation(
    handleSuccessFindPw,
    handleFailFindPw
  );

  const handleFail = () => {
    setRequest("pending");
    reset();
  };

  const isActive =
    watch("name").trim() !== "" &&
    watch("email").trim() !== "" &&
    watch("phone1").trim() !== "" &&
    watch("phone2").trim() !== "" &&
    watch("phone3").trim() !== "" &&
    pattern.email.value.test(watch("email")) &&
    pattern.call.value.test(
      `${watch("phone1")}-${watch("phone2")}-${watch("phone3")}`
    );

  const handleSubmitPassword = (data) => {
    setIsMailLoading(true);
    findPwMutation({
      ...data,
    });
  };

  const handleChangePassword = (maxLength) => (e) => {
    const value = e.target.value;
    const name = e.target.name;
    const reg = /[0-9]/g;
    if (!reg.test(value) || value.length > maxLength) {
      return;
    }

    setValue(name, value);
  };

  return (
    <Box
      margin={"138px auto 200px"}
      width={"328px"}
      display={Box.DISPLAY.FLEX}
      flexDirection={"column"}
      al="center"
    >
      {request === "success" && (
        <>
          <Image src={"/assets/auth/checked.svg"} />
          <Text
            as={Text.VARIANT.SUBTITLE}
            size={Text.SIZE.CLIENTCATEGORY}
            weight={Text.WEIGHT.NORMAL}
            color={Text.COLOR.SECONDARY}
            textAlign={"center"}
            margin={"24px 0 0"}
          >
            {success.title[0]}
          </Text>
          <Text
            as={Text.VARIANT.SUBTITLE}
            size={Text.SIZE.CLIENTCATEGORY}
            weight={Text.WEIGHT.NORMAL}
            color={Text.COLOR.SECONDARY}
            textAlign={"center"}
            margin={"0 0 35px"}
          >
            {success.title[1]}
          </Text>

          <Button
            width={"100%"}
            padding={"13px 0"}
            border={"1px solid"}
            borderColor={Button.COLOR.SECONDARY}
            borderRadius={"15px"}
            bgColor={Button.COLOR.SECONDARY}
            color={Button.COLOR.WHITE}
            fontSize={Button.FONT_SIZE.CLIENTCONTENT}
            fontWeight={Button.FONT_WEIGHT.BOLD}
            onClick={() => changeLink("/login")}
          >
            {success.submit}
          </Button>
        </>
      )}
      {request === "fail" && (
        <>
          <Image src={"/assets/auth/Error.svg"} />
          <Text
            as={Text.VARIANT.SUBTITLE}
            size={Text.SIZE.CLIENTCATEGORY}
            weight={Text.WEIGHT.NORMAL}
            color={Text.COLOR.SECONDARY}
            textAlign={"center"}
            margin={"24px 0 0"}
          >
            {fail.title[0]}
          </Text>{" "}
          <Text
            as={Text.VARIANT.SUBTITLE}
            size={Text.SIZE.CLIENTCATEGORY}
            weight={Text.WEIGHT.NORMAL}
            color={Text.COLOR.SECONDARY}
            textAlign={"center"}
            margin={"0 0 35px"}
          >
            {fail.title[1]}
          </Text>
          <Button
            width={"100%"}
            padding={"13px 0"}
            margin={"0 0 10px"}
            border={"1px solid"}
            borderColor={Button.COLOR.SECONDARY}
            borderRadius={"15px"}
            bgColor={Button.COLOR.SECONDARY}
            color={Button.COLOR.WHITE}
            fontSize={Button.FONT_SIZE.CLIENTCONTENT}
            fontWeight={Button.FONT_WEIGHT.BOLD}
            onClick={handleFail}
          >
            {fail.submit}
          </Button>
        </>
      )}
      {request === "pending" && (
        <>
          <Text
            as={Text.VARIANT.SUBTITLE}
            size={Text.SIZE.CLIENTCATEGORY}
            weight={Text.WEIGHT.NORMAL}
            textAlign={"center"}
            margin={"0 0 30px"}
          >
            {pending.title}
          </Text>
          <Input
            size={Input.SIZE.CLIENTETC}
            placeholder={pending.form.name}
            width={"100%"}
            padding={"15px 20px"}
            margin={"0 0 10px"}
            borderColor={Input.COLOR.LINEGRAY}
            borderRadius={"15px"}
            {...register("name")}
          />

          <Input
            size={Input.SIZE.CLIENTETC}
            placeholder={pending.form.email}
            width={"100%"}
            padding={"15px 20px"}
            margin={"0 0 10px"}
            borderColor={Input.COLOR.LINEGRAY}
            borderRadius={"15px"}
            {...register("email")}
          />
          <Box
            display={Box.DISPLAY.FLEX}
            jc={"space-between"}
            width={"100%"}
            margin={"0 0 10px 0"}
          >
            <Input
              size={Input.SIZE.CLIENTETC}
              type="tel"
              placeholder={pending.form.tel}
              width={"calc((100% - 40px) / 3)"}
              padding={"15px 14px"}
              borderColor={Input.COLOR.LINEGRAY}
              borderRadius={"15px"}
              {...register("phone1")}
              onChange={handleChangePassword(3)}
              value={watch("phone1")}
            />
            <Text
              isSort
              width={"6px"}
              margin={"0 2px"}
              color={Text.COLOR.TEXTGRAY}
            >
              -
            </Text>
            <Input
              size={Input.SIZE.CLIENTETC}
              type="tel"
              width={"calc((100% - 40px) / 3)"}
              padding={"15px 14px"}
              borderColor={Input.COLOR.LINEGRAY}
              borderRadius={"15px"}
              {...register("phone2")}
              onChange={handleChangePassword(4)}
              value={watch("phone2")}
            />
            <Text
              isSort
              width={"6px"}
              margin={"0 2px"}
              color={Text.COLOR.TEXTGRAY}
            >
              -
            </Text>
            <Input
              size={Input.SIZE.CLIENTETC}
              type="tel"
              width={"calc((100% - 40px) / 3)"}
              padding={"15px 14px"}
              borderColor={Input.COLOR.LINEGRAY}
              borderRadius={"15px"}
              {...register("phone3")}
              onChange={handleChangePassword(4)}
              value={watch("phone3")}
            />
          </Box>

          <Box width={"100%"} margin={"0 0 35px"}>
            <Text
              as={Text.VARIANT.TEXT}
              color={Text.COLOR.RED}
              size={Text.SIZE.MOBILECONTENTDETAIL}
              width={"100%"}
              textAlign={"right"}
            >
              {pending.info[0]}
            </Text>
            <Text
              as={Text.VARIANT.TEXT}
              color={Text.COLOR.RED}
              size={Text.SIZE.MOBILECONTENTDETAIL}
              width={"100%"}
              textAlign={"right"}
            >
              {pending.info[1]}
            </Text>
          </Box>

          {isMailLoading ? (
            <Loading />
          ) : (
            <Button
              width={"100%"}
              padding={"13px 0"}
              border={"1px solid"}
              borderColor={
                isActive ? Button.COLOR.SECONDARY : Button.COLOR.LINEGRAY
              }
              borderRadius={"15px"}
              bgColor={
                isActive ? Button.COLOR.SECONDARY : Button.COLOR.LINEGRAY
              }
              color={Button.COLOR.WHITE}
              fontSize={Button.FONT_SIZE.CLIENTCONTENT}
              fontWeight={Button.FONT_WEIGHT.BOLD}
              onClick={handleSubmit(handleSubmitPassword)}
              disabled={!isActive}
            >
              {pending.submit}
            </Button>
          )}
        </>
      )}
    </Box>
  );
};

export default FindPw;
