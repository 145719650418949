import Box from "components/atoms/box";
import {
  Privacy,
  Using,
  Email,
  CopyRight,
} from "components/organisms/serviceContent";
import useUrl from "hooks/utils/useUrl";
import React from "react";

const ServiceForm = {
  privacy: Privacy,
  termsOfUse: Using,
  email: Email,
  copyright: CopyRight,
};

const Service = () => {
  const {
    query: { category = "privacy" },
  } = useUrl();

  const ServiceContent = ServiceForm[category];

  return (
    <Box display={Box.DISPLAY.FLEX} jc="center">
      <Box
        width={"1260px"}
        padding={"32px 40px"}
        margin={"80px 0 200px"}
        borderRadius={"10px"}
        bgColor={Box.COLOR.LIGHTGRAY}
      >
        <ServiceContent />
      </Box>
    </Box>
  );
};

export default Service;
