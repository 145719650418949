import { GET, PUT } from "api/config";
import { dashboard } from "../url";

const { membershipUrl, articleUrl, mypageUrl, todayUrl } = dashboard;

// 회원 현황
export const getDashboardMembership = async () => await GET(membershipUrl);

// 게시글 조회수 순위
export const getDashboardArticleList = async (boardName) => await GET(`${articleUrl}/${boardName}`);

// 내정보 조회
export const getMyInfo = async () => await GET(mypageUrl);

// 내정보 수정
export const updateMyInfo = async ({ data }) => await PUT(mypageUrl, data);

// 일별 방문자수
export const getTodayVisitors = async ({ type }) => await GET(`${todayUrl}/${type}`);
