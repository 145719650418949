import Box from "components/atoms/box";
import Input from "components/atoms/input";
import Link from "components/atoms/link";
import Text from "components/atoms/text";
import useAllCheckbox from "hooks/utils/useAllCheckbox";
import React, { useEffect, useState } from "react";
import HasNotResult from "../HasNotResult";
import useUrl from "hooks/utils/useUrl";
import TableDeleteBar from "../tableDeleteBar";
import { useDeleteMember } from "hooks/api/admin/member";
import { common } from "text/admin/common";
import { adminDefaultUrl } from "api/admin/url";

const MemberTable = (props) => {
  const { list, tableHeader, itemsPerPage } = props;

  const {
    default: { remove },
    selectRemoveItems,
  } = common.alert;

  const {
    changeLink,
    query: { page = 1 },
  } = useUrl();

  const currPage = parseInt(page);

  const count = list.length > parseInt(itemsPerPage) ? parseInt(itemsPerPage) : list.length;

  let keyArr = [];
  const [allKeyList, setAllKeyList] = useState([]);

  useEffect(() => {
    setAllKeyList(keyArr);
  }, [list]);

  const {
    checkAll,
    checkboxes,
    resetCheckbox,
    selectedKeys,
    handleCheckAllChange,
    handleCheckboxChange,
  } = useAllCheckbox(count, allKeyList);

  const isNoneSelected = selectedKeys.length === 0;

  const mutate = useDeleteMember(resetCheckbox);

  const handleDeleteSelectedBanner = () => {
    const data = { keyList: selectedKeys };
    if (isNoneSelected) {
      alert(selectRemoveItems);
      return;
    }

    const confirm = window.confirm(remove);
    if (confirm) mutate({ data });
  };

  const handleCreateManager = () => {
    changeLink(`${adminDefaultUrl}/member/membership/detail`);
  };

  return (
    <>
      <TableDeleteBar
        title="일반 회원 등록"
        handleDelete={handleDeleteSelectedBanner}
        handleSave={handleCreateManager}
        hasRadius={false}
      />
      <Box
        display={Box.DISPLAY.FLEX}
        height="32px"
        borderTop="1px solid"
        borderColor={Box.COLOR.LINEGRAY}
      >
        <Box
          width="6%"
          height="100%"
          display={Box.DISPLAY.FLEX}
          al="center"
          jc="center"
          borderLeft="1px solid"
          borderBottom={list.length === 0 ? "1px solid" : ""}
          borderColor={Box.COLOR.ADMINLINEGRAY}
          bgColor={Box.COLOR.ADMINCONTENTSBG}
        >
          <Input
            type="checkbox"
            width="16px"
            height="16px"
            borderColor={Input.COLOR.ADMINLINEGRAY}
            onChange={handleCheckAllChange}
            checked={checkAll}
          />
        </Box>
        {tableHeader.map(({ title, width, ariaLabel }, idx) => (
          <Box
            width={width}
            padding="8px 0"
            key={idx}
            borderLeft="1px solid"
            borderRight={idx === tableHeader.length - 1 ? "1px solid" : ""}
            borderBottom={list.length === 0 ? "1px solid" : ""}
            borderColor={Box.COLOR.LINEGRAY}
            bgColor={Box.COLOR.ADMINCONTENTSBG}
          >
            <Text
              as={Text.VARIANT.TEXT}
              size={Text.SIZE.CLIENTCONTENTDETAIL}
              weight={Text.WEIGHT.BOLD}
              textAlign="center"
              ariaLabel={ariaLabel}
            >
              {title}
            </Text>
          </Box>
        ))}
      </Box>
      {list.length === 0 && <HasNotResult />}
      {list.length > 0 &&
        list.map(
          (
            { email1, email2, hp1, hp2, hp3, newsYN, regDate, userId, userName, userType, key },
            idx
          ) => {
            const pageIdx = currPage > 1 ? (currPage - 1) * 10 + (idx + 1) : idx + 1;
            keyArr.push(key);

            return (
              <Box
                key={idx}
                borderTop={idx === 0 ? "1px solid" : ""}
                borderBottom="1px solid"
                borderColor={Box.COLOR.LINEGRAY}
                display={Box.DISPLAY.FLEX}
                height="77px"
              >
                <Box
                  width="6%"
                  height="100%"
                  display={Box.DISPLAY.FLEX}
                  jc="center"
                  borderLeft="1px solid"
                  borderColor={Box.COLOR.ADMINLINEGRAY}
                  al="center"
                >
                  <Input
                    type="checkbox"
                    name={key}
                    width="16px"
                    height="16px"
                    borderColor={Input.COLOR.ADMINLINEGRAY}
                    checked={checkboxes[key] ?? false}
                    onChange={(e) => handleCheckboxChange({ e })}
                  />
                </Box>
                <Box
                  width="7%"
                  display={Box.DISPLAY.FLEX}
                  jc="center"
                  al="center"
                  borderLeft="1px solid"
                  borderColor={Box.COLOR.LINEGRAY}
                  padding="0 10px"
                >
                  <Text
                    as={Text.VARIANT.TEXT}
                    size={Text.SIZE.CLIENTCONTENTDETAIL}
                    textAlign="center"
                    ariaLabel={pageIdx}
                  >
                    {pageIdx}
                  </Text>
                </Box>
                <Box
                  width="15%"
                  display={Box.DISPLAY.FLEX}
                  jc="center"
                  al="center"
                  borderLeft="1px solid"
                  borderColor={Box.COLOR.LINEGRAY}
                  padding="0 10px"
                  wordBreak="break-all"
                >
                  <Link link={`${adminDefaultUrl}/member/membership/detail?key=${key}`}>
                    <Text
                      as={Text.VARIANT.TEXT}
                      size={Text.SIZE.CLIENTCONTENTDETAIL}
                      textAlign="center"
                      decoration="underline"
                      ariaLabel={userId}
                    >
                      {userId}
                    </Text>
                  </Link>
                </Box>
                <Box
                  width="8%"
                  display={Box.DISPLAY.FLEX}
                  jc="center"
                  al="center"
                  borderLeft="1px solid"
                  borderColor={Box.COLOR.LINEGRAY}
                  padding="0 10px"
                  wordBreak="break-all"
                >
                  <Text
                    as={Text.VARIANT.TEXT}
                    size={Text.SIZE.CLIENTCONTENTDETAIL}
                    textAlign="center"
                    ariaLabel={userName}
                  >
                    {userName}
                  </Text>
                </Box>
                <Box
                  width="13%"
                  display={Box.DISPLAY.FLEX}
                  jc="center"
                  al="center"
                  borderLeft="1px solid"
                  borderColor={Box.COLOR.LINEGRAY}
                  padding="0 10px"
                >
                  <Text
                    as={Text.VARIANT.TEXT}
                    size={Text.SIZE.CLIENTCONTENTDETAIL}
                    textAlign="center"
                    ariaLabel={`${hp1}-${hp2}-${hp3}`}
                  >
                    {userType === "간편 로그인" ? "" : `${hp1}-${hp2}-${hp3}`}
                  </Text>
                </Box>
                <Box
                  width="16%"
                  display={Box.DISPLAY.FLEX}
                  jc="center"
                  al="center"
                  borderLeft="1px solid"
                  borderColor={Box.COLOR.LINEGRAY}
                  padding="0 10px"
                  wordBreak="break-all"
                >
                  <Text
                    as={Text.VARIANT.TEXT}
                    size={Text.SIZE.CLIENTCONTENTDETAIL}
                    textAlign="center"
                    ariaLabel={`${email1}@${email2}`}
                  >
                    {userType === "간편 로그인" ? "" : `${email1}@${email2}`}
                  </Text>
                </Box>
                <Box
                  width="10%"
                  display={Box.DISPLAY.FLEX}
                  jc="center"
                  al="center"
                  borderLeft="1px solid"
                  borderColor={Box.COLOR.LINEGRAY}
                  padding="0 10px"
                >
                  <Text
                    as={Text.VARIANT.TEXT}
                    size={Text.SIZE.CLIENTCONTENTDETAIL}
                    textAlign="center"
                    ariaLabel={userType}
                  >
                    {userType}
                  </Text>
                </Box>
                <Box
                  width="9%"
                  display={Box.DISPLAY.FLEX}
                  jc="center"
                  al="center"
                  borderLeft="1px solid"
                  borderColor={Box.COLOR.LINEGRAY}
                  padding="0 10px"
                >
                  <Text
                    as={Text.VARIANT.TEXT}
                    size={Text.SIZE.CLIENTCONTENTDETAIL}
                    textAlign="center"
                    ariaLabel={newsYN === "Y" ? "뉴스레터 수신" : "뉴스레터 수신거부"}
                  >
                    {newsYN}
                  </Text>
                </Box>
                <Box
                  width="16%"
                  display={Box.DISPLAY.FLEX}
                  jc="center"
                  al="center"
                  borderLeft="1px solid"
                  borderRight="1px solid"
                  padding="0 10px"
                  borderColor={Box.COLOR.LINEGRAY}
                >
                  <Text
                    as={Text.VARIANT.TEXT}
                    size={Text.SIZE.CLIENTCONTENTDETAIL}
                    textAlign="center"
                    ariaLabel={`가입일: ${regDate}`}
                  >
                    {regDate}
                  </Text>
                </Box>
              </Box>
            );
          }
        )}
    </>
  );
};

export default MemberTable;
