import { createRequest } from "api/service";
import { useMutation } from "react-query";

export const useCreateRequestMutation = (successCallback, failCallback) => {
  const createRequestMutation = useMutation(createRequest, {
    onSuccess: () => {
      successCallback();
    },
    onError: () => {
      failCallback();
    },
  });

  const submit = (data) => {
    createRequestMutation.mutate(data);
  };

  return submit;
};
