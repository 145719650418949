import { DatePicker } from "antd";
import Box from "components/atoms/box";
import Text from "components/atoms/text";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { BiCalendar } from "@react-icons/all-files/bi/BiCalendar";
import "dayjs/locale/ko";
import locale from "antd/es/date-picker/locale/ko_KR";
import useUrl from "hooks/utils/useUrl";
import weekOfYear from "dayjs/plugin/weekOfYear";
import isoWeek from "dayjs/plugin/isoWeek";
import SearchInput from "components/molecules/searchInput";
import calendarText from "text/calendar";

const { searchInput, search } = calendarText;

dayjs.extend(weekOfYear);
dayjs.extend(isoWeek);
dayjs.locale("ko");

const cellRender = (list, isError, isLoading, handleCheckActive) => {
  if (isError || isLoading)
    return (value) => {
      return (
        <Box
          display={Box.DISPLAY.FLEX}
          height={"100%"}
          width={"100%"}
          jc={"center"}
          al={"center"}
        >
          <Text
            as={Text.VARIANT.TEXT}
            size={Text.SIZE.CLIENTCONTENTDETAIL}
            color={Text.COLOR.BLACK}
            weight={Text.WEIGHT.NORMAL}
          >
            {value.format("D")}
          </Text>
        </Box>
      );
    };

  return (value) => {
    const date = value.format("MMDD");
    const isActive = handleCheckActive(list, date);

    return (
      <Box
        display={Box.DISPLAY.FLEX}
        height={"100%"}
        width={"100%"}
        jc={"center"}
        al={"center"}
      >
        <Text
          as={Text.VARIANT.TEXT}
          size={Text.SIZE.CLIENTCONTENTDETAIL}
          color={isActive ? Text.COLOR.SECONDARY : Text.COLOR.BLACK}
          weight={isActive ? Text.WEIGHT.BOLD : Text.WEIGHT.NORMAL}
        >
          {value.format("D")}
        </Text>
      </Box>
    );
  };
};

const CalendarHeader = (props) => {
  const {
    title,
    format,
    picker,
    defaultValue,
    handleCheckActive,
    head,
    length,
    handleChangeDate,
    type,
    category,
    preFetch,
    isSearch,
    isDaily,
  } = props;
  const {
    query: { date = defaultValue, searchKeyword },
    changeQuery,
  } = useUrl();
  const [selectDate, setSelectDate] = useState(dayjs(date));
  const [calendarDate, setCalendarDate] = useState(selectDate);
  const { list, isError, isLoading } = preFetch({
    type,
    category,
    date: calendarDate,
  });

  const dateForm = selectDate.format(format);

  const handleSearch = (value) => {
    changeQuery({
      searchKeyword: value,
      page: 1,
    });
  };

  const isSearchResult = searchKeyword && isSearch;

  useEffect(() => {
    setSelectDate(dayjs(date));
  }, [date]);

  return (
    <Box padding={isDaily ? "0" : "0 40px"} margin={isDaily ? "0" : "0 0 19px"}>
      {title && (
        <Text
          as={Text.VARIANT.TEXT}
          size={Text.SIZE.CLIENTNORMAL}
          color={Text.COLOR.SECONDARY}
          weight={Text.WEIGHT.BOLD}
        >
          {title}
        </Text>
      )}
      <Box
        display={Box.DISPLAY.FLEX}
        jc={"space-between"}
        padding={"10px 26px"}
        margin={isDaily ? "12px 0 0 0" : ""}
        borderRadius={"0 20px"}
        bgColor={Box.COLOR.PRIMARY}
        color={Box.COLOR.WHITE}
      >
        <Box display={Box.DISPLAY.INLINE_FLEX} al={"center"}>
          <Text as={Text.VARIANT.TITLE} size={Text.SIZE.CLIENTTITLE}>
            {dateForm}
          </Text>

          <DatePicker
            picker={picker}
            value={selectDate}
            onChange={handleChangeDate(changeQuery)}
            onPanelChange={(date) => {
              setCalendarDate(date);
            }}
            locale={locale}
            className="calendar"
            dateRender={cellRender(list, isError, isLoading, handleCheckActive)}
            suffixIcon={<BiCalendar color={"white"} />}
          />
        </Box>

        {isSearch && (
          <SearchInput
            placeholder={searchInput}
            ariaLabel="캘린더 검색창"
            theme={SearchInput.THEME.BOARD}
            submit={handleSearch}
          />
        )}
      </Box>

      {isSearchResult && (
        <Box
          display={Box.DISPLAY.FLEX}
          jc={"flex-end"}
          width={"100%"}
          padding={"20px 0 0"}
        >
          <Text as={Text.VARIANT.TEXT} size={Text.SIZE.CLIENTCONTENT}>
            <Text
              as={Text.VARIANT.SPAN}
              color={Text.COLOR.SECONDARY}
              weight={Text.WEIGHT.BOLD}
            >
              {searchKeyword}
            </Text>{" "}
            {search[0]}
            <Text
              as={Text.VARIANT.SPAN}
              color={Text.COLOR.SECONDARY}
              weight={Text.WEIGHT.BOLD}
            >
              {length}
            </Text>{" "}
            {search[1]}
          </Text>
        </Box>
      )}

      {head()}
    </Box>
  );
};

export default CalendarHeader;
