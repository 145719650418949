import Box from "components/atoms/box";
import Button from "components/atoms/button";
import dayjs from "dayjs";
import {
  useCreateNewsletter,
  useUpdateNewsletter,
} from "hooks/api/admin/news_letter";
import {
  useNewsletterBanner,
  useNewsletterSideNews,
} from "hooks/api/newsletter";
import useUrl from "hooks/utils/useUrl";
import React, { useRef, useEffect } from "react";
import convertFilePath from "utils/convertFilePath";

const NewsletterTemplate = (props) => {
  const {
    id,
    period,
    cnt,
    sec1Title,
    sec1ImgUrl,
    sec1Contents,
    sec1Url,
    sec2Title,
    sec2ImgUrl,
    sec2Contents,
    sec2Url,
    submitData,
    setIsOpen,
    isAdmin,
    setIsChangeOrderList,
  } = props;
  const letterRef = useRef(null);
  const title1Ref = useRef(null);
  const title2Ref = useRef(null);

  const content1Ref = useRef(null);
  const content2Ref = useRef(null);

  const {
    query: { key },
  } = useUrl();

  const { list, isSuccess: isSideNewsSuccess } = useNewsletterSideNews(id);
  const { banner, isSuccess: isNewsletterBannerSuccess } =
    useNewsletterBanner();
  const api = key ? useUpdateNewsletter : useCreateNewsletter;
  const mutate = api(setIsChangeOrderList);

  const isSuccess = isSideNewsSuccess && isNewsletterBannerSuccess;

  const handleChangeText = (text, length) => {
    if (text.length > length) {
      return `${text.substring(0, length)}...`;
    }
    return text;
  };

  useEffect(() => {
    const titleHeight = title1Ref.current.clientHeight;
    const title2Height = title2Ref.current.clientHeight;

    const maxHeight = Math.max(titleHeight, title2Height);

    title1Ref.current.style.height = `${maxHeight}px`;
    title2Ref.current.style.height = `${maxHeight}px`;
  }, []);

  useEffect(() => {
    const contentHeight = content1Ref.current.clientHeight;
    const content2Height = content2Ref.current.clientHeight;

    const maxHeight = Math.max(contentHeight, content2Height);

    content1Ref.current.style.height = `${maxHeight}px`;
    content2Ref.current.style.height = `${maxHeight}px`;
  }, []);

  // useEffect(() => {
  //   if (isSuccess) console.log(letterRef.current.innerHTML);
  // }, [isSuccess]);

  const handleCancel = (e) => {
    e.preventDefault();
    setIsOpen(false);
  };

  const handleSave = (e) => {
    e.preventDefault();
    const confirm = window.confirm("저장하시겠습니까?");
    if (confirm) mutate({ data: submitData, key });
  };

  const formatDateFormDayjs = (dateString) => {
    const standardized = dateString.replace(/\s+/g, "").replace(/\./g, "-");

    return standardized.endsWith("-")
      ? standardized.slice(0, -1)
      : standardized;
  };

  return (
    <div
      ref={letterRef}
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <table
        style={{
          width: "818px",
          margin: "0 auto",
          tableLayout: "fixed",
          overflowWrap: "break-word",
          wordBreak: "normal",
        }}
      >
        <tbody>
          <tr>
            <td style={{ width: "818px" }}>
              {/*  */}
              {/*  */}
              {/*  */}
              {/*  */}
              {/*  */}
              {/*@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ 1번째 단락 @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ */}
              {/*  */}
              {/*  */}
              {/*  */}
              {/*  */}
              {/*  */}
              <div
                style={{
                  height: "140px",
                  backgroundImage: "url('/assets/mail/header.png')",
                  backgroundColor: "#3A47B8",
                  borderRadius: "0 40px",
                  padding: "25px 25px 10px",
                  boxSizing: "border-box",
                }}
              >
                <div
                  style={{
                    color: "white",
                  }}
                >
                  <span
                    style={{
                      marginRight: "12px",
                      fontWeight: "700",
                      fontSize: "25px",
                    }}
                  >
                    Vol. {cnt}
                  </span>

                  {dayjs(formatDateFormDayjs(period)).format("YYYY.MM.DD")}
                </div>

                <div
                  style={{
                    marginTop: "10px",
                    textAlign: "right",
                  }}
                >
                  <a href={`${window.location.origin}/`} target="_blank">
                    <img
                      src={"/assets/newsletter/webzineLogo.png"}
                      width={"369px"}
                      alt={"logo"}
                    />
                  </a>
                </div>
              </div>

              {/*  */}
              {/*  */}
              {/*  */}
              {/*  */}
              {/*  */}
              {/*@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ 2번째 단락 @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ */}
              {/*  */}
              {/*  */}
              {/*  */}
              {/*  */}
              {/*  */}
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                  boxSizing: "border-box",
                  backgroundColor: "#FFFFFF",
                  marginTop: "20px",
                  padding: "0 55px",
                  boxSizing: "border-box",
                }}
              >
                <div
                  style={{
                    display: "inline-block",

                    color: "white",
                    borderRadius: "40px",
                    width: "calc((100% / 3) - 16px)",
                  }}
                >
                  <a
                    style={{
                      display: "block",
                      width: "100%",
                      cursor: "pointer",
                      color: "white",
                      fontWeight: "700",
                      fontSize: "16px",
                      boxSizing: "border-box",
                      textDecoration: "none !important",
                    }}
                    href={`${window.location.origin}/news`}
                    target={"_blank"}
                  >
                    <img
                      src={"/assets/newsletter/newsButton.svg"}
                      width={"100%"}
                      alt={"newsButton"}
                    />
                  </a>
                </div>
                <div
                  style={{
                    display: "inline-block",

                    color: "white",
                    borderRadius: "40px",
                    width: "calc((100% / 3) - 16px)",
                    margin: "0 12px",
                  }}
                >
                  <a
                    style={{
                      display: "block",
                      width: "100%",
                      cursor: "pointer",
                      color: "white",
                      fontWeight: "700",
                      fontSize: "16px",
                      boxSizing: "border-box",
                      textDecoration: "none !important",
                    }}
                    href={`${window.location.origin}/feature`}
                    target={"_blank"}
                  >
                    <img
                      src={"/assets/newsletter/featureButton.svg"}
                      width={"100%"}
                      alt={"featureButton"}
                    />
                  </a>
                </div>
                <div
                  style={{
                    display: "inline-block",

                    color: "white",
                    borderRadius: "40px",
                    width: "calc((100% / 3) - 16px)",
                  }}
                >
                  <a
                    style={{
                      display: "block",
                      width: "100%",
                      cursor: "pointer",
                      color: "white",
                      fontWeight: "700",
                      fontSize: "16px",
                      boxSizing: "border-box",
                      textDecoration: "none !important",
                    }}
                    href={`${window.location.origin}/people`}
                    target={"_blank"}
                  >
                    <img
                      src={"/assets/newsletter/peopleButton.svg"}
                      width={"100%"}
                      alt={"peopleButton"}
                    />
                  </a>
                </div>
              </div>

              {/*  */}
              {/*  */}
              {/*  */}
              {/*  */}
              {/*  */}
              {/*@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ 3번째 단락 @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ */}
              {/*  */}
              {/*  */}
              {/*  */}
              {/*  */}
              {/*  */}
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                  boxSizing: "border-box",
                  backgroundColor: "#FFFFFF",
                  marginTop: "20px",
                  padding: "0 55px",
                  boxSizing: "border-box",
                }}
              >
                <h2
                  className="title"
                  style={{
                    fontSize: "24px",
                    fontWeight: "bold",
                    textAlign: "left",
                    lineHeight: "24px",
                    color: "#23295c",
                    boxSizing: "border-box",
                    marginBottom: "10px",
                  }}
                >
                  Weekly News
                </h2>

                {/*  */}
                {/*  */}
                {/*  */}
                {/*  */}
                {/*  */}
                {/*@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ Weekly News 기사 @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ */}
                {/*  */}
                {/*  */}
                {/*  */}
                {/*  */}
                {/*  */}

                <div
                  style={{
                    width: "calc(50% - 18px)",
                    display: "inline-block",
                    margin: "0 30px 0 0",
                  }}
                >
                  <a
                    style={{
                      display: "block",
                      cursor: "pointer",
                      width: "100%",
                    }}
                    href={sec1Url}
                    target={"_blank"}
                  >
                    <div
                      style={{
                        width: "100%",
                      }}
                    >
                      <img
                        src={sec1ImgUrl}
                        style={{
                          borderRadius: "0 30px",
                          width: "100%",
                          height: "219px",
                        }}
                        alt={"sec1ImgImg"}
                      />
                      {/* <p
                      style={{
                        fontSize: "12px",
                        color: "#a8a8a8a8",
                        textAlign: "center",
                        boxSizing: "border-box",
                        padding: "12px 0 30px",
                      }}
                    >
                      이미지를 클릭하면 해당 기사로 이동합니다.
                    </p> */}
                    </div>

                    <h3
                      className="title"
                      style={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        textAlign: "left",
                        lineHeight: "24px",
                        color: "#23295c",
                        boxSizing: "border-box",
                        margin: "10px 0",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 2,
                      }}
                      ref={title1Ref}
                    >
                      {sec1Title}
                    </h3>

                    <p
                      style={{
                        fontSize: "14px",
                        textAlign: "left",
                        lineHeight: "22px",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 2,
                      }}
                      ref={content1Ref}
                    >
                      {sec1Contents}
                    </p>
                  </a>
                </div>

                <div
                  style={{
                    width: "calc(50% - 18px)",
                    display: "inline-block",
                    margin: "0",
                  }}
                >
                  <a
                    style={{
                      display: "block",
                      cursor: "pointer",
                      width: "100%",
                    }}
                    href={sec2Url}
                    target={"_blank"}
                  >
                    <div
                      style={{
                        width: "100%",
                      }}
                    >
                      <img
                        src={sec2ImgUrl}
                        style={{
                          borderRadius: "0 30px",
                          width: "100%",
                          height: "219px",
                        }}
                        alt={"sec2ImgImg"}
                      />
                      {/* <p
                      style={{
                        fontSize: "12px",
                        color: "#a8a8a8a8",
                        textAlign: "center",
                        boxSizing: "border-box",
                        padding: "12px 0 30px",
                      }}
                    >
                      이미지를 클릭하면 해당 기사로 이동합니다.
                    </p> */}
                    </div>

                    <h3
                      className="title"
                      style={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        textAlign: "left",
                        lineHeight: "24px",
                        color: "#23295c",
                        boxSizing: "border-box",
                        margin: "10px 0",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 2,
                      }}
                      ref={title2Ref}
                    >
                      {sec2Title}
                    </h3>

                    <p
                      style={{
                        fontSize: "14px",
                        textAlign: "left",
                        lineHeight: "22px",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 2,
                      }}
                      ref={content2Ref}
                    >
                      {sec2Contents}
                    </p>
                  </a>
                </div>
              </div>

              {/*  */}
              {/*  */}
              {/*  */}
              {/*  */}
              {/*  */}
              {/*@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ 4번째 단락 @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ */}
              {/*  */}
              {/*  */}
              {/*  */}
              {/*  */}
              {/*  */}
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                  boxSizing: "border-box",
                  backgroundColor: "#FFFFFF",
                  marginTop: "40px",
                  padding: "0 55px",
                  boxSizing: "border-box",
                }}
              >
                {/*  */}
                {/*  */}
                {/*  */}
                {/*  */}
                {/*  */}
                {/*@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ 과학 기술계 소식 @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ */}
                {/*  */}
                {/*  */}
                {/*  */}
                {/*  */}
                {/*  */}
                <div
                  style={{
                    width: "calc(50% - 18px)",
                    display: "inline-block",
                    marginRight: "30px",
                    padding: "20px 10px",
                    boxSizing: "border-box",
                    background: "#E8F0FF",
                    borderRadius: "0 20px",
                  }}
                >
                  <div
                    style={{
                      marginBottom: "10px",
                    }}
                  >
                    <h3
                      className="title"
                      style={{
                        display: "inline-block",
                        fontSize: "16px",
                        fontWeight: "bold",
                        textAlign: "left",

                        color: "#23295c",
                        boxSizing: "border-box",

                        width: "calc(100% - 80px)",
                      }}
                    >
                      과학기술계소식
                    </h3>

                    <div
                      style={{
                        display: "inline-block",
                        width: "74px",
                        textAlign: "right",
                      }}
                    >
                      <a
                        style={{
                          fontSize: "14px",
                          boxSizing: "border-box",
                          cursor: "pointer",
                        }}
                        href={`${window.location.origin}/news?category=COM045_YpfsuKY`}
                        target="_blank"
                      >
                        <img src={"/assets/mail/more.svg"} alt={"moreButton"} />
                      </a>
                    </div>
                  </div>

                  {isSideNewsSuccess &&
                    list.scienceList.map(({ title, key }, idx) => (
                      <a
                        style={{
                          display: "block",
                          cursor: "pointer",
                          width: "100%",
                          textDecoration: "none !important",
                        }}
                        href={`${window.location.origin}/news/${key}?category=COM045_YpfsuKY`}
                        target="_blank"
                        key={key}
                      >
                        <p
                          key={idx}
                          style={{
                            fontSize: "14px",
                            textAlign: "left",
                            lineHeight: "22px",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            WebkitLineClamp: 1,
                          }}
                        >
                          {handleChangeText(title, 28)}
                        </p>
                      </a>
                    ))}
                </div>
                {/*  */}
                {/*  */}
                {/*  */}
                {/*  */}
                {/*  */}
                {/*@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ 회원 단체 @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ */}
                {/*  */}
                {/*  */}
                {/*  */}
                {/*  */}
                {/*  */}
                <div
                  style={{
                    width: "calc(50% - 18px)",
                    display: "inline-block",
                    padding: "20px 10px",
                    boxSizing: "border-box",
                    background: "#E8F0FF",
                    borderRadius: "0 20px",
                  }}
                >
                  <div
                    style={{
                      marginBottom: "10px",
                    }}
                  >
                    <h3
                      className="title"
                      style={{
                        display: "inline-block",
                        fontSize: "16px",
                        fontWeight: "bold",
                        textAlign: "left",

                        color: "#23295c",
                        boxSizing: "border-box",

                        width: "calc(100% - 80px)",
                      }}
                    >
                      회원단체소식
                    </h3>

                    <div
                      style={{
                        display: "inline-block",
                        width: "74px",
                        textAlign: "right",
                      }}
                    >
                      <a
                        style={{
                          fontSize: "14px",
                          boxSizing: "border-box",
                          cursor: "pointer",
                          cursor: "pointer",
                          textDecoration: "none !important",
                        }}
                        href={`${window.location.origin}/news?category=COM045_AcXdVFG`}
                        target="_blank"
                      >
                        <img src={"/assets/mail/more.svg"} alt={"moreButton"} />
                      </a>
                    </div>
                  </div>

                  {isSideNewsSuccess &&
                    list.groupList.map(({ title, key }, idx) => (
                      <a
                        style={{
                          display: "block",
                          cursor: "pointer",
                          width: "100%",
                          textDecoration: "none !important",
                        }}
                        href={`${window.location.origin}/news/${key}?category=COM045_AcXdVFG`}
                        target="_blank"
                        key={key}
                      >
                        <p
                          key={idx}
                          style={{
                            fontSize: "14px",
                            textAlign: "left",
                            lineHeight: "22px",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            WebkitLineClamp: 1,
                          }}
                        >
                          {handleChangeText(title, 28)}
                        </p>
                      </a>
                    ))}
                </div>
              </div>

              {/*  */}
              {/*  */}
              {/*  */}
              {/*  */}
              {/*  */}
              {/*@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ 5번째 단락 @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ */}
              {/*  */}
              {/*  */}
              {/*  */}
              {/*  */}
              {/*  */}
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                  boxSizing: "border-box",
                  backgroundColor: "#FFFFFF",
                  marginTop: "12px",
                  padding: "0 55px",
                  boxSizing: "border-box",
                }}
              >
                {/*  */}
                {/*  */}
                {/*  */}
                {/*  */}
                {/*  */}
                {/*@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ 과학 기술 캘린더 @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ */}
                {/*  */}
                {/*  */}
                {/*  */}
                {/*  */}
                {/*  */}
                <div
                  style={{
                    width: "100%",
                    padding: "20px 10px",
                    boxSizing: "border-box",
                    display: "inline-block",
                    background: "#E8F0FF",
                    borderRadius: "0 20px",
                  }}
                >
                  <div
                    style={{
                      marginBottom: "10px",
                    }}
                  >
                    <h3
                      className="title"
                      style={{
                        display: "inline-block",
                        fontSize: "16px",
                        fontWeight: "bold",
                        textAlign: "left",

                        color: "#23295c",
                        boxSizing: "border-box",

                        width: "calc(100% - 80px)",
                      }}
                    >
                      과학기술 캘린더
                    </h3>

                    <div
                      style={{
                        display: "inline-block",
                        width: "74px",
                        textAlign: "right",
                      }}
                    >
                      <a
                        style={{
                          fontSize: "14px",
                          boxSizing: "border-box",
                          cursor: "pointer",
                          textDecoration: "none !important",
                        }}
                        href={`${window.location.origin}/calendar`}
                        target="_blank"
                      >
                        <img src={"/assets/mail/more.svg"} alt={"moreButton"} />
                      </a>
                    </div>
                  </div>

                  {isSideNewsSuccess &&
                    list.calendarList.map(
                      ({ title, key, eventBeginDate, eventEndDate }, idx) => (
                        <a
                          style={{
                            display: "block",
                            cursor: "pointer",
                            width: "100%",
                          }}
                          href={`${window.location.origin}/calendar/${key}`}
                          target="_blank"
                          key={key}
                        >
                          <p
                            key={idx}
                            style={{
                              fontSize: "14px",
                              textAlign: "left",
                              lineHeight: "22px",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              display: "-webkit-box",
                              WebkitBoxOrient: "vertical",
                              WebkitLineClamp: 1,
                            }}
                          >
                            {handleChangeText(title, 80)}
                          </p>
                        </a>
                      )
                    )}
                </div>
              </div>

              {/*  */}
              {/*  */}
              {/*  */}
              {/*  */}
              {/*  */}
              {/*@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ 6번째 단락 @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ */}
              {/*  */}
              {/*  */}
              {/*  */}
              {/*  */}
              {/*  */}
              {isNewsletterBannerSuccess && (
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                    backgroundColor: "#FFFFFF",
                    marginTop: "20px",
                    marginBottom: "10px",
                    padding: "20px 55px 0",
                    boxSizing: "border-box",

                    borderTop: "1px solid #DFDFDF",
                  }}
                >
                  <div
                    style={{
                      width: "calc(50% - 18px)",
                      marginRight: "12px",
                      display: "inline-block",
                    }}
                  >
                    <a
                      style={{
                        display: "block",
                        width: "100%",
                        cursor: "pointer",
                      }}
                      href={banner[0].url}
                      target={banner[0].newWindow === "Y" ? "_blank" : "_self"}
                    >
                      <img
                        src={convertFilePath(banner[0].filePath)}
                        style={{
                          aspectRatio: "300/95",
                          borderRadius: "5px",
                          width: "100%",
                        }}
                        alt={"banner1"}
                      />
                    </a>
                  </div>

                  <div
                    style={{
                      width: "calc(50% - 18px)",
                      display: "inline-block",
                    }}
                  >
                    <a
                      style={{
                        display: "block",
                        width: "100%",
                        cursor: "pointer",
                      }}
                      href={banner[1].url}
                      target={banner[1].newWindow === "Y" ? "_blank" : "_self"}
                    >
                      <img
                        src={convertFilePath(banner[1].filePath)}
                        style={{
                          aspectRatio: "300/95",
                          borderRadius: "5px",
                          width: "100%",
                        }}
                        alt={"banner2"}
                      />
                    </a>
                  </div>
                </div>
              )}
              {/*  */}
              {/*  */}
              {/*  */}
              {/*  */}
              {/*  */}
              {/*@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ 7번째 단락 @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ */}
              {/*  */}
              {/*  */}
              {/*  */}
              {/*  */}
              {/*  */}
              {isNewsletterBannerSuccess && (
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                    boxSizing: "border-box",
                    padding: "0 55px",
                    marginBottom: "20px",
                    backgroundColor: "#FFFFFF",
                  }}
                >
                  <div
                    style={{
                      width: "calc(50% - 18px)",
                      marginRight: "12px",
                      display: "inline-block",
                    }}
                  >
                    <a
                      style={{
                        display: "block",
                        width: "100%",
                        cursor: "pointer",
                      }}
                      href={banner[2].url}
                      target={banner[2].newWindow === "Y" ? "_blank" : "_self"}
                    >
                      <img
                        src={convertFilePath(banner[2].filePath)}
                        style={{
                          aspectRatio: "300/95",
                          borderRadius: "5px",
                          width: "100%",
                        }}
                        alt={"banner3"}
                      />
                    </a>
                  </div>

                  <div
                    style={{
                      width: "calc(50% - 18px)",
                      display: "inline-block",
                    }}
                  >
                    <a
                      style={{
                        display: "block",
                        width: "100%",
                        cursor: "pointer",
                      }}
                      href={banner[3].url}
                      target={banner[3].newWindow === "Y" ? "_blank" : "_self"}
                    >
                      <img
                        src={convertFilePath(banner[3].filePath)}
                        style={{
                          aspectRatio: "300/95",
                          borderRadius: "5px",
                          width: "100%",
                        }}
                        alt={"banner4"}
                      />
                    </a>
                  </div>
                </div>
              )}
            </td>
          </tr>
          <tr>
            <td style={{ width: "818px" }}>
              {/*  */}
              {/*  */}
              {/*  */}
              {/*  */}
              {/*  */}
              {/*@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ 8번째 단락 @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ */}
              {/*  */}
              {/*  */}
              {/*  */}
              {/*  */}
              {/*  */}
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                  boxSizing: "border-box",
                  backgroundColor: "#FBFBFB",
                  padding: "50px 55px 40px",
                }}
              >
                <table
                  style={{
                    width: "100%",
                  }}
                >
                  <tbody>
                    <tr>
                      <td
                        style={{
                          width: "170px",
                          textAlign: "left",
                        }}
                      >
                        <img
                          src="/assets/newsletter/newsletterfooter.svg"
                          style={{
                            width: "150px",
                          }}
                          alt={"footerLogo"}
                        />
                      </td>
                      <td
                        style={{
                          width: "calc(100% - 190px)",
                          verticalAlign: "middle",
                          textAlign: "left",
                        }}
                      >
                        <p
                          style={{
                            fontSize: "10px",
                            lineHeight: "16px",
                          }}
                        >
                          [06130] 서울시 강남구 테헤란로 7길 22(역삼동 635-4)
                          한국과학기술회관 1관 2층 | TEL. 02-3420-1242 | FAX.
                          02-563-4931
                        </p>
                        <p
                          style={{
                            fontSize: "10px",
                            lineHeight: "16px",
                          }}
                        >
                          정기간행물 등록번호 : 서울 아 00067 | 등록일자 :
                          2005.09.26 | 발행일자 : 2005.09.27 | 발행인 : 이태식 |
                          편집인 : 강건기
                        </p>
                        <p
                          style={{
                            fontSize: "10px",
                            lineHeight: "16px",
                          }}
                        >
                          청소년보호책임자 : 오승원
                        </p>
                        <p
                          style={{
                            fontSize: "10px",
                            lineHeight: "16px",
                          }}
                        >
                          COPYRIGHT (C) KOFST. ALL RIGHTS RESERVED
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {/*  */}
              {/*  */}
              {/*  */}
              {/*  */}
              {/*  */}
              {/*@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ 9번째 단락 @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ */}
              {/*  */}
              {/*  */}
              {/*  */}
              {/*  */}
              {/*  */}
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                  boxSizing: "border-box",
                  backgroundColor: "#23295C",
                  color: "white",
                  padding: "10px 0 14px",
                }}
              >
                <p
                  style={{
                    fontSize: "12px",
                    lineHeight: "20px",
                  }}
                >
                  한국과총 Webzine은 한국과학기술단체총연합회에서 발행하는
                  뉴스레터입니다.
                </p>
                {/* <p
                  style={{
                    fontSize: "10px",
                    lineHeight: "20px",
                  }}
                >
                  메일의 수신을 원치 않으시면
                  <a
                    style={{
                      color: "white",
                      cursor: "pointer",
                    }}
                    href={`${window.location.origin}/unsubscribe?key={}`}
                    target="_blank"
                  >
                    &nbsp; [여기]&nbsp;
                  </a>
                  를 클릭해 주세요. 본 메일은 발신전용 메일로 회신이 되지 않으며
                  문의사항은 webmaster 메일을 이용해주세요
                </p> */}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      {isAdmin && (
        <Box
          display={Box.DISPLAY.FLEX}
          al="center"
          jc="center"
          gap="12px"
          width="100%"
          padding="40px 0"
          bgColor={Box.COLOR.ADMINCONTENTSBG}
        >
          <Button
            onClick={handleCancel}
            themeStyle="adminCancel"
            padding="12px 40px"
          >
            취소
          </Button>
          <Button
            themeStyle="adminSave"
            padding="12px 40px"
            onClick={handleSave}
          >
            저장
          </Button>
        </Box>
      )}
    </div>
  );
};

export default NewsletterTemplate;
