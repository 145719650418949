import {
  getBoardCalendar,
  getBoardCalendarMonth,
  getBoardDaily,
  getBoardDailyMonth,
  getBoardDetail,
  getBoardFeature,
  getBoardNews,
  updateViewCount,
} from "api/board";
import { useEffect } from "react";
import { useMutation, useQueries, useQuery, useQueryClient } from "react-query";

export const useBoardNews = ({ type, category, page, limit }) => {
  const { data, isLoading, isSuccess, isError } = useQuery(
    ["board", type, category, page, limit],
    () => getBoardNews(type, category, page, limit),
    {
      staleTime: 5 * 60 * 1000, // 5 minutes in milliseconds
      cacheTime: 10 * 60 * 1000, // 10 minutes in milliseconds
    }
  );

  const list = data?.data.list;
  const totalCnt = data?.data.totalCnt;
  const totalPageIndex = data?.data.totalPageIndex;
  const listviewList = data?.data.listviewList;
  const listviewBasic = data?.data.listviewBasic;

  return {
    list,
    totalCnt,
    totalPageIndex,
    listviewList,
    listviewBasic,
    isLoading,
    isSuccess,
    isError,
  };
};

export const usePreFetchBoardNews = ({ type, category, page, limit }) => {
  const queryClient = useQueryClient();

  useEffect(() => {
    const pageTerm = parseInt((page - 1) / 3);
    const start = pageTerm * 3 < 1 ? 1 : pageTerm * 3;
    const end = (pageTerm + 1) * 3 + 1;

    for (let i = start; i <= end; i++) {
      const page = String(i);

      queryClient.prefetchQuery(
        ["board", type, category, page, limit],
        () => getBoardNews(type, category, page, limit),
        {
          staleTime: 5 * 60 * 1000, // 5 minutes in milliseconds
          cacheTime: 10 * 60 * 1000, // 10 minutes in milliseconds
        }
      );
    }
  }, [category, page, limit, type, queryClient]);
};

export const useBoardDetail = (id) => {
  const {
    data: res,
    isLoading,
    isSuccess,
    isError,
  } = useQuery(["detail", id], () => getBoardDetail(id));

  const data = res?.data;

  return {
    data,
    isLoading,
    isSuccess: isSuccess && data,
    isError,
  };
};

export const useBoardDaily = ({ category, date, page, limit }) => {
  const { data, isLoading, isSuccess, isError } = useQuery(
    ["daily", category, date, page, limit],
    () => getBoardDaily(category, date, page, limit)
  );

  const list = data?.data.list;
  const totalCnt = data?.data.totalCnt;
  const totalPageIndex = data?.data.totalPageIndex;

  return {
    list,
    totalCnt,
    totalPageIndex,
    isLoading,
    isSuccess,
    isError,
  };
};

export const useBoardDailyMonth = ({ date }) => {
  const curr = date.format("YYYYMM");
  const before = date.clone().subtract(1, "month").format("YYYYMM");
  const after = date.clone().add(1, "month").format("YYYYMM");

  const [
    {
      data: beforeData,
      isLoading: isBeforeLoading,
      isSuccess: isBeforeSuccess,
      isError: isBeforeError,
    },
    {
      data: currData,
      isLoading: isCurrLoading,
      isSuccess: isCurrSuccess,
      isError: isCurrError,
    },
    {
      data: afterData,
      isLoading: isAfterLoading,
      isSuccess: isAfterSuccess,
      isError: isAfterError,
    },
  ] = useQueries([
    {
      queryKey: ["month", before],
      queryFn: () => getBoardDailyMonth(before),
    },
    {
      queryKey: ["month", curr],
      queryFn: () => getBoardDailyMonth(curr),
    },
    {
      queryKey: ["month", after],
      queryFn: () => getBoardDailyMonth(after),
    },
  ]);

  const list = [];

  if (isBeforeSuccess) {
    list.push(...beforeData.data.list);
  }

  if (isCurrSuccess) {
    list.push(...currData.data.list);
  }

  if (isAfterSuccess) {
    list.push(...afterData.data.list);
  }

  return {
    list,
    isLoading: isBeforeLoading || isCurrLoading || isAfterLoading,
    isError: isBeforeError || isCurrError || isAfterError,
  };
};

export const useBoardCalendar = ({
  type,
  category,
  date,
  page,
  searchKeyword,
}) => {
  const { data, isLoading, isSuccess, isError } = useQuery(
    ["calendar", type, category, date, page, searchKeyword],
    () => getBoardCalendar(type, category, date, page, searchKeyword)
  );

  const list = data?.data.list;
  const totalCnt = data?.data.totalCnt;
  const totalPageIndex = data?.data.totalPageIndex;

  const listviewList = data?.data.listviewList;
  const listviewBasic = data?.data.listviewBasic;

  return {
    list,
    totalCnt,
    totalPageIndex,
    listviewList,
    listviewBasic,
    isLoading,
    isSuccess,
    isError,
  };
};

export const useBoardCalendarMonth = ({ type, category, date }) => {
  const curr = date.format("YYYYMM");
  const before = date.clone().subtract(1, "month").format("YYYYMM");
  const after = date.clone().add(1, "month").format("YYYYMM");

  const [
    {
      data: beforeData,
      isLoading: isBeforeLoading,
      isSuccess: isBeforeSuccess,
      isError: isBeforeError,
    },
    {
      data: currData,
      isLoading: isCurrLoading,
      isSuccess: isCurrSuccess,
      isError: isCurrError,
    },
    {
      data: afterData,
      isLoading: isAfterLoading,
      isSuccess: isAfterSuccess,
      isError: isAfterError,
    },
  ] = useQueries([
    {
      queryKey: [type, before],
      queryFn: () => getBoardCalendarMonth(type, category, before),
    },
    {
      queryKey: [type, curr],
      queryFn: () => getBoardCalendarMonth(type, category, curr),
    },
    {
      queryKey: [type, after],
      queryFn: () => getBoardCalendarMonth(type, category, after),
    },
  ]);

  const list = [];

  if (isBeforeSuccess) {
    list.push(...beforeData.data.list);
  }

  if (isCurrSuccess) {
    list.push(...currData.data.list);
  }

  if (isAfterSuccess) {
    list.push(...afterData.data.list);
  }

  return {
    list,
    isLoading: isBeforeLoading || isCurrLoading || isAfterLoading,
    isError: isBeforeError || isCurrError || isAfterError,
  };
};

export const useBoardFeature = ({ page, limit }) => {
  const { data, isLoading, isSuccess, isError } = useQuery(
    ["feature", page, limit],
    () => getBoardFeature(page, limit)
  );

  const list = data?.data.list;
  const totalCnt = data?.data.totalCnt;
  const totalPageIndex = data?.data.totalPageIndex;

  return {
    list,
    totalCnt,
    totalPageIndex,
    isLoading,
    isSuccess,
    isError,
  };
};

export const useUpdateViewCountMutation = () => {
  const updateViewCountMutation = useMutation(updateViewCount);

  const submit = (key) => {
    updateViewCountMutation.mutate(key);
  };

  return submit;
};
