import Box from "components/atoms/box";
import Text from "components/atoms/text";
import List from "components/molecules/list";
import MobileBoard from "components/organisms/mobileBoard";
import MobileNewsCategory from "components/organisms/mobileNewsCategory";
import NewsList from "components/organisms/newsList";
import { useHeadLineNews } from "hooks/api/news";
import useUrl from "hooks/utils/useUrl";
import React from "react";

const MobileNews = () => {
  const {
    query: { category = "all" },
  } = useUrl();

  const boardType = "news";
  const isAllPage = category === "all";
  const isSchedule = category === "COM045_AspbHPe";

  return (
    <>
      <Box margin={"145px 0 0 0"} padding={"20px 12px 10px"}>
        {isAllPage && (
          <>
            <Box
              width={"100%"}
              bgColor={Box.COLOR.LIGHTGRAY}
              borderRadius={"10px"}
              padding={"20px 24px"}
            >
              <NewsList
                title="주요"
                bold="News"
                size={Text.SIZE.MOBILETITLE}
                margin={"0 0 20px"}
              >
                <List api={useHeadLineNews}>
                  {(list) =>
                    list && (
                      <NewsList.LAYOUT.MobileHeadLineNews
                        list={list}
                        borderFilter={(idx) => idx !== 1}
                      />
                    )
                  }
                </List>
              </NewsList>
            </Box>

            <Box margin={"20px 0 0"}>
              <MobileNewsCategory />
            </Box>
          </>
        )}
      </Box>

      <MobileBoard
        isAllPage={isAllPage}
        padding={isSchedule ? "32px 0" : "32px 24px"}
      >
        {isSchedule ? (
          <Box width={"100%"}>
            <Text
              as={Text.VARIANT.TEXT}
              margin={"0 24px"}
              size={Text.SIZE.MOBILECATEGORY}
            >
              Today
            </Text>
            <MobileBoard.Calendar isCoverage />
          </Box>
        ) : (
          <MobileBoard.News category={category} boardType={boardType} />
        )}
      </MobileBoard>
    </>
  );
};

export default MobileNews;
