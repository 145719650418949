import Box from "components/atoms/box";
import Loading from "components/atoms/loading";
import ImageUploader from "components/molecules/imageUploader";
import InputForm from "components/molecules/inputForm";
import AdminDetailForm from "components/organisms/adminDetailForm";
import AdminDetailFormContainer from "components/organisms/adminDetailFormContainer";
import EventDatePicker from "components/organisms/eventDatePicker";
import { AdminContentsContainer } from "components/templates/admin/admin.style";
import useUrl from "hooks/utils/useUrl";
import React, { useRef } from "react";
import useEventStore from "store/useEventStore";
import NamoWebEditor from "components/molecules/namoWebEditor";
import FileUploader from "components/molecules/fileUploader";
import {
  useCreateArticle,
  useNewsBoardDetailList,
  useUpdateArticleInfo,
} from "hooks/api/admin/news_board";
import TextArea from "components/molecules/textarea";
import AdminDisplayOption from "components/molecules/adminDisplayOption";
import SelectTwoDepthMenu from "components/molecules/adminSelectTwoDepthMenu";
import { useState } from "react";
import { useEffect } from "react";
import { useCategoryList } from "hooks/api/admin/category";
import { newsboard } from "text/admin/newsboard";
import usePendingMutate from "hooks/utils/usePendingMutate";

const category = {
  news: "COM045",
  feature: "COM046",
  people: "COM047",
  science: "COM048",
};

const NewsBoardDetail = () => {
  const {
    query: { key, categoryName },
  } = useUrl();

  const { depth1Options, defaultValues } = newsboard.detail;

  const subTitle = key ? "게시물 수정" : "게시물 등록";

  const handleSuccess = () => {
    localStorage.removeItem("newsboard");
  };

  const [depth1Menu, setDepth1Menu] = useState("");
  const [depth2Menu, setDepth2Menu] = useState("");
  const [depth2Options, setDepth2Options] = useState([]);

  const { list, isLoading, fileList } = useNewsBoardDetailList({ categoryName, key });
  const { isPending, handleMutate } = usePendingMutate();

  const { list: defaultList } = useCategoryList({ categoryName: depth1Menu });

  const api = key ? useUpdateArticleInfo : useCreateArticle;
  const mutate = api(handleSuccess);

  const {
    selectedEndDate,
    selectedEndHours,
    selectedEndMinutes,
    setSelectedEndHours,
    setSelectedEndMinutes,
  } = useEventStore();

  useEffect(() => {
    if (list) {
      const defaultCate1 = depth1Options.filter(({ value }) => category[value] === list.cate1);
      setDepth1Menu(defaultCate1[0].value);

      const hours = list.regDate.substring(8, 10);
      const minutes = list.regDate.substring(10, 12);
      setSelectedEndHours(hours || "00");
      setSelectedEndMinutes(minutes || "00");
    }
  }, [list]);

  useEffect(() => {
    if (defaultList) {
      setDepth2Options(
        defaultList
          ?.map(({ categoryName, key }) => ({
            title: categoryName,
            value: key,
          }))
          .filter((option) => {
            if (option.title === "과총 동정" || option.title === "과학/IT 속보") {
              return;
            } else {
              return option.title;
            }
          })
      );
    }
  }, [defaultList]);

  useEffect(() => {
    if (list) {
      const defaultDepth2 = depth2Options.filter(({ value }) => value === list.cate2);
      setDepth2Menu(defaultDepth2[0]?.value);
    }
  }, [list, depth2Options]);

  const _instanceRef = useRef(null);

  const formInfo = [
    {
      title: "카테고리 선택",
      height: "48px",
      components: (
        <SelectTwoDepthMenu
          depth1Menu={depth1Menu}
          setDepth1Menu={setDepth1Menu}
          depth2Menu={depth2Menu}
          setDepth2Menu={setDepth2Menu}
          depth1Options={depth1Options}
          depth2Options={depth2Options}
          width="150px"
        />
      ),
      required: true,
    },
    {
      title: "머리말",
      height: "48px",
      components: (
        <InputForm
          name="foreword"
          type="text"
          width="395px"
          height="32px"
          padding="0 12px"
          theme="secondary"
          defaultName={list?.foreword ?? ""}
          ariaLabel="머리말"
        />
      ),
      required: false,
    },
    {
      title: "작성자",
      minHeight: "48px",
      components: (
        <InputForm
          name="writer"
          type="text"
          width="395px"
          height="32px"
          padding="0 12px"
          theme="secondary"
          defaultName={list?.writer}
          ariaLabel="작성자"
          required="필수값 입니다."
        />
      ),
      required: true,
    },
    {
      title: "등록일",
      height: "48px",
      components: <EventDatePicker list={list} isRegisterDate={true} />,
      required: true,
    },
    {
      title: "이메일",
      minHeight: "48px",
      components: (
        <InputForm
          name="email"
          type="text"
          width="395px"
          height="32px"
          padding="0 12px"
          theme="secondary"
          defaultName={list?.email}
          ariaLabel="이메일"
          required="필수값 입니다."
        />
      ),
      required: true,
    },
    {
      title: "진열 옵션",
      height: "58px",
      components: <AdminDisplayOption name="mainNewsYN" displayStatus={list?.mainNewsYN} />,
      required: false,
    },
    {
      title: "제목",
      minHeight: "48px",
      components: (
        <InputForm
          name="title"
          type="text"
          width="395px"
          height="32px"
          padding="0 12px"
          theme="secondary"
          defaultName={list?.title}
          ariaLabel="제목"
          required="필수값 입니다."
        />
      ),
      required: true,
    },
    {
      title: "요약내용",
      minHeight: "160px",
      components: (
        <TextArea
          name="summaryContents"
          width="395px"
          height="144px"
          padding="12px"
          defaultValue={list?.summaryContents}
          ariaLabel="요약내용"
          required="필수값 입니다."
        />
      ),
      required: true,
    },
    {
      title: "내용",
      minHeight: "436px",
      components: <NamoWebEditor name="editor" defaultValue={list?.contents} ref={_instanceRef} />,
      required: true,
      isUpperPosition: true,
    },
    {
      title: "첨부파일",
      minHeight: "48px",
      components: <FileUploader registerName="files" defaultFileList={fileList} no={list?.key} />,
      required: false,
    },
    {
      title: "썸네일 등록",
      minHeight: "281px",
      components: (
        <ImageUploader
          registerName="thumbnail"
          comments={["- 권장 이미지 : 596px*400px / 10mb 이하 / jpg,png 파일"]}
          required={false}
          serverImgPath="thumbnail"
          defaultPath={list?.thumbnail ?? null}
        />
      ),
      required: false,
      isUpperPosition: true,
    },
  ];

  const createFormData = (data, contents, regDate) => {
    const formData = new FormData();

    formData.append(
      "param",
      JSON.stringify({
        cate1: category[data.cate1],
        cate2: data.cate2,
        contents,
        email: data.email,
        foreword: data.foreword,
        mainNewsYN: data.mainNewsYN ? "Y" : "N",
        writer: data.writer,
        regDate,
        summaryContents: data.summaryContents,
        title: data.title,
      })
    );
    if (data.files && data.files.length > 0) {
      for (let i = 0; i < data.files.length; i++) {
        formData.append("files", data.files[i]);
      }
    }
    if (data.thumbnail) {
      formData.append("thumbnail", data.thumbnail);
    }
    return formData;
  };

  const date = selectedEndDate.format("YYYYMMDD");
  const regDate = `${date}${selectedEndHours}${selectedEndMinutes}00`;

  const handleSubmit = (data) => {
    const contents = _instanceRef.current.GetBodyValue();

    if (!category[data.cate1]) {
      alert("1차 카테고리를 선택해주세요.");
      return;
    } else if (!data.cate2) {
      alert("2차 카테고리를 선택해주세요.");
      return;
    }

    const formData = createFormData(data, contents, regDate);

    handleMutate(mutate, { data: formData, key });
  };

  if (isLoading) return <Loading />;

  return (
    <AdminContentsContainer>
      <Box maxWidth="1024px">
        <AdminDetailFormContainer
          title={subTitle}
          defaultValues={defaultValues}
          handleSubmit={handleSubmit}
          isPending={isPending}
          //
          setDepth1Menu={setDepth1Menu}
          setDepth2Menu={setDepth2Menu}
          hasTemporaryFunc={true}
          regDate={regDate}
          ref={_instanceRef}
        >
          <AdminDetailForm formInfo={formInfo} />
        </AdminDetailFormContainer>
      </Box>
    </AdminContentsContainer>
  );
};

export default NewsBoardDetail;
