import Box from "components/atoms/box";
import React from "react";
import NewsList from "../newsList";
import List from "components/molecules/list";
import Blank from "components/molecules/blank";
import { useState } from "react";
import useUrl from "hooks/utils/useUrl";
import { useEffect } from "react";
import {
  useBoardCalendar,
  useBoardNews,
  usePreFetchBoardNews,
} from "hooks/api/board";
import Text from "components/atoms/text";
import MobileSideNews from "../mobileSideNews";
import dayjs from "dayjs";
import MobileCalendarHeader from "../mobileCalendarHeader";
import SearchInput from "components/molecules/searchInput";
import CalendarList from "../calendarList";
import calendarText from "text/calendar";

const { searchInput, search } = calendarText;

const NewsCalendar = ({ isCoverage }) => {
  const {
    query: {
      category = "COM065_meJ9Dpf",
      date = dayjs().format("YYYYMMDD"),
      page = "1",
      searchKeyword = "",
    },
    changeQuery,
  } = useUrl();

  const [length, setLength] = useState(0);
  const [typeForm, setTypeForm] = useState([]);
  const [basicForm, setBasicForm] = useState(null);

  const handleSearch = (value) => {
    changeQuery({
      searchKeyword: value,
      page: 1,
    });
  };

  return (
    <>
      <Box padding={isCoverage ? "0 24px" : "20px 0 0 40px"}>
        <MobileCalendarHeader
          calendar={date}
          format={"YYYY년 MM월 DD일 dd요일"}
          category={"date"}
        />
      </Box>
      <Box
        width={"100%"}
        padding={isCoverage ? "30px 24px 16px" : "30px 40px 16px"}
        borderBottom={length !== 0 ? "1px solid" : ""}
        borderColor={Box.COLOR.LINEGRAY}
      >
        <SearchInput
          theme={SearchInput.THEME.HEADER}
          placeholder={searchInput}
          submit={handleSearch}
        />
        {searchKeyword && (
          <Text
            as={Text.VARIANT.TEXT}
            size={Text.SIZE.MOBILECONTENT}
            margin={"6px 0 0 15px"}
          >
            <Text
              as={Text.VARIANT.SPAN}
              color={Text.COLOR.SECONDARY}
              weight={Text.WEIGHT.BOLD}
            >
              {searchKeyword}
            </Text>{" "}
            {search[0]}
            <Text
              as={Text.VARIANT.SPAN}
              color={Text.COLOR.SECONDARY}
              weight={Text.WEIGHT.BOLD}
            >
              {length}{" "}
            </Text>
            {search[1]}
          </Text>
        )}
      </Box>

      <Box
        display={Box.DISPLAY.FLEX}
        jc="flex-end"
        width={"100%"}
        margin={isCoverage ? "21px 0 8px" : length !== 0 ? "21px 0 8px" : "0"}
        padding={"0 24px"}
      >
        <NewsList.Type isMobile typeForm={typeForm} basicForm={basicForm} />
      </Box>

      <Box padding={isCoverage ? "0" : "0 0 32px"} width={"100%"}>
        <List
          api={useBoardCalendar}
          params={{
            type: isCoverage ? "news" : "calendar",
            category,
            date,
            page,
            searchKeyword,
          }}
        >
          {(list, { listviewList, listviewBasic }) =>
            list && (
              <CalendarList
                list={list}
                setLength={setLength}
                isMobile
                setTypeForm={setTypeForm}
                setBasicForm={setBasicForm}
                listviewList={listviewList}
                listviewBasic={listviewBasic}
                isCoverage={isCoverage}
              />
            )
          }
        </List>
      </Box>
    </>
  );
};

const newsListType = {
  title: NewsList.LAYOUT.TextNews,
  summary: NewsList.LAYOUT.LineSmallImageNews,
  photo: NewsList.LAYOUT.MobileThumbnailNews,
};

const NewsBoard = (props) => {
  const { boardType, isAllPage, category } = props;
  const [typeForm, setTypeForm] = useState(null);
  const [basicForm, setBasicForm] = useState("title");
  const isTypeForm = (typeForm && typeForm.length > 1) || isAllPage;

  const {
    query: { type, page = "1" },
  } = useUrl();

  const newsType = type ? type : basicForm;
  const NewsForm = newsListType[newsType] ?? newsListType.title;

  const NewsBoardList = ({ list, listviewList, listviewBasic, isAllPage }) => {
    useEffect(() => {
      setTypeForm(listviewList);
      if (listviewBasic) setBasicForm(listviewBasic);
    }, [listviewList, isAllPage, listviewBasic]);

    return (
      <NewsForm
        list={list.slice(0, 4)}
        category={boardType}
        boxPadding={"12px 0"}
        width={2}
        isBorder
        isBlank
        isMobile
      />
    );
  };

  return (
    <NewsList
      size={Text.SIZE.MOBILETITLE}
      title={isAllPage ? "전체기사" : ""}
      sideBar={
        isTypeForm &&
        (() => (
          <NewsList.Type
            isMobile
            isAllPage={isAllPage}
            typeForm={typeForm}
            basicForm={basicForm}
          />
        ))
      }
      margin="0 0 20px"
    >
      <List
        api={useBoardNews}
        preFetch={usePreFetchBoardNews}
        params={{ type: boardType, category, page, limit: 4 }}
        margin={"50px 0 18px"}
      >
        {(list, { listviewList, listviewBasic }) =>
          list && (
            <>
              {list.length === 0 && <Blank isMobile />}
              {list.length !== 0 && (
                <NewsBoardList
                  list={list}
                  isAllPage={isAllPage}
                  listviewList={listviewList}
                  listviewBasic={listviewBasic}
                />
              )}
            </>
          )
        }
      </List>
    </NewsList>
  );
};

const MobileBoard = (props) => {
  const { isAllPage, isTransparent, padding = "32px 24px", children } = props;

  const childrenWithProps = React.Children.map(children, (child) =>
    React.cloneElement(child, { isAllPage })
  );

  return (
    <>
      <Box padding={"0 12px"}>
        <Box
          display={Box.DISPLAY.FLEX}
          wrap={"wrap"}
          width={"100%"}
          padding={padding}
          margin={isAllPage ? "20px 0 10px" : "0 0 10px"}
          bgColor={isTransparent ? Box.COLOR.WHITE : Box.COLOR.LIGHTGRAY}
          borderRadius="10px"
          id={"board"}
        >
          {childrenWithProps}
        </Box>
      </Box>
      {!isAllPage && <MobileSideNews />}
    </>
  );
};

MobileBoard.News = NewsBoard;
MobileBoard.Calendar = NewsCalendar;

export default MobileBoard;
