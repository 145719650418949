import Box from "components/atoms/box";
import Text from "components/atoms/text";
import React from "react";

const HasNotResult = () => {
  return (
    <Box
      display={Box.DISPLAY.FLEX}
      al="center"
      jc="center"
      height="106px"
      borderLeft="1px solid"
      borderRight="1px solid"
      borderBottom="1px solid"
      borderColor={Box.COLOR.ADMINLINEGRAY}
      bgColor={Box.COLOR.WHITE}
    >
      <Text as={Text.VARIANT.TEXT}>검색 결과가 없습니다.</Text>
    </Box>
  );
};

export default HasNotResult;
