import React, { useEffect, useRef } from "react";
import Box from "components/atoms/box";
import Text from "components/atoms/text";
import convertImage from "utils/convertImage";
import Image from "components/atoms/image";
import Button from "components/atoms/button";
import useUrl from "hooks/utils/useUrl";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import Link from "components/atoms/link";
import "dayjs/locale/ko";
import { FiChevronRight } from "@react-icons/all-files/fi/FiChevronRight";
import { Select } from "antd";
import commonText from "text/common";
import useMobileStore from "store/useMobileStore";

const { news } = commonText;

dayjs.extend(weekday);
dayjs.locale("ko");

export const NewsInfo = ({
  margin,
  writer,
  regDate,
  modDate,
  commentCnt,
  size = Text.SIZE.CLIENTETC,
  isMobile,
}) => {
  const exChangeDate = (dateStr) => {
    const year = dateStr.substring(0, 4);
    const month = dateStr.substring(4, 6);
    const day = dateStr.substring(6, 8);
    const hour = dateStr.substring(8, 10);
    const minute = dateStr.substring(10, 12);

    const date = new Date(year, month - 1, day, hour, minute);

    return dayjs(date).format(
      isMobile ? "MM월 DD일 HH:mm" : "YYYY-MM-DD (dd) HH:mm"
    );
  };

  return (
    <Text
      as={Text.VARIANT.TEXT}
      margin={margin}
      size={isMobile ? Text.SIZE.MOBILECONTENT : size}
      color={Text.COLOR.TEXTGRAY}
    >
      {writer}
      <Text
        as={Text.VARIANT.SPAN}
        padding={"0 5px"}
        margin={"0 5px"}
        borderLeft={"1px solid"}
        borderRight={isMobile ? "" : "1px solid"}
        borderColor={Text.COLOR.LINEGRAY}
      >
        {news.info.regDate} {exChangeDate(regDate)}
      </Text>
      {!isMobile && (
        <>
          {modDate && (
            <Text
              as={Text.VARIANT.SPAN}
              padding={"0 5px 0 0 "}
              margin={"0 5px 0 0"}
              borderRight={"1px solid"}
              borderColor={Text.COLOR.LINEGRAY}
            >
              {news.info.modDate} {exChangeDate(modDate)}
            </Text>
          )}
          {news.info.comment}{" "}
          <Text as={Text.VARIANT.SPAN} color={Text.COLOR.SECONDARY}>
            {commentCnt}
          </Text>
        </>
      )}
    </Text>
  );
};

const BigImageNews = (props) => {
  const {
    link,
    category,
    cate2,
    //
    thumbnail,
    title,
    contents,
    //
    isMobile = false,
    //
    margin,
    lineClamp,
    aspectRatio,
    ariaLabel,
    //
    titleSize,
    contentSize,
  } = props;

  return (
    <Link
      link={`/${category}/${link}${cate2 ? `?category=${cate2}` : ""}`}
      width={"100%"}
    >
      <Image
        width={"100%"}
        radius={"0 20px"}
        aspectRatio={aspectRatio}
        //
        src={convertImage(thumbnail, "thumbnail")}
        alt={title}
        ariaLabel={`${title} 뉴스 링크`}
      />
      <Text
        as={Text.VARIANT.SUBTITLE}
        ariaLabel={`${title} 뉴스 링크`}
        size={titleSize}
        //
        margin={margin}
        lineHeight={isMobile ? "17px" : "24.2px"}
        lineClamp={1}
      >
        {title}
      </Text>

      <Text
        as={Text.VARIANT.TEXT}
        ariaLabel={`${ariaLabel} 본문`}
        //
        size={contentSize}
        lineHeight={isMobile ? "17px" : "22px"}
        lineClamp={lineClamp}
      >
        {contents}
      </Text>
    </Link>
  );
};

const SmallImageNews = (props) => {
  const {
    link,
    category,
    cate2,
    cate2Name,
    //
    thumbnail,
    title,
    contents,
    isBlank,

    //
    boxPadding,
    width,
    margin,
    lineClamp,
    aspectRatio,
    //
    titleSize,
    contentSize,
    //
    isLast,
    isSort = true,
    //
    ariaLabel,
  } = props;

  const { isMobile } = useMobileStore();

  return (
    <Box
      display={"flex"}
      width={width}
      height={"auto"}
      padding={boxPadding}
      margin={!isLast && isSort ? "0 12px 0 0" : ""}
      ariaLabel={`${title} 뉴스 링크`}
    >
      <Link
        link={`/${category}/${link}${cate2 ? `?category=${cate2}` : ""}`}
        width={"100%"}
      >
        <Image
          width={"100%"}
          radius={"0 20px"}
          aspectRatio={aspectRatio}
          //
          src={convertImage(thumbnail, "thumbnail")}
          alt={title}
          ariaLabel={`${title} 뉴스 링크`}
        />
        {isMobile && cate2Name && (
          <Text
            as={Text.VARIANT.SPAN}
            //
            size={titleSize}
            weight={Text.WEIGHT.BOLD}
            //
            color={Text.COLOR.SECONDARY}
            margin={"0 8px 0 0"}
          >
            [{cate2Name}]
          </Text>
        )}
        <Text
          as={Text.VARIANT.SUBTITLE}
          ariaLabel={`${title} 제목`}
          //
          margin={margin}
          size={titleSize}
          lineClamp={1}
          lineHeight={"1.1"}
        >
          {!isMobile && cate2Name && (
            <Text
              as={Text.VARIANT.SPAN}
              //
              size={titleSize}
              weight={Text.WEIGHT.BOLD}
              //
              color={Text.COLOR.SECONDARY}
              margin={"0 8px 0 0"}
            >
              [{cate2Name}]
            </Text>
          )}
          {title}
        </Text>

        {(contents || isBlank) && (
          <Text
            as={Text.VARIANT.TEXT}
            ariaLabel={`${title} 본문`}
            //
            height={"36px"}
            size={contentSize}
            lineClamp={lineClamp}
            lineHeight={isMobile ? "17px" : "18px"}
          >
            {contents}
          </Text>
        )}
      </Link>
    </Box>
  );
};

const LineImageNews = (props) => {
  const {
    link,
    isSearch,
    //
    category = "detail",
    thumbnail,
    title,
    bold,
    contents,
    writer,
    regDate,
    commentCnt,
    cate2Name,
    cate2,
    //
    isBorder,
    isCenter,
    isMain,
    //
    imageWidth,
    boxPadding,
    margin,
    padding,
    lineClamp,
    aspectRatio,
    //
    titleSize,
    contentSize,
    //
    ariaLabel,
  } = props;

  const { isMobile } = useMobileStore();

  return (
    <Link link={`/${category}/${link}?category=${cate2}`} width={"100%"}>
      <Box
        display={Box.DISPLAY.FLEX}
        jc={"space-between"}
        //
        width={"100%"}
        padding={boxPadding}
        //
        borderBottom={isBorder ? "1px solid" : ""}
        borderColor={Box.COLOR.LINEGRAY}
        ariaLabel={`${title} 뉴스 링크`}
      >
        <Box width={imageWidth} ariaLabel={`${title} 뉴스 링크`}>
          <Image
            width={"100%"}
            radius={"0 20px"}
            aspectRatio={aspectRatio}
            //
            src={convertImage(thumbnail, "thumbnail")}
            ariaLabel={title}
            alt={title}
          />
        </Box>
        <Box
          display={Box.DISPLAY.FLEX}
          flexDirection={"column"}
          jc={isCenter ? "center" : "flex-start"}
          //
          width={`calc(100% - ${imageWidth})`}
          padding={padding}
          ariaLabel={`${title} 뉴스 링크`}
        >
          {isMobile && !isSearch && cate2Name && (
            <Text
              as={Text.VARIANT.SPAN}
              //
              size={titleSize}
              weight={Text.WEIGHT.BOLD}
              //
              color={Text.COLOR.SECONDARY}
              margin={"0 8px 0 0"}
            >
              [{cate2Name}]
            </Text>
          )}
          {!isMobile && !isSearch && cate2Name && (
            <Text
              as={Text.VARIANT.SPAN}
              //
              color={Text.COLOR.SECONDARY}
              margin={"0 0 5px 0"}
              //
              size={titleSize}
              weight={Text.WEIGHT.BOLD}
            >
              [{cate2Name}]
            </Text>
          )}
          <Text
            as={Text.VARIANT.SUBTITLE}
            ariaLabel={`${ariaLabel} 제목`}
            //
            margin={margin}
            size={titleSize}
            lineClamp={1}
            lineHeight={isMobile ? "17px" : "22px"}
          >
            {bold && (
              <Text
                as={Text.VARIANT.SPAN}
                //
                color={Text.COLOR.SECONDARY}
                margin={"0 8px 0 0"}
              >
                {bold}
              </Text>
            )}

            {title}
          </Text>

          <Text
            as={Text.VARIANT.TEXT}
            ariaLabel={`${ariaLabel} 본문`}
            //
            size={contentSize}
            lineClamp={lineClamp}
            lineHeight={isMobile ? "17px" : isMain ? "18px" : "22px"}
            height={isMobile ? "18px" : isMain ? "36px" : "66px"}
          >
            {contents}
          </Text>

          {writer && (
            <NewsInfo
              margin={isMobile ? "4px 0 0" : "26px 0 0"}
              writer={writer}
              regDate={regDate}
              commentCnt={commentCnt}
              isMobile={isMobile}
            />
          )}
        </Box>
      </Box>
    </Link>
  );
};

const LineTextNews = (props) => {
  const {
    link,
    category = "detail",
    //
    isSearch,
    isMobile,
    //
    cate1Name,
    cate2Name,
    cate2,
    title,
    bold,
    contents = "",
    writer,
    regDate,
    modDate,
    commentCnt,
    //
    margin,
    padding,
    lineClamp,
    highlight,
    //
    titleSize,
    contentSize,
    titleWeight,
    //
    isSideNews,
    ariaLabel,
  } = props;

  const titleRef = useRef();
  const highlightRef = useRef();

  useEffect(() => {
    if (highlight) {
      let titleText = titleRef.current.innerHTML;
      const hightLightText = highlightRef.current.innerHTML;

      titleText = titleText.replace(highlight, hightLightText);

      titleRef.current.innerHTML = titleText;
    }
  }, [highlight]);

  return (
    <Link link={`/${category}/${link}?category=${cate2}`} width={"100%"}>
      <Box display={Box.DISPLAY.NONE} ref={highlightRef}>
        <Text as={Text.VARIANT.SPAN} bgColor={Text.COLOR.HIGHLIGHT}>
          {highlight}
        </Text>
      </Box>
      <Box
        width={"100%"}
        padding={padding}
        //
        borderBottom={"1px solid"}
        borderColor={Box.COLOR.LINEGRAY}
      >
        {isSearch && (
          <Text
            as={Text.VARIANT.TEXT}
            size={titleSize}
            color={Text.COLOR.SECONDARY}
            weight={Text.WEIGHT.BOLD}
            margin={isMobile ? "0" : "0 0 12px 0"}
            isSort
          >
            {cate1Name}
            <FiChevronRight />
            {cate2Name}
          </Text>
        )}
        {!isSearch && isMobile && (
          <Text
            as={Text.VARIANT.TEXT}
            size={titleSize}
            color={Text.COLOR.SECONDARY}
            weight={Text.WEIGHT.BOLD}
          >
            [{cate2Name}]
          </Text>
        )}

        {!isMobile && !isSearch && cate2Name && (
          <Text
            as={Text.VARIANT.SPAN}
            size={titleSize}
            weight={titleWeight}
            color={Text.COLOR.SECONDARY}
            margin={"0 0  8px 0"}
          >
            [{cate2Name}]
          </Text>
        )}
        <Text
          as={Text.VARIANT.SUBTITLE}
          ariaLabel={`${ariaLabel} 제목`}
          //
          margin={margin}
          //
          size={titleSize}
          weight={titleWeight}
          lineClamp={1}
          ref={titleRef}
          lineHeight={isMobile ? "14px" : "22px"}
        >
          {bold && (
            <Text
              as={Text.VARIANT.SPAN}
              color={Text.COLOR.SECONDARY}
              margin={"0 8px 0 0"}
            >
              {bold}
            </Text>
          )}

          {title}
        </Text>

        {!isSideNews && (
          <Text
            as={Text.VARIANT.TEXT}
            ariaLabel={`${ariaLabel} 본문`}
            //
            lineClamp={lineClamp}
            lineHeight={isMobile ? "17px" : "22px"}
            size={contentSize}
            margin={isMobile ? "4px 0 0 0" : "12px 0 0 0"}
            height={isMobile ? "34px" : "66px"}
          >
            {contents}
          </Text>
        )}

        {writer && (
          <NewsInfo
            margin={isMobile ? "4px 0 0" : "24px 0 0"}
            writer={writer}
            modDate={modDate}
            regDate={regDate}
            commentCnt={commentCnt}
            isMobile={isMobile}
          />
        )}
      </Box>
    </Link>
  );
};

const MainNews = ({ list }) => {
  return (
    <Box
      display={Box.DISPLAY.FLEX}
      jc={"space-between"}
      width={"100%"}
      ariaLabel={"주간 Main News 영역"}
    >
      <Box
        display={Box.DISPLAY.FLEX}
        jc={"space-between"}
        //
        width={"calc(50% - 20px)"}
        ariaLabel={"주간 Main News 영역"}
      >
        {list.slice(0, 2).map((data) => (
          <SmallImageNews
            link={data.key}
            category={"detail"}
            //
            thumbnail={data.thumbnail}
            aspectRatio={"277/180"}
            ariaLabel={"주요 News"}
            //
            title={data.title}
            //
            width={"calc(50% - 7.5px)"}
            margin={"10px 0"}
            //
            lineClamp={2}
            titleSize={Text.SIZE.CLIENTNORMAL}
            key={data.key}
          />
        ))}
      </Box>
      <Box width={"calc(50% - 20px)"} ariaLabel={"주간 Main News 영역"}>
        {list.slice(2, list.length).map((data, idx) => (
          <Link link={`/detail/${data.key}`} key={data.key} width={"100%"}>
            <Box
              padding={idx > 0 ? "20px 0" : "0 0 20px"}
              //
              // borderTop={idx === 0 ? "1px solid" : ""}
              borderBottom={"1px solid"}
              borderColor={Box.COLOR.LINEGRAY}
              ariaLabel={`${data.title} 뉴스 링크`}
            >
              <Text
                as={Text.VARIANT.SUBTITLE}
                ariaLabel={data.title}
                //
                size={Text.SIZE.CLIENTNORMAL}
                lineClamp={1}
                lineHeight={"22px"}
              >
                {data.title}
              </Text>
            </Box>
          </Link>
        ))}
      </Box>
    </Box>
  );
};

const HeadLineNews = ({ list, category }) => {
  return (
    <>
      <Box width={"calc(50% - 20px)"} ariaLabel="뉴스 영역">
        <BigImageNews
          {...list[0]}
          link={list[0].key}
          category={category ?? list[0].boardName}
          //
          ariaLabel={"주요 News"}
          //
          margin={"15px 0 10px"}
          lineClamp={2}
          aspectRatio={"570/370"}
          titleSize={Text.SIZE.CLIENTSUBTITLE}
          contentSize={Text.SIZE.CLIENTCONTENT}
        />
      </Box>
      <Box
        width={"calc(50% - 20px)"}
        display={Box.DISPLAY.FLEX}
        jc={"space-between"}
        wrap={"wrap"}
        ariaLabel="뉴스 영역"
      >
        {list.slice(1).map((data, idx) => (
          <SmallImageNews
            category={category ?? data.boardName}
            link={data.key}
            thumbnail={data.thumbnail}
            title={data.title}
            width={"calc(50% - 6px)"}
            margin={"10px 0"}
            aspectRatio={"277/180"}
            ariaLabel={"주요 News"}
            isLast={(idx + 1) % 2 === 0}
            titleSize={Text.SIZE.CLIENTNORMAL}
            key={data.key}
          />
        ))}
      </Box>
    </>
  );
};

const ThumbnailNews = ({
  category,
  //
  list,
  boxPadding,
  ariaLabel,
  //
  width = 4,
  //
  isBorder,
  isBlank,
  isSort,
}) => {
  return (
    <Box
      display={Box.DISPLAY.FLEX}
      wrap={"wrap"}
      // jc={"space-between"}
      //
      width={"100%"}
      borderBottom={isBorder ? "1px solid" : ""}
      borderColor={Box.COLOR.LINEGRAY}
      ariaLabel={"뉴스 영역"}
    >
      {list.map((data, idx) => (
        <SmallImageNews
          link={data.key}
          category={category}
          cate2={data.cate2}
          cate2Name={data.cate2Name}
          //
          thumbnail={data.thumbnail}
          title={data.title}
          contents={data.contents}
          //
          ariaLabel={ariaLabel}
          aspectRatio={"284/180"}
          //
          width={`calc(100% / ${width} - (12px * 3) / 4)`}
          margin={"10px 0"}
          boxPadding={boxPadding}
          //
          lineClamp={2}
          titleSize={Text.SIZE.CLIENTNORMAL}
          contentSize={Text.SIZE.CLIENTCONTENTDETAIL}
          //
          isLast={(idx + 1) % width === 0}
          isBorder={isBorder}
          isBlank={isBlank}
          isSort={isSort}
          key={data.key}
        />
      ))}
    </Box>
  );
};

const LineSmallImageNews = ({
  list,
  isSearch,
  ariaLabel,
  borderFilter,
  category,
  margin = "5px 0",
  fontSize = Text.SIZE.CLIENTNORMAL,
}) => {
  const { isMobile } = useMobileStore();

  return list.map(
    (
      {
        key,
        thumbnail,
        title,
        boardName,
        contents,
        writer,
        regDate,
        commentCnt,
        cate2Name,
        cate2,
        codeNm,
        codeName,
      },
      idx
    ) => (
      <LineImageNews
        link={key}
        isSearch={isSearch}
        isMobile={isMobile}
        //
        category={category}
        thumbnail={thumbnail}
        title={title}
        bold={boardName}
        contents={contents}
        writer={writer}
        regDate={regDate}
        commentCnt={commentCnt}
        cate2Name={cate2Name ?? codeNm ?? codeName}
        cate2={cate2}
        //
        ariaLabel={ariaLabel}
        imageWidth={isMobile ? "120px" : "284px"}
        aspectRatio={isMobile ? "120/78" : "284/180"}
        //
        boxPadding={isMobile ? "12px 0" : "30px 0"}
        padding={isMobile ? "0 0 0 10px" : "0 0 0 40px"}
        margin={margin}
        //
        lineClamp={isMobile ? 1 : 3}
        titleSize={isMobile ? Text.SIZE.MOBILECONTENT : fontSize}
        contentSize={
          isMobile ? Text.SIZE.MOBILECONTENT : Text.SIZE.CLIENTCONTENT
        }
        //
        isBorder={borderFilter ? borderFilter(idx) : true}
        isCenter
        key={key}
      />
    )
  );
};

const TextNews = ({ list, category, highlight, isSearch }) => {
  const { isMobile } = useMobileStore();

  return list.map(
    (
      {
        key,
        cate1Name,
        cate2Name,
        cate2,
        title,
        boardName,
        contents,
        writer,
        regDate,
        modDate,
        commentCnt,
      },
      idx
    ) => (
      <LineTextNews
        link={key}
        category={category}
        //
        isSearch={isSearch}
        isMobile={isMobile}
        //
        cate1Name={cate1Name}
        cate2Name={cate2Name}
        cate2={cate2}
        title={title}
        bold={boardName}
        contents={contents}
        writer={writer}
        regDate={regDate}
        modDate={modDate}
        commentCnt={commentCnt}
        //
        padding={isMobile ? "12px 0" : "30px 0"}
        margin={"2px 0 0"}
        lineClamp={isMobile ? 2 : 3}
        //
        titleSize={
          isMobile ? Text.SIZE.MOBILECONTENT : Text.SIZE.CLIENTSUBTITLE
        }
        titleWeight={Text.WEIGHT.BOLD}
        contentSize={
          isMobile ? Text.SIZE.MOBILECONTENT : Text.SIZE.CLIENTCONTENT
        }
        highlight={highlight}
        //
        ariaLabel={title}
        key={idx}
      />
    )
  );
};

const BroadCastNews = ({ list, category }) => {
  return (
    <>
      <Box
        width={"100%"}
        padding={"0 0 37px 0"}
        //
        borderBottom={"1px solid"}
        borderColor={Box.COLOR.LINEGRAY}
      >
        <BigImageNews
          {...list[0]}
          category={category}
          link={list[0].key}
          ariaLabel={"지상 중계"}
          //
          margin={"15px 0 10px"}
          lineClamp={3}
          aspectRatio={"530/334"}
          //
          titleSize={Text.SIZE.CLIENTSUBTITLE}
          contentSize={Text.SIZE.CLIENTCONTENT}
        />
      </Box>

      {list.slice(1).map((data) => (
        <Box
          padding={"13px 5px 6px"}
          //
          borderBottom={"1px solid"}
          borderColor={Box.COLOR.LINEGRAY}
          key={data.key}
        >
          <LineImageNews
            {...data}
            link={data.key}
            //
            ariaLabel={"지상 중계"}
            //
            imageWidth={"20%"}
            aspectRatio={"104/64"}
            padding={"0 0 0 15px"}
            margin={"5px 0"}
            //
            lineClamp={2}
            titleSize={Text.SIZE.CLIENTNORMAL}
            contentSize={Text.SIZE.CLIENTCONTENTDETAIL}
            isMain
          />
        </Box>
      ))}
    </>
  );
};

const SideImageNews = ({ list, category }) => {
  return list.map((data) => (
    <SmallImageNews
      link={data.key}
      category={category}
      thumbnail={data.thumbnail}
      title={data.title}
      width={"100%"}
      margin={"15px 0 20px"}
      aspectRatio={"277/180"}
      ariaLabel={"주요 News"}
      isSort={false}
      titleSize={Text.SIZE.CLIENTNORMAL}
      key={data.key}
    />
  ));
};

const SideLineNews = ({ list, category }) => {
  const { isMobile } = useMobileStore();

  return list.map(({ title, key }, idx) => (
    <Box display={Box.DISPLAY.FLEX} width={"100%"} key={idx}>
      <Text
        as={Text.VARIANT.TEXT}
        width={"30px"}
        size={isMobile ? Text.SIZE.MOBILETITLE : Text.SIZE.CLIENTNORMAL}
        weight={Text.WEIGHT.BOLD}
        isSort
        padding={"0 8px"}
        borderBottom={"1px solid"}
        borderColor={Box.COLOR.LINEGRAY}
        color={idx < 3 ? Text.COLOR.SECONDARY : Text.COLOR.BLACK}
      >
        {idx + 1}
      </Text>
      <LineTextNews
        link={key}
        title={title}
        category={category}
        //
        padding={"10px 0"}
        lineClamp={1}
        //
        titleSize={isMobile ? Text.SIZE.MOBILECONTENT : Text.SIZE.CLIENTCONTENT}
        titleWeight={Text.WEIGHT.MEDIUM}
        //
        isSideNews
        ariaLabel={title}
      />
    </Box>
  ));
};

const MobileLineNews = ({ list, isSearch, ariaLabel, borderFilter }) => {
  return list.map(
    (
      {
        key,
        thumbnail,
        title,
        boardName,
        contents,
        writer,
        regDate,
        commentCnt,
        cate2Name,
        codeNm,
      },
      idx
    ) => (
      <LineImageNews
        link={key}
        isSearch={isSearch}
        //
        thumbnail={thumbnail}
        title={title}
        bold={boardName}
        contents={contents}
        writer={writer}
        regDate={regDate}
        commentCnt={commentCnt}
        cate2Name={cate2Name || codeNm}
        //
        ariaLabel={ariaLabel}
        imageWidth={"120px"}
        aspectRatio={"120/78"}
        //
        boxPadding={"16px 0"}
        padding={"0 0 0 10px"}
        margin={"5px 0"}
        //
        lineClamp={2}
        titleSize={Text.SIZE.MOBILECONTENT}
        contentSize={Text.SIZE.MOBILECONTENTDETAIL}
        //
        isBorder={borderFilter ? borderFilter(idx) : true}
        isCenter
        isMobile
        key={key}
      />
    )
  );
};

const MobileHeadLineNews = ({ list, category }) => {
  return (
    <>
      <Box width={"100%"} ariaLabel="뉴스 영역" margin={"0 0 30px"}>
        <BigImageNews
          {...list[0]}
          link={list[0].key}
          category={category ?? list[0].boardName}
          isMobile
          //
          ariaLabel={"주요 News"}
          //
          margin={"10px 0 6px"}
          lineClamp={3}
          aspectRatio={"570/370"}
          titleSize={Text.SIZE.MOBILECONTENT}
          contentSize={Text.SIZE.MOBILECONTENT}
        />
      </Box>
      <Box
        width={"100%"}
        display={Box.DISPLAY.FLEX}
        jc={"space-between"}
        wrap={"wrap"}
        ariaLabel="뉴스 영역"
      >
        {list.slice(1).map((data, idx) => (
          <SmallImageNews
            category={category ?? data.boardName}
            link={data.key}
            cate2={data.cate2}
            thumbnail={data.thumbnail}
            title={data.title}
            width={"calc(50% - 3.5px)"}
            margin={"10px 0"}
            aspectRatio={"277/180"}
            ariaLabel={"주요 News"}
            isLast={(idx + 1) % 2 === 0}
            titleSize={Text.SIZE.MOBILECONTENT}
            key={data.key}
            isSort={false}
          />
        ))}
      </Box>
    </>
  );
};

const MobileThumbnailNews = ({ list, isContent, margin, category }) => {
  return (
    <Box
      width={"100%"}
      display={Box.DISPLAY.FLEX}
      jc={"space-between"}
      wrap={"wrap"}
      gap={"20px 0"}
      ariaLabel="뉴스 영역"
    >
      {list.map((data, idx) => (
        <SmallImageNews
          category={category}
          cate2Name={data.cate2Name}
          cate2={data.cate2}
          link={data.key}
          thumbnail={data.thumbnail}
          title={data.title}
          contents={data.contents}
          lineClamp={2}
          width={"calc(50% - 3.5px)"}
          margin={data.cate2Name ? "4px 0" : "10px 0 4px"}
          aspectRatio={"277/180"}
          ariaLabel={"주요 News"}
          isLast={(idx + 1) % 2 === 0}
          titleSize={Text.SIZE.MOBILECONTENT}
          contentSize={Text.SIZE.MOBILECONTENT}
          key={data.key}
          isSort={false}
        />
      ))}
    </Box>
  );
};

const NewsLink = ({ link }) => {
  return (
    <Link link={link} ariaLabel={"더보기 링크"}>
      <Box
        display={Box.DISPLAY.FLEX}
        al={"center"}
        height={"100%"}
        ariaLabel={"더보기 링크"}
      >
        <Text
          as={Text.VARIANT.TEXT}
          size={Text.SIZE.CLIENTCONTENT}
          weight={Text.WEIGHT.MEDIUM}
          ariaLabel={"더보기 링크"}
          lineHeight={"22px"}
        >
          {news.list.more}
        </Text>
        <FiChevronRight size={12} />
      </Box>
    </Link>
  );
};

const NewsSelect = (props) => {
  const { list, width, onChange, value } = props;
  return (
    <Select
      style={{
        width,
      }}
      className={"news-select"}
      value={value}
      options={list}
      onChange={onChange}
    />
  );
};

const NewsType = ({ isAllPage, typeForm, basicForm }) => {
  const {
    query: { type },
    changeQuery,
  } = useUrl();
  const { isMobile } = useMobileStore();

  const newsType = type ? type : basicForm;
  const listView = isAllPage ? ["title", "summary", "photo"] : typeForm;
  const selectButtonList = news.list.buttonList.filter(({ buttonType }) => {
    return listView.includes(buttonType);
  });

  if (selectButtonList.length <= 1) return null;

  return (
    <Box display={Box.DISPLAY.FLEX}>
      {selectButtonList.map(({ title, buttonType, image, activeImage }) => {
        const bgColor =
          buttonType === newsType ? Button.COLOR.SECONDARY : Button.COLOR.WHITE;
        const color =
          buttonType === newsType ? Button.COLOR.WHITE : Button.COLOR.BLACK;
        const borderColor =
          buttonType === newsType
            ? Button.COLOR.SECONDARY
            : Button.COLOR.LINEGRAY;
        const currImage = buttonType === newsType ? activeImage : image;

        return (
          <Button
            key={title}
            display={Box.DISPLAY.FLEX}
            al="center"
            width={isMobile ? "22px" : ""}
            padding={isMobile ? "3px" : "7px"}
            margin={isMobile ? "0 0 0 9px" : "0 0 0 5px"}
            border={"1px solid"}
            bgColor={bgColor}
            borderColor={borderColor}
            color={color}
            borderRadius={"2px"}
            onClick={() =>
              changeQuery({
                type: buttonType,
              })
            }
            ariaLabel={`기사 유형 : ${title}`}
          >
            <Image src={currImage} width={isMobile ? "100%" : ""} alt={title} />

            {!isMobile && (
              <Text
                as={Text.VARIANT.SPAN}
                size={Text.SIZE.CLIENTETC}
                margin={"0 0 0 5px"}
              >
                {title}
              </Text>
            )}
          </Button>
        );
      })}
    </Box>
  );
};

const NewsList = (props) => {
  const {
    title,
    margin = "0 0 28px",
    size = Text.SIZE.CLIENTTITLE,
    bold,
    children,
    sideBar,
    link,
    isBorder,
    isMobile,
  } = props;

  const isTitle = title || bold || sideBar;

  return (
    <Box width={"100%"}>
      {isTitle && (
        <Box
          width={"100%"}
          display={Box.DISPLAY.FLEX}
          jc={"space-between"}
          al="center"
          margin={margin}
          padding={isBorder ? "0 0 28px" : "0"}
          borderBottom={isBorder ? "1px solid" : ""}
          borderColor={Box.COLOR.LINEGRAY}
          ariaLabel={`News 카테고리 영역`}
        >
          <Text
            as={Text.VARIANT.SUBTITLE}
            size={size}
            ariaLabel={`News 카테고리 ${title}`}
          >
            {title}{" "}
            <Text
              as={Text.VARIANT.SPAN}
              color={Text.COLOR.SECONDARY}
              ariaLabel={bold}
            >
              {bold}
            </Text>
          </Text>
          {sideBar && sideBar()}
        </Box>
      )}

      <Box
        width={"100%"}
        display={Box.DISPLAY.FLEX}
        jc={"space-between"}
        wrap={"wrap"}
        ariaLabel="News 리스트"
      >
        {children}
      </Box>

      {isMobile && link && (
        <Link width={"100%"} margin={"20px 0 0"} link={link}>
          <Box
            display={Box.DISPLAY.FLEX}
            jc={"center"}
            al={"center"}
            border="1px solid"
            borderColor={Box.COLOR.LINEGRAY}
            borderRadius="60px"
            padding={"10px 0"}
          >
            <Text
              as={Text.VARIANT.TEXT}
              size={Text.SIZE.MOBILECONTENT}
              color={Text.COLOR.TEXTGRAY}
            >
              {news.list.more}
            </Text>
          </Box>
        </Link>
      )}
    </Box>
  );
};

NewsList.TEMPLATE = {
  BigImageNews,
  SmallImageNews,
  LineImageNews,
};

NewsList.LAYOUT = {
  MainNews,
  HeadLineNews,
  ThumbnailNews,
  BroadCastNews,
  LineSmallImageNews,
  TextNews,
  SideImageNews,
  SideLineNews,
  MobileLineNews,
  MobileHeadLineNews,
  MobileThumbnailNews,
};

NewsList.Link = NewsLink;
NewsList.Type = NewsType;
NewsList.Select = NewsSelect;

export default NewsList;
