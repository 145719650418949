import {
  createCategoryDetail,
  deleteCategoryDetail,
  getCategoryDetail,
  getCategoryList,
  updateCategoryDetail,
  updateCategoryOrder,
} from "api/admin/category";
import useHandleAlert from "hooks/utils/useHandleAlert";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useChangeOrderStore } from "store/useChangeStore";
import { common } from "text/admin/common";

const {
  success: { create, remove, update, order },
  failed: { removeCategory },
} = common.alert;

export const useCategoryList = ({ categoryName }) => {
  const { data, isLoading, isSuccess, isError } = useQuery({
    queryKey: ["category", categoryName],
    queryFn: () => getCategoryList(categoryName),
    enabled: !!categoryName,
  });

  const list = data?.data.list;
  const totalCnt = data?.data.totalCnt;
  const totalPageIndex = data?.data.totalPageIndex;

  return {
    list,
    totalCnt,
    totalPageIndex,
    isLoading,
    isSuccess,
    isError,
  };
};

export const useCategoryDetail = ({ categoryName, key }) => {
  const { data, isLoading, isSuccess, isError } = useQuery({
    queryKey: ["categoryDetail", categoryName, key],
    queryFn: () => getCategoryDetail({ categoryName, key }),
    enabled: !!key,
  });

  const list = data?.data.categoryCodeNameList;

  return {
    list,
    isLoading,
    isSuccess,
    isError,
  };
};

export const useUpdateCategoryDetail = () => {
  const { handleGoBack } = useHandleAlert();
  const queryClient = useQueryClient();
  const updateCategoryDetailMutation = useMutation(updateCategoryDetail, {
    onSuccess: () => {
      alert(update);
      queryClient.invalidateQueries("category");
      handleGoBack();
    },
  });

  const mutate = ({ key, categoryName, data }) => {
    updateCategoryDetailMutation.mutate({ key, categoryName, data });
  };

  return mutate;
};

export const useUpdateCategoryOrder = () => {
  const { setIsChangeOrderList } = useChangeOrderStore();
  const queryClient = useQueryClient();
  const updateCategoryOrderMutation = useMutation(updateCategoryOrder, {
    onSuccess: () => {
      alert(order);
      queryClient.invalidateQueries("category");
      setIsChangeOrderList(false);
    },
  });

  const mutate = ({ categoryName, data }) => {
    updateCategoryOrderMutation.mutate({ categoryName, data });
  };

  return mutate;
};

export const useCreateCategoryDetail = () => {
  const { handleGoBack } = useHandleAlert();
  const queryClient = useQueryClient();
  const updateCategoryDetailMutation = useMutation(createCategoryDetail, {
    onSuccess: () => {
      alert(create);
      queryClient.invalidateQueries("category");
      handleGoBack();
    },
  });

  const mutate = ({ categoryName, data }) => {
    updateCategoryDetailMutation.mutate({ categoryName, data });
  };

  return mutate;
};

export const useDeleteCategoryDetail = () => {
  const queryClient = useQueryClient();
  const updateCategoryDetailMutation = useMutation(deleteCategoryDetail, {
    onSuccess: () => {
      alert(remove);
      queryClient.invalidateQueries("category");
    },
    onError: (error) => {
      if (error.response.data.message === "RELATED ITEMS EXIST") {
        alert(removeCategory);
      }
    },
  });

  const mutate = ({ key, categoryName }) => {
    updateCategoryDetailMutation.mutate({ key, categoryName });
  };

  return mutate;
};
