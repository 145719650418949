const createTheme = (theme) => {
  return Object.keys(theme).reduce((acc, key) => {
    acc[key.toUpperCase()] = key;

    return acc;
  }, {});
};

export const color = {
  primary: "#23295C",
  secondary: "#3A47B8",
  third: "#DA4337",
  fourth: "#525784",
  white: "#FFFFFF",
  black: "#000000",
  yellow: "#ffc226",
  red: "#ff0000",
  gray: "#EEEEEE",
  lightGray: "#FBFBFB",
  darkGray: "#838383",
  lineGray: "#dfdfdf",
  textGray: "#747474",
  menuGray: "#fcfcff",
  bannerGray: "#f4f4f4",
  transparent: "transparent",
  sidebar: "#D6E3F6",
  sidemenu: "#23295C80",
  adminLineGray: "#D9DEE3",
  adminContentsBg: "#F8F9FB",
  selectMenuGray: "#BBBBBB",
  shadow: "rgba(0, 0, 0, 0.30)",
  highlight: "#DBDFFF",
  accentCount: "#007AEA",
  attachedFileBg: "#F5F5F5",
  adminDarkGray: "#333333",
  attachedFileLightGray: "#818181",
  dashboardBlue: "#3A47B81A",
  modalBg: "#0000004D",
  disabledBg: "#F7F7F7",
  coverage: "rgba(58, 71, 184, 0.15)",
  coverageLine: "rgba(255, 255, 255, 0.5)",
};

export const COLOR = createTheme(color);

const font = {
  clientHead: "2rem",
  clientTitle: "1.5625rem",
  clientCategory: "1.375rem",
  clientSubTitle: "1.25rem",
  clientContent: "0.9375rem",
  clientContentDetail: "0.8125rem",
  clientLarge: "2.1875rem",
  clientNormal: "1.0625rem",
  clientEtc: "0.75rem",
  mobileHead: "1.25rem",
  mobileTitle: "1rem",
  mobileCategory: "0.875rem",
  mobileSubTitle: "1.25rem",
  mobileContent: "0.75rem",
  mobileContentDetail: "0.5rem",
  mobileLarge: "1.25rem",
  mobileNormal: "1.25rem",
  mobileAdd: "0.9375rem",
  mobileEtc: "0.6875rem",
  adminPoint: "1.25rem",
  adminDashboard: "1rem",
  adminCategory: "0.875rem",
  adminContent: "0.8125rem",
  adminContentDetail: "0.75rem",
  adminEtc: "0.6875rem",
};

export const FONT_SIZE = createTheme(font);

const weight = {
  light: 300,
  normal: 400,
  medium: 500,
  bold: 700,
};

export const FONT_WEIGHT = createTheme(weight);

const radius = {
  small: "3px",
  normal: "5px",
  round: "9999px",
};

export const RADIUS = createTheme(radius);

const option = {
  shadow: "2px 2px 4px 1px rgba(0,0,0,0.3)",
};

export const OPTION = createTheme(option);

const border = "1px solid #d9dee3";

const theme = {
  color,
  font,
  weight,
  radius,
  option,
  border,
};

export default theme;
