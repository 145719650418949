import AdminCalendarList from "components/organisms/adminCalendarList";
import SearchCondition from "components/organisms/searchCondition";
import { AdminContentsContainer } from "components/templates/admin/admin.style";
import dayjs from "dayjs";
import useUrl from "hooks/utils/useUrl";
import React, { useState } from "react";
import { calendar } from "text/admin/calendar";
import convertDateWithYear from "utils/convertDateWithYear";

const CalendarScience = () => {
  const today = dayjs();

  const { defaultValues, dateOptions, keywordOptions } = calendar;

  const { pathname, changeLink } = useUrl();

  const [selectedMenu, setSelectedMenu] = useState("");
  const [dateSelectedMenu, setDateSelectedMenu] = useState("regDate");
  const [selectedStartDate, setSelectedStartDate] = useState(today.subtract(1, "month"));
  const [selectedEndDate, setSelectedEndDate] = useState(today);
  const [filterData, setFilterData] = useState([]);

  const onSubmit = (data) => {
    const startDate = convertDateWithYear(selectedStartDate);
    const endDate = convertDateWithYear(selectedEndDate);
    const { searchValue, dateCondition } = data;
    setFilterData({
      startDate,
      endDate,
      searchCondition: selectedMenu === "" ? "all" : selectedMenu,
      searchValue,
      dateCondition,
    });
    changeLink(pathname);
  };

  return (
    <AdminContentsContainer>
      <SearchCondition onSubmit={onSubmit} defaultValues={defaultValues}>
        <SearchCondition.DatePicker
          title="일시"
          selectedStartDate={selectedStartDate}
          setSelectedStartDate={setSelectedStartDate}
          selectedEndDate={selectedEndDate}
          setSelectedEndDate={setSelectedEndDate}
          selectedMenu={dateSelectedMenu}
          setSelectedMenu={setDateSelectedMenu}
          options={dateOptions}
          width="120px"
          registerName="dateCondition"
          hasSelectMenu
        />
        <SearchCondition.Select
          title="검색 분류"
          registerName="searchValue"
          selectedMenu={selectedMenu}
          setSelectedMenu={setSelectedMenu}
          options={keywordOptions}
          width="104px"
        />
      </SearchCondition>
      <AdminCalendarList filterData={filterData} />
    </AdminContentsContainer>
  );
};

export default CalendarScience;
