import Box from "components/atoms/box";
import Button from "components/atoms/button";
import Loading from "components/atoms/loading";
import Text from "components/atoms/text";
import InputForm from "components/molecules/inputForm";
import SelectMenu from "components/molecules/selectMenu";
import AdminDetailForm from "components/organisms/adminDetailForm";
import AdminDetailFormContainer from "components/organisms/adminDetailFormContainer";
import CompanyGroup from "components/organisms/companyGroup";
import ListView from "components/organisms/listView";
import { AdminContentsContainer } from "components/templates/admin/admin.style";
import {
  useAuthorityList,
  useCreateManager,
  useManagerDetail,
  useUpdateManagerInfo,
  useUpdateManagerPassword,
} from "hooks/api/admin/manager";
import usePendingMutate from "hooks/utils/usePendingMutate";
import useUrl from "hooks/utils/useUrl";
import React from "react";
import { useEffect, useState } from "react";
import { common } from "text/admin/common";
import { management } from "text/admin/management";
import convertContactNum from "utils/convertContactNum";
import convertEmailFormatter from "utils/convertEmailFormatter";

const ManagerDetail = () => {
  const {
    query: { key },
  } = useUrl();

  const { buttonList, defaultValues } = management.detail;
  const {
    temporaryPassword: { request },
  } = common.alert;

  const { list, isLoading } = useManagerDetail({ key });
  const { list: authorList } = useAuthorityList({});
  const { isPending, handleMutate } = usePendingMutate();

  const api = key ? useUpdateManagerInfo : useCreateManager;

  const [selectedMenu, setSelectedMenu] = useState("");
  const [options, setOptions] = useState([]);
  const [isCreatedPw, setIsCreatedPw] = useState(false);

  const mutate = api();
  const passwordMutate = useUpdateManagerPassword(setIsCreatedPw);

  const MASTER_MANAGER = "ROLE_ADM_OVW5LS6";

  useEffect(() => {
    if (authorList) {
      const options = authorList.map((item) => {
        return {
          value: item.authorCode,
          title: item.authorName,
        };
      });
      setOptions(options);
    }
  }, [authorList]);

  useEffect(() => {
    setSelectedMenu(list?.authorCode ?? MASTER_MANAGER);
  }, [list]);

  const handleTemporaryPassword = (e) => {
    e.preventDefault();
    const confirm = window.confirm(request);
    if (confirm) passwordMutate({ key });
  };

  const formInfo = [
    {
      title: "운영자 ID",
      minHeight: "48px",
      components: key ? (
        <Box display={Box.DISPLAY.FLEX} al="center" height="31px" ariaLabel="ID">
          <Text as={Text.VARIANT.TEXT} size={Text.SIZE.ADMINCONTENT}>
            {list?.userId}
          </Text>
        </Box>
      ) : (
        <InputForm
          name="userId"
          type="text"
          width="240px"
          height="32px"
          padding="0 12px"
          theme="secondary"
          defaultName={list?.userId}
          required={list?.userId ? false : "필수값입니다."}
          ariaLabel="운영자 ID"
        />
      ),
      required: true,
    },
    {
      title: "이름",
      minHeight: "48px",
      components: (
        <InputForm
          name="userName"
          type="text"
          width="240px"
          height="32px"
          padding="0 12px"
          theme="secondary"
          defaultName={list?.userName}
          required="필수값입니다."
          ariaLabel="이름"
        />
      ),
      required: true,
    },
    {
      title: "부서",
      minHeight: "48px",
      components: (
        <InputForm
          name="department"
          type="text"
          width="240px"
          height="32px"
          padding="0 12px"
          theme="secondary"
          defaultName={list?.department}
          ariaLabel="부서"
        />
      ),
      required: false,
    },
    {
      title: "비밀번호",
      minHeight: "48px",
      components: !list ? (
        <Box
          display={Box.DISPLAY.FLEX}
          al="center"
          height="31px"
          ariaLabel="계정 생성 시 자동으로 임시 비밀번호가 발급 됩니다."
        >
          <Text as={Text.VARIANT.TEXT} size={Text.SIZE.ADMINCONTENT} color={Text.COLOR.SECONDARY}>
            * 계정 생성 시 자동으로 임시 비밀번호가 발급 됩니다.
          </Text>
        </Box>
      ) : (
        <Button
          themeStyle={isCreatedPw ? "disabled" : "adminCommon"}
          padding="8px 14px"
          borderColor={Button.COLOR.SECONDARY}
          ariaLabel="임시 비밀번호 발급"
          onClick={handleTemporaryPassword}
        >
          <Text
            as={Text.VARIANT.TEXT}
            size={Text.SIZE.ADMINCONTENT}
            color={Text.COLOR.SECONDARY}
            cursor={isCreatedPw ? "not-allowed" : "pointer"}
          >
            임시 비밀번호 발급
          </Text>
        </Button>
      ),
      required: !list ? false : true,
    },
    {
      title: "연락처",
      minHeight: "48px",
      components: (
        <InputForm
          name="hp"
          type="text"
          width="240px"
          height="32px"
          padding="0 12px"
          theme="secondary"
          maxLength={13}
          defaultName={list ? `${list?.hp1}-${list?.hp2}-${list?.hp3}` : ""}
          required="필수값입니다."
          ariaLabel={`연락처: ${list?.hp1}-${list?.hp2}-${list?.hp3}`}
        />
      ),
      required: true,
    },
    {
      title: "이메일",
      minHeight: "48px",
      components: (
        <InputForm
          name="email"
          type="text"
          width="240px"
          height="32px"
          padding="0 12px"
          theme="secondary"
          defaultName={list ? `${list?.email1}@${list?.email2}` : ""}
          required="필수값입니다."
          ariaLabel={`이메일: ${list?.email1}@${list?.email2}`}
        />
      ),
      required: true,
    },
    {
      title: "내부/외부",
      height: "100px",
      components: (
        <CompanyGroup
          companyGroup={list?.companyGroup ?? "1"}
          companyName={list?.companyName ?? ""}
        />
      ),
      required: true,
      isUpperPosition: true,
    },
    {
      title: "사용 여부",
      height: "40px",
      components: (
        <ListView
          registerName="inactive"
          buttonList={buttonList}
          isRenderedListView={list?.inactive ?? "Y"}
        />
      ),
      required: true,
    },
    {
      title: "권한 설정",
      height: "48px",
      components: (
        <SelectMenu
          registerName="authorCode"
          selectedMenu={selectedMenu}
          setSelectedMenu={setSelectedMenu}
          options={options}
          width="237px"
        />
      ),
      required: true,
      isUpperPosition: true,
    },
  ];

  const handleSubmit = (data) => {
    const { hp, email, authorCode, companyGroup, department, inactive, userId, userName } = data;

    let { companyName } = data;

    if (companyGroup === "1") {
      companyName = "";
    }

    const { hp1, hp2, hp3 } = convertContactNum(hp);
    const { email1, email2 } = convertEmailFormatter(email);

    const submitData = {
      userId,
      userName,
      department,
      hp1,
      hp2,
      hp3,
      email1,
      email2,
      companyGroup,
      companyName,
      inactive,
      authorCode,
    };

    handleMutate(mutate, { key, data: submitData });
  };

  if (isLoading) return <Loading />;

  return (
    <AdminContentsContainer>
      <Box maxWidth="1024px">
        <AdminDetailFormContainer
          title="운영자 등록"
          defaultValues={defaultValues}
          handleSubmit={handleSubmit}
          isPending={isPending}
        >
          <AdminDetailForm formInfo={formInfo} />
        </AdminDetailFormContainer>
      </Box>
    </AdminContentsContainer>
  );
};

export default ManagerDetail;
