import Box from "components/atoms/box";
import React from "react";
import SelectMenu from "../selectMenu";
import Text from "components/atoms/text";

const TimeList = (props) => {
  const { selectedHours, setSelectedHours, selectedMinutes, setSelectedMinutes } = props;

  const hours = new Array(24).fill(0).map((_, idx) => {
    const hour = idx < 10 ? `0${idx}` : `${idx}`;
    return {
      title: hour,
      value: hour,
    };
  });

  const minutes = new Array(60).fill(0).map((_, idx) => {
    const minutes = idx < 10 ? `0${idx}` : `${idx}`;
    return {
      title: minutes,
      value: minutes,
    };
  });

  return (
    <Box display={Box.DISPLAY.FLEX} al="center" gap="10px">
      <Box display={Box.DISPLAY.FLEX} al="center" gap="5px">
        <SelectMenu
          options={hours}
          selectedMenu={selectedHours}
          setSelectedMenu={setSelectedHours}
          width="60px"
        />
        <Text as={Text.VARIANT.SPAN}>시</Text>
      </Box>
      <Box display={Box.DISPLAY.FLEX} al="center" gap="5px">
        <SelectMenu
          options={minutes}
          selectedMenu={selectedMinutes}
          setSelectedMenu={setSelectedMinutes}
          width="60px"
        />
        <Text as={Text.VARIANT.SPAN}>분</Text>
      </Box>
    </Box>
  );
};

export default TimeList;
