import Box from "components/atoms/box";
import Loading from "components/atoms/loading";
import BelowComment from "components/molecules/belowComment";
import ImageUploader from "components/molecules/imageUploader";
import InputForm from "components/molecules/inputForm";
import AdminDetailForm from "components/organisms/adminDetailForm";
import AdminDetailFormContainer from "components/organisms/adminDetailFormContainer";
import ExposureDate from "components/organisms/exposureDate";
import LinkList from "components/organisms/linkList";
import ListView from "components/organisms/listView";
import { AdminContentsContainer } from "components/templates/admin/admin.style";
import {
  useCreatePopup,
  useSitePopupDetailList,
  useUpdatePopup,
} from "hooks/api/admin/management_site";
import usePendingMutate from "hooks/utils/usePendingMutate";
import useUrl from "hooks/utils/useUrl";
import React, { useState } from "react";
import { useEffect } from "react";
import usePopupStore from "store/usePopupStore";
import { site } from "text/admin/site";

const PopupDetail = () => {
  const {
    path,
    query: { key },
  } = useUrl();

  const { isPending, handleMutate } = usePendingMutate();

  const [isDisabled, setIsDisabled] = useState(false);
  const [popupPreviewPath, setPopupPreviewPath] = useState("");
  const [popupPeriod, setPopupPeriod] = useState("TODAY");
  const [popupNewWindow, setPopupNewWindow] = useState("N");

  const categoryName = path[2];

  const { buttonList, defaultValues, adviceComments } = site.popup.detail;

  const subTitle = {
    popup: key ? "팝업 수정" : "팝업 등록",
  };

  const {
    selectedStartDate,
    selectedEndDate,
    selectedStartHours,
    selectedStartMinutes,
    selectedEndHours,
    selectedEndMinutes,
    reset,
  } = usePopupStore();

  useEffect(() => {
    return () => {
      reset();
    };
  }, []);

  const { list, isLoading } = useSitePopupDetailList({ key });

  const api = key ? useUpdatePopup : useCreatePopup;

  const mutate = api();

  const formInfo = [
    {
      title: "사용 여부",
      height: "40px",
      components: (
        <ListView
          buttonList={buttonList}
          registerName="status"
          isRenderedListView={list?.status ?? "Y"}
        />
      ),
      required: true,
    },
    {
      title: "제목",
      minHeight: "48px",
      components: (
        <InputForm
          name="title"
          type="text"
          width="430px"
          height="32px"
          padding="0 12px"
          theme="secondary"
          required="필수값 입니다."
          defaultName={list?.title}
          ariaLabel="제목"
        />
      ),
      required: true,
    },
    {
      title: "이미지 등록",
      height: "300px",
      components: (
        <ImageUploader
          registerName="files"
          comments={adviceComments[categoryName]}
          required={key ? false : "필수값 입니다."}
          serverImgPath="attach"
          defaultPath={list?.filePath ?? null}
          setPopupPreviewPath={setPopupPreviewPath}
        />
      ),
      required: key ? false : true,
      isUpperPosition: true,
    },
    {
      title: "링크",
      height: isDisabled ? "auto" : "120px",
      components: (
        <LinkList
          radioName="isUsedLink"
          inputName="url"
          checkName="newWindow"
          defaultCheckValue={list?.newWindow ?? "N"}
          defaultLink={list?.url ?? ""}
          setIsDisabled={setIsDisabled}
          setPopupNewWindow={setPopupNewWindow}
        />
      ),
      required: true,
      isUpperPosition: true,
    },
    {
      title: "하단 문구",
      height: "32px",
      components: (
        <BelowComment
          isActiveComment={list?.bottomPhrase ?? "TODAY"}
          setPopupPeriod={setPopupPeriod}
        />
      ),
      required: true,
    },
    {
      title: "노출 기간",
      height: "82px",
      components: <ExposureDate name="isExposure" list={list} />,
      required: true,
    },
  ];

  const handleSubmit = (data) => {
    const { files, status, title, newWindow, bottomPhrase, isUsedLink, isExposure } = data;
    let { url } = data;

    let startDate = `${selectedStartDate.format(
      "YYYYMMDD"
    )}${selectedStartHours}${selectedStartMinutes}`;
    let endDate = `${selectedEndDate.format("YYYYMMDD")}${selectedEndHours}${selectedEndMinutes}`;

    if (isUsedLink === "N") url = "";
    if (isExposure === "N") startDate = endDate = "";

    const formData = new FormData();
    const param = {
      status,
      title,
      startDate,
      endDate,
      url,
      newWindow: newWindow === true ? "Y" : "N",
      bottomPhrase,
    };

    formData.append("files", files);
    formData.append("param", JSON.stringify(param));

    handleMutate(mutate, { key, formData });
  };

  const handlePreview = (e) => {
    e.preventDefault();
    window.open(
      `/admin/popup/preview?isAdmin=Y&path=${popupPreviewPath}&period=${popupPeriod}&newWindow=${popupNewWindow}`,
      "_blank"
    );
  };

  if (isLoading) return <Loading />;

  return (
    <AdminContentsContainer>
      <Box maxWidth="1024px">
        <AdminDetailFormContainer
          title={subTitle[categoryName]}
          defaultValues={defaultValues}
          handleSubmit={handleSubmit}
          isPending={isPending}
          popupPreview={true}
          handlePreview={handlePreview}
        >
          <AdminDetailForm formInfo={formInfo} />
        </AdminDetailFormContainer>
      </Box>
    </AdminContentsContainer>
  );
};

export default PopupDetail;
