import React from "react";
import Box from "components/atoms/box";
import Button from "components/atoms/button";
import Input from "components/atoms/input";
import Text from "components/atoms/text";
import CheckBox from "components/molecules/checkbox";
import Picker from "react-mobile-picker";
import { useFormContext } from "react-hook-form";
import { useState } from "react";
import { useEffect } from "react";
import useToggle from "hooks/utils/useToggle";
import { IoMdClose } from "@react-icons/all-files/io/IoMdClose";
import {
  useCheckEmailCodeMutation,
  useSendEmailCodeMutation,
} from "hooks/api/auth";
import Loading from "components/atoms/loading";
import signUpText from "text/signup";

const { pending } = signUpText;

const optionGroups = {
  select: [
    pending.form.emailSelect.title,
    "kofst.or.kr",
    "dreamwiz.com",
    "empal.com",
    "freechal.com",
    "gmail.com",
    "hanafos.com",
    "hanmail.net",
    "hanmir.com",
    "hotmail.com",
    "korea.com",
    "lycos.com",
    "nate.com",
    "naver.com",
    "netian.com",
    "paran.com",
    "yahoo.com.kr",
    "yahoo.com",
    "chol.com",
  ],
};

const MobileSignupForm = () => {
  const { register, watch, setValue } = useFormContext();
  const [isShowEmail, setShowEmail] = useToggle(false);
  const [selectEmail, setSelectEmail] = useState({
    select: pending.form.emailSelect.title,
  });

  const [isReadOnly, setIsReadOnly] = useState(false);
  const [isSendMail, setIsSendMail] = useState(false);
  const [isMailLoading, setIsMailLoading] = useState(false);

  const handleSuccessSendEmail = () => {
    setIsSendMail(true);
    setIsMailLoading(false);
    alert(pending.form.alert.sendEmail.success);
  };

  const handleFailSendEmail = () => {
    setIsSendMail(false);
    setIsMailLoading(false);
    alert(pending.form.alert.sendEmail.fail);
  };

  const handleSuccessCheckEmail = () => {
    alert(pending.form.alert.checkEmail.success);
    setValue("isEmail", true);
  };

  const handleFailCheckEmail = () => {
    alert(pending.form.alert.checkEmail.fail);
    setValue("isEmail", false);
  };

  const sendEmailCodeMutation = useSendEmailCodeMutation(
    handleSuccessSendEmail,
    handleFailSendEmail
  );
  const checkEmailCodeMutation = useCheckEmailCodeMutation(
    handleSuccessCheckEmail,
    handleFailCheckEmail
  );
  const emailSelect = watch("emailSelect");

  const email1 = watch("email");
  const email2 = watch("emailDomain");
  const emailCheck = watch("emailCheck");
  const isEmail = watch("isEmail");

  const email = isEmail ? `${email1}@${email2}` : "";

  const handleChange = (name, value) => {
    setSelectEmail({
      [name]: value,
    });
  };

  const handleSubmit = () => {
    setValue("emailSelect", selectEmail.select);
    setShowEmail();
  };

  const handleSubmitEmail = () => {
    setIsMailLoading(true);

    sendEmailCodeMutation({
      email1,
      email2,
    });
  };

  const handleChangePassword = (maxLength) => (e) => {
    const value = e.target.value;
    const name = e.target.name;
    const reg = /[0-9]/g;
    if (!reg.test(value) || value.length > maxLength) {
      return;
    }

    setValue(name, value);
  };

  useEffect(() => {
    const select =
      emailSelect === pending.form.emailSelect.title ? "" : emailSelect;

    setValue("emailDomain", select);

    if (select === "") {
      setIsReadOnly(false);
    } else {
      setIsReadOnly(true);
    }
  }, [emailSelect, setValue]);

  return (
    <>
      <Text
        as={Text.VARIANT.TEXT}
        size={Text.SIZE.MOBILECONTENT}
        color={Text.COLOR.TEXTGRAY}
      >
        <Text as={Text.VARIANT.SPAN} color={Text.COLOR.SECONDARY}>
          *
        </Text>
        {pending.form.info.common[0]}
        <Text as={Text.VARIANT.SPAN} weight={Text.WEIGHT.BOLD}>
          {pending.form.info.bold[0]}
        </Text>
        {pending.form.info.common[1]}
      </Text>

      <Box
        display={Box.DISPLAY.FLEX}
        padding={"10px 12px"}
        margin={"12px 0"}
        border={"1px solid"}
        borderColor={Box.COLOR.LINEGRAY}
        borderRadius={"5px"}
        bgColor={Text.COLOR.WHITE}
      >
        <Text
          as={Text.VARIANT.TEXT}
          size={Text.SIZE.MOBILECONTENT}
          width={"60px"}
          margin={"0 8px 0 0"}
          borderRight={"1px solid"}
          borderColor={Text.COLOR.LINEGRAY}
          weight={Text.WEIGHT.BOLD}
          color={Text.COLOR.TEXTGRAY}
          isSort
        >
          {pending.form.name.title}
          <Text as={Text.VARIANT.SPAN} color={Text.COLOR.SECONDARY}>
            *
          </Text>
        </Text>

        <Input
          width={"calc(100% - 128px)"}
          border={"none"}
          size={Input.SIZE.MOBILEETC}
          color={Input.COLOR.TEXTGRAY}
          {...register("name")}
        />
      </Box>

      <Box
        display={Box.DISPLAY.FLEX}
        padding={"10px 12px"}
        margin={"12px 0"}
        border={"1px solid"}
        borderColor={Box.COLOR.LINEGRAY}
        borderRadius={"5px"}
        bgColor={Text.COLOR.WHITE}
      >
        <Text
          as={Text.VARIANT.TEXT}
          size={Text.SIZE.MOBILECONTENT}
          width={"66px"}
          margin={"0 8px 0 0"}
          borderRight={"1px solid"}
          borderColor={Text.COLOR.LINEGRAY}
          weight={Text.WEIGHT.BOLD}
          color={Text.COLOR.TEXTGRAY}
          isSort
        >
          {pending.form.tel.title}
          <Text as={Text.VARIANT.SPAN} color={Text.COLOR.SECONDARY}>
            *
          </Text>
        </Text>

        <Input
          width={"calc((100% - 84px) / 3)"}
          border={"none"}
          size={Input.SIZE.MOBILEETC}
          color={Input.COLOR.TEXTGRAY}
          type={"tel"}
          {...register("phone1")}
          onChange={handleChangePassword(3)}
          value={watch("phone1")}
        />
        <Text
          as={Text.VARIANT.TEXT}
          size={Text.SIZE.MOBILECONTENT}
          color={Text.COLOR.TEXTGRAY}
          weight={Text.WEIGHT.BOLD}
        >
          -
        </Text>
        <Input
          width={"calc((100% - 84px) / 3)"}
          border={"none"}
          size={Input.SIZE.MOBILEETC}
          color={Input.COLOR.TEXTGRAY}
          type={"tel"}
          {...register("phone2")}
          onChange={handleChangePassword(4)}
          value={watch("phone2")}
        />
        <Text
          as={Text.VARIANT.TEXT}
          size={Text.SIZE.MOBILECONTENT}
          color={Text.COLOR.TEXTGRAY}
          weight={Text.WEIGHT.BOLD}
        >
          -
        </Text>
        <Input
          width={"calc((100% - 84px) / 3)"}
          border={"none"}
          size={Input.SIZE.MOBILEETC}
          color={Input.COLOR.TEXTGRAY}
          type={"tel"}
          {...register("phone3")}
          onChange={handleChangePassword(4)}
          value={watch("phone3")}
        />
      </Box>

      <Box display={Box.DISPLAY.FLEX} margin={"12px 0"}>
        <Box
          display={Box.DISPLAY.FLEX}
          width={"calc(100% - 95px)"}
          margin={"0 4px 0 0"}
          padding={"10px 12px"}
          border={"1px solid"}
          borderColor={Box.COLOR.LINEGRAY}
          borderRadius={"5px"}
          bgColor={isEmail ? Button.COLOR.GRAY : Text.COLOR.WHITE}
        >
          <Text
            as={Text.VARIANT.TEXT}
            size={Text.SIZE.MOBILECONTENT}
            width={"50px"}
            margin={"0 8px 0 0"}
            borderRight={"1px solid"}
            borderColor={Text.COLOR.LINEGRAY}
            weight={Text.WEIGHT.BOLD}
            color={Text.COLOR.TEXTGRAY}
            isSort
          >
            {pending.form.email.title}
            <Text as={Text.VARIANT.SPAN} color={Text.COLOR.SECONDARY}>
              *
            </Text>
          </Text>
          <Input
            width={"calc(50% - 30px)"}
            border={"none"}
            size={Input.SIZE.MOBILEETC}
            color={Input.COLOR.TEXTGRAY}
            readOnly={isSendMail}
            bgColor={isEmail ? Button.COLOR.GRAY : Button.COLOR.WHITE}
            {...register("email")}
          />
          <Text
            as={Text.VARIANT.TEXT}
            size={Text.SIZE.MOBILECONTENT}
            color={Text.COLOR.TEXTGRAY}
            weight={Text.WEIGHT.BOLD}
          >
            @
          </Text>
          <Input
            width={"calc(50% - 30px)"}
            border={"none"}
            size={Input.SIZE.MOBILEETC}
            color={Input.COLOR.TEXTGRAY}
            readOnly={isReadOnly || isSendMail}
            bgColor={isEmail ? Button.COLOR.GRAY : Button.COLOR.WHITE}
            {...register("emailDomain")}
          />
        </Box>

        <Button
          border={"1px solid"}
          borderColor={Button.COLOR.LINEGRAY}
          borderRadius={"5px"}
          width={"91px"}
          bgColor={isEmail ? Button.COLOR.GRAY : Button.COLOR.WHITE}
          color={Button.COLOR.TEXTGRAY}
          fontSize={Button.FONT_SIZE.MOBILECONTENT}
          fontWeight={Button.FONT_WEIGHT.BOLD}
          onClick={() => !isEmail && setShowEmail()}
        >
          {emailSelect === "" ? pending.form.emailSelect.title : emailSelect}
        </Button>
      </Box>

      <Box display={Box.DISPLAY.FLEX} jc={"flex-end"} margin={"12px 0"}>
        {isMailLoading ? (
          <Box width={"91px"}>
            <Loading />
          </Box>
        ) : (
          <Button
            border={"1px solid"}
            borderColor={Button.COLOR.LINEGRAY}
            borderRadius={"5px"}
            width={"91px"}
            padding={"10px 0 11px"}
            margin={"0 0 0 10px"}
            bgColor={isEmail ? Button.COLOR.GRAY : Button.COLOR.WHITE}
            color={Button.COLOR.TEXTGRAY}
            fontSize={Button.FONT_SIZE.MOBILECONTENT}
            fontWeight={Button.FONT_WEIGHT.BOLD}
            onClick={() => {
              !isEmail && handleSubmitEmail();
            }}
          >
            {pending.form.sendEmail.title}
          </Button>
        )}
      </Box>

      <Box display={Box.DISPLAY.FLEX} jc={"flex-end"} margin={"12px 0"}>
        <Box
          display={Box.DISPLAY.FLEX}
          width={"145px"}
          margin={"0 4px 0 0"}
          padding={"10px 12px"}
          border={"1px solid"}
          borderColor={Box.COLOR.LINEGRAY}
          borderRadius={"5px"}
          bgColor={isEmail ? Button.COLOR.GRAY : Button.COLOR.WHITE}
        >
          <Input
            width={"100%"}
            border={"none"}
            size={Input.SIZE.MOBILEETC}
            color={Input.COLOR.TEXTGRAY}
            placeholder={pending.form.emailCheck.placeholder}
            {...register("emailCheck")}
            readOnly={isEmail}
            bgColor={isEmail ? Button.COLOR.GRAY : Button.COLOR.WHITE}
          />
        </Box>
        <Button
          border={"1px solid"}
          borderColor={Button.COLOR.LINEGRAY}
          borderRadius={"5px"}
          width={"91px"}
          color={Button.COLOR.TEXTGRAY}
          fontSize={Button.FONT_SIZE.MOBILECONTENT}
          fontWeight={Button.FONT_WEIGHT.BOLD}
          bgColor={isEmail ? Button.COLOR.GRAY : Button.COLOR.WHITE}
          onClick={() => {
            isSendMail &&
              !isEmail &&
              checkEmailCodeMutation({ email1, email2, code: emailCheck });
          }}
        >
          {pending.form.checkEmail.title}
        </Button>
      </Box>

      <Box
        display={Box.DISPLAY.FLEX}
        padding={"10px 12px"}
        margin={"12px 0"}
        border={"1px solid"}
        borderColor={Box.COLOR.LINEGRAY}
        borderRadius={"5px"}
        bgColor={Text.COLOR.GRAY}
      >
        <Text
          as={Text.VARIANT.TEXT}
          size={Text.SIZE.MOBILECONTENT}
          width={"60px"}
          margin={"0 8px 0 0"}
          borderRight={"1px solid"}
          borderColor={Text.COLOR.LINEGRAY}
          weight={Text.WEIGHT.BOLD}
          color={Text.COLOR.TEXTGRAY}
          isSort
        >
          {pending.form.id.title}
        </Text>

        <Input
          width={"calc(100% - 60px)"}
          border={"none"}
          size={Input.SIZE.MOBILEETC}
          color={Input.COLOR.TEXTGRAY}
          bgColor={Text.COLOR.GRAY}
          value={email}
          readOnly
        />
      </Box>

      <Box
        display={Box.DISPLAY.FLEX}
        padding={"10px 12px"}
        margin={"12px 0"}
        border={"1px solid"}
        borderColor={Box.COLOR.LINEGRAY}
        borderRadius={"5px"}
        bgColor={Text.COLOR.WHITE}
      >
        <Text
          as={Text.VARIANT.TEXT}
          size={Text.SIZE.MOBILECONTENT}
          width={"60px"}
          margin={"0 8px 0 0"}
          borderRight={"1px solid"}
          borderColor={Text.COLOR.LINEGRAY}
          weight={Text.WEIGHT.BOLD}
          color={Text.COLOR.TEXTGRAY}
          isSort
        >
          {pending.form.password.title}
          <Text as={Text.VARIANT.SPAN} color={Text.COLOR.SECONDARY}>
            *
          </Text>
        </Text>

        <Input
          type={"password"}
          width={"calc(100% - 60px)"}
          border={"none"}
          size={Input.SIZE.MOBILEETC}
          color={Input.COLOR.TEXTGRAY}
          placeholder={pending.form.password.mobilePlaceholder}
          {...register("password")}
        />
      </Box>

      <Box
        display={Box.DISPLAY.FLEX}
        padding={"10px 12px"}
        margin={"12px 0"}
        border={"1px solid"}
        borderColor={Box.COLOR.LINEGRAY}
        borderRadius={"5px"}
        bgColor={Text.COLOR.WHITE}
      >
        <Text
          as={Text.VARIANT.TEXT}
          size={Text.SIZE.MOBILECONTENT}
          width={"80px"}
          margin={"0 8px 0 0"}
          borderRight={"1px solid"}
          borderColor={Text.COLOR.LINEGRAY}
          weight={Text.WEIGHT.BOLD}
          color={Text.COLOR.TEXTGRAY}
          isSort
        >
          {pending.form.passwordCheck.title}
          <Text as={Text.VARIANT.SPAN} color={Text.COLOR.SECONDARY}>
            *
          </Text>
        </Text>

        <Input
          type={"password"}
          width={"calc(100% - 80px)"}
          border={"none"}
          size={Input.SIZE.MOBILEETC}
          color={Input.COLOR.TEXTGRAY}
          {...register("passwordCheck")}
        />
      </Box>

      <Box
        as={"label"}
        display={Box.DISPLAY.FLEX}
        al={"center"}
        padding={"12px 0"}
        margin={"24px 0 0"}
        borderTop={"1px solid"}
        borderBottom={"1px solid"}
        borderColor={Text.COLOR.LINEGRAY}
      >
        <CheckBox
          size={"10px"}
          {...register("isAgreement")}
          borderColor={Input.COLOR.SECONDARY}
        />
        <Text
          as={Text.VARIANT.SPAN}
          color={Text.COLOR.TEXTGRAY}
          margin={"0 0 0 6px"}
          size={Text.SIZE.MOBILECONTENT}
        >
          {pending.form.newsletterAgree}
        </Text>
      </Box>

      {isShowEmail && (
        <Box
          position={"fixed"}
          top={"0"}
          left={"0"}
          width={"100%"}
          height={"100vh"}
          display={Box.DISPLAY.FLEX}
          jc={"center"}
          al={"center"}
          zIndex={Box.LEVEL.ROOT}
          bgColor={Box.COLOR.SHADOW}
          onClick={setShowEmail}
        >
          <Box
            position={"relative"}
            width="275px"
            height={"295px"}
            bgColor={Box.COLOR.WHITE}
            padding={"12px 10px"}
            border={"1px solid"}
            borderColor={Box.COLOR.SECONDARY}
            borderRadius={"20px"}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Text
              as={Text.VARIANT.SUBTITLE}
              size={Text.SIZE.MOBILETITLE}
              weight={Text.WEIGHT.BOLD}
              textAlign={"center"}
            >
              E-Mail
            </Text>

            <Box position={"absolute"} top={"12px"} right={"22px"}>
              <Button themeStyle={Button.THEME.NONE} onClick={setShowEmail}>
                <IoMdClose size={18} />
              </Button>
            </Box>
            <Picker
              optionGroups={optionGroups}
              valueGroups={selectEmail}
              onChange={handleChange}
            />

            <Button
              themeStyle={Button.THEME.NONE}
              width={"100%"}
              padding={"9px 0"}
              fontSize={Button.FONT_SIZE.MOBILETITLE}
              fontWeight={Button.FONT_WEIGHT.BOLD}
              color={Button.COLOR.WHITE}
              bgColor={Button.COLOR.SECONDARY}
              borderRadius={"17.5px"}
              onClick={handleSubmit}
            >
              {pending.modal.submit}
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
};

export default MobileSignupForm;
