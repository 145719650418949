import Box from "components/atoms/box";
import Image from "components/atoms/image";
import Link from "components/atoms/link";
import Slider from "components/atoms/slider";
import Text from "components/atoms/text";
import Banner from "components/molecules/banner";
import List from "components/molecules/list";
import MobileMainBanner from "components/organisms/mobileMainBanner";
import MobileSchedule from "components/organisms/mobileSchedule";
import NewsList from "components/organisms/newsList";
import ScienceCalendar from "components/organisms/scienceCalendar";
import { useGetBanner } from "hooks/api/banner";
import {
  useBroadCastNews,
  useCoverageNews,
  useFeatureNews,
  useHeadLineNews,
  useIctNews,
  usePeopleNews,
  usePreFetchBanner,
  useScienceNews,
} from "hooks/api/news";
import React from "react";
import "./slide.css";
import useCategory from "hooks/utils/useCategory";
import Button from "components/atoms/button";
import convertImage from "utils/convertImage";
import NewsLetterForm from "components/organisms/newsLetterForm";
import NewsLetter from "components/organisms/newsLetter";
import Popup from "components/organisms/popup";
import { usePopup } from "hooks/api/popup";
import homeText from "text/home";

const { bottomCategory, newsTitle, newsLink } = homeText;

const BOTTOM_BANNER = [
  useScienceNews,
  usePeopleNews,
  useFeatureNews,
  useIctNews,
];

const MobileHome = () => {
  const [bottomBannerCategory, setBottomBannerCategory, bottomBannerListApi] =
    useCategory(BOTTOM_BANNER);

  const { list, isSuccess } = usePopup();

  usePreFetchBanner();

  return (
    <Box margin={"55px 0 0 0"}>
      <MobileMainBanner />

      <Box
        display={Box.DISPLAY.FLEX}
        flexDirection={"column"}
        jc={"center"}
        width={"100%"}
        padding={"20px 12px 0"}
      >
        <List api={useCoverageNews}>
          {(coverageNews) =>
            coverageNews && <MobileSchedule coverageNews={coverageNews} />
          }
        </List>
        <Box
          width={"100%"}
          bgColor={Box.COLOR.LIGHTGRAY}
          borderRadius={"10px"}
          padding={"20px 24px"}
          margin={"20px 0 0"}
        >
          <NewsList
            title={newsTitle.headline.title}
            bold={newsTitle.headline.bold}
            size={Text.SIZE.MOBILETITLE}
            margin={"0 0 20px"}
            isMobile
          >
            <List api={useHeadLineNews} isBorderTop>
              {(list) =>
                list && (
                  <NewsList.LAYOUT.MobileHeadLineNews
                    list={list}
                    borderFilter={(idx) => idx !== 1}
                  />
                )
              }
            </List>
          </NewsList>
        </Box>

        <Box
          width={"100%"}
          bgColor={Box.COLOR.LIGHTGRAY}
          borderRadius={"10px"}
          padding={"20px 24px"}
          margin={"20px 0 0"}
        >
          <Box
            display={Box.DISPLAY.FLEX}
            wrap={"wrap"}
            border={"1px solid"}
            borderColor={Box.COLOR.LINEGRAY}
            borderRadius={"5px"}
          >
            <Link width={"50%"} link={"/science?category=all&type=title"}>
              <Box
                display={Box.DISPLAY.FLEX}
                flexDirection="column"
                al="center"
                padding={"12px 0 6px"}
                borderRight="1px solid"
                borderBottom="1px solid"
                borderColor={Box.COLOR.LINEGRAY}
              >
                <Image
                  src={"/assets/mobile/home/science.svg"}
                  alt={"science"}
                />
                <Text
                  as={Text.VARIANT.TEXT}
                  size={Text.SIZE.MOBILEETC}
                  color={Text.COLOR.PRIAMRY}
                  margin={"8px 0 0 0"}
                >
                  {newsLink.science}
                </Text>
              </Box>
            </Link>
            <Link
              width={"50%"}
              link={"/news?category=COM045_AcXdVFG&type=title"}
            >
              <Box
                display={Box.DISPLAY.FLEX}
                flexDirection="column"
                al="center"
                padding={"12px 0 6px"}
                borderBottom="1px solid"
                borderColor={Box.COLOR.LINEGRAY}
              >
                <Image src={"/assets/mobile/home/group.svg"} alt={"group"} />
                <Text
                  as={Text.VARIANT.TEXT}
                  size={Text.SIZE.MOBILEETC}
                  color={Text.COLOR.PRIAMRY}
                  margin={"8px 0 0 0"}
                >
                  {newsLink.group}
                </Text>
              </Box>
            </Link>
            <Link
              width={"50%"}
              link={"/news?category=COM045_UaAtTVG&type=title"}
            >
              <Box
                display={Box.DISPLAY.FLEX}
                flexDirection="column"
                al="center"
                padding={"12px 0 6px"}
                borderRight="1px solid"
                borderColor={Box.COLOR.LINEGRAY}
              >
                <Image src={"/assets/mobile/home/it.svg"} alt={"it"} />

                <Text
                  as={Text.VARIANT.TEXT}
                  size={Text.SIZE.MOBILEETC}
                  color={Text.COLOR.PRIAMRY}
                  margin={"8px 0 0 0"}
                >
                  {newsLink.breaking}
                </Text>
              </Box>
            </Link>

            <Link width={"50%"} link={"/daily?category=daily&type=title"}>
              <Box
                display={Box.DISPLAY.FLEX}
                flexDirection="column"
                al="center"
                padding={"12px 0 6px"}
              >
                <Image src={"/assets/mobile/home/hot.svg"} alt={"hot"} />

                <Text
                  as={Text.VARIANT.TEXT}
                  size={Text.SIZE.MOBILEETC}
                  color={Text.COLOR.PRIAMRY}
                  margin={"8px 0 0 0"}
                >
                  {newsLink.daily}
                </Text>
              </Box>
            </Link>
          </Box>
        </Box>
      </Box>

      <Box margin={"20px 0 0"}>
        <List api={useGetBanner} params={{ type: "middle-mobile" }} isBorderTop>
          {(list) =>
            list && (
              <Banner imageList={list} bottom={"3px"}>
                {(imageList) =>
                  imageList.map(({ filePath, key, url }, idx) => (
                    <Banner.Slide key={key}>
                      <Box
                        aspectRatio={"375/106"}
                        overflow="hidden"
                        ariaLabel={`${idx + 1} 번째 중간 배너 이미지`}
                      >
                        <Link width={"100%"} link={url} target="_blank">
                          <Image
                            src={convertImage(filePath)}
                            alt="banner"
                            width="100%"
                            height="100%"
                            fit="cover"
                            ariaLabel={`${idx + 1} 번째 중간 배너 이미지`}
                          />
                        </Link>
                      </Box>
                    </Banner.Slide>
                  ))
                }
              </Banner>
            )
          }
        </List>
      </Box>

      <Box
        display={Box.DISPLAY.FLEX}
        flexDirection={"column"}
        jc={"center"}
        width={"100%"}
        padding={"20px 12px 0"}
      >
        <Box
          width={"100%"}
          bgColor={Box.COLOR.LIGHTGRAY}
          borderRadius={"10px"}
          padding={"20px 24px"}
          margin={"20px 0 0"}
        >
          <NewsList
            title={newsTitle.broadCast.title}
            size={Text.SIZE.MOBILETITLE}
            margin={"0 0 20px"}
            isMobile
            link={"/news?category=COM045_7DaOeSe"}
          >
            <List api={useBroadCastNews} isBorderTop>
              {(list) =>
                list && (
                  <NewsList.LAYOUT.MobileHeadLineNews
                    list={list}
                    category={"news"}
                    borderFilter={(idx) => idx !== 1}
                  />
                )
              }
            </List>
          </NewsList>
        </Box>

        <Box
          width={"100%"}
          bgColor={Box.COLOR.LIGHTGRAY}
          borderRadius={"10px"}
          padding={"20px 24px"}
          margin={"20px 0 0"}
        >
          <Text
            as={Text.VARIANT.TITLE}
            size={Text.SIZE.MOBILETITLE}
            ariaLabel={`과학기술 일정`}
          >
            {newsTitle.scienceCalendar.title}
          </Text>
          <ScienceCalendar />
        </Box>

        <Box
          width={"100%"}
          bgColor={Box.COLOR.LIGHTGRAY}
          borderRadius={"10px"}
          padding={"20px 24px"}
          margin={"20px 0 10px"}
        >
          <Slider slidesPerView={"auto"} spaceBetween={30} isAuto={false}>
            {bottomCategory.map(({ title }, idx) => (
              <Slider.Slide key={title}>
                <Button
                  themeStyle={Button.THEME.NONE}
                  fontSize={Text.SIZE.MOBILECATEGORY}
                  padding={"0 0 12px"}
                  color={
                    bottomBannerCategory === idx
                      ? Button.COLOR.SECONDARY
                      : Button.COLOR.BLACK
                  }
                  fontWeight={
                    bottomBannerCategory === idx
                      ? Button.FONT_WEIGHT.BOLD
                      : Button.FONT_WEIGHT.NORMAL
                  }
                  borderBottom={bottomBannerCategory === idx && "2px solid"}
                  borderColor={Button.COLOR.SECONDARY}
                  onClick={() => setBottomBannerCategory(idx)}
                >
                  {title}
                </Button>
              </Slider.Slide>
            ))}
          </Slider>

          <Box margin={"30px 0 0"}>
            <NewsList
              size={Text.SIZE.MOBILETITLE}
              margin={"0 0 20px"}
              isMobile
              link={bottomCategory[bottomBannerCategory].link}
            >
              <List api={bottomBannerListApi} isBorderTop>
                {(list) =>
                  list && (
                    <NewsList.LAYOUT.MobileThumbnailNews
                      list={list}
                      category={bottomCategory[bottomBannerCategory].root}
                    />
                  )
                }
              </List>
            </NewsList>
          </Box>
        </Box>
      </Box>

      <List api={useGetBanner} params={{ type: "sns" }} isBorderTop>
        {(list) =>
          list && (
            <Banner
              imageList={list}
              slidesPerView={1}
              spaceBetween={10}
              bottom={"3px"}
            >
              {(imageList) => {
                return imageList.map(({ key, filePath, url }, idx) => (
                  <Banner.Slide key={key}>
                    <Box
                      display={Box.DISPLAY.FLEX}
                      jc={"center"}
                      al={"center"}
                      aspectRatio={"375/160"}
                      overflow="hidden"
                      borderRadius={Box.RADIUS.NORMAL}
                    >
                      <Link
                        width={"375px"}
                        height={"160px"}
                        link={url}
                        target="_blank"
                      >
                        <Image
                          src={convertImage(filePath)}
                          alt="banner"
                          width={"375px"}
                          height={"160px"}
                          ariaLabel={`${idx + 1} 번째 하단 SNS 배너 이미지`}
                        />
                      </Link>
                    </Box>
                  </Banner.Slide>
                ));
              }}
            </Banner>
          )
        }
      </List>

      <NewsLetterForm />
      <NewsLetter />

      {isSuccess &&
        list.map(({ url, filePath, title, contents, newWindow, key }, idx) => (
          <Popup
            position={`${(idx + 1) * 50}px`}
            link={url}
            image={filePath}
            type={contents}
            newWindow={newWindow}
            key={key}
            id={key}
          />
        ))}
    </Box>
  );
};

export default MobileHome;
