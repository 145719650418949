import React from "react";
import Text from "components/atoms/text";
import Box from "components/atoms/box";
import Button from "components/atoms/button";
import { BsChevronDown } from "@react-icons/all-files/bs/BsChevronDown";
import { BsChevronUp } from "@react-icons/all-files/bs/BsChevronUp";
import { useState } from "react";
import { useEffect } from "react";
import useUrl from "hooks/utils/useUrl";
import { useRef } from "react";

const FeatureContent = ({ contents }) => {
  const {
    query: { page = 1 },
  } = useUrl();
  const [isExpanded, setIsExpanded] = useState(false);
  const [isMore, setIsMore] = useState(false);
  const contentRef = useRef(null);

  const handleToggleMore = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    const height = 112;
    if (contentRef.current.clientHeight > height) {
      setIsExpanded(true);
      setIsMore(true);
    }
  }, [page]);

  return (
    <>
      <Text
        ref={contentRef}
        as={Text.VARIANT.TEXT}
        width={"100%"}
        lineHeight={"28px"}
        lineClamp={isExpanded ? "4" : "none"}
        margin={"40px 0"}
      >
        {contents}
      </Text>

      {isMore && (
        <Box
          display={Box.DISPLAY.FLEX}
          jc="flex-end"
          width={"100%"}
          margin={"0 0 20px"}
          borderBottom="1px solid"
          borderColor={Button.COLOR.LINEGRAY}
        >
          <Button
            themeStyle={Button.THEME.NONE}
            color={Button.COLOR.TEXTGRAY}
            fontSize={Button.FONT_SIZE.CLIENTNORMAL}
            padding={"10px 0"}
            onClick={handleToggleMore}
            display={"flex"}
            al={"center"}
          >
            {isExpanded ? (
              <>
                <Text as={Text.VARIANT.SPAN} margin={"0 4px 0 0"}>
                  더보기
                </Text>
                <BsChevronDown />
              </>
            ) : (
              <>
                <Text as={Text.VARIANT.SPAN} margin={"0 4px 0 0"}>
                  접기
                </Text>
                <BsChevronUp />
              </>
            )}
          </Button>
        </Box>
      )}
    </>
  );
};

export default FeatureContent;
