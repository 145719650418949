import Box from "components/atoms/box";
import Button from "components/atoms/button";
import Image from "components/atoms/image";
import Text from "components/atoms/text";
import MobileSignupForm from "components/organisms/mobileSignupForm";
import SignupAgreement from "components/organisms/signupAgreement";
import { useCreateMemberMutation } from "hooks/api/auth";
import useContextForm from "hooks/utils/useContextForm";
import usePattern from "hooks/utils/usePattern";
import useUrl from "hooks/utils/useUrl";
import React, { useState } from "react";
import signUpText from "text/signup";

const { success, fail, pending } = signUpText;

const MobileSignUp = () => {
  const {
    Provider,
    methods: { watch, reset, handleSubmit },
  } = useContextForm({
    id: "",
    name: "",
    email: "",
    emailDomain: "",
    emailSelect: "",
    phone1: "",
    phone2: "",
    phone3: "",
    password: "",
    passwordCheck: "",
    emailCheck: "",
    isEmail: false,
    isAgreement: false,
    isUsing: false,
    isPrivacy: false,
  });
  const pattern = usePattern();
  const { changeLink } = useUrl();
  const [request, setRequest] = useState("pending");

  const handleSuccessCreateMember = () => {
    setRequest("success");
    window.scrollTo(0, 0);
  };

  const handleFailCreateMember = () => {
    setRequest("fail");
    window.scrollTo(0, 0);
  };

  const createMemberMutation = useCreateMemberMutation(
    handleSuccessCreateMember,
    handleFailCreateMember
  );

  const handleFail = () => {
    setRequest("pending");
    reset();
  };

  const isActive =
    watch("name").trim() !== "" &&
    watch("email").trim() !== "" &&
    watch("emailDomain").trim() !== "" &&
    watch("phone1").trim() !== "" &&
    watch("phone2").trim() !== "" &&
    watch("phone3").trim() !== "" &&
    pattern.call.value.test(
      `${watch("phone1")}-${watch("phone2")}-${watch("phone3")}`
    ) &&
    watch("password").trim() !== "" &&
    pattern.password.validate(watch("password")) &&
    watch("passwordCheck").trim() !== "" &&
    watch("password") === watch("passwordCheck") &&
    watch("isEmail") &&
    watch("isUsing") &&
    watch("isPrivacy");

  return (
    <Provider>
      <Box display={Box.DISPLAY.FLEX} jc="center">
        <Box
          width={"calc(100% - 24px)"}
          padding={"32px 24px 40px"}
          margin={"75px 0 10px"}
          borderRadius={"10px"}
          bgColor={
            request === "pending" ? Box.COLOR.LIGHTGRAY : Box.COLOR.WHITE
          }
        >
          {request === "success" && (
            <Box
              display={Box.DISPLAY.FLEX}
              flexDirection={"column"}
              al={"center"}
              margin={"0 0 400px"}
            >
              <Image src={"/assets/auth/checked.svg"} width={"20px"} />
              <Text
                as={Text.VARIANT.SUBTITLE}
                size={Text.SIZE.MOBILETITLE}
                weight={Text.WEIGHT.BOLD}
                color={Text.COLOR.SECONDARY}
                textAlign={"center"}
                margin={"24px 0 0"}
              >
                {success.title[0]}
              </Text>
              <Text
                as={Text.VARIANT.SUBTITLE}
                size={Text.SIZE.MOBILETITLE}
                weight={Text.WEIGHT.BOLD}
                color={Text.COLOR.SECONDARY}
                textAlign={"center"}
                margin={"0 0 35px"}
              >
                {success.title[1]}
              </Text>

              <Button
                width={"252px"}
                padding={"10px 0"}
                margin={"0 0 10px"}
                border={"1px solid"}
                borderColor={Button.COLOR.SECONDARY}
                borderRadius={"21px"}
                bgColor={Button.COLOR.SECONDARY}
                color={Button.COLOR.WHITE}
                fontSize={Button.FONT_SIZE.MOBILECONTENT}
                fontWeight={Button.FONT_WEIGHT.BOLD}
                onClick={() => changeLink("/login")}
              >
                {success.submit}
              </Button>
            </Box>
          )}
          {request === "fail" && (
            <Box
              display={Box.DISPLAY.FLEX}
              flexDirection={"column"}
              al={"center"}
              margin={"0 0 400px"}
            >
              <Image src={"/assets/auth/Error.svg"} width={"20px"} />
              <Text
                as={Text.VARIANT.SUBTITLE}
                size={Text.SIZE.MOBILETITLE}
                weight={Text.WEIGHT.BOLD}
                color={Text.COLOR.SECONDARY}
                textAlign={"center"}
                margin={"24px 0 0"}
              >
                {fail.title[0]}
              </Text>
              <Text
                as={Text.VARIANT.SUBTITLE}
                size={Text.SIZE.MOBILETITLE}
                weight={Text.WEIGHT.BOLD}
                color={Text.COLOR.SECONDARY}
                textAlign={"center"}
                margin={"0 0 35px"}
              >
                {fail.title[1]}
              </Text>

              <Button
                width={"252px"}
                padding={"10px 0"}
                margin={"0 0 10px"}
                border={"1px solid"}
                borderColor={Button.COLOR.SECONDARY}
                borderRadius={"21px"}
                bgColor={Button.COLOR.SECONDARY}
                color={Button.COLOR.WHITE}
                fontSize={Button.FONT_SIZE.MOBILECONTENT}
                fontWeight={Button.FONT_WEIGHT.BOLD}
                onClick={handleFail}
              >
                {fail.submit}
              </Button>
            </Box>
          )}
          {request === "pending" && (
            <>
              <Text
                as={Text.VARIANT.SUBTITLE}
                size={Text.SIZE.MOBILETITLE}
                textAlign={"center"}
                padding={"0 0 40px"}
              >
                {pending.title}
              </Text>

              <MobileSignupForm />

              <Text
                as={Text.VARIANT.SUBTITLE}
                size={Text.SIZE.MOBILETITLE}
                textAlign={"center"}
                margin={"60px 0 22px"}
                borderColor={Text.COLOR.LINEGRAY}
              >
                {pending.termsOfUse}
              </Text>

              <SignupAgreement />

              <Button
                width={"100%"}
                height={"36px"}
                margin={"40px 0 12px"}
                color={Button.COLOR.WHITE}
                bgColor={
                  isActive ? Button.COLOR.SECONDARY : Button.COLOR.LINEGRAY
                }
                border={"1px solid"}
                borderColor={
                  isActive ? Button.COLOR.SECONDARY : Button.COLOR.LINEGRAY
                }
                borderRadius={"20px"}
                fontSize={Button.FONT_SIZE.MOBILECONTENT}
                fontWeight={Button.FONT_WEIGHT.BOLD}
                onClick={handleSubmit(createMemberMutation)}
                disabled={!isActive}
              >
                {pending.submit.accept}
              </Button>
              <Button
                width={"100%"}
                height={"36px"}
                color={Button.COLOR.SECONDARY}
                bgColor={Button.COLOR.WHITE}
                border={"1px solid"}
                borderColor={Button.COLOR.SECONDARY}
                borderRadius={"20px"}
                fontSize={Button.FONT_SIZE.MOBILECONTENT}
                fontWeight={Button.FONT_WEIGHT.BOLD}
              >
                {pending.submit.cancel}
              </Button>
            </>
          )}
        </Box>
      </Box>
    </Provider>
  );
};

export default MobileSignUp;
