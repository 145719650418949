import Box from "components/atoms/box";
import Loading from "components/atoms/loading";
import ImageUploader from "components/molecules/imageUploader";
import InputForm from "components/molecules/inputForm";
import AdminDetailForm from "components/organisms/adminDetailForm";
import AdminDetailFormContainer from "components/organisms/adminDetailFormContainer";
import DatePickerList from "components/organisms/datePickerList";
import EventDatePicker from "components/organisms/eventDatePicker";
import { AdminContentsContainer } from "components/templates/admin/admin.style";
import dayjs from "dayjs";
import {
  useCreateSchedule,
  useScienceCalendarDetail,
  useUpdateScheduleInfo,
} from "hooks/api/admin/calendar";
import useUrl from "hooks/utils/useUrl";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import useEventStore from "store/useEventStore";
import NamoWebEditor from "components/molecules/namoWebEditor";
import FileUploader from "components/molecules/fileUploader";
import { calendar } from "text/admin/calendar";
import usePendingMutate from "hooks/utils/usePendingMutate";

const ScienceDetail = () => {
  const {
    path,
    query: { key },
  } = useUrl();

  const { defaultValues, adviceComments } = calendar.detail;

  const today = dayjs();

  const [preSelectedStartDate, setPreSelectedStartDate] = useState(today);
  const [preSelectedEndDate, setPreSelectedEndDate] = useState(today);

  const categoryName = path[2];

  const subTitle = key ? "일정 수정" : "일정 등록";

  const handleSuccess = () => {
    localStorage.removeItem("science");
  };

  const api = key ? useUpdateScheduleInfo : useCreateSchedule;
  const mutate = api(handleSuccess);

  const { isPending, handleMutate } = usePendingMutate();
  const { list, isLoading, fileList } = useScienceCalendarDetail({ key });

  const {
    selectedStartDate,
    selectedEndDate,
    selectedStartHours,
    selectedStartMinutes,
    selectedEndHours,
    selectedEndMinutes,
  } = useEventStore();

  useEffect(() => {
    if (list) {
      const {
        eventBeginDate,
        eventEndDate,
        preBeginDate,
        preEndDate,
        eventBeginHour,
        eventBeginMin,
        eventEndHour,
        eventEndMin,
      } = list;
      useEventStore.setState({
        selectedStartDate: dayjs(eventBeginDate),
        selectedEndDate: dayjs(eventEndDate),
        selectedStartHours: eventBeginHour,
        selectedStartMinutes: eventBeginMin,
        selectedEndHours: eventEndHour,
        selectedEndMinutes: eventEndMin,
      });
      setPreSelectedStartDate(dayjs(preBeginDate));
      setPreSelectedEndDate(dayjs(preEndDate));
    }
  }, [list]);

  const formatToYYYYMMDD = (date) => date.format("YYYY-MM-DD");

  const eventDates = {
    eventBeginDate: formatToYYYYMMDD(selectedStartDate),
    eventEndDate: formatToYYYYMMDD(selectedEndDate),
    preBeginDate: formatToYYYYMMDD(preSelectedStartDate),
    preEndDate: formatToYYYYMMDD(preSelectedEndDate),
    eventBeginHour: selectedStartHours,
    eventBeginMin: selectedStartMinutes,
    eventEndHour: selectedEndHours,
    eventEndMin: selectedEndMinutes,
  };

  const _instanceRef = useRef(null);

  const formInfo = [
    {
      title: "제목",
      minHeight: "48px",
      components: (
        <InputForm
          name="title"
          type="text"
          width="500px"
          height="32px"
          padding="0 12px"
          theme="secondary"
          defaultName={list?.eventTitle}
          required="필수값입니다."
          ariaLabel="제목"
        />
      ),
      required: true,
    },
    {
      title: "행사일자 / 시간",
      height: "48px",
      components: <EventDatePicker list={list} />,
      required: false,
    },
    {
      title: "사전등록기간",
      height: "48px",
      components: (
        <DatePickerList
          selectedStartDate={preSelectedStartDate}
          selectedEndDate={preSelectedEndDate}
          setSelectedStartDate={setPreSelectedStartDate}
          setSelectedEndDate={setPreSelectedEndDate}
        />
      ),
      required: false,
    },
    {
      title: "행사장소",
      height: "48px",
      components: (
        <InputForm
          name="eventPlace"
          type="text"
          width="500px"
          height="32px"
          padding="0 12px"
          theme="secondary"
          defaultName={list?.eventPlace}
          ariaLabel="행사장소"
        />
      ),
      required: false,
    },
    {
      title: "이메일",
      minHeight: "48px",
      components: (
        <InputForm
          name="email"
          type="text"
          width="500px"
          height="32px"
          padding="0 12px"
          theme="secondary"
          defaultName={list?.email}
          ariaLabel="이메일"
        />
      ),
      required: false,
    },
    {
      title: "내용",
      minHeight: "436px",
      components: <NamoWebEditor name="editor" defaultValue={list?.contents} ref={_instanceRef} />,
      required: false,
      isUpperPosition: true,
    },
    {
      title: "첨부파일",
      minHeight: "48px",
      components: <FileUploader registerName="files" defaultFileList={fileList} no={list?.key} />,
      required: false,
    },
    {
      title: "썸네일 등록",
      minHeight: "281px",
      components: (
        <ImageUploader
          registerName="thumbnail"
          comments={adviceComments[categoryName]}
          defaultPath={list?.thumbnail ?? null}
          serverImgPath="thumbnail"
        />
      ),
      required: false,
      isUpperPosition: true,
    },
  ];

  const createFormData = (data, contents, files, thumbnail) => {
    const formData = new FormData();
    formData.append(
      "param",
      JSON.stringify({
        ...data,
        contents,
      })
    );
    if (files && files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        formData.append("files", files[i]);
      }
    }
    if (thumbnail) {
      formData.append("thumbnail", thumbnail);
    }
    return formData;
  };

  const handleSubmit = (data) => {
    const contents = _instanceRef.current.GetBodyValue();
    const { title, eventPlace, email, thumbnail, files } = data;

    const formData = createFormData(
      {
        title,
        eventPlace,
        email,
        ...eventDates,
      },
      contents,
      files,
      thumbnail
    );

    handleMutate(mutate, { data: formData, key });
  };

  if (isLoading) return <Loading />;

  return (
    <AdminContentsContainer>
      <Box maxWidth="1024px">
        <AdminDetailFormContainer
          title={subTitle}
          defaultValues={defaultValues}
          handleSubmit={handleSubmit}
          isPending={isPending}
          hasTemporaryFunc={true}
          eventDates={eventDates}
          setPreSelectedStartDate={setPreSelectedStartDate}
          setPreSelectedEndDate={setPreSelectedEndDate}
          ref={_instanceRef}
        >
          <AdminDetailForm formInfo={formInfo} />
        </AdminDetailFormContainer>
      </Box>
    </AdminContentsContainer>
  );
};

export default ScienceDetail;
