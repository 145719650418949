const convertContactNum = (num) => {
  const hp = num.split("-");
  const hp1 = hp[0];
  const hp2 = hp[1];
  const hp3 = hp[2];

  return {
    hp1,
    hp2,
    hp3,
  };
};

export default convertContactNum;
