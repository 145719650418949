import Box from "components/atoms/box";
import Loading from "components/atoms/loading";
import Text from "components/atoms/text";
import SelectMenu from "components/molecules/selectMenu";
import AdminDetailForm from "components/organisms/adminDetailForm";
import AdminDetailFormContainer from "components/organisms/adminDetailFormContainer";
import { AdminContentsContainer } from "components/templates/admin/admin.style";
import { useRequestDetailList, useUpdateRequestInfo } from "hooks/api/admin/cs";
import useUrl from "hooks/utils/useUrl";
import React, { useEffect } from "react";
import { useState } from "react";
import { common } from "text/admin/common";
import { cs } from "text/admin/cs";
import convertDayWithTime from "utils/convertDayWithTime";

const RequestDetail = () => {
  const {
    query: { key },
  } = useUrl();

  const { options, defaultValues } = cs.detail;
  const {
    default: { update },
  } = common.alert;

  const [selectedMenu, setSelectedMenu] = useState("N");

  const { list, isLoading } = useRequestDetailList({ key });

  useEffect(() => {
    if (list) setSelectedMenu(list.status);
  }, [list]);

  const mutate = useUpdateRequestInfo();

  const formInfo = [
    {
      title: "상태",
      height: "48px",
      components: (
        <SelectMenu
          registerName="status"
          selectedMenu={selectedMenu}
          setSelectedMenu={setSelectedMenu}
          options={options}
          width="104px"
        />
      ),
      required: false,
    },
    {
      title: "신청일",
      height: "48px",
      components: (
        <Box display={Box.DISPLAY.FLEX} al="center" height="100%">
          <Text size={Text.SIZE.ADMINCONTENTDETAIL} as={Text.VARIANT.TEXT}>
            {convertDayWithTime(list?.regDate)}
          </Text>
        </Box>
      ),
      required: false,
    },
    {
      title: "신청자",
      height: "48px",
      components: (
        <Box display={Box.DISPLAY.FLEX} al="center" height="100%">
          <Text size={Text.SIZE.ADMINCONTENTDETAIL} as={Text.VARIANT.TEXT}>
            {list?.writer}
          </Text>
        </Box>
      ),
      required: false,
    },
    {
      title: "이메일",
      height: "48px",
      components: (
        <Box display={Box.DISPLAY.FLEX} al="center" height="100%">
          <Text
            size={Text.SIZE.ADMINCONTENTDETAIL}
            as={Text.VARIANT.TEXT}
          >{`${list?.email1}@${list?.email2}`}</Text>
        </Box>
      ),
      required: false,
    },
    {
      title: "제목",
      minHeight: "48px",
      components: (
        <Box display={Box.DISPLAY.FLEX} al="center" height="31px">
          <Text size={Text.SIZE.ADMINCONTENTDETAIL} as={Text.VARIANT.TEXT}>
            {list?.title}
          </Text>
        </Box>
      ),
      required: false,
    },
    {
      title: "내용",
      minHeight: "48px",
      components: (
        <Box display={Box.DISPLAY.FLEX} al="center" minHeight="31px">
          <Text size={Text.SIZE.ADMINCONTENTDETAIL} as={Text.VARIANT.TEXT} lineHeight="1.2">
            {list?.contents}
          </Text>
        </Box>
      ),
      required: false,
    },
  ];

  const handleSubmit = (data) => {
    const confirm = window.confirm(update);
    if (confirm) mutate({ key, data });
  };

  if (isLoading) return <Loading />;

  return (
    <AdminContentsContainer>
      <Box maxWidth="1024px">
        <AdminDetailFormContainer
          title="관리자에게 수정"
          defaultValues={defaultValues}
          handleSubmit={handleSubmit}
        >
          <AdminDetailForm formInfo={formInfo} />
        </AdminDetailFormContainer>
      </Box>
    </AdminContentsContainer>
  );
};

export default RequestDetail;
