import { newsletter } from "api/redirect";
import { useQuery } from "react-query";

const useNewsletterRedirect = (key) => {
  const { data, isError } = useQuery(["newsletterRedirect", key], () =>
    newsletter(key)
  );

  return { data, isError };
};

export default useNewsletterRedirect;
