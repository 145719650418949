import { DELETE, GET, POST, PUT } from "api/config";
import { coverage } from "../url";

const { coverageUrl } = coverage;

// 과총동정 목록
export const getScienceCoverageList = async ({
  page = "1",
  itemsPerPage = "10",
  searchCondition = "",
  searchValue = "",
  dateCondition = "",
  startDate = "",
  endDate = "",
}) =>
  await GET(
    `${coverageUrl}?pageIndex=${page}&itemsPerPage=${itemsPerPage}&${searchCondition}=${searchValue}&searchDateCondition=${dateCondition}&startDate=${startDate}&endDate=${endDate}`
  );

// 과총동정 상세
export const getScienceCoverageDetail = async ({ key }) => await GET(`${coverageUrl}/${key}`);

// 과총동정 등록
export const createCoverage = async ({ data }) => {
  await POST(`${coverageUrl}`, data);
};

// 과총동정 수정
export const updateCoverageInfo = async ({ key, data }) => {
  await PUT(`${coverageUrl}/${key}`, data);
};

// 과총동정 삭제
export const deleteCoverage = async ({ data }) => {
  await DELETE(`${coverageUrl}`, data);
};
