import React from "react";
import { ButtonComponent } from "./button.style";
import PropTypes from "prop-types";
import { COLOR, FONT_SIZE, FONT_WEIGHT, RADIUS } from "styles/theme";

const SORT = {
  BETWEEN: "between",
  AROUND: "around",
  CENTER: "center",
  START: "start",
  END: "end",
};

const SIZE = {
  SMALL: "small",
  MEDIUM: "medium",
  LARGE: "large",
  FULL: "full",
};

const THEME = {
  PRIMARY: "primary",
  COMMON: "common",
  CATEGORY: "category",
  NONE: "none",
  SIDEBARMENU: "sideBarMenu",
  ADMINHOVER: "adminHover",
};

const TYPE = {
  BUTTON: "button",
  SUBMIT: "submit",
  RESET: "reset",
};

const Button = (props) => {
  const {
    children,
    //
    display,
    al,
    jc,
    //
    size,
    width,
    maxWidth,
    height,
    margin,
    padding,
    sort,
    position,
    top,
    bottom,
    left,
    right,
    themeStyle,
    //
    color,
    fontSize,
    fontWeight,
    //
    bgColor,
    //
    border,
    borderTop,
    borderBottom,
    borderLeft,
    borderRight,
    borderRadius,
    borderColor,
    //
    isActive,
    //
    onClick,
    type,
    disabled,
    //
    value,
    //
    ariaLabel,
  } = props;

  return (
    <ButtonComponent
      $display={display}
      al={al}
      jc={jc}
      //
      size={size}
      $width={width}
      maxWidth={maxWidth}
      $height={height}
      margin={margin}
      padding={padding}
      $color={color}
      $fontSize={fontSize}
      $fontWeight={fontWeight}
      //
      position={position}
      top={top}
      bottom={bottom}
      left={left}
      right={right}
      //
      bgColor={bgColor}
      //
      border={border}
      borderTop={borderTop}
      borderBottom={borderBottom}
      borderLeft={borderLeft}
      borderRight={borderRight}
      borderRadius={borderRadius}
      borderColor={borderColor}
      //
      sort={sort}
      themeStyle={themeStyle}
      isActive={isActive}
      //
      aria-label={ariaLabel}
      //
      onClick={onClick}
      type={type}
      disabled={disabled}
      //
      value={value}
    >
      {children}
    </ButtonComponent>
  );
};

Button.THEME = THEME;
Button.TYPE = TYPE;
Button.SIZE = SIZE;

Button.RADIUS = RADIUS;
Button.COLOR = COLOR;

Button.FONT_SIZE = FONT_SIZE;
Button.FONT_WEIGHT = FONT_WEIGHT;

Button.SORT = SORT;

Button.propsTypes = {
  /**
   * The content of the component.
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),

  /**
   * The size of the component.
   * use Button.SIZE
   */
  size: PropTypes.oneOf(Object.values(SIZE)),
  /**
   * The width of the component.
   * ex) "100px"
   */
  width: PropTypes.string,
  /**
   * The height of the component.
   * ex) "100px"
   */
  height: PropTypes.string,
  /**
   * The position of the component.
   * ex) "absolute"
   */
  position: PropTypes.string,
  /**
   * The margin of the component.
   * ex) "10px 10px 0 10px"
   * ex) "10px 10px"
   * ex) "10px"
   */
  margin: PropTypes.string,
  /**
   * The padding of the component.
   * ex) "10px 10px 0 10px"
   * ex) "10px 10px"
   * ex) "10px"
   */
  padding: PropTypes.string,

  /**
   * The borderColor of the component.
   * use Box.COLOR
   */
  color: PropTypes.oneOf(Object.values(COLOR)),

  /**
   * The fontSize of the component.
   */
  fontSize: PropTypes.oneOf(Object.values(FONT_SIZE)),
  /**
   * The fontWeight of the component.
   * use Box.WEIGHT
   */
  fontWeight: PropTypes.oneOf(Object.values(FONT_WEIGHT)),

  /**
   * The bgColor of the component.
   * use Box.COLOR
   */
  bgColor: PropTypes.oneOf(Object.values(COLOR)),

  /**
   * The border of the component.
   * ex) "1px solid #000"
   */
  border: PropTypes.string,
  /**
   * The borderTop of the component.
   * ex) "1px solid #000"
   */
  borderTop: PropTypes.string,
  /**
   * The borderBottom of the component.
   * ex) "1px solid #000"
   */
  borderBottom: PropTypes.string,
  /**
   * The borderLeft of the component.
   * ex) "1px solid #000"
   */
  borderLeft: PropTypes.string,
  /**
   * The borderRight of the component.
   * ex) "1px solid #000"
   */
  borderRight: PropTypes.string,
  /**
   * The borderRadius of the component.
   * use Box.RADIUS
   */
  borderRadius: PropTypes.oneOf(Object.values(RADIUS)),
  /**
   * The borderColor of the component.
   * use Box.COLOR
   */
  borderColor: PropTypes.oneOf(Object.values(COLOR)),

  /**
   * The sort of the component.
   * use Button.SORT
   */
  sort: PropTypes.oneOf(Object.values(SORT)),
  /**
   * The theme of the component.
   * use Button.THEME
   */
  themeStyle: PropTypes.oneOf(Object.values(THEME)),
  /**
   * The isActive of the component.
   */
  isActive: PropTypes.bool,

  /**
   * The type of the component.
   * use Button.TYPE
   */
  type: PropTypes.oneOf(Object.values(TYPE)),
  /**
   * The onClick of the component.
   */
  onClick: PropTypes.func,
  /**
   * The disabled of the component.
   */
  disabled: PropTypes.bool,
};

export default Button;
