import React, { useState } from "react";
import { Sitemap, Rss, Admin } from "components/organisms/helpContent";
import Box from "components/atoms/box";
import useUrl from "hooks/utils/useUrl";
import SideNews from "components/organisms/sideNews";

const HelpForm = {
  sitemap: Sitemap,
  rss: Rss,
  admin: Admin,
};

const Help = () => {
  const {
    query: { category = "sitemap" },
  } = useUrl();

  const [isHidden, setIsHidden] = useState(false);

  const HelpContent = HelpForm[category];

  return (
    <Box as={"section"} display={Box.DISPLAY.FLEX} jc="center">
      <Box
        display={Box.DISPLAY.FLEX}
        width={"1260px"}
        margin={"80px 0 200px"}
        borderRadius={"10px"}
        bgColor={isHidden ? Box.COLOR.WHITE : Box.COLOR.LIGHTGRAY}
      >
        <HelpContent setIsHidden={setIsHidden} />

        {!isHidden && <SideNews padding={"32px 40px 80px"} />}
      </Box>
    </Box>
  );
};

export default Help;
