import { useEffect } from "react";
import { useState } from "react";
import generateCheckListCount from "utils/generateCheckList";

const useAllCheckbox = (count, list, subObj, subCategoryList) => {
  const [checkAll, setCheckAll] = useState(false);
  const [checkboxes, setCheckboxes] = useState({ main: {}, sub: {} });
  const [cate2Name, setCate2Name] = useState([]);

  useEffect(() => {
    const main = generateCheckListCount(parseInt(count), list);
    if (list.length > 0) {
      if (subObj) {
        setCheckboxes({ main, sub: subObj });

        for (const menuNo in main) {
          const subCategories = subCategoryList(menuNo);
          const allSubChecked = subCategories.every((sub) => subObj[sub.menuNo]);

          if (allSubChecked) {
            setCheckboxes((p) => ({
              ...p,
              main: { ...p.main, [menuNo]: true },
            }));
          }
        }

        const allMainChecked =
          Object.values(checkboxes.main).length > 0 &&
          Object.values(checkboxes.main).every((checked) => checked);
        setCheckAll(allMainChecked);
      } else {
        setCheckboxes(main);
      }
    }
  }, [count, list]);

  const handleCheckAllChange = () => {
    const mainCheckboxes = subObj ? checkboxes.main : checkboxes;
    const subCheckboxes = subObj ? checkboxes.sub : null;

    setCheckAll((prev) => {
      const isCheckedAll = !prev;
      const mainCheckList = Object.keys(mainCheckboxes).reduce((acc, key) => {
        acc[key] = isCheckedAll;
        return acc;
      }, {});

      if (subCheckboxes) {
        const subCheckList = Object.keys(subCheckboxes).reduce((acc, key) => {
          acc[key] = isCheckedAll;
          return acc;
        }, {});
        setCheckboxes({ main: mainCheckList, sub: subCheckList });
      } else {
        setCheckboxes(mainCheckList);
      }

      return isCheckedAll;
    });
  };

  const updateCate2Name = (codeName, checked) => {
    setCate2Name((prev) => {
      const index = prev.findIndex((item) => item === codeName);
      if (checked && index === -1) {
        return [...prev, codeName];
      } else {
        prev.splice(index, 1);
      }
      return prev;
    });
  };

  const getUpdatedCheckboxes = (name, checked, currentCheckboxes) => {
    if (subCategoryList) {
      const subCategories = subCategoryList(name);
      const subCheckboxes = subCategories.reduce((acc, sub) => {
        acc[sub.menuNo] = checked;
        return acc;
      }, {});

      return {
        main: { ...currentCheckboxes.main, [name]: checked },
        sub: { ...currentCheckboxes.sub, ...subCheckboxes },
      };
    }
    return {
      ...currentCheckboxes,
      [name]: checked,
    };
  };

  const areAllCheckboxesChecked = (updatedCheckboxes) => {
    return Object.values(updatedCheckboxes).every((checkbox) => checkbox);
  };

  const handleCheckboxChange = ({ e, codeName }) => {
    const { name, checked } = e.target;

    if (codeName) updateCate2Name(codeName, checked);

    const updatedCheckboxes = getUpdatedCheckboxes(name, checked, checkboxes);

    setCheckboxes(updatedCheckboxes);

    setCheckAll(areAllCheckboxesChecked({ ...checkboxes, [name]: checked }));
  };

  const handleSubCheckboxChange = ({ e, menuNo }) => {
    const { name, checked } = e.target;
    setCheckboxes((p) => {
      const newCheckboxes = {
        ...p,
        main: {
          ...p.main,
          [menuNo]: checked,
        },
        sub: {
          ...p.sub,
          [name]: checked,
        },
      };

      const subCategories = subCategoryList(menuNo);
      const allSubChecked = subCategories.every((sub) => newCheckboxes.sub[sub.menuNo]);

      newCheckboxes.main[menuNo] = allSubChecked;

      const allCheckboxesChecked = Object.values({
        ...newCheckboxes.main,
        ...{ [name]: checked },
      }).every((checkbox) => checkbox);

      setCheckAll(allCheckboxesChecked);

      return newCheckboxes;
    });
  };

  const resetCheckbox = () => {
    setCheckboxes(
      Object.keys(checkboxes).reduce((acc, key) => {
        acc[key] = false;
        return acc;
      }, {})
    );
    setCheckAll(false);
  };

  const selectedKeys = Object.entries(checkboxes)
    .filter(([_, value]) => value === true)
    .map(([key, _]) => key);

  return {
    checkAll,
    resetCheckbox,
    checkboxes,
    setCheckboxes,
    selectedKeys,
    handleCheckAllChange,
    handleCheckboxChange,
    handleSubCheckboxChange,
    cate2Name,
  };
};

export default useAllCheckbox;
