import React, { useEffect, useRef, useState } from "react";
import Box from "components/atoms/box";
import Input from "components/atoms/input";
import Button from "components/atoms/button";
import { AiOutlineSearch } from "@react-icons/all-files/ai/AiOutlineSearch";
import useUrl from "hooks/utils/useUrl";
import { Select } from "antd";

const { Option } = Select;

const theme = {
  HEADER: {
    margin: "0 20px 0 0",
    maxWidth: "290px",
    input: {
      borderColor: Input.COLOR.LINEGRAY,
      borderRadius: "21px",
      bgColor: Input.COLOR.WHITE,
      size: Input.SIZE.CLIENTETC,
      padding: "12px 33px 12px 15px",
      color: Input.COLOR.TEXTGRAY,
    },
    button: {
      color: Button.COLOR.SECONDARY,
      top: "12px",
      right: "15px",
      size: "18",
    },
  },
  BOARD: {
    margin: "0",
    maxWidth: "290px",
    input: {
      borderColor: Input.COLOR.TRANSPARENT,
      borderRadius: "21px",
      bgColor: Input.COLOR.FOURTH,
      size: Input.SIZE.CLIENTETC,
      color: Input.COLOR.WHITE,
      padding: "12px 33px 12px 15px",
    },
    button: {
      color: Button.COLOR.WHITE,
      top: "12px",
      right: "15px",
      size: "18",
    },
  },
  SEARCH: {
    margin: " 0",
    maxWidth: "600px",
    input: {
      borderColor: Input.COLOR.TRANSPARENT,
      borderRadius: "80px",
      bgColor: Input.COLOR.BANNERGRAY,
      color: Button.COLOR.TEXTGRAY,
      size: Input.SIZE.CLIENTCATEGORY,
      padding: "22px 68px 22px 42px",
      searchPadding: "22px 68px 22px 171px",
    },
    button: {
      color: Button.COLOR.TEXTGRAY,
      top: "22px",
      right: "42px",
      size: "26px",
    },
  },
  MOBILESEARCH: {
    margin: " 0",
    maxWidth: "600px",
    input: {
      borderColor: Input.COLOR.TRANSPARENT,
      borderRadius: "80px",
      bgColor: Input.COLOR.BANNERGRAY,
      color: Button.COLOR.TEXTGRAY,
      size: Input.SIZE.MOBILECATEGORY,
      searchPadding: "9px 18px 9px 88px",
    },
    button: {
      color: Button.COLOR.TEXTGRAY,
      top: "11px",
      right: "18px",
      size: "14px",
    },
  },
};

const SearchInput = (props) => {
  const {
    placeholder,
    ariaLabel,
    theme,
    isSelect,
    isMobile,
    selectList,
    value,
    submit,
  } = props;
  const inputRef = useRef();
  const {
    pathname,
    query: { searchKeyword },
  } = useUrl();

  const [defaultSearch, setDefaultSearch] = useState(searchKeyword);
  const [selectValue, setSelectValue] = useState(value);

  const handleChangeSearch = (e) => {
    setDefaultSearch(e.target.value);
  };

  useEffect(() => {
    setDefaultSearch("");
  }, [pathname]);

  useEffect(() => {
    setDefaultSearch(searchKeyword ?? "");
  }, [searchKeyword]);

  return (
    <Box
      as={"form"}
      width={"100%"}
      maxWidth={theme.maxWidth}
      position="relative"
      margin={theme.margin}
      border="1px solid"
      borderRadius={theme.input.borderRadius}
      borderColor={theme.input.borderColor}
      bgColor={theme.input.bgColor}
      padding={isSelect ? theme.input.searchPadding : theme.input.padding}
      onSubmit={(e) => {
        e.preventDefault();
        if (!defaultSearch || defaultSearch.trim() === "") return;

        submit(defaultSearch, selectValue);
      }}
    >
      {isSelect && (
        <Box
          position={"absolute"}
          top={isMobile ? "calc((100% - 32px) / 2)" : "22px"}
          left={isMobile ? "18px" : "42px"}
        >
          <Select
            defaultValue={selectValue}
            onChange={(value) => setSelectValue(value)}
            className={isMobile ? "mobileSearch" : "search"}
            style={{
              fontSize: "11px",
            }}
          >
            {selectList.map((select) => (
              <Option
                value={select.value}
                className={isMobile ? "mobileOption" : "option"}
              >
                {select.value}
              </Option>
            ))}
          </Select>
        </Box>
      )}
      <Input
        ref={inputRef}
        value={defaultSearch}
        onChange={handleChangeSearch}
        name={"search"}
        width="100%"
        border={"none"}
        bgColor={Input.COLOR.TRANSPARENT}
        color={theme.input.color}
        size={theme.input.size}
        placeholder={placeholder}
        ariaLabel={ariaLabel}
      />
      <Button
        position="absolute"
        top={theme.button.top}
        right={theme.button.right}
        color={theme.button.color}
        themeStyle={Button.THEME.NONE}
        ariaLabel={"검색 버튼"}
      >
        <AiOutlineSearch size={theme.button.size} />
      </Button>
    </Box>
  );
};

SearchInput.THEME = theme;

export default SearchInput;
