import Box from "components/atoms/box";
import Input from "components/atoms/input";
import Link from "components/atoms/link";
import Text from "components/atoms/text";
import useAllCheckbox from "hooks/utils/useAllCheckbox";
import React, { useEffect, useState } from "react";
import HasNotResult from "../HasNotResult";
import useUrl from "hooks/utils/useUrl";
import TableDeleteBar from "../tableDeleteBar";
import convertDayWithTime from "utils/convertDayWithTime";
import { useDeleteManager } from "hooks/api/admin/manager";
import { common } from "text/admin/common";
import { adminDefaultUrl } from "api/admin/url";

const ManagerTable = (props) => {
  const { list, tableHeader, itemsPerPage } = props;

  const {
    selectRemoveItems,
    default: { remove },
  } = common.alert;

  const { changeLink } = useUrl();

  const count = list.length > parseInt(itemsPerPage) ? parseInt(itemsPerPage) : list.length;

  let keyArr = [];
  const [allKeyList, setAllKeyList] = useState([]);

  useEffect(() => {
    setAllKeyList(keyArr);
  }, [list]);

  const {
    checkAll,
    checkboxes,
    resetCheckbox,
    selectedKeys,
    handleCheckAllChange,
    handleCheckboxChange,
  } = useAllCheckbox(count, allKeyList);

  const isNoneSelected = selectedKeys.length === 0;

  const mutate = useDeleteManager(resetCheckbox);

  const handleDeleteSelectedBanner = () => {
    const data = { keyList: selectedKeys };
    if (isNoneSelected) {
      alert(selectRemoveItems);
      return;
    }

    const confirm = window.confirm(remove);
    if (confirm) mutate({ data });
  };

  const handleCreateManager = () => {
    changeLink(`${adminDefaultUrl}/management/manager/detail`);
  };

  return (
    <>
      <TableDeleteBar
        title="운영자 등록"
        handleDelete={handleDeleteSelectedBanner}
        handleSave={handleCreateManager}
        hasRadius={false}
      />
      <Box
        display={Box.DISPLAY.FLEX}
        height="32px"
        borderTop="1px solid"
        borderColor={Box.COLOR.LINEGRAY}
      >
        <Box
          width="4%"
          height="100%"
          display={Box.DISPLAY.FLEX}
          al="center"
          jc="center"
          borderLeft="1px solid"
          borderBottom={list.length === 0 ? "1px solid" : ""}
          borderColor={Box.COLOR.ADMINLINEGRAY}
          bgColor={Box.COLOR.ADMINCONTENTSBG}
        >
          <Input
            type="checkbox"
            width="16px"
            height="16px"
            borderColor={Input.COLOR.ADMINLINEGRAY}
            onChange={handleCheckAllChange}
            checked={checkAll}
          />
        </Box>
        {tableHeader.map(({ title, width, ariaLabel }, idx) => (
          <Box
            width={width}
            padding="8px 0"
            key={idx}
            borderLeft="1px solid"
            borderRight={idx === tableHeader.length - 1 ? "1px solid" : ""}
            borderBottom={list.length === 0 ? "1px solid" : ""}
            borderColor={Box.COLOR.LINEGRAY}
            bgColor={Box.COLOR.ADMINCONTENTSBG}
          >
            <Text
              as={Text.VARIANT.TEXT}
              size={Text.SIZE.CLIENTCONTENTDETAIL}
              weight={Text.WEIGHT.BOLD}
              textAlign="center"
              ariaLabel={ariaLabel}
            >
              {title}
            </Text>
          </Box>
        ))}
      </Box>
      {list.length === 0 && <HasNotResult />}
      {list.length > 0 &&
        list.map(
          (
            {
              authorName,
              email1,
              email2,
              hp1,
              hp2,
              hp3,
              inEx,
              inactive,
              loginLog,
              userId,
              userName,
              key,
            },
            idx
          ) => {
            keyArr.push(key);

            return (
              <Box
                key={idx}
                borderTop={idx === 0 ? "1px solid" : ""}
                borderBottom="1px solid"
                borderColor={Box.COLOR.LINEGRAY}
                display={Box.DISPLAY.FLEX}
                height="77px"
              >
                <Box
                  width="4%"
                  height="100%"
                  display={Box.DISPLAY.FLEX}
                  jc="center"
                  borderLeft="1px solid"
                  borderColor={Box.COLOR.ADMINLINEGRAY}
                  al="center"
                >
                  <Input
                    type="checkbox"
                    name={key}
                    width="16px"
                    height="16px"
                    borderColor={Input.COLOR.ADMINLINEGRAY}
                    checked={checkboxes[key] ?? false}
                    onChange={(e) => handleCheckboxChange({ e, key })}
                  />
                </Box>
                <Box
                  width="15%"
                  display={Box.DISPLAY.FLEX}
                  jc="center"
                  al="center"
                  borderLeft="1px solid"
                  borderColor={Box.COLOR.LINEGRAY}
                  wordBreak="break-all"
                >
                  <Link link={`${adminDefaultUrl}/management/manager/detail?key=${key}`}>
                    <Text
                      as={Text.VARIANT.TEXT}
                      size={Text.SIZE.CLIENTCONTENTDETAIL}
                      textAlign="center"
                      decoration="underline"
                      ariaLabel={userId}
                    >
                      {userId}
                    </Text>
                  </Link>
                </Box>
                <Box
                  width="10%"
                  display={Box.DISPLAY.FLEX}
                  jc="center"
                  al="center"
                  borderLeft="1px solid"
                  borderColor={Box.COLOR.LINEGRAY}
                >
                  <Text
                    as={Text.VARIANT.TEXT}
                    size={Text.SIZE.CLIENTCONTENTDETAIL}
                    textAlign="center"
                    ariaLabel={userName}
                  >
                    {userName}
                  </Text>
                </Box>
                <Box
                  width="11%"
                  display={Box.DISPLAY.FLEX}
                  jc="center"
                  al="center"
                  borderLeft="1px solid"
                  borderColor={Box.COLOR.LINEGRAY}
                >
                  <Text
                    as={Text.VARIANT.TEXT}
                    size={Text.SIZE.CLIENTCONTENTDETAIL}
                    textAlign="center"
                    ariaLabel={inEx}
                  >
                    {inEx}
                  </Text>
                </Box>
                <Box
                  width="12%"
                  display={Box.DISPLAY.FLEX}
                  jc="center"
                  al="center"
                  borderLeft="1px solid"
                  borderColor={Box.COLOR.LINEGRAY}
                >
                  <Text
                    as={Text.VARIANT.TEXT}
                    size={Text.SIZE.CLIENTCONTENTDETAIL}
                    textAlign="center"
                    ariaLabel={`${hp1}-${hp2}-${hp3}`}
                  >
                    {`${hp1}-${hp2}-${hp3}`}
                  </Text>
                </Box>
                <Box
                  width="16%"
                  display={Box.DISPLAY.FLEX}
                  jc="center"
                  al="center"
                  borderLeft="1px solid"
                  borderColor={Box.COLOR.LINEGRAY}
                  wordBreak="break-all"
                >
                  <Text
                    as={Text.VARIANT.TEXT}
                    size={Text.SIZE.CLIENTCONTENTDETAIL}
                    textAlign="center"
                    ariaLabel={`${email1}@${email2}`}
                  >
                    {`${email1}@${email2}`}
                  </Text>
                </Box>
                <Box
                  width="14%"
                  display={Box.DISPLAY.FLEX}
                  jc="center"
                  al="center"
                  borderLeft="1px solid"
                  borderColor={Box.COLOR.LINEGRAY}
                  wordBreak="break-all"
                >
                  <Text
                    as={Text.VARIANT.TEXT}
                    size={Text.SIZE.CLIENTCONTENTDETAIL}
                    textAlign="center"
                    ariaLabel={loginLog}
                  >
                    {convertDayWithTime(loginLog)}
                  </Text>
                </Box>
                <Box
                  width="10%"
                  display={Box.DISPLAY.FLEX}
                  jc="center"
                  al="center"
                  borderLeft="1px solid"
                  borderColor={Box.COLOR.LINEGRAY}
                >
                  <Text
                    as={Text.VARIANT.TEXT}
                    size={Text.SIZE.CLIENTCONTENTDETAIL}
                    textAlign="center"
                    ariaLabel={authorName}
                  >
                    {authorName}
                  </Text>
                </Box>
                <Box
                  width="8%"
                  display={Box.DISPLAY.FLEX}
                  jc="center"
                  al="center"
                  borderLeft="1px solid"
                  borderRight="1px solid"
                  borderColor={Box.COLOR.LINEGRAY}
                >
                  <Text
                    as={Text.VARIANT.TEXT}
                    size={Text.SIZE.CLIENTCONTENTDETAIL}
                    textAlign="center"
                    ariaLabel={inactive}
                  >
                    {inactive === "N" ? "사용" : "미사용"}
                  </Text>
                </Box>
              </Box>
            );
          }
        )}
    </>
  );
};

export default ManagerTable;
