import Box from "components/atoms/box";
import Button from "components/atoms/button";
import Image from "components/atoms/image";
import Input from "components/atoms/input";
import Text from "components/atoms/text";
import { useFindIdMutation } from "hooks/api/auth";
import usePattern from "hooks/utils/usePattern";
import useUrl from "hooks/utils/useUrl";
import React from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { AiOutlineCheck } from "@react-icons/all-files/ai/AiOutlineCheck";
import findIdText from "text/findId";

const { success, fail, pending } = findIdText;

const FindId = () => {
  const { changeLink } = useUrl();
  const { register, setValue, watch, reset, handleSubmit } = useForm({
    defaultValues: {
      name: "",
      phone1: "",
      phone2: "",
      phone3: "",
    },
  });

  const pattern = usePattern();
  const [request, setRequest] = useState("pending");
  const [userInfo, setUserInfo] = useState([]);

  const handleSuccessFindId = (info) => {
    setRequest("success");
    setUserInfo(info);
  };

  const handleFailFindId = () => {
    setRequest("fail");
  };

  const findIdMutation = useFindIdMutation(
    handleSuccessFindId,
    handleFailFindId
  );

  const handleFail = () => {
    setRequest("pending");
    reset();
  };

  const isActive =
    watch("name").trim() !== "" &&
    watch("phone1").trim() !== "" &&
    watch("phone2").trim() !== "" &&
    watch("phone3").trim() !== "" &&
    pattern.call.value.test(
      `${watch("phone1")}-${watch("phone2")}-${watch("phone3")}`
    );

  const handleChangePassword = (maxLength) => (e) => {
    const value = e.target.value;
    const name = e.target.name;
    const reg = /[0-9]/g;
    if (!reg.test(value) || value.length > maxLength) {
      return;
    }

    setValue(name, value);
  };

  const handleChangeId = (userId) => {
    const [id, email] = userId.split("@");

    const idLength = id.length;
    const emailLength = email.length;

    return `${id.slice(0, 3).padEnd(idLength, "*")}@${email
      .slice(0, 3)
      .padEnd(emailLength, "*")}`;
  };

  return (
    <Box
      margin={"138px auto 200px"}
      width={"328px"}
      display={Box.DISPLAY.FLEX}
      flexDirection={"column"}
      al="center"
    >
      {request === "success" && (
        <>
          <Image src={"/assets/auth/checked.svg"} />
          <Text
            as={Text.VARIANT.SUBTITLE}
            size={Text.SIZE.CLIENTCATEGORY}
            weight={Text.WEIGHT.NORMAL}
            color={Text.COLOR.SECONDARY}
            textAlign={"center"}
            margin={"24px 0 40px"}
          >
            {success.title}
          </Text>

          {userInfo.map(({ userId, regDate }, idx) => (
            <Text
              as={Text.VARIANT.TEXT}
              size={Text.SIZE.CLIENTETC}
              margin={idx === userInfo.length - 1 ? "0 0 35px" : "0 0 5px"}
              isSort
              key={userId}
            >
              <Text
                as={Text.VARIANT.SPAN}
                color={Text.COLOR.SECONDARY}
                margin={"0 4px 0 0"}
              >
                <AiOutlineCheck size={16} />
              </Text>
              {handleChangeId(userId)} ({regDate} {success.info.unit})
            </Text>
          ))}

          <Button
            width={"100%"}
            padding={"13px 0"}
            margin={"0 0 10px"}
            border={"1px solid"}
            borderColor={Button.COLOR.SECONDARY}
            borderRadius={"15px"}
            bgColor={Button.COLOR.SECONDARY}
            color={Button.COLOR.WHITE}
            fontSize={Button.FONT_SIZE.CLIENTCONTENT}
            fontWeight={Button.FONT_WEIGHT.BOLD}
            onClick={() => changeLink("/login")}
          >
            {success.submit.login}
          </Button>
          <Button
            width={"100%"}
            padding={"13px 0"}
            border={"1px solid"}
            borderColor={Button.COLOR.LINEGRAY}
            borderRadius={"15px"}
            bgColor={Button.COLOR.LINEGRAY}
            color={Button.COLOR.WHITE}
            fontSize={Button.FONT_SIZE.CLIENTCONTENT}
            fontWeight={Button.FONT_WEIGHT.BOLD}
            onClick={() => changeLink("/find/pw")}
          >
            {success.submit.findPw}
          </Button>
        </>
      )}
      {request === "fail" && (
        <>
          <Image src={"/assets/auth/Error.svg"} />
          <Text
            as={Text.VARIANT.SUBTITLE}
            size={Text.SIZE.CLIENTCATEGORY}
            weight={Text.WEIGHT.NORMAL}
            color={Text.COLOR.SECONDARY}
            textAlign={"center"}
            margin={"24px 0 0"}
          >
            {fail.title[0]}
          </Text>{" "}
          <Text
            as={Text.VARIANT.SUBTITLE}
            size={Text.SIZE.CLIENTCATEGORY}
            weight={Text.WEIGHT.NORMAL}
            color={Text.COLOR.SECONDARY}
            textAlign={"center"}
            margin={"0 0 35px"}
          >
            {fail.title[1]}
          </Text>
          <Button
            width={"100%"}
            padding={"13px 0"}
            margin={"0 0 10px"}
            border={"1px solid"}
            borderColor={Button.COLOR.SECONDARY}
            borderRadius={"15px"}
            bgColor={Button.COLOR.SECONDARY}
            color={Button.COLOR.WHITE}
            fontSize={Button.FONT_SIZE.CLIENTCONTENT}
            fontWeight={Button.FONT_WEIGHT.BOLD}
            onClick={handleFail}
          >
            {fail.submit}
          </Button>
        </>
      )}
      {request === "pending" && (
        <>
          <Text
            as={Text.VARIANT.SUBTITLE}
            size={Text.SIZE.CLIENTCATEGORY}
            weight={Text.WEIGHT.NORMAL}
            textAlign={"center"}
            margin={"0 0 30px"}
          >
            {pending.title}
          </Text>

          <Input
            size={Input.SIZE.CLIENTETC}
            placeholder={pending.form.id}
            width={"100%"}
            padding={"15px 14px"}
            borderColor={Input.COLOR.LINEGRAY}
            borderRadius={"15px"}
            {...register("name")}
          />

          <Box
            display={Box.DISPLAY.FLEX}
            jc={"space-between"}
            width={"100%"}
            margin={"10px 0 35px"}
          >
            <Input
              size={Input.SIZE.CLIENTETC}
              type="tel"
              placeholder={pending.form.tel}
              width={"calc((100% - 40px) / 3)"}
              padding={"15px 14px"}
              borderColor={Input.COLOR.LINEGRAY}
              borderRadius={"15px"}
              {...register("phone1")}
              onChange={handleChangePassword(3)}
              value={watch("phone1")}
            />
            <Text
              isSort
              width={"6px"}
              margin={"0 2px"}
              color={Text.COLOR.TEXTGRAY}
            >
              -
            </Text>
            <Input
              size={Input.SIZE.CLIENTETC}
              type="tel"
              width={"calc((100% - 40px) / 3)"}
              padding={"15px 14px"}
              borderColor={Input.COLOR.LINEGRAY}
              borderRadius={"15px"}
              {...register("phone2")}
              onChange={handleChangePassword(4)}
              value={watch("phone2")}
            />
            <Text
              isSort
              width={"6px"}
              margin={"0 2px"}
              color={Text.COLOR.TEXTGRAY}
            >
              -
            </Text>
            <Input
              size={Input.SIZE.CLIENTETC}
              type="tel"
              width={"calc((100% - 40px) / 3)"}
              padding={"15px 14px"}
              borderColor={Input.COLOR.LINEGRAY}
              borderRadius={"15px"}
              {...register("phone3")}
              onChange={handleChangePassword(4)}
              value={watch("phone3")}
            />
          </Box>

          <Button
            width={"100%"}
            padding={"13px 0"}
            border={"1px solid"}
            borderColor={
              isActive ? Button.COLOR.SECONDARY : Button.COLOR.LINEGRAY
            }
            borderRadius={"15px"}
            bgColor={isActive ? Button.COLOR.SECONDARY : Button.COLOR.LINEGRAY}
            color={Button.COLOR.WHITE}
            fontSize={Button.FONT_SIZE.CLIENTCONTENT}
            fontWeight={Button.FONT_WEIGHT.BOLD}
            onClick={handleSubmit(findIdMutation)}
            disabled={!isActive}
          >
            {pending.form.submit}
          </Button>
        </>
      )}
    </Box>
  );
};

export default FindId;
