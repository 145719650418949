import React, { useEffect, useState } from "react";
import Box from "components/atoms/box";
import Link from "components/atoms/link";
import Text from "components/atoms/text";
import Image from "components/atoms/image";
import useUrl from "hooks/utils/useUrl";
import SearchInput from "components/molecules/searchInput";
import SnsList from "../snsList";
import useMenu from "hooks/utils/useMenu";
import useUserStore from "store/useUserStore";
import { useLogoutMutation, useToken, useTokenMutation } from "hooks/api/auth";
import layoutText from "text/layout";

const { header } = layoutText;

const firstCategory = {
  daily: "daily",
  news: "all",
  people: "all",
  science: "all",
  newsletter: "list",
  calendar: "science",
  help: "sitemap",
  service: "privacy",
};

const Header = () => {
  const { path, query, pathname, search, hash, changeLink } = useUrl();
  const { mainMenu, subMenu } = useMenu();
  const { category = "", page } = query;
  const { user, setUser } = useUserStore();

  const handleSuccessToken = (list) => {
    setUser(list);
  };

  const tokenMutation = useTokenMutation(handleSuccessToken);

  const handleSuccessLogout = () => {
    setUser(null);
  };

  const handleFailLogout = () => {
    alert(header.alert.logout);
  };

  const logoutMutation = useLogoutMutation(
    handleSuccessLogout,
    handleFailLogout
  );

  const subData = subMenu[path[0]];

  const isSubMenu = path.length < 2 && subData && subData.length > 1;

  const handleSearch = (keyword) => {
    changeLink(`/search?searchKeyword=${keyword}`);
  };

  useEffect(() => {
    document.getElementById("root").scrollTop = 0;
  }, [pathname, category]);

  useEffect(() => {
    if (hash) {
      const element = document.getElementById(hash.substring(1));

      if (element) {
        element.scrollIntoView();
      }
    }
  }, [hash, page]);

  useEffect(() => {
    if (!user) {
      tokenMutation();
    }
  }, [user, pathname, search, hash]);

  return (
    <Box minWidth={"1260px"}>
      <Box
        display={Box.DISPLAY.FLEX}
        jc={"center"}
        borderBottom="1px solid"
        borderColor={Box.COLOR.LINEGRAY}
      >
        <Box
          width="100%"
          maxWidth="1260px"
          display={Box.DISPLAY.FLEX}
          jc={"flex-end"}
          padding={"19px 0"}
        >
          {user ? (
            <Text
              as={Text.VARIANT.TEXT}
              size={Text.SIZE.CLIENTETC}
              color={Text.COLOR.TEXTGRAY}
              cursor={"pointer"}
              onClick={logoutMutation}
            >
              {header.auth.logout}
            </Text>
          ) : (
            <>
              <Link link={"login"} ariaLabel="로그인 링크">
                <Text
                  as={Text.VARIANT.TEXT}
                  size={Text.SIZE.CLIENTETC}
                  color={Text.COLOR.TEXTGRAY}
                >
                  {header.auth.login}
                </Text>
              </Link>
              <Link
                link={"signup"}
                margin={"0 0 0 15px"}
                ariaLabel="회원가입 링크"
              >
                <Text
                  as={Text.VARIANT.TEXT}
                  size={Text.SIZE.CLIENTETC}
                  color={Text.COLOR.TEXTGRAY}
                >
                  {header.auth.signup}
                </Text>
              </Link>
            </>
          )}
          <Link
            link="/help?category=sitemap"
            margin={"0 0 0 15px"}
            ariaLabel="사이트맵 링크"
          >
            <Text
              as={Text.VARIANT.TEXT}
              size={Text.SIZE.CLIENTETC}
              color={Text.COLOR.TEXTGRAY}
            >
              {header.sitemap}
            </Text>
          </Link>
        </Box>
      </Box>

      <Box
        display={Box.DISPLAY.FLEX}
        jc={"center"}
        borderBottom="1px solid"
        borderColor={Box.COLOR.LINEGRAY}
      >
        <Box
          width="100%"
          maxWidth="1260px"
          display={Box.DISPLAY.FLEX}
          jc={"space-between"}
          padding={"50px 0"}
        >
          <Link ariaLabel="홈 화면으로">
            <Image src={"/assets/common/logo.png"} alt="logo" width={"400px"} />
          </Link>

          <Box display={Box.DISPLAY.FLEX} al="center">
            <SearchInput
              placeholder={header.searchInput}
              ariaLabel="뉴스 검색창"
              theme={SearchInput.THEME.HEADER}
              submit={handleSearch}
            />

            <SnsList margin={"0 0 0 10px"} />
          </Box>
        </Box>
      </Box>

      <Box
        display={Box.DISPLAY.FLEX}
        jc={"center"}
        borderBottom="1px solid"
        borderColor={Box.COLOR.LINEGRAY}
      >
        <Box
          width="100%"
          maxWidth="1260px"
          display={Box.DISPLAY.FLEX}
          jc={"space-between"}
          padding={"0 26px"}
        >
          {mainMenu.map(({ title, link, ariaLabel }) => (
            <Link
              link={link}
              padding={"15px 14px"}
              active={Link.ACTIVE.MENU}
              key={title}
              ariaLabel={ariaLabel}
            >
              <Text
                as={Text.VARIANT.TEXT}
                size={Text.SIZE.CLIENTNORMAL}
                weight={Text.WEIGHT.BOLD}
              >
                {title}
              </Text>
            </Link>
          ))}
        </Box>
      </Box>

      {isSubMenu && (
        <Box display={Box.DISPLAY.FLEX} jc={"center"}>
          <Box
            width="100%"
            maxWidth="1260px"
            padding={"40px 40px 0px"}
            borderBottom="1px solid"
            borderColor={Box.COLOR.LINEGRAY}
            bgColor={Box.COLOR.MENUGRAY}
          >
            <Text
              as={Text.VARIANT.SUBTITLE}
              size={Text.SIZE.CLIENTTITLE}
              weight={Text.WEIGHT.BOLD}
              ariaLabel={`카테고리 : ${subData.title}`}
            >
              {subData[0].rootName}
            </Text>

            <Box
              display={Box.DISPLAY.FLEX}
              jc="flex-start"
              wrap={"wrap"}
              gap={"0 60px"}
              padding={"38px 0 6px 0"}
            >
              {subData.map(({ code, codeName, listviewBasic, root }, idx) => {
                const firstMenu = category === "" && firstCategory[root];

                const isActive = firstMenu === code || category === code;
                return (
                  <Link
                    link={`/${root}?category=${code}&${
                      listviewBasic ? `type=${listviewBasic}` : ""
                    }`}
                    key={codeName}
                    ariaLabel={`${root} 서브 메뉴 ${codeName} 링크`}
                    margin={"0 0 24px 0"}
                  >
                    <Text
                      as={Text.VARIANT.TEXT}
                      size={Text.SIZE.CLIENTCONTENT}
                      color={isActive ? Text.COLOR.SECONDARY : Text.COLOR.BLACK}
                      weight={isActive ? Text.WEIGHT.BOLD : Text.WEIGHT.NORMAL}
                    >
                      {codeName}
                    </Text>
                  </Link>
                );
              })}
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Header;
