import Box from "components/atoms/box";
import { Admin, MobileRss, Sitemap } from "components/organisms/helpContent";
import MobileSideNews from "components/organisms/mobileSideNews";
import useUrl from "hooks/utils/useUrl";
import React from "react";
import { useState } from "react";

const HelpForm = {
  sitemap: Sitemap,
  rss: MobileRss,
  admin: Admin,
};

const MobileHelp = () => {
  const {
    query: { category = "sitemap" },
  } = useUrl();

  const [isHidden, setIsHidden] = useState(false);

  const HelpContent = HelpForm[category];

  return (
    <Box as={"section"}>
      <Box
        display={Box.DISPLAY.FLEX}
        margin={"165px 12px 20px"}
        borderRadius={"10px"}
        bgColor={Box.COLOR.LIGHTGRAY}
      >
        <HelpContent isMobile setIsHidden={setIsHidden} />
      </Box>
      {!isHidden && <MobileSideNews />}
    </Box>
  );
};

export default MobileHelp;
