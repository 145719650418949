import Box from "components/atoms/box";
import Loading from "components/atoms/loading";
import ImageUploader from "components/molecules/imageUploader";
import InputForm from "components/molecules/inputForm";
import AdminDetailForm from "components/organisms/adminDetailForm";
import AdminDetailFormContainer from "components/organisms/adminDetailFormContainer";
import { AdminContentsContainer } from "components/templates/admin/admin.style";
import useUrl from "hooks/utils/useUrl";
import React, { useState } from "react";
import { DatePicker } from "antd";
import locale from "antd/es/date-picker/locale/ko_KR";
import Image from "components/atoms/image";
import dayjs from "dayjs";
import Text from "components/atoms/text";
import Button from "components/atoms/button";
import AdminNewsletterForm from "components/organisms/adminNewsletterForm";
import useHandleAlert from "hooks/utils/useHandleAlert";
import ChangeOrderContainer from "components/molecules/changeOrder";
import { useNewsLetterDetailList } from "hooks/api/admin/news_letter";
import { common } from "text/admin/common";
import { useRef } from "react";
import ModalContainer from "components/molecules/modalContainer";
import NewsletterTemplate from "components/organisms/newsletterTemplate";
import { SERVER_URL } from "api/config";
import { useChangeOrderStore } from "store/useChangeStore";
import { useEffect } from "react";
import convertDateMonthDays from "utils/convertDateMonthDays";

const AdminNewsLetterDetail = () => {
  const {
    query: { key },
  } = useUrl();

  const dateFormat = "YYYY-MM-DD";

  const subTitle = key ? "게시물 수정" : "게시물 등록";

  const { cancel } = common.alert.edit;
  const { handleCancel } = useHandleAlert();

  const { list, isLoading } = useNewsLetterDetailList({ key });

  const defaultValues = {
    title: "",
    thumbnail: "",
    sec1Title: "",
    sec1Contents: "",
    sec1Url: "",
    sec1NewWindow: "",
    sec2Title: "",
    sec2Contents: "",
    sec2Url: "",
    sec2NewWindow: "",
  };

  const [selectedDate, setSelectedDate] = useState(dayjs());
  const { setIsChangeOrderList } = useChangeOrderStore();
  const [newList, setNewList] = useState("");
  const [showPreview, setShowPreview] = useState(false);
  const [previewDetails, setPreviewDetails] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [sec1ImgUrl, setSec1ImgUrl] = useState("");
  const [sec2ImgUrl, setSec2ImgUrl] = useState("");
  const [submitData, setSubmitData] = useState(null);
  const [isChange, setIsChange] = useState(false);
  const [formIdxList, setFormIdxList] = useState([1, 0]);

  useEffect(() => {
    setFormIdxList([formIdxList[1], formIdxList[0]]);
  }, [isChange]);

  useEffect(() => {
    if (list) setSelectedDate(dayjs(convertDateMonthDays(list?.period), "YYYY-MM-DD"));
  }, [list]);

  const previewRef = useRef();

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const formInfo = [
    {
      title: "회차",
      minHeight: "48px",
      components: (
        <InputForm
          name="cnt"
          type="text"
          width="107px"
          height="32px"
          padding="0 12px"
          theme="secondary"
          defaultName={list?.cnt ?? ""}
          required="필수값입니다."
          ariaLabel="회차"
        />
      ),
      required: true,
    },
    {
      title: "일자",
      minHeight: "48px",
      components: (
        <Box as="label" display={Box.DISPLAY.FLEX} al="center" gap="6px" width="fit-content">
          <DatePicker
            locale={locale}
            format={dateFormat}
            onChange={handleDateChange}
            value={selectedDate}
            className="newsletter-admin-picker"
          />
          <Image src="/assets/admin/calendarIcon.png" width="24px" height="24px" />
        </Box>
      ),
      required: true,
    },
    {
      title: "제목",
      minHeight: "48px",
      components: (
        <InputForm
          name="title"
          type="text"
          width="500px"
          height="32px"
          padding="0 12px"
          theme="secondary"
          defaultName={list?.title}
          ariaLabel={list?.title}
          required="필수값입니다."
        />
      ),
      required: true,
    },
    {
      title: "썸네일 등록",
      minHeight: "281px",
      components: (
        <ImageUploader
          registerName="thumbnail"
          comments={["- 권장 이미지 : 506px*326px / 10mb 이하 / jpg,png 파일"]}
          required={list?.thumbnail ? false : "필수값입니다."}
          defaultPath={list?.thumbnail ?? null}
          serverImgPath="thumbnail"
        />
      ),
      required: true,
      isUpperPosition: true,
    },
  ];

  const isFileObject = (obj) => obj instanceof File;

  const handleSubmit = (data) => {
    const year = selectedDate.format("YYYY");
    const period = selectedDate.format("YYYY.M.D");
    let defaultSec1Img = list?.sec1Img ?? "";
    let defaultSec2Img = list?.sec2Img ?? "";
    const { cnt, thumbnail, title, sec1Link, sec2Link } = data;

    const imageUrlFromServer = (filename) => `${SERVER_URL}/upload/newsletter/${filename}`;

    if (formIdxList[0] === 1 && formIdxList[1] === 0) {
      [data.sec1Title, data.sec2Title] = [data.sec2Title, data.sec1Title];
      [data.sec1Contents, data.sec2Contents] = [data.sec2Contents, data.sec1Contents];
      [data.sec1Url, data.sec2Url] = [data.sec2Url, data.sec1Url];
      [data.sec1NewWindow, data.sec2NewWindow] = [data.sec2NewWindow, data.sec1NewWindow];
      [data.sec1Img, data.sec2Img] = [data.sec2Img, data.sec1Img];
      [defaultSec1Img, defaultSec2Img] = [defaultSec2Img, defaultSec1Img];
    }

    if (isFileObject(data.sec1Img) && isFileObject(data.sec2Img)) {
      setSec1ImgUrl(URL.createObjectURL(data.sec1Img));
      setSec2ImgUrl(URL.createObjectURL(data.sec2Img));
    } else if (isFileObject(data.sec1Img)) {
      setSec1ImgUrl(URL.createObjectURL(data.sec1Img));
      setSec2ImgUrl(imageUrlFromServer(defaultSec2Img));
    } else if (isFileObject(data.sec2Img)) {
      setSec1ImgUrl(imageUrlFromServer(defaultSec1Img));
      setSec2ImgUrl(URL.createObjectURL(data.sec2Img));
    } else {
      setSec1ImgUrl(imageUrlFromServer(defaultSec1Img));
      setSec2ImgUrl(imageUrlFromServer(defaultSec2Img));
    }

    setPreviewDetails({
      thumbnail,
      sec1Img: data.sec1Img,
      sec2Img: data.sec2Img,
      year,
      cnt,
      period,
      title,
      sec1Title: data.sec1Title,
      sec1Contents: data.sec1Contents,
      sec1NewWindow: data.sec1NewWindow === true ? "Y" : "N",
      sec1Url: sec1Link === "Y" ? data.sec1Url : "",
      sec2Title: data.sec2Title,
      sec2Contents: data.sec2Contents,
      sec2Url: sec2Link === "Y" ? data.sec2Url : "",
      sec2NewWindow: data.sec2NewWindow === true ? "Y" : "N",
    });

    const formData = new FormData();
    formData.append("thumbnail", thumbnail);
    formData.append("sec1Img", data.sec1Img);
    formData.append("sec2Img", data.sec2Img);
    formData.append(
      "param",
      JSON.stringify({
        year,
        cnt,
        period,
        title,
        sec1Title: data.sec1Title,
        sec1Contents: data.sec1Contents,
        sec1NewWindow: data.sec1NewWindow === true ? "Y" : "N",
        sec1Url: sec1Link === "Y" ? data.sec1Url : "",
        sec2Title: data.sec2Title,
        sec2Contents: data.sec2Contents,
        sec2Url: sec2Link === "Y" ? data.sec2Url : "",
        sec2NewWindow: data.sec2NewWindow === true ? "Y" : "N",
      })
    );

    setSubmitData(formData);

    setIsOpen(true);
  };

  const handleShowPreview = () => {
    setShowPreview(true);
  };

  const handleChangeOrder = (e) => {
    e.preventDefault();
    setIsChange((p) => !p);
  };

  if (isLoading) return <Loading />;

  return (
    <AdminContentsContainer>
      <Box maxWidth="1024px">
        <AdminDetailFormContainer
          title={subTitle}
          defaultValues={defaultValues}
          handleSubmit={handleSubmit}
          isHidden={true}
        >
          <AdminDetailForm formInfo={formInfo} />
          <Box>
            <Box padding="40px 0 15px 0" bgColor={Box.COLOR.ADMINCONTENTSBG}>
              <Text as={Text.VARIANT.SUBTITLE}>콘텐츠</Text>
            </Box>
            <ChangeOrderContainer
              newList={newList}
              setNewList={setNewList}
              setIsChange={setIsChange}
            >
              {formIdxList.map((idx, no) => (
                <AdminNewsletterForm key={idx} formIdx={idx} defaultList={list} no={no} />
              ))}
            </ChangeOrderContainer>

            <Box
              display={Box.DISPLAY.FLEX}
              al="center"
              jc="flex-end"
              padding="10px 0 0 0"
              bgColor={Box.COLOR.ADMINCONTENTSBG}
            >
              <Button
                padding="12px 40px"
                themeStyle="adminCommon"
                bgColor={Button.COLOR.WHITE}
                onClick={handleChangeOrder}
              >
                순서변경
              </Button>
            </Box>
            <Box
              display={Box.DISPLAY.FLEX}
              al="center"
              jc="center"
              gap="12px"
              width="100%"
              padding="40px 0 0 0"
              bgColor={Box.COLOR.ADMINCONTENTSBG}
            >
              <Button
                onClick={(e) => handleCancel(e, cancel)}
                themeStyle="adminCancel"
                padding="12px 40px"
              >
                취소
              </Button>
              <Button themeStyle="adminSave" padding="12px 40px" onClick={handleShowPreview}>
                미리보기
              </Button>
            </Box>
          </Box>
        </AdminDetailFormContainer>
        {isOpen && showPreview && (
          <ModalContainer setIsOpen={setIsOpen} height="100%" ref={previewRef}>
            <NewsletterTemplate
              {...previewDetails}
              sec1ImgUrl={sec1ImgUrl}
              sec2ImgUrl={sec2ImgUrl}
              submitData={submitData}
              setIsOpen={setIsOpen}
              setIsChangeOrderList={setIsChangeOrderList}
              isAdmin
            />
          </ModalContainer>
        )}
      </Box>
    </AdminContentsContainer>
  );
};

export default AdminNewsLetterDetail;
