import Box from "components/atoms/box";
import Button from "components/atoms/button";
import Image from "components/atoms/image";
import Link from "components/atoms/link";
import dayjs from "dayjs";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import useMobileStore from "store/useMobileStore";
import convertImage from "utils/convertImage";

const Popup = ({ position, image, id, type, link, newWindow, isPopupPreview = false }) => {
  const [isShow, setIsShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { isMobile } = useMobileStore();

  const handleClosePopupPeriod = () => {
    const curr = dayjs();

    if (type === "TODAY") {
      const nextDay = curr.add(1, "day").format("YYYY-MM-DD");
      localStorage.setItem(id, nextDay);
    } else {
      const nextWeek = curr.add(7, "day").format("YYYY-MM-DD");
      localStorage.setItem(id, nextWeek);
    }

    handleClosePopup();
  };

  const handleClosePopup = () => {
    setIsShow(false);
  };

  useEffect(() => {
    const showInfo = localStorage.getItem(id);

    if (showInfo) {
      const curr = dayjs();
      const isShow = dayjs(showInfo).isAfter(curr);

      setIsShow(!isShow);
    } else {
      setIsShow(!showInfo);
    }
  }, [id]);

  return (
    <Box
      display={isShow && isLoading ? Box.DISPLAY.BLOCK : Box.DISPLAY.NONE}
      position="absolute"
      zIndex={Box.LEVEL.ROOT}
      width={isMobile ? "350px" : "auto"}
      top={isMobile ? "50%" : `calc(200px + ${position})`}
      left={isMobile ? "50%" : position}
      transform={isMobile ? "translate(-50%, -50%)" : ""}
      bgColor={Box.COLOR.WHITE}
      isShadow
      maxWidth={isMobile ? "500px" : "26vw"}
      minWidth={isMobile ? "350px" : "500px"}
    >
      <Link link={link} target={newWindow === "Y" ? "_blank" : "_self"} width={"100%"}>
        <Image
          src={isPopupPreview ? image : convertImage(image)}
          alt={"popup"}
          onLoad={() => setIsLoading(true)}
          width={"100%"}
        />
      </Link>
      <Box display={Box.DISPLAY.FLEX} borderTop={`1px solid`} borderColor={Button.COLOR.LINEGRAY}>
        <Button
          themeStyle={Button.THEME.NONE}
          width={"50%"}
          fontSize={isMobile ? Button.FONT_SIZE.MOBILECONTENT : Button.FONT_SIZE.CLIENTCONTENT}
          padding={"13px 0"}
          onClick={handleClosePopupPeriod}
          borderRight={`1px solid`}
          borderColor={Button.COLOR.LINEGRAY}
        >
          {type === "TODAY" ? "오늘 하루 열지 않기" : "일주일간 열지 않기"}
        </Button>
        <Button
          themeStyle={Button.THEME.NONE}
          width={"50%"}
          fontSize={isMobile ? Button.FONT_SIZE.MOBILECONTENT : Button.FONT_SIZE.CLIENTCONTENT}
          padding={"13px 0"}
          onClick={handleClosePopup}
        >
          닫기
        </Button>
      </Box>
    </Box>
  );
};

export default Popup;
