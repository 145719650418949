import Box from "components/atoms/box";
import Text from "components/atoms/text";
import AdminTable from "components/organisms/adminTable";
import { AdminContentsContainer } from "components/templates/admin/admin.style";
import { useCategoryList } from "hooks/api/admin/category";
import useUrl from "hooks/utils/useUrl";
import React from "react";
import CategoryTable from "components/molecules/categoryTable";
import useContextForm from "hooks/utils/useContextForm";
import { category } from "text/admin/category";

const ManagementCategory = () => {
  const { path } = useUrl();

  const categoryName = path[2];

  const { subTitle, tableHeader, defaultValues } = category.management;

  const { Provider } = useContextForm(defaultValues);

  return (
    <AdminContentsContainer>
      <Box maxWidth="1024px" display={Box.DISPLAY.FLEX} flexDirection="column">
        <Text as={Text.VARIANT.SUBTITLE} ariaLabel={subTitle[categoryName]} margin="0 0 10px 0">
          {subTitle[categoryName]}
        </Text>
        <Provider>
          <AdminTable
            type={["countBar", "changeOrder"]}
            hasSelectMenu={false}
            //
            api={useCategoryList}
            tableHeader={tableHeader}
            categoryName={categoryName}
          >
            <CategoryTable />
          </AdminTable>
        </Provider>
      </Box>
    </AdminContentsContainer>
  );
};

export default ManagementCategory;
