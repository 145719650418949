import React from "react";
import Box from "components/atoms/box";
import Button from "components/atoms/button";

const MobileCategorySelect = (props) => {
  const { list, current, onClick } = props;

  return (
    <Box
      width={"100%"}
      borderBottom={"1px solid"}
      padding={"0 0 1px"}
      margin={"0 0 30px"}
      borderColor={Box.COLOR.LINEGRAY}
    >
      {list.map(({ title }, idx) => {
        const isActive = idx === current;
        return (
          <Button
            width={"50%"}
            padding={"0 0 13px"}
            fontSize={Button.FONT_SIZE.MOBILECATEGORY}
            fontWeight={
              isActive ? Button.FONT_WEIGHT.BOLD : Button.FONT_WEIGHT.NORMAL
            }
            borderBottom={isActive ? "2px solid" : ""}
            borderColor={Button.COLOR.SECONDARY}
            color={isActive ? Button.COLOR.SECONDARY : Button.COLOR.BLACK}
            themeStyle={Button.THEME.NONE}
            onClick={() => onClick(idx)}
          >
            {title}
          </Button>
        );
      })}
    </Box>
    // <Box
    //   width={"100%"}
    //   borderBottom="1px solid"
    //   borderColor={Box.COLOR.LINEGRAY}
    //   borderRadius={"0 20px"}
    //   margin={margin}
    // >
    //   <Box width={"630px"} bgColor={Box.COLOR.GRAY} borderRadius={"0 20px"}>
    //     {list.map(({ title }, idx) => {
    //       const isBorder =
    //         idx !== list.length - 1 && idx !== current - 1 && idx !== current;

    //       return (
    //         <Button
    //           key={title}
    //           themeStyle={Button.THEME.CATEGORY}
    //           width={"50%"}
    //           isActive={idx === current}
    //           onClick={() => onClick(idx)}
    //           //
    //           ariaLabel={`${title} 선택 버튼`}
    //         >
    //           <Text as={Text.VARIANT.TEXT} position="relative">
    //             {title}
    //             {isBorder && (
    //               <Text
    //                 position="absolute"
    //                 right="0"
    //                 as={Text.VARIANT.SPAN}
    //                 margin="3px 0"
    //                 borderRight={"1px solid"}
    //                 borderColor={Text.COLOR.LINEGRAY}
    //               >
    //                 &nbsp;
    //               </Text>
    //             )}
    //           </Text>
    //         </Button>
    //       );
    //     })}
    //   </Box>
    // </Box>
  );
};

export default MobileCategorySelect;
