import { DELETE, GET, POST, PUT } from "api/config";
import { site } from "../url";

const { bannerUrl, commentUrl, popupUrl } = site;

// 배너 관리
export const getSiteBannerList = async (category) => await GET(`${bannerUrl}/${category}`);

// 배너 상세 (슬라이드, 중간)
export const getSiteBannerDetail = async ({ category, key }) =>
  await GET(`${bannerUrl}/${category}/${key}`);

// 배너 상세 (SNS)
export const getSNSBannerDetail = async ({ category, key, snsForm }) =>
  await GET(`${bannerUrl}/${category}/${key}?snsForm=${snsForm}`);

// 배너 타입 변경
export const updateBannerType = async ({ data }) => {
  await PUT(`${bannerUrl}/sns/form`, data);
};

// 배너 순서 변경
export const updateBannerOrder = async ({ categoryName, data, snsForm }) => {
  await PUT(`${bannerUrl}/${categoryName}${snsForm ? `?snsForm=${snsForm}` : ""}`, data);
};

// 배너 등록 (슬라이드, 중간)
export const createSlideBanner = async ({ categoryName, formData }) => {
  await POST(`${bannerUrl}/${categoryName}`, formData);
};

// 배너 수정 (슬라이드, 중간)
export const updateSlideBanner = async ({ categoryName, key, formData }) => {
  await PUT(`${bannerUrl}/${categoryName}/${key}`, formData);
};

// 배너 수정 (SNS)
export const updateSNSBanner = async ({ categoryName, key, snsForm, formData }) => {
  await PUT(`${bannerUrl}/${categoryName}/${key}?snsForm=${snsForm}`, formData);
};

// 배너 삭제 (슬라이드, 중간)
export const deleteSlideBanner = async ({ categoryName, data }) =>
  await DELETE(`${bannerUrl}/${categoryName}`, data);

// 댓글 ON/OFF
export const getCommentOnOff = async () => await GET(commentUrl);

export const updateCommentOnOff = async ({ data }) => {
  await PUT(commentUrl, data);
};

// 팝업 관리
export const getSitePopupList = async (page = "1", itemsPerPage = "10", searchValue = "") =>
  await GET(`${popupUrl}?pageIndex=${page}&itemsPerPage=${itemsPerPage}&title=${searchValue}`);

// 팝업 상세
export const getSitePopupDetail = async ({ key }) => await GET(`${popupUrl}/${key}`);

// 팝업 등록
export const createPopup = async ({ formData }) => {
  await POST(`${popupUrl}`, formData);
};

// 팝업 수정
export const updatePopup = async ({ key, formData }) => {
  await PUT(`${popupUrl}/${key}`, formData);
};

// 팝업 삭제
export const deletePopup = async ({ data }) => {
  await DELETE(`${popupUrl}`, data);
};
