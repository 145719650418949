import React from "react";
import SVG from "components/molecules/svg";

const generateMenuIcons = (title, isActiveMenu, isOpenMenu, width = "30px") => {
  switch (title) {
    case "대시보드":
      return (
        <SVG
          path={`/assets/admin/sideBar/01_dashboard${isActiveMenu || isOpenMenu ? "_p" : ""}.svg`}
          width={width}
        />
      );
    case "카테고리 관리":
      return (
        <SVG
          path={`/assets/admin/sideBar/02_category${isActiveMenu || isOpenMenu ? "_p" : ""}.svg`}
          width={width}
        />
      );
    case "사이트 관리":
      return (
        <SVG
          path={`/assets/admin/sideBar/03_site${isActiveMenu || isOpenMenu ? "_p" : ""}.svg`}
          width={width}
        />
      );
    case "운영자 관리":
      return (
        <SVG
          path={`/assets/admin/sideBar/04_sysop${isActiveMenu || isOpenMenu ? "_p" : ""}.svg`}
          width={width}
        />
      );
    case "과학기술 캘린더":
      return (
        <SVG
          path={`/assets/admin/sideBar/11_calendar${isActiveMenu || isOpenMenu ? "_p" : ""}.svg`}
          width={width}
        />
      );
    case "과총 동정":
      return (
        <SVG
          path={`/assets/admin/sideBar/12_evaluation${isActiveMenu || isOpenMenu ? "_p" : ""}.svg`}
          width={width}
        />
      );
    case "뉴스게시판 관리":
      return (
        <SVG
          path={`/assets/admin/sideBar/06_news_board${isActiveMenu || isOpenMenu ? "_p" : ""}.svg`}
          width={width}
        />
      );
    case "뉴스레터 관리":
      return (
        <SVG
          path={`/assets/admin/sideBar/07_newsletter${isActiveMenu || isOpenMenu ? "_p" : ""}.svg`}
          width={width}
        />
      );
    case "고객센터":
      return (
        <SVG
          path={`/assets/admin/sideBar/08_customer_service${
            isActiveMenu || isOpenMenu ? "_p" : ""
          }.svg`}
          width={width}
        />
      );
    case "회원 관리":
      return (
        <SVG
          path={`/assets/admin/sideBar/09_member${isActiveMenu || isOpenMenu ? "_p" : ""}.svg`}
          width={width}
        />
      );
    case "통계":
      return (
        <SVG
          path={`/assets/admin/sideBar/10_statistics${isActiveMenu || isOpenMenu ? "_p" : ""}.svg`}
          width={width}
        />
      );
    case "로그아웃":
      return <SVG path={`/assets/admin/sideBar/13_logout.svg`} width={width} />;
    default:
      return (
        <SVG
          path={`/assets/admin/sideBar/01_dashboard${isActiveMenu || isOpenMenu ? "_p" : ""}.svg`}
          width={width}
        />
      );
  }
};

export default generateMenuIcons;
