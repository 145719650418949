import Box from "components/atoms/box";
import Input from "components/atoms/input";
import Text from "components/atoms/text";
import usePattern from "hooks/utils/usePattern";
import React, { useEffect } from "react";
import { useState } from "react";
import { useFormContext } from "react-hook-form";

const InputForm = (props) => {
  const {
    type,
    Label,
    name,
    placeholder,
    width,
    height,
    padding,
    margin,
    size,
    borderRadius,
    theme,
    ariaLabel,
    errorMessage,
    required,
    defaultName,
    isError = true,
    maxLength,
    isAdminDetail,
    disabled,
  } = props;

  const [inputValue, setInputValue] = useState("");

  const {
    register,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();

  const pattern = usePattern(watch("password"));

  const valueFromForm = watch(name);

  useEffect(() => {
    if (name === "hp") return;
    setInputValue(valueFromForm || "");
  }, [valueFromForm]);

  useEffect(() => {
    if (!defaultName) return;
    setValue(name, defaultName, { shouldValidate: true });
    setInputValue(defaultName);
  }, [setValue, name, defaultName]);

  const { onChange: onRegisteredChange, ...registered } = register(name, {
    required,
    pattern: pattern[name],
  });

  const handleInputChange = (event) => {
    onRegisteredChange(event);

    let value = event.target.value;

    if (name === "hp") {
      value = value.replace(/-/g, "");
      if (value.length > 3 && value.length <= 7) {
        value = value.slice(0, 3) + "-" + value.slice(3);
      } else if (value.length > 7) {
        value = value.slice(0, 3) + "-" + value.slice(3, 7) + "-" + value.slice(7);
      }
    }
    setInputValue(value);
  };

  const hasError = isError && errors[name];

  return (
    <Box
      as="label"
      position="relative"
      width={width}
      display={Box.DISPLAY.FLEX}
      flexDirection="column"
      gap="4px"
    >
      {Label && <Label />}
      <Input
        {...registered}
        value={inputValue}
        onChange={handleInputChange}
        type={type}
        name={name}
        placeholder={placeholder}
        width={width}
        height={height}
        padding={padding}
        margin={margin}
        themeStyle={theme}
        borderRadius={borderRadius}
        size={size ?? Text.SIZE.ADMINCONTENT}
        maxLength={maxLength}
        disabled={disabled}
        ariaLabel={ariaLabel}
      />
      {hasError && (
        <Box
          position={isAdminDetail ? "absolute" : "relative"}
          top={isAdminDetail ? "50%" : ""}
          transform={isAdminDetail ? "translateY(-50%)" : "none"}
        >
          <Text
            as={Text.VARIANT.TEXT}
            color={Text.COLOR.RED}
            whiteSpace="pre-line"
            size={Text.SIZE.ADMINCONTENT}
            padding="0 0 0 8px"
            ariaLabel={hasError.message || errorMessage}
            lineHeight="1.2"
          >
            {hasError.message || errorMessage}
          </Text>
        </Box>
      )}
    </Box>
  );
};

export default InputForm;
