import styled from "styled-components";

export const ImageComponent = styled.img`
  width: ${(props) => props.$width};
  height: ${(props) => props.$height};

  margin: ${(props) => props.margin};
  border-radius: ${(props) =>
    props.theme.radius[props.$radius] ?? props.$radius};

  fill: ${(props) => props.theme.color[props.color]};
  stroke: ${(props) => props.theme.color[props.color]};

  object-fit: ${(props) => props.fit};
  aspect-ratio: ${(props) => props.aspectRatio};
`;
