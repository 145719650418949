import Box from "components/atoms/box";
import Button from "components/atoms/button";
import Text from "components/atoms/text";
import React from "react";
import { IoMdClose } from "@react-icons/all-files/io/IoMdClose";

const TextSizeBox = ({ size, isMobile, toggle, setSize }) => {
  return (
    <>
      {isMobile && (
        <Box
          position={"fixed"}
          top={"0"}
          left={"0"}
          width={"100vw"}
          height={"100vh"}
          bgColor={Box.COLOR.SHADOW}
          zIndex={Box.LEVEL.ROOT}
          onClick={toggle}
        />
      )}

      <Box
        display={Box.DISPLAY.FLEX}
        jc="space-between"
        al="flex-start"
        wrap="wrap"
        //
        position={isMobile ? "fixed" : "absolute"}
        right={isMobile ? "calc((100% - 192px) / 2)" : "0"}
        top={isMobile ? "calc((100vh - 100px) / 2)" : "35px"}
        //
        width="192px"
        height={isMobile ? "100px" : "72px"}
        padding={"20px 27px"}
        //
        border={"1px solid"}
        borderColor={Box.COLOR.SECONDARY}
        borderRadius="20px"
        bgColor={Box.COLOR.WHITE}
        zIndex={Box.LEVEL.ROOT}
      >
        {isMobile && (
          <>
            <Text
              as={Text.VARIANT.SUBTITLE}
              size={Text.SIZE.MOBILECONTENT}
              weight={Text.WEIGHT.BOLD}
              textAlign={"center"}
              width={"100%"}
              margin={"0 0 16px"}
            >
              {/* 공유하기 */}
            </Text>
            <Box position={"absolute"} top={"16px"} right={"16px"}>
              <Button themeStyle={Button.THEME.NONE} onClick={toggle}>
                <IoMdClose size={18} />
              </Button>
            </Box>
          </>
        )}

        <Button
          width={"32px"}
          height={"32px"}
          themeStyle={Button.THEME.NONE}
          border={"1px solid"}
          borderColor={
            size === 0 ? Button.COLOR.SECONDARY : Button.COLOR.LINEGRAY
          }
          borderRadius={"10px"}
          bgColor={size === 0 ? Button.COLOR.SECONDARY : Button.COLOR.WHITE}
          color={size === 0 ? Button.COLOR.WHITE : Button.COLOR.BLACK}
          fontSize={Button.FONT_SIZE.CLIENTETC}
          onClick={() => setSize(0)}
        >
          가
        </Button>
        <Button
          width={"32px"}
          height={"32px"}
          themeStyle={Button.THEME.NONE}
          border={"1px solid"}
          borderColor={
            size === 1 ? Button.COLOR.SECONDARY : Button.COLOR.LINEGRAY
          }
          borderRadius={"10px"}
          bgColor={size === 1 ? Button.COLOR.SECONDARY : Button.COLOR.WHITE}
          color={size === 1 ? Button.COLOR.WHITE : Button.COLOR.BLACK}
          fontSize={Button.FONT_SIZE.CLIENTCONTENT}
          onClick={() => setSize(1)}
        >
          가
        </Button>
        <Button
          width={"32px"}
          height={"32px"}
          themeStyle={Button.THEME.NONE}
          border={"1px solid"}
          borderColor={
            size === 2 ? Button.COLOR.SECONDARY : Button.COLOR.LINEGRAY
          }
          borderRadius={"10px"}
          bgColor={size === 2 ? Button.COLOR.SECONDARY : Button.COLOR.WHITE}
          color={size === 2 ? Button.COLOR.WHITE : Button.COLOR.BLACK}
          fontSize={Button.FONT_SIZE.CLIENTSUBTITLE}
          onClick={() => setSize(2)}
        >
          가
        </Button>
      </Box>
    </>
  );
};

export default TextSizeBox;
