import Box from "components/atoms/box";
import Board from "components/organisms/board";
import NewsletterList from "components/organisms/newsletterList";
import NewsletterRequest from "components/organisms/newsletterRequest";
import useUrl from "hooks/utils/useUrl";
import React from "react";
import { useState } from "react";

const Newsletter = () => {
  const {
    query: { category },
  } = useUrl();
  const [isHidden, setIsHidden] = useState(false);

  const isBefore = category === "before";
  const isRequest = category === "request";

  return (
    <Box width={"100%"} display={Box.DISPLAY.FLEX} jc={"center"}>
      <Board isAllPage={isHidden} isTransparent={isHidden}>
        {isRequest ? (
          <NewsletterRequest setIsHidden={setIsHidden} />
        ) : (
          <NewsletterList isBefore={isBefore} />
        )}
      </Board>
    </Box>
  );
};

export default Newsletter;
