import { useCategory } from "hooks/api/category";
import { useEffect, useState } from "react";

const MenuList = [
  {
    title: "Daily News",
    link: "daily",
    ariaLabel: "Daily News 링크",
  },
  {
    title: "과학기술 캘린더",
    link: "calendar",
    ariaLabel: "과학기술 캘린더 링크",
  },
  {
    title: "뉴스",
    link: "news",
    ariaLabel: "뉴스 링크",
  },
  {
    title: "특집",
    link: "feature",
    ariaLabel: "특집 기사 링크",
  },
  {
    title: "인물",
    link: "people",
    ariaLabel: "인물 기사 링크",
  },
  {
    title: "과학으로 본 세상",
    link: "science",
    ariaLabel: "과학으로 본 세상 링크",
  },
  {
    title: "뉴스레터",
    link: "newsletter",
    ariaLabel: "뉴스레터 링크",
  },
];

const SUBMENU = {
  help: [
    {
      codeName: "사이트맵",
      root: "help",
      rootName: "고객센터",
      code: "sitemap",
    },
    {
      codeName: "RSS",
      root: "help",
      rootName: "고객센터",
      code: "rss",
    },
    {
      codeName: "관리자에게",
      root: "help",
      rootName: "고객센터",
      code: "admin",
    },
  ],

  service: [
    {
      codeName: "개인정보처리방침",
      code: "privacy",
      root: "service",
      rootName: "SERVICE",
    },
    {
      codeName: "이용약관",
      code: "termsOfUse",
      root: "service",
      rootName: "SERVICE",
    },
    {
      codeName: "이메일무단수집거부",
      code: "email",
      root: "service",
      rootName: "SERVICE",
    },
    {
      codeName: "저작권보호정책",
      code: "copyright",
      root: "service",
    },
  ],

  calendar: [
    {
      codeName: "과학기술 캘린더",
      code: "science",
      root: "calendar",
      rootName: "과학기술 캘린더",
    },
    // {
    //   codeName: "과총 동정",
    //   code: "kofst",
    //   root: "calendar",
    //   rootName: "과학기술 캘린더",
    // },
  ],

  daily: [
    {
      codeName: "Daily News",
      code: "daily",
      root: "daily",
      rootName: "Daily News ",
    },
    {
      codeName: "주간 Main News",
      code: "week",
      root: "daily",
      rootName: "Daily News ",
    },
    {
      codeName: "월간 Main News",
      code: "month",
      root: "daily",
      rootName: "Daily News ",
    },
  ],

  newsletter: [
    {
      codeName: "뉴스레터 리스트",
      code: "list",
      root: "newsletter",
      rootName: "뉴스레터",
    },
    {
      codeName: "뉴스레터 신청/취소",
      code: "request",
      root: "newsletter",
      rootName: "뉴스레터",
    },
    {
      codeName: "이전 뉴스레터",
      code: "before",
      root: "newsletter",
      rootName: "뉴스레터",
    },
  ],
};

const useMenu = (customSubMenu = {}, customMainMenu = []) => {
  const [subMenu, setSubMenu] = useState({ ...SUBMENU });
  const { data, isSuccess } = useCategory();

  const mainMenu = MenuList;

  useEffect(() => {
    if (isSuccess && data) {
      const subMenu = {};

      data.list.forEach(({ boardName, cate1Name, ...props }) => {
        if (!subMenu[boardName])
          subMenu[boardName] = [
            {
              code: "all",
              codeName: "전체",
              sortSeq: "0",
              root: boardName,
              rootName: cate1Name,
            },
          ];

        subMenu[boardName].push({
          root: boardName,
          rootName: cate1Name,
          ...props,
        });
      });

      setSubMenu({ ...SUBMENU, ...subMenu });
    }
  }, [isSuccess, data]);

  return {
    mainMenu: [...mainMenu, ...customMainMenu],
    subMenu: { ...subMenu, ...customSubMenu },
  };
};

export default useMenu;
