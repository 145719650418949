import { API_VERSION, SERVER_URL } from "api/config";
import Box from "components/atoms/box";
import useUrl from "hooks/utils/useUrl";
import React, { forwardRef, useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import useTemporaryStore from "store/useTemporaryStore";
import { editor } from "text/admin/editor";

const NamoWebEditor = forwardRef((props, ref) => {
  const { menuList, font } = editor;
  const url = `${SERVER_URL}/api/${API_VERSION}/upload/editor`;
  const { defaultValue = "", name = "editor", params: propsParams = {} } = props;

  const editorParentRef = useRef(null);

  const { hasTempData } = useTemporaryStore();
  const [editorInitialized, setEditorInitialized] = useState(false);

  const { path } = useUrl();
  const currPage = path[1];

  useEffect(() => {
    createEditor();
  }, [currPage, hasTempData]);

  const toolBarList = menuList.join("|");

  const createEditor = () => {
    const params = { ...propsParams };

    if (typeof window.NamoSE !== "undefined") {
      if (ref.current === null) {
        ref.current = new window.NamoSE(name);

        ref.current.params = params;

        ref.current.params.Width = "100%";
        ref.current.params.UserLang = "auto";
        ref.current.params.NewToolbar = true;
        ref.current.params.Height = 380;
        ref.current.params.Width = 850;
        ref.current.params.UserToolbar = true;
        ref.current.params.CreateToolbar = toolBarList;
        ref.current.params.UploadFileExecutePath = url;
        ref.current.params.UploadEtcFileExecutePath = url;
        ref.current.params.PluginModeExecutePath = url;
        ref.current.params.UploadFileSizeLimit = "image:52428800";
        ref.current.params.Font = font;

        if (typeof ref.current.params.event === "undefined") {
          ref.current.params.event = {};
        }

        ref.current.params.event.OnInitCompleted = handleInitCompleted;
        ref.current.params.ParentEditor = editorParentRef.current;

        ref.current.EditorStart();
      }
    }
  };

  const handleInitCompleted = () => {
    setEditorInitialized(true);
  };

  useEffect(() => {
    if (!editorInitialized) return;

    const tempData = localStorage.getItem(currPage);

    setTimeout(() => {
      if (hasTempData) {
        const parsedData = JSON.parse(tempData);
        const { contents } = parsedData;
        ref.current.SetBodyValue(contents);
      } else {
        if (ref.current) {
          ref.current.SetBodyValue(defaultValue);
        }
      }
    }, 0);
  }, [editorInitialized, hasTempData]);

  return <Box ref={editorParentRef} height="100%"></Box>;
});

export default NamoWebEditor;
