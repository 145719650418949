import Box from "components/atoms/box";
import Text from "components/atoms/text";
import useContextForm from "hooks/utils/useContextForm";
import React from "react";
import AdminTable from "../adminTable";
import { useState } from "react";
import CalendarTable from "components/molecules/calendarTable";
import { useScienceCalendarList } from "hooks/api/admin/calendar";
import { calendar } from "text/admin/calendar";
import useUrl from "hooks/utils/useUrl";

const AdminCalendarList = (props) => {
  const {
    filterData: { startDate, endDate, searchCondition, searchValue, dateCondition },
  } = props;

  const {
    query: { page },
  } = useUrl();

  const { tableHeader, defaultValues } = calendar.table;

  const [selectedMenu, setSelectedMenu] = useState("10");

  const { Provider } = useContextForm(defaultValues);

  return (
    <Box maxWidth="1024px" display={Box.DISPLAY.FLEX} flexDirection="column" margin="40px 0 0 0">
      <Text as={Text.VARIANT.SUBTITLE} ariaLabel="일정 목록" margin="0 0 10px 0">
        일정 목록
      </Text>
      <Provider>
        <AdminTable
          type={["countBar"]}
          hasSelectMenu={true}
          page={page}
          //
          api={useScienceCalendarList}
          tableHeader={tableHeader}
          searchValue={searchValue}
          searchCondition={searchCondition}
          dateCondition={dateCondition}
          //
          startDate={startDate}
          endDate={endDate}
          //
          selectedMenu={selectedMenu}
          setSelectedMenu={setSelectedMenu}
          width="120px"
        >
          <CalendarTable />
        </AdminTable>
      </Provider>
    </Box>
  );
};

export default AdminCalendarList;
